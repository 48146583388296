/* eslint-disable max-len */
import { Card, Col, Form, InputNumber, Row, Select, Switch } from "antd"
import { useState } from "react"
import Styles from "./styles.module.css"

const ANIMATION_TYPES = [
  { label: "None", value: null },
  { label: "Fade", value: "fade" },
  { label: "Slide from left", value: "slideFromLeft" },
  { label: "Slide from right", value: "slideFromRight" },
  { label: "Slide from top", value: "slideFromTop" },
  { label: "Slide from bottom", value: "slideFromBottom" },
]

type Props = {
  notificationsPerGameIndex: number;
  parentIndex: number;
};

export const AnimationEditor = ({ parentIndex, notificationsPerGameIndex }: Props) => {
  const form = Form.useFormInstance()
  const [isEnabled, setIsEnabled] = useState(!!form.getFieldValue(["notificationsPerGame", notificationsPerGameIndex, "notifications", parentIndex, "animationType"]))

  const enableAnimation = () => {
    setIsEnabled(true)
    // eslint-disable-next-line max-len
    form.setFieldsValue({ notificationsPerGame: { [notificationsPerGameIndex]: { notifications: { [parentIndex]: { animationType: null } } } } })
  }

  const disableAnimation = () => {
    setIsEnabled(false)
    // eslint-disable-next-line max-len
    form.setFieldsValue({ notificationsPerGame: { [notificationsPerGameIndex]: { notifications: { [parentIndex]: { animationType: null } } } } })
  }

  return (
    <Card
      title={
        <div>
          Animation
          <Switch checked={isEnabled} className={Styles.switch} onChange={checked => (checked ? enableAnimation() : disableAnimation())} />
        </div>
      }
      bodyStyle={{ display: isEnabled ? "block" : "none" }}
    >
      <Row gutter={16} style={{ display: isEnabled ? "flex" : "none" }}>
        <Col span={12}>
          <Form.Item label="Type" name={[parentIndex, "animationType"]}>
            <Select options={ANIMATION_TYPES} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Duration (ms)"
            name={[parentIndex, "animationDuration"]}
            tooltip="The duration of the animation in milliseconds."
          >
            <InputNumber min={0} step={1} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
