import { Button, Space } from "antd"
import { useState } from "react"
import { StyledPopupTable } from "./components/StyledPopupTable"
import { StyledTemplate } from "domains/popup-templates/store/StyledTemplate.store"
import { StyledPopupEditModal } from "./components/StyledPopupEditModal"

export const StyledTemplatesTable = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editedPopup, setEditedPopup] = useState<StyledTemplate | undefined>(undefined)

  const createNewPopup = () => {
    setIsEditModalOpen(true)
    setEditedPopup(undefined)
  }

  const handleModalClose = () => {
    setIsEditModalOpen(false)
    setEditedPopup(undefined)
  }

  const editPopup = (popup: StyledTemplate) => {
    setEditedPopup(popup)
    setIsEditModalOpen(true)
  }

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Button type="primary" onClick={createNewPopup}>
          Create new styled popup
        </Button>
        <StyledPopupTable editPopup={editPopup} />
      </Space>
      {isEditModalOpen && <StyledPopupEditModal onClose={handleModalClose} editedTemplate={editedPopup} />}
    </div>
  )
}
