/* eslint-disable max-len */
import { gameNames } from "abstract/constants/game-ids"
import { Card, Col, Row, Select, Tag, Tooltip, DatePicker, Form, Input } from "antd"
import { NotificationsPerGame } from "domains/specialEvents/pages/main"
import { COUNTRY_CODES } from "utils/countryCodes"

const { RangePicker } = DatePicker

const GAME_OPTIONS = Object.entries(gameNames).map(([key, value]) => ({ label: value, value: key }))

export const MainInfoEditor = () => {
  const form = Form.useFormInstance()

  // when game is changed, we also need to update the notificationsPerGame
  const handleGameChange = (selectedGameIds: number[]) => {
    const currentNotificationsPerGame: NotificationsPerGame[] = form.getFieldValue("notificationsPerGame") || []
    const updatedNotificationsPerGame = currentNotificationsPerGame.filter(notification => selectedGameIds.includes(notification.gameId))

    selectedGameIds.forEach(gameId => {
      if (!updatedNotificationsPerGame.some(notification => notification.gameId === gameId)) {
        updatedNotificationsPerGame.push({ gameId })
      }
    })
    form.setFieldsValue({ notificationsPerGame: updatedNotificationsPerGame })
  }

  // when country is changed, we also need to update the source images for each notification
  const handleCountryChange = (selectedCountryCodes: string[]) => {
    const selectedCountryCodesWithGlobal = ["global", ...selectedCountryCodes]
    const notificationsPerGame: NotificationsPerGame[] = form.getFieldValue(["notificationsPerGame"]) || []
    const updatedNotificationsPerGame = notificationsPerGame.map(notificationPerGame => {
      const updatedNotifications = notificationPerGame.notifications?.map(notification => {
        const updatedImagesByGeo = selectedCountryCodesWithGlobal.map(countryCode => {
          const existingImageByGeo = notification.sourceImagesByGeo?.find(imageByGeo => imageByGeo.country === countryCode)

          return existingImageByGeo || { country: countryCode, imageSources: [] }
        })

        return { ...notification, sourceImagesByGeo: updatedImagesByGeo }
      })

      return { ...notificationPerGame, notifications: updatedNotifications }
    })

    form.setFieldsValue({ notificationsPerGame: updatedNotificationsPerGame })
  }

  return (
    <Card title="Main info" bordered={false}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[{
              required: true,
              message: "Event name is required",
            }]}
          >
            <Input placeholder="Enter event name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Games" name={["games"]}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              options={GAME_OPTIONS}
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              onChange={handleGameChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Restricted countries" name="restrictedToCountries">
            <Select
              mode="tags"
              onChange={handleCountryChange}
              allowClear
              style={{ width: "100%" }}
              placeholder="No restrictions"
              options={COUNTRY_CODES.map(({ code, name }) => ({ label: `${name} (${code})`, value: code }))}
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              tagRender={({ closable, value, label, onClose }) => {
                const handleMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                  event.preventDefault()
                  event.stopPropagation()
                }

                return (
                  <Tooltip title={label}>
                    <Tag
                      onMouseDown={handleMouseDown}
                      closable={closable}
                      onClose={onClose}
                      style={{ marginRight: 3, cursor: "default" }}
                    >
                      {value}
                    </Tag>
                  </Tooltip>
                )
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12} >
          <Form.Item rules={[{ required: true, message: "Dates are required" }]} required label="Start time / End time (UTC)" name="dates">
            <RangePicker showTime format={date => date.utc().format("MMM DD, YYYY HH:mm:ss")} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
