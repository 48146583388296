import { Button, Form, Spin, Typography } from "antd"
import { OfferwallSettings } from "domains/managingJson/store/types"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { notifications as appNotifications } from "notifications"
import Styles from "./styles.module.css"
import GeoPickerElement from "components/geoPicker"

const { Title } = Typography

export const SettingsPage = observer(() => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const { dataStore: { managingJsonStore } } = useStore()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<OfferwallSettings | undefined>(undefined)

  useEffect(() => {
    const getData = async() => {
      await managingJsonStore.getData()
      setIsLoading(false)
    }
    getData()
  }, [managingJsonStore])

  useEffect(() => {
    const offerwallSettings = managingJsonStore?.itemTypes?.filter(
      item => item.type === "offerwallSettings",
    )
    if (offerwallSettings && offerwallSettings.length > 0) {
      const data = offerwallSettings[0].data as OfferwallSettings

      setData(data)
    }
  }, [managingJsonStore.itemTypes])

  const getStoreData = (): OfferwallSettings | undefined => {
    const eventsData = managingJsonStore?.itemTypes?.filter(
      item => item.type === "offerwallSettings",
    )

    return eventsData[0]?.data as OfferwallSettings
  }

  const updateStore = async(
    callback: (prev: OfferwallSettings | undefined) => OfferwallSettings,
  ) => {
    const previousData = getStoreData()
    const newData = callback(previousData)

    previousData
      ? await managingJsonStore.update({
        name: "offerwallSettings",
        type: "offerwallSettings",
        data: newData,
      })
      : await managingJsonStore.create({
        name: "offerwallSettings",
        type: "offerwallSettings",
        data: newData,
      })
  }

  const save = async() => {
    managingJsonStore.funcAfterSuccess = async() => {
      appNotifications.success("Saved successfully")
      await managingJsonStore.getData()
    }

    try {
      const values = await form.validateFields()

      const { whitelistedCountries } = values
      const newSettings: OfferwallSettings = { whitelistedCountries: whitelistedCountries ?? [] }


      updateStore(() => {
        return newSettings
      })


    } catch (error) {
      return
    }
  }

  useEffect(() => {
    // checking if form is rendered - if not, we won't set fields value
    // loader might be rendered instead of form while data is loading
    if (!formRef.current) return
    form.setFieldsValue({ ...data })
  }, [data])

  if (isLoading) return (
    <div className={Styles.spinnerWrapper}>
      <Spin size="large" />
    </div>
  )

  return (
    <Form form={form} layout="vertical" ref={formRef}>
      <Title level={2}>Offerwall Settings</Title>
      <GeoPickerElement label="Whitelisted Countries" name={["whitelistedCountries"]} initialValue={data?.whitelistedCountries} />
      <Button type="primary" onClick={save}>Save</Button>
    </Form>

  )
})
