import React, { FC } from "react"
import { observer } from "mobx-react"
import { Form, Input, Modal } from "antd"
import { useStore } from "stores/utils/use-store"
import { GameType } from "domains/games/store/types"
import { SubmitCancelButtons } from "components"

type GameModeProps = {
  onClose: () => void;
  editType: "create" | "edit";
  gameId: string;
};

export const GameStatusModal: FC<GameModeProps> = observer(({ onClose, editType, gameId }) => {
  const {
    dataStore: {
      gamesStore,
      gamesStore: {
        selectedGameType,
        selectedGameType: { name, pretyName, id, status, description },
      },
    },
  } = useStore()
  const [form] = Form.useForm()

  const onFinishHandler = async(event: GameType) => {
    const dataToBack = {
      ...selectedGameType,
      name: event.name,
      pretyName: event.pretyName,
      gameId,
      status: event.status,
      description: event.description,
    }

    editType === "edit"
      ? await gamesStore.updateGameStatus(dataToBack)
      : await gamesStore.createGameStatus(dataToBack)
    onClose()
    await gamesStore.getData()
  }

  return (
    <Modal
      style={{ top: "10px" }}
      visible
      title={editType === "edit" ? "Edit game mode" : "Create game mode"}
      footer={null}
      onCancel={onClose}
      width="700px"
    >
      <Form onFinish={onFinishHandler} form={form}>
        <Form.Item
          label="Name"
          name="name"
          initialValue={name}
          rules={[
            {
              required: true,
              message: "Input name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Pretty name"
          name="pretyName"
          initialValue={pretyName}
          rules={[
            {
              required: true,
              message: "Input pretty name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          initialValue={status}
          rules={[
            {
              required: true,
              message: "Input status!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          initialValue={description}
        >
          <Input />
        </Form.Item>
        <SubmitCancelButtons onClose={onClose} isLoading={gamesStore.isLoading} />
      </Form>
    </Modal>
  )
})
