import { Tag } from "antd"

import classes from "./styles.module.css"
import { defaultFormat } from "utils/date"

export const popupsColumns = [
  { title: "Name", dataIndex: "name", key: "name" },
  {
    title: "link",
    dataIndex: "link",
    key: "link",
    render: (link: string) => {
      if (!link) return <Tag>No link</Tag>

      return (
        <Tag
          color="darkcyan"
          style={{
            maxWidth: 600,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {link}
        </Tag>
      )
    },
  },

  {
    title: "Active Until",
    dataIndex: "activeUntil",
    key: "activeUntil",
    render: (time: number) => {
      if (!time) return <Tag>No time</Tag>
      if (Date.now() > new Date(time).valueOf()) return <Tag color="red">Expired!</Tag>

      return <Tag color="blue">{defaultFormat(time)}</Tag>
    },
  },
  {
    title: "Icon",
    key: "img",
    dataIndex: "img",
    render: (img: string) => img ? <img src={img} alt="ico" className={classes.icon} /> : <Tag>No Image</Tag>,
  },

  {
    title: "Countries",
    key: "countries",
    dataIndex: "countries",
    render: e => e && <div>{e.join(",")}</div>,
  },
]
