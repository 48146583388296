import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { Table, Tabs } from "antd"
// import { challengesColumns } from "./columns"
import { Product } from "domains/products/store/types"
import { productsColumns } from "./columns"
import { ProductDetails } from "./ProductDetails"
import { CreateNewElement } from "components"

const { TabPane } = Tabs

export const ProductsList: FC = observer(() => {
  const { dataStore: { productsStore } } = useStore()

  const [activeTab, setActiveTab] = useState("0")
  const [isVisibleInfo, setInfoVisibility] = useState<boolean>(false)

  const [isVisibleCreate, setCreationVisibility] = useState<boolean>(false)

  useEffect(() => {
    productsStore.getData()
  }, [productsStore, activeTab])


  return (
    <Tabs style={{ marginBottom: "20px" }} activeKey={activeTab} onChange={setActiveTab}>
      <TabPane tab="Products" key="0">
        <CreateNewElement
          valueText="Product"
          onClick={() => {
            setCreationVisibility(true)
          }}
        />
        <Table
          dataSource={
            productsStore.activeProducts
          }
          columns={productsColumns}
          rowKey="id"
          loading={productsStore.isLoading}
          onRow={(product: Product) => ({
            onClick: () => {
              productsStore.selectedElement = { ...product }
              setInfoVisibility(true)
            },
          })}
        />
        {isVisibleInfo ? (
          <ProductDetails setInfoVisibility={setInfoVisibility} isVisibleInfo={isVisibleInfo} />
        ) : null}
        {isVisibleCreate ? (
          <ProductDetails setInfoVisibility={setCreationVisibility} isVisibleInfo={isVisibleCreate} newProduct />
        ) : null}
      </TabPane>
    </Tabs>
  )
})
