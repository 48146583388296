import { observer } from "mobx-react"
import { Popconfirm, Space, Table } from "antd"
import { Image } from "antd"
import { DeleteIconButton, EditIconButton } from "components"
import { ColumnsType } from "antd/lib/table"
import { FC } from "react"
import { usePopupTemplatesContext } from "domains/popup-templates/store/context"
import { ResponsiveTemplate, isResponsiveTemplate } from "domains/popup-templates/store/ResponsiveTemplate.store"
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"
import { red, green } from "@ant-design/colors"

type Props = {
  editPopup: (popup: ResponsiveTemplate) => void;
};

export const ResponsivePopupTable: FC<Props> = observer(({ editPopup }) => {
  const popups = usePopupTemplatesContext()
  const { responsiveTemplatesJS } = popups

  const handleEditClick = (id: number) => {
    const mobxPopup = popups.findTemplate(id)
    if (!mobxPopup || !isResponsiveTemplate(mobxPopup)) return
    editPopup(mobxPopup)
  }

  const columns: ColumnsType<ResponsiveTemplate> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 310,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      render: (enabled: boolean) => enabled ? (
        <CheckCircleFilled style={{ color: green[6] }} />
      ) : (
        <CloseCircleFilled style={{ color: red[6] }} />
      ),
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 400,
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Image",
      dataIndex: "img",
      key: "img",
      render: (src: string) => <Image height={90} src={src} />,
      width: 120,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (link: string) => (
        <a href={link} target="_blank" rel="noreferrer">
          {link}
        </a>
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Space>
          <EditIconButton onClick={() => handleEditClick(record.id)} />
          <Popconfirm
            title="Are you sure to delete this template?"
            onConfirm={async() => await popups.removeTemplate(record)}
            okType="danger"
          >
            <DeleteIconButton />
          </Popconfirm>
        </Space>
      ),
      width: 200,
    },
  ]

  return (
    <Table
      loading={popups.isLoading}
      size="middle"
      tableLayout="fixed"
      dataSource={responsiveTemplatesJS}
      columns={columns as any}
      rowKey="id"
    />
  )
})
