import { Form } from "antd"
import { NamePath } from "antd/lib/form/interface"
import { FC, useEffect } from "react"
import SimpleDataInput from "./SimpleDataInput"
import { useForm } from "antd/lib/form/Form"
import { IExternalOfferProvider } from "domains/internal-offerwall/api/types"
import { FormItemInputProps } from "antd/es/form/FormItemInput"

type SimpleDataInputType = "string" | "number" | "boolean" | "select"

interface SimpleDataDefinition {
    type: SimpleDataInputType;
    options?: {title: string, value: any}[]
    label?: string;
    required?: boolean;
}

export interface StructureDefinition {
    id: string;
    definition: {
        [key: string]: SimpleDataDefinition;
    }
}

interface StructuredJsonInputProps extends FormItemInputProps {
    name: NamePath;
    label: string;
    structure: StructureDefinition;
    initialValue?: Record<string, any>;
}

const joinPath = (...paths: NamePath[]): NamePath => {
  return paths.flat()
}

const castInitialValue = (initialValue: Record<string, any>, structure: StructureDefinition["definition"]): Record<string, any> => {
  const casted = {}
  Object.entries(structure).forEach(([key, value]) => {
    const initialValueValue = initialValue[key]
    if (initialValueValue !== undefined) {
      casted[key] = initialValueValue
    } else if (value.type === "string") {
      casted[key] = ""
    } else if (value.type === "number") {
      casted[key] = 0
    } else if (value.type === "boolean") {
      casted[key] = false
    } else if (value.type === "select") {
      casted[key] = value.options?.[0]
    }
  })

  return casted
}

const StructuredJsonInput: FC<StructuredJsonInputProps> = ({
  structure: { id, definition: structure },
  initialValue: _initialValue,
  name,
  label,
  ...props
}) => {
  const [form] = useForm<IExternalOfferProvider>()
  const initialValue = castInitialValue(_initialValue ?? {}, structure)

  useEffect(() => {
    form.setFieldValue(name, { ...initialValue })
  }, [initialValue, name, id])

  return (
    <Form.Item
      {...props}
      label={label}
      noStyle
      style={{ flexDirection: "column" }}
    >
      {label ? <h3 style={{ marginBottom: 10 }}>{label}</h3> : <span>{label}:</span>}
      <div style={{ width: "100%", backgroundColor: "#54545410", padding: 10, display: "flex", flexDirection: "column", gap: 10 }}>
        {Object.entries(structure).map(([key, value]) => {
          return (
            <Form.Item
              key={key}
              name={joinPath(name, key)}
              label={value.label ?? key}
              required={value.required}
              dependencies={[name]}
              style={{ marginBottom: 0 }}
            >
              <SimpleDataInput type={value.type as SimpleDataInputType} options={value.options} />
            </Form.Item>
          )
        },
        )}
      </div>
    </Form.Item>
  )
}

export default StructuredJsonInput
