/* eslint-disable max-len */
import { Button, Form, Tabs, notification } from "antd"
import { SubmitCancelButtons } from "components"
import React, { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { MainInfoTab } from "./contest-list-edit-tabs/MainInfoTab"
import { GamesSetupTab } from "./contest-list-edit-tabs/GamesSetup/GamesSetupTab"
import { PrizesTab } from "./contest-list-edit-tabs/PrizesTab/PrizesTab"
import { ColorsTab } from "./contest-list-edit-tabs/ColorsTab"
import { ImagesTab } from "./contest-list-edit-tabs/ImagesTab/ImagesTab"
import { OnboadringTab } from "./contest-list-edit-tabs/OnboardingTab/OnboardingTab"
import { SchedulingTab } from "./contest-list-edit-tabs/SchedulingTab/SchedulingTab"
import { AnalyticsTab } from "./contest-list-edit-tabs/AnalyticsTab/AnalyticsTab"
import { HighlightSharingTab } from "./contest-list-edit-tabs/HighlightSharingTab/HighlightSharingTab"
import { TranslationTab } from "./contest-list-edit-tabs/TranslationTab/TranslationTab"
import { ChallengesTab } from "./contest-list-edit-tabs/ChallengesTab/ChallengesTab"
import { NotificationsPerGame } from "domains/specialEvents/pages/main"
import { ContestConfig } from "domains/managingJson/store/types"
import { GAME_NOTIFICATIONS } from "domains/managingJson/components/EditContest/IngameNotifications/ingameNotifications"

export const ContestListEdit: FC = () => {

  const {
    dataStore: {
      managingJsonStore: { selectedElement },
      managingJsonStore,
    },
  } = useStore()
  const data = selectedElement.data as ContestConfig
  const name = selectedElement.name

  const [form] = Form.useForm()
  const [selectedGames, setSelectedGames] = useState(data["loungePageData"]?.games)
  const [leaderboardWithCountry, setLeaderboardWithCountry] = useState(data.leaderboardWithCountry ?? false)

  const [onboardingSteps, setOnboardingSteps] = useState(data.onboarding ?? [])

  const [constestLive, setContestLive] = useState(data.contestLiveSchedule ?? [])
  const [contestNotStarted, setContestNotStarted] = useState(data.contestNotStartedSchedule ?? { image: "", text: "", title: "" })
  const [contestFinished, setContestFinished] = useState(data.contestFinishedSchedule ?? { image: "", text: "", title: "", CTAtext: "" })

  const [inAppPixels, setInAppPixels] = useState(data.inAppPixels ?? [])
  const [startgamePixels, setStartgamePixels] = useState(data.startgamePixels ?? [])
  const [endgamePixels, setEndgamePixels] = useState(data.endgamePixels ?? [])

  const [distTypeGrand, setDistTypeGrand] = useState(data.distributionType ?? "EVERY_WEEK")
  const [grandPrizes, setGrandPrizes] = useState(data.grandPrizeRewards ?? {})

  useEffect(() => {
    form.setFieldValue("notificationsPerGame", data.notificationsPerGame)
  }, [data])
  const onClose = () => {
    managingJsonStore.clearSelectedElement()
  }

  useEffect(() => {
    if (!data.challengeBasedContest) {
      data.challengeBasedContest = {
        rightMainPageBanner: {
          imageNotCompleted1State: "",
          imageCompleted1State: "",
          text1State: "",
          title1State: "",
          CTAtext1State: "",
          imageNotCompleteState: "",
          titleNotCompleteState: "",
          textNotCompleteState: "",
          CTAtextNotCompleteState: "",
          imageGrandState: "",
          titleGrandState: "",
          textGrandState: "",
          CTAtextGrandState: "",
        },
        leaderboard: {
          notwin: {
            image: "",
            title: "",
            text: "",
            CTAtext: "",
          },
          win: {
            image: "",
            title: "",
            text: "",
            CTAtext: "",
          },
          right: {
            image: "",
            title: "",
            text: "",
            titleFinished: "",
          },
        },
      }
    }
  }, [data])

  useEffect(() => {
    if (!data.leaderboardBanners) {
      data.leaderboardBanners = {
        rightBannerCopyText: "",
        topBannerCopyText: "",
      }
    }
  }, [data])

  const addCountryTranslations = (
    e: any,
    title: string,
    contest: {
    type: any;
    name: string;
    data: ContestConfig;
}) => {
    const countriesWithGlobal = ["global", ...data.countries ?? []]
    countriesWithGlobal.map(country => {
      contest.data.countryTranslations = contest.data.countryTranslations ?? {}
      contest.data.countryTranslations[country] = contest.data.countryTranslations[country] ?? {}
      contest.data.countryTranslations[country][title] = e[`trans${country} ` + title] ?? data.countryTranslations?.[country]?.leaderboardTitle ?? ""
    })
  }

  const onFinishHandler = async(e: {
    name: string,
    "prettyName": string,
    countries: string[],
    termsAndCondition: string,
    support: string,
    faq: string,
    selectedGames: string[] | undefined,

    neutralBlack: string | undefined,
    neutralInactive: string | undefined,
    neutralRed: string | undefined,
    neutralGray: string | undefined,
    primaryDark: string | undefined,
    primaryDefault: string | undefined,
    primaryLight: string | undefined,
    secondaryClick: string | undefined,
    secondaryDefault: string | undefined,
    secondaryHover: string | undefined,
    textGray: string | undefined,
    textInactive: string | undefined,
    textWhite: string | undefined,
    backgroundMain: string | undefined,
    backgroundSecondary: string | undefined,

    rightCopyText: string | undefined,
    topCopyText: string | undefined,

    weekTextFinish: string | undefined,
    weekTextStart: string | undefined,
    weekTitleFinish: string | undefined,
    weekTitleStart: string | undefined,
    CTAtext: string | undefined,
    gameProducerDisclaimer: string | undefined,

    sharingMessage: string | undefined
    contestPoints: string | undefined
    contestSharingInterval: string | undefined
    watermarkWidth: number | undefined
    transTitle1: string | undefined
    transSubtitle1: string | undefined
    "transCTA text1": string | undefined
    "transTOC text1": string | undefined
    "transContinue Button": string | undefined
    "transJoin Button": string | undefined
    "transTOC text2": string | undefined
    transTitle2: string | undefined
    transSubtitle2: string | undefined
    "transCTA text2": string | undefined
    "transBig Timer Title": string | undefined
    "transSmall Timer Title": string | undefined
    "transGet Started Button": string | undefined
    transSchedule: string | undefined
    transLeaderboard: string | undefined
    "transFree Points": string | undefined
    "transMy Rewards": string | undefined
    "transClaim Button": string | undefined
    "transClaimed Button": string | undefined
    "transSupport Button": string | undefined
    "transFAQ Button": string | undefined
    "transFooter text": string | undefined
    transDate: string | undefined
    transEvent: string | undefined
    transReward: string | undefined
    transTitle3: string | undefined
    transSubtitle3: string | undefined
    microPrize1: string | undefined
    microPrize2: string | undefined
    microPrize3: string | undefined
    microPrize4: string | undefined
    microPrizeGiftCard: string | undefined

    challengeText1: string | undefined
    challengeTitle1: string | undefined
    challengeCTAText1: string | undefined
    titlegrand: string
    textgrand: string
    ctatextgrand: string
    titlenotcomplete: string
    textnotcomplete: string
    ctatextnotcomplete: string
    lefttitlenowin: string
    leftttextnowin: string
    lefttctanowin: string
    lefttitlewin: string
    leftextwin: string
    leftctawin: string
    righttitle: string
    righttext: string
    tightfinishtitle: string
    notificationsPerGame: NotificationsPerGame[]
  }) => {
    managingJsonStore.funcAfterSuccess = async() => {
      notification.success({ message: "Contest updated successfully" })
      await managingJsonStore.getData()
    }

    const contest = { type: "contests" as any, name: name, data: data }
    contest.data["prettyName"] = e.prettyName ?? data.prettyName
    contest.data["termsAndConditionsLink"] = e.termsAndCondition ?? data.termsAndConditionsLink
    contest.data.countries = e.countries
    contest.data["supportLink"] = e.support ?? data.supportLink
    contest.data["faqLink"] = e.faq ?? data.faqLink
    contest.data["loungePageData"] ? contest.data["loungePageData"].games = selectedGames : []
    contest.data["inGameNotificationsRedefine"] = true
    contest.data["notificationsPerGame"] = e.notificationsPerGame ?? data?.notificationsPerGame

    contest.data.colors = {
      primary: {
        default: e.primaryDefault ?? data.colors?.primary.default ?? "#ffffff",
        light: e.primaryLight ?? data.colors?.primary.light ?? "#ffffff",
        dark: e.primaryDark ?? data.colors?.primary.dark ?? "#ffffff",
      },
      secondary: {
        default: e.secondaryDefault ?? data.colors?.secondary.default ?? "#ffffff",
        hover: e.secondaryHover ?? data.colors?.secondary.hover ?? "#ffffff",
        click: e.secondaryClick ?? data.colors?.secondary.click ?? "#ffffff",
      },
      neutral: {
        black: e.neutralBlack ?? data.colors?.neutral.black ?? "#ffffff",
        gray: e.neutralGray ?? data.colors?.neutral.gray ?? "#ffffff",
        inactive: e.neutralInactive ?? data.colors?.neutral.inactive ?? "#ffffff",
        red: e.neutralRed ?? data.colors?.neutral.red ?? "#ffffff",
      },
      text: {
        white: e.textWhite ?? data.colors?.text.white ?? "#ffffff",
        gray: e.textGray ?? data.colors?.text.gray ?? "#ffffff",
        inactive: e.textInactive ?? data.colors?.text.inactive ?? "#ffffff",
      },
      background: {
        main: e.backgroundMain ?? data.colors?.background.main ?? "#ffffff",
        secondary: e.backgroundSecondary ?? data.colors?.background.secondary ?? "#ffffff",
      },
    }

    contest.data.leaderboardBanners
      ? contest.data.leaderboardBanners = {
        rightBannerCopyText: e.rightCopyText ?? data.leaderboardBanners?.rightBannerCopyText ?? "",
        topBannerCopyText: e.topCopyText ?? data.leaderboardBanners?.topBannerCopyText ?? "",
      }: ""

    contest.data["onboarding"] = onboardingSteps
    for (let i = 0; i < onboardingSteps.length; i++) {
      contest.data.onboarding[i].title = e[`Title${i+1}`] ?? contest.data.onboarding[i].title
      contest.data.onboarding[i].text = e[`Text${i+1}`] ?? contest.data.onboarding[i].text
    }

    contest.data["contestNotStartedSchedule"] = {
      title: e.weekTitleStart ?? data.contestNotStartedSchedule?.title ?? "",
      text: e.weekTextStart ?? data.contestNotStartedSchedule?.text ?? "",
      image: contestNotStarted.image ?? data.contestNotStartedSchedule?.image ?? "",
    }
    contest.data["contestFinishedSchedule"] = {
      title: e.weekTitleFinish ?? data.contestFinishedSchedule?.title ?? "",
      text: e.weekTextFinish ?? data.contestFinishedSchedule?.text ?? "",
      image: contestFinished.image ?? data.contestFinishedSchedule?.image ?? "",
      CTAtext: e.CTAtext ?? data.contestFinishedSchedule?.CTAtext ?? "",
    }

    contest.data["contestLiveSchedule"] = constestLive
    contest.data["gameProducerDisclaimer"] = e.gameProducerDisclaimer ?? data.gameProducerDisclaimer ?? ""

    contest.data["endgamePixels"] = endgamePixels
    contest.data["startgamePixels"] = startgamePixels
    contest.data["inAppPixels"] = inAppPixels

    contest.data["contestServerSideConfiguration"] = {
      contestTwitterShareMessage: e.sharingMessage ?? data.contestServerSideConfiguration?.contestTwitterShareMessage ?? "",
      contestRewardCount: e.microPrize4 ? parseInt(e.microPrize4) : data.contestServerSideConfiguration?.contestRewardCount ?? 0,
      contestSharingInterval: e.contestSharingInterval
        ? parseInt(e.contestSharingInterval) : data.contestServerSideConfiguration?.contestSharingInterval ?? 0,
    }
    if (contest.data["freePoints"] !== undefined && contest.data["freePoints"].length !== 0) {
      contest.data["freePoints"][0].watermarkWidth = e.watermarkWidth
        ? e.watermarkWidth : data.freePoints ? data.freePoints[0].watermarkWidth ?? 0 : 0
      contest.data["freePoints"][0].points = e.contestPoints
        ? parseInt(e.contestPoints) : data.freePoints ? data.freePoints[0].points ?? 0 : 0
    }

    addCountryTranslations(e, "leaderboard title", contest)

    contest.data.splashScreen = {
      title: e.transTitle1 ?? data.splashScreen?.title ?? undefined,
      subtitle: e.transSubtitle1 ?? data.splashScreen?.subtitle ?? undefined,
      CTAtext: e["transCTA text1"] ?? data.splashScreen?.CTAtext ?? undefined,
      TOCtext: e["transTOC text1"] ?? data.splashScreen?.TOCtext ?? undefined,
    }
    contest.data.onboardingCTA = {
      continueButton: e["transContinue Button"] ?? data.onboardingCTA?.continueButton ?? undefined,
      joinButton: e["transJoin Button"] ?? data.onboardingCTA?.joinButton ?? undefined,
      TOCtext: e["transTOC text2"] ?? data.onboardingCTA?.TOCtext ?? undefined,
    }
    contest.data.errorStateGlobal = {
      title: e.transTitle2 ?? data.errorStateGlobal?.title ?? undefined,
      subtitle: e.transSubtitle2 ?? data.errorStateGlobal?.subtitle ?? undefined,
      CTAtext: e["transCTA text2"] ?? data.errorStateGlobal?.CTAtext ?? undefined,
    }
    contest.data.schedule = {
      bigTimerTitle: e["transBig Timer Title"] ?? data.schedule?.bigTimerTitle ?? undefined,
      smallTimerTitle: e["transSmall Timer Title"] ?? data.schedule?.smallTimerTitle ?? undefined,
      getStartedButton: e["transGet Started Button"] ?? data.schedule?.getStartedButton ?? undefined,
      tabs: {
        schedule: e.transSchedule ?? data.schedule ? data.schedule?.tabs?.schedule : undefined,
        leaderboard: e.transLeaderboard ?? data.schedule ? data.schedule?.tabs?.leaderboard : undefined,
        freePoints: e["transFree Points"] ?? data.schedule ? data.schedule?.tabs?.freePoints : undefined,
        myRewards: e["transMy Rewards"] ?? data.schedule ? data.schedule?.tabs?.myRewards : undefined,
      },
    }
    contest.data.rewards = {
      claimButton: e["transClaim Button"] ?? data.rewards?.claimButton ?? undefined,
      claimedButton: e["transClaimed Button"] ?? data.rewards?.claimedButton ?? undefined,
      supportButton: e["transSupport Button"] ?? data.rewards?.supportButton ?? undefined,
      faqButton: e["transFAQ Button"] ?? data.rewards?.faqButton ?? undefined,
      footerText: e["transFooter text"] ?? data.rewards?.footerText ?? undefined,
      head: {
        date: e.transDate ?? data.rewards?.head?.date ?? undefined,
        event: e.transEvent ?? data.rewards?.head?.event ?? undefined,
        reward: e.transReward ?? data.rewards?.head?.reward ?? undefined,
      },
      emptyPage: {
        title: e.transTitle3 ?? data.rewards?.emptyPage?.title ?? undefined,
        subtitle: e.transSubtitle3 ?? data.rewards?.emptyPage?.subtitle ?? undefined,
      },
    }

    contest.data["topLadderRewardStrategy"] = {
      rewardText: e.microPrize1 ?? data.topLadderRewardStrategy?.rewardText ?? "",
      transactionName: e.microPrize2 ?? data.topLadderRewardStrategy?.transactionName ?? "",
      buffPointsAmount: e.microPrize3 ? parseInt(e.microPrize3) : data.topLadderRewardStrategy?.buffPointsAmount ?? 0,
      giftCardText: e.microPrizeGiftCard ?? data.topLadderRewardStrategy?.giftCardText ?? "",
      type: data.topLadderRewardStrategy?.type,
    }

    contest.data.distributionType = distTypeGrand
    contest.data["grandPrizeRewards"] = grandPrizes

    contest.data.challengeBasedContest ? contest.data.challengeBasedContest.rightMainPageBanner = {
      titleGrandState: e.titlegrand ?? data.challengeBasedContest?.rightMainPageBanner?.titleGrandState ?? "",
      textGrandState: e.textgrand ?? data.challengeBasedContest?.rightMainPageBanner?.textGrandState ?? "",
      CTAtextGrandState: e.ctatextgrand ?? data.challengeBasedContest?.rightMainPageBanner?.CTAtextGrandState ?? "",
      titleNotCompleteState: e.titlenotcomplete ?? data.challengeBasedContest?.rightMainPageBanner?.titleNotCompleteState ?? "",
      textNotCompleteState: e.textnotcomplete ?? data.challengeBasedContest?.rightMainPageBanner?.textNotCompleteState ?? "",
      CTAtextNotCompleteState: e.ctatextnotcomplete ?? data.challengeBasedContest?.rightMainPageBanner?.CTAtextNotCompleteState ?? "",
      imageNotCompleted1State: data.challengeBasedContest?.rightMainPageBanner?.imageCompleted1State ?? "",
      imageCompleted1State: data.challengeBasedContest?.rightMainPageBanner?.imageCompleted1State ?? "",
      imageGrandState: data.challengeBasedContest?.rightMainPageBanner?.imageGrandState ?? "",
      CTAtext1State: e.challengeCTAText1 ?? data.challengeBasedContest?.rightMainPageBanner?.CTAtext1State ?? "",
      text1State: e.challengeText1 ?? data.challengeBasedContest?.rightMainPageBanner?.text1State ?? "",
      title1State: e.challengeTitle1 ?? data.challengeBasedContest?.rightMainPageBanner?.title1State ?? "",
      imageNotCompleteState: data.challengeBasedContest?.rightMainPageBanner?.imageNotCompleteState ?? "",
    } : ""

    contest.data.challengeBasedContest ? contest.data.challengeBasedContest.leaderboard = {
      notwin: {
        image: data.challengeBasedContest?.leaderboard?.notwin?.image ?? "",
        title: e.lefttitlenowin ?? data.challengeBasedContest?.leaderboard?.notwin?.title ?? "",
        text: e.leftttextnowin ?? data.challengeBasedContest?.leaderboard?.notwin?.text ?? "",
        CTAtext: e.lefttctanowin ?? data.challengeBasedContest?.leaderboard?.notwin?.CTAtext ?? "",
      },
      win: {
        image: data.challengeBasedContest?.leaderboard?.win?.image ?? "",
        title: e.lefttitlewin ?? data.challengeBasedContest?.leaderboard?.win?.title ?? "",
        text: e.leftextwin ?? data.challengeBasedContest?.leaderboard?.win?.text ?? "",
        CTAtext: e.leftctawin ?? data.challengeBasedContest?.leaderboard?.win?.CTAtext ?? "",
      },
      right: {
        image: data.challengeBasedContest?.leaderboard?.right?.image ?? "",
        title: e.righttitle ?? data.challengeBasedContest?.leaderboard?.right?.title ?? "",
        text: e.righttext ?? data.challengeBasedContest?.leaderboard?.right?.text ?? "",
        titleFinished: e.tightfinishtitle ?? data.challengeBasedContest?.leaderboard?.right?.titleFinished ?? "",
      },
    } : ""

    contest.data.challengeBasedContest ? contest.data.challengeBasedContest.leaderboard = {
      notwin: {
        image: data.challengeBasedContest?.leaderboard?.notwin?.image ?? "",
        title: e.lefttitlenowin ?? data.challengeBasedContest?.leaderboard?.notwin?.title ?? "",
        text: e.leftttextnowin ?? data.challengeBasedContest?.leaderboard?.notwin?.text ?? "",
        CTAtext: e.lefttctanowin ?? data.challengeBasedContest?.leaderboard?.notwin?.CTAtext ?? "",
      },
      win: {
        image: data.challengeBasedContest?.leaderboard?.win?.image ?? "",
        title: e.lefttitlewin ?? data.challengeBasedContest?.leaderboard?.win?.title ?? "",
        text: e.leftextwin ?? data.challengeBasedContest?.leaderboard?.win?.text ?? "",
        CTAtext: e.leftctawin ?? data.challengeBasedContest?.leaderboard?.win?.CTAtext ?? "",
      },
      right: {
        image: data.challengeBasedContest?.leaderboard?.right?.image ?? "",
        title: e.righttitle ?? data.challengeBasedContest?.leaderboard?.right?.title ?? "",
        text: e.righttext ?? data.challengeBasedContest?.leaderboard?.right?.text ?? "",
        titleFinished: e.tightfinishtitle ?? data.challengeBasedContest?.leaderboard?.right?.titleFinished ?? "",
      },
    } : ""

    await managingJsonStore.update(contest)
  }

  useEffect(() => {
    const prevValues = form.getFieldValue("notificationsPerGame")

    form.setFieldsValue({
      notificationsPerGame: selectedGames?.map(game => {
        const prevGameValues = prevValues?.find(prevValue => prevValue.gameId === game)

        return {
          gameId: game,
          notifications: GAME_NOTIFICATIONS[game]?.map(supportedNotification => {
            const savedNotification = prevGameValues?.notifications?.find(notification => notification.triggerEventName === supportedNotification)

            const countriesWithGlobal = ["global", ...form.getFieldValue("countries") as string[]]

            const notification = {
              ...savedNotification,
              triggerEventName: supportedNotification,
              sourceImagesByGeo: countriesWithGlobal.map(country => ({ country, imageSources: savedNotification?.sourceImagesByGeo?.find(imageByGeo => imageByGeo.country === country)?.imageSources || [] })),
            }

            return notification
          },
          ),
          stackingWrapperTopOffset: prevGameValues?.stackingWrapperTopOffset || 0,
          stackingWrapperTopOffsetUnit: prevGameValues?.stackingWrapperTopOffsetUnit || "%",
        }
      },
      ),
    })
  }, [selectedGames])

  const onFinishFailedHandler = (e: any) => {
    notification.error({ message: "Failed to update contest" })
    console.log(e)
  }

  return (
    <>
      <div><span style={{ color: "gray" }}>Contests / </span><span>{name}</span></div>
      <div style={{ display: "flex" }}>
        <h1 style={{ marginRight: "auto", fontSize: "20px" }}>Contests</h1>
        <div><SubmitCancelButtons isLoading={managingJsonStore.isLoading} onClose={onClose} formId="contestEditForm" /></div>
        <div style={{ marginLeft: "16px", display: "flex", alignItems: "center" }}>
          <Button
            onClick={e => {
              e.stopPropagation()
              const url = window.URL.createObjectURL(new Blob([JSON.stringify(managingJsonStore.selectedElement)]))
              const link = document.createElement("a")
              link.href = url
              link.setAttribute("download", "data.json")
              document.body.appendChild(link)
              link.click()
              link.parentNode?.removeChild(link)
            }}
          >Extract JSON
          </Button>
        </div>
      </div>
      <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} form={form} id="contestEditForm">
        <Tabs>
          <Tabs.TabPane tab="Main Info" key="1">
            <MainInfoTab form={form} setLeaderboardWithCountry={setLeaderboardWithCountry} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Games setup" key="2">
            <GamesSetupTab form={form} selectedGames={selectedGames} setSelectedGames={setSelectedGames} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Colors" key="3">
            <ColorsTab form={form} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Images" key="4">
            <ImagesTab form={form} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Onboarding" key="5">
            <OnboadringTab form={form} onboardingSteps={onboardingSteps} setOnboardingSteps={setOnboardingSteps} />
          </Tabs.TabPane>
          {data.contestType === "SCORE_BASED" ? (
            <Tabs.TabPane tab="Scheduling" key="6">
              <SchedulingTab
                form={form}
                constestLive={constestLive}
                setContestLive={setContestLive}
                notStarted={contestNotStarted}
                setNotStarted={setContestNotStarted}
                finished={contestFinished}
                setFinished={setContestFinished}
              />
            </Tabs.TabPane>
          ) : (
            <Tabs.TabPane tab="Challenges data" key="6">
              <ChallengesTab form={form} />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Analytics Impressions" key="7">
            <AnalyticsTab
              form={form}
              startgame={startgamePixels}
              endgame={endgamePixels}
              inApp={inAppPixels}
              setStartgame={setStartgamePixels}
              setEndgame={setEndgamePixels}
              setInApp={setInAppPixels}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Highlights Sharing" key="8">
            <HighlightSharingTab form={form} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Translation" key="9">
            <TranslationTab form={form} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Prizes" key="10">
            <PrizesTab
              form={form}
              distTypeGrand={distTypeGrand}
              setDistTypeGrand={setDistTypeGrand}
              grandPrizes={grandPrizes}
              setGrandPrizes={setGrandPrizes}
            />
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </>
  )
}
