import { BaseStore } from "stores/base.store"
import { RootStore } from "stores/root.store"
import { MarketplaceCategory, CategoryToUpdate } from "./types"
import { computed, observable, makeObservable, transaction } from "mobx"
import {
  getMarketplaceCategories,
  updateMarketplaceCategory,
  createMarketplaceCategory,
  deleteMarketplaceCategory,
} from "../api"

export class MarketplaceCategoriesStore extends BaseStore {
  @observable
  private _categories: MarketplaceCategory[] = []

  @observable
  private _selectedElement: MarketplaceCategory = {} as MarketplaceCategory

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getData() {
    this.resetFuncAfterSuccess()
    const res = await this.defaultFunction(getMarketplaceCategories)

    transaction(() => {
      this._categories = res
    })

    return res
  }
  @computed
  get categories(): MarketplaceCategory[] {
    return this._categories
  }

  async update(data: CategoryToUpdate) {
    await this.defaultFunction(updateMarketplaceCategory, this._selectedElement.id, data)
  }

  async create(data: CategoryToUpdate) {
    await this.defaultFunction(createMarketplaceCategory, data)
  }

  async delete(id: string) {
    await deleteMarketplaceCategory(id)
    await this.getData()
  }

  @computed
  get selectedElement(): MarketplaceCategory {
    return this._selectedElement
  }

  set selectedElement(value: MarketplaceCategory) {
    this._selectedElement = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as MarketplaceCategory
  }
}
