import { apiCall } from "api-calls/api-call"

class BulkRewardsApi {
  async getUploadUrl() {
    return apiCall({
      method: "GET",
      path: "files/get-csv-signed-url",
    }).then(response => response as {
            putUrl: string,
            fileData: {
                bucket: string,
                key: string,
                region: string,
            }
        })
  }

  async uploadCSV(file: File, putUrl: string) {
    return fetch(putUrl, {
      method: "PUT",
      body: file,
    })
  }

  async sendBulkRewards(data: {
        file: {
                bucket: string,
                key: string,
                region: string
            }, codeInfoId: string}) {
    return apiCall({
      method: "POST",
      path: "rewards/files/push-rewards-from-csv/code-storage",
      body: data,
    }).then(response => response as {
                published: number,
                failed: {accountId: string, message: string, error: any}[]
            })
  }

  async sendBulkRewardBuffs(data: {
    file: {
            bucket: string,
            key: string,
            region: string
        }, transactionType: string, transactionName: string, transactionAmount: number}) {
    return apiCall({
      method: "POST",
      path: "rewards/files/push-rewards-from-csv/buff-points",
      body: data,
    }).then(response => response as {
            published: number,
            failed: {accountId: string, message: string, error: any}[]
        })
  }
}

export const bulkRewardsApi = new BulkRewardsApi()
