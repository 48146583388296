import React from "react"
import { observer } from "mobx-react"
import s from "./styles.module.css"

interface IMobileView {
  uploadText: string;
}

function splitByString(source: string, splitBy: string) {
  const splitter: any[] = splitBy.split("")
  splitter.push([source])

  return splitter.reduceRight(function(accumulator: string[], curValue: string) {
    let k: string[] = []
    accumulator.forEach((v: string) => (k = [...k, ...v.split(curValue)]))

    k = k.filter((v: string) => v.length !== 0)

    return k
  })
}


const MobileView: React.FC<IMobileView> = ({ uploadText }) => {
  const arr: string[] = splitByString(uploadText, "**")

  return (
    <div className={s.containerPhone}>
      <div className={s.phone}>
        {arr?.map((text: string) => {
          if (text.includes("green-text")) {
            const textR = text.replace("green-text!", "")

            return (
              textR.length > 0 && (
                <span className={s.text} style={{ color: "#C8FF88" }}>
                  {text.replace("green-text!", "")}
                </span>
              )
            )
          }
          if (text.includes("pink-text")) {
            const textR = text.replace("green-text!", "")

            return (
              textR.length > 0 && (
                <span className={s.text} style={{ color: "#FF83EB" }}>
                  {text.replace("green-text!", "")}
                </span>
              )
            )
          }
          if (text.includes("gray-text")) {
            const textR = text.replace("gray-text!", "")

            return (
              textR.length > 0 && (
                <span className={s.text} style={{ color: "#ADADAD" }}>
                  {text.replace("gray-text!", "")}
                </span>
              )
            )
          }

          if (text.includes("white-text")) {
            const textR = text.replace("white-text!", "")

            return (
              textR.length > 0 && (
                <span className={s.text} style={{ color: "#EAEAEA" }}>
                  {text.replace("white-text!", "")}
                </span>
              )
            )
          }
          if (text.includes("wrap-new-line")) return <br />
        })}
      </div>
    </div>
  )
}

export default observer(MobileView)
