/* eslint-disable max-len */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd"
import Styles from "./styles.module.css"
import { PixelsEditorV2 } from "components/PixelsEditorV2"
import {
  CopyOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons"
import { SourceImagesEditor } from "../sourceImagesEditor"
import { AnimationEditor } from "../animationEditor"
import { gameNames } from "abstract/constants/game-ids"
import { NotificationNameDisplay } from "./components/NotificationNameDisplay"
import { Notification } from "domains/specialEvents/pages/main"
import { getCountryNameById } from "utils/countryCodes"
import CodeEditor from "@uiw/react-textarea-code-editor"

type Props = {
  parentIndex: number;
  contestMode?: boolean;
};

export const NotificationEditor = ({ parentIndex, contestMode }: Props) => {
  const form = Form.useFormInstance()

  return (
    <Form.List name={[parentIndex, "notifications"]}>
      {(fields, { add, remove }) => {
        const duplicate = (index: number) => {
          const notification: Notification = form.getFieldValue([
            "notificationsPerGame",
            parentIndex,
            "notifications",
            index,
          ])

          add({ ...notification })
        }

        const addWithSourceImageGeos = () => {
          const countries = form.getFieldValue(["restrictedToCountries"]) || []
          const countriesWithGlobal = ["global", ...countries]
          const notificationWithSourceImagesWithGeos = { sourceImagesByGeo: countriesWithGlobal.map(country => ({ country, imageSources: [] })) }
          add(notificationWithSourceImagesWithGeos)
        }

        return (
          <Space direction="vertical" size="large" className={Styles.mainSpace}>
            {fields.map(({ key, name: notificationIndex, ...restFields }) => {
              const ingameOverlayType = form.getFieldValue([
                "notificationsPerGame",
                parentIndex,
                "notifications",
                notificationIndex,
                "ingameOverlayType",
              ])
              const defaultCSS = ingameOverlayType === "generic" ? undefined : JSON.stringify({
                width: "15%",
                minWidth: 150,
                maxWidth: 450,
              }, null, 2)

              return (
                <div key={key} className={Styles.wrapper}>
                  <h3 className={Styles.title}>
                    <Form.Item
                      {...restFields}
                      name={[notificationIndex, "triggerEventName"]}
                      noStyle
                    >
                      <NotificationNameDisplay
                        index={notificationIndex}
                        parentIndex={parentIndex}
                      />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      {...restFields}
                      name={[notificationIndex, "enabled"]}
                      valuePropName="checked"
                      initialValue={true}
                    >
                      <Switch />
                    </Form.Item>
                  </h3>
                  <Row>
                    <Col span={8}>
                      <Card size="small" className={Styles.fullHeightCard} title="Main">
                        <Row gutter={12}>
                          {!contestMode && (
                            <Col span={24}>
                              <Tooltip title="If checked, the notification will ignore the game's spawn cap.">
                                <Form.Item
                                  {...restFields}
                                  name={[notificationIndex, "ignoreSpawnCap"]}
                                  valuePropName="checked"
                                  style={{ width: 150 }}
                                >
                                  <Checkbox>Ignore Spawn Cap</Checkbox>
                                </Form.Item>
                              </Tooltip>
                            </Col>
                          )}

                          <Col span={24}>
                            <Form.Item
                              {...restFields}
                              name={[notificationIndex, "ingameOverlayType"]}
                              label="Ingame Overlay Type"
                              tooltip={
                                <div>
                                The type of the ingame overlay that will be used for the notification.
                                  <br />
                                  <br />
                                  <b>Stacking:</b> Notifications will be displayed on the left side of the screen, and will stack on top of each other.
                                  <br />
                                  <b>Generic:</b> The notification will be displayed as set in Custom styles textarea below.
                                </div>
                              }
                            >
                              <Select style={{ width: "100%" }} defaultValue="stacking">
                                <Select.Option value="stacking">Stacking</Select.Option>
                                <Select.Option value="generic">Generic</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              {...restFields}
                              name={[notificationIndex, "triggerEventName"]}
                              label="Event"
                              tooltip={contestMode ? "Not editable in contest - contest has predefined supported events" : "The name of the event that triggers the notification."}
                            >
                              <Input disabled={contestMode} />
                            </Form.Item>
                          </Col>
                          {!contestMode && (
                            <Col flex="250px">
                              <Form.Item
                                {...restFields}
                                name={[notificationIndex, "spawnProbability"]}
                                label="Spawn Probability (0 - 1)"
                                tooltip="The probability of the notification being triggered. 0 = never, 1 = always."
                              >
                                <InputNumber min={0} max={1} step={0.01} style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          )}
                          {contestMode && (
                            <Col flex="240px">
                              <Form.Item
                                {...restFields}
                                name={[notificationIndex, "contestPointsReward"]}
                                label="Contest points reward"
                                tooltip="The amount of contest points user will receive for seeing this notification"
                              >
                                <InputNumber min={0} step={0.1} style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          )}
                          {contestMode && (
                            <Col span={24}>
                              <Form.Item
                                {...restFields}
                                name={[notificationIndex, "endgameDisplayTitle"]}
                                label="Endgame Display Title"
                                tooltip="If empty, this reward won't be displayed in the endgame screen"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          )}
                          {!contestMode && (
                            <Col flex="180px">
                              <Form.Item
                                {...restFields}
                                name={[notificationIndex, "buffsReward"]}
                                label="Buffs Reward"
                                tooltip="The number of buffs the player receives when the notification is triggered."
                              >
                                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          )}
                          <Col flex='210px'>
                            <Form.Item
                              {...restFields}
                              name={[notificationIndex, "duration"]}
                              label="Duration (seconds)"
                              tooltip="The duration of the notification in seconds."
                            >
                              <InputNumber min={0} step={1} style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col flex='240px'>
                            <Form.Item
                              {...restFields}
                              name={[notificationIndex, "triggerDelay"]}
                              label="Trigger Delay (seconds)"
                              tooltip="The delay between the event and the notification being triggered."
                            >
                              <InputNumber min={0} step={0.1} style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Custom styles"
                              name={[notificationIndex, "customStyles"]}
                              rules={[{
                                message: "Invalid JSON",
                                validator: async(_, value) => {
                                  if (!value) return Promise.resolve()
                                  try {
                                    JSON.parse(value)

                                    return Promise.resolve()
                                  } catch (error) {
                                    return Promise.reject(new Error("Invalid JSON"))
                                  }
                                },
                              }]}
                              initialValue={defaultCSS}
                            >
                              <CodeEditor
                                language="JSON"
                                padding={15}
                                placeholder={"{ \"position\": \"absolute\",\"left\": 0, \"maxWidth\": 300}"}
                                style={{
                                  backgroundColor: "#f5f5f5",
                                  fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        size="small"
                        className={Styles.fullHeightCard}
                        title={
                          <div className={Styles.cardHeadingWithTooltip}>
                            Images
                            <Tooltip title="If multiple images are present, one of them will be randomly chosen each time event is triggered">
                              <QuestionCircleOutlined className={Styles.icon} />
                            </Tooltip>
                          </div>
                        }
                      >
                        <Form.List name={[notificationIndex, "sourceImagesByGeo"]}>
                          {fields => {
                            const items: TabsProps["items"] = fields.map(({ name: countryIndex }) => {
                              const country = form.getFieldValue([
                                "notificationsPerGame",
                                parentIndex,
                                "notifications",
                                notificationIndex,
                                "sourceImagesByGeo",
                                countryIndex,
                                "country",
                              ])

                              const getTooltipTitle = () => {
                                if (country === "global") {
                                  return "Global images are used when no specific country images are available."
                                }

                                return getCountryNameById(country)
                              }

                              return { label: <Tooltip title={getTooltipTitle()}>{country}</Tooltip>, key: country, children: <SourceImagesEditor key={countryIndex} notificationIndex={notificationIndex} notificationsPerGameArrayIndex={parentIndex} countryIndex={countryIndex} /> }
                            })

                            return (
                              <div>
                                <Tabs items={items} />
                              </div>
                            )
                          }}
                        </Form.List>
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card size="small" className={Styles.fullHeightCard} title="Tracking Pixels">
                        <Form.Item {...restFields} name={[notificationIndex, "trackingPixels"]}>
                          <PixelsEditorV2
                            pixels={
                              form.getFieldValue([
                                "notificationsPerGame",
                                parentIndex,
                                "notifications",
                                notificationIndex,
                                "trackingPixels",
                              ]) ?? []
                            }
                            onChange={pixels => {
                              const updatedValues = {}
                              updatedValues[
                                `notificationsPerGame[${parentIndex}].notifications[${notificationIndex}].trackingPixels`
                              ] = pixels
                              form.setFieldsValue({ notificationsPerGame: { [parentIndex]: { notifications: { [notificationIndex]: { trackingPixels: pixels } } } } })
                            }}
                          />
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>
                      <AnimationEditor
                        notificationsPerGameIndex={notificationIndex}
                        parentIndex={notificationIndex}
                      />
                    </Col>
                    {!contestMode && (
                      <Col span={8}>
                        <Card title="Actions">
                          <Space>
                            <Popconfirm
                              title={`Are you sure you want to delete this notification (event "${form.getFieldValue(
                                [
                                  "notificationsPerGame",
                                  parentIndex,
                                  "notifications",
                                  notificationIndex,
                                  "triggerEventName",
                                ],
                              )}" for game ${
                                gameNames[
                                  form.getFieldValue(["notificationsPerGame", parentIndex, "gameId"])
                                ]
                              })?`}
                              onConfirm={() => remove(notificationIndex)}
                              okText="Delete"
                              cancelText="Cancel"
                            >
                              <Button type="primary" danger icon={<DeleteOutlined />}>
                              Delete
                              </Button>
                            </Popconfirm>
                            <Button
                              type="primary"
                              icon={<CopyOutlined />}
                              onClick={() => duplicate(notificationIndex)}
                            >
                            Duplicate
                            </Button>
                          </Space>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </div>
              )
            })}
            {!contestMode && (
              <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => addWithSourceImageGeos()}>
                Add new{" "}
                {gameNames[form.getFieldValue(["notificationsPerGame", parentIndex, "gameId"])]}{" "}
                notification
              </Button>
            )}
          </Space>
        )
      }}
    </Form.List>
  )
}
