import { Tabs } from "antd"
import { PopupTemplatesContext } from "../store/context"
import { PopupTemplatesStore } from "../store/popupTemplates.store"
import { ResponsiveTemplatesTable } from "../tabs/ResponsiveTemplates"
import { StyledTemplatesTable } from "../tabs/StyledTemplates"

export const OptimovePopups = () => (
  <PopupTemplatesContext.Provider value={new PopupTemplatesStore()}>
    <Tabs>
      <Tabs.TabPane tab="Responsive Popups" key="1">
        <ResponsiveTemplatesTable />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Styled Popups" key="2">
        <StyledTemplatesTable />
      </Tabs.TabPane>
    </Tabs>
  </PopupTemplatesContext.Provider>
)
