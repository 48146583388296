import { Form, Space } from "antd"
import Styles from "./styles.module.css"

import { GameCard } from "../gameCard"

type Props = {
  contestMode?: boolean
}

export const GamesEditor = ({ contestMode }: Props) => {
  return (
    <Form.List name="notificationsPerGame">
      {fields => (
        <Space direction="vertical" size="large" className={Styles.wrapper}>
          {fields.map(({ key, name: index }) => (
            <GameCard key={key} index={index} contestMode={contestMode} />
          ))}
        </Space>
      )}
    </Form.List>
  )
}
