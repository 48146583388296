import { FC } from "react"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { LoadingOutlined } from "@ant-design/icons"
import { Collapse } from "antd"

export const ItemInfo: FC<{ type: "find" }> = observer(() => {
  const { dataStore: { itemProviderStore: { isLoading, itemInfo } } } = useStore()

  if (isLoading) return <LoadingOutlined style={{ padding: "25px" }} />

  if (itemInfo) {
    const {
      status: { error, message },
      provider: { balance },
      item,
    } = itemInfo

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "2px red solid",
            borderRadius: "5px",
            padding: "5px",
            margin: "5px 0 0 0 ",
            backgroundColor: "pink",
          }}
        >
          <div style={{ textAlign: "center", padding: "5px" }}>
            Server has returned result with error status
          </div>
          <div>
            <span style={{ fontSize: "14px" }}>Message:</span>
            <span style={{ fontSize: "16px", textDecoration: "underline", marginLeft: "5px" }}>
              {message}
            </span>
          </div>
          <div>
            <span style={{ fontSize: "14px" }}>Balance:</span>
            <span style={{ fontSize: "16px", textDecoration: "underline", marginLeft: "5px" }}>
              {balance}
            </span>
          </div>
        </div>
      )
    }

    const { id, meta, name, price } = item

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "2px orange solid",
          borderRadius: "5px",
          padding: "5px",
          margin: "5px 0 0 0 ",
          backgroundColor: "rgb(255,255,153)",
        }}
      >
        <div style={{ textAlign: "center", padding: "5px" }}>
          Success response from item-provider
        </div>
        <div>
          <span style={{ fontSize: "14px", marginLeft: "10px" }}>Item provider balance:</span>
          <span style={{ fontSize: "16px", textDecoration: "underline", marginLeft: "5px" }}>
            {balance}
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
            <div>
              Item Id: <span style={{ fontSize: "16px", textDecoration: "underline" }}>{id}</span>
            </div>
            <div style={{ margin: "0 5px 0 5px" }} />
            <div>
              Item price:{" "}
              <span style={{ fontSize: "16px", textDecoration: "underline" }}>{price}</span>
            </div>
          </div>
          <div style={{ marginLeft: "10px" }}>
            Item title:
            <span style={{ fontSize: "16px", textDecoration: "underline", marginLeft: "10px" }}>
              {name}
            </span>
          </div>
          <div>
            <Collapse>
              <Collapse.Panel header="meta" key="0" style={{ backgroundColor: "gold" }}>
                <Collapse>
                  {Object.keys(meta as any).map(key => {
                    return (
                      <Collapse.Panel header={key} key={key}>
                        {JSON.stringify(meta[key])}
                      </Collapse.Panel>
                    )
                  })}
                </Collapse>
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>
      </div>
    )
  }

  return <div />
})
