import { GamePlatform, GameMode, GameType } from "domains/games/store/types"

export const gamesColumns = [
  {
    title: "Name",
    dataIndex: "pretyName",
    key: "pretyName",
  },
  {
    title: "Game Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Game modes",
    dataIndex: "gameModes",
    key: "gameModes",
    render: (gameModes: GameMode[]) => (
      <ul style={{ listStyle: "none" }}>
        {gameModes.map(item => (
          <li key={item.id}>{item.pretyName}</li>
        ))}
      </ul>
    ),
  },
  {
    title: "Game status",
    dataIndex: "gameTypes",
    key: "gameTypes",
    render: (gameTypes: GameType[]) => (
      <ul style={{ listStyle: "none" }}>
        {gameTypes
          .filter(item => item.isActive)
          .map(item => (
            <li
              key={item.id}
              style={
                item.isActive
                  ? {
                    color:
                        item.status === "ONLINE"
                          ? "green"
                          : item.status === "OFFLINE"
                            ? "red"
                            : "orange",
                  }
                  : {}
              }
            >
              {item.pretyName}
            </li>
          ))}
      </ul>
    ),
  },
  {
    title: "Game identifier",
    dataIndex: "gameIdentifier",
    key: "gameIdentifier",
  },
  {
    title: "Picture",
    dataIndex: "pictureUrl",
    key: "pictureUrl",
    render: (value: string) => <img src={value} alt="" style={{ backgroundColor: "green" }} />,
  },
  {
    title: "Game platform",
    dataIndex: "gamePlatform",
    key: "gamePlatform",
    render: (item: GamePlatform) => item.name,
  },
]
