import { computed, observable, transaction, makeObservable } from "mobx"
import {
  GetItemsFilter,
  ParentToUpdate,
  ParentCodeType,
  ParentPrize,
} from "domains/promocodes/store/types"
import {
  createItem,
  deleteItem,
  getParentCodes,
  updateItem,
  getParentPrizes,
} from "domains/promocodes/api/parent-codes.api"

import { PaginationStore } from "stores/data/pagination.store"
import { RootStore } from "stores/root.store"

export class ParentPromocodesStore extends PaginationStore<ParentCodeType, GetItemsFilter> {
  @observable
  private _itemTypes: ParentCodeType[] = [] as ParentCodeType[]

  @observable
  private _selectedElement: ParentCodeType = {} as ParentCodeType

  @observable
  private _parentPrizes: ParentPrize[] = [] as ParentPrize[]

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<ParentCodeType[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getParentCodes, this.filter, page, limit)

    transaction(() => {
      this._itemTypes = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: ParentToUpdate) {
    await this.defaultFunction(updateItem, this._selectedElement.id, data)
  }

  async create(data: ParentToUpdate) {
    await this.defaultFunction(createItem, data)
  }

  async deleteById(id: string) {
    await this.defaultFunction(deleteItem, id)
  }

  async getParentPrizes(): Promise<ParentPrize[]> {
    this.resetFuncAfterSuccess()
    const res = await this.defaultFunction(getParentPrizes)
    transaction(() => {
      this._parentPrizes = res.data
    })

    return res.data
  }

  @computed
  get itemTypes(): ParentCodeType[] {
    return this._itemTypes
  }

  @computed
  get selectedElement(): ParentCodeType {
    return this._selectedElement
  }

  set selectedElement(value: ParentCodeType) {
    this._selectedElement = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as ParentCodeType
  }

  @computed
  get parentPrizes(): ParentPrize[] {
    return this._parentPrizes
  }
}
