import { FC, useMemo, useState } from "react"

import { Button, Layout, Menu, Modal, Tag } from "antd"
import "antd/dist/antd.css"
import { Content, Footer } from "antd/es/layout/layout"
import Sider from "antd/es/layout/Sider"
import { ItemType } from "antd/es/menu/hooks/useItems"
import { observer } from "mobx-react"
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./App.css"
import { LoginForm } from "./components/login"
import { Config } from "./config/config"
import { useConfigJson } from "./config/use-config-json.hook"
import { routes } from "./routes/routes"
import { useStore } from "./stores/utils/use-store"
import { UploadComponent } from "components"
import { notifications } from "notifications"

const App: FC = observer(() => {
  const configData = useConfigJson()

  type ItemsType = ItemType & { link: string };
  const items = useMemo<ItemsType[]>(() => {
    return routes
      .filter(el => el.name)
      .map(el => ({
        key: el.name || el.link,
        link: el.link,
        title: el.name,
        icon: el.icon,
        label: el.children ? (
          <span className="ant-menu-title-content">
            <a>{el.name}</a>
          </span>
        ) : (
          <Link to={el.link}>{el.name}</Link>
        ),
        children: el.children?.filter(el => el.name).map(el1 => ({
          key: el1.name || el1.link,
          title: el1.name,
          icon: el1.icon,
          label: <Link to={el1.link}>{el1.name}</Link>,
        })),
      }))
  }, [])

  if (configData) {
    Config.init(configData)
  }
  const location = useLocation()

  const currentRoute = items.find(r => location.pathname.startsWith(String(r?.link)))
  const [isVisibleImage, setIsVisibleImage] = useState<boolean>(false)
  const [image, setImage] = useState("")

  let subLink = ""
  routes.find(item => {
    item.children?.find(r => {
      if (r.link === location.pathname) {
        subLink = r.name || ""
      }
    })
  })

  const selectedMenuItem = subLink ? [subLink] : currentRoute ? [String(currentRoute.key)] : []
  const selectedOpenKeys = currentRoute ? [String(currentRoute.key)] : []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collapsed, setCollapsedState] = useState<boolean>(false)
  const { dataStore: { authStore } } = useStore()

  if (!configData) {
    return <h1>Loading configuration ...</h1>
  }

  if (!authStore.token) {
    return <LoginForm />
  }

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider>
          <Menu
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={items}
            className="mainMenu"
            defaultSelectedKeys={selectedMenuItem}
            defaultOpenKeys={selectedOpenKeys}
          />
          <Button
            style={{ width: "200px", marginTop: "20px", color: "white", textAlign: "left" }}
            onClick={() => setIsVisibleImage(true)}
            type="text"
          >
            File Upload
          </Button>
          <div
            style={{
              marginTop: "20px",
              marginLeft: "15px",
            }}
          >
            {
              process.env.REACT_APP_BASE_URL === "https://api.admin.core-tst.app.buff.game"
                ? <Tag color="#cd201f">DEVELOPMENT</Tag>
                : <Tag color="#00962a">PRODUCTION</Tag>
            }
          </div>
          <Modal
            visible={isVisibleImage}
            footer={null}
            onCancel={() => {
              setIsVisibleImage(false)
              setImage("")
            }}
          >
            <UploadComponent image={image} setImage={setImage} placeholder=" " />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(image)
                notifications.success("Copied!")
              }}
              type="primary"
            >
              Copy
            </Button>
          </Modal>
        </Sider>
        <Layout className="site-layout">
          <Content style={{ margin: "5px 16px 0" }}>
            <Routes>
              {routes.map((el, idx) => el.children
                ? el.children.map(item => item.component && (
                  <Route path={item.link} key={idx} element={<item.component />} />
                ))
                : el.component && <Route path={el.link} key={idx} element={<el.component />} />)}
              <Route path="/" element={<Navigate to="/accounts/list" replace />} />
            </Routes>
          </Content>
          <Footer style={{ textAlign: "center", padding: "0", paddingBottom: "10px" }}>
            Buff Team 2023, Admin Web version: {Config.version}
          </Footer>
        </Layout>
      </Layout>
      <ToastContainer className="toast-container" progressClassName="toast-progress-line" />
    </>
  )
})

export default App
