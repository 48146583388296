import { observable } from "mobx"
import { notifications } from "notifications"
import { RootStore } from "./root.store"

export class BaseStore {
  protected rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
  public isLoading: boolean

  @observable
  public funcAfterSuccess: any = null

  public resetFuncAfterSuccess() {
    this.funcAfterSuccess = null
  }

  public showErrorNotifications(result: any): boolean {
    if (result?.error || result?.message) {
      if (Array.isArray(result?.message)) {
        result?.message?.map((item: string) => notifications.error(item))
      } else {
        notifications.error(result?.message)
      }

      return true
    }

    return false
  }

  public async defaultFunction<Out = any, Args extends Array<any> = Array<any>>(
    func: (...data: Args) => Promise<Out>, ...args: Args): Promise<Out> {
    this.isLoading = true
    try {
      const res = await func(...args)
      const showed = this.showErrorNotifications(res)
      if (showed) {
        return res
      }
      if (this.funcAfterSuccess) {
        this.funcAfterSuccess()
      }

      return res
    } catch (err: any) {
      this.showErrorNotifications(err)

      return err
    } finally {
      this.isLoading = false
    }
  }
}
