import { useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { Button, Form, Typography } from "antd"
import { observer } from "mobx-react"
import { notifications } from "notifications"
import { MobileConfigType } from "domains/managingJson/store/types"
import TextArea from "antd/lib/input/TextArea"
const { Title } = Typography

export const JSONEditor = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()
  const [jsonData, setJsonData] = useState<MobileConfigType | undefined>()
  const [form] = Form.useForm()
  const [jsonString, setJsonString] = useState<string>()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    setJsonString(JSON.stringify(jsonData?.variants, null, 4))
  }, [jsonData])

  useEffect(() => {
    const mobileConfigData = managingJsonStore?.itemTypes?.filter(item => item.type === "mobileConfig")
    if (mobileConfigData && mobileConfigData.length > 0) {
      const data = mobileConfigData[0].data as MobileConfigType
      setJsonData(data)
    }
  }, [managingJsonStore.itemTypes])

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  const onFinishHandler = async() => {
    try {
      setError(false)
      if (!jsonString) throw new Error("missing json string")
      const variants: MobileConfigType["variants"] = JSON.parse(jsonString)
      managingJsonStore.funcAfterSuccess = async() => {
        notifications.success("Saved successfully")
        await managingJsonStore.getData()
      }
      jsonData
        ? await managingJsonStore.update({
          name: "mobileConfig",
          type: "mobileConfig",
          data: { variants },
        })
        : await managingJsonStore.create({
          name: "mobileConfig",
          type: "mobileConfig",
          data: { variants },
        })
    } catch (error) {
      notifications.error("Invalid JSON syntax")
      setError(true)

      return
    }
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <Title level={2}>Mobile Config</Title>
      <div style={{ display: "flex", flexDirection: "column", gap: 8, marginBottom: 32 }}>
        <TextArea
          status={error ? "error" : undefined}
          autoSize={{ minRows: 10 }}
          value={jsonString}
          onChange={e => {
            setJsonString(e.target.value)
          }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 480 }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            cursor: managingJsonStore.isLoading ? "not-allowed" : "auto",
            backgroundColor: managingJsonStore.isLoading ? "#d9d9d9" : "#1690ff",
            borderColor: managingJsonStore.isLoading ? "#d9d9d9" : "#1690ff",
          }}
          disabled={managingJsonStore.isLoading}
        >
          Save
        </Button>
      </div>
    </Form>
  )
})
