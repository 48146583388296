import { Button, Space, Tabs, Tag, Typography } from "antd"
import { MobileConfigType, MobileConfigVariant } from "domains/managingJson/store/types"
import { observer } from "mobx-react-lite"
import { notifications } from "notifications"
import { useCallback, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { VariantEditor } from "./VariantEditor"
import { toJS } from "mobx"
import equal from "fast-deep-equal"
const { Title, Text } = Typography

export const UIEditor = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()
  const [data, setData] = useState<MobileConfigType | undefined>()
  const [isVariantInvalid, setIsVariantInvalid] = useState<boolean[]>([])

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const mobileConfigData = managingJsonStore?.itemTypes?.filter(item => item.type === "mobileConfig")
    if (mobileConfigData && mobileConfigData.length > 0) {
      const data = mobileConfigData[0].data as MobileConfigType
      setData(data)
    }
  }, [managingJsonStore.itemTypes])

  const getStoreData = (): MobileConfigType | undefined => {
    const mobileConfigData = managingJsonStore?.itemTypes?.filter(item => item.type === "mobileConfig")

    return mobileConfigData[0]?.data as MobileConfigType
  }

  const updateStore = async(callback: (prev: MobileConfigType | undefined) => MobileConfigType) => {
    const previousData = getStoreData()
    const newData = callback(previousData)
    previousData
      ? await managingJsonStore.update({
        name: "mobileConfig",
        type: "mobileConfig",
        data: newData,
      })
      : await managingJsonStore.create({
        name: "mobileConfig",
        type: "mobileConfig",
        data: newData,
      })
  }

  const setVariantData = (
    callback: (prev: MobileConfigVariant) => MobileConfigVariant,
    index: number,
  ) => {
    setData(prev => {
      const prevVariant = prev ? prev?.variants?.[index] : undefined
      if (!prevVariant) return
      const newVariant = callback(prevVariant)
      const variants = [...(prev?.variants ?? [])]
      variants[index] = newVariant

      return { variants }
    })
  }

  const saveAll = async() => {
    managingJsonStore.funcAfterSuccess = async() => {
      notifications.success("Saved successfully")
      await managingJsonStore.getData()
    }
    await updateStore(() => {
      return { variants: data?.variants }
    })
  }

  const deleteVariant = async(index: number) => {
    setData(prev => {
      const newVariants = prev?.variants?.filter((_, variantIndex) => variantIndex !== index)

      return { ...prev, variants: newVariants ?? [] }
    })
  }
  const duplicateVariant = async(index: number) => {
    setData(prev => {
      const toBeDuplicated = prev?.variants![index]

      return { ...prev, variants: [...(prev?.variants ?? []), toBeDuplicated!] }
    })
  }

  const createVariant = async() => {
    setData(prev => ({ variants: [...(prev?.variants ?? []), { tabs: [] }] }))
  }

  const onEdit = (_: any, action: "add" | "remove") => {
    if (action === "add") {
      createVariant()
    }
  }

  const setVariantValidity = useCallback((isValid: boolean, index: number) => {
    setIsVariantInvalid(prev => {
      const cloned = [...prev]
      cloned[index] = !isValid

      return cloned
    })
  }, [])

  return (
    <div>
      <Title level={2}>Variants</Title>
      <Tabs type="editable-card" onEdit={onEdit}>
        {data?.variants?.map((variant, index) => {
          const isUnsaved = !equal(toJS(variant), toJS(getStoreData()?.variants?.[index]))
          console.log("original", toJS(getStoreData()?.variants?.[index]))
          console.log("new", toJS(variant))
          const isInvalid = isVariantInvalid[index]

          return (
            <Tabs.TabPane
              closable={false}
              destroyInactiveTabPane={true}
              tab={
                <Space size={4}>
                  <span>Variant {index}</span>
                  {isInvalid && <Text type="danger"> (!)</Text>}
                  {isUnsaved && <Tag color="error">unsaved changes</Tag>}
                </Space>
              }
              key={index}
            >
              <VariantEditor
                index={index}
                deleteVariant={() => deleteVariant(index)}
                duplicateVariant={() => duplicateVariant(index)}
                setVariantData={setVariantData}
                variantData={variant}
                setIsVariantValid={setVariantValidity}
              />
            </Tabs.TabPane>
          )
        })}
      </Tabs>
      <Space style={{ position: "fixed", right: 20, bottom: 20 }} size="middle">
        <Button
          type="primary"
          disabled={isVariantInvalid.some(isInvalid => isInvalid === true)}
          onClick={async() => {
            try {
              await saveAll()
            } catch (error) {
              console.log(error)
            }
          }}
        >
          Save all
        </Button>
      </Space>
    </div>
  )
})
