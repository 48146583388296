import React, { FC } from "react"

import Styles from "../styles.module.css"

import { FormElement, UploadComponent } from "components"
import { Divider } from "antd"

export const ChallengeBasedContent: FC<{
  topMainPageLeftBannerIMG: string;
  settopMainPageLeftBanner: (data) => void;
  topMainPageRightBannerIMG: string;
  settopMainPageRightBanner: (data) => void;

  imageNotCompleted1StateIMG: string;
  setimageNotCompleted1State: (data) => void;
  imageCompleted1StateIMG: string;
  setimageCompleted1State: (data) => void;

  imageNotCompleteStateIMG: string;
  setimageNotCompleteState: (data) => void;
  imageGrandStateIMG: string;
  setimageGrandState: (data) => void;
  leaderboardnotwinIMG: string;
  setleaderboardnotwin: (data) => void;
  leaderboardwinIMG: string;
  setleaderboardwin: (data) => void;
  leaderboardrightIMG: string;
  setleaderboardright: (data) => void;
}> = ({
  topMainPageLeftBannerIMG,
  settopMainPageLeftBanner,
  topMainPageRightBannerIMG,
  settopMainPageRightBanner,
  imageCompleted1StateIMG,
  setimageCompleted1State,
  imageNotCompleted1StateIMG,
  setimageNotCompleted1State,
  imageNotCompleteStateIMG,
  setimageNotCompleteState,
  imageGrandStateIMG,
  setimageGrandState,
  leaderboardnotwinIMG,
  setleaderboardnotwin,
  leaderboardwinIMG,
  setleaderboardwin,
  leaderboardrightIMG,
  setleaderboardright,
}) => {
  return (
    <>
      <Divider>Main Page - Top Blocks</Divider>

      <div className={Styles.containerUploadImages}>
        <UploadComponent
          image={topMainPageLeftBannerIMG}
          setImage={settopMainPageLeftBanner}
          placeholder="Top Left Banner"
          title="Top Left Banner"
        />
        <UploadComponent
          image={topMainPageRightBannerIMG}
          setImage={settopMainPageRightBanner}
          placeholder="Top Left Banner"
          title="Top Left Banner"
        />

        <Divider>Main Page - Right Blocks</Divider>
        <div className={Styles.containerUploadImages}>
          <div style={{ display: "flex", gap: "10px" }}>
            <UploadComponent
              image={imageCompleted1StateIMG}
              setImage={setimageCompleted1State}
              placeholder="Challenges Completed - Claim Reward"
              title="Challenges Completed - Claim Reward"
            />
            <UploadComponent
              image={imageNotCompleted1StateIMG}
              setImage={setimageNotCompleted1State}
              placeholder="Challenges Not Completed  Yet"
              title="Challenges Not Completed  Yet"
            />
            <div>
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "title1State"]}
                label="Title Challenges Not Completed  Yet"
              />
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "text1State"]}
                label="Body Challenges Not Completed  Yet"
              />
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "CTAtext1State"]}
                label="CTA Challenges Not Completed  Yet"
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <UploadComponent
              image={imageGrandStateIMG}
              setImage={setimageGrandState}
              placeholder="Grand Prize Raffle - Final State"
              title="Grand Prize Raffle - Final State"
            />
            <div>
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "titleGrandState"]}
                label="Title Grand Prize Raffle - Final State"
              />
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "textGrandState"]}
                label="Body Grand Prize Raffle - Final State"
              />
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "CTAtextGrandState"]}
                label="CTA Grand Prize Raffle - Final State"
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <UploadComponent
              image={imageNotCompleteStateIMG}
              setImage={setimageNotCompleteState}
              placeholder="Too Late State"
              title="Too Late State"
            />
            <div>
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "titleNotCompleteState"]}
                label="Title Too Late State"
              />
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "textNotCompleteState"]}
                label="Body Too Late State"
              />
              <FormElement
                type="input"
                name={["challengeBasedContest", "rightMainPageBanner", "CTAtextNotCompleteState"]}
                label="CTA Too Late State"
              />
            </div>
          </div>
        </div>
        <Divider>Winners List</Divider>

        <div style={{ display: "grid" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <UploadComponent
              image={leaderboardnotwinIMG}
              setImage={setleaderboardnotwin}
              placeholder="Leaderboard banner"
              title="Winners List - Not A Winner"
            />

            <UploadComponent
              image={leaderboardwinIMG}
              setImage={setleaderboardwin}
              placeholder="Leaderboard win banner"
              title="Winners List - Winner"
            />

            <UploadComponent
              image={leaderboardrightIMG}
              setImage={setleaderboardright}
              placeholder="Leaderboard right banner"
              title="Winners List - Reward Bank"
            />
          </div>

          <br />

          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "notwin", "title"]}
            label="Title Winners List - Not A Winner"
          />
          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "win", "title"]}
            label="Title Winners List - Winner"
          />
          <br />

          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "notwin", "text"]}
            label="Body Winners List - Not A Winner"
          />
          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "win", "text"]}
            label="Body Winners List - Winner"
          />
          <br />

          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "notwin", "CTAtext"]}
            label="CTA See Challenges - Not A Winner"
          />
          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "win", "CTAtext"]}
            label="CTA - Winner"
          />
          <br />
          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "right", "title"]}
            label="Title Winners List - Reward Bank"
          />
          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "right", "text"]}
            label="Body Winners List - Reward Bank"
          />
          <FormElement
            type="input"
            name={["challengeBasedContest", "leaderboard", "right", "titleFinished"]}
            label="Title Empty Winners List - Reward Bank "
          />
          <br />
        </div>
      </div>
    </>
  )
}
