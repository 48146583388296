import React, { FC } from "react"
import { DatePicker, Form, Input, InputNumber } from "antd"
import TextArea from "antd/lib/input/TextArea"

type FormElementType = {
  type: "input" | "inputNumber" | "text" | "button" | "select" | "date" | "readOnly";
  label: string;
  name: string | string[];
  initialValue?: any;
  rules?: any[];
  required?: boolean;
  style?: any;
  className?: string;
  props?: any;
  children?: React.ReactNode;
};

export const FormElement: FC<FormElementType> = ({
  type,
  label,
  name,
  initialValue,
  rules,
  required,
  style,
  className,
  props,
  children,
}) => {
  const displayByType = () => {
    switch (type) {
    case "input":
      return <Input {...props} />
    case "inputNumber":
      return <InputNumber {...props} />
    case "text":
      return <TextArea {...props} />
    case "date":
      return <DatePicker {...props} />
    case "select":
      return children
    case "readOnly":
      return <Input {...props} disabled />
    }
  }

  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={initialValue}
      rules={
        rules
          ? rules
          : required
            ? [
              {
                required: true,
                message: `Please fill ${label}`,
              },
            ]
            : []
      }
      style={style}
      className={className}
    >
      {displayByType()}
    </Form.Item>
  )
}
