import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import { GiftCard } from "stores/primitives/gift-card"
import { computed, observable, transaction, makeObservable } from "mobx"
import {
  createGiftCard,
  deleteGiftCard,
  getGiftCards,
  updateGiftCard,
  uploadGiftCardsList,
} from "domains/purchased-gift-cards/api/gift-cards.call"

export class GiftCardsStore extends PaginationStore<GiftCard> {
  @observable
  private _giftCards: GiftCard[]
  private _selectedElement: GiftCard = {} as GiftCard

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<GiftCard[]> {
    const selectedElementId = this.rootStore.dataStore.marketplaceStore.selectedElement?.id
    if (!selectedElementId) return []
    const { page, limit } = this.pagination

    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getGiftCards, selectedElementId, page, limit)
    if (res) {
      transaction(() => {
        this._giftCards = res?.data
        this.total = res?.total
      })
    }

    return res?.data
  }

  async create(data: { giftCode: string }) {
    const selectedElementId = this.rootStore.dataStore.marketplaceStore.selectedElement?.id
    if (!selectedElementId) return
    await this.defaultFunction(createGiftCard, { ...data, itemTypeId: selectedElementId })
  }

  async update(data: { giftCode: string }) {
    await this.defaultFunction(updateGiftCard, this._selectedElement.id, data)
  }

  async delete() {
    await this.defaultFunction(deleteGiftCard, this._selectedElement.id)
  }

  async uploadList(data: FormData) {
    const selectedElementId = this.rootStore.dataStore.marketplaceStore.selectedElement?.id
    if (!selectedElementId) return
    await this.defaultFunction(uploadGiftCardsList, selectedElementId, data)
  }

  @computed
  get selectedElement(): GiftCard {
    return this._selectedElement
  }

  set selectedElement(value: GiftCard) {
    this._selectedElement = value
  }

  @computed
  get giftCards(): GiftCard[] {
    return this._giftCards
  }
}
