import { FormInstance, notification } from "antd"
import { FormElement, InputColor } from "components"
import { ContestConfig } from "domains/managingJson/store/types"
import { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

interface props {
  form: FormInstance<any>
}

export const ColorsTab: FC<props> = ({ form }) => {
  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const [neutralBlack, setneutralBlack] = useState((data && data.colors && data.colors?.neutral && data.colors?.neutral.black) || "#fff")
  const [neutralGray, setneutralGray] = useState((data && data.colors && data.colors?.neutral && data.colors?.neutral.gray) || "#fff")
  const [neutralInactive, setneutralInactive] = useState(
    (data && data.colors && data.colors?.neutral && data.colors?.neutral.inactive) || "#fff")
  const [neutralRed, setneutralRed] = useState((data && data.colors && data.colors?.neutral && data.colors?.neutral.red) || "#fff")

  const [primaryDefault, setprimaryDefault] = useState(
    (data && data.colors && data.colors?.primary && data.colors?.primary.default) || "#fff")
  const [primaryLight, setprimaryLight] = useState((data && data.colors && data.colors?.primary && data.colors?.primary.light) || "#fff")
  const [primaryDark, setprimaryDark] = useState((data && data.colors && data.colors?.primary && data.colors?.primary.dark) || "#fff")

  const [secondaryClick, setsecondaryClick] = useState(
    (data && data.colors && data.colors?.secondary && data.colors?.secondary.click) || "#fff")

  const [secondaryDefault, setsecondaryDefault] = useState(
    (data && data.colors && data.colors?.secondary && data.colors?.secondary.default) || "#fff")
  const [secondaryHover, setsecondaryHover] = useState(
    (data && data.colors && data.colors?.secondary && data.colors?.secondary.hover) || "#fff")

  const [backgroundMain, setbackgroundMain] = useState((
    data && data.colors && data.colors?.background && data.colors?.background.main) || "")

  const [backgroundSecondary, setbackgroundSecondary] = useState(
    (data && data.colors && data.colors?.background && data.colors?.background.secondary) || "")

  const [textInactive, settextInactive] = useState((data && data.colors && data.colors?.text && data.colors?.text.inactive) || "#fff")
  const [textWhite, settextWhite] = useState((data && data.colors && data.colors?.text && data.colors?.text.white) || "")
  const [textGray, settextGray] = useState((data && data.colors && data.colors?.text && data.colors?.text.gray) || "")

  useEffect(() => {
    if (data) {
      setprimaryDefault(data.colors?.primary?.default || "#fff")
      setprimaryLight(data.colors?.primary?.light || "#fff")
      setprimaryDark(data.colors?.primary?.dark || "#fff")

      setsecondaryHover(data.colors?.secondary?.hover || "#fff")
      setsecondaryDefault(data.colors?.secondary?.default || "#fff")
      setsecondaryClick(data.colors?.secondary?.click || "#fff")

      setneutralBlack(data.colors?.neutral?.black || "#fff")
      setneutralGray(data.colors?.neutral?.gray || "#fff")
      setneutralInactive(data.colors?.neutral?.inactive || "#fff")
      setneutralRed(data.colors?.neutral?.red || "#fff")

      setbackgroundMain(data.colors?.background?.main || "#fff")
      setbackgroundSecondary(data.colors?.background?.secondary || "#fff")

      settextInactive(data.colors?.text?.inactive || "#fff")
      settextWhite(data.colors?.text?.white || "#fff")
      settextGray(data.colors?.text?.gray || "#fff")
    }
  }, [data])

  return (

    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px", display: "flex" }}>
      <div style={{ width: "49%", marginRight: "16px" }}>
        <div style={{ background: "white", padding: "24px 16px", marginBottom: "24px" }}>
          <div style={{ fontWeight: 500, marginBottom: "16px" }}>Primary</div>
          <div style={{ display: "flex", background: "white" }}>
            <div style={{ width: "50%", marginRight: "10px" }}>
              <FormElement type="select" label="" name="primaryDefault">
                <InputColor
                  title="Default color"
                  color={data.colors?.primary.default ?? ""}
                  onChange={setprimaryDefault}
                />
              </FormElement>
            </div>
            <div style={{ width: "50%" }}>
              <FormElement type="select" label="" name="primaryDark">
                <InputColor
                  title="Dark color"
                  color={data.colors?.primary.dark ?? ""}
                  onChange={setprimaryDark}
                />
              </FormElement>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <FormElement type="select" label="" name="primaryLight">
              <InputColor
                title="Light color"
                color={data.colors?.primary.light ?? ""}
                onChange={setprimaryLight}
              />
            </FormElement>
          </div>
        </div>
        <div style={{ background: "white", padding: "24px 16px", marginBottom: "24px" }}>
          <div style={{ fontWeight: 500, marginBottom: "16px" }}>Neutral</div>
          <div style={{ display: "flex", background: "white" }}>
            <div style={{ width: "50%", marginRight: "10px" }}>
              <FormElement type="select" label="" name="neutralBlack">
                <InputColor
                  title="Black"
                  color={data.colors?.neutral.black ?? ""}
                  onChange={setneutralBlack}
                />
              </FormElement>
            </div>
            <div style={{ width: "50%" }}>
              <FormElement type="select" label="" name="neutralInactive">
                <InputColor
                  title="Inactive"
                  color={data.colors?.neutral.inactive ?? ""}
                  onChange={setneutralInactive}
                />
              </FormElement>
            </div>
          </div>
          <div style={{ display: "flex", background: "white" }}>
            <div style={{ width: "50%", marginRight: "10px" }}>
              <FormElement type="select" label="" name="neutralRed">
                <InputColor
                  title="Red"
                  color={data.colors?.neutral.red ?? ""}
                  onChange={setneutralRed}
                />
              </FormElement>
            </div>
            <div style={{ width: "50%" }}>
              <FormElement type="select" label="" name="neutralGray">
                <InputColor
                  title="Gray"
                  color={data.colors?.neutral.gray ?? ""}
                  onChange={setneutralGray}
                />
              </FormElement>
            </div>
          </div>
        </div>
        <div style={{ background: "white", padding: "24px 16px", marginBottom: "24px" }}>
          <div style={{ fontWeight: 500, marginBottom: "16px" }}>Background</div>
          <div style={{ display: "flex", background: "white" }}>
            <div style={{ width: "50%", marginRight: "10px" }}>
              <FormElement type="select" label="" name="backgroundMain">
                <InputColor
                  title="Main"
                  color={data.colors?.background.main ?? ""}
                  onChange={setbackgroundMain}
                />
              </FormElement>
            </div>
            <div style={{ width: "50%" }}>
              <FormElement type="select" label="" name="backgroundSecondary">
                <InputColor
                  title="Secondary"
                  color={data.colors?.background.secondary ?? ""}
                  onChange={setbackgroundSecondary}
                />
              </FormElement>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "49%", marginRight: "16px" }}>
        <div style={{ background: "white", padding: "24px 16px", marginBottom: "24px" }}>
          <div style={{ fontWeight: 500, marginBottom: "16px" }}>Secondary</div>
          <div style={{ display: "flex", background: "white" }}>
            <div style={{ width: "50%", marginRight: "10px" }}>
              <FormElement type="select" label="" name="secondaryDefault">
                <InputColor
                  title="Default"
                  color={data.colors?.secondary.default ?? ""}
                  onChange={setsecondaryDefault}
                />
              </FormElement>
            </div>
            <div style={{ width: "50%" }}>
              <FormElement type="select" label="" name="secondaryHover">
                <InputColor
                  title="Hover"
                  color={data.colors?.secondary.hover ?? ""}
                  onChange={setsecondaryHover}
                />
              </FormElement>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <FormElement type="select" label="" name="secondaryClick">
              <InputColor
                title="Click"
                color={data.colors?.secondary.click ?? ""}
                onChange={setsecondaryClick}
              />
            </FormElement>
          </div>
        </div>
        <div style={{ background: "white", padding: "24px 16px", marginBottom: "24px" }}>
          <div style={{ fontWeight: 500, marginBottom: "16px" }}>Text</div>
          <div style={{ display: "flex", background: "white" }}>
            <div style={{ width: "50%", marginRight: "10px" }}>
              <FormElement type="select" label="" name="textWhite">
                <InputColor
                  title="White"
                  color={data.colors?.text.white ?? ""}
                  onChange={settextWhite}
                />
              </FormElement>
            </div>
            <div style={{ width: "50%" }}>
              <FormElement type="select" label="" name="textGray">
                <InputColor
                  title="Gray"
                  color={data.colors?.text.gray ?? ""}
                  onChange={settextGray}
                />
              </FormElement>
            </div>
          </div>
          <div style={{ display: "flex", background: "white" }}>
            <div style={{ width: "100%" }}>
              <FormElement type="select" label="" name="textInactive">
                <InputColor
                  title="Inactive"
                  color={data.colors?.text.inactive ?? ""}
                  onChange={settextInactive}
                />
              </FormElement>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
