import { FC, useMemo, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import useAdvertiser from "../hooks/useTasks"
import Title from "antd/lib/typography/Title"
import { Button, Modal, Space, Switch, Table } from "antd"
import { IAdvertiser, ITask } from "../api/types"
import { updateTask } from "../api"
import { CreateAdvertiser } from "./createAdvertiser"
import { CreateTask } from "./createTask"
import { ArrowDownOutlined, ArrowUpOutlined, CopyOutlined } from "@ant-design/icons"
import { priorityUtils } from "../utils/priority"

const InternalOWAdvertiserPage: FC = () => {
  const { advertiser: id } = useParams()
  const [showEditAdvertiser, setShowEditAdvertiser] = useState(false)
  const [editTask, setEditTask] = useState<ITask | true>()
  const {
    advertiser,
    updateTask: updateTaskEntry,
    addTask,
    setAdvertiser,
  } = useAdvertiser(id || "")
  const [patching, setPatching] = useState(false)

  const onPatch = (task: ITask) => {
    if (patching) return
    if (!id) return
    setPatching(true)
    updateTask(id, task)
      .then(updated => {
        updateTaskEntry(updated)
      })
      .finally(() => {
        setPatching(false)
      })
  }

  const onClose = (advertiser?: IAdvertiser) => {
    if (advertiser) {
      setAdvertiser(
        old => old && {
          ...old,
          ...advertiser,
        },
      )
    }
    setShowEditAdvertiser(false)
  }

  const onCloseTask = (task?: ITask) => {
    setEditTask(old => {
      if (task) {
        if (old === true) {
          // new task
          addTask(task)
        } else {
          // edit task
          updateTaskEntry(task)
        }
      }

      return undefined
    })
  }

  const {
    isTopPriority,
    isLowestPriority,
    switchPriority,
  } = useMemo(() => priorityUtils(advertiser?.tasks || [], task => updateTask(id!, task), updateTaskEntry), [
    advertiser?.tasks, id, updateTaskEntry,
  ])

  const onSwitchPriority = (task: ITask, up: boolean) => {
    if (patching) return
    setPatching(true)
    switchPriority(task, up).finally(() => setPatching(false))
  }

  const onApiKey = async() => {
    const apiKey = advertiser?.apiKeys[0]
    if (!apiKey) return alert("No API Key found, contact developer team")
    await navigator.clipboard.writeText(apiKey.value)
    alert("API Key copied to clipboard")
  }

  const onCopyId = async(id: string) => {
    await navigator.clipboard.writeText(id)
    alert("Task ID copied to clipboard")
  }

  const renderTrimmedDescription = (description: string) => {
    if (!description) return ""
    const willTrim = description.length > 100

    return willTrim ? description.substring(0, 100).trim() + "..." : description
  }

  if (!id) return <Navigate to="/internal-offerwall/advertisers" />

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <Modal
        open={showEditAdvertiser}
        title="Edit Advertiser"
        width="700px"
        footer={null}
        onCancel={() => onClose()}
        style={{ top: "10px" }}
      >
        <CreateAdvertiser onClose={onClose} initial={advertiser} />
      </Modal>
      <Modal
        open={!!editTask}
        title={editTask === true ? "Create Task" : "Edit Task"}
        width="700px"
        footer={null}
        onCancel={() => onCloseTask()}
        style={{ top: "10px" }}
      >
        <CreateTask
          onClose={onCloseTask}
          advertiser={id}
          advertiserRegions={advertiser?.geoRegions || []}
          initial={typeof editTask === "object" ? editTask : undefined}
        />
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 24,
          paddingTop: 12,
        }}
      >
        <Title level={2} style={{ marginBottom: 0 }}>
          {advertiser?.name}
        </Title>
        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <Button
            type="primary"
            onClick={() => {
              setEditTask(true)
            }}
          >
            New Task
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setShowEditAdvertiser(true)
            }}
          >
            Edit Advertiser Details
          </Button>
          <Button
            type="primary"
            onClick={onApiKey}
          >
            API Key
          </Button>
        </div>
      </div>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Table<ITask>
          dataSource={advertiser?.tasks}
          pagination={false}
          rowKey={record => record.id}
          scroll={{ x: true }}
          columns={[
            {
              key: "id",
              title: "Task ID",
              dataIndex: "id",
              render: id => (
                <span
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={e => {
                    e.stopPropagation()
                    onCopyId(id)
                  }}
                >
                  <CopyOutlined color="black" style={{ marginRight: 4 }} />
                  {id}
                </span>
              ),
            },
            { key: "name", title: "Task Name", dataIndex: "name" },
            {
              key: "description",
              title: "Description",
              dataIndex: "description",
              render: renderTrimmedDescription,
            },
            {
              key: "reward",
              title: "Reward",
              dataIndex: "reward",
              render: reward => <span>{reward} BUFFS</span>,
            },
            {
              key: "revenue",
              title: "Revenue",
              dataIndex: "revenue",
              render: revenue => <span>${revenue}</span>,
            },
            {
              key: "active",
              title: "Active",
              dataIndex: "active",
              render: (active, advertiser) => (
                <div onClick={e => e.stopPropagation()}>
                  <Switch
                    onClick={() => onPatch({ ...advertiser, active: !advertiser.active })}
                    disabled={patching}
                    checked={active}
                  />
                </div>
              ),
            },
            {
              key: "priority",
              title: "Priority",
              dataIndex: "priority",
              render: (priority, task) => (
                <div
                  onClick={e => e.stopPropagation()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 12,
                    pointerEvents: patching ? "none" : "auto",
                  }}
                >
                  <Button
                    disabled={isTopPriority(task)}
                    onClick={
                      () => onSwitchPriority(task, true)
                    }
                  >
                    <ArrowUpOutlined />
                  </Button>
                  <Button
                    disabled={isLowestPriority(task)}
                    onClick={
                      () => onSwitchPriority(task, false)
                    }
                  >
                    <ArrowDownOutlined />
                  </Button>
                </div>
              ),
            },
          ]}
          onRow={record => ({
            style: { cursor: "pointer" },
            onClick: () => {
              setEditTask(record)
            },
          })}
          loading={!advertiser}
        />
      </Space>
    </div>
  )
}

export default InternalOWAdvertiserPage
