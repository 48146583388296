import { apiCall } from "api-calls/api-call"

// CODES API
export const getCodes = async(page: number = 1) => {
  return apiCall({
    method: "GET",
    path: `codes-storage/code-info?limit=100&page=${page}`,
  })
}

export const getCodeByIs = async(id: string) => {
  return apiCall({
    method: "GET",
    path: `codes-storage/code-info/${id}`,
  })
}
export const createCode = async(body: any) => {
  return apiCall({
    method: "POST",
    path: "codes-storage/code-info",
    body,
  })
}
export const updateCode = async(id: string, body: any) => {
  return apiCall({
    method: "PUT",
    path: `codes-storage/code-info/${id}`,
    body,
  })
}

export const deleteCode = async(id: string) => {
  return apiCall({
    method: "DELETE",
    path: `codes-storage/code-info/${id}`,
  })
}
export const getSubCode = async(page: number = 1, codeId: string) => {
  return apiCall({
    method: "GET",
    path: `codes-storage/codes?codeInfoId=${codeId}&limit=100&page=${page}`,
  })
}
export const createSubCodes = async(body: { codeInfoId: string; codes: string[] }) => {
  return apiCall({
    method: "POST",
    path: "codes-storage/codes",
    body,
  })
}

export const createSubCodesCsv = async(codeInfoId: string, body: FormData) => {
  return apiCall({
    method: "POST",
    path: `codes-storage/codes/${codeInfoId}`,
    formData: body,
  })
}

export const deleteSubCodes = async(subcodeId: string) => {
  return apiCall({
    method: "DELETE",
    path: `codes-storage/codes/${subcodeId}`,
  })
}
