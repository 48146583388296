import { FormInstance, Radio } from "antd"
import { FormElement } from "components"
import { ContestConfig } from "domains/managingJson/store/types"
import { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"

interface props {
  gameId: string
  form: FormInstance<any>
}

export const MicroPrizesList: FC<props> = ({ gameId, form }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const [rewardType, setRewardType] = useState(data?.topLadderRewardStrategy?.type)


  const rewardTypeChange = e => {
    data.topLadderRewardStrategy ? data.topLadderRewardStrategy.type = e.target.value : null
    setRewardType(e.target.value)
  }

  return (
    <div>
      <div style={{ fontWeight: "500" }}>
        Reward type
      </div>
      <div style={{ display: "flex", marginBottom: "16px" }}>
        <Radio.Group onChange={rewardTypeChange} value={rewardType}>
          <Radio value="GIFT_CARDS">Gift Cards</Radio>
          <Radio value="BUFF_POINTS">Buffs</Radio>
        </Radio.Group>
      </div>
      <div style={{ width: "100%", marginRight: "16px" }}>
        {rewardType == "BUFF_POINTS" ? "Reward Name on the Rewards Page" : "Gift Card Name"}
        <FormElement type="input" label="" name="microPrize1" initialValue={data.topLadderRewardStrategy?.rewardText} />
      </div>
      <div style={{ width: "100%", marginRight: "16px" }}>
        {rewardType == "BUFF_POINTS" ? "Buff Transaction Name" : "Reward Name on the Rewards Page"}
        <FormElement type="input" label="" name="microPrize2" initialValue={data.topLadderRewardStrategy?.transactionName} />
      </div>
      <div style={{ display: "flex" }}>
        {rewardType == "BUFF_POINTS" && (
          <div style={{ width: "49%", marginRight: "16px" }}>
           Buffs Amount
            <FormElement type="input" label="" name="microPrize3" initialValue={data.topLadderRewardStrategy?.buffPointsAmount} />
          </div>)}
        {rewardType == "GIFT_CARDS" && (
          <div style={{ width: "49%", marginRight: "16px" }}>
            Gift Card Text
            <FormElement type="input" label="" name="microPrizeGiftCard" initialValue={data.topLadderRewardStrategy?.giftCardText} />
          </div>)}
        <div style={{ width: "49%", marginRight: "16px" }}>
          {rewardType == "BUFF_POINTS" ? "Prize-winning Places" : "Prize-winning Places/week"}
          <FormElement type="input" label="" name="microPrize4" initialValue={data.contestServerSideConfiguration?.contestRewardCount} />
        </div>
      </div>
    </div>
  )
}
