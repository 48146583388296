import React, { FC } from "react"
import { observer } from "mobx-react"
import { Form, Input, Modal } from "antd"
import { useStore } from "stores/utils/use-store"

import { GameType as GameTypeType } from "domains/games/store/types"

import Styles from "./styles.module.css"
import { SubmitCancelButtons } from "components"

type GameTypeProps = {
  onClose: () => void;
};

export const GameType: FC<GameTypeProps> = observer(({ onClose }) => {
  const {
    dataStore: {
      gamesStore,
      gamesStore: { selectedGameType: { pretyName, description, id } },
    },
  } = useStore()
  const [form] = Form.useForm()

  const onFinishHandler = async(event: GameTypeType) => {
    gamesStore.funcAfterSuccess = async() => {
      onClose()
      await gamesStore.getData()
    }

    await gamesStore.updateGameType({
      gameTypeId: id,
      pretyName: event.pretyName,
      description: event.description,
    })
  }

  return (
    <Modal
      style={{ top: "10px" }}
      visible
      title="Edit type"
      footer={null}
      onCancel={onClose}
      width="700px"
    >
      <Form onFinish={onFinishHandler} form={form}>
        <Form.Item
          label="Pretty name"
          name="pretyName"
          initialValue={pretyName}
          rules={[
            {
              required: true,
              message: "Input pretty name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          initialValue={description}
          className={Styles.flexTextarea}
          rules={[
            {
              required: true,
              message: "Input description!",
            },
          ]}
        >
          <Input.TextArea className={Styles.textarea} showCount autoSize={{ minRows: 6 }} />
        </Form.Item>
        <SubmitCancelButtons onClose={onClose} isLoading={gamesStore.isLoading} />
      </Form>
    </Modal>
  )
})
