import { Form, FormInstance, notification } from "antd"
import { FC } from "react"
import { useStore } from "stores/utils/use-store"
import { TranslationInput } from "./TranslationInput"
import { GeoTranslations } from "./GeoTranslations"
import { ContestConfig } from "domains/managingJson/store/types"

export const TranslationTab: FC<{form: FormInstance<any>}> = ({ form }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Splash Screen</div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TranslationInput title="Title1" value={data.splashScreen?.title ?? ""} width={30} />
          <TranslationInput title="Subtitle1" value={data.splashScreen?.subtitle ?? ""} width={30} />
          <TranslationInput title="CTA text1" value={data.splashScreen?.CTAtext ?? ""} width={30} />
          <TranslationInput title="TOC text1" value={data.splashScreen?.TOCtext ?? ""} width={30} />
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Onboarding CTA</div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TranslationInput title="Continue Button" value={data.onboardingCTA?.continueButton ?? ""} width={30} />
          <TranslationInput title="Join Button" value={data.onboardingCTA?.joinButton ?? ""} width={30} />
          <TranslationInput title="TOC text2" value={data.onboardingCTA?.TOCtext ?? ""} width={30} />
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Error State Global</div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TranslationInput title="Title2" value={data.errorStateGlobal?.title ?? ""} width={30} />
          <TranslationInput title="Subtitle2" value={data.errorStateGlobal?.subtitle ?? ""} width={30} />
          <TranslationInput title="CTA text2" value={data.errorStateGlobal?.CTAtext ?? ""} width={30} />
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Main Page </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TranslationInput title="Big Timer Title" value={data.schedule?.bigTimerTitle ?? ""} width={30} />
          <TranslationInput title="Small Timer Title" value={data.schedule?.smallTimerTitle ?? ""} width={30} />
          <TranslationInput title="Get Started Button" value={data.schedule?.getStartedButton ?? ""} width={30} />
        </div>
        <div style={{ textAlign: "center", fontWeight: "500", marginBottom: "16px" }}>Tabs</div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TranslationInput title="Schedule" value={data.schedule?.tabs?.schedule ?? ""} width={23} />
          <TranslationInput title="Leaderboard" value={data.schedule?.tabs?.leaderboard ?? ""} width={23} />
          <TranslationInput title="Free Points" value={data.schedule?.tabs?.freePoints ?? ""} width={23} />
          <TranslationInput title="My Rewards" value={data.schedule?.tabs?.myRewards ?? ""} width={23} />
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Rewards</div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TranslationInput title="Claim Button" value={data.rewards?.claimButton ?? ""} width={18} />
          <TranslationInput title="Claimed Button" value={data.rewards?.claimedButton ?? ""} width={18} />
          <TranslationInput title="Support Button" value={data.rewards?.supportButton ?? ""} width={18} />
          <TranslationInput title="FAQ Button" value={data.rewards?.faqButton ?? ""} width={18} />
          <TranslationInput title="Footer text" value={data.rewards?.footerText ?? ""} width={18} />
        </div>
        <div style={{ textAlign: "center", fontWeight: "500", marginBottom: "16px" }}>Head table sell titles</div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TranslationInput title="Date" value={data.rewards?.head?.date ?? ""} width={30} />
          <TranslationInput title="Event" value={data.rewards?.head?.event ?? ""} width={30} />
          <TranslationInput title="Reward" value={data.rewards?.head?.reward ?? ""} width={30} />
        </div>
        <div style={{ textAlign: "center", fontWeight: "500", marginBottom: "16px" }}>Empty Page</div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TranslationInput title="Title3" value={data.rewards?.emptyPage?.title ?? ""} width={45} />
          <TranslationInput title="Subtitle3" value={data.rewards?.emptyPage?.subtitle ?? ""} width={45} />
        </div>
      </div>
      {data.leaderboardWithCountry && (
        <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
          <div style={{ fontWeight: "500", marginBottom: "24px" }}>Geo Translations</div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <GeoTranslations form={form} title="leaderboard title" />
          </div>
        </div>
      )}
    </div>
  )

}
