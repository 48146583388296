import React from "react"
import { Form, Input, Select } from "antd"
import { Btn } from "../../store/types"
import { DefaultLinksRouting } from "components"

export enum CTATypes {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  Premium = "PREMIUM",
}

export const ButtonCTA = ({ id, button }: { id: number; button: Btn }) => {
  return (
    <div>
      <Form.Item
        label={`Title ${id + 1} CTA Button`}
        name={`titleCTAButton${id + 1}`}
        style={{ width: "100%" }}
        initialValue={button.text}
        rules={[
          {
            required: true,
            message: "Please input CTA title!",
          },
        ]}
      >
        <Input maxLength={20} />
      </Form.Item>
      <Form.Item
        label={`Type ${id + 1} CTA Button`}
        name={`typeCTAButton${id + 1}`}
        rules={[
          {
            required: true,
            message: "Please select CTA type!",
          },
        ]}
        initialValue={button.type}
      >
        <Select>
          <Select.Option value={CTATypes.Primary}>Primary</Select.Option>
          <Select.Option value={CTATypes.Secondary}>Secondary</Select.Option>
          <Select.Option value={CTATypes.Premium}>Premium</Select.Option>
        </Select>
      </Form.Item>

      <DefaultLinksRouting formItemName={`link${id + 1}`} link={button.link} />
    </div>
  )
}
