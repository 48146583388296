import { EntityToUpdate } from "abstract/entity-to-update"
import { UtmBonus } from "../store"
import { apiCall } from "api-calls/api-call"

export const getUtmBonuses = async(
  filter: string,
  page: number,
  limit: number,
): Promise<{
  data: UtmBonus[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string } = {
    page,
    limit,
  }
  if (filter) query.filter = filter

  return apiCall({
    method: "GET",
    path: "utm-bonuses",
    query,
  })
}

export const updateUtmBonus = async(id: string, data: EntityToUpdate<UtmBonus>): Promise<void> => {
  return apiCall({
    method: "PUT",
    path: `utm-bonuses/${id}`,
    body: data,
  })
}

export const createUtmBonus = async(data: EntityToUpdate<UtmBonus>): Promise<void> => {
  return apiCall({
    method: "POST",
    path: "utm-bonuses",
    body: data,
  })
}
export const deleteUtmBonus = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `utm-bonuses/${id}`,
  })
}
