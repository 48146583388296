import { FormInstance } from "antd"
import { FormElement, UploadComponent } from "components"
import { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { GeoImagesEditor } from "../../geoImagesEditor"
import { ContestConfig } from "domains/managingJson/store/types"

export const ImagesTab: FC<{form: FormInstance<any>}> = ({ form }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const [, refreshState] = useState(0)
  const refresh = () => refreshState(e => e + 1)
  useEffect(() => {
    data.endgameWindow === undefined ? data.endgameWindow = {
      endgameSuccessImage: "",
      endgameFraudImage: "",
      endgameFailImage: "",
      gameBackgroundImage: "",
    } : ""

    data.startgameWindow === undefined ? data.startgameWindow = { contestImage: "" } : ""
  }, [data])

  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Main Images</div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <UploadComponent
              name="mainLogo"
              image={data.mainLogo ?? ""}
              setImage={e => {
                refresh()
                data.mainLogo = e
              }}
              placeholder=""
              title="Main Logo"
            />
          </div>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <UploadComponent
              image={data.advImage ?? ""}
              setImage={e => {
                refresh()
                data.advImage = e
              }}
              placeholder=""
              title="ADS block placeholder"
            />
          </div>
          <div style={{ width: "33%" }}>
            <UploadComponent
              image={data.settingsLogo ?? ""}
              setImage={e => {
                refresh()
                data.settingsLogo = e
              }}
              placeholder=""
              title="Settings Logo"
            />
          </div>
        </div>
        <div style={{ width: "33%", marginRight: "8px" }}>
          <UploadComponent
            image={data.topBarLogo ?? ""}
            setImage={e => {
              refresh()
              data.topBarLogo = e
            }}
            placeholder=""
            title="Top Bar Logo"
          />
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Start / End Game Windows</div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <UploadComponent
              image={data.endgameWindow?.endgameSuccessImage ?? ""}
              setImage={e => {
                refresh()
                data.endgameWindow ? data.endgameWindow.endgameSuccessImage = e : ""
              }}
              placeholder=""
              title="End Game Succes"
            />
          </div>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <UploadComponent
              image={data.endgameWindow?.endgameFraudImage ?? ""}
              setImage={e => {
                refresh()
                data.endgameWindow ? data.endgameWindow.endgameFraudImage = e : ""
              }}
              placeholder=""
              title="End Game Fraud"
            />
          </div>
          <div style={{ width: "33%" }}>
            <UploadComponent
              image={data.endgameWindow?.endgameFailImage ?? ""}
              setImage={e => {
                refresh()
                data.endgameWindow ? data.endgameWindow.endgameFailImage = e : ""
              }}
              placeholder=""
              title="End Game Fail"
            />
          </div>
        </div>
        <div style={{ width: "33%", marginRight: "8px" }}>
          <UploadComponent
            image={data.startgameWindow?.contestImage ?? ""}
            setImage={e => {
              refresh()
              data.startgameWindow ? data.startgameWindow.contestImage = e : ""
            }}
            placeholder=""
            title="Start Game Contest"
          />
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Leaderboard</div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <UploadComponent
              image={data.leaderboardBanner ?? ""}
              setImage={e => {
                refresh()
                data.leaderboardBanner = e
              }}
              placeholder=""
              title="Leaderboard Banner"
            />
          </div>
          <div style={{ width: "33%" }}>
            <UploadComponent
              image={data.leaderboardAdReplacementBanner ?? ""}
              setImage={e => {
                refresh()
                data.leaderboardAdReplacementBanner = e
              }}
              placeholder=""
              title="Ad Replacement Banner"
            />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "10px", width: "30%" }}>
            <GeoImagesEditor
              parentIndex={1}
              form={form}
              title="Top Banner"
              objectKey="leaderboardTopBannerCountry"
            />
            <div style={{ marginTop: "8px" }}>
                Leaderboard Top Banner Copy Text
              <FormElement
                type="input"
                label=""
                name="topCopyText"
                initialValue={data.leaderboardBanners?.topBannerCopyText}
              />
            </div>
          </div>
          <div style={{ marginRight: "10px", width: "30%" }}>
            <GeoImagesEditor
              parentIndex={1}
              form={form}
              title="Schedule Right Side Bannner"
              objectKey="leaderboardBannerCountry"
            />
            <div style={{ marginTop: "8px" }}>
                Leaderboard Right Banner Copy Text
              <FormElement
                type="input"
                label=""
                name="rightCopyText"
                initialValue={data.leaderboardBanners?.rightBannerCopyText}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  )

}
