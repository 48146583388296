import { Column } from "abstract/types/column.type"

export const marketplaceRegionsColumns: Column[] = [
  { title: "ID", key: "id", dataIndex: "id", fixed: true },
  { title: "Name", key: "name", dataIndex: "name", fixed: true },
  {
    title: "Related Items Count",
    key: "name",
    dataIndex: "_count",
    fixed: true,
    render: (count: { itemTypes: number }) => {
      return count.itemTypes
    },
  },
]
