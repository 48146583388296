import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Modal, Table } from "antd"
import { TableOutlined } from "@ant-design/icons"

import { useStore } from "stores/utils/use-store"
import { showDeleteConfirm, DeleteIconButton, EditIconButton, CreateNewElement } from "components"
import { PromocodeGroup } from "domains/promocodes/store/types"
import { GroupEdit } from "domains/promocodes/components/Groups"
import { promocodesGroupsColumns } from "./columns"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { parentPromocodeColumns } from "domains/promocodes/components/ItemTypes/PromocodesList/columns"
import { childPromocodeColumns } from "domains/promocodes/components/ItemTypes/ChildList/columns"

import Styles from "./styles.module.css"

export const GroupsList: FC = observer(() => {
  const { dataStore: { promocodesGroupsStore, childPromocodesStore, parentPromocodesStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)
  const [isParentList, setParentList] = useState<boolean>(false)
  const [isChildList, setChildList] = useState<boolean>(false)

  useEffect(() => {
    promocodesGroupsStore.getData()
  }, [promocodesGroupsStore])

  const onClose = () => {
    promocodesGroupsStore.clearSelectedElement()
    setEditCreateVisibility(null)
  }

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      promocodesGroupsStore.pagination = {
        page,
        limit: pageSize || promocodesGroupsStore.pagination.limit,
      }
    },
    total: promocodesGroupsStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: promocodesGroupsStore.pagination.page,
  }

  const childPaginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      childPromocodesStore.pagination = {
        page,
        limit: pageSize || childPromocodesStore.pagination.limit,
      }
    },
    total: childPromocodesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: childPromocodesStore.pagination.page,
  }

  const parentPaginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      parentPromocodesStore.pagination = {
        page,
        limit: pageSize || parentPromocodesStore.pagination.limit,
      }
    },
    total: parentPromocodesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: parentPromocodesStore.pagination.page,
  }

  const handleDeleteGroup = (e, row: PromocodeGroup) => {
    e.stopPropagation()
    showDeleteConfirm(`Promocode group: ${row.name}`, undefined, async() => {
      promocodesGroupsStore.funcAfterSuccess = async() => await promocodesGroupsStore.getData()

      promocodesGroupsStore.delete(row.id)
    })
  }

  const columns = [
    ...promocodesGroupsColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: PromocodeGroup) => (
        <div className={Styles.actions}>
          <Button
            style={{ background: "green", borderWidth: 0 }}
            type="primary"
            title="parent list"
            onClick={e => {
              e.stopPropagation()
              setParentList(true)
              parentPromocodesStore.setFilter({ promoGroupId: row.id })
            }}
            icon={<TableOutlined />}
          />
          <Button
            style={{ background: "orange", borderWidth: 0 }}
            type="primary"
            title="child list"
            onClick={e => {
              e.stopPropagation()
              setChildList(true)
              childPromocodesStore.setFilter({ parentPromoCodeGroupId: row.id })
            }}
            icon={<TableOutlined />}
          />
          <EditIconButton
            onClick={e => {
              e.stopPropagation()
              setEditCreateVisibility("edit")
              promocodesGroupsStore.selectedElement = row
            }}
          />
          <DeleteIconButton onClick={e => handleDeleteGroup(e, row)} />
        </div>
      ),
    },
  ]

  return (
    <>
      <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="group" />
      <Table
        dataSource={[...promocodesGroupsStore.groups]}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        onRow={(record: PromocodeGroup) => ({
          onClick: () => {
            promocodesGroupsStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit group" : "Create group"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <GroupEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}

      {isParentList && (
        <Modal
          style={{ top: "10px" }}
          width="85%"
          visible
          title="List of Parent Promo Codes"
          footer={null}
          onCancel={() => {
            parentPromocodesStore.setFilter({})
            setParentList(false)
          }}
        >
          <Table
            dataSource={[...parentPromocodesStore.itemTypes]}
            columns={parentPromocodeColumns as any}
            pagination={parentPaginationOptions}
            rowKey="id"
            scroll={{ x: true, y: "70vh" }}
          />
        </Modal>
      )}

      {isChildList && (
        <Modal
          style={{ top: "10px" }}
          width="85%"
          visible
          title="List of Child Promo Codes"
          footer={null}
          onCancel={() => {
            childPromocodesStore.setFilter({})
            setChildList(false)
          }}
        >
          <Table
            dataSource={[...childPromocodesStore.itemTypes]}
            columns={childPromocodeColumns as any}
            pagination={childPaginationOptions}
            rowKey="id"
            scroll={{ x: true, y: "70vh" }}
          />
        </Modal>
      )}
    </>
  )
})
