import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./index.css"
import reactotron from "./reactotronConfig"
import { createStore } from "./stores/utils/create-store"
import { StoreProvider } from "./stores/utils/state-context"

export const rootStore = createStore()
reactotron()

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <StoreProvider value={rootStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>)
