import React, { FC, useRef } from "react"
import { Form, Modal, Select } from "antd"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"
import { SubmitCancelButtons } from "components"
import { COUNTRY_CODES } from "utils/countryCodes"

export const ChangeCountry: FC<{
  visible: boolean;
  onClose: () => void;
}> = observer(({ visible, onClose }) => {
  const formRef: any = useRef()

  const { dataStore: { accountDetailsStore } } = useStore()

  const onFinishHandler = async(e: { country: string[] }) => {
    const country = e.country[0]
    await accountDetailsStore.changeCountry(country)
    onClose()
  }

  return (
    <Modal visible={visible} title="Change Location" footer={null} onCancel={() => onClose()}>
      <Form onFinish={onFinishHandler} ref={formRef}>
        <Form.Item
          label="Country"
          name="country"
          initialValue={[accountDetailsStore.account.country]}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length === 0) {
                  return Promise.reject(new Error("Please select one country!"))
                }
                if (value && value.length > 1) {
                  return Promise.reject(new Error("Please select only one country!"))
                }
                if (value && value.length === 1) {
                  return Promise.resolve()
                }
              },
            },
          ]}
        >
          <Select
            allowClear
            mode="tags"
            options={COUNTRY_CODES.map(({ code, name }) => ({ label: `${name} (${code})`, value: code }))}
          />
        </Form.Item>
        <SubmitCancelButtons onClose={onClose} isLoading={false} />
      </Form>
    </Modal>
  )
})
