import { Modal } from "antd"

export const showDeleteConfirm = (
  name: string,
  event?: string,
  onOk?: () => Promise<any>,
) => {
  Modal.confirm({
    title: `Are you sure delete this ${name}?`,
    content: "The operation cannot be canceled.",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    maskClosable: true,
    onOk() {
      event && window.dispatchEvent(new Event(event))
      if (onOk) {
        return onOk()
      }
    },
    onCancel() {},
  })
}
