import { Button, Form, FormInstance, notification } from "antd"
import { FC, SetStateAction, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { SchedulingImage } from "./SchedulingImage"
import moment from "moment"
import { ContestConfig, ContestConfigLiveData, ContestConfigSchedulePageData } from "domains/managingJson/store/types"
import { FormElement } from "components"

export const SchedulingTab: FC<{
  form: FormInstance<any>
  constestLive: ContestConfigLiveData[]
  setContestLive: React.Dispatch<SetStateAction<ContestConfigLiveData[]>>
  notStarted: ContestConfigSchedulePageData
  setNotStarted: React.Dispatch<SetStateAction<ContestConfigSchedulePageData>>
  finished: ContestConfigSchedulePageData & {
    CTAtext: string;
  }
  setFinished: React.Dispatch<SetStateAction<ContestConfigSchedulePageData & {
    CTAtext: string;
}>>
}> = ({ form, constestLive, setContestLive, notStarted, setNotStarted, finished, setFinished }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const deleteWeek = (index: number) => {
    const arr = constestLive.filter((_, i) => i !== index)
    setContestLive(arr)

    if (arr.length === index && arr.length > 0) {
      data.endDate = arr[index - 1].endDate
    }

    for (let i = index; i < arr.length; i++) {
      form.setFieldValue(`weekTitle${i + 1}`, arr[i].title)
      form.setFieldValue(`weekText${i + 1}`, arr[i].text)
      form.setFieldValue(`contestLiveStartDate${i + 1}`, moment(arr[i].startDate))
      form.setFieldValue(`contestLiveEndDate${i + 1}`, moment(arr[i].endDate))
    }
  }

  const addWeek = () => {
    const arr = [...constestLive, {
      title: "",
      text: "",
      startDate: constestLive[constestLive.length - 1].endDate,
      endDate: constestLive[constestLive.length - 1].endDate,
      image: "",
    }]
    setContestLive(arr)
  }

  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Main page</div>
        <div style={{ width: "30%", marginRight: "16px" }}>
          Game producer&apos;s disclaimer
          <FormElement
            type="input"
            label=""
            name="gameProducerDisclaimer"
            initialValue={data.gameProducerDisclaimer}
          />
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Not Started</div>
        <SchedulingImage
          step={notStarted as any}
          form={form}
          name="Contest Not Started Schedule"
          setter={setNotStarted}
        />
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px", display: "flex" }}>
            Contest is Live
          <Button style={{ marginLeft: "auto" }} type="primary" onClick={addWeek}>Add new week</Button>
        </div>
        <div style={{ display: "flex", marginBottom: "24px", flexWrap: "wrap" }}>
          {constestLive.map((step, index) => (
            <SchedulingImage
              key={index}
              step={step as any}
              index={index}
              form={form}
              name={`Week ${index + 1}`}
              deleteFunc={() => {
                deleteWeek(index)
              }}
              weeks={constestLive}
              setContestLive={setContestLive}
            />
          ))}
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Finished</div>
        <SchedulingImage
          step={finished}
          form={form}
          name="Contest Not Started Schedule"
          finish
          setter={setFinished as any}
        />
      </div>
    </div>
  )

}
