import { DeleteOutlined } from "@ant-design/icons"
import { FormInstance } from "antd"
import { FormElement, UploadComponent } from "components"
import { ConsentRow, UserConsentEditor } from "components/userConsentEditor"
import { ContestConfigOnboardingScreenData } from "domains/managingJson/store/types"
import { FC, useEffect, useState } from "react"

interface props {
  step: ContestConfigOnboardingScreenData
  index: number
  onboardingSteps: ContestConfigOnboardingScreenData[]
  setOnboardingSteps: React.Dispatch<React.SetStateAction<ContestConfigOnboardingScreenData[]>>
  form: FormInstance<any>
}

export const OnboardingStep: FC<props> = ({ step, index, onboardingSteps, setOnboardingSteps, form }) => {

  const deleteStep = (index: number) => {
    const onboardingStepsFiltered = onboardingSteps.filter((_, i) => i !== index)
    setOnboardingSteps(onboardingStepsFiltered)
    for (let i = index; i < onboardingStepsFiltered.length; i++) {
      form.setFieldValue(`Title${i+1}`, onboardingStepsFiltered[i].title)
      form.setFieldValue(`Text${i+1}`, onboardingStepsFiltered[i].text)
    }
  }

  const [consentRows, setConsentRows] = useState<ConsentRow[] | undefined>(step.consentRows)

  useEffect(() => {
    setOnboardingSteps(e => {
      e[index].consentRows = consentRows

      return e
    })
  }, [consentRows])

  return (
    <div style={{ width: "30%", marginRight: "16px", marginBottom: "16px" }}>
      <div style={{ padding: "16px 16px 16px", border: "1px solid #F0F0F0" }}>
        <div style={{ fontWeight: "500" }}>Onboarding {index + 1}</div>
        <UploadComponent
          image={step.image}
          setImage={e => {
            onboardingSteps[index].image = e
            setOnboardingSteps([...onboardingSteps])
          }}
          placeholder=""
          title=""
        />
        <div style={{ background: "white", marginTop: "32px" }}>
          <div style={{ width: "100%", marginRight: "16px" }}>
              Title
            <FormElement type="input" label="" name={`Title${index+1}`} initialValue={step.title} />
          </div>
          <div style={{ width: "100%", marginRight: "16px" }}>
              Text
            <FormElement type="text" label="" name={`Text${index+1}`} initialValue={step.text} />
          </div>
        </div>
        <UserConsentEditor
          rows={consentRows ?? []}
          onChange={value => setConsentRows(value)}
          withCustomId
        />
      </div>
      <div
        style={{
          margin: "",
          color: "#FF0000",
          border: "1px dotted  #D9D9D9",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => deleteStep(index)}
      ><DeleteOutlined style={{}} />Delete
      </div>
    </div>
  )
}
