import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { Button, Modal, Table } from "antd"
import { notificationsColumns } from "./columns"
import { NotificationCreate } from "./notification.create"
import { Column } from "abstract/types/column.type"
import { DeleteIconButton, showDeleteConfirm, CreateNewElement } from "components"
import { NotificationsResponse } from "../store/types"
import { NotificationData } from "./notification.view"
import { SendOutlined } from "@ant-design/icons"

export const NotificationsList: FC = observer(() => {
  const { dataStore: { notificationsStore } } = useStore()

  const [isVisibleInfo, setInfoVisibility] = useState<boolean>(false)

  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      notificationsStore.pagination.page = page
    },
    total: notificationsStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | null>(null)

  useEffect(() => {
    notificationsStore.getData()
  }, [notificationsStore])

  const onClose = () => {
    notificationsStore.clearSelectedElement()
    setEditCreateVisibility(null)
    setInfoVisibility(false)
  }

  const columns: Column[] = [
    ...notificationsColumns,
    {
      title: "Send",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (_: any, row: NotificationsResponse) => (
        <Button
          disabled={row.sendStatus !== "NOT_STARTED"}
          type="primary"
          onClick={e => {
            e.stopPropagation()
            notificationsStore.sendNotificationById(row.id)
          }}
          icon={<SendOutlined />}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (_: any, row: NotificationsResponse) => {
        return (
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(`market item: ${row.title}`, undefined, async() => {
                notificationsStore.funcAfterSuccess = async() => await notificationsStore.getData()

                notificationsStore.deleteNotificationById(row.id)
              })
            }}
          />
        )
      },
    },
  ]

  return (
    <>
      <CreateNewElement
        onClick={() => setEditCreateVisibility("create")}
        valueText="notification"
      />

      <Table
        dataSource={notificationsStore.notifications}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={notificationsStore.isLoading}
        onRow={(record: NotificationsResponse) => ({
          onClick: () => {
            notificationsStore.selectedElement = record
            setInfoVisibility(true)
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title="Create notification"
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <NotificationCreate onClose={onClose} />
        </Modal>
      )}
      <Modal visible={isVisibleInfo} footer={null} onCancel={onClose}>
        <NotificationData data={notificationsStore.selectedElement} />
      </Modal>
    </>
  )
})
