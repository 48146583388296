export enum PopupRewardTypesEnum {
  BUFFS = "BUFFS",
  PREMIUM = "PREMIUM",
  SPIN_COLLECT_GOLDEN_TICKET = "SPIN_COLLECT_GOLDEN_TICKET",
  BOOST_TIMED = "BOOST_TIMED",
  RAFFLE_TICKETS = "RAFFLE_TICKETS",
}

export enum PremiumType {
  PREMIUM = "PREMIUM",
  PREMIUM_PLUS = "PREMIUM_PLUS",
  PREMIUM_ELITE = "PREMIUM_ELITE",
}

export enum DurationPeriod {
  HOURS = "HOURS",
  DAYS = "DAYS",
}

export enum PersonalBoosterType {
  UNKNOWN = "UNKNOWN",
  BOOST_TIMED = "BOOST_TIMED",
  BOOST_SEASONAL = "BOOST_SEASONAL",
}

export type BuffsReward = {
  rewardType: PopupRewardTypesEnum.BUFFS;
  amount: number;
  duration?: never;
  period?: never;
  premiumType?: never;
  count?: never;
  boosterType?: never;
  percentage?: never;
}

export type PremiumReward = {
  rewardType: PopupRewardTypesEnum.PREMIUM;
  premiumType: PremiumType;
  duration: number;
  period: DurationPeriod;
  amount?: never;
  count?: never;
  boosterType?: never;
  percentage?: never;
}

export type SpinCollectGoldenTicketReward = {
  rewardType: PopupRewardTypesEnum.SPIN_COLLECT_GOLDEN_TICKET;
  count: number;
  amount?: never;
  duration?: never;
  period?: never;
  premiumType?: never;
  boosterType?: never;
  percentage?: never;
}

export type BoostTimedReward = {
  rewardType: PopupRewardTypesEnum.BOOST_TIMED;
  duration: number;
  period: DurationPeriod;
  boosterType: PersonalBoosterType;
  percentage: number;
  amount?: never;
  premiumType?: never;
  count?: never;
}

export type RaffleTicketReward = {
  rewardType: PopupRewardTypesEnum.RAFFLE_TICKETS;
  count: number;
  amount?: never;
  duration?: never;
  period?: never;
  premiumType?: never;
  boosterType?: never;
  percentage?: never;
}

export type PopupReward = BuffsReward | PremiumReward | SpinCollectGoldenTicketReward | BoostTimedReward | RaffleTicketReward
