import React, { FC } from "react"
import { Form } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { FormElement, SubmitCancelButtons } from "components"

export const ContestNameEdit: FC<{ onClose: () => void }> = observer(({ onClose }) => {
  const {
    dataStore: {
      managingJsonStore: { selectedElement: { name, data, type } },
      managingJsonStore,
    },
  } = useStore()
  const [form] = Form.useForm()

  const onFinishHandler = async(e: {name: string, "new name": string}) => {
    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      form.resetFields()
      await managingJsonStore.getData()
    }
    const contest = { type: type, oldName: e.name, newName: e["new name"] }
    await managingJsonStore.updateName(contest)
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (

    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} form={form}>
      <FormElement type="readOnly" label="Name" name="name" initialValue={name} required />
      <FormElement type="input" label="New name" name="new name" initialValue={name} required />

      <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
    </Form>
  )
})
