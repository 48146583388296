import { computed, makeObservable, observable, transaction } from "mobx"

import { getBlacklist, deleteBlacklist, postBlacklist } from "domains/blacklist/api/blacklist.call"
import { BaseStore } from "stores/base.store"
import { RootStore } from "stores/root.store"
import { BlacklistType } from "./types"

export class Blacklist {
  constructor(public fingerprint?: string[], public ip?: string[], public country?: string[]) {}
}

export class BlacklistStore extends BaseStore {
  @observable
  public isLoading: boolean = false

  @observable
  private _blacklist: Blacklist = { fingerprint: [], ip: [], country: [] }

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getBlacklist(): Promise<Blacklist> {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getBlacklist)
    transaction(() => {
      this._blacklist = res
    })

    return res
  }
  public async deleteBlacklist(type: BlacklistType, value: string) {
    await this.defaultFunction(deleteBlacklist, type, value)
  }
  public async postBlacklist(values: Blacklist) {
    await this.defaultFunction(postBlacklist, values)
  }

  // @action
  // setFilter = (filter: string) => {
  //   this.filter = filter;
  //   this.pagination.page = 1;
  // };

  @computed
  get blacklist(): Blacklist {
    return this._blacklist
  }
}
