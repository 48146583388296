import React, { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Form, Input, Switch, Select } from "antd"
import { observer } from "mobx-react"

import { notifications } from "notifications"
import { TextsConfig } from "domains/managingJson/store/types"
import { ApprovedTextsNames } from "domains/managingJson/constants"
import { LaunchGameOnlineMode } from "./launchGameOnlineMode"
import { SubmitCancelButtons } from "components"

export const EditTexts: FC<{
  editType: "create" | "edit";
  onClose: () => void;
  configType: "texts";
}> = observer(({ editType, configType, onClose }) => {
  const {
    dataStore: {
      managingJsonStore,
      managingJsonStore: { selectedElement },
    },
  } = useStore()

  const data = selectedElement.data as TextsConfig
  const name = selectedElement.name

  const [form] = Form.useForm()

  const [nameSwitch, setnameSwitch] = useState(false)
  const [nameEl, setnameEl] = useState(name || "")
  const [textsForLaunchGameOnline, settextsForLaunchGameOnline] = useState<
    { title: string; subtitle: string }[]
  >(data?.texts || [{ title: "", subtitle: "" }])

  const onFinishHandler = async(event: any) => {
    const launchGameOnlineMode: { title: string; subtitle: string }[]
      = textsForLaunchGameOnline.map((_, id) => {
        return {
          title: event[`title${id}`],
          subtitle: event[`subtitle${id}`],
        }
      })
    if (launchGameOnlineMode.length === 0) {
      return notifications.error("Input minimum one title/subtitle")
    }
    const data = { texts: launchGameOnlineMode }
    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      await managingJsonStore.getData()
    }

    editType === "edit"
      ? await managingJsonStore.update({
        name: event.name,
        type: configType,
        data,
      })
      : await managingJsonStore.create({
        name: event.name,
        type: configType,
        data,
      })
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <div style={{ display: "flex", gap: "10px" }}>
        <div>
          Select/Input
          <Switch checked={nameSwitch} onChange={setnameSwitch} />
        </div>
        <Form.Item
          label="Name"
          name="name"
          initialValue={name}
          required
          style={{ width: "100%", marginBottom: "20px" }}
        >
          {!nameSwitch ? (
            <Select disabled={editType === "edit"} onChange={setnameEl}>
              {ApprovedTextsNames.map(action => (
                <Select.Option key={action} value={action}>
                  {action}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input style={{ width: "100%" }} disabled={editType === "edit"} />
          )}
        </Form.Item>
      </div>

      {nameEl === "launchGameOnlineMode" ? (
        <LaunchGameOnlineMode
          texts={textsForLaunchGameOnline}
          form={form}
          settexts={settextsForLaunchGameOnline}
        />
      ) : (
        <div />
      )}

      <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
    </Form>
  )
})
