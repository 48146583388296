import { StructureDefinition } from "components/structuredJsonInput"

export const paramStructuresByType: Record<string, StructureDefinition> = {
  revu: {
    id: "revu",
    definition: {
      numOffers: { type: "number", label: "Max Offers" },
      wallId: { type: "string", label: "Wall ID" },
    },
  },
  "revu-iframe": {
    id: "revu-iframe",
    definition: { wallId: { type: "string", label: "Wall ID" } },
  },
  cpx: {
    id: "cpx",
    definition: {
      appId: { type: "number", label: "App ID" },
      numOffers: { type: "number", label: "Max Offers" },
      orderBy: {
        type: "select",
        label: "Order By",
        options: [
          { value: 1, title: "Best Score" },
          { value: 2, title: "Best Money" },
          { value: 3, title: "Best Conversion Rate" },
        ],
      },
    },
  },
}

export const providerNamesByType = { revu: "RevU", "revu-iframe": "RevU Iframe", cpx: "CPX Script" }

