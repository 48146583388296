import { Button, Popconfirm, Space, Table, Tag, Tooltip, Typography } from "antd"
import { observer } from "mobx-react-lite"
import { notifications } from "notifications"
import { useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { gameNames } from "abstract/constants/game-ids"
import { getCountryNameById } from "utils/countryCodes"
import { ColumnsType } from "antd/lib/table"
import { useNavigate } from "react-router-dom"
import { ConfigItem, Pixel, SpecialEventsConfigType } from "domains/managingJson/store/types"
const { Title } = Typography

type NotificationSrc = {
  src?: string;
}

type ImagesByGeo = {
  country: string;
  imageSources: NotificationSrc[];
}

export type Notification = {
  uid: string;

  triggerEventName?: string;
  spawnProbability?: number;

  trackingPixels?: Pixel[];
  ignoreSpawnCap?: boolean;

  buffsReward?: number;
  duration?: number;
  triggerDelay?: number;

  animationType?: string;
  animationDuration?: number;

  customStyles?: string;

  sourceImagesByGeo?: ImagesByGeo[];
  contestPointsReward?: number;
};

export type NotificationsPerGame = {
  gameId: number;
  notificationsSpawnCap?: number;
  notifications?: Notification[];
}

export type IngameOverlayType = "stacking" | "generic";

export type SpecialEvent = {
  uid: string;
  name: string;
  games: string[];
  restrictedToCountries?: string[];
  startDate: string;
  endDate: string;
  notificationsPerGame: NotificationsPerGame[];
  ingameOverlayType: IngameOverlayType;
};

export const SpecialEventsPage = observer(() => {
  const navigate = useNavigate()
  const { dataStore: { managingJsonStore } } = useStore()
  const [data, setData] = useState<SpecialEventsConfigType | undefined>()
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const eventsData = managingJsonStore?.itemTypes?.filter(
      item => item.type === "specialEvents",
    ) as ConfigItem<"specialEvents">[]
    if (eventsData && eventsData.length > 0) {
      const data = eventsData[0].data
      setData(data)
    }
  }, [managingJsonStore.itemTypes])

  const getStoreData = (): SpecialEventsConfigType | undefined => {
    const eventsData = managingJsonStore?.itemTypes?.filter(
      item => item.type === "specialEvents",
    ) as ConfigItem<"specialEvents">[]

    return eventsData[0]?.data
  }

  const updateStore = async(
    callback: (prev: SpecialEventsConfigType | undefined) => SpecialEventsConfigType,
  ) => {
    const previousData = getStoreData()
    const newData = callback(previousData)

    previousData
      ? await managingJsonStore.update({
        name: "specialEvents",
        type: "specialEvents",
        data: newData,
      })
      : await managingJsonStore.create({
        name: "specialEvents",
        type: "specialEvents",
        data: newData,
      })
  }

  const deleteSpecialEvent = async(uid: string) => {
    managingJsonStore.funcAfterSuccess = async() => {
      notifications.success("Deleted successfully")
      await managingJsonStore.getData()
    }
    await updateStore(prev => {
      const specialEvents = prev?.specialEvents?.filter(event => event.uid !== uid)

      return { specialEvents }
    })
  }

  const edit = (event: SpecialEvent) => {
    navigate(event.uid)
  }

  const columns: ColumnsType<SpecialEvent> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Games",
      dataIndex: "games",
      key: "games",
      width: "20%",
      render: (games: string[]) => (
        <>
          {games.map(game => (
            <Tooltip key={game} title={`ID: ${game}`}>
              <Tag>{gameNames[game]}</Tag>
            </Tooltip>
          ))}
        </>
      ),
    },
    {
      title: "Restricted Countries",
      dataIndex: "restrictedToCountries",
      key: "restrictedToCountries",
      width: "20%",
      render: (countries: string[]) => (
        <>
          {countries.map(country => (
            <Tooltip key={country} title={getCountryNameById(country)}>
              <Tag>{country}</Tag>
            </Tooltip>
          ))}
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "20%",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "20%",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <div>
          <Space>
            <Button type="primary" onClick={() => edit(record)}>
              Edit
            </Button>
            <Popconfirm
              title="Do you really want to delete this special event?"
              placement="leftBottom"
              onConfirm={async() => {
                await deleteSpecialEvent(record.uid)
                setConfirmLoading(false)
              }}
              okButtonProps={{ loading: confirmLoading }}
            >
              <Button danger ghost>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ]

  const specialEventsWithKeys = data?.specialEvents?.map(event => ({
    ...event,
    key: event.uid,
  }))

  return (
    <div>
      <Title level={2}>Special Events</Title>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Table<SpecialEvent> dataSource={specialEventsWithKeys} columns={columns} />
        <Button
          type="primary"
          onClick={() => {
            navigate("new")
          }}
        >
          Create new event
        </Button>
      </Space>
    </div>
  )
})
