import { Column } from "abstract/types/column.type"
import { defaultFormat } from "utils/date"
import { Tag } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"

export const giftoinColumns: Column[] = [
  // { title: "id", key: "id", dataIndex: "id" },
  { title: "name", key: "name", dataIndex: "name" },
  // { title: "externalCampaignId", key: "externalCampaignId", dataIndex: "externalCampaignId" },
  { title: "event", key: "event", dataIndex: "event" },
  { title: "probability", key: "probability", dataIndex: "probability", width: 100 },
  // {
  //   title: "geo restriction",
  //   key: "geoRestriction",
  //   dataIndex: "geoRestriction",
  //   render: (geoRestriction: string[]) => geoRestriction.join(","),
  // },
  // {
  //   title: "premium restriction",
  //   key: "premiumRestriction",
  //   dataIndex: "premiumRestriction",
  //   render: (premiumRestriction: string[]) => premiumRestriction.join(" "),
  // },
  {
    title: "start",
    key: "periodStart",
    dataIndex: "periodStart",
    render: text => {
      return (
        <div style={{ display: "grid", gap: "6px" }}>
          <Tag color="green">{defaultFormat(text)}</Tag>
        </div>
      )
    },
  },
  {
    title: "finish",
    key: "periodFinish",
    dataIndex: "periodFinish",
    render: text => {
      return (
        <div style={{ display: "grid", gap: "6px" }}>
          <Tag color="red">{defaultFormat(text)}</Tag>
        </div>
      )
    },
  },
  {
    title: "enabled",
    key: "enabled",
    dataIndex: "enabled",
    width: 85,
    render: (enabled: boolean) => enabled
      ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleFilled style={{ color: "red" }} />,
  },
]
