import { GetItemsFilter, ChildCodeType, ChildToUpdate } from "domains/promocodes/store/types"
import { computed, observable, transaction, makeObservable } from "mobx"
import {
  createItem,
  deleteItem,
  getChildCodes,
  updateItem,
  getCsv,
} from "domains/promocodes/api/child-codes.api"

import { PaginationStore } from "stores/data/pagination.store"
import { RootStore } from "stores/root.store"

export class ChildPromocodesStore extends PaginationStore<ChildCodeType, GetItemsFilter> {
  @observable
  private _itemTypes: ChildCodeType[] = [] as ChildCodeType[]

  @observable
  private _selectedElement: ChildCodeType = {} as ChildCodeType

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<ChildCodeType[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getChildCodes, this.filter, page, limit)

    transaction(() => {
      this._itemTypes = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: ChildToUpdate) {
    await this.defaultFunction(updateItem, this._selectedElement.id, data)
  }

  async create(data: ChildToUpdate) {
    await this.defaultFunction(createItem, data)
  }

  async deleteById(id: string) {
    await this.defaultFunction(deleteItem, id)
  }

  async getCsv(id: string) {
    const res = await this.defaultFunction(getCsv, id)

    return res.url
  }

  @computed
  get itemTypes(): ChildCodeType[] {
    return this._itemTypes
  }

  @computed
  get selectedElement(): ChildCodeType {
    return this._selectedElement
  }

  set selectedElement(value: ChildCodeType) {
    this._selectedElement = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as ChildCodeType
  }
}
