import { Column } from "abstract/types/column.type"
import { Tag } from "antd"
import { defaultFormat } from "utils/date"

export const childPromocodeColumns: Column[] = [
  { title: "ID", key: "id", dataIndex: "id" },
  { title: "Code", key: "code", dataIndex: "code", width: 100 },
  {
    title: "Assignee",
    key: "assignee",
    dataIndex: ["childPromoCodeRedeem", "accountLogin"],
    width: 100,
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => <Tag color="green">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (el: Date) => <Tag color="blue">{defaultFormat(el)}</Tag>,
  },
]
