import { Button, Space } from "antd"
import { ResponsivePopupEditModal } from "./components/ResponsivePopupEditModal"
import { useState } from "react"
import { ResponsivePopupTable } from "./components/ResponsivePopupTable"
import { ResponsiveTemplate } from "domains/popup-templates/store/ResponsiveTemplate.store"

export const ResponsiveTemplatesTable = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editedPopup, setEditedPopup] = useState<ResponsiveTemplate | undefined>(undefined)

  const createNewPopup = () => {
    setIsEditModalOpen(true)
    setEditedPopup(undefined)
  }

  const handleModalClose = () => {
    setIsEditModalOpen(false)
    setEditedPopup(undefined)
  }

  const editPopup = (popup: ResponsiveTemplate) => {
    setEditedPopup(popup)
    setIsEditModalOpen(true)
  }

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Button type="primary" onClick={createNewPopup}>
          Create new responsive popup
        </Button>
        <ResponsivePopupTable editPopup={editPopup} />
      </Space>
      {isEditModalOpen && <ResponsivePopupEditModal onClose={handleModalClose} editedTemplate={editedPopup} />}
    </div>
  )
}
