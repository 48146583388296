import { FC, useEffect } from "react"
import { observer } from "mobx-react"
import { Table } from "antd"

import { useStore } from "stores/utils/use-store"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { purchasedGiftCardsColumns } from "domains/purchased-gift-cards/components/purchased-gift-cards.columns"

export const PurchasedGiftCardsList: FC = observer(() => {
  const { dataStore: { purchasedGiftCardsStore } } = useStore()

  useEffect(() => {
    purchasedGiftCardsStore.getData()
  }, [purchasedGiftCardsStore])

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      purchasedGiftCardsStore.pagination = {
        page,
        limit: pageSize || purchasedGiftCardsStore.pagination.limit,
      }
    },
    total: purchasedGiftCardsStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }

  return (
    <Table
      dataSource={purchasedGiftCardsStore.giftCards}
      columns={purchasedGiftCardsColumns}
      rowKey="id"
      pagination={paginationOptions}
      loading={purchasedGiftCardsStore.isLoading}
    />
  )
})
