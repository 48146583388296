import { RootStore } from "stores/root.store"
import { RewardType, RewardToUpdate, RewardPrize, RewardBuffType } from "./types"
import { computed, observable, makeObservable, transaction } from "mobx"
import { createRewardsType, getRewardsTypes, updateRewardsType } from "../api/rewards.api"
import { PaginationStore } from "stores/data/pagination.store"

export class PromocodesRewardsStore extends PaginationStore<RewardType> {
  @observable
  private _rewards: RewardType[] = [] as RewardType[]

  @observable
  private _order: any = {}

  @observable
  private _selectedElement: RewardType = {} as RewardType

  @observable
  private _rewardPrizes: RewardPrize[] = [] as RewardPrize[]

  @observable
  private _rewardBuffTypes: RewardBuffType[] = [] as RewardBuffType[]

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getData(order?: any) {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getRewardsTypes, page, limit, order)

    transaction(() => {
      this._rewards = res.data
      this.total = res.total
      this._order = order
    })

    return res.data
  }
  @computed
  get rewards(): RewardType[] {
    return this._rewards
  }

  async update(data: RewardToUpdate) {
    await this.defaultFunction(updateRewardsType, this._selectedElement.id, data)
  }
  async create(data: RewardToUpdate) {
    await this.defaultFunction(createRewardsType, data)
  }

  @computed
  get selectedElement(): RewardType {
    return this._selectedElement
  }

  set selectedElement(value: RewardType) {
    this._selectedElement = value
  }

  @computed
  get rewardPrizes(): RewardPrize[] {
    return this._rewardPrizes
  }

  @computed
  get rewardBuffTypes(): RewardBuffType[] {
    return this._rewardBuffTypes
  }

  public clearSelectedElement() {
    this._selectedElement = {} as RewardType
  }
}
