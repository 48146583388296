import React, { FC, useState } from "react"
import { DatePicker, Form, Input, Radio, Select, Switch } from "antd"
import { observer } from "mobx-react"
import moment from "moment"
import { useStore } from "stores/utils/use-store"
import { Banner } from "../store/banners.store"
import { SubmitCancelButtons, FormElement, UploadComponent, DefaultLinksRouting, PixelsEditorV2 } from "components"
import { Pixel } from "domains/managingJson/store/types"
import GeoPickerElement from "components/geoPicker"

const platforms = ["pc", "ios", "android", "mobile"]

export const BannerEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      bannersStore: {
        selectedElement: {
          name,
          link,
          isActive,
          countries,
          priority,
          activeUntil,
          img,
          platform,
          excludeCountries,
          startDate,
          userTypes,
          daysSinceRegistration,
          type,
          pixels,
        },
      },
      bannersStore,
    },
  } = useStore()
  const [geoType, setgeoType] = useState(countries && countries.length > 0
    ? "WHITE"
    : excludeCountries && excludeCountries.length > 0
      ? "BLACK"
      : "ALL")
  const [image, setImage] = useState(img)
  const [typeV, setType] = useState(type || "IMAGE")
  const [curPlatform, setcurPlatform] = useState(platform)
  const [trackingPixels, setTrackingPixels] = useState<Pixel[] | undefined>(pixels)

  const onFinishHandler = async(e: Banner) => {
    e.img = image
    e.type = typeV
    e.excludeCountries
        = geoType === "BLACK" && e.countries && e.countries?.length > 0
        ? e.countries
        : null
    e.countries
        = geoType === "WHITE" && e.countries && e.countries?.length > 0
        ? e.countries
        : null

    e.daysSinceRegistration
        = String(e.daysSinceRegistration).length === 0 || Number(e.daysSinceRegistration) === 0
        ? null
        : Number(e.daysSinceRegistration)

    e.pixels = trackingPixels ?? []

    if (!e.img) {
      bannersStore.setImageValidationError()

      return
    }

    e.countries = e.countries || []
    if (e.userTypes && e.userTypes.length < 1) e.userTypes = null

    if (e.countries.length < 1) e.countries = null
    else e.countries = e.countries
      .map(item => item.toUpperCase())
      .filter((value, index, self) => self.indexOf(value) === index)
    if (!e.activeUntil) e.activeUntil = null
    if (!e.startDate) e.startDate = null

    if (editType === "edit") {
      if (e.isActive === undefined) e.isActive = isActive
    } else {
      if (e.isActive === undefined) e.isActive = false
    }
    bannersStore.funcAfterSuccess = async() => {
      onClose()
      await bannersStore.getData()
    }

    editType === "edit" ? await bannersStore.update(e) : await bannersStore.create(e)
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} id="createBanner">
      <FormElement
        label="Name"
        name="name"
        required
        initialValue={name}
        style={{ width: "100%" }}
        type="input"
      />
      <Form.Item
        label="Platform"
        name="platform"
        initialValue={curPlatform}
        rules={[
          {
            required: true,
            message: "Please select platform!",
          },
        ]}
      >
        <Select onChange={e => setcurPlatform(e)}>
          {platforms.map(region => (
            <Select.Option key={region} value={region}>
              {region.toUpperCase()}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <DefaultLinksRouting
        link={link || ""}
        formItemName="link"
        mobile={curPlatform !== "pc"}
      />
      <Form.Item
        label="Start Date"
        name="startDate"
        style={{ width: "100%" }}
        initialValue={startDate ? moment(startDate as Date) : ""}
      >
        <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          style={{ width: "100%" }}
          placeholder="Select Time"
        />
      </Form.Item>
      <Form.Item
        label="Expire"
        name="activeUntil"
        initialValue={activeUntil ? moment(activeUntil as Date) : ""}
        style={{ width: "100%" }}
      >
        <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="Select Time"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <FormElement
        label="Priority"
        name="priority"
        initialValue={priority}
        required
        props={{ style: { width: "100%" } }}
        type="inputNumber"
      />

      <Form.Item
        label="Days Since Registration"
        name="daysSinceRegistration"
        initialValue={daysSinceRegistration}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item label="User Types" name="userTypes" initialValue={userTypes || undefined}>
        <Select value={link} mode="multiple">
          {["REGULAR", "PREMIUM_PLUS", "PREMIUM_ELITE"]?.map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="GEO limits" initialValue={geoType}>
        <Radio.Group onChange={e => setgeoType(e.target.value)} value={geoType}>
          <Radio value="ALL" defaultChecked>
            All
          </Radio>
          <Radio value="WHITE">Whitelist GEOs</Radio>
          <Radio value="BLACK">Blacklist GEOs</Radio>
        </Radio.Group>
      </Form.Item>
      {geoType !== "ALL" && (
        <GeoPickerElement label="Choose GEOs" name="countries" initialValue={(geoType === "BLACK" ? excludeCountries : countries) || []} />
      )}

      <Form.Item label="Active" name="isActive" valuePropName="checked">
        <Switch defaultChecked={(isActive as boolean) || false} />
      </Form.Item>

      <Form.Item required label="Type" name="type" initialValue={typeV}>
        <Radio.Group
          value={typeV}
          defaultValue={undefined}
          onChange={e => {
            setType(e.target.value)
          }}
        >
          {["IMAGE", "VIDEO"].map(item => (
            <Radio key={item} value={item}>{item}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <UploadComponent
        image={image}
        setImage={setImage}
        // uploadStyle={{ className: 'avatar-uploader', listType: 'picture' }}
        imgStyle={{ width: "100%", textAlign: "center" }}
        placeholder="Upload item image"
        placeHolderStyle={{ display: "flex", flexDirection: "row" }}
      />

      {bannersStore.isValidationError ? (
        <span style={{ fontSize: "10px", color: "red" }}>Image is requred</span>
      ) : (
        <div style={{ height: "15px" }} />
      )}

      <h2>Tracking pixels</h2>
      <PixelsEditorV2
        pixels={trackingPixels ?? []}
        onChange={values => setTrackingPixels(values)}
      />

      <SubmitCancelButtons onClose={onClose} isLoading={bannersStore.isLoading} />
    </Form>
  )
})
