import React, { FC } from "react"

import { Divider } from "antd"
import { FormElement } from "components"
import { PointAwarding } from "domains/managingJson/store/types"

export const PointsAwarding: FC<{ data?: PointAwarding; form: any }> = ({
  data,
  form,
}) => {
  return (
    <>
      <Divider>Race points </Divider>
      <div>
        <FormElement
          type="input"
          label="Max Points per Race "
          name={["pointAwarding", "maxpoints"]}
          initialValue={data?.maxpoints}
          required
        />

        <FormElement
          type="text"
          label="Time for Max Points "
          name={["pointAwarding", "maxpointsTime"]}
          initialValue={data?.maxpointsTime}
          required
        />

        <FormElement
          type="text"
          label="Seconds to lose point "
          name={["pointAwarding", "secondsToLosePoint"]}
          initialValue={data?.secondsToLosePoint}
          required
        />

        <FormElement
          type="text"
          label="Multiplayer Factor "
          name={["pointAwarding", "multiplayerFactor"]}
          initialValue={data?.multiplayerFactor}
          required
        />
      </div>
    </>
  )
}
