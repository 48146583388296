import React, { FC, useEffect, useState } from "react"
import { Button, Form, Modal, Table } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { RafflePrizes } from "../../store/types"
import s from "../styles.module.css"
import { DeleteIconButton, showDeleteConfirm, UserIconButton, showInfoConfirm } from "components"

import { CopyOutlined, LeftOutlined } from "@ant-design/icons"
import { notifications } from "notifications"
import { rafflesPrizesColumns } from "./prizes-columns"
import { WinnerCreation } from "../WinnerCreation"
import { SubmitCancelButtons } from "components"

export const PrizeCreation: FC<{ closeModal: () => void; editType: "create" | "edit" }> = observer(({ editType, closeModal }) => {
  const [needSaving, setneedSaving] = useState<boolean>(false)
  const [clickClose, setclickClose] = useState<boolean>(false)

  const {
    dataStore: {
      rafflesStore: { prizes },
      rafflesStore,
      codesStore,
    },
  } = useStore()

  const [winnerPopup, setWinnerPopup] = useState<RafflePrizes | null>(null)

  useEffect(() => {
    codesStore.getData()
  }, [codesStore])

  useEffect(() => {
    if (editType) {
      rafflesStore.getPrizes()
    }
  }, [editType])

  const [prizesNew, setPrizesNew] = useState<RafflePrizes[]>(prizes || [])

  const beforeClose = () => {
    if (!needSaving) {
      closeModal()
      rafflesStore.isLoading = false
      rafflesStore.prizes = []
      setPrizesNew([])
    } else {
      setclickClose(true)
    }
  }
  useEffect(() => {
    if (prizes && prizes.length > 0) {
      let obj: any = []
      prizes.map(item => {
        let val: any = {}
        val = { ...item }
        val["prizeCondition.duration"] = item?.prizeType?.prizeCondition?.duration
        val["prizeCondition.periodType"] = item?.prizeType?.prizeCondition?.periodType
        val["prizeCondition.percentage"] = item?.prizeType?.prizeCondition?.percentage
        val["prizeCondition.codeInfoId"] = item?.prizeType?.prizeCondition?.codeInfoId
        val["prizeCondition.codeId"] = item?.prizeType?.prizeCondition?.codeId

        val["prizeType.type"] = item?.prizeType?.type
        val["prizeType.countOfPrizes"] = item?.prizeType?.countOfPrizes
        obj = [...obj, val]

        return null
      })
      setPrizesNew(obj?.reverse())
    }
  }, [prizes])

  const onFinishHandler = async() => {
    let editedPrizes: any = []
    let newPrizes: any = []
    let filledAllFields = true
    prizesNew.map(item => {
      if (filledAllFields) {
        const obj: any = {}
        // obj.place = item.place;
        obj.raffleId = item?.raffleId
        obj.id = item?.id
        if (!item["prizeType.type"]) {
          filledAllFields = false

          return notifications.error("Fill all fields please")
        }
        if (
          item["prizeCondition.duration"]
            || item["prizeCondition.periodType"]
            || item["prizeCondition.percentage"]
            || item["prizeCondition.codeInfoId"]
            || item["prizeCondition.codeId"]
        ) {
          obj.prizeCondition = {}
          obj.prizeCondition.periodType = item["prizeCondition.periodType"] || undefined
          obj.prizeCondition.duration = item["prizeCondition.duration"] || undefined
          obj.prizeCondition.percentage = item["prizeCondition.percentage"] || undefined
          obj.prizeCondition.codeInfoId = item["prizeCondition.codeInfoId"] || undefined
          obj.prizeCondition.codeId = item["prizeCondition.codeId"] || undefined
        }

        if (item["prizeType.type"] || item["prizeType.countOfPrizes"]) {
          obj.prizeType = {}
          obj.prizeType.type = item["prizeType.type"]
          obj.prizeType.countOfPrizes = item["prizeType.countOfPrizes"] || 1
        }
        if (item.new) {
          newPrizes = [...newPrizes, obj]
        } else if (item.edited) {
          editedPrizes = [...editedPrizes, obj]
        }
      }

      return null
    })
    let res
    if (filledAllFields) {
      rafflesStore.resetFuncAfterSuccess()
      newPrizes.map(async(item, id) => {
        setTimeout(async() => {
          res = await rafflesStore.createPrize(item)
          res?.message.map(el => notifications.error(`Error in ${item.place}:${el}`))
        }, id * 300)
      })
      editedPrizes.map(async(item, id) => {
        setTimeout(async() => {
          res = await rafflesStore.updatePrize(item?.id, item)
          res?.message.map(el => notifications.error(`Error in ${item.place}:${el}`))
        }, id * 300)
      })
      setTimeout(() => {
        rafflesStore.getPrizes()
      }, 1000)
      setneedSaving(false)
      setclickClose(false)
    }
  }

  const updatePrizesData = (id: number, key: any, value: any) => {
    const newPrizes = [...prizesNew]
    newPrizes[id] = { ...newPrizes[id], [key]: value, edited: true }
    setPrizesNew(newPrizes)
    setneedSaving(true)
  }
  const columns = [
    ...rafflesPrizesColumns(codesStore, prizesNew, updatePrizesData),
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "150px",
      render: (_: any, row: any, id: number) => (
        <div style={{ display: "flex", gap: 10 }}>
          <div
            onClick={() => {
              const newPrizes = [
                ...prizesNew,
                {
                  ...prizesNew[id],
                  new: true,
                  edited: true,
                  id: undefined,
                  raffleWinner: undefined,
                  place: prizesNew.length + 1,
                },
              ]

              setPrizesNew(newPrizes)
            }}
            style={{
              background: "#1890FF",
              width: 32,
              height: 32,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <CopyOutlined
              style={{ color: "white", transform: "translate(8px, 2px)" }}
              title="Clone"
            />
          </div>
          {!row.new && (
            <UserIconButton title="Assign winner" onClick={() => setWinnerPopup(row)} />
          )}
          <DeleteIconButton
            title="Delete prize"
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(row.place, undefined, async() => {
                if (row.id) {
                  await rafflesStore.deletePrize(row.id)
                  await rafflesStore.getPrizes()
                } else {
                  const newPrizes = [...prizesNew]
                  setPrizesNew(newPrizes.filter(item => item.place !== row.place))
                }
              })
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <Modal
      visible
      footer={null}
      onCancel={() => beforeClose()}
      width={930}
      bodyStyle={{ padding: "24px 0", zIndex: 1 }}
    >
      <>
        <Form onFinish={onFinishHandler} id="rafflePrizeCreation">
          <Button type="default" style={{ marginBottom: "10px" }}>
            <LeftOutlined />
            Back
          </Button>
          <br />
          <Button
            type="primary"
            style={{ marginBottom: "10px" }}
            onClick={() => setPrizesNew([
              ...prizesNew,
              { place: prizesNew.length + 1, edited: true, new: true },
            ])}
          >
            Add new prize
          </Button>
          <Table
            dataSource={prizesNew}
            columns={columns as any}
            rowKey="id"
            loading={rafflesStore.isLoading}
            id="prizesTable"
            pagination={false}
            rowClassName={record => (record.edited ? s.edited : s.noEdited)}
          />
          <SubmitCancelButtons onClose={closeModal} isLoading={rafflesStore.isLoading} />
        </Form>
        {!!winnerPopup && (
          <Modal
            visible={!!winnerPopup}
            onCancel={() => setWinnerPopup(null)}
            width={510}
            bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
            footer={null}
            title={`Assign Winner to Place ${winnerPopup.place}`}
          >
            <WinnerCreation
              closeModal={() => setWinnerPopup(null)}
              prizeId={winnerPopup.id || ""}
              winnerData={winnerPopup.raffleWinner}
            />
          </Modal>
        )}
        {needSaving
            && clickClose
            && showInfoConfirm(closeModal, onFinishHandler, "Do you want close without saving?")}
      </>
    </Modal>
  )
})
