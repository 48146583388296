import { apiCall } from "api-calls/api-call"

export const getGiftoin = async(
  input: any,
  page: number,
  limit: number,
): Promise<{ total: number; data: any[] }> => {
  const query: { page: number; limit: number } & { filter: any } = {
    page,
    limit,
    filter: { ...input },
  }

  return apiCall({
    method: "GET",
    path: "giftoin/campaign/all",
    query,
  })
}

export const createItem = async(input: any): Promise<any> => {
  return apiCall({
    method: "POST",
    path: "giftoin/campaign",
    body: input,
  })
}

export const updateItem = async(id: string, input: any): Promise<any> => {
  return apiCall({
    method: "PUT",
    path: `giftoin/campaign/${id}`,
    body: input,
  })
}

export const deleteItem = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `giftoin/campaign/${id}`,
  })
}
