import { FC, useMemo, useState } from "react"
import Title from "antd/lib/typography/Title"
import { Button, Modal, Space, Switch, Table } from "antd"
import { IExternalOfferProvider } from "../api/types"
import { updateExternalOfferProvider } from "../api"
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import useExternalProviders from "../hooks/useExternalProviders"
import { priorityUtils } from "../utils/priority"
import { CreateExternalProvider } from "./createExternalProvider"
import { paramStructuresByType, providerNamesByType } from "../constants/externalProviders"

const InternalOWExternalProvidersPage: FC = () => {
  const { providers, updateProvider: updateProviderEntry } = useExternalProviders()
  const [editProvider, setEditProvider] = useState<IExternalOfferProvider | true>()
  const [patching, setPatching] = useState(false)

  const onPatch = (provider: IExternalOfferProvider) => {
    if (patching) return
    setPatching(true)
    updateExternalOfferProvider(provider).then(updated => {
      updateProviderEntry(updated)
    }).finally(() => {
      setPatching(false)
    })
  }

  const {
    isTopPriority,
    isLowestPriority,
    switchPriority,
  } = useMemo(() => priorityUtils(providers || [], updateExternalOfferProvider, updateProviderEntry), [providers, updateProviderEntry])

  const onSwitchPriority = (provider: IExternalOfferProvider, up: boolean) => {
    if (patching) return
    setPatching(true)
    switchPriority(provider, up).finally(() => setPatching(false))
  }

  const onClose = (updated?: IExternalOfferProvider) => {
    if (updated) {
      updateProviderEntry(updated)
    }
    setEditProvider(undefined)
  }

  return (
    <div>
      <Modal
        open={!!editProvider}
        title="Edit a Provider"
        width="700px"
        footer={null}
        onCancel={() => onClose()}
        destroyOnClose
        style={{ top: "10px" }}
      >
        <CreateExternalProvider onClose={onClose} initial={editProvider !== true ? editProvider : undefined} />
      </Modal>
      <Space
        direction="horizontal"
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: 12,
        }}
      >
        <Title level={2}>External Providers</Title>
      </Space>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Table<IExternalOfferProvider>
          dataSource={providers}
          pagination={false}
          rowKey={record => record.id}
          scroll={{ x: true }}
          columns={[
            { key: "name", title: "Title", dataIndex: "name" },
            {
              key: "type",
              title: "Provider Type",
              dataIndex: "type",
              render: type => providerNamesByType[type],
            },
            {
              key: "regions",
              title: "Regions",
              dataIndex: "geoRegions",
              render: regions => regions.join(", "),
            },
            {
              key: "params",
              title: "Params",
              dataIndex: "params",
              render: (params, { type }) => {
                const structure = paramStructuresByType[type]?.definition
                if (!structure) {
                  return "No params"
                }

                return Object.entries(params).map(([key, value]) => {
                  const keyStructure = structure[key]
                  const labelInStructure = keyStructure?.label
                  if (!labelInStructure) {
                    return null
                  }

                  let paramValue = params[key]
                  if (keyStructure?.type === "select") {
                    paramValue = keyStructure.options?.find(({ value: v }) => value === v)?.title
                  }

                  return (
                    <div key={key}>
                      <b>{structure[key].label}</b>: {paramValue}
                    </div>
                  )
                })
              },
            },
            {
              key: "active",
              title: "Active",
              dataIndex: "active",
              render: (active, advertiser) => (
                <div onClick={e => e.stopPropagation()}>
                  <Switch
                    onClick={() => onPatch({ ...advertiser, active: !advertiser.active })}
                    disabled={patching}
                    checked={active}
                  />
                </div>
              ),
            },
            {
              key: "priority",
              title: "Priority",
              dataIndex: "priority",
              render: (_, provider) => (
                <div
                  onClick={e => e.stopPropagation()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 12,
                    pointerEvents: patching ? "none" : "auto",
                  }}
                >
                  <Button
                    disabled={isTopPriority(provider)}
                    onClick={
                      () => onSwitchPriority(provider, true)
                    }
                  >
                    <ArrowUpOutlined />
                  </Button>
                  <Button
                    disabled={isLowestPriority(provider)}
                    onClick={
                      () => onSwitchPriority(provider, false)
                    }
                  >
                    <ArrowDownOutlined />
                  </Button>
                </div>
              ),
            },
          ]}
          onRow={record => ({
            style: { cursor: "pointer" },
            onClick: () => {
              setEditProvider(record)
            },
          })}
          loading={!providers}
        />
      </Space>
    </div>
  )
}

export default InternalOWExternalProvidersPage
