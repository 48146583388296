import React, { FC } from "react"

import { Divider } from "antd"
import { FormElement } from "components"

export const OnboardingCTA: FC<{ data?: any }> = ({ data }) => {
  return (
    <>
      <Divider>Onboarding CTA data </Divider>
      <div>
        <FormElement
          type="input"
          label="continueButton"
          name={["onboardingCTA", "continueButton"]}
          initialValue={data?.continueButton}
        />
        <FormElement
          type="input"
          label="joinButton"
          name={["onboardingCTA", "joinButton"]}
          initialValue={data?.joinButton}
        />

        <FormElement
          type="input"
          label="TOCtext"
          name={["onboardingCTA", "TOCtext"]}
          initialValue={data?.TOCtext}
        />
      </div>
    </>
  )
}
