import { Column } from "abstract/types/column.type"
import { Tag } from "antd"
import { defaultFormat } from "utils/date"

export const marketplaceRegionsColumns: Column[] = [
  { title: "Name", key: "name", dataIndex: "name", fixed: true },
  { title: "Tag", key: "tag", dataIndex: "tag", fixed: true },
  {
    title: "Parent",
    key: "parent",
    dataIndex: "parent",
    render: (parent: Record<string, unknown>) => parent?.name,
  },
  {
    title: "Priority",
    key: "priority",
    align: "center",
    dataIndex: "priority",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => <Tag color="green">{defaultFormat(el)}</Tag>,
  },
]
