import { computed, observable, transaction, makeObservable } from "mobx"

import {
  getTransactionsTypes,
  getAccountTransaction,
} from "domains/accounts/api/transactions.call"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import { getGameTypes } from "domains/games/api"

export type AccountTransactionsFilter = {
  lastDate?: string;
  type?: number[];
  limit?: number;
  gameId?: number[];
  createdAtOrder?: string;
  startDate?: string;
  endDate?: string;
};
export type AccountTransactionsType = {
  id: string;
  type: string;
  recipientId: string;
  senderId: string;
  amount: string;
  data: { name: string };
  gameId: string;
  isConfirmed: string;
  createdAt: string;
  updatedAt: string;
};

export class AccountTransactionsStore extends PaginationStore<
  AccountTransactionsType,
  AccountTransactionsFilter
> {
  @observable
  private _accountTransactions: AccountTransactionsType[] = []
  @observable
  private _currentId: string
  @observable
  public _expectedLength: number

  @observable
  public loadedPages: number = 1
  @observable
  public isEmptyArr: boolean = false

  @observable
  public _lastDate: string

  @observable
  public _availableGames: Record<string, string>
  @observable
  public _availableTypes: Record<string, string>

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
    this.pagination.limit = 20
  }
  public async getData() {
    this.isEmptyArr = false

    if (this._currentId) {
      return this.getAccountTransactions(this._currentId)
    } else {
      return []
    }
  }

  public async getAccountTransactions(id: string) {
    this.resetFuncAfterSuccess()
    if (this._currentId !== id) {
      this._currentId = id
      this._accountTransactions = []
    }
    if (this._accountTransactions.length === 0) {
      this.loadedPages = 1
    }
    this.isEmptyArr = false

    const accountTransactions = await this.defaultFunction(
      getAccountTransaction,
      id,
      this.filter,
      20,
    )
    if (accountTransactions) {
      transaction(() => {
        this.isLoading = false
        if (accountTransactions.result?.length === 0) {
          this.isEmptyArr = true
          this.loadedPages = 0
        }
        if (this.loadedPages > 1) {
          this._accountTransactions = [...this._accountTransactions, ...accountTransactions.result]
        } else {
          this._accountTransactions = accountTransactions.result
        }
        this._expectedLength = accountTransactions.expectedLength
        this._lastDate = accountTransactions.lastDate
        this.total = accountTransactions.realLength
      })
    }

    return accountTransactions.result
  }

  public async getGameTypes() {
    const gametypes = await this.defaultFunction(getGameTypes)
    this._availableGames = gametypes
  }

  public async getTransactionTypes() {
    const transactionsTypes = await this.defaultFunction(getTransactionsTypes)
    this._availableTypes = transactionsTypes
  }

  @computed
  get accountTransactions(): AccountTransactionsType[] {
    return this._accountTransactions
  }

  @computed
  get expectedLength(): number {
    return this._expectedLength
  }

  @computed
  get lastDate(): string {
    return this._lastDate
  }

  @computed
  get availableGames(): Record<string, string> {
    return this._availableGames
  }

  @computed
  get availableTypes(): Record<string, string> {
    return this._availableTypes
  }
}
