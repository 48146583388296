import { Form, FormInstance, notification } from "antd"
import { FormElement, UploadComponent } from "components"
import { ContestConfig } from "domains/managingJson/store/types"
import { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

export const ChallengesTab: FC<{form: FormInstance<any>}> = ({ form }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const [, refreshState] = useState(0)
  const refresh = () => refreshState(e => e + 1)
  useEffect(() => {
    data.endgameWindow === undefined ? data.endgameWindow = {
      endgameSuccessImage: "",
      endgameFraudImage: "",
      endgameFailImage: "",
      gameBackgroundImage: "",
    } : ""

    data.startgameWindow === undefined ? data.startgameWindow = { contestImage: "" } : ""
  }, [data])

  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Main Page Top blocks data</div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <UploadComponent
              image={data.challengeBasedContest?.topMainPageLeftBanner ?? ""}
              setImage={e => {
                refresh()
                data.challengeBasedContest ? data.challengeBasedContest.topMainPageLeftBanner = e : ""
              }}
              placeholder=""
              title="Top left logo"
            />
          </div>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <UploadComponent
              image={data.challengeBasedContest?.topMainPageRightBanner ?? ""}
              setImage={e => {
                refresh()
                data.challengeBasedContest ? data.challengeBasedContest.topMainPageRightBanner = e : ""
              }}
              placeholder=""
              title="Top right logo"
            />
          </div>
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "16px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Main Page Right block data</div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ border: "1px solid #F0F0F0", width: "63%", padding: "16px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", marginRight: "8px", marginBottom: "16px" }}>
                <UploadComponent
                  image={data.challengeBasedContest?.rightMainPageBanner?.imageCompleted1State ?? ""}
                  setImage={e => {
                    refresh()
                    data.challengeBasedContest && data.challengeBasedContest.rightMainPageBanner
                      ? data.challengeBasedContest.rightMainPageBanner.imageCompleted1State = e : ""
                  }}
                  placeholder=""
                  title="First state image completed challenges"
                />
              </div>
              <div style={{ width: "100%" }}>
                <UploadComponent
                  image={data.challengeBasedContest?.rightMainPageBanner?.imageNotCompleted1State ?? ""}
                  setImage={e => {
                    refresh()
                    data.challengeBasedContest && data.challengeBasedContest.rightMainPageBanner
                      ? data.challengeBasedContest.rightMainPageBanner.imageNotCompleted1State = e : ""
                  }}
                  placeholder=""
                  title="First state image not completed challenges"
                />
              </div>
            </div>
            <div style={{ marginBottom: "16px" }}>
              First state text
              <FormElement
                type="input"
                label=""
                name="challengeText1"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.text1State}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              Title state text
              <FormElement
                type="input"
                label=""
                name="challengeTitle1"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.title1State}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              First state cta text
              <FormElement
                type="input"
                label=""
                name="challengeCTAText1"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.CTAtext1State}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ border: "1px solid #F0F0F0", width: "30%", padding: "16px", marginRight: "16px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", marginRight: "8px", marginBottom: "16px" }}>
                <UploadComponent
                  image={data.challengeBasedContest?.rightMainPageBanner?.imageGrandState ?? ""}
                  setImage={e => {
                    refresh()
                    data.challengeBasedContest && data.challengeBasedContest.rightMainPageBanner
                      ? data.challengeBasedContest.rightMainPageBanner.imageGrandState = e : ""
                  }}
                  placeholder=""
                  title="Grand state"
                />
              </div>
            </div>
            <div style={{ marginBottom: "16px" }}>
              Grand state title
              <FormElement
                type="input"
                label=""
                name="titlegrand"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.titleGrandState}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              Grand state text
              <FormElement
                type="input"
                label=""
                name="textgrand"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.textGrandState}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              Grand state cta text
              <FormElement
                type="input"
                label=""
                name="ctatextgrand"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.CTAtextGrandState}
              />
            </div>
          </div>
          <div style={{ border: "1px solid #F0F0F0", width: "30%", padding: "16px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", marginRight: "8px", marginBottom: "16px" }}>
                <UploadComponent
                  image={data.challengeBasedContest?.rightMainPageBanner?.imageNotCompleteState ?? ""}
                  setImage={e => {
                    refresh()
                    data.challengeBasedContest && data.challengeBasedContest.rightMainPageBanner
                      ? data.challengeBasedContest.rightMainPageBanner.imageNotCompleteState = e : ""
                  }}
                  placeholder=""
                  title="Not complete"
                />
              </div>
            </div>
            <div style={{ marginBottom: "16px" }}>
              First state text
              <FormElement
                type="input"
                label=""
                name="titlenotcomplete"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.titleNotCompleteState}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              First state text
              <FormElement
                type="input"
                label=""
                name="textnotcomplete"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.textNotCompleteState}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              First state cta text
              <FormElement
                type="input"
                label=""
                name="ctatextnotcomplete"
                initialValue={data.challengeBasedContest?.rightMainPageBanner?.CTAtextNotCompleteState}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Winners List</div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ border: "1px solid #F0F0F0", width: "30%", padding: "16px", marginRight: "16px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", marginRight: "8px", marginBottom: "16px" }}>
                <UploadComponent
                  image={data.challengeBasedContest?.leaderboard?.notwin?.image ?? ""}
                  setImage={e => {
                    refresh()
                    data.challengeBasedContest && data.challengeBasedContest?.leaderboard?.notwin
                      ? data.challengeBasedContest.leaderboard.notwin.image = e : ""
                  }}
                  placeholder=""
                  title="Leaderboard banner"
                />
              </div>
            </div>
            <div style={{ marginBottom: "16px" }}>
              Left title when not win
              <FormElement
                type="input"
                label=""
                name="lefttitlenowin"
                initialValue={data.challengeBasedContest?.leaderboard?.notwin?.title}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              Left text when not win
              <FormElement
                type="input"
                label=""
                name="leftttextnowin"
                initialValue={data.challengeBasedContest?.leaderboard?.notwin?.text}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              Left cta text when not win
              <FormElement
                type="input"
                label=""
                name="lefttctanowin"
                initialValue={data.challengeBasedContest?.leaderboard?.notwin?.CTAtext}
              />
            </div>
          </div>
          <div style={{ border: "1px solid #F0F0F0", width: "30%", padding: "16px", marginRight: "16px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", marginRight: "8px", marginBottom: "16px" }}>
                <UploadComponent
                  image={data.challengeBasedContest?.leaderboard?.win?.image ?? ""}
                  setImage={e => {
                    refresh()
                    data.challengeBasedContest && data.challengeBasedContest?.leaderboard?.win
                      ? data.challengeBasedContest.leaderboard.win.image = e : ""
                  }}
                  placeholder=""
                  title="Leaderboard win banner"
                />
              </div>
            </div>
            <div style={{ marginBottom: "16px" }}>
              left title when win
              <FormElement
                type="input"
                label=""
                name="lefttitlewin"
                initialValue={data.challengeBasedContest?.leaderboard?.win?.title}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              left text when win
              <FormElement
                type="input"
                label=""
                name="leftextwin"
                initialValue={data.challengeBasedContest?.leaderboard?.win?.text}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              left cta text when win
              <FormElement
                type="input"
                label=""
                name="leftctawin"
                initialValue={data.challengeBasedContest?.leaderboard?.win?.CTAtext}
              />
            </div>
          </div>
          <div style={{ border: "1px solid #F0F0F0", width: "30%", padding: "16px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", marginRight: "8px", marginBottom: "16px" }}>
                <UploadComponent
                  image={data.challengeBasedContest?.leaderboard?.right?.image ?? ""}
                  setImage={e => {
                    refresh()
                    data.challengeBasedContest && data.challengeBasedContest?.leaderboard?.right
                      ? data.challengeBasedContest.leaderboard.right.image = e : ""
                  }}
                  placeholder=""
                  title="Leaderboard right banner"
                />
              </div>
            </div>
            <div style={{ marginBottom: "16px" }}>
              right title
              <FormElement
                type="input"
                label=""
                name="righttitle"
                initialValue={data.challengeBasedContest?.leaderboard?.right?.title}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              right text
              <FormElement
                type="input"
                label=""
                name="righttext"
                initialValue={data.challengeBasedContest?.leaderboard?.right?.text}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              right finished title
              <FormElement
                type="input"
                label=""
                name="tightfinishtitle"
                initialValue={data.challengeBasedContest?.leaderboard?.right?.titleFinished}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
