import { FC, useEffect, useRef, useState } from "react"
import { Form, Input, Select } from "antd"

import { observer } from "mobx-react"
import { notifications } from "notifications"
import { SubmitCancelButtons } from "components"
import { IExternalOfferProvider } from "../api/types"
import { updateExternalOfferProvider } from "../api"
import { useStore } from "stores/utils/use-store"
import GeoPickerElement from "components/geoPicker"
import StructuredJsonInput from "components/structuredJsonInput"
import { paramStructuresByType, providerNamesByType } from "../constants/externalProviders"
import { FormInstance } from "antd/es/form/Form"

export const CreateExternalProvider: FC<{
  onClose: (provider?: IExternalOfferProvider) => void,
  initial?: IExternalOfferProvider
}> = observer(({ onClose, initial }) => {
  const form = useRef<FormInstance>(null)
  const { dataStore: { marketplaceRegionsStore } } = useStore()
  const [submitting, setSubmitting] = useState(false)
  const [type, setType] = useState(initial?.type)

  const onFinishHandler = async(e: IExternalOfferProvider) => {
    const isEdit = !!initial?.id
    if (submitting) return
    if (!e.name) {
      return notifications.error("Please enter the provider name")
    }
    if (!e.geoRegions || !e.geoRegions.length) {
      return notifications.error("Please select at least one country")
    }
    const data = {
      ...initial,
      ...e,
    } as IExternalOfferProvider

    if (isEdit) {
      e.id = initial?.id

      return updateExternalOfferProvider(data).then(async updated => {
        notifications.success("Provider updated")

        setSubmitting(false)
        close(updated)
      }).catch(e => {
        notifications.error(e.cause.message)
        setSubmitting(false)
      })
    }

    // createExternalProvider is not defined
  }
  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  const close = (provider?: IExternalOfferProvider) => {
    onClose(provider)
  }

  useEffect(() => {
    marketplaceRegionsStore.getData()
  }, [])

  return (
    <Form ref={form} onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} initialValues={{ ...initial }}>
      <Form.Item
        label="Type"
        name="type"
        style={{ width: "100%" }}
        shouldUpdate
      >
        <Select onChange={setType}>
          {Object.entries(providerNamesByType).map(([key, value]) => (
            <Select.Option key={key} value={key}>{value}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Title"
        name="name"
        style={{ width: "100%" }}
      >
        <Input />
      </Form.Item>
      <GeoPickerElement label="Whitelisted Countries" name="geoRegions" initialValue={initial?.geoRegions} />
      {type && paramStructuresByType[type] && (
        <StructuredJsonInput
          name="params"
          label="Params"
          structure={paramStructuresByType[type]}
        />
      )}
      <SubmitCancelButtons onClose={() => close()} isLoading={submitting} />
    </Form>
  )
})
