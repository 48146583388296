import { Tag } from "antd"
import { defaultFormat } from "utils/date"

export const bannersColumns = [
  { title: "Banner Id", dataIndex: "id", key: "id" },
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Priority", dataIndex: "priority", key: "priority" },
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (isActive: boolean) => {
      if (!isActive) return <Tag color="red">Not Active</Tag>

      return <Tag color="darkcyan">Active</Tag>
    },
  },
  {
    title: "link",
    dataIndex: "link",
    key: "link",
    render: (link: string) => {
      if (!link) return <Tag>No link</Tag>

      return (
        <Tag
          color="darkcyan"
          style={{
            maxWidth: 175,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {link}
        </Tag>
      )
    },
  },
  {
    title: "Platform",
    dataIndex: "platform",
    key: "platform",
    render: (platform: string) => <Tag>{platform.toUpperCase()}</Tag>,
  },
  {
    title: "Countries",
    dataIndex: "countries",
    key: "country",
    render: (array: string[]) => {
      const isEmpty = array && array.length === 0
      if (isEmpty) return <Tag color="green">ALL</Tag>

      return <div>{array && array.map(item => <Tag key={item} color="cyan">{item}</Tag>)}</div>
    },
  },
  {
    title: "Active Until",
    dataIndex: "activeUntil",
    key: "activeUntil",
    render: (time: number) => {
      if (!time) return <Tag>No time</Tag>
      if (Date.now() > new Date(time).valueOf()) return <Tag color="red">Expired!</Tag>

      return <Tag color="blue">{defaultFormat(time)}</Tag>
    },
  },
  {
    title: "Icon",
    key: "img",
    dataIndex: "img",
    render: (img: string) => img ? (
      <img src={img} alt="ico" style={{ width: "35px", height: "35px" }} />
    ) : (
      <Tag>No Image</Tag>
    ),
  },
]
