import React, { FC } from "react"
import { observer } from "mobx-react"
import { Form, InputNumber, Select } from "antd"
import { useStore } from "stores/utils/use-store"

import { WheelValue } from "domains/managingSpinCollect/store/types"

import { SubmitCancelButtons } from "components"

export const WheelValuesItem: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      managingSpinCollectStore,
      managingSpinCollectStore: {
        selectedElement,
        selectedElement: { coinsAmount, weight, type },
      },
    },
  } = useStore()
  const [form] = Form.useForm()

  const onFinishHandler = async(event: WheelValue) => {
    const data = {
      ...selectedElement,
      coinsAmount: event.coinsAmount,
      weight: event.weight,
      type: event.type,
    }
    managingSpinCollectStore.funcAfterSuccess = async() => {
      onClose()
      await managingSpinCollectStore.getConfiguration()
      await managingSpinCollectStore.getFreeValues()
      await managingSpinCollectStore.getPaidValues()
    }
    editType === "edit"
      ? await managingSpinCollectStore.updateWheelValue(data)
      : await managingSpinCollectStore.createWheelValue(data)
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <Form.Item
        label="Type"
        name="type"
        initialValue={type}
        rules={[
          {
            required: true,
            message: "Input type!",
          },
        ]}
      >
        <Select>
          {["FREE", "PAID"].map(el => (
            <Select.Option value={el} key={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Coins amount"
        name="coinsAmount"
        initialValue={coinsAmount}
        rules={[
          {
            required: true,
            message: "Input amount!",
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Weight"
        name="weight"
        initialValue={weight}
        rules={[
          {
            required: true,
            message: "Input weight!",
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <SubmitCancelButtons onClose={onClose} isLoading={managingSpinCollectStore.isLoading} />
    </Form>
  )
})
