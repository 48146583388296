import {
  InternalItemTypeGet,
  GetItemsFilter,
  ItemToUpdate,
  InternalCategory,
} from "domains/marketplace/store/types"
import { computed, observable, transaction, makeObservable } from "mobx"
import {
  createItem,
  deleteItem,
  getItems,
  updateItem,
  getItemFilters,
} from "domains/marketplace/api/internal-items.api"

import { PaginationStore } from "stores/data/pagination.store"
import { RootStore } from "stores/root.store"

export class InternalMarketplaceStore extends PaginationStore<InternalItemTypeGet, GetItemsFilter> {
  @observable
  private _itemTypes: InternalItemTypeGet[]

  @observable
  private _selectedElement: InternalItemTypeGet = {} as InternalItemTypeGet

  @observable
  private _internalCategories: InternalCategory = {} as InternalCategory

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<InternalItemTypeGet[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination
    console.log(page, limit, " this.pagination")
    const res: any = await this.defaultFunction(getItems, page, limit)
    transaction(() => {
      this._itemTypes = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: ItemToUpdate) {
    await this.defaultFunction(updateItem, this._selectedElement.id, data)
  }

  async create(data: ItemToUpdate) {
    await this.defaultFunction(createItem, data)
  }

  async delete(id: string) {
    await this.defaultFunction(deleteItem, id)
  }
  async getCategories() {
    const res = await this.defaultFunction(getItemFilters)
    this.internalCategories = res
  }

  @computed
  get itemTypes(): InternalItemTypeGet[] {
    return this._itemTypes
  }

  @computed
  get selectedElement(): InternalItemTypeGet {
    return this._selectedElement
  }

  set selectedElement(value: InternalItemTypeGet) {
    this._selectedElement = value
  }

  @computed
  get internalCategories(): InternalCategory {
    return this._internalCategories
  }

  set internalCategories(value: InternalCategory) {
    this._internalCategories = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as InternalItemTypeGet
  }
}
