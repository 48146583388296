import React, { FC, useEffect } from "react"
import { Table } from "antd"
import { Column } from "abstract/types/column.type"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { useNavigate } from "react-router-dom"
import Search from "antd/es/input/Search"
import { GameAccDataType, GameAccount } from "domains/game-accounts/store/game-account.store"
import { gameNames } from "abstract/constants/game-ids"

export const GameAccountsList: FC = observer(() => {
  const { dataStore: { gameAccountStore, gameAccountDetailsStore } } = useStore()
  const navigate = useNavigate()
  const handleRowClick = (value: GameAccount): any => {
    const { buffAccountId } = value
    gameAccountDetailsStore.setDataAcc(value)
    navigate(`/game-accounts/details/${buffAccountId}`)
  }
  useEffect(() => {
    gameAccountStore.getAllGameAccounts()
  }, [gameAccountStore])

  const columns: Column[] = [
    { title: "Account Id", dataIndex: "id", key: "id", width: 70 },
    { title: "In Game Id", dataIndex: "inGameId", key: "inGameId", width: 70, ellipsis: true },
    {
      title: "Game Id",
      ellipsis: true,
      dataIndex: "gameId",
      key: "gameId",
      width: 70,
      render: (el: number) => gameNames[el] || el,
    },
    { title: "Buff account Id", dataIndex: "buffAccountId", key: "buffAccountId", width: 70 },
    {
      title: "Nickname",
      dataIndex: "account",
      key: "nickname",
      width: 70,
      render: (el: GameAccDataType) => el.nickname,
    },

    {
      title: "Email",
      dataIndex: "account",
      key: "email",
      width: 70,
      render: (el: GameAccDataType) => el.email,
    },
  ]

  const searchHandler = async(value: string) => {
    gameAccountStore.setFilter(value)
  }
  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      gameAccountStore.pagination.page = page
    },
    total: gameAccountStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 15,
    current: gameAccountStore.pagination.page,
  }

  return (
    <>
      <Search placeholder="input search text" enterButton onSearch={searchHandler} />
      <Table
        dataSource={gameAccountStore.accounts}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={gameAccountStore.isLoading}
        onRow={record => ({
          onClick: () => {
            handleRowClick(record)
          },
        })}
      />
    </>
  )
})
