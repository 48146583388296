import React, { FC } from "react"
import { Button } from "antd"
import { ReadOutlined } from "@ant-design/icons"

interface BookIconButtonProps {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const BookIconButton: FC<BookIconButtonProps> = ({ disabled, onClick }) => {
  return (
    <Button
      disabled={disabled}
      type="primary"
      style={{ background: "#FFD176", borderColor: "#FFD176" }}
      onClick={onClick}
      icon={<ReadOutlined />}
    />
  )
}
