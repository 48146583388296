import { Tag } from "antd"
import { defaultFormat } from "utils/date"

export const utmTierColumns = [
  { title: "Name", dataIndex: "name", key: "name" },
  {
    title: "Countries",
    dataIndex: "countries",
    key: "countries",
    render: (countries: string[] | null) => {
      if (!countries) {
        return "Any other country"
      }

      return `[${countries.map(el => `"${el}"`).join(", ")}]`
    },
  },
  { title: "Payout Amount", dataIndex: "payoutAmount", key: "payoutAmount" },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => <Tag color="green">{defaultFormat(el)}</Tag>,
  },
]
