import { Tag } from "antd"

export const subscriptionColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    render: (tags: string[]) => tags.map(item => <Tag key={item}>{item}</Tag>),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "60%",
  },
]
