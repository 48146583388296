import { Button, Space, Table } from "antd"
import { GrandPrizeReward, GrandPrizeRewards } from "domains/managingJson/store/types"
import { ComponentProps, Dispatch, FC, SetStateAction } from "react"
import { produce } from "immer"
import { EditableCell } from "./components/EditableCell"
import { DeleteIconButton } from "components"


type Props = {
  prizes: GrandPrizeReward[];
  onChange: Dispatch<SetStateAction<GrandPrizeRewards>>;
  gameIdString: string;
};

export const PrizesEditor: FC<Props> = ({ prizes, gameIdString, onChange }) => {
  const addNew = () => {
    onChange(
      produce(draft => {
        const game = draft[gameIdString]
        if (!game) {
          draft[gameIdString] = [{ tooltipText: "" }]
        } else {
          draft[gameIdString].push({ tooltipText: "" })
        }
      }),
    )
  }

  const editText = (index: number, newText: string) => {
    onChange(
      produce(draft => {
        draft[gameIdString][index].tooltipText = newText
      }),
    )
  }

  const deletePrize = (index: number) => {
    onChange(
      produce(draft => {
        draft[gameIdString] = draft[gameIdString].filter((_, i) => i !== index)
      }),
    )
  }

  const COLUMNS: ComponentProps<typeof Table>["columns"] = [
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Tooltip text",
      dataIndex: "tooltipText",
      key: "tooltipText",
      render: (text, _, index) => <EditableCell index={index} text={text} editText={editText} />,
      shouldCellUpdate: (record, prevRecord) => record !== prevRecord,
    },
    {
      title: "",
      dataIndex: "tooltipText",
      key: "tooltipText",
      render: (_, __, index) => <DeleteIconButton onClick={() => deletePrize(index)} />,

    },
  ]

  return (
    <Space direction="vertical" style={{ width: "100%" }} >
      <Table dataSource={prizes} columns={COLUMNS} pagination={false} />
      <Button onClick={addNew}>Add new prize</Button>
    </Space>
  )
}
