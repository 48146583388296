import React, { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import s from "./styles.module.css"
import { Button, Input } from "antd"
import { notifications } from "notifications"

export const ProductProcessorInfo: FC<{ onClose: () => void}> = observer(() => {
  const {
    dataStore: {
      productsStore,
      productsStore: { selectedElement },
    },
  } = useStore()
  const [name, setName] = useState(selectedElement.name)
  const [description, setDescription] = useState(selectedElement.description)
  const [price, setPrice] = useState(selectedElement.price)
  const [consumable, setConsumable] = useState(selectedElement.consumable)
  const [active, setActive] = useState(selectedElement.isActive)
  const [stripeId, setStripeId] = useState(selectedElement.stripeId)
  const [appStoreId, setAppStoreId] = useState(selectedElement.appStoreId)
  const [googlePlayId, setGooglePlayId] = useState(selectedElement.googlePlayId)

  return (
    <div className={s.prettyJson}>
      <div >
        <div>Name:</div>
        <Input onChange={e => setName(e.target.value)} value={name} />
      </div>
      <div >
        <div>Description:</div>
        <Input onChange={e => setDescription(e.target.value)} value={description} />
      </div>
      <div >
        <div>Price:</div>
        <Input type="number" onChange={e => setPrice(parseFloat(e.target.value))} value={price} />
      </div>
      <div >
        <div>StripeId:</div>
        <Input onChange={e => setStripeId(e.target.value)} value={stripeId} />
      </div>
      <div >
        <div>AppStoreId:</div>
        <Input onChange={e => setAppStoreId(e.target.value)} value={appStoreId} />
      </div>
      <div >
        <div>GooglePlayId:</div>
        <Input onChange={e => setGooglePlayId(e.target.value)} value={googlePlayId} />
      </div>
      <div >
        <div>Consumable:</div>
        <Input type="checkbox" onChange={e => setConsumable(a => !a)} checked={consumable} />
      </div>
      <div >
        <div>Active:</div>
        <Input type="checkbox" onChange={e => setActive(a => !a)} checked={active} />
      </div>
      <div style={{ display: "flex" }}>
        <Button
          style={{ display: "flex", margin: "auto", marginTop: "15px" }}
          type="primary"
          onClick={() => {
            productsStore.funcAfterSuccess = () => {
              productsStore.getData()
              notifications.success("Saved!")
            }
            productsStore.patchProduct(selectedElement.id, {
              name: name,
              description: description,
              isActive: active,
              price: price,
              consumable: consumable,
              stripeId: stripeId,
              appStoreId: appStoreId,
              googlePlayId: googlePlayId,
            })
          }}
        >
        Update Product
        </Button>
        <Button
          style={{ display: "flex", margin: "auto", marginTop: "15px", backgroundColor: "red" }}
          type="primary"
          onClick={() => {
            productsStore.funcAfterSuccess = () => {
              productsStore.getData()
              notifications.success("Deleted!")
            }
            productsStore.deleteProduct(selectedElement.id)
          }}
        >
        Delete Product
        </Button>
      </div>
    </div>
  )
})
