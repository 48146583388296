import Styles from "./styles.module.css"
import { Input } from "antd"

type Props = {
  value: string;
  onChange: (value: string) => void;
}

export const TestingArea = ({ value, onChange }: Props) => {
  return (
    <div className={Styles.testInputWrapper}>
      <h2>TESTING AREA</h2>
      <p className={Styles.testInputLabel}>
          Use this for testing your triggers. Enter a gamemode and triggered matches will be shown
          in red.
      </p>
      <Input
        placeholder={"Enter gamemode here (e.g. \"Replaying Competitive Dust II\")"}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
}
