import { FC, useState } from "react"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"

import { Modal, Divider, Radio, Button } from "antd"

interface IPromocodeFilter {
  setIsVisibleFilter: (arg: boolean) => void;
  isVisibleFilter: boolean;
}

export const PromocodeFilter: FC<IPromocodeFilter> = observer(({ setIsVisibleFilter, isVisibleFilter }) => {
  const { dataStore: { parentPromocodesStore } } = useStore()

  const [isActive, setIsActive] = useState<boolean | undefined>(undefined)

  const onClick = async() => {
    const filters: any = {}

    if (isActive !== undefined) {
      filters.timeStatus = isActive ? "ACTIVE" : "EXPIRED"
    }

    await parentPromocodesStore.setFilter(filters)
    setIsVisibleFilter(false)
  }
  const reset = () => {
    setIsActive(undefined)
  }

  return (
    <Modal
      width="700px"
      bodyStyle={{
        width: 800,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      visible={isVisibleFilter}
      onOk={onClick}
      onCancel={() => setIsVisibleFilter(false)}
    >
      <Divider orientation="left">Status</Divider>
      <Radio.Group
        value={isActive}
        defaultValue={undefined}
        onChange={e => {
          setIsActive(e.target.value)
        }}
      >
        <Radio.Button value={undefined}>All</Radio.Button>
        <Radio.Button value={false}>Expired</Radio.Button>
        <Radio.Button value={true}>Active</Radio.Button>
      </Radio.Group>
      <Divider orientation="left" />
      <Button onClick={reset}>Reset</Button>
    </Modal>
  )
})
