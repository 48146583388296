import {
  computed,
  makeObservable,
  observable,
  transaction,
} from "mobx"
import { Product, PatchProduct, CreateProduct } from "./types"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import {
  getProducts,
  patchProduct,
  deleteProduct,
  createProduct,
} from "../api/products.calls"

export class ProductsStore extends PaginationStore<Product> {
  @observable
  private _products: Product[]
  @observable
  private _activeChallenges: Product[]

  @observable
  private _selectedElement: Product | any

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<Product[]> {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getProducts)

    transaction(() => {
      this._activeChallenges = res
    })

    return res
  }

  async patchProduct(productId: string, data: PatchProduct) {
    await this.defaultFunction(patchProduct, productId, data)
  }

  async deleteProduct(productId: string) {
    await this.defaultFunction(deleteProduct, productId)
  }

  async createProduct(data: CreateProduct) {
    await this.defaultFunction(createProduct, data)
  }

  @computed
  get products(): Product[] {
    return this._products
  }
  @computed
  get activeProducts(): Product[] {
    return this._activeChallenges
  }

  @computed
  get selectedElement(): Product {
    return this._selectedElement
  }

  set selectedElement(value: Product) {
    this._selectedElement = value
  }
}
