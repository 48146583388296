import {
  Form,
  Space,
  Spin,
} from "antd"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useStore } from "stores/utils/use-store"
import { SpecialEvent } from "../main"
import { observer } from "mobx-react"
import { SpecialEventEditHeader } from "./components/header"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import { notifications as appNotifications } from "notifications"
import Styles from "./styles.module.css"
import { MainInfoEditor } from "./components/mainInfoEditor"
import { GamesEditor } from "./components/gamesEditor"
import { ConfigItem, SpecialEventsConfigType } from "domains/managingJson/store/types"

export const EditSpecialEventPage = observer(() => {
  const { id } = useParams<{ id: string }>()
  const { dataStore: { managingJsonStore } } = useStore()
  const [editedEvent, setEditedEvent] = useState<SpecialEvent | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const formRef = useRef(null)

  const editedDateRange = editedEvent ? [moment(editedEvent.startDate), moment(editedEvent.endDate)] : undefined

  useEffect(() => {
    const getData = async() => {
      await managingJsonStore.getData()
      setIsLoading(false)
    }
    getData()
  }, [managingJsonStore])

  useEffect(() => {
    if (!id) return
    const eventsData = managingJsonStore?.itemTypes?.filter(
      item => item.type === "specialEvents",
    ) as ConfigItem<"specialEvents">[]
    if (eventsData && eventsData.length > 0) {
      const data = eventsData[0].data
      const specialEvent = data?.specialEvents?.find(event => event.uid === id)
      setEditedEvent(specialEvent)
    }
  }, [managingJsonStore.itemTypes])

  useEffect(() => {
    // checking if form is rendered - if not, we won't set fields value
    // loader might be rendered instead of form while data is loading
    if (!formRef.current) return
    form.setFieldsValue({
      dates: editedDateRange,
      ...editedEvent,
    })
  }, [editedEvent])

  const getStoreData = (): SpecialEventsConfigType | undefined => {
    const eventsData = managingJsonStore?.itemTypes?.filter(
      item => item.type === "specialEvents",
    ) as ConfigItem<"specialEvents">[]

    return eventsData[0]?.data
  }

  const updateStore = async(
    callback: (prev: SpecialEventsConfigType | undefined) => SpecialEventsConfigType,
  ) => {
    const previousData = getStoreData()
    const newData = callback(previousData)

    previousData
      ? await managingJsonStore.update({
        name: "specialEvents",
        type: "specialEvents",
        data: newData,
      })
      : await managingJsonStore.create({
        name: "specialEvents",
        type: "specialEvents",
        data: newData,
      })
  }


  const save = async() => {
    managingJsonStore.funcAfterSuccess = async() => {
      appNotifications.success("Saved successfully")
      await managingJsonStore.getData()
    }

    try {
      const values = await form.validateFields()

      const { games, restrictedToCountries, dates, notificationsPerGame, ...rest } = values
      const newEvent: SpecialEvent = {
        ...rest,
        games: games ?? [],
        restrictedToCountries: restrictedToCountries ?? [],
        uid: editedEvent?.uid ?? uuidv4(),
        startDate: dates[0].toISOString(),
        endDate: dates[1].toISOString(),
        notificationsPerGame: notificationsPerGame ?? [],
      }


      updateStore(prev => {
        const prevEvent = prev?.specialEvents?.find(prevEvent => prevEvent.uid === newEvent.uid)
        if (prevEvent) {
          const specialEvents = prev?.specialEvents?.map(prevEvent => {
            if (prevEvent.uid === newEvent.uid) {
              return newEvent
            }

            return prevEvent
          })

          return { specialEvents }
        }

        return { specialEvents: [...(prev?.specialEvents ?? []), newEvent] }
      })

      return navigate(`/special-events/${newEvent.uid}`)

    } catch (error) {
      return
    }

  }


  if (isLoading) return (
    <div className={Styles.spinnerWrapper}>
      <Spin size="large" />
    </div>
  )

  return (
    <div className={Styles.container}>
      <SpecialEventEditHeader name={editedEvent?.name ?? "New event"} onCancel={() => navigate("/special-events")} onSave={save} />

      <Form form={form} layout="vertical" ref={formRef}>
        <Space direction="vertical" className={Styles.cardContainer} size="large">
          <MainInfoEditor />
          <GamesEditor />
        </Space>
      </Form>
    </div>
  )
})
