// Core
import { toast } from "react-toastify"

// Instruments

export const notifications = {
  error: (message: string, timeout: number | false | undefined = 5000) => {
    toast(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "toast-error",
      bodyClassName: "error-body",
      closeButton: false,
      autoClose: timeout,
    })
  },
  success: (message: string, timeout: number | false | undefined = 5000) => {
    if (localStorage.getItem("buff-notifications") === "false") {
      return false
    }
    toast(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "toast-success",
      bodyClassName: "success-body",
      closeButton: false,
      autoClose: timeout,
    })
  },
  bonus: (message: string, timeout: number | false | undefined = 5000) => {
    if (localStorage.getItem("buff-notifications") === "false") {
      return false
    }
    toast(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "toast-bonus",
      bodyClassName: "bonus-body",
      closeButton: false,
      autoClose: timeout,
    })
  },
  info: (message: string, timeout: number | false | undefined = 5000) => {
    if (localStorage.getItem("buff-notifications") === "false") {
      return false
    }
    toast(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "toast-info",
      bodyClassName: "info-body",
      closeButton: false,
      autoClose: timeout,
    })
  },
  hideAll: toast.dismiss,
}
