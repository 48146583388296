import { Form, FormInstance, notification } from "antd"
import { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { Pixels } from "./Pixels"
import { Pixel } from "domains/managingJson/store/types"

export const AnalyticsTab: FC<{
  form: FormInstance<any>
  startgame: Pixel[]
  endgame: Pixel[]
  inApp: Pixel[]
  setStartgame: (startgame: Pixel[]) => void
  setEndgame: (endgame: Pixel[]) => void
  setInApp: (inApp: Pixel[]) => void
}> = ({ form, startgame, endgame, inApp, setStartgame, setEndgame, setInApp }) => {

  const {
    dataStore: {
      managingJsonStore: { selectedElement: { name, data } },
      managingJsonStore,
    },
  } = useStore()

  const onFinishHandler = async() => {
    managingJsonStore.funcAfterSuccess = async() => {
      form.resetFields()
      notification.success({ message: "Contest updated successfully" })
      await managingJsonStore.getData()
    }

    const contest = { type: "contests" as any, name: name, data: data }
    contest.data["endgamePixels"] = endgame
    contest.data["startgamePixels"] = startgame
    contest.data["inAppPixels"] = inApp
    await managingJsonStore.update(contest)
  }

  const onFinishFailedHandler = (e: any) => {
    notification.error({ message: "Failed to update contest" })
    console.log(e)
  }

  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px" }}>Analytics Impressions</div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <Pixels
              pixelsList={inApp}
              setPixelsList={setInApp}
              title="In-app Pixels"
              description="Fired whenever branded image from contests is shown"
              form={form}
            />
          </div>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <Pixels
              pixelsList={startgame}
              setPixelsList={setStartgame}
              title="Start Game window Pixels"
              description="Fired whenever branded image from contests is shown"
              form={form}
            />
          </div>
          <div style={{ width: "33%", marginRight: "8px" }}>
            <Pixels
              pixelsList={endgame}
              setPixelsList={setEndgame}
              title="End Game Windows Pixels"
              description="Fired whenever branded image from contests is shown"
              form={form}
            />
          </div>
        </div>
      </div>
    </div>
  )

}
