import React, { FC, useEffect, useState } from "react"

import { Button, Form, Select } from "antd"
import { ContestConfigFreePointsData } from "domains/managingJson/store/types"
import { FormElement, UploadComponent } from "components"
const { Option } = Select

export const FreePoints: FC<{
  id: number;
  data: ContestConfigFreePointsData;
  deleteFunc: () => void;
  form: any;
}> = ({ id, data, deleteFunc, form }) => {
  const [image, setImage] = useState(data?.image || "")
  const [type, settype] = useState(data?.type || "")
  const [watermark, setWatermark] = useState(data?.watermark || "")

  useEffect(() => {
    if (data) {
      setImage(data?.image)
      setWatermark(data?.watermark || "")
    }
  }, [data])

  useEffect(() => {
    const arr = {}
    arr[`pointsImage${id}`] = image
    form.setFieldsValue({ ...arr })
  }, [image, form, id])

  useEffect(() => {
    const arr = {}
    arr[`watermark${id}`] = watermark
    form.setFieldsValue({ ...arr })
  }, [watermark, form, id])

  return (
    <div>
      <Form.Item
        label={`Points type ${id}`}
        name={`pointsType${id}`}
        initialValue={data?.type}
        rules={[
          {
            required: true,
            message: `Input points type ${id}`,
          },
        ]}
      >
        <Select onChange={e => settype(e)}>
          <Option value="share" key="share">
            Share video
          </Option>
          <Option value="invite" key="invite">
            Invite friend
          </Option>
          <Option value="newsletter" key="newsletter">
            Newsletter
          </Option>
          ))
        </Select>
      </Form.Item>
      <FormElement
        type="input"
        label={`Points title ${id}`}
        name={`pointsTitle${id}`}
        initialValue={data?.title}
        required
      />

      <FormElement
        type="input"
        label={`Points reward text ${id}`}
        name={`pointsRewardText${id}`}
        initialValue={data?.text}
        required
      />

      <FormElement
        type="input"
        label={`Points CTA active text ${id}`}
        name={`pointsCTAactiveText${id}`}
        initialValue={data?.CTA?.activeText}
        required
      />
      <FormElement
        type="input"
        label={`Points CTA inactive text ${id}`}
        name={`pointsCTAinactiveText${id}`}
        initialValue={data?.CTA?.inactiveText}
        required
      />

      <FormElement
        type="inputNumber"
        label={`Points number ${id}`}
        name={`points${id}`}
        initialValue={data?.points}
        required
        props={{ min: 1 }}
      />

      <FormElement
        type="input"
        label={`Points No Available Text ${id}`}
        name={`pointstopText${id}`}
        initialValue={data?.topText}
      />
      <FormElement
        type="text"
        label={`Points text ${id}`}
        name={`pointsText${id}`}
        initialValue={data?.text}
      />

      <Form.Item name={`pointsImage${id}`} initialValue={image}>
        <UploadComponent
          image={image}
          setImage={setImage}
          placeholder={`Points image ${id}`}
          title={`Points image ${id}`}
          required
        />
      </Form.Item>
      {type === "share" && (
        <div>
          <FormElement
            type="input"
            label={`Points highlights screen header ${id}`}
            name={`pointshighlightsScreenheader${id}`}
            initialValue={data?.highlightsScreen?.header}
          />
          <FormElement
            type="text"
            label={`Points highlights screen description ${id}`}
            name={`pointshighlightsScreendescription${id}`}
            initialValue={data?.highlightsScreen?.description}
          />

          <FormElement
            type="input"
            label={`Points highlights screen cta text ${id}`}
            name={`pointshighlightsScreenCTAtext${id}`}
            initialValue={data?.highlightsScreen?.CTAtext}
          />

          <h2>Watermark settings</h2>
          <Form.Item name={`watermark${id}`} initialValue={watermark}>
            <UploadComponent
              image={watermark}
              setImage={setWatermark}
              placeholder={`Watermark ${id}`}
              title={`Watermark ${id}`}
            />
          </Form.Item>

          <FormElement
            style={{ marginTop: "14px" }}
            type="inputNumber"
            label="ratio watermark width / video width"
            name={`watermarkWidth${id}`}
            initialValue={data?.watermarkWidth}
          />

          <FormElement
            type="select"
            label={`Watermark position ${id}`}
            name={`watermarkPosition${id}`}
            initialValue={data?.watermarkPosition}
          >
            <Select>
              <Option value="center" key="center">
                Center
              </Option>
              <Option value="topLeft" key="topLeft">
                Top left
              </Option>
              <Option value="topRight" key="topRight">
                Top right
              </Option>
              <Option value="bottomLeft" key="bottomLeft">
                Bottom left
              </Option>
              <Option value="bottomRight" key="bottomRight">
                Bottom right
              </Option>
            </Select>
          </FormElement>
        </div>
      )}
      {type === "invite" && (
        <div>
          <FormElement
            type="inputNumber"
            label={`max Free Points ${id}`}
            name={`maxFreePoints${id}`}
            initialValue={data?.maxFreePoints}
            props={{ min: 0 }}
          />
          <FormElement
            type="inputNumber"
            label={`goal Invite ${id}`}
            name={`goalInvite${id}`}
            initialValue={data?.goalInvite}
            props={{ min: 0 }}
          />
        </div>
      )}
      <Button
        style={{ display: "flex", margin: "auto", marginBottom: "14px" }}
        onClick={deleteFunc}
      >
        Delete Free point {id}
      </Button>
    </div>
  )
}
