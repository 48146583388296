import React, { FC, useState } from "react"
import { Button, Upload } from "antd"
import { useStore } from "stores/utils/use-store"
import { UploadOutlined } from "@ant-design/icons"

export const AddKeysComponent: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { dataStore: { giftCardsStore } } = useStore()

  const [fileToUpload, setFileToUpload] = useState<FormData>()
  const [filelist, setfilelist] = useState<any>([])

  return (
    <>
      <Upload
        name="file"
        beforeUpload={file => {
          const formData = new FormData()
          formData.append("file", file)
          setFileToUpload(formData)
          setfilelist([file])

          return false
        }}
        maxCount={1}
        fileList={filelist}
        onRemove={() => {
          setfilelist([])
          setFileToUpload(undefined)
        }}
      >
        <Button icon={<UploadOutlined />}>Click to Add files</Button>
      </Upload>
      <Button
        disabled={!fileToUpload}
        onClick={async() => {
          giftCardsStore.funcAfterSuccess = () => {
            giftCardsStore.getData()
            setFileToUpload(undefined)
            setfilelist([])
            onClose()
          }
          await giftCardsStore.uploadList(fileToUpload as FormData)
        }}
      >
        Upload Selected File?
      </Button>
    </>
  )
}
