import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, transaction, makeObservable } from "mobx"
import { EntityToUpdate } from "abstract/entity-to-update"
import { RootStore } from "stores/root.store"
import { createContest, deleteContest, getContests, updateContest } from "../api"

export class Contest {
  constructor(
    public id: string,
    public name: string,
    public createdAt: Date,
    public updatedAt: Date,
    public startDate: Date,
    public endDate: Date,
    public gameId: number,
    public contestId: number,
  ) {}
}

export class ContestsStore extends PaginationStore<Contest, string> {
  @observable
  private _contests: Contest[]
  @observable
  private _selectedElement: Contest = {} as Contest

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<Contest[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getContests, this.filter, page, limit)
    if (res) {
      transaction(() => {
        this._contests = res.data
        this.total = res.total
      })
    }

    return res.data
  }

  async update(data: EntityToUpdate<Contest>) {
    await this.defaultFunction(updateContest, this._selectedElement.id, data)
  }

  async create(data: EntityToUpdate<Contest>) {
    await this.defaultFunction(createContest, data)
  }

  async delete(id: string) {
    await this.defaultFunction(deleteContest, id)
  }

  @computed
  get contests(): Contest[] {
    return this._contests
  }

  @computed
  get selectedElement(): Contest {
    return this._selectedElement
  }

  set selectedElement(value: Contest) {
    this._selectedElement = value
  }

  clearSelectedElement() {
    this._selectedElement = {} as Contest
  }
}
