import { BaseStore } from "stores/base.store"
import { computed, observable, makeObservable, transaction } from "mobx"
import { Tier } from "stores/primitives/tier"
import { getTiers } from "domains/tiers/api/tiers.call"
import { RootStore } from "stores/root.store"
export class TierStore extends BaseStore {
  @observable
  private _tiers: Tier[] = []

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getData() {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getTiers)

    transaction(() => {
      this._tiers = res
    })

    return res
  }
  @computed
  get tiers(): Tier[] {
    return this._tiers
  }
}
