import React, { FC, useMemo, useState } from "react"

import { Form, Input, InputNumber, Select } from "antd"
import { RegionToUpdate } from "domains/marketplace/store/types"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import Styles from "./styles.module.css"
import { SubmitCancelButtons } from "components"

export const MarketplaceRegionEdit: FC<{ editType: "create" | "edit"; onClose: () => void }>
  = observer(({ editType, onClose }) => {
    const {
      dataStore: {
        marketplaceRegionsStore: { selectedElement: { name, tag, priority, parentId } },
        marketplaceRegionsStore,
      },
    } = useStore()

    const [selectedParentId, setParentId] = useState<string>(parentId)

    const regionsWithoutSelected = useMemo(() => {
      if (!marketplaceRegionsStore.selectedElement) {
        return marketplaceRegionsStore?.regions
      }

      return marketplaceRegionsStore?.regions?.filter(el => el.id !== marketplaceRegionsStore.selectedElement.id)
    }, [marketplaceRegionsStore])

    const maxPriorityItem = useMemo(() => {
      let region
      let max = -1

      for (const el of marketplaceRegionsStore.regions) {
        if (el.priority > max) {
          max = el.priority
          region = el
        }
      }

      return region
    }, [marketplaceRegionsStore.regions])

    const onFinishHandler = async(event: RegionToUpdate) => {
      event.parentId = selectedParentId
      event.tag = event.tag?.toUpperCase().trim()

      marketplaceRegionsStore.funcAfterSuccess = async() => {
        onClose()
        await marketplaceRegionsStore.getData()
      }

      editType === "edit"
        ? await marketplaceRegionsStore.update(event)
        : await marketplaceRegionsStore.create(event)
    }

    return (
      <Form onFinish={onFinishHandler} id="regionsEdit">
        <Form.Item
          label="Name"
          name="name"
          initialValue={name}
          rules={[
            {
              required: true,
              message: "Please input region name!",
            },
          ]}
          style={{ width: "100%" }}
          className={Styles.flex}
        >
          <Input maxLength={20} />
        </Form.Item>

        <Form.Item
          label="Tag"
          name="tag"
          initialValue={tag}
          rules={[
            {
              required: true,
              message: "Please input region tag!",
            },
            {
              pattern: /^[A-z\d]+$/,
              message: "Only letters and numbers are allowed",
            },
          ]}
          style={{ width: "100%" }}
          className={Styles.flex}
        >
          <Input style={{ width: "100%" }} maxLength={20} />
        </Form.Item>

        <Form.Item label="Parent" className={Styles.platforms}>
          <Select onChange={setParentId}>
            {regionsWithoutSelected.map(region => (
              <Select.Option key={region.id} value={region.id}>{region.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Priority"
          name="priority"
          initialValue={priority}
          style={{ width: "100%" }}
          className={Styles.flex}
          help={
            maxPriorityItem
            && `Current max priority (${maxPriorityItem.priority}) has item "${maxPriorityItem.name}"`
          }
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>

        <SubmitCancelButtons onClose={onClose} isLoading={marketplaceRegionsStore.isLoading} />
      </Form>
    )
  })
