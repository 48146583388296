/* eslint-disable max-len */
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Form } from "antd"
import { UploadComponent } from "components"
import Styles from "./styles.module.css"

type Props = {
  countryIndex: number;
  notificationsPerGameArrayIndex: number;
  notificationIndex: number;
};

type UploadComponentProps = {
  value?: string,
  onChange?: (value: string) => void,
}

const UploadComponentCompatibilityWrapper = ({ value, onChange }: UploadComponentProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <UploadComponent image={value ?? ""} setImage={onChange} />
}

export const SourceImagesEditor = ({ countryIndex }: Props) => {
  return (
    <Form.List name={[countryIndex, "imageSources"]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name: imageSourceIndex, ...restField }) => (
            <div key={key} style={{ marginBottom: 24 }}>
              <div className={Styles.imageUploadWrapper}>
                <Form.Item
                  {...restField}
                  name={[imageSourceIndex, "src"]}
                >
                  <UploadComponentCompatibilityWrapper />
                </Form.Item>
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => remove(imageSourceIndex)}
                />
              </div>
            </div>
          ))}
          <Button type="primary" icon={<PlusOutlined />} onClick={() => add()}>
            Add new image variant
          </Button>
        </>
      )}
    </Form.List>
  )
}
