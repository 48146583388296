import { EntityToUpdate } from "abstract/entity-to-update"
import { PopUp } from "stores/primitives/pop-up"
import { apiCall } from "api-calls/api-call"

export const getPopups = async(
  filter: string,
  page: number,
  limit: number,
): Promise<{
  data: PopUp[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string } = {
    page,
    limit,
  }
  if (filter) query.filter = filter

  return apiCall({
    method: "GET",
    path: "pop-ups",
    query,
  })
}
export const updatePopup = async(
  id: string,
  data: EntityToUpdate<PopUp>,
): Promise<{
  data: PopUp;
}> => {
  return apiCall({
    method: "PUT",
    path: `pop-ups/${id}`,
    body: data,
  })
}
export const createPopup = async(data: EntityToUpdate<PopUp>): Promise<{
  data: PopUp;
}> => {
  return apiCall({
    method: "POST",
    path: "pop-ups",
    body: data,
  })
}
export const deletePopup = async(id: string): Promise<{
  data: PopUp;
}> => {
  return apiCall({
    method: "DELETE",
    path: `pop-ups/${id}`,
  })
}
