import {
  StatusEnum,
  TemplateChallenge,
  UpdatedChallenge,
} from "domains/challenges/store/types"
import { apiCall } from "api-calls/api-call"
import { CreateProduct, PatchProduct, Product } from "../store/types"


export const getProducts = async() => {
  return apiCall({
    method: "GET",
    path: "products",
  })
}


export const updateChallenge = async(data: UpdatedChallenge): Promise<void> => {
  return apiCall({
    method: "PATCH",
    path: "challenges/goal/update-goal-level-data",
    body: data,
  })
}

export const patchProduct = async(
  productId: string,
  data: PatchProduct,
): Promise<void> => {
  return apiCall({
    method: "PATCH",
    path: `products/${productId}`,
    body: data,
  })
}

export const deleteProduct = async(
  productId: string,
): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `products/${productId}`,
  })
}

export const createProduct = async(
  data: CreateProduct,
): Promise<void> => {
  return apiCall({
    method: "POST",
    path: "products",
    body: data,
  })
}
