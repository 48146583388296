import { Tag } from "antd"
import { gameNames } from "abstract/constants/game-ids"
import { Level } from "domains/challenges/store/types"
import { CopyOutlined } from "@ant-design/icons"
import { notifications } from "notifications"

const copyId = (goalId: string) => {
  return (
    <div
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        navigator.clipboard.writeText(goalId)
        notifications.success("Copied goalId:" + goalId)
      }}
      style={{
        background: "#A3A3A3",
        width: 24,
        height: 24,
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      <CopyOutlined style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }} />
    </div>
  )
}
export const challengesColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id: string) => {
      return <div>{copyId(id)}</div>
    },
  },
  {
    title: "Game id",
    dataIndex: "gameId",
    key: "gameId",
  },
  {
    title: "Game",
    dataIndex: "gameId",
    key: "gameId",
    render: (gameId: number) => {
      return <div>{gameNames[gameId]}</div>
    },
  },
  {
    title: "Challenge name",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (value: string) => (
      <Tag color={value === "ACCUMULATIVE" ? "darkcyan" : "orange"}>{value}</Tag>
    ),
  },
  {
    title: "Lvl 1: Goal / Reward",
    dataIndex: "levels",
    key: "levels",
    render: (level: Level[]) => {
      const lvl = level.filter(item => +item.level === 1)

      if (!lvl[0]) return null

      return (
        <div>
          {lvl[0].goalValue} / {lvl[0].reward}
        </div>
      )
    },
  },
  {
    title: "Lvl 2: Goal / Reward",
    dataIndex: "levels",
    key: "levels",
    render: (level: Level[]) => {
      const lvl = level.filter(item => +item.level === 2)

      if (!lvl[0]) return null

      return (
        <div>
          {lvl[0].goalValue} / {lvl[0].reward}
        </div>
      )
    },
  },

  {
    title: "Lvl 3: Goal / Reward",
    dataIndex: "levels",
    key: "levels",
    render: (level: Level[]) => {
      const lvl = level.filter(item => +item.level === 3)

      if (!lvl[0]) return null

      return (
        <div>
          {lvl[0].goalValue} / {lvl[0].reward}
        </div>
      )
    },
  },
]
