import React, { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { Tabs, Table, Input, Button } from "antd"
import { observer } from "mobx-react"
import { BlacklistType } from "../store/types"
import { DeleteIconButton } from "components"
import { ColumnProps } from "antd/lib/table"

const { TabPane } = Tabs

export const BlacklistList: FC = observer<FC>(() => {
  const { dataStore: { blacklistStore } } = useStore()

  const [activeTab, setActiveTab] = useState("fingerprint")
  const [inputValue, setinputValue] = useState<string>("")

  useEffect(() => {
    blacklistStore.getBlacklist()
  }, [])

  const submitList = () => {
    const value = {}
    value[activeTab] = inputValue.split("##").filter(el => el.length > 0)
    blacklistStore.funcAfterSuccess = () => {
      blacklistStore.getBlacklist()
      setinputValue("")
    }

    blacklistStore.postBlacklist(value)
  }
  const columns = (type: BlacklistType): ColumnProps<string>[] => [
    {
      title: `Value of ${String(type).toLocaleLowerCase()}`,
      dataIndex: "value",
      key: "value",
      render(v, r) {
        return <span>{r}</span>
      },
    },
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      width: "70px",
      render: (_: any, row: string) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            blacklistStore.funcAfterSuccess = () => {
              blacklistStore.getBlacklist()
            }
            blacklistStore.deleteBlacklist(type, row)
          }}
        />
      ),
    },
  ]

  return (
    <>
      <Input
        value={inputValue}
        onChange={e => setinputValue(e.target.value)}
        style={{ width: "500px", marginRight: "15px", marginTop: "20px", marginBottom: "20px" }}
        placeholder="Input values without spaces divide ##"
      />
      <Button type="primary" htmlType="submit" onClick={submitList}>
        Submit
      </Button>
      <Tabs
        style={{ marginBottom: "20px", width: "600px" }}
        activeKey={activeTab}
        onChange={setActiveTab}
      >
        <TabPane tab="Fingerprint" key="fingerprint">
          <Table<any>
            dataSource={blacklistStore.blacklist.fingerprint}
            columns={columns("FINGERPRINT")}
            rowKey="id"
          />
        </TabPane>
        <TabPane tab="Country" key="country">
          <Table<any>
            dataSource={blacklistStore.blacklist.country}
            columns={columns("COUNTRY")}
            rowKey="id"
          />
        </TabPane>
        <TabPane tab="IP" key="ip">
          <Table<any>
            dataSource={blacklistStore.blacklist.ip}
            columns={columns("IP")}
            rowKey="id"
          />
        </TabPane>
      </Tabs>
    </>
  )
})
