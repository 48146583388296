import React, { FC } from "react"
import { useStore } from "stores/utils/use-store"

import { Form, Input } from "antd"
import { observer } from "mobx-react"

import { ChildToUpdate } from "domains/promocodes/store/types"

import { SubmitCancelButtons } from "components"

export const ChildEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      childPromocodesStore: { selectedElement: { code } },
      childPromocodesStore,
      parentPromocodesStore: { selectedElement: { id: parentPromoCodeId } },
    },
  } = useStore()

  const onFinishHandler = async(event: ChildToUpdate) => {
    const dataToBack = {
      code: event.code,
      parentPromoCodeId,
    }

    childPromocodesStore.funcAfterSuccess = async() => {
      onClose()
      await childPromocodesStore.getData()
    }
    editType === "edit"
      ? await childPromocodesStore.update(dataToBack)
      : await childPromocodesStore.create(dataToBack)
  }

  return (
    <Form onFinish={onFinishHandler} labelCol={{ span: 5 }} labelAlign="left">
      <Form.Item
        label="Enter the code"
        name="code"
        initialValue={code}
        rules={[
          {
            required: true,
            message: "Please enter the code!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <SubmitCancelButtons onClose={onClose} isLoading={childPromocodesStore.isLoading} />
    </Form>
  )
})
