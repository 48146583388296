import React, { FC } from "react"
import { Button } from "antd"
import { DeleteOutlined } from "@ant-design/icons"

interface DeleteIconButtonProps {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  title?: string;
}

export const DeleteIconButton: FC<DeleteIconButtonProps> = ({ disabled, onClick, title }) => {
  return (
    <Button
      disabled={disabled}
      type="primary"
      danger
      onClick={onClick}
      icon={<DeleteOutlined />}
      title={title}
    />
  )
}
