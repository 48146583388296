import React, { FC, useState } from "react"
import { Form, Select } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { CodesInventoryType } from "../../store/types"
import { UploadComponent, SubmitCancelButtons, FormElement } from "components"

export const CodeCreation: FC<{
  closeModal: () => void;
  editType: "create" | "edit";
}> = observer(({ closeModal }) => {
  const {
    dataStore: {
      codesStore,
      codesStore: { selectedElement },
    },
  } = useStore()

  const [image, setImage] = useState(selectedElement?.imageUrl || "")

  const onFinishHandler = async(e: any) => {
    const obj: CodesInventoryType = {
      name: e.name,
      description: e.description,
      howToRedeem: e.howToRedeem,

      imageUrl: image,
      geo: e.geo,
      category: e.category,
      itemType: e.itemType,
    }
    codesStore.funcAfterSuccess = async() => {
      closeModal()
      await codesStore.getData()
    }

    if (selectedElement?.id) {
      await codesStore.updateCodes(selectedElement?.id, { ...obj, id: selectedElement.id })
    } else {
      await codesStore.createCodes(obj)
    }
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} id="createBanner">
      <FormElement
        label="Name"
        name="name"
        initialValue={selectedElement?.name}
        type="input"
        style={{ marginTop: "20px" }}
      />

      <FormElement
        label="Description"
        name="description"
        initialValue={selectedElement?.description}
        type="text"
      />
      <FormElement
        label="How to redeem"
        name="howToRedeem"
        initialValue={selectedElement?.howToRedeem}
        type="text"
      />
      <FormElement label="Geo" name="geo" initialValue={selectedElement?.geo} type="input" />
      <FormElement
        label="Category"
        name="category"
        initialValue={selectedElement?.category}
        type="input"
      />

      <Form.Item label="itemType" name="itemType" initialValue={selectedElement?.itemType}>
        <Select>
          <Select.Option value="REGULAR">REGULAR</Select.Option>
          <Select.Option value="PREMIUM">PREMIUM</Select.Option>
          <Select.Option value="BUFF_PAY">BUFF_PAY</Select.Option>
        </Select>
      </Form.Item>

      <UploadComponent
        image={image}
        setImage={setImage}
        uploadStyle={{ className: "avatar-uploader", listType: "picture" }}
        imgStyle={{ width: "50px", textAlign: "center" }}
        placeholder="Upload item image"
        placeHolderStyle={{ display: "flex", flexDirection: "row" }}
      />
      <SubmitCancelButtons onClose={closeModal} isLoading={codesStore.isLoading} />
    </Form>
  )
})
