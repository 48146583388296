import { useState } from "react"
import { popupAPI } from "../api/popupApi"
import { notifications } from "notifications"

export const usePopupActivation = (initialActivationStatus: boolean = true) => {
  const [isActivated, setIsActivated] = useState(initialActivationStatus)
  const { activate, deactivate } = popupAPI
  const [isLoading, setIsLoading] = useState(false)

  const changeActivationStatus = async(status: boolean, id?: number) => {
    setIsLoading(true)
    try {
      if (status) {
        if (id) await activate(id)
      } else {
        if (id) await deactivate(id)
      }
      setIsActivated(status)
    } catch (error) {
      console.error(error)
      notifications.error("Failed to change activation status")
    }
    setIsLoading(false)
  }


  return {
    isActivated,
    isLoading,
    changeActivationStatus,
  }
}
