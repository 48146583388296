import React, { FC } from "react"
import { Form, Input } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { RaffleWinner } from "domains/raffles/store/types"
import { DeleteIconButton, showDeleteConfirm, SubmitCancelButtons } from "components"

export const WinnerCreation: FC<{
  closeModal: () => void;
  prizeId: string;
  winnerData?: RaffleWinner;
}> = observer(({ prizeId, closeModal, winnerData }) => {
  const { dataStore: { rafflesStore } } = useStore()

  const onFinishHandler = async(data: any) => {
    const obj = {
      accountId: data.accountId && data.accountId.length > 0 ? data.accountId : undefined,
      email: data.email && data.email.length > 0 ? data.email : undefined,
      login: data.login && data.login.length > 0 ? data.login : undefined,
      rafflePrizeId: prizeId,
      confirm: true,
    }
    rafflesStore.funcAfterSuccess = async() => {
      closeModal()
      rafflesStore.resetFuncAfterSuccess()
      await rafflesStore.getPrizes()
    }
    if (winnerData?.id) {
      await rafflesStore.updateWinner(obj, winnerData?.id)
    } else {
      await rafflesStore.createWinner(obj)
    }
  }

  return (
    <Form onFinish={onFinishHandler} id="raffleWinnerCreation">
      <div style={{ marginBottom: 15, marginTop: 25 }}>
        <span style={{ color: "red" }}>*</span> Enter one of the following:
      </div>
      {winnerData?.id && (
        <DeleteIconButton
          title="Delete prize"
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(`winner with login ${winnerData?.login}`, undefined, async() => {
              if (winnerData?.id) {
                rafflesStore.funcAfterSuccess = async() => {
                  closeModal()
                  rafflesStore.resetFuncAfterSuccess()
                  await rafflesStore.getPrizes()
                }
                await rafflesStore.disableWinner(prizeId)
              }
            })
          }}
        />
      )}
      <Form.Item label="Email" name="email" initialValue={winnerData?.accountRaffle?.email}>
        <Input type="email" />
      </Form.Item>

      <Form.Item label="Login" name="login" initialValue={winnerData?.accountRaffle?.login}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Account ID"
        name="accountId"
        initialValue={winnerData?.accountRaffle?.accountId}
      >
        <Input />
      </Form.Item>
      <SubmitCancelButtons onClose={closeModal} isLoading={rafflesStore.isLoading} />
    </Form>
  )
})
