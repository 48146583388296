import { PaginationStore } from "stores/data/pagination.store"
import { PromoPopUp } from "domains/promo-pop-ups/store/types"
import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { EntityToUpdate } from "abstract/entity-to-update"
import { getPromoPopups, createPromoPopup, updatePromoPopup, deletePromoPopups } from "../api"

export class PromoPopUpsStore extends PaginationStore<PromoPopUp, string> {
  @observable
  private _popUps: PromoPopUp[]
  @observable
  private _selectedElement: PromoPopUp = {} as PromoPopUp

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<PromoPopUp[]> {
    this.resetFuncAfterSuccess()
    const res = await this.defaultFunction(getPromoPopups)

    transaction(() => {
      this._popUps = res
    })

    return res.data
  }
  async updatePopUp(newPopUp: EntityToUpdate<PromoPopUp>) {
    await this.defaultFunction(updatePromoPopup, this._selectedElement.id, newPopUp)
  }
  async createPopUp(popUp: EntityToUpdate<PromoPopUp>) {
    await this.defaultFunction(createPromoPopup, popUp)
  }
  async deletePopUp(id: string) {
    await this.defaultFunction(deletePromoPopups, id)
  }
  @computed
  get popUps(): PromoPopUp[] {
    return this._popUps
  }

  @computed
  get selectedPopUp(): PromoPopUp {
    return this._selectedElement
  }

  set setSelectedPopUp(value: PromoPopUp) {
    this._selectedElement = value
  }
}
