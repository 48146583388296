import { EntityToUpdate } from "abstract/entity-to-update"
import { apiCall } from "api-calls/api-call"
import { DataUploadType, EmailCTA } from "../store/types"

export const getEmailCTAs = async(
  filter: string,
  page: number,
  limit: number,
  order?: Record<any, any>,
): Promise<{
  data: EmailCTA[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string; order?: any } = {
    page,
    limit,
  }
  if (filter) query.filter = filter
  if (order) {
    const ord = Object.entries(order)
    console.log(order, ord[0][0], "!!!!!!!!!")

    query.order = order
  }

  return apiCall({
    method: "GET",
    path: "rewards/cta-email",
    query,
  })
}
export const createEmailCTA = async(data: EntityToUpdate<EmailCTA>): Promise<{
  data: EmailCTA;
}> => {
  return apiCall({
    method: "POST",
    path: "rewards/cta-email",
    body: data,
  })
}
export const sendEmailCTA = async(id: string) => {
  return apiCall({
    method: "POST",
    path: `rewards/cta-email/send/${id}`,
    body: { id },
  })
}
export const sendCsvEmailCTA = async(id: string): Promise<{
  data: EmailCTA;
}> => {
  return apiCall({
    method: "POST",
    path: `rewards/cta-email/${id}`,
  })
}

export const uploadUrl = async(): Promise<DataUploadType> => {
  return apiCall({
    method: "GET",
    path: "files/upload-url?mimeType=csv",
  })
}

export const sendEmailCsv = async(data: EntityToUpdate<EmailCTA>) => {
  return apiCall({
    method: "POST",
    path: "rewards/cta-email/csv",
    body: data,
  })
}
