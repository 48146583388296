import { PaginationStore } from "stores/data/pagination.store"
import { PopUp } from "stores/primitives/pop-up"
import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { EntityToUpdate } from "abstract/entity-to-update"
import { createPopup, deletePopup, getPopups, updatePopup } from "domains/pop-ups/api/pop-ups.call"

export class PopUpsStore extends PaginationStore<PopUp, string> {
  @observable
  private _popUps: PopUp[]
  @observable
  private _selectedElement: PopUp = {} as PopUp

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<PopUp[]> {
    const { page, limit } = this.pagination

    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getPopups, this.filter, page, limit)

    transaction(() => {
      this._popUps = res.data
      this.total = res.total
    })

    return res.data
  }
  async updatePopUp(newPopUp: EntityToUpdate<PopUp>) {
    await this.defaultFunction(updatePopup, this._selectedElement.id, newPopUp)
  }
  async createPopUp(popUp: EntityToUpdate<PopUp>) {
    await this.defaultFunction(createPopup, popUp)
  }

  async deletePopUp(id: string) {
    await this.defaultFunction(deletePopup, id)
  }

  @computed
  get popUps(): PopUp[] {
    return this._popUps
  }

  @computed
  get selectedPopUP(): PopUp {
    return this._selectedElement
  }

  set setSelectedPopUp(value: PopUp) {
    this._selectedElement = value
  }
  clearSelectedElement() {
    this._selectedElement = {} as PopUp
  }
}
