import { Column } from "abstract/types/column.type"

import { Tag } from "antd"
import { ProviderItem } from "domains/marketplace/store/types"

import Styles from "./styles.module.css"

export const marketplaceColumns: Column[] = [
  { title: "Name", key: "name", dataIndex: "name", fixed: true },

  {
    title: "Category",
    key: "items_category",
    dataIndex: ["itemsCategory", "name"],
  },
  {
    title: "Item Provider",
    key: "itemProvider",
    align: "center",
    dataIndex: "providerItem",
    render: (providerItem: ProviderItem) => {
      if (providerItem?.itemProvider?.name) {
        if (providerItem?.itemProvider?.name === "kinguin") return (
          <Tag color="purple">{providerItem?.itemProvider?.name || "Internal"}
          </Tag>)

        if (providerItem?.itemProvider?.name === "gcow") return <Tag color="orange">{providerItem?.itemProvider?.name || "gcow"}</Tag>

        return <Tag color="blue">{providerItem?.itemProvider?.name || "Internal"}</Tag>
      }

      return <Tag color="gray">{providerItem?.itemProvider?.name || "Internal"}</Tag>
    },
  },
  {
    title: "Max Price In Euro",
    key: "priceLabel",
    align: "center",
    dataIndex: "providerItem",
    render: (providerItem: ProviderItem) => {
      providerItem = providerItem || {}
      const { price } = providerItem

      return <Tag color={price ? "blue" : "gray"}>{price ? price : "Internal"}</Tag>
    },
  },
  {
    title: "Price",
    key: "price",
    align: "center",
    dataIndex: "price",
    render: (price: number) => <Tag color="blue">{Number(price * 100).toFixed(2)}</Tag>,
  },
  {
    title: "Premium price",
    key: "premiumPrice",
    align: "center",
    dataIndex: "premiumPrice",
    render: (price: number) => <Tag color="blue">{Number(price * 100).toFixed(2)}</Tag>,
  },

  {
    title: "Status",
    key: "active",
    align: "center",
    dataIndex: "active",
    render: (active: boolean) => (
      <Tag color={active ? "blue" : "red"}>{active ? "Active" : "Disabled"}</Tag>
    ),
  },

  {
    title: "Icon",
    key: "img",
    dataIndex: "img",
    render: (img: string) => img ? <img src={img} alt="ico" className={Styles.icon} /> : <Tag>No Image</Tag>,
  },
  {
    title: "Items Available",
    key: "giftCards",
    dataIndex: "giftCards",
    align: "center",
    render: (el: number) => el || <Tag>No giftcards</Tag>,
  },
]
