import { apiCall } from "api-calls/api-call"
import { ResponsiveTemplate } from "../store/ResponsiveTemplate.store"
import { GenericTemplateInterface, GenericTemplateWithIdInterface } from "../store/types"
import { StyledTemplatePayload } from "../store/StyledTemplate.store"

type TemplateDTO = ResponsiveTemplate & {
  id: number;
  createdAt: string;
  updatedAt: string;
};

export type ResponsiveTemplateGetDTO = Omit<GenericTemplateWithIdInterface, "popupVersion"> & {
  payload: {
    popupVersion: "responsive"
  }
};

export type StyledTemplateGetDTO = Omit<GenericTemplateWithIdInterface, "popupVersion"> & {
  payload: StyledTemplatePayload
};

export type TemplateGetDTO = ResponsiveTemplateGetDTO | StyledTemplateGetDTO

type GetAllRes = {
  data: TemplateGetDTO[];
  value: number;
};

type PostBody = GenericTemplateInterface & {
  payload?: string;
};

export type PutBody = GenericTemplateWithIdInterface & {
  payload?: string;
};

const MAX_TEMPLATES_TO_LOAD_IN_ONE_API_CALL = 100 // API MAX LIMIT 100

export const popupAPI = {
  getAll: async(page: number = 1, popUps: TemplateGetDTO[] = []): Promise<TemplateGetDTO[]> => {
    const res: GetAllRes = await apiCall({
      method: "GET",
      path: `extended-pop-ups?page=${page}&limit=${MAX_TEMPLATES_TO_LOAD_IN_ONE_API_CALL}`,
      withAuth: true,
    })
    popUps.push(...res.data)
    if (res.data.length === MAX_TEMPLATES_TO_LOAD_IN_ONE_API_CALL) {
      return popupAPI.getAll(page + 1, popUps)
    } else {
      return popUps
    }
  },

  post: async(body: PostBody) => {
    const res: TemplateDTO = await apiCall({
      method: "POST",
      path: "extended-pop-ups",
      body: { ...body },
    })

    return res.id
  },

  delete: (id: number) => apiCall({
    method: "DELETE",
    path: `extended-pop-ups/${id}`,
  }),

  put: (body: PutBody) => apiCall({
    method: "PUT",
    path: `extended-pop-ups/${body.id}`,
    body: { ...body },
    query: { id: body.id },
  }),

  activate: (id: number) => apiCall({
    method: "POST",
    path: `extended-pop-ups/${id}/activate`,
  }),

  deactivate: (id: number) => apiCall({
    method: "POST",
    path: `extended-pop-ups/${id}/deactivate`,
  }),
}
