import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"
import { useDebouncedCallback } from "use-debounce"

import Search from "antd/es/input/Search"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { Button, Modal, Table } from "antd"

import { ReactComponent as Filter } from "assets/filter.svg"

import { MarketplaceFilter } from "./marketplace.filter"
import { Column } from "abstract/types/column.type"
import { marketplaceColumns } from "./columns"

import { ItemType } from "domains/marketplace/store/types"
import { MarketplaceEdit } from "domains/marketplace/components/ItemTypes/Edit"
import { KeysList } from "domains/marketplace/components/ItemTypes/KeysList"
import { DeleteIconButton, showDeleteConfirm, CreateNewElement } from "components"
import Styles from "./styles.module.css"
import { MarketplaceInfo } from "../Info"
import { LockOutlined } from "@ant-design/icons"

export const MarketplaceList: FC = observer(() => {
  const {
    dataStore: {
      marketplaceStore,
      itemProviderStore,
      marketplaceCategoriesStore,
      tierStore,
      bannersStore,
      marketplaceRegionsStore,
    },
  } = useStore()

  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [valSearch, setValSearch] = useState<string>("")

  const [isVisibleInfo, setInfoVisibility] = useState<boolean>(false)

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  const [isVisibleKeyInfo, setKeyInfoVisibility] = useState<boolean>(false)
  const [isVisibleFilter, setIsVisibleFilter] = useState<boolean>(false)

  useEffect(() => {
    marketplaceStore.getData()
    itemProviderStore.getData()
    marketplaceCategoriesStore.getData()
    tierStore.getData()
    marketplaceRegionsStore.getData()
  }, [
    marketplaceStore,
    itemProviderStore,
    marketplaceCategoriesStore,
    tierStore,
    marketplaceRegionsStore,
  ])

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      marketplaceStore.pagination = {
        page,
        limit: pageSize || marketplaceStore.pagination.limit,
      }
    },
    total: marketplaceStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: marketplaceStore.pagination.page,
  }

  const debounce = useDebouncedCallback(async(val: string) => {
    marketplaceStore.setFilter({ name: val.toUpperCase().trim() })
    setLoadingSearch(false)
  }, 2000)

  const onClose = () => {
    itemProviderStore.clearItem()
    itemProviderStore.setVisibleError(false)
    marketplaceStore.clearSelectedElement()
    bannersStore.clearSelectedElement()
    setEditCreateVisibility(null)
    setInfoVisibility(false)
    marketplaceStore.isLoading = false
  }

  const columns: Column[] = [
    ...marketplaceColumns,
    {
      title: "Key list",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: ItemType) => (
        <LockOutlined
          style={{ background: "green", padding: "9px", borderRadius: "2px", color: "white" }}
          onClick={e => {
            e.stopPropagation()
            marketplaceStore.selectedElement = row
            setKeyInfoVisibility(true)
          }}
        />
      ),
    },

    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (_: any, row: ItemType) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(`market item: ${row.name}`, undefined, async() => {
              marketplaceStore.funcAfterSuccess = async() => await marketplaceStore.getData()
              marketplaceStore.delete(row.id)
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <div className={Styles.header}>
        <CreateNewElement
          onClick={() => setEditCreateVisibility("create")}
          valueText="market item"
        />
      </div>

      <div className={Styles.containerSearch}>
        <Button
          icon={<Filter />}
          color="white"
          type="default"
          size="middle"
          onClick={() => setIsVisibleFilter(!isVisibleFilter)}
        />
        <Search
          placeholder="Write some name for search ..."
          allowClear
          loading={loadingSearch}
          value={valSearch}
          onChange={e => {
            const val = e.target.value
            setValSearch(val)
            setLoadingSearch(true)
            debounce(val)
          }}
          onSearch={(value: string) => {
            marketplaceStore.setFilter({ name: value.toUpperCase().trim() })
          }}
        />
      </div>
      <MarketplaceFilter
        isVisibleFilter={isVisibleFilter}
        setIsVisibleFilter={setIsVisibleFilter}
      />
      <Table
        dataSource={marketplaceStore.itemTypes}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={marketplaceStore.isLoading}
        scroll={{ x: true, y: "70vh" }}
        onRow={(record: ItemType) => ({
          onClick: () => {
            marketplaceStore.selectedElement = record
            setInfoVisibility(true)
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit market item" : "Create market item"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <MarketplaceEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
      <Modal visible={isVisibleInfo} footer={null} onCancel={onClose}>
        <Button
          type="primary"
          style={{ marginBottom: "10px", width: "95%" }}
          onClick={() => {
            setInfoVisibility(false)
            setEditCreateVisibility("edit")
          }}
        >
          Edit
        </Button>
        <MarketplaceInfo />
      </Modal>
      <Modal
        visible={isVisibleKeyInfo}
        footer={null}
        onCancel={() => {
          setKeyInfoVisibility(false)
          marketplaceStore.getData()
        }}
        width="85%"
      >
        <KeysList itemTypeId={marketplaceStore?.selectedElement?.id} />
      </Modal>
    </>
  )
})
