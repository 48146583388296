export type EmailCTA = {
  accountId: string;
  email: string;
  templateId: string;
  category: string;
  id: string;
  type:
    | "PREMIUM"
    | "PREMIUM_LITE"
    | "PREMIUM_PLUS"
    | "PREMIUM_ELITE"
    | "MARKETPLACE_DISCOUNT"
    | "MARKETPLACE"
    | "BUFFS"
    | "RAFFLE_TICKETS"
    | "BOOST_TIMED"
    | "BOOST_SEASONAL"
    | "SPIN_COLLECT_GOLDEN_TICKET";
  data:
    | {
        percentage: number;
        period: "HOURS" | "DAYS";
        duration: number;
        gameIds: number[];
        personalBoosterType: PersonalBoosterType;
      }
    | {
        amount: number;
      }
    | { percentage: number }
    | {
        premiumType: "PREMIUM" | "PREMIUM_PLUS" | "PREMIUM_ELITE";
        duration: number;
        period: "HOURS" | "DAYS";
      }
    | { count: number };
  needToSend: boolean;

  createdAt?: string;
};

export enum PersonalBoosterType {
  UNKNOWN = "UNKNOWN",
  BOOST_TIMED = "BOOST_TIMED",
  BOOST_SEASONAL = "BOOST_SEASONAL",
}
export type DataUploadType = {
  putUrl: string;
  fileData: {
    bucket: string;
    key: string;
    region: string;
  };
};
