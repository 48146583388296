import { Button, Modal, Table } from "antd"
import { TablePaginationConfig } from "antd/lib/table/interface"

import { MarketplaceRegion } from "domains/marketplace/store/types"
import { observer } from "mobx-react"
import { FC, useEffect, useState } from "react"

import { useStore } from "stores/utils/use-store"
import { showDeleteConfirm, CreateNewElement } from "components"
import { MarketplaceRegionEdit } from "../Edit"
import { MarketplaceRegionInfo } from "../Info"
import { marketplaceRegionsColumns } from "./columns"

export const MarketplaceRegionsList: FC = observer(() => {
  const { dataStore: { marketplaceRegionsStore } } = useStore()

  const [isVisibleInfo, setInfoVisibility] = useState<boolean>(false)

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  useEffect(() => {
    marketplaceRegionsStore.getData()
  }, [marketplaceRegionsStore])

  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      marketplaceRegionsStore.pagination.page = page
    },
    total: marketplaceRegionsStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 100,
    current: marketplaceRegionsStore.pagination.page,
  }

  const onClose = () => {
    marketplaceRegionsStore.clearSelectedElement()
    setEditCreateVisibility(null)
    setInfoVisibility(false)
    marketplaceRegionsStore.isLoading = false
  }

  return (
    <>
      <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="region" />

      <Table
        dataSource={marketplaceRegionsStore.regions}
        columns={marketplaceRegionsColumns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={marketplaceRegionsStore.isLoading}
        scroll={{ x: true, y: "70vh" }}
        onRow={(record: MarketplaceRegion) => ({
          onClick: () => {
            marketplaceRegionsStore.selectedElement = record
            setInfoVisibility(true)
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit region item" : "Create region item"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <MarketplaceRegionEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
      <Modal visible={isVisibleInfo} footer={null} onCancel={onClose}>
        <MarketplaceRegionInfo />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Button
            type="primary"
            style={{ width: "45%" }}
            onClick={() => {
              setInfoVisibility(false)
              setEditCreateVisibility("edit")
            }}
          >
            Edit
          </Button>
          <Button
            type="primary"
            style={{ width: "45%" }}
            danger
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(
                `Marketplace region: ${marketplaceRegionsStore.selectedElement?.name}`,
                undefined,
                async() => {
                  marketplaceRegionsStore.funcAfterSuccess = async() => await marketplaceRegionsStore.getData()

                  marketplaceRegionsStore.deleteById(marketplaceRegionsStore.selectedElement.id)
                },
              )
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  )
})
