import { Tag } from "antd"
import { defaultFormat } from "utils/date"

export const utmBonusColumns = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Parameter", dataIndex: "utmDataField", key: "utmDataField" },
  { title: "geoRegion", dataIndex: "geoRegion", key: "geoRegion" },
  { title: "Code", dataIndex: "code", key: "code" },
  { title: "Bonus Amount", dataIndex: "amount", key: "amount" },
  {
    title: "Bonus Subscription",
    dataIndex: "subscription",
    key: "subscription",
    render: subscription => subscription && subscription.name,
  },
  { title: "Billing Period", dataIndex: "billingPeriod", key: "billingPeriod" },
  {
    title: "Active Until",
    dataIndex: "expiresAt",
    key: "expiresAt",
    render: expiresAt => <Tag color="blue">{defaultFormat(expiresAt)}</Tag>,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (time: Date) => {
      return <Tag color="green">{defaultFormat(time)}</Tag>
    },
  },
]
