/* eslint-disable react/jsx-curly-brace-presence */
import { DeleteOutlined } from "@ant-design/icons"
import { Button, Input, InputNumber, Typography } from "antd"
import { Pixel } from "domains/managingJson/store/types"

const { Text } = Typography

const isTimestampPresent = (url: string) => url.includes("[timestamp]")

type Props = {
  pixel: Pixel;
  index: number;
  deletePixel: (index: number) => void;
  onChange: (index: number, callback: (pixel: Pixel) => Pixel) => void;
};

export const PixelEditRow = ({ pixel, index, deletePixel, onChange }: Props) => {
  const hasTimestamp = isTimestampPresent(pixel.src)
  const isEmpty = pixel.src === ""

  return (
    <div key={index} style={{ width: "100%", display: "flex", gap: 8, alignItems: "end" }}>
      <label style={{ flexGrow: 1 }}>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Text>Pixel URL</Text>
          {(!hasTimestamp && !isEmpty && !!pixel.firingInterval) && (
            <Text type="warning">
              {"Interval firing of a pixel without a [timestamp] shortcode won't work"}
            </Text>
          )}
        </div>
        <Input
          value={pixel.src}
          onChange={e => onChange(index, prev => {
            return { ...prev, src: e.target.value }
          })}
          status={(!hasTimestamp && !isEmpty && pixel.firingInterval) ? "warning" : undefined}
        />
      </label>
      <label>
        Interval (seconds):
        <InputNumber
          value={pixel.firingInterval}
          onChange={value => onChange(index, prev => ({ ...prev, firingInterval: value ?? 0 }))}
          style={{ display: "block" }}
        />
      </label>

      <Button
        danger
        type="primary"
        shape="circle"
        icon={<DeleteOutlined />}
        onClick={() => deletePixel(index)}
      />
    </div>
  )
}
