import Reactotron from "reactotron-react-js"

declare global {
  interface Console {
    tron: any;
  }
}

export default () => {
  Reactotron.configure().connect()

  console.tron = Reactotron
}
