/* eslint-disable prefer-const */
/* eslint-disable no-prototype-builtins */
import { rootStore } from "../index"
import { Events } from "abstract/events"
import { Config } from "../config/config"
import { notifications } from "notifications"

interface IApiCallProps {
  withAuth?: boolean;
  path: string;
  method?: string;
  query?: Record<string, any>;
  body?: Record<string, any>;
  formData?: FormData;
}

const serializeCustom = (obj: any, prefix?: any): any => {
  let str: any[] = [],
    p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p]
      str.push(v !== null && typeof v === "object"
        ? serializeCustom(v, k)
        : encodeURIComponent(k) + "=" + encodeURIComponent(v))
    }
  }

  return str.join("&")
}

export const apiCall = async({
  method = "GET",
  path,
  withAuth = true,
  query,
  body,
  formData,
}: IApiCallProps) => {
  const headers: HeadersInit = body
    ? {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
    : {}
  try {
    const qs = serializeCustom(query)

    const queryPostfix = qs ? "?" + qs : ""

    const token = rootStore.dataStore.authStore.token

    if (withAuth && token) {
      headers.Authorization = `Bearer ${token}`
    }
    console.log(method, path, withAuth, query, body)

    return fetch(`${Config.baseUrl}/${path}${queryPostfix}`, {
      headers,
      method,
      ...(body && { body: JSON.stringify(body) }),
      ...(formData && { body: formData }),
    })
      .then(async res => {
        if (!res.ok) {
          if (res.status === 401) {
            window.dispatchEvent(new Event(Events.JWT_EXPIRED))

            return
          }
          const data = await res.json()
          console.log(res.statusText, "Error, apiCall")
          if (data) {
            throw new Error(data.message, { cause: data })
          }
          throw new Error(`Network request error Status: ${res.status}`)
        }
        // 204 No content - cannot serialize res to json
        if (res.status === 204) return

        return res.json()
      })
      .catch((err: Error) => {
        if (String(err).includes("SyntaxError:") && String(err).includes("JSON")) {
          console.log(String(err))
        } else {
          console.log(String(err))
          // rethrowing the error so it can be handled in methods that actually should handle it
          throw new Error(err.message, { cause: err.cause })
        }
      })
  } catch (err) {
    notifications.error(String(err))
  }
}
