import { Collapse, Tag } from "antd"

import { defaultFormat } from "utils/date"

export const emailsColumns = [
  { title: "email", dataIndex: "email", key: "email", sorter: true },

  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    sorter: true,
  },

  {
    title: "Template Id",
    dataIndex: "templateId",
    key: "templateId",
    sorter: true,
  },

  {
    title: "Token Status",
    dataIndex: "tokenStatus",
    sorter: true,
    key: "tokenStatus",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "data",
    dataIndex: "data",
    key: "data",
    render: (data: any) => {
      return (
        <Collapse>
          <Collapse.Panel key="1" header="Show content" style={{ width: "350px" }}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Collapse.Panel>
        </Collapse>
      )
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,

    render: (createdAt: any) => {
      return (
        <div style={{ display: "grid", width: "max-content" }}>
          <Tag color="green">{defaultFormat(createdAt)}</Tag>
        </div>
      )
    },
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (updatedAt: any) => {
      return (
        <div style={{ display: "grid", width: "max-content" }}>
          <Tag color="blue">{defaultFormat(updatedAt)}</Tag>
        </div>
      )
    },
  },
]
