import React, { FC } from "react"
import { observer } from "mobx-react"
import { Modal } from "antd"
import { ChallengeEdit } from "domains/challenges/components/challenge.edit"
import { ChallengeProcessorInfo } from "domains/challenges/components/challenge-processor.info"

interface IChallengeDetails {
  isVisibleInfo: boolean;
  setInfoVisibility: (arg: boolean) => void;
}

export const ChallengeDetails: FC<IChallengeDetails> = observer(({ isVisibleInfo, setInfoVisibility }) => {
  return (
    <Modal
      visible={isVisibleInfo}
      onCancel={() => setInfoVisibility(false)}
      width={400}
      bodyStyle={{ paddingBottom: 0 }}
      footer={null}
    >
      <ChallengeEdit onClose={() => setInfoVisibility(false)} />
      <ChallengeProcessorInfo onClose={() => setInfoVisibility(false)} />
    </Modal>
  )
})
