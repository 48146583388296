export const GameIdsMap = {
  DOTA: 7314,
  LOL: 5426,
  FORTNITE: 21216,
  CSGO: 7764,
  RS6: 10826,
  PUBG: 10906,
  SPLITGATE: 21404,
  SPLITGATE_PSN: 214040,
  VALORANT: 21640,
  APEX: 21566,
  WARZONE: 21626,
  ROCKET_LEAGUE: 10798,
  LOL_TFT: 5426111,
  BRAWL_STARS: 20201,
  CLASH_ROYAL: 20202,
  CLASH_OF_CLANS: 20203,
  HEARTHSTONE: 9898,
  OVERWATCH: 10844,
  MINECRAFT_PC: 8032,
  HALO_INFINITE: 21854,
  FALL_GUYS: 42424,
  LEAP: 22312,
  LOL_ARENA: 21556,
  CALL_OF_DUTY_MW2: 22328,
  CALL_OF_DUTY_MW3: 23424,
  CALL_OF_DUTY_WARZONE2: 223281,
  PALWORLD: 23944,
}

export const gameIds = [
  GameIdsMap.DOTA,
  GameIdsMap.LOL,
  GameIdsMap.FORTNITE,
  GameIdsMap.CSGO,
  GameIdsMap.RS6,
  GameIdsMap.PUBG,
  GameIdsMap.SPLITGATE,
  GameIdsMap.SPLITGATE_PSN,
  GameIdsMap.VALORANT,
  GameIdsMap.APEX,
  GameIdsMap.WARZONE,
  GameIdsMap.ROCKET_LEAGUE,
  GameIdsMap.LOL_TFT,
  GameIdsMap.BRAWL_STARS,
  GameIdsMap.CLASH_ROYAL,
  GameIdsMap.CLASH_OF_CLANS,
  GameIdsMap.HEARTHSTONE,
  GameIdsMap.OVERWATCH,
  GameIdsMap.MINECRAFT_PC,
  GameIdsMap.HALO_INFINITE,
  GameIdsMap.FALL_GUYS,
  GameIdsMap.LEAP,
  GameIdsMap.LOL_ARENA,
  GameIdsMap.CALL_OF_DUTY_MW2,
  GameIdsMap.CALL_OF_DUTY_MW3,
  GameIdsMap.CALL_OF_DUTY_WARZONE2,
  GameIdsMap.PALWORLD,
]

export const gameNamesDesktop: Record<number, string> = {
  [GameIdsMap.DOTA]: "Dota 2",
  [GameIdsMap.LOL]: "League Of Legends",
  [GameIdsMap.FORTNITE]: "Fortnite",
  [GameIdsMap.CSGO]: "CS:GO",
  [GameIdsMap.RS6]: "Rainbow Six: Siege",
  [GameIdsMap.PUBG]: "PUBG",
  [GameIdsMap.SPLITGATE]: "Splitgate",
  [GameIdsMap.VALORANT]: "Valorant",
  [GameIdsMap.APEX]: "APEX",
  [GameIdsMap.WARZONE]: "Call of Duty: Warzone",
  [GameIdsMap.ROCKET_LEAGUE]: "Rocket League",
  [GameIdsMap.LOL_TFT]: "Teamfight Tactics: League of Legends",
  [GameIdsMap.HEARTHSTONE]: "Hearthstone",
  [GameIdsMap.OVERWATCH]: "Overwatch",
  [GameIdsMap.MINECRAFT_PC]: "Minecraft Desktop",
  [GameIdsMap.HALO_INFINITE]: "Halo Infinite",
  [GameIdsMap.FALL_GUYS]: "Fall Guys",
  [GameIdsMap.LEAP]: "LEAP",
  [GameIdsMap.LOL_ARENA]: "League of Legends Arena Mode",
  [GameIdsMap.CALL_OF_DUTY_MW2]: "Call of Duty: Modern Warfare II",
  [GameIdsMap.CALL_OF_DUTY_MW3]: "Call Of Duty: Modern Warfare III",
  [GameIdsMap.CALL_OF_DUTY_WARZONE2]: "Call of Duty: Warzone 2",
  [GameIdsMap.PALWORLD]: "Palworld",
}

export const gameNamesMobile: Record<number, string> = {
  [GameIdsMap.SPLITGATE_PSN]: "Splitgate PlayStation",
  [GameIdsMap.BRAWL_STARS]: "Brawl Stars",
  [GameIdsMap.CLASH_ROYAL]: "Clash Royal",
  [GameIdsMap.CLASH_OF_CLANS]: "Clash Of Clans",
}

export const gameNames: Record<number, string> = { ...gameNamesMobile, ...gameNamesDesktop }
