import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Modal, Table } from "antd"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { UtmBonus } from "domains/utm/store"

import { useStore } from "stores/utils/use-store"
import { Column } from "abstract/types/column.type"
import { UtmBonusEdit } from "../BonusesEdit"
import { utmBonusColumns } from "./columns"
import { DeleteIconButton, showDeleteConfirm, CreateNewElement } from "components"

export const UtmBonusesList: FC = observer(() => {
  const { dataStore: { utmBonusesStore, subscriptionStore } } = useStore()

  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      utmBonusesStore.pagination.page = page
    },
    total: utmBonusesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  useEffect(() => {
    utmBonusesStore.getData()
    subscriptionStore.getData()
  }, [utmBonusesStore, subscriptionStore])

  const onClose = () => {
    utmBonusesStore.clearSelectedElement()
    setEditCreateVisibility(null)
  }

  const data = () => {
    if (!utmBonusesStore || !utmBonusesStore.utmBonuses) {
      return []
    }

    return utmBonusesStore.utmBonuses.map(el => {
      const subscription = (subscriptionStore.subscriptions || []).find(sub => sub.id === el.subscriptionId)

      return {
        ...el,
        subscription,
      }
    })
  }

  const columns: Column[] = [
    ...utmBonusColumns,

    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (_: any, row: any) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(`market item: ${row.name}`, undefined, async() => {
              utmBonusesStore.funcAfterSuccess = async() => await utmBonusesStore.getData()
              utmBonusesStore.delete(row.id)
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="utm bonus" />
      <Table
        dataSource={data()}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={utmBonusesStore.isLoading}
        onRow={(record: UtmBonus) => ({
          onClick: () => {
            utmBonusesStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit utm bonus" : "Create utm bonus"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <UtmBonusEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
