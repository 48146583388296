import React, { useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Button, Divider, Form, Input } from "antd"
import { observer } from "mobx-react"

import { notifications } from "notifications"
import { PlusCircleOutlined } from "@ant-design/icons"
import { ConfigItemAtList } from "domains/managingJson/store/types"

export const EditNavbarAvatarTabs = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()

  const [form] = Form.useForm()

  const [navbarExist, setNavbarExist] = useState(false)
  const [highlights, setHighlights] = useState<{ queryName: string; tabName: string }[]>([
    { queryName: "", tabName: "" },
  ])
  const [discover, setDiscover] = useState<{ queryName: string; tabName: string }[]>([
    { queryName: "", tabName: "" },
  ])

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const navbarAvatarTabsData = managingJsonStore?.itemTypes?.filter(
      item => item.type === "navbarAvatarTabs") as ConfigItemAtList<"navbarAvatarTabs">[]
    if (
      navbarAvatarTabsData
      && navbarAvatarTabsData.length === 1
      && navbarAvatarTabsData[0]?.data
    ) {
      setNavbarExist(true)
      navbarAvatarTabsData[0]?.data?.discover
        && setDiscover(navbarAvatarTabsData[0]?.data?.discover)
      navbarAvatarTabsData[0]?.data?.highlights
        && setHighlights(navbarAvatarTabsData[0]?.data?.highlights)

      const tempValue = {}

      navbarAvatarTabsData[0]?.data?.discover?.map((item, id) => {
        tempValue[`discoverqueryName${id}`] = item.queryName
        tempValue[`discovertabName${id}`] = item.tabName

        return null
      })

      navbarAvatarTabsData[0]?.data?.highlights?.map((item, id) => {
        tempValue[`highlightsqueryName${id}`] = item.queryName
        tempValue[`highlightstabName${id}`] = item.tabName

        return null
      })
      form.setFieldsValue({ ...tempValue })
    }
  }, [managingJsonStore.itemTypes, form])

  const onFinishHandler = async(event: any) => {
    const data = {}
    const highlightsArr = highlights.map((_, id) => {
      return {
        queryName: event[`highlightsqueryName${id}`],
        tabName: event[`highlightstabName${id}`],
      }
    })
    const discoverArr = discover.map((_, id) => {
      return {
        queryName: event[`discoverqueryName${id}`],
        tabName: event[`discovertabName${id}`],
      }
    })
    data["highlights"] = highlightsArr
    data["discover"] = discoverArr

    managingJsonStore.funcAfterSuccess = async() => {
      notifications.success("Saved successfully")
      await managingJsonStore.getData()
    }
    navbarExist
      ? await managingJsonStore.update({
        name: "navbarAvatarTabs",
        type: "navbarAvatarTabs",
        data,
      })
      : await managingJsonStore.create({
        name: "navbarAvatarTabs",
        type: "navbarAvatarTabs",
        data,
      })
  }

  const deleteValue = (value: "discover" | "highlights", id: number) => {
    const dependingData = value === "discover" ? discover : highlights
    const arr = [...dependingData].filter((_, idx) => idx !== id)

    const tempValue = {}

    let idx = 0
    let idx2 = 0

    dependingData.forEach(() => {
      if (idx2 === id) {
        tempValue[`${value}queryName${idx2}`] = ""
        tempValue[`${value}tabName${idx2}`] = ""
        idx2++

        return
      }

      tempValue[`${value}queryName${idx}`] = form.getFieldValue(`${value}queryName${idx2}`)
      tempValue[`${value}tabName${idx}`] = form.getFieldValue(`${value}tabName${idx2}`)

      if (idx2 === dependingData.length - 1) {
        tempValue[`${value}queryName${idx2}`] = ""
        tempValue[`${value}tabName${idx2}`] = ""
      }
      idx++
      idx2++
    })
    value === "discover" ? setDiscover(arr) : setHighlights(arr)

    form.setFieldsValue({ ...tempValue })
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <Divider>Highlights tabs</Divider>
      {highlights.map((item, id) => (
        <div style={{ display: "flex", gap: "10px" }} key={id}>
          <Button onClick={() => deleteValue("highlights", id)}>Delete</Button>

          <Form.Item
            label={`Query name ${id + 1}`}
            name={`highlightsqueryName${id}`}
            style={{ width: "100%" }}
            rules={[
              {
                validator: (_, value) => !value.includes(" ")
                  ? Promise.resolve()
                  : Promise.reject(new Error("No spaces allowed")),
              },
              {
                required: true,
                message: "Please input query name!",
              },
            ]}
            initialValue={item.queryName}
          >
            <Input value={item.queryName} />
          </Form.Item>
          <Form.Item
            label={`Tab name ${id + 1}`}
            name={`highlightstabName${id}`}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please input tab name!",
              },
            ]}
            initialValue={item.tabName}
          >
            <Input value={item.tabName} />
          </Form.Item>
        </div>
      ))}
      <div
        onClick={() => setHighlights([...highlights, { queryName: "", tabName: "" }])}
        style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}
      >
        <div>Add Highlight</div>
        <PlusCircleOutlined />
      </div>

      <Divider>Discover tabs</Divider>
      {discover.map((item, id) => (
        <div style={{ display: "flex", gap: "10px" }} key={id}>
          <Button onClick={() => deleteValue("discover", id)}>Delete</Button>
          <Form.Item
            label={`Query name ${id + 1}`}
            name={`discoverqueryName${id}`}
            style={{ width: "100%" }}
            rules={[
              {
                validator: (_, value) => !value.includes(" ")
                  ? Promise.resolve()
                  : Promise.reject(new Error("No spaces allowed")),
              },

              {
                required: true,
                message: "Please input query name!",
              },
            ]}
            initialValue={item.queryName}
          >
            <Input value={item.queryName} />
          </Form.Item>
          <Form.Item
            label={`Tab name ${id + 1}`}
            name={`discovertabName${id}`}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please input tab name!",
              },
            ]}
            initialValue={item.tabName}
          >
            <Input value={item.tabName} />
          </Form.Item>
        </div>
      ))}
      <div
        onClick={() => setDiscover([...discover, { queryName: "", tabName: "" }])}
        style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}
      >
        <div>Add Discover</div>
        <PlusCircleOutlined />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
})
