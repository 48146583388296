import { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { Modal, Table } from "antd"
import { Column } from "abstract/types/column.type"
import { CreatePromoPopupForm } from "./create-promo-popup-form"
import { PromoPopUp } from "domains/promo-pop-ups/store/types"
import { showDeleteConfirm, DeleteIconButton, EditIconButton, CreateNewElement } from "components"

export const PromoPopUpsLists: FC = observer(() => {
  const { dataStore: { promoPopupsStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  const columns: Column[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: string) => <div title={id}>{id}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title: string) => <div title={title}>{title}</div>,
    },
    {
      title: "Image",
      dataIndex: "img",
      key: "img",
      render: (_: any, row: PromoPopUp) => (
        <img
          src={row.imageUrl}
          style={{ height: "70px", width: "70px", objectFit: "cover" }}
          alt=""
        />
      ),
    },
    {
      title: "Platforms",
      dataIndex: "platform",
      key: "platform",
      render: (_: any, row: PromoPopUp) => <div>{String(row.platforms)}</div>,
    },
    {
      title: "Edit",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: PromoPopUp) => (
        <EditIconButton
          onClick={e => {
            e.stopPropagation()
            setEditCreateVisibility("edit")
            promoPopupsStore.setSelectedPopUp = row
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: PromoPopUp) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(row.title, undefined, async() => {
              promoPopupsStore.funcAfterSuccess = async() => await promoPopupsStore.getData()
              await promoPopupsStore.deletePopUp(row.id)
            })
          }}
        />
      ),
    },
  ]

  useEffect(() => {
    promoPopupsStore.getData()
    promoPopupsStore.setSelectedPopUp = {} as PromoPopUp
  }, [promoPopupsStore])

  const onClose = () => {
    setEditCreateVisibility(null)
  }

  return (
    <>
      <CreateNewElement
        onClick={() => {
          promoPopupsStore.setSelectedPopUp = {} as PromoPopUp
          setEditCreateVisibility("create")
        }}
        valueText="promo popup"
      />
      <Table dataSource={promoPopupsStore.popUps} columns={columns as any} rowKey="id" />
      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit promo-popup" : "Create promo-popup"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <CreatePromoPopupForm editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
