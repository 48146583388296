import { FC } from "react"
import { Card } from "antd"

export const UtmData: FC<{
  data: {
    term: string;
    medium: string;
    campaign: string;
    extra: string;
    content: string;
    source: string;
    partnerId: string;
  };
}> = ({ data }) => {
  return (
    <Card title="Utm Data">
      <p>Term: {data?.term}</p>
      <p>Medium: {data?.medium}</p>
      <p>Campaign: {data?.campaign}</p>
      <p>Extra: {data?.extra}</p>
      <p>Content: {data?.content}</p>
      <p>Source: {data?.source}</p>
      <p>Partner Id: {data?.partnerId}</p>
    </Card>
  )
}
