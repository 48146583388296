import { DeleteOutlined } from "@ant-design/icons"
import { Button, FormInstance } from "antd"
import { FormElement } from "components"
import { Pixel } from "domains/managingJson/store/types"
import { FC } from "react"

interface props {
  pixelsList: Pixel[]
  setPixelsList: (pixels: Pixel[]) => void
  title: string
  description: string
  form: FormInstance<any>
}

export const Pixels: FC<props> = ({ pixelsList, setPixelsList, title, description, form }) => {

  const addPixel = () => {
    setPixelsList([...pixelsList, { src: "", firingInterval: 0 }])
  }
  const deletePixel = (index: number) => {
    const newPixels = [...pixelsList]
    newPixels.splice(index, 1)
    setPixelsList(newPixels)

    for (let i = index; i < newPixels.length; i++) {
      form.setFieldValue(`${title}${i}URL`, newPixels[i].src)
      form.setFieldValue(`${title}${i}Interval`, newPixels[i].firingInterval)
    }
  }

  return (
    <div style={{ border: "1px solid #F0F0F0", padding: "16px" }}>
      <div style={{ fontWeight: "500" }}>{title}</div>
      <div style={{ fontSize: "12px", marginBottom: "16px" }}>{description}</div>
      <div style={{ display: "flex", marginBottom: "16px" }}>
        <div style={{ fontWeight: "500" }}>Tracking Pixels</div>
        <Button style={{ marginLeft: "auto" }} type="primary" onClick={addPixel}>Add new Pixel</Button>
      </div>
      {pixelsList.map((pixel, index) => (
        <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }} key={title + index}>
          <div style={{ width: "60%", marginRight: "16px" }}>
            {index + 1} Pixel URL
            <FormElement
              type="input"
              label=""
              name={`${title}${index}URL`}
              initialValue={pixel.src}
              props={{
                onChange: e => {
                  const newPixels = [...pixelsList]
                  newPixels[index].src = e.target.value
                  setPixelsList(newPixels)
                },
              }}
            />
          </div>
          <div style={{ width: "20%", marginRight: "16px" }}>
            Interval (s)
            <FormElement
              type="input"
              label=""
              name={`${title}${index}Interval`}
              initialValue={pixel.firingInterval}
              props={{
                onChange: e => {
                  const newPixels = [...pixelsList]
                  newPixels[index].firingInterval = e.target.value
                  setPixelsList(newPixels)
                },
              }}
            />
          </div>
          <div
            style={{
              color: "#FF0000",
              fontSize: "18px",
              cursor: "pointer",
            }}
            onClick={() => {
              deletePixel(index)
            }}
          >
            <DeleteOutlined />
          </div>
        </div>
      ))}
    </div>
  )
}
