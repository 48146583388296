import { Tag } from "antd"

import { Column } from "abstract/types/column.type"
import { defaultFormat } from "utils/date"

export const accountColumns = [
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => el && <Tag color="green">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Is Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (el: boolean) => (el ? "Yes" : "No"),
  },
  {
    title: "Active Until",
    dataIndex: "activeUntil",
    key: "activeUntil",
    render: (el: Date) => el && <Tag color="blue">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Billing Plan Id",
    dataIndex: "billingPlanId",
    key: "billingPlanId",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Billing Period",
    dataIndex: "billingPeriod",
    key: "billingPeriod",
  },
  {
    title: "First Month Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Provider Name",
    dataIndex: "providerName",
    key: "providerName",
  },
]

export const transactionColumns: Column[] = [
  { title: "Amount", dataIndex: "amount", key: "amount" },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => el && <Tag color="green">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Name",
    dataIndex: "datum",
    key: "data",
    render: (props: any) => props && <Tag>{props?.name || "Noname"}</Tag>,
  },
]

export const sessionColumns: Column[] = [
  { title: "Ip Address", dataIndex: "ipAddress", key: "ipAddress" },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => el && <Tag color="green">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    render: (props: string) => props && <Tag>{props || "Noname"}</Tag>,
  },
]

export const itemsColumns: Column[] = [
  { title: "Code", dataIndex: "giftCode", key: "giftCode" },
  {
    title: "Purchased At",
    dataIndex: "date",
    key: "date",
    render: (el: Date) => el && <Tag color="red">{defaultFormat(el)}</Tag>,
  },
  { title: "Name", dataIndex: "name", key: "name" },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Item Provider Name",
    dataIndex: "itemProviderName",
    key: "itemProviderName",
    render: (el: string) => el || "Internal",
  },
]

export const raffleTicketColumns: Column[] = [
  {
    title: "Is For Premium Only?",
    dataIndex: "isPremium",
    key: "isPremium",
    render: (el: boolean) => (el ? "Yes" : "No"),
  },
  { title: "Price", dataIndex: "price", key: "price" },
  { title: "Raffle Name", dataIndex: "name", key: "name" },
  {
    title: "Purchased At",
    dataIndex: "purchasedAt",
    key: "purchasedAt",
    render: (el: Date) => el && <Tag color="red">{defaultFormat(el)}</Tag>,
  },
]
