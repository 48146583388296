import { FC, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Table } from "antd"
import { IAccountInventoryPage } from "../store/types"
import { getInventory } from "../api/accounts.call"

export const AccountInventory: FC = observer<FC>(() => {
  const { id } = useParams<{ id: string }>()
  const [page, setPage] = useState(1)
  const [pageData, setPageData] = useState<IAccountInventoryPage>()

  const loadPage = useCallback((page: number) => {
    if (!id) {
      return
    }
    setPageData(undefined)
    getInventory(id, page, 50).then(setPageData)
  }, [id])

  useEffect(() => {
    loadPage(page)
  }, [page, loadPage])

  return (
    <Table
      dataSource={pageData?.data}
      columns={[
        {
          title: "ID",
          dataIndex: "id",
        },
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Source",
          dataIndex: "source",
        },
        {
          title: "Code",
          dataIndex: ["marketplaceItem", "giftCode"],
          render: (code: string) => (
            code ? code : <span style={{ opacity: 0.5 }}>Empty</span>
          ),
        },
        {
          title: "Received At",
          dataIndex: "createdAt",
          render: (date: string) => date ? new Date(date).toLocaleString() : "Not received",
        },
        {
          title: "Claim Status",
          dataIndex: "claimStatus",
        },
      ]}
      rowKey="id"
      pagination={{ total: pageData?.total, pageSize: 50, onChange: p => setPage(p) }}
      loading={!pageData}
    />
  )
})
