import { computed, observable, transaction, makeObservable } from "mobx"

import { Tier } from "stores/primitives/tier"
import {
  BillingPeriod,
  AccountSubscription,
  PaymentProviderTypeEnum,
} from "domains/accounts/store/types"

import { Session } from "stores/primitives/session"
import { BaseStore } from "stores/base.store"
import {
  addBonusFunc,
  clearBalanceFunc,
  obfuscateAccount,
  freezeAccountFunc,
  unfreezeAccountFunc,
  getAccountDetailsById,
  givePremiumFunc,
  removePremiumFunc,
  verifyEmailFunc,
  changeCountryFunc,
} from "domains/accounts/api/accounts.call"
import { RootStore } from "stores/root.store"
import { Transaction } from "./types"

export class AccountDetails {
  constructor(
    public currentTier: Tier | null,
    public oldTier: Tier | null,
    public subscriptions: AccountSubscription[],
    public coinsPurchases: Transaction[],
    public premiumBonuses: Transaction[],
    public bonuses: Transaction[],
    public sessions: Session[],
    public items: {
      name: string;
      price: number;
      date: Date;
      giftCode: string;
      itemProviderName: string;
    }[],
    public raffleTickets: {
      isPremium: boolean;
      price: number;
      name: string;
      purchasedAt: Date;
    }[],
    public country: string,
    public email: string,
    public login: string,
    public nickname: string,
    public createdAt: Date,
    public updatedAt: Date,
    public balance: number,
    public earned: number,
    public spent: number,
    public earnedByGames: number,
    public referrals: { login: string; balance: number }[],
    public utmData: {
      term: string;
      medium: string;
      campaign: string;
      extra: string;
      content: string;
      source: string;
      partnerId: string;
    },
    public isClosed: boolean,
    public isFrozen: boolean,
    public raffleBonuses: Transaction[],
    public reasonOfBan: string,
    public accountFreeze: {
      accountId: string;
      createdAt: Date;
      updatedAt: Date;
      confirm: boolean;
    } | null,
  ) {}
}

export class AccountDetailsStore extends BaseStore {
  @observable
  private _account: AccountDetails = {} as AccountDetails
  @observable
  public isLoading: boolean = false

  public id: string

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getAccountDetails(id: string) {
    this.id = id

    this.resetFuncAfterSuccess()

    const accountDetails = await this.defaultFunction(getAccountDetailsById, id)

    transaction(() => {
      this.isLoading = false
      this._account = accountDetails
    })

    return accountDetails
  }

  public async freezeAccount(data: { reasonOfBan: string }) {
    await this.defaultFunction(freezeAccountFunc, this.id, data)
  }

  public async unfreezeAccount() {
    await this.defaultFunction(unfreezeAccountFunc, this.id)
  }

  public async givePremium(data: {
    subscriptionId: string;
    billingPeriod: BillingPeriod;
    startDate: string;
    paymentProviderType: PaymentProviderTypeEnum;
    billingPlanId: string;
  }) {
    await this.defaultFunction(givePremiumFunc, data, this.id)
  }

  public async removePremium(data: { accountSubscriptionId: string }) {
    await this.defaultFunction(removePremiumFunc, data)
  }

  public async verifyEmail() {
    await this.defaultFunction(verifyEmailFunc, this.id)
  }

  public async addBonus(data: { name: string; amount: number, type: number }) {
    await this.defaultFunction(addBonusFunc, data, this.id)
  }

  public async clearBalance() {
    await this.defaultFunction(clearBalanceFunc, this.id)
  }

  public async changeCountry(country: string) {
    await this.defaultFunction(changeCountryFunc, this.id, country)
  }

  public async closeAccount() {
    await this.defaultFunction(obfuscateAccount, this.id)
  }

  @computed
  get account(): AccountDetails {
    return this._account
  }
}
