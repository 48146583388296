import { Input, Select, Tag } from "antd"
import { CodesStore } from "domains/codesInventory/store/codes-store"
import { typeRewardArr } from "domains/promocodes/store/types"
import { RafflePrizes } from "domains/raffles/store/types"
import s from "../styles.module.css"

export const rafflesPrizesColumns = (
  codesStore: CodesStore,
  prizesNew: RafflePrizes[],
  updatePrizesData: any,
) => [
  {
    title: "Place",
    dataIndex: "place",
    key: "place",
    width: "90px",
    render: (_, row: any, id: number) => {
      return (
        <Input
          type="number"
          placeholder="Place"
          min={1}
          onChange={e => updatePrizesData(id, "place", e.target.value)}
          value={prizesNew[id]?.place}
          disabled
        />
      )
    },
  },
  {
    title: "Prize",
    dataIndex: "prize",
    key: "prize",
    width: "175px",
    render: (_, row: RafflePrizes, id: number) => {
      return (
        <Select
          style={{ width: "100%" }}
          placeholder="Prize Type"
          onChange={(e: any) => updatePrizesData(id, ["prizeType.type"], e)}
          value={prizesNew[id]["prizeType.type"]}
        >
          {typeRewardArr.map(item => (
            <Select.Option key={item} value={item}>{item}</Select.Option>
          ))}
        </Select>
      )
    },
  },

  {
    title: "Setup",
    dataIndex: "setup",
    key: "setup",
    render: (_, row: RafflePrizes, id: number) => {
      switch (prizesNew[id]["prizeType.type"]) {
      case "PREMIUM":
      case "PREMIUM_PLUS":
      case "PREMIUM_ELITE":
        return (
          <div>
            <Select
              placeholder="Period Type"
              className={s.title}
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.periodType"], e)}
              value={prizesNew[id]["prizeCondition.periodType"]}
            >
              {["HOURS", "DAYS"].map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
            <Input
              placeholder="Duration"
              className={s.titleWithoutMargin}
              type="number"
              min={0}
              max={750}
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.duration"], e.target.value)}
              value={prizesNew[id]["prizeCondition.duration"]}
            />
          </div>
        )
      case "BUFFS":
      case "RAFFLE_TICKET":
        return (
          <Input
            className={s.titleWithoutMargin}
            placeholder="Count"
            type="number"
            onChange={(e: any) => updatePrizesData(id, ["prizeType.countOfPrizes"], e.target.value)}
            value={prizesNew[id]["prizeType.countOfPrizes"]}
          />
        )

      case "BOOSTER":
        return (
          <div>
            <Select
              placeholder="Period Type"
              className={s.title}
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.periodType"], e)}
              value={prizesNew[id]["prizeCondition.periodType"]}
            >
              {["HOURS", "DAYS"].map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
            <Input
              placeholder="Duration"
              className={s.title}
              type="number"
              min={0}
              max={750}
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.duration"], e.target.value)}
              value={prizesNew[id]["prizeCondition.duration"]}
            />
            <Input
              className={s.titleWithoutMargin}
              placeholder="Percentage"
              type="number"
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.percentage"], e.target.value)}
              value={prizesNew[id]["prizeCondition.percentage"]}
            />
          </div>
        )

      case "MARKETPLACE_DISCOUNT":
        return (
          <Input
            className={s.titleWithoutMargin}
            placeholder="Percentage"
            max={100}
            type="number"
            onChange={(e: any) => updatePrizesData(id, ["prizeCondition.percentage"], e.target.value)}
            value={prizesNew[id]["prizeCondition.percentage"]}
          />
        )

      case "CODE_STORAGE":
        return (
          <div>
            <Select
              placeholder="Select Code Item"
              style={{ width: "100%" }}
              dropdownStyle={{ minWidth: 300 }}
              value={prizesNew.find(prize => prize.prizeType?.name === row.prizeType?.name)?.prizeType?.name}
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.codeInfoId"], e)}
            >
              {codesStore?.codesInventory?.map(item => (
                <Select.Option key={item.id} value={item?.id}>
                  <div className={s.selectItemWrapper}>
                    <span>{item?.name}</span>
                    <span className={s.remaining}>{`Free: ${item.numberOfFreeCodes}`}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
        )
      default:
        return (
          <div>
            <Input
              className={s.title}
              placeholder="Title"
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.title"], e.target.value)}
              value={prizesNew[id]["prizeCondition.title"]}
            />
            <Input
              className={s.title}
              placeholder="Enter code"
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.code"], e.target.value)}
              value={prizesNew[id]["prizeCondition.code"]}
            />
            <Select
              className={s.titleWithoutMargin}
              placeholder="Enter code"
              onChange={(e: any) => updatePrizesData(id, ["prizeCondition.code"], e)}
              value={prizesNew[id]["prizeCondition.code"]}
            />
          </div>
        )
      }
    },
  },
  {
    title: "Status",
    dataIndex: ["prizeType", "status"],
    key: "prizeType.status",
  },
  {
    title: "Asignee",
    dataIndex: "asignee",
    key: "asignee",
    render: (data: any, row, id) => prizesNew && prizesNew[id]?.raffleWinner ? (
      <Tag color="green">{prizesNew[id]?.raffleWinner?.accountRaffle?.email}</Tag>
    ) : (
      <Tag color="red">Not assigned</Tag>
    ),
  },
]
