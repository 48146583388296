export class Challenge {
  createdAt: string
  gameId: number
  id: string
  levels: Level[]
  template: string
  title: string
  type: string
  updatedAt: string
  transitionFunctions: Record<string, number | string | boolean>[]
  deletedAt: string | null
}
export type Level = {
  createdAt: string;
  goalId: string;
  goalValue: number;
  id: string;
  level: string;
  reward: number;
  updatedAt: string;
  title: string;
  type: string;
};

export type CreateLevelDTO = {
  goalValue: number;
  reward: number;
  level: 1 | 2 | 3;
};

export class CreateChallengeDTO {
  template: string
  transitionFunctions: Record<string, number | string | boolean>[]
  levels: CreateLevelDTO[]
  type: "ACCUMULATIVE" | "NON_ACCUMULATIVE"
  gameId: number
}

export class UpdatedChallenge {
  levelId: string
  goalValue: number
  reward: number
}

export enum StatusEnum {
  ACTIVATE = "ACTIVATE",
  DEACTIVATE = "DEACTIVATE",
}

export type TemplateChallenge = {
  template: string;
  transitionFunctions: { type: string; meta?: { path: string; value: string } };
};
