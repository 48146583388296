import { FC, useState, useEffect } from "react"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { Modal, Table, Input } from "antd"
import { Column } from "abstract/types/column.type"
import { giftoinColumns } from "./columns"
import {
  DeleteIconButton,
  EditIconButton,
  showDeleteConfirm,
  UploadComponent,
  CreateNewElement,
} from "components"
import { PromocodeFilter } from "domains/promocodes/components/ItemTypes/PromocodesList/promocode.filter"
import { GitftoinEditModal } from "./GitftoinEditModal"

export const GiftoinList: FC = observer(() => {
  const { dataStore: { giftoinStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  const [isVisibleKeyInfo, setKeyInfoVisibility] = useState<boolean>(false)
  const [isVisibleFilter, setIsVisibleFilter] = useState<boolean>(false)

  const [isVisibleImage, setIsVisibleImage] = useState<boolean>(false)
  const [image, setImage] = useState("")

  useEffect(() => {
    giftoinStore.getData()
  }, [giftoinStore])

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      giftoinStore.pagination = {
        page,
        limit: pageSize || giftoinStore.pagination.limit,
      }
    },
    total: giftoinStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: giftoinStore.pagination.page,
  }

  const onClose = () => {
    giftoinStore.clearSelectedElement()
    setEditCreateVisibility(null)
    giftoinStore.isLoading = false
  }

  const columns: Column[] = [
    ...giftoinColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: any) => (
        <div>
          <EditIconButton
            onClick={() => {
              giftoinStore.selectedElement = row
              setEditCreateVisibility("edit")
            }}
          />
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(`promo code: ${row.name}`, undefined, async() => {
                giftoinStore.funcAfterSuccess = async() => {
                  await giftoinStore.getData()
                }
                giftoinStore.deleteById(row.id)
              })
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <div style={{ paddingTop: "30px" }}>
      <CreateNewElement
        onClick={() => {
          giftoinStore.clearSelectedElement()
          setEditCreateVisibility("create")
        }}
        valueText="giftoin campaign"
      />

      <PromocodeFilter isVisibleFilter={isVisibleFilter} setIsVisibleFilter={setIsVisibleFilter} />
      <Table
        dataSource={[...giftoinStore.itemTypes]}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={giftoinStore.isLoading}
        scroll={{ x: true, y: "70vh" }}
        onRow={(record: any) => ({
          onClick: () => {
            giftoinStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit Giftoin Campaign" : "Create Giftoin Campaign"}
          width="900px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <GitftoinEditModal editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
      <Modal
        visible={isVisibleKeyInfo}
        footer={null}
        onCancel={() => {
          setKeyInfoVisibility(false)
          giftoinStore.getData()
        }}
        width="85%"
      />
      <Modal
        visible={isVisibleImage}
        footer={null}
        onCancel={() => {
          setIsVisibleImage(false)
          setImage("")
        }}
      >
        <UploadComponent image={image} setImage={setImage} placeholder=" " />
        <Input addonBefore="URL:" value={image} style={{ marginTop: "1rem" }} />
      </Modal>
    </div>
  )
})
