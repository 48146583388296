import { useEffect, useState } from "react"

const baseUrl = process.env.REACT_APP_BASE_URL || ""
const version = process.env.REACT_APP_VERSION || ""

console.log(process.env)
const config = { baseUrl, version }
export function useConfigJson(): { baseUrl: string, version: string } {
  const [configData] = useState(config)

  useEffect(() => {
    console.log(configData)
  })

  return configData
}
