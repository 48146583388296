import React, { FC, useEffect, useState } from "react"
import { Button, Form, Input, Modal, Table, TablePaginationConfig, Upload } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { codesUploadColumns } from "./codes-columns"
import { DeleteIconButton, showDeleteConfirm, SubmitCancelButtons } from "components"
import { UploadOutlined } from "@ant-design/icons"
import { ValidateErrorEntity } from "rc-field-form/lib/interface"

export const CodeUpload: FC<{
  closeModal: () => void;
}> = observer(() => {
  const { dataStore: { subCodesStore, codesStore } } = useStore()

  const [uploadOneCode, setuploadOneCode] = useState(false)
  const [uploadMultiple, setuploadMultiple] = useState(false)

  const [fileToUpload, setFileToUpload] = useState<FormData>()
  const [filelist, setfilelist] = useState<any>([])

  useEffect(() => {
    if (codesStore?.selectedElement?.id) {
      subCodesStore.getSubCodes(codesStore.selectedElement.id)
    }
  }, [codesStore, subCodesStore])

  const columns = [
    ...codesUploadColumns,
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, row: any) => (
        <div style={{ display: "flex", gap: 10 }}>
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(row.code, undefined, async() => {
                subCodesStore.funcAfterSuccess = async() => await subCodesStore.getSubCodes(codesStore?.selectedElement?.id || "")
                await subCodesStore.deleteCode(row.id)
              })
            }}
          />
        </div>
      ),
    },
  ]
  const onFinishHandler = async(e: any) => {
    if (codesStore?.selectedElement?.id) {
      subCodesStore.funcAfterSuccess = () => {
        setuploadOneCode(false)
        setTimeout(() => {
          subCodesStore.getSubCodes(codesStore?.selectedElement?.id || "")
        }, 2000)
      }

      subCodesStore.createSubCodes({
        codeInfoId: codesStore?.selectedElement?.id,
        codes: [e.code],
      })
    }
  }

  const onCsvSubmit = async() => {
    await subCodesStore.createSubCodesCsv(
      codesStore?.selectedElement?.id as string,
      fileToUpload as FormData,
    )
    subCodesStore.getSubCodes(codesStore?.selectedElement?.id || "")
    console.log(fileToUpload, "fileToUpload")
    setFileToUpload(undefined)
    setfilelist([])
    setuploadMultiple(false)
  }

  const onFinishFailedHandler = (e: ValidateErrorEntity<any>) => {
    console.log(e)
  }

  const paginationOptionsCodes: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      subCodesStore.pagination = {
        page,
        limit: pageSize || subCodesStore.pagination.limit,
      }
    },
    total: subCodesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 100,
  }

  return (
    <>
      <div style={{ display: "flex", gap: 10, paddingTop: "15px" }}>
        <Button
          type="primary"
          onClick={() => setuploadOneCode(true)}
          style={{ marginBottom: "1rem" }}
        >
          Upload a new code
        </Button>
        <Button
          type="primary"
          onClick={() => setuploadMultiple(true)}
          style={{ marginBottom: "1rem" }}
        >
          Upload via CSV
        </Button>
      </div>
      <Table
        dataSource={subCodesStore.subCodesInventory}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptionsCodes}
        loading={subCodesStore.isLoading}
        id="raffleTable"
      />
      {uploadMultiple && (
        <Modal
          visible={uploadMultiple}
          onCancel={() => setuploadMultiple(false)}
          width={720}
          bodyStyle={{ paddingBottom: 20, paddingTop: 50 }}
          footer={null}
        >
          <Upload
            name="file"
            beforeUpload={file => {
              const formData = new FormData()
              formData.append("file", file)

              setFileToUpload(formData)
              setfilelist([file])

              return false
            }}
            maxCount={1}
            fileList={filelist}
            onRemove={() => {
              setfilelist([])
              setFileToUpload(undefined)
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Add file</Button>
          </Upload>
          <Button disabled={!fileToUpload} onClick={onCsvSubmit}>

            Upload Selected File?
          </Button>
        </Modal>
      )}
      {uploadOneCode && (
        <Modal
          visible={uploadOneCode}
          onCancel={() => setuploadOneCode(false)}
          width={720}
          bodyStyle={{ paddingBottom: 20, paddingTop: 50 }}
          footer={null}
        >
          <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} id="createBanner">
            <Form.Item label="Code" name="code">
              <Input />
            </Form.Item>

            <SubmitCancelButtons
              onClose={() => setuploadOneCode(false)}
              isLoading={subCodesStore.isLoading}
            />
          </Form>
        </Modal>
      )}
    </>
  )
})
