import { message } from "antd"
import { RcFile } from "antd/lib/upload/interface"

export const checkIsUploadableImage = (file: RcFile, maxSize?: number) => {
  const isSVG = file.type.includes("image/svg")
  const isJPG = file.type === "image/jpeg"
  const isPng = file.type === "image/png"
  const isVideo = file.type.includes("video")

  const maxImageSize = maxSize || 2

  if (!isJPG && !isPng && !isSVG && !isVideo) {
    message.error("You can only upload JPG or PNG  or SVG file!")
  }

  const isSizeValid = file.size / 1024 / 1024 < maxImageSize
  if (!isSizeValid) {
    message.error(`Image must be smaller than ${maxImageSize}MB!`)
  }

  return (isJPG || isPng || isSVG || isVideo) && isSizeValid
}
