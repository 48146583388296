import { FC, useState, useEffect } from "react"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { Button, Modal, Table, Input } from "antd"
import { TableOutlined } from "@ant-design/icons"

import { ReactComponent as Filter } from "assets/filter.svg"

import { Column } from "abstract/types/column.type"
import { parentPromocodeColumns } from "./columns"

import { ParentCodeType } from "domains/promocodes/store/types"
import {
  DeleteIconButton,
  EditIconButton,
  showDeleteConfirm,
  UploadComponent,
  CreateNewElement,
} from "components"

import { PromocodeFilter } from "domains/promocodes/components/ItemTypes/PromocodesList/promocode.filter"
import { ChildList, PromocodeEdit } from "domains/promocodes/components/ItemTypes"

import Styles from "./styles.module.css"

export const PromocodesList: FC = observer(() => {
  const { dataStore: { parentPromocodesStore, bannersStore, childPromocodesStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  const [isVisibleKeyInfo, setKeyInfoVisibility] = useState<boolean>(false)
  const [isVisibleFilter, setIsVisibleFilter] = useState<boolean>(false)

  const [isVisibleImage, setIsVisibleImage] = useState<boolean>(false)
  const [image, setImage] = useState("")
  const [isPrivate, setPrivate] = useState<boolean>(false)

  useEffect(() => {
    parentPromocodesStore.getData()
  }, [parentPromocodesStore])

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      parentPromocodesStore.pagination = {
        page,
        limit: pageSize || parentPromocodesStore.pagination.limit,
      }
    },
    total: parentPromocodesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: parentPromocodesStore.pagination.page,
  }

  const onClose = () => {
    parentPromocodesStore.clearSelectedElement()
    bannersStore.clearSelectedElement()
    setEditCreateVisibility(null)
    parentPromocodesStore.isLoading = false
  }

  const columns: Column[] = [
    ...parentPromocodeColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: ParentCodeType) => (
        <div className={Styles.actions}>
          <Button
            style={{ background: "orange", borderWidth: 0 }}
            type="primary"
            title="View codes"
            onClick={e => {
              e.stopPropagation()
              childPromocodesStore.setFilter({ parentPromoCodeId: row.id })
              setPrivate(row.promoCodeType === "PERSONALIZED")
              parentPromocodesStore.selectedElement = row
              setKeyInfoVisibility(true)
            }}
            icon={<TableOutlined />}
          />
          <EditIconButton
            onClick={() => {
              parentPromocodesStore.selectedElement = row
              setEditCreateVisibility("edit")
            }}
          />
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(`promo code: ${row.name}`, undefined, async() => {
                parentPromocodesStore.funcAfterSuccess = async() => {
                  await parentPromocodesStore.getData()
                }
                parentPromocodesStore.deleteById(row.id)
              })
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <CreateNewElement
        onClick={() => {
          parentPromocodesStore.clearSelectedElement()
          setEditCreateVisibility("create")
        }}
        valueText="promo code"
      />

      <div className={Styles.containerSearch}>
        <Button
          icon={<Filter />}
          color="white"
          type="default"
          size="middle"
          onClick={() => setIsVisibleFilter(!isVisibleFilter)}
        />
        {/* <Search
          placeholder="Write some name for search ..."
          allowClear
          loading={loadingSearch}
          value={valSearch}
          onChange={(e) => {
            const val = e.target.value;
            setValSearch(val);
            setLoadingSearch(true);
            debounce(val);
          }}
          onSearch={(value: string) => {
            parentPromocodesStore.setFilter({ name: value.toUpperCase().trim() });
          }}
        /> */}
      </div>
      <PromocodeFilter isVisibleFilter={isVisibleFilter} setIsVisibleFilter={setIsVisibleFilter} />
      <Table
        dataSource={[...parentPromocodesStore.itemTypes]}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={parentPromocodesStore.isLoading}
        scroll={{ x: true, y: "70vh" }}
        onRow={(record: ParentCodeType) => ({
          onClick: () => {
            parentPromocodesStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit Promo Code" : "Create Promo Code"}
          width="900px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <PromocodeEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
      <Modal
        visible={isVisibleKeyInfo}
        footer={null}
        onCancel={() => {
          setKeyInfoVisibility(false)
          parentPromocodesStore.getData()
        }}
        width="85%"
      >
        <ChildList itemTypeId={parentPromocodesStore?.selectedElement?.id} isPrivate={isPrivate} />
      </Modal>
      <Modal
        visible={isVisibleImage}
        footer={null}
        onCancel={() => {
          setIsVisibleImage(false)
          setImage("")
        }}
      >
        <UploadComponent image={image} setImage={setImage} placeholder=" " />
        <Input addonBefore="URL:" value={image} style={{ marginTop: "1rem" }} />
      </Modal>
    </>
  )
})
