import { EntityToUpdate } from "abstract/entity-to-update"
import { apiCall } from "api-calls/api-call"
import { UtmTier } from "../store"

export type TierResponse = {
  success: boolean;
  message: string;
  statusCode: number;
}

export const getUtmTiers = async(
  filter: string,
  page: number,
  limit: number,
): Promise<{
  data: UtmTier[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string } = {
    page,
    limit,
  }

  if (filter) {
    query.filter = filter
  }

  return apiCall({
    method: "GET",
    path: "utm-tiers",
    query,
    withAuth: true,
  })
}

export const updateUtmTier = async(
  id: string,
  data: EntityToUpdate<UtmTier>,
): Promise<TierResponse> => {
  return apiCall({
    method: "PUT",
    path: `utm-tiers/${id}`,
    withAuth: true,
    body: data,
  })
}

export const createUtmTier = async(data: EntityToUpdate<UtmTier>): Promise<TierResponse> => {
  return apiCall({
    method: "POST",
    withAuth: true,
    body: data,
    path: "utm-tiers",
  })
}

export const deleteUtmTier = async(id: string): Promise<TierResponse> => {
  return apiCall({
    method: "DELETE",
    path: `utm-tiers/${id}`,
    withAuth: true,
  })
}
