import { Tag } from "antd"
import { defaultFormat } from "utils/date"

export const modesColumns = [
  // {
  //   title: 'Game mode Id',
  //   dataIndex: 'id',
  //   key: 'id',
  // },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Pretty name",
    dataIndex: "pretyName",
    key: "pretyName",
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => <Tag color="green">{defaultFormat(el)}</Tag>,
  },
]

export const typesColumns = [
  // {
  //   title: 'Game type Id',
  //   dataIndex: 'id',
  //   key: 'id',
  // },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Pretty name",
    dataIndex: "pretyName",
    key: "pretyName",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => <Tag color="green">{defaultFormat(el)}</Tag>,
  },
]
