import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Modal, Table } from "antd"

import { useStore } from "stores/utils/use-store"
import { notifications } from "notifications"
import { showDeleteConfirm, DeleteIconButton, EditIconButton, CreateNewElement } from "components"
import { marketplaceRegionsColumns } from "./columns"
import { MarketplaceCategory } from "domains/marketplace/store/types"
import { CategoryEdit } from "domains/marketplace/components/Categories"
import Styles from "./styles.module.css"

export const CategoriesList: FC = observer(() => {
  const { dataStore: { marketplaceCategoriesStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  useEffect(() => {
    marketplaceCategoriesStore.getData()
  }, [marketplaceCategoriesStore])

  const onClose = () => {
    marketplaceCategoriesStore.clearSelectedElement()
    setEditCreateVisibility(null)
  }

  const handleDeleteCategory = (e, row: MarketplaceCategory) => {
    const countOfTypes = row._count.itemTypes

    e.stopPropagation()

    if (countOfTypes === 0) {
      showDeleteConfirm(`Merch Category: ${row.name}`, undefined, async() => {
        marketplaceCategoriesStore.funcAfterSuccess = async() => await marketplaceCategoriesStore.getData()

        marketplaceCategoriesStore.delete(row.id)
      })
    } else {
      notifications.info(`You cannot delete this category cause there are ${countOfTypes} linked to this category.
      Change the items’ categories to other ones to delete this category.`)
    }
  }

  const columns = [
    ...marketplaceRegionsColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: MarketplaceCategory) => (
        <div className={Styles.actions}>
          <DeleteIconButton onClick={e => handleDeleteCategory(e, row)} />
          <EditIconButton
            onClick={e => {
              e.stopPropagation()
              setEditCreateVisibility("edit")
              marketplaceCategoriesStore.selectedElement = row
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="category" />
      <Table
        dataSource={marketplaceCategoriesStore.categories}
        columns={columns as any}
        rowKey="id"
        onRow={(record: MarketplaceCategory) => ({
          onClick: () => {
            marketplaceCategoriesStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit category" : "Create category"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <CategoryEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
