import { apiCall } from "api-calls/api-call"
import { AccountTransactionsFilter, AccountTransactionsType } from "../store/account-transactions.store"

export const getAccountTransaction = async(
  accountId: string,
  filter: AccountTransactionsFilter,
  limit: number,
): Promise<{
  result: AccountTransactionsType[];
  expectedLength: number;
  realLength: number;
  lastDate: string;
}> => {
  const query: {
    lastDate?: string;
    type?: number[];
    limit?: number;
    gameId?: number[];
    createdAtOrder?: string;
    startDate?: string;
    endDate?: string;
  } = { limit }
  if (filter && filter.type) query.type = filter.type
  if (filter && filter.lastDate) query.lastDate = filter.lastDate
  if (filter && filter.gameId) query.gameId = filter.gameId
  if (filter && filter.createdAtOrder) query.createdAtOrder = filter.createdAtOrder
  if (filter && filter.startDate) query.startDate = filter.startDate
  if (filter && filter.endDate) query.endDate = filter.endDate

  return apiCall({
    method: "GET",
    path: `accounts/buff-transactions/${accountId}`,
    query,
  })
}

export const getTransactionsTypes = async() => {
  return apiCall({
    method: "GET",
    path: "transactions/transaction-types",
  })
}

export const deleteTransactionsById = async(id: string) => {
  return apiCall({
    method: "DELETE",
    path: `transactions/${id}`,
  })
}

export const createTransactions = async(data: any) => {
  return apiCall({
    method: "POST",
    path: "transactions",
    body: data,
  })
}
