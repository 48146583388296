import { useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { Button, Form, InputNumber, Typography } from "antd"
import { observer } from "mobx-react"
import { notifications } from "notifications"
import { VariousConfigType } from "domains/managingJson/store/types"
const { Title } = Typography

type FormValuesType = {
  codStartgameAutoclose: number;
  codLaunchgameAutoclose: number;
  codEndgameAutoclose: number;
  codGameSummaryAutoclose: number;
};

export const VariousConfig = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()
  const [data, setData] = useState<VariousConfigType | undefined>()
  const [form] = Form.useForm<FormValuesType>()

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const mobileConfigData = managingJsonStore?.itemTypes?.find(
      item => item.type === "variousConfig",
    )
    if (mobileConfigData) {
      const data = mobileConfigData.data as VariousConfigType
      setData(data)
      form.setFieldsValue(data)
    }
  }, [managingJsonStore.itemTypes])

  const onFinishHandler = async() => {
    const { codStartgameAutoclose, codLaunchgameAutoclose, codEndgameAutoclose, codGameSummaryAutoclose } = form.getFieldsValue()

    managingJsonStore.funcAfterSuccess = async() => {
      notifications.success("Saved successfully")
      await managingJsonStore.getData()
    }

    const config = {
      name: "variousConfig",
      type: "variousConfig",
      data: { codStartgameAutoclose, codLaunchgameAutoclose, codEndgameAutoclose, codGameSummaryAutoclose },
    } as const

    data ? await managingJsonStore.update(config) : await managingJsonStore.create(config)
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <Title level={2}>Various Config</Title>
      <Form.Item
        name="codStartgameAutoclose"
        label="COD games startgame window autoclose (seconds)"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="codLaunchgameAutoclose"
        label="COD games launchgame window autoclose (seconds)"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="codEndgameAutoclose"
        label="COD games endgame window autoclose (seconds)"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="codGameSummaryAutoclose"
        label="COD games gamesummary window autoclose (seconds)"
      >
        <InputNumber />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  )
})
