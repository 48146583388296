import { Tabs } from "antd"
import * as React from "react"
import { MarketplaceList } from "../ItemTypes"
import { InternalMarketplaceList } from "../InternalMarket"
import { MarketplaceRegionsList } from "../Regions"
import { CategoriesList } from "../Categories"

export const MarketplaceSettings: React.FunctionComponent = () => {
  return (
    <Tabs>
      <Tabs.TabPane tab="Item Types" key="1">
        <MarketplaceList />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Internal market" key="2">
        <InternalMarketplaceList />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Regions" key="3">
        <MarketplaceRegionsList />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Categories" key="4">
        <CategoriesList />
      </Tabs.TabPane>
    </Tabs>
  )
}
