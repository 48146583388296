import {
  ItemType,
  GetItemsFilter,
  ItemToUpdate,
  ItemCategory,
  ItemProvider,
} from "domains/marketplace/store/types"
import { apiCall } from "api-calls/api-call"

export const getItems = async(
  input: GetItemsFilter,
  page: number,
  limit: number,
): Promise<{ total: number; data: ItemType[] }> => {
  const query: { page: number; limit: number } & GetItemsFilter = {
    page,
    limit,
    ...input,
  }

  return apiCall({
    method: "GET",
    path: "marketplace",
    query,
  })
}

export const createItem = async(input: ItemToUpdate): Promise<void> => {
  return apiCall({
    method: "POST",
    path: "marketplace",
    body: input,
  })
}

export const updateItem = async(id: string, input: ItemToUpdate): Promise<void> => {
  return apiCall({
    method: "PUT",
    path: `marketplace/${id}`,
    body: input,
  })
}
export const deleteItem = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `marketplace/${id}`,
  })
}

export const getItemInfo = async(
  iprovider: string,
  itemId: string,
): Promise<any> => {
  const query = { iprovider, itemId }

  return apiCall({
    method: "GET",
    path: "marketplace/item-providers/info",
    query,
  })
}

export const getItemCategories = async(): Promise<ItemCategory[]> => {
  return apiCall({
    method: "GET",
    path: "marketplace-categories",
  })
}

export const getItemProviders = async(): Promise<ItemProvider[]> => {
  return apiCall({
    method: "GET",
    path: "marketplace/item-providers",
  })
}
