import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, transaction, makeObservable } from "mobx"
import { EntityToUpdate } from "abstract/entity-to-update"
import { RootStore } from "stores/root.store"
import { DistributionType, FullRaffle, HandleType, LifeCycleStatus, RafflePrizes, UpdateRaffle } from "./types"
import {
  getRaffles,
  createRaffle,
  updateRaffle,
  deleteRaffle,
  createPrize,
  updatePrize,
  deletePrize,
  createWinner,
  updateWinner,
  deleteWinner,
  getPrizes,
  rafflePrizeSubmit,
} from "../api"
import { RaffleWinner } from "./types"

export type GetItemsFilter = {
  title?: string | null;
  handleType?: HandleType | null;
  distributionType?: DistributionType | null;
  lifeCycleStatus?: LifeCycleStatus | null;
};

export class RafflesStore extends PaginationStore<FullRaffle, GetItemsFilter> {
  @observable
  private _raffles: FullRaffle[] = []
  @observable
  private _selectedElement: FullRaffle | any
  @observable
  private _winner: RaffleWinner
  @observable
  private _prizes: RafflePrizes[]

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  // RAFFLE DATA
  async getData(): Promise<FullRaffle[]> {
    this.resetFuncAfterSuccess()

    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getRaffles, page, limit, {
      title: this.filter?.title || null,
      handleType: this.filter?.handleType || null,
      lifeCycleStatus: this.filter?.lifeCycleStatus || null,
      distributionType: this.filter?.distributionType || null,
    })

    this._raffles = res.data
    this.total = res.total

    return res.data
  }

  async createRaffle(data: FullRaffle) {
    await this.defaultFunction(createRaffle, data)
  }

  async updateRaffle(data: UpdateRaffle) {
    await this.defaultFunction(updateRaffle, this._selectedElement.id, data)
  }

  async deleteRaffle(id: string) {
    await this.defaultFunction(deleteRaffle, id)
  }

  // PRIZE
  async getPrizes() {
    const res = await this.defaultFunction(getPrizes, this.selectedElement?.id || "")

    transaction(() => {
      this._prizes = res.data
    })
  }

  async submitProcessing(id: string) {
    await this.defaultFunction(rafflePrizeSubmit, id)
  }

  async createPrize(body: RafflePrizes) {
    await this.defaultFunction(createPrize, {
      ...body,
      raffleId: this.selectedElement?.id || "",
    })
  }

  async updatePrize(id: string, body: RafflePrizes) {
    await this.defaultFunction(updatePrize, id, body)
  }
  async deletePrize(prizeId: string) {
    await this.defaultFunction(deletePrize, prizeId)
  }

  async updateWinner(data: EntityToUpdate<RaffleWinner>, winnerId: string) {
    await this.defaultFunction(updateWinner, winnerId, data)
  }

  async createWinner(data: EntityToUpdate<RaffleWinner>) {
    await this.defaultFunction(createWinner, data)
  }

  async disableWinner(winnerId: string) {
    await this.defaultFunction(deleteWinner, winnerId)
  }

  @computed
  get winner(): RaffleWinner {
    return this._winner
  }
  set winner(value: RaffleWinner) {
    this._winner = value
  }

  @computed
  get prizes(): RafflePrizes[] {
    return this._prizes
  }
  set prizes(value: RafflePrizes[]) {
    this._prizes = value
  }

  @computed
  get raffles(): FullRaffle[] {
    return this._raffles
  }
  set raffles(value: FullRaffle[]) {
    this._raffles = value
  }

  @computed
  get selectedElement(): FullRaffle {
    return this._selectedElement
  }
  set selectedElement(value: FullRaffle) {
    this._selectedElement = value
  }
}
