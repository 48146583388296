import { IReactionDisposer, observable, reaction, makeObservable } from "mobx"
import { isClient } from "utils/is-client"
import { Events } from "abstract/events"

export class AuthStore {
  @observable
  public token?: string

  private readonly activateAutosave = (): IReactionDisposer => reaction(
    () => this.token,
    () => {
      if (this.token) localStorage.setItem("token", this.token)
      console.log("activate autosave done")
    },
  )

  constructor() {
    makeObservable(this)
    if (!isClient) return

    this.activateAutosave()

    window.addEventListener(Events.JWT_EXPIRED, () => {
      this.token = undefined
    })
    this.token = localStorage.getItem("token") || undefined
  }
}
