import { Tabs } from "antd"
import * as React from "react"
import { AffiliateTiersList } from "../TiersList"
import { UtmBonusesList } from "../BonusesList"

export const UtmSettings: React.FunctionComponent = () => {
  return (
    <Tabs>
      <Tabs.TabPane tab="Utm bonuses" key="1">
        <UtmBonusesList />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Utm affiliate tiers" key="2">
        <AffiliateTiersList />
      </Tabs.TabPane>
    </Tabs>
  )
}
