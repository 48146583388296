import React, { FC, useEffect, useState } from "react"

import { Button, Divider, Form } from "antd"
import { ContestConfigOnboardingScreenData } from "domains/managingJson/store/types"
import { FormElement, UploadComponent } from "components"
import { ConsentRow, UserConsentEditor } from "components/userConsentEditor"
import Title from "antd/lib/typography/Title"

export const Onboarding: FC<{
  id: number;
  data: ContestConfigOnboardingScreenData;
  form: any;
  deleteFunc: () => void;
}> = ({ id, data, deleteFunc, form }) => {
  const [image, setImage] = useState(data.image)
  const [consentRows, setConsentRows] = useState<ConsentRow[] | undefined>(data.consentRows)

  useEffect(() => {
    if (data) {
      setImage(data.image)
    }
  }, [data])

  useEffect(() => {
    const obj = {}
    obj[`onboardingImage${id}`] = image
    obj[`onboardingConsentRows${id}`] = consentRows

    form.setFieldsValue({ ...obj })
  }, [form, id, image, consentRows])

  return (
    <div>
      <Title level={2}>Onboarding step {id}</Title>
      <FormElement
        type="input"
        label={`Onboarding title ${id}`}
        name={`onboardingTitle${id}`}
        initialValue={data.title}
        required
      />
      <FormElement
        type="text"
        label={`Onboarding text ${id}`}
        name={`onboardingText${id}`}
        initialValue={data.text}
        required
      />

      <Form.Item name={`onboardingImage${id}`} initialValue={image}>
        <UploadComponent
          image={image}
          setImage={setImage}
          placeholder={`Onboarding Image ${id}`}
          title={`Onboarding Image ${id}`}
          required
        />
      </Form.Item>

      <Divider />
      <Title level={4}>Consent Management</Title>
      <Form.Item name={`onboardingConsentRows${id}`} initialValue={consentRows}>
        <UserConsentEditor
          rows={consentRows ?? []}
          onChange={value => setConsentRows(value)}
          withCustomId
        />
      </Form.Item>
      <Divider />

      <Button
        style={{ display: "flex", margin: "auto", marginBottom: "14px" }}
        onClick={deleteFunc}
      >
        Delete Onboarding {id}
      </Button>

      <Divider />
    </div>
  )
}
