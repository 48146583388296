import React from "react"

import { Form } from "antd"
import { observer } from "mobx-react"
import { FC } from "react"
import { useStore } from "stores/utils/use-store"
import { Game } from "../../store/types"
import { SubmitCancelButtons } from "components"

export const GameDelete: FC<{ onClose: () => void }> = observer(({ onClose }) => {
  const {
    dataStore: {
      gamesStore,
      gamesStore: { game: { id } },
    },
  } = useStore()

  const onFinishHandler = async(e: Game) => {

    await gamesStore.deleteGame(id)
    onClose()
    await gamesStore.getData()
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler}>
      <SubmitCancelButtons onClose={onClose} isLoading={gamesStore.isLoading} />
    </Form>
  )
})
