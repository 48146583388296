import React, { FC } from "react"

import Styles from "../styles.module.css"

import { UploadComponent } from "components"
import { Divider } from "antd"

export const UploadImages: FC<{
  topBarLogoIMG: string;
  settopBarLogo: (data) => void;

  mainLogoIMG: string;
  setmainLogo: (data) => void;

  settingsLogoIMG: string;
  setsettingsLogo: (data) => void;

  advImageIMG: string;
  setadvImage: (data) => void;

  contestImageIMG: string;
  setcontestImage: (data) => void;

  endgameSuccessImageIMG: string;
  setendgameSuccessImage: (data) => void;

  endgameFraudImageIMG: string;
  setendgameFraudImage: (data) => void;

  endgameFailImageIMG: string;
  setendgameFailImage: (data) => void;

  gameBackgroundImageIMG: string;
  setGameBackgroundImage: (data) => void;

  leaderboardBanner: string;
  setleaderboardBanner: (data) => void;

  leaderboardTopBanner: string;
  setleaderboardTopBanner: (data) => void;

  leaderboardAdReplacementBanner: string;
  setleaderboardAdReplacementBanner: (data) => void;

  scheduleSidebarBanner: string;
  setScheduleSidebarBanner: (data) => void;
}> = ({
  topBarLogoIMG,
  settopBarLogo,
  mainLogoIMG,
  setmainLogo,
  settingsLogoIMG,
  setsettingsLogo,
  advImageIMG,
  setadvImage,
  contestImageIMG,
  setcontestImage,
  endgameSuccessImageIMG,
  setendgameSuccessImage,
  endgameFraudImageIMG,
  setendgameFraudImage,
  endgameFailImageIMG,
  setendgameFailImage,
  gameBackgroundImageIMG,
  setGameBackgroundImage,
  leaderboardBanner,
  setleaderboardBanner,
  leaderboardTopBanner,
  setleaderboardTopBanner,
  leaderboardAdReplacementBanner,
  setleaderboardAdReplacementBanner,
  scheduleSidebarBanner,
  setScheduleSidebarBanner,
}) => {
  return (
    <>
      <Divider>Main images</Divider>

      <div className={Styles.containerUploadImages}>
        {(topBarLogoIMG.length === 0
            || mainLogoIMG.length === 0
            || settingsLogoIMG.length === 0
            || advImageIMG.length === 0) && (
          <div className={Styles.redText}>Select all main images </div>
        )}
        <UploadComponent
          image={topBarLogoIMG}
          setImage={settopBarLogo}
          placeholder="TopBar Logo"
          title="TopBar Logo"
          required
        />
        <UploadComponent
          image={mainLogoIMG}
          setImage={setmainLogo}
          placeholder="Main Logo"
          title="Main Logo"
          required
        />

        <UploadComponent
          image={settingsLogoIMG}
          setImage={setsettingsLogo}
          placeholder="Settings Logo"
          title="Settings Logo"
          required
        />
        <UploadComponent
          image={advImageIMG}
          setImage={setadvImage}
          placeholder="Adv second windows image"
          title="Adv second windows image"
          required
        />
        <Divider>Start / Endgame Window Images</Divider>
        <div className={Styles.containerUploadImages}>
          {(contestImageIMG.length === 0
              || endgameSuccessImageIMG.length === 0
              || endgameFraudImageIMG.length === 0
              || endgameFailImageIMG.length === 0) && (
            <div className={Styles.redText}>Select all images </div>
          )}

          <UploadComponent
            image={contestImageIMG}
            setImage={setcontestImage}
            placeholder="Startgame contest"
            title="Startgame contest"
            required
          />
          <UploadComponent
            image={endgameSuccessImageIMG}
            setImage={setendgameSuccessImage}
            placeholder="Endgame Success"
            title="Endgame Success"
            required
          />
          <UploadComponent
            image={endgameFailImageIMG}
            setImage={setendgameFailImage}
            placeholder="Endgame Fail"
            title="Endgame Fail"
            required
          />
          <UploadComponent
            image={endgameFraudImageIMG}
            setImage={setendgameFraudImage}
            placeholder="Endgame Fraud"
            title="Endgame Fraud"
            required
          />
          <UploadComponent
            image={gameBackgroundImageIMG}
            setImage={setGameBackgroundImage}
            placeholder="Game Background"
            title="Game Background"
          />
        </div>
        <Divider>Leaderboard</Divider>

        <UploadComponent
          image={leaderboardBanner}
          setImage={setleaderboardBanner}
          placeholder="Leaderboard banner"
          title="Leaderboard banner"
        />

        <UploadComponent
          image={leaderboardTopBanner}
          setImage={setleaderboardTopBanner}
          placeholder="Leaderboard top banner"
          title="Leaderboard top banner"
        />

        <UploadComponent
          image={leaderboardAdReplacementBanner}
          setImage={setleaderboardAdReplacementBanner}
          placeholder="Leaderboard ad replacement banner"
          title="Leaderboard ad replacement banner"
        />

        <Divider>Other</Divider>
        <UploadComponent
          image={scheduleSidebarBanner}
          setImage={setScheduleSidebarBanner}
          placeholder="Schedule sidebar banner"
          title="Schedule sidebar banner"
        />
      </div>
    </>
  )
}
