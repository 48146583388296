import { computed, observable, makeObservable, transaction } from "mobx"
import { BaseStore } from "stores/base.store"
import { getSubscriptions, updateSubscription } from "domains/subscription/api"
import { Subscription } from "domains/subscription/store/types"
import { RootStore } from "stores/root.store"

export class SubscriptionStore extends BaseStore {
  @observable
  private _subscriptions: Subscription[] = []

  @observable
  private _selectedElement: Subscription = {} as Subscription

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getData() {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getSubscriptions)

    transaction(() => {
      this._subscriptions = res
    })

    return res
  }

  async update(data: Subscription) {
    await this.defaultFunction(updateSubscription, this._selectedElement.id, data)
  }

  @computed
  get subscriptions(): Subscription[] {
    return this._subscriptions
  }

  @computed
  get selectedElement(): Subscription {
    return this._selectedElement
  }

  set selectedElement(value: Subscription) {
    this._selectedElement = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as Subscription
  }
}
