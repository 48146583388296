import React, { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import s from "./styles.module.css"
import { Button, Input } from "antd"
import { notifications } from "notifications"

export const ProductProcessorCreate: FC<{ onClose: () => void}> = observer(() => {
  const { dataStore: { productsStore } } = useStore()
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [price, setPrice] = useState(0)
  const [consumable, setConsumable] = useState(false)
  const [stripeId, setStripeId] = useState("")
  const [appStoreId, setAppStoreId] = useState("")
  const [googlePlayId, setGooglePlayId] = useState("")

  return (
    <div className={s.prettyJson}>
      <div >
        <div>Name:</div>
        <Input onChange={e => setName(e.target.value)} value={name} />
      </div>
      <div >
        <div>Description:</div>
        <Input onChange={e => setDescription(e.target.value)} value={description} />
      </div>
      <div >
        <div>Price:</div>
        <Input type="number" onChange={e => setPrice(parseFloat(e.target.value))} value={price} />
      </div>
      <div >
        <div>StripeId:</div>
        <Input onChange={e => setStripeId(e.target.value)} value={stripeId} />
      </div>
      <div >
        <div>AppStoreId:</div>
        <Input onChange={e => setAppStoreId(e.target.value)} value={appStoreId} />
      </div>
      <div >
        <div>GooglePlayId:</div>
        <Input onChange={e => setGooglePlayId(e.target.value)} value={googlePlayId} />
      </div>
      <div >
        <div>Consumable:</div>
        <Input type="checkbox" onChange={e => setConsumable(a => !a)} checked={consumable} />
      </div>
      <div style={{ display: "flex" }}>
        <Button
          style={{ display: "flex", margin: "auto", marginTop: "15px" }}
          type="primary"
          onClick={() => {
            productsStore.funcAfterSuccess = () => {
              productsStore.getData()
              notifications.success("Created!")
            }
            productsStore.createProduct({
              name: name,
              description: description,
              price: price,
              stripeId: stripeId,
              appStoreId: appStoreId,
              googlePlayId: googlePlayId,
              consumable: consumable,
            })
          }}
        >
        Create Product
        </Button>
      </div>
    </div>
  )
})
