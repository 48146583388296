import { useCallback, useEffect, useMemo, useState } from "react"
import { IAdvertiser, ITask } from "../api/types"
import { getAdvertiser } from "../api"

const useAdvertiser = (id: string) => {
  const [advertiserWithUnsortedTasks, setAdvertiser] = useState<IAdvertiser>()

  useEffect(() => {
    setAdvertiser(undefined)
    getAdvertiser(id).then(setAdvertiser)
  }, [id])

  const updateTask = useCallback((task: ITask) => {
    setAdvertiser(prev => prev ? { ...prev, tasks: prev.tasks?.map(p => p.id === task.id ? task : p) } : prev)
  }, [])

  const addTask = useCallback((task: ITask) => {
    setAdvertiser(prev => prev ? { ...prev, tasks: prev.tasks ? [...prev.tasks, task] : [task] } : prev)
  }, [])

  const advertiser = useMemo(() => {
    if (!advertiserWithUnsortedTasks) return advertiserWithUnsortedTasks
    const copy = [...advertiserWithUnsortedTasks.tasks]
    // sort by createdAt
    copy.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    // sort by priority
    copy.sort((a, b) => b.priority - a.priority)

    return {
      ...advertiserWithUnsortedTasks,
      tasks: [...copy],
    }
  }, [advertiserWithUnsortedTasks])

  return {
    advertiser,
    setAdvertiser,
    updateTask,
    addTask,
  }
}

export default useAdvertiser
