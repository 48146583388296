import React, { useState, FC, useEffect } from "react"
import Input from "antd/lib/input"
import Button from "antd/lib/button"
import Dropdown from "antd/lib/dropdown"
import { SketchPicker } from "react-color"
import "antd/dist/antd.css"

type InputColorProps = {
  title: string;
  color: string;
  onChange: (color: string) => void;
};

export const InputColor: FC<InputColorProps> = ({ color, onChange, title }) => {
  const [internalColor, setInternalColor] = useState(color && color?.length > 0 ? color : "#ffffff")

  useEffect(() => {
    if (color) {
      setInternalColor(color?.length > 0 ? color : "#ffffff")
    }
  }, [color])

  const handleChange = (color: any) => {
    setInternalColor(color.hex)

    if (onChange) {
      onChange(color.hex)
    }
  }

  const overlay = (
    <div>
      <SketchPicker color={internalColor} onChange={handleChange} />
    </div>
  )

  return (
    <>
      <div style={{ marginBottom: "5px" }}>{title}</div>
      <Input
        value={internalColor || ""}
        style={{ marginBottom: "7px" }}
        onChange={e => {
          setInternalColor(e.target.value)
          onChange(e.target.value)
        }}
        prefix={
          <Dropdown trigger={["click"]} overlay={overlay}>
            <Button style={{ background: internalColor }}> </Button>
          </Dropdown>
        }
        placeholder="Default color is white #ffffff"
      />
    </>
  )
}
