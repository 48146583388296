import React from "react"
import { ReactComponent as StatusBarPhone } from "assets/statusBarPhone.svg"
import { ReactComponent as Round } from "assets/round.svg"
import s from "./styles.module.css"

interface IInfoVisibleDevice {
  title: string;
  image: string;
  buttonName: string;
}

interface IMobileView {
  uploadText: string;
  infoVisibleDevice: IInfoVisibleDevice;
}

function splitByString(source: string, splitBy: string) {
  const splitter: any[] = splitBy.split("")
  splitter.push([source])

  return splitter.reduceRight(function(accumulator: string[], curValue: string) {
    let k: string[] = []
    accumulator.forEach((v: string) => (k = [...k, ...v.split(curValue)]))

    k = k.filter((v: string) => v.length !== 0)

    return k
  })
}

export const MobileView: React.FC<IMobileView> = ({ infoVisibleDevice, uploadText }) => {
  const arr: string[] = splitByString(uploadText, "&*")

  return (
    <div className={s.containerPhone}>
      <div className={s.phone}>
        <StatusBarPhone />
        {infoVisibleDevice?.image ? (
          <img src={infoVisibleDevice?.image} alt="GameImg" className={s.imagePhone} />
        ) : null}
        <div className={s.titlePhone}>{infoVisibleDevice?.title}</div>
        {arr?.map((text: string, index: number) => (
          <div key={index} className={s.containerText}>
            {text[0] && text[1] === "-" ? (
              // item list
              <>
                <Round />
                <div className={s.textPhoneList}>{text.substring(2)}</div>
              </>
            ) : text[1] === "#" ? (
              // header
              <div style={{ fontWeight: "bold", color: "white" }}>{text.substring(2)}</div>
            ) : text[1] === "@" ? (
              // image
              <img src={text.substring(2)} alt="" style={{ width: "100%" }} />
            ) : (
              // paragraph
              <div className={s.textPhoneParagraph}>{text.substring(2)}</div>
            )}
          </div>
        ))}
        {infoVisibleDevice?.buttonName && (
          <div className={s.buttonPhone}>{infoVisibleDevice?.buttonName}</div>
        )}
        <div className={s.empty}>buff team</div>
      </div>
    </div>
  )
}
