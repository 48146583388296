import React, { FC, useState } from "react"

import { DatePicker, Form, Select } from "antd"
import { FormElement } from "components"
import { gameNamesDesktop } from "abstract/constants/game-ids"
import moment from "moment"
import GeoPickerElement from "components/geoPicker"

const { Option } = Select

export const MainInfoTab: FC<{
  data?: any;
  name: string;
  editType: "create" | "edit";
  startDate?: Date;
  setstartDate: (date) => void;
  endDate?: Date;
  setendDate: (date) => void;
  setcontestType: (value: "CHALLENGE_BASED" | "SCORE_BASED") => void;
  contestType?: "CHALLENGE_BASED" | "SCORE_BASED";
}> = ({
  data,
  name,
  editType,
  startDate,
  setstartDate,
  endDate,
  setendDate,
  contestType,
  setcontestType,
}) => {
  const [rewardType, setrewardType] = useState(editType === "edit" ? data?.topLadderRewardStrategy?.type : "")

  return (
    <>
      <FormElement
        type="input"
        label="Name"
        name="name"
        initialValue={name}
        required
        style={{ width: "100%", marginBottom: "20px" }}
        props={{ style: { width: "100%" }, disabled: editType === "edit" }}
      />
      <FormElement
        type="input"
        label="Pretty name"
        name="prettyName"
        initialValue={data?.prettyName}
        required
      />
      <FormElement type="select" label="Type Contest" name="contestType">
        <Select
          defaultValue="SCORE_BASED"
          placeholder="Please select contest type"
          value={contestType}
          onChange={(e: any) => setcontestType(e)}
        >
          {["SCORE_BASED", "CHALLENGE_BASED"].map(item => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            )
          })}
        </Select>
      </FormElement>
      <FormElement type="select" required label="Games" name={["loungePageData", "games"]}>
        <Select mode="tags" placeholder="Please select games">
          {Object.entries(gameNamesDesktop).map(item => {
            return (
              <Option value={item[0]} key={item[0]}>
                {item[1]}
              </Option>
            )
          })}
        </Select>
      </FormElement>
      <GeoPickerElement label="Country codes" name="countries" initialValue={data?.countries} />
      <div style={{ display: "flex", gap: "20px" }}>
        <Form.Item
          label="Start Date (UTC)"
          name="startDate"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Select start date",
            },
          ]}
        >
          <DatePicker
            value={moment(startDate)}
            onChange={e => setstartDate(e?.toDate())}
            showTime
            format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
            placeholder="Select Start Date"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="End Date (UTC)"
          name="endDate"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Select end date",
            },
          ]}
        >
          <DatePicker
            showTime
            format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
            placeholder="Select End Date"
            style={{ width: "100%" }}
            value={moment(endDate)}
            onChange={e => setendDate(e?.toDate())}
            disabledDate={e => {
              return e < moment(startDate)
            }}
          />
        </Form.Item>
      </div>
      <FormElement
        type="inputNumber"
        label="Analytics Impression Interval"
        name="analyticsImpressionInterval"
        required
        props={{ style: { width: "100%" }, min: 1 }}
      />
      {/* <FormElement
        type="input"
        label="Services"
        name="services"
        required
        props={{ placeholder: 'Input Services devide comma' }}
      /> */}

      <FormElement
        type="input"
        label="Terms&Conditions Link"
        name="termsAndConditionsLink"
        rules={[
          {
            required: true,
            message: "This field is required.",
          },
          {
            type: "url",
            message: "This field must be a valid url.",
          },
        ]}
      />

      <FormElement
        type="input"
        label="Support Link"
        name="supportLink"
        rules={[
          {
            required: true,
            message: "This field is required.",
          },
          {
            type: "url",
            message: "This field must be a valid url.",
          },
        ]}
      />

      <FormElement
        type="input"
        label="FAQ Link"
        name="faqLink"
        rules={[
          {
            type: "url",
            message: "This field must be a valid url.",
          },
        ]}
      />

      {contestType === "SCORE_BASED" && (
        <>
          <FormElement
            type="select"
            label="Reward type"
            name={["topLadderRewardStrategy", "type"]}
          >
            <Select
              placeholder="Please select reward type"
              value={rewardType}
              onChange={e => setrewardType(e)}
            >
              {["BUFF_POINTS", "GIFT_CARDS"].map(item => {
                return (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                )
              })}
            </Select>
          </FormElement>
          <FormElement
            type="input"
            name={["topLadderRewardStrategy", "rewardText"]}
            label="Reward Text"
          />
          {rewardType === "BUFF_POINTS" && (
            <FormElement
              type="input"
              name={["topLadderRewardStrategy", "buffPointsAmount"]}
              label="Rewards Points Amount"
              props={{ type: "number" }}
            />
          )}
          {rewardType === "GIFT_CARDS" && (
            <FormElement
              type="input"
              name={["topLadderRewardStrategy", "giftCardText"]}
              label="Gift Card Text"
            />
          )}
          <FormElement
            type="input"
            name={["leaderboardBanners", "topBannerCopyText"]}
            label="Leaderboard Top Banner Copy Text"
          />
          <FormElement
            type="input"
            name={["leaderboardBanners", "rightBannerCopyText"]}
            label="Leaderboard Right Banner Copy Text"
          />
        </>
      )}
    </>
  )
}
