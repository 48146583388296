import {
  computed,
  makeObservable,
  observable,
  transaction,
} from "mobx"
import {
  getChallenges,
  updateChallenge,
  getActiveChallenges,
  updateActiveChallenges,
  updateGoalTemplate,
  updateStatusChallenge,
  createChallenge,
} from "domains/challenges/api/challenges.calls"
import { Challenge, CreateChallengeDTO, StatusEnum, TemplateChallenge, UpdatedChallenge } from "./types"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"

export class ChallengesStore extends PaginationStore<Challenge> {
  @observable
  private _challenges: Challenge[]
  @observable
  private _activeChallenges: Challenge[]

  @observable
  private _selectedElement: Challenge | any

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<Challenge[]> {
    const { page, limit } = this.pagination

    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getChallenges, this.filter, page, limit)

    transaction(() => {
      this._challenges = res?.data?.payload?.goals
      this.total = res?.data?.payload?.total
    })

    return res?.data?.payload?.goals
  }
  async getActiveChallenges(): Promise<Challenge[]> {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getActiveChallenges)
    transaction(() => {
      this._activeChallenges = res
    })

    return res
  }

  async create(data: CreateChallengeDTO) {
    await this.defaultFunction(createChallenge, data)
  }

  async update(data: UpdatedChallenge) {
    await this.defaultFunction(updateChallenge, data)
  }

  async updateActiveChallengeGoal(id: string, newGoalId: string) {
    await this.defaultFunction(updateActiveChallenges, id, { newGoalId })
  }
  async updateStatusChallenge(goalId: string, status: StatusEnum) {
    await this.defaultFunction(updateStatusChallenge, goalId, status)
  }

  async updateGoalTemplate(goalId: string, data: TemplateChallenge) {
    await this.defaultFunction(updateGoalTemplate, goalId, data)
  }

  @computed
  get challenges(): Challenge[] {
    return this._challenges
  }
  @computed
  get activeChallenges(): Challenge[] {
    return this._activeChallenges
  }

  @computed
  get selectedElement(): Challenge {
    return this._selectedElement
  }

  set selectedElement(value: Challenge) {
    this._selectedElement = value
  }
}
