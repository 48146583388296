import { EntityToUpdate } from "abstract/entity-to-update"
import { Booster } from "../store/boosters.store"
import { apiCall } from "api-calls/api-call"

export const getBoosters = async(
  filter: string,
  page: number,
  limit: number,
): Promise<{
  data: Booster[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string } = {
    page,
    limit,
  }
  if (filter) query.filter = filter

  return apiCall({
    method: "GET",
    path: "boosters",
    query: query,
  })
}

export const updateBooster = async(
  id: string,
  data: EntityToUpdate<Booster>,
): Promise<{
  data: Booster;
}> => {
  return apiCall({
    method: "PUT",
    path: `boosters/${id}`,
    body: data,
  })
}

export const createBooster = async(data: EntityToUpdate<Booster>): Promise<{
  data: Booster;
}> => {
  return apiCall({
    method: "POST",
    path: "boosters",
    body: data,
  })
}

export const deleteBooster = async(id: string): Promise<{
  data: Booster;
}> => {
  return apiCall({
    method: "DELETE",
    path: `boosters/${id}`,
  })
}
