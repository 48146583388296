export type Transaction = {
  id: string;
  type: number;
  recipientId: string;
  senderId: string;
  amount: number;
  datum: Record<any, any>;
  gameId: number | null;
  isConfirmed: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export const TransactionTypes = {
  1: "GAME_START",
  2: "GAME_END",
  3: "PURCHASE",
  4: "FRAUD",
  5: "BONUS",
  6: "ACHIEVEMENTS_START",
  9: "IRONSOURCE_ADS",
  13: "PREMIUM_MONTHLY_BONUS",
  14: "DAILY_BONUS",
  15: "SUBSCRIPTION_BONUS",
  16: "REFERRAL_BONUS",
  17: "UTM_BONUS",
  18: "LOTTERY_TICKET_PURCHASE",
  30: "BEST_PLAYS_PURCHASE",
  80: "PROMOCODE_BONUS",
  90: "BRIGHT_DATA_INTEGRATION_BOOSTER_BONUS",
  98: "BOOSTER_BONUS",
  99: "PROMOTION_BONUS",
  100: "CARD_WELCOME_BONUS",
  101: "CARD_TRANSACTION_BONUS",
  102: "CARD_CHALLENGE_BONUS",
  103: "BUFFPAY_REWARD",
  110: "SPIN_N_COLLECT_FREE_REWARD",
  111: "SPIN_N_COLLECT_PAID_REWARD",
  115: "BEST_PLAYS",
  120: "IRONSOURCE_OFFERWALL_REWARD",
  121: "IRONSOURCE_ADS_REVOKE",
  122: "WATCH_ADS_DESKTOP",
  123: "BUFF_TIKTOK_SUBSCRIPTION",
  133: "POINTS_PACK_SMALL",
  134: "POINTS_PACK_MEDIUM",
  135: "POINTS_PACK_LARGE",
  141: "SECOND_SCREEN_BONUS",
  150: "LEADERBOARD_CONTEST_REWARD",
  160: "AVATAR_VIDEO_REWARD",
  161: "ADS_REWARD",
  162: "AVATAR_MINI_GAME_FINISHED_REWARD",
  163: "AVATAR_BUFF_PASS_REWARD",
  164: "AVATAR_BUFF_SPAWNED_REWARD",
  165: "RAFFLE_REWARD",
  8: "CRM_BONUS",
  12: "ADDITIONAL_COINS",
} as const

export type TransactionTypesType = keyof typeof TransactionTypes;

export enum BillingPeriod {
  YEAR = "YEAR",
  MONTH = "MONTH",
  DAY = "DAY",
}

export enum PaymentProviderTypeEnum {
  STRIPE = "STRIPE",
  PAYPAL = "PAYPAL",
  ADMIN = "ADMIN",
}

export class AccountSubscription {
  createdAt: Date
  activeUntil: Date
  billingPlanId: string
  id: string
  name: string
  isActive: boolean
  billingPeriod: BillingPeriod
  price: number
}

export interface AccountItemDTO {
  id: string
  type: string
  name: string
  description: string
  imageUrl: string
  createdAt: string
  updatedAt: string
  claimedAt: string
  source: string
  sourceData: Record<any, any>
  itemData: Record<any, any>
  status: string
  claimStatus: string
  isHidden: boolean
  marketplaceItem?: {
    id: string
    itemId: string
    accountItemId: string
    price: number
    premiumPrice: number
    howToRedeem: string
    category: string
    geoGroup: string
    giftCode: string
  }
}

export interface IAccountInventoryPage {
  data: AccountItemDTO[]
  total: number
}
