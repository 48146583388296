import { Form, InputNumber, Select } from "antd"
import { useState } from "react"
import { PersonalBoosterType, PopupReward, PopupRewardTypesEnum, PremiumType, DurationPeriod } from "../store/rewards.types"


type Props = {
  value?: PopupReward;
};

export const ClaimableRewardPicker = ({ value }: Props) => {
  const [type, setType] = useState<PopupRewardTypesEnum | undefined>(value?.rewardType)

  const onTypeChange = (type: string) => {
    setType(type as PopupRewardTypesEnum)
  }

  return (
    <>
      <Form.Item
        name={["reward", "rewardType"]}
        label="Type"
        required
        rules={[
          {
            required: true,
            message: "Type is required",
          },
        ]}
      >
        <Select onChange={onTypeChange} value={type}>
          <Select.Option value={PopupRewardTypesEnum.BUFFS}>Buffs</Select.Option>
          <Select.Option value={PopupRewardTypesEnum.RAFFLE_TICKETS}>Raffle Tickets</Select.Option>
          <Select.Option value={PopupRewardTypesEnum.SPIN_COLLECT_GOLDEN_TICKET}>Spin & Collect Golden Tickets</Select.Option>
          <Select.Option value={PopupRewardTypesEnum.PREMIUM}>Premium</Select.Option>
          <Select.Option value={PopupRewardTypesEnum.BOOST_TIMED}>Boost Timed</Select.Option>
        </Select>
      </Form.Item>

      {type === PopupRewardTypesEnum.BUFFS && (
        <Form.Item
          name={["reward", "amount"]}
          label="Amount"
          required
          rules={[
            {
              required: true,
              min: 1,
              validator(_, value) {
                if (!value || value < 1) {
                  return Promise.reject(new Error("Amount is required and cannot be less than 1"))
                }

                return Promise.resolve()

              },
            },
          ]}
        >
          <InputNumber min={1} step={1} />
        </Form.Item>
      )}

      {(type === PopupRewardTypesEnum.RAFFLE_TICKETS || type === PopupRewardTypesEnum.SPIN_COLLECT_GOLDEN_TICKET) && (
        <Form.Item
          name={["reward", "count"]}
          label="Count"
          required
          rules={[
            {
              required: true,
              min: 1,
              validator(_, value) {
                if (!value || value < 1) {
                  return Promise.reject(new Error("Count is required and cannot be less than 1"))
                }

                return Promise.resolve()

              },
            },
          ]}
        >
          <InputNumber min={1} step={1} />
        </Form.Item>
      )}

      {(type === PopupRewardTypesEnum.PREMIUM) && (
        <Form.Item
          name={["reward", "premiumType"]}
          label="Premium Type"
          required
          rules={[
            {
              required: true,
              message: "Premium Type is required",
            },
          ]}
        >
          <Select>
            {Object.keys(PremiumType).map(premiumType => (
              <Select.Option key={premiumType} value={premiumType}>{premiumType}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {(type === PopupRewardTypesEnum.BOOST_TIMED && (
        <Form.Item
          name={["reward", "boosterType"]}
          label="Boost Type"
          required
          rules={[
            {
              required: true,
              message: "Boost Type is required",
            },
          ]}
        >
          <Select>
            {Object.keys(PersonalBoosterType).map(booster => (
              <Select.Option key={booster} value={booster}>{booster}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      ))}

      {(type === PopupRewardTypesEnum.BOOST_TIMED && (
        <Form.Item
          name={["reward", "percentage"]}
          label="Percentage"
          required
          rules={[
            {
              required: true,
              min: 1,
              max: 100,
              validator(_, value) {
                if (!value || value < 1 || value > 100) {
                  return Promise.reject(new Error("Percentage is required and cannot be less than 1 or greater than 100"))
                }

                return Promise.resolve()

              },
            },
          ]}
        >
          <InputNumber
            min={1}
            max={100}
            step={1}
            addonAfter="%"
          />
        </Form.Item>
      ))}

      {((type === PopupRewardTypesEnum.PREMIUM || type === PopupRewardTypesEnum.BOOST_TIMED) && (
        <>
          <Form.Item
            name={["reward", "duration"]}
            label="Duration"
            required
            rules={[
              {
                required: true,
                min: 1,
                validator(_, value) {
                  if (!value || value < 1) {
                    return Promise.reject(new Error("Duration is required and cannot be less than 1"))
                  }

                  return Promise.resolve()

                },
              },
            ]}
          >
            <InputNumber min={1} step={1} />
          </Form.Item>
          <Form.Item
            name={["reward", "period"]}
            label="Period"
            required
            rules={[
              {
                required: true,
                message: "Period is required",
              },
            ]}
          >
            <Select>
              {Object.keys(DurationPeriod).map(period => (
                <Select.Option key={period} value={period}>{period}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      ))}

    </>
  )
}
