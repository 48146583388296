import { FC } from "react"
import { Card, Tag } from "antd"
import { Tier } from "stores/primitives/tier"
import { defaultFormat } from "utils/date"

export const AccountInfo: FC<{
  id?: string;
  email: string;
  login: string;
  nickname: string;
  createdAt: Date;
  updatedAt: Date;
  balance: number;
  earned: number;
  spent: number;
  earnedByGames: number;
  tier: Tier | null;
  isFrozen: boolean;
  reasonOfBan: string | null;
  accountFreeze: {
    accountId: string
    createdAt: Date
    updatedAt: Date
    confirm: boolean
  } | null
}> = ({
  id,
  email,
  login,
  nickname,
  createdAt,
  balance,
  earned,
  spent,
  earnedByGames,
  tier,
  isFrozen,
  reasonOfBan,
  accountFreeze,
}) => {
  return (
    <Card title="Account Info">
      <p>ID: {id}</p>
      <p>Email: {email}</p>
      <p>Login: {login}</p>
      <p>Nickname: {nickname}</p> <p>Created At: {createdAt && defaultFormat(createdAt)}</p>
      <p>Last Activity Date At: {createdAt && defaultFormat(createdAt)}</p>
      <p>Balance: {(+balance)?.toFixed(2)}</p>
      <p>Total Earned: {(+earned)?.toFixed(2)}</p>
      <p>Total Spent: {(+spent)?.toFixed(2)}</p>
      <p>Total Earned By games: {(+earnedByGames)?.toFixed(2)}</p>
      {tier && (
        <p>
          Tier name: {tier.description} <Tag color="blue">{`${tier.points}/${tier.end}`}</Tag>
        </p>
      )}
      <p>Account Status: {isFrozen ? "Frozen" : "Not Frozen"}</p>
      <p>Account in obfuscation list: {accountFreeze ? `Yes (since ${accountFreeze.createdAt})` : "No"} </p>
      <p>Account obfuscated: {accountFreeze?.confirm ? "Yes" : "No"} </p>
      {reasonOfBan && <p>Reason of Ban: {reasonOfBan}</p>}
    </Card>
  )
}
