import { computed, observable, makeObservable, transaction } from "mobx"
import { BaseStore } from "stores/base.store"
import {
  getGames,
  createGameMode,
  updateGameMode,
  deleteGameMode,
  autoUpdate,
  updateGameStatus,
  updateGameType,
  updateGamePriority,
  deleteGame,
  createGame,
  createGameStatus,
  deleteGameStatus,
} from "domains/games/api"
import { Game, GameMode, GameStatus, GameType } from "domains/games/store/types"
import { RootStore } from "stores/root.store"

export class GamesStore extends BaseStore {
  @observable
  private _games: Game[] = []

  @observable
  private _game: Game = {} as Game

  @observable
  private _selectedElement: Game = {} as Game

  @observable
  private _selectedGameMode: GameMode = {} as GameMode

  @observable
  private _selectedGameType: GameType = {} as GameType

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getData() {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getGames)

    transaction(() => {
      this._games = res
    })

    return res
  }

  public setGame(data: Game) {
    this._game = data
  }

  public async createGameMode(data: GameMode) {
    return createGameMode(data)
  }

  public async updateGameMode(data: GameMode & { modeId: string }) {
    return updateGameMode(data)
  }

  public async deleteGameMode(id: string) {
    await deleteGameMode(id)
    await this.getData()
  }

  public async createGameStatus(data: GameType) {
    return createGameStatus(data)
  }

  public async deleteGameStatus(data: string) {
    return deleteGameStatus(data)
  }

  public async deleteGame(id: string) {
    await deleteGame(id)
    await this.getData()
  }

  public async autoUpdate(data: { gameId: string; status: boolean }) {
    return autoUpdate(data)
  }

  public async updateGameStatus(data: { gameId: string; status: GameStatus }) {
    return updateGameStatus(data)
  }

  public async updateGameType(data: {
    gameTypeId: string;
    pretyName: string;
    description: string;
  }) {
    return updateGameType(data)
  }

  public async updateGamePriority(data: { gameId: string; priority: number | null }) {
    return updateGamePriority(data)
  }

  public async createGame(data: {
    gameIdentifier: string;
    priority: number | null;
    gamePlatform: boolean | any;
    gamePlatformId: string;
    pretyName: string;
    name: string;
    updateUrl: string;
    updatedAt: string;
    createdAt: string;
    pictureUrl: string;
    isAutoUpdatable: boolean;
    }) {
    return createGame(data)
  }

  @computed
  get games(): Game[] {
    return this._games
  }

  @computed
  get selectedElement(): Game {
    return this._selectedElement
  }

  set selectedElement(value: Game) {
    this._selectedElement = value
  }

  @computed
  get selectedGameMode(): GameMode {
    return this._selectedGameMode
  }

  set selectedGameMode(value: GameMode) {
    this._selectedGameMode = value
  }

  @computed
  get selectedGameType(): GameType {
    return this._selectedGameType
  }

  set selectedGameType(value: GameType) {
    this._selectedGameType = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as Game
    this._selectedGameMode = {} as GameMode
  }

  @computed
  get game(): Game {
    return this._game
  }
}
