import { CopyOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import { notifications } from "notifications"
import { defaultFormat } from "utils/date"
import { DistributionType, HandleType, LifeCycleStatus } from "../../store/types"

export const rafflesColumns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    render: (id: string) => {
      return (
        <div
          onClick={() => {
            navigator.clipboard.writeText(id)
            notifications.success("Copied id:" + id)
          }}
          style={{
            background: "#A3A3A3",
            width: 24,
            height: 24,
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <CopyOutlined
            style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }}
          />
        </div>
      )
    },
  },
  { title: "Name", dataIndex: "title", key: "title" },

  {
    title: "Type",
    dataIndex: "handleType",
    key: "handleType",
    render: (handleType: HandleType) => {
      if (handleType === "AUTOMATED") return <Tag color="blue">Automated</Tag>

      return <Tag color="red">Custom</Tag>
    },
  },
  {
    title: "Winner Selection",
    dataIndex: "distributionType",
    key: "distributionType",
    render: (type: DistributionType) => {
      if (type === "AUTOMATED") return <Tag color="blue">Automated</Tag>

      return <Tag color="red">Custom</Tag>
    },
  },
  {
    title: "Status",
    dataIndex: "lifeCycleStatus",
    key: "lifeCycleStatus",
    render: (lifeCycleStatus: LifeCycleStatus) => {
      if (lifeCycleStatus === "LIVE") {
        return (
          <ul className="activeStatus">
            <li>Active</li>
          </ul>
        )
      } else if (lifeCycleStatus === "UPCOMING") {
        return (
          <ul className="processingStatus">
            <li>Upcoming</li>
          </ul>
        )
      } else if (lifeCycleStatus === "FINISHED") {
        return (
          <ul className="finishedStatus">
            <li>Finished</li>
          </ul>
        )
      } else if (lifeCycleStatus === "PROCESSING") {
        return (
          <ul className="anotherStatus">
            <li>{lifeCycleStatus?.toLocaleLowerCase()}</li>

            <Tag color="red">You should apply all winners</Tag>
          </ul>
        )
      }
    },
  },
  {
    title: "Start/End Dates",
    dataIndex: "startDate",

    key: "startEndDates",
    render: (text, row) => {
      return (
        <div style={{ display: "grid", gap: "6px", width: "max-content" }}>
          <Tag color="green">{defaultFormat(row?.periodStart)}</Tag>
          <Tag color="red">{defaultFormat(row?.periodFinish)}</Tag>
        </div>
      )
    },
  },
]
