import React, { useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Button, Divider, Form, Select } from "antd"
import { observer } from "mobx-react"

import { gameNamesDesktop } from "abstract/constants/game-ids"
import { PlusCircleOutlined } from "@ant-design/icons"

export enum SecondaryWindowPositions {
  TOP_LEFT = "TOP_LEFT",
  TOP_CENTER = "TOP_CENTER",
  TOP_RIGHT = "TOP_RIGHT",
  CENTER_LEFT = "CENTER_LEFT",
  CENTER = "CENTER",
  CENTER_RIGHT = "CENTER_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_CENTER = "BOTTOM_CENTER",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}
export enum SecondaryWindowTitle {
  startgame = "startgame",
  endgame = "endgame",
  launchGame = "launchGame",
  secondMonitor = "secondMonitor",
  gameSummary = "gameSummary",
}

export type PositionsIngameScreens = Record<
  keyof typeof gameNamesDesktop,
  Record<SecondaryWindowTitle, SecondaryWindowPositions>
>;

export const EditPostionOfScreens = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()

  const [form] = Form.useForm()
  const [positionsObject, setpositionsObject] = useState<PositionsIngameScreens>({})
  const [openedValues, setopenedValues] = useState<Record<keyof typeof gameNamesDesktop, boolean>>({})
  const [postionOfScreensExist, setPostionOfScreensExist] = useState(false)

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const positionsIngameScreensData = managingJsonStore?.itemTypes?.filter(item => item.type === "positionsIngameScreens")
    if (
      positionsIngameScreensData
      && positionsIngameScreensData.length === 1
      && positionsIngameScreensData[0]?.data
    ) {
      const newopenedValues = {}
      Object.keys(positionsIngameScreensData[0]?.data).map(item => {
        newopenedValues[item] = true
      })
      setopenedValues(newopenedValues)
      setPostionOfScreensExist(true)
      setpositionsObject(positionsIngameScreensData[0]?.data as PositionsIngameScreens)
    }
  }, [managingJsonStore.itemTypes, form])

  const onFinishHandler = async() => {
    postionOfScreensExist
      ? await managingJsonStore.update({
        name: "positionsIngameScreens",
        type: "positionsIngameScreens",
        data: positionsObject,
      })
      : await managingJsonStore.create({
        name: "positionsIngameScreens",
        type: "positionsIngameScreens",
        data: positionsObject,
      })
  }
  const updateData = (
    game: keyof typeof gameNamesDesktop,
    windowName: SecondaryWindowTitle,
    position: SecondaryWindowPositions,
  ) => {
    const newPostionObject = { ...positionsObject }
    const windowDataUpdated = { ...positionsObject[game] }
    windowDataUpdated[windowName] = position

    if (position === undefined) {
      delete windowDataUpdated[windowName]
    }
    newPostionObject[game] = windowDataUpdated
    if (Object.values(windowDataUpdated).length === 0) {
      delete newPostionObject[game]
    }
    setpositionsObject(newPostionObject)
  }
  const updatedOpenedValues = (game: keyof typeof gameNamesDesktop) => {
    const newopenedValues = { ...openedValues }
    newopenedValues[game] = !newopenedValues[game]
    setopenedValues(newopenedValues)
  }

  return (
    <div>
      {Object.entries(gameNamesDesktop).map(item => (
        <div key={item[0]}>
          <div onClick={() => updatedOpenedValues(+item[0])} style={{ cursor: "pointer" }}>
            <Divider>
              {item[1]}
              <PlusCircleOutlined
                style={{
                  cursor: "pointer",
                  paddingLeft: "10px",
                  transform: "translate(5px, -2px)",
                }}
              />
            </Divider>
          </div>
          {openedValues[+item[0]] && (
            <div
              style={{
                display: "grid",
                gap: "10px",
                gridAutoFlow: "column",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridTemplateRows: "1fr 1fr",
              }}
            >
              {Object.values(SecondaryWindowTitle).map(item1 => (
                <div
                  key={item1}
                  style={{
                    display: "grid",
                    gap: "10px",
                    gridAutoFlow: "column",
                    gridTemplateColumns: "max-content 200px",
                  }}
                >
                  <div>{item1}</div>

                  <Select
                    allowClear
                    onChange={e => updateData(+item[0], item1, e)}
                    value={positionsObject[item[0]] && positionsObject[item[0]][item1]}
                  >
                    {Object.values(SecondaryWindowPositions).map(el => (
                      <Select.Option key={item1} value={el}>{el}</Select.Option>
                    ))}
                  </Select>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
        <Button type="primary" htmlType="submit" onClick={onFinishHandler}>
          Save
        </Button>
      </div>
    </div>
  )
})
