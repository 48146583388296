import { Column } from "abstract/types/column.type"
import { Tag } from "antd"
import { defaultFormat } from "utils/date"

export const parentPromocodeColumns: Column[] = [
  { title: "Name", key: "name", dataIndex: "name", fixed: true },
  {
    title: "Promo code group",
    key: "group",
    dataIndex: ["promoGroup", "name"],
    render: (el: string) => <div style={{ minWidth: 100 }}>{el}</div>,
  },
  {
    title: "Success message header",
    key: "successMessage",
    dataIndex: ["successMessage", "header"],
    render: (el: string) => (
      <div style={{ minWidth: 60 }}>
        {el?.slice(0, 20)}
        {el?.length > 20 && "..."}
      </div>
    ),
  },
  {
    title: "Success message body",
    key: "successMessage",
    dataIndex: ["successMessage", "body"],
    render: (el: string) => (
      <div style={{ minWidth: 60 }}>
        {el?.slice(0, 20)}
        {el?.length > 20 && "..."}
      </div>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: ["startDate", "endDate"],
    render: (_, record) => {
      const timeNow = new Date()
      const isActive = timeNow > new Date(record.startDate) && timeNow < new Date(record.endDate)
      const isExpired = timeNow > new Date(record.endDate)

      return <div style={{ minWidth: 50 }}>{isActive ? "Active" : isExpired ? "Expired" : ""}</div>
    },
  },
  {
    title: "Start date",
    dataIndex: "startDate",
    key: "startDate",
    render: (el: Date) => (
      <Tag style={{ minWidth: 100 }} color="green">
        {defaultFormat(el)}
      </Tag>
    ),
  },
  {
    title: "Expiration date",
    dataIndex: "endDate",
    key: "endDate",
    render: (el: Date) => (
      <Tag style={{ minWidth: 100 }} color="red">
        {defaultFormat(el)}
      </Tag>
    ),
  },
  { title: "Code", key: "code", dataIndex: "parentCode" },
  {
    title: "Type",
    dataIndex: "promoCodeType",
    key: "promoCodeType",
  },
  {
    title: "Number of codes",
    key: "numOfCodes",
    dataIndex: "childPromoCodesLimit",
    render: (el: string) => <div style={{ minWidth: 60 }}>{el}</div>,
  },
  {
    title: "Number of uses",
    key: "numOfUses",
    dataIndex: "numberOfRedeems",
    render: (el: string) => <div style={{ minWidth: 60 }}>{el}</div>,
  },
]
