import { BaseStore } from "stores/base.store"
import { computed, observable, action, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { ItemProvider, ItemProviderResponse } from "./types"

import { getItemInfo, getItemProviders } from "../api"
import { notifications } from "notifications"

export class ItemProviderStore extends BaseStore {
  @observable
  private _itemProviders: ItemProvider[] = []

  @observable
  private _itemInfo?: ItemProviderResponse

  @observable
  private _isEmptyError: boolean = false

  @observable
  private _isVisibleError: boolean = false

  @observable
  private _isEmptyImage: boolean = false

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getData() {
    this.resetFuncAfterSuccess()

    const data = await getItemProviders()
    this._itemProviders = data

    return data
  }
  @computed
  get itemProviders(): ItemProvider[] {
    return this._itemProviders
  }
  @computed
  get itemInfo(): ItemProviderResponse | undefined {
    return this._itemInfo
  }
  @computed
  get isEmptyError(): boolean {
    return this._isEmptyError
  }

  @computed
  get isEmptyImage(): boolean {
    return this._isEmptyImage
  }
  @computed
  get isVisibleError(): boolean {
    return this._isVisibleError
  }

  @action.bound
  setEmptyError() {
    this._isEmptyError = true
    setTimeout(() => (this._isEmptyError = false), 2000)
  }
  @action.bound
  async getItemInfo(provider: string, id: string) {
    const response = await this.defaultFunction(getItemInfo, provider, id)
    if (response?.data) {
      this._itemInfo = response?.data as ItemProviderResponse
    } else {
      this._itemInfo = response as ItemProviderResponse
    }
    if (response?.data && response?.data?.status && response?.data?.status?.error) {
      notifications.error(response?.data?.status?.message)
    }
  }

  @action.bound
  public setIsEmptyImage() {
    this._isEmptyImage = true
    setTimeout(() => (this._isEmptyImage = false), 3000)
  }

  @action.bound
  clearItem() {
    this._itemInfo = undefined
  }

  @action.bound
  public setVisibleError(value: boolean) {
    this._isVisibleError = value
    if (value) setTimeout(() => this.setVisibleError(false), 4000)
  }
}
