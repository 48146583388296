import { MobileConfigHeaderButton } from "domains/managingJson/store/types"
import { Button, Collapse, Form, Typography } from "antd"
import { Input } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react"
import { UploadComponent } from "components"
import Styles from "./styles.module.css"
const { Text } = Typography

type Props = {
  headerButton: MobileConfigHeaderButton;
  error: boolean;
  index: number;
  setError: (err: boolean, index: number) => void;
  updateHeaderButton: (
    callback: (data: MobileConfigHeaderButton) => MobileConfigHeaderButton,
    index: number,
  ) => void;
  deleteHeaderButton: (index: number) => void;
  duplicateHeaderButton: (index: number) => void;
};

export const HeaderButtonEditor = ({
  headerButton,
  setError,
  error,
  index,
  updateHeaderButton,
  deleteHeaderButton,
  duplicateHeaderButton,
}: Props) => {
  const [form] = Form.useForm()
  const [imageUrl, setImageUrl] = useState<string>(headerButton.icon ?? "")

  const jsonString = headerButton.options ? JSON.stringify(headerButton.options, null, 4) : ""

  const validate = useCallback(async() => {
    try {
      await form.validateFields()
      setError(false, index)
    } catch (errors: any) {
      if (errors.errorFields.length > 0) setError(true, index)
    }
  }, [form, index, setError])

  const onValuesChange = useCallback(async() => {
    const formData = form.getFieldsValue()
    let parsedOptions = undefined
    try {
      parsedOptions = formData.options ? JSON.parse(formData.options) : undefined
    } catch (error) {
      console.log(error)
    }

    updateHeaderButton(
      prev => ({
        ...prev,
        icon: formData.icon,
        src: formData.src,
        options: parsedOptions,
      }),
      index,
    )
    validate()
  }, [form, index, updateHeaderButton, validate])

  useEffect(() => {
    validate()
  }, [validate])

  const handleDelete = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setError(false, index)
      deleteHeaderButton(index)
    },
    [deleteHeaderButton, index, setError],
  )

  const handleDuplicate = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      duplicateHeaderButton(index)
    },
    [duplicateHeaderButton, index],
  )

  return useMemo(
    () => (
      <Collapse>
        <Collapse.Panel
          forceRender
          header={
            <div>
              {headerButton.src || `Header button ${index}`}
              {error && <Text type="danger"> (!)</Text>}
            </div>
          }
          key={headerButton.uid}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{
              src: headerButton.src,
              options: jsonString,
              icon: headerButton.icon,
            }}
            autoComplete="off"
            onChange={onValuesChange}
          >
            <Form.Item
              label="src"
              name="src"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="icon"
              name="icon"
              valuePropName="fileList"
              rules={[{ required: true, message: "Required field" }]}
            >
              <UploadComponent
                image={imageUrl}
                setImage={src => {
                  setImageUrl(src)
                  form.setFieldsValue({ icon: src })
                }}
                uploadStyle={{ listType: "picture-card" }}
                placeholder="Upload icon"
              />
            </Form.Item>
            <Form.Item
              label="options"
              name="options"
              rules={[
                {
                  message: "Unparsable JSON syntax",
                  validator: (_, value) => {
                    try {
                      if (!value) return Promise.resolve()
                      JSON.parse(value)

                      return Promise.resolve()
                    } catch (error) {
                      return Promise.reject(new Error("Unparsable JSON syntax"))
                    }
                  },
                },
              ]}
            >
              <TextArea autoSize={{ minRows: 3 }} />
            </Form.Item>
          </Form>
          <div className={Styles.collapseButtonWrapper}>
            <Button onClick={handleDuplicate}>Duplicate</Button>
            <Button danger onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </Collapse.Panel>
      </Collapse>
    ),
    [
      error,
      form,
      handleDelete,
      handleDuplicate,
      headerButton.icon,
      headerButton.src,
      headerButton.uid,
      imageUrl,
      index,
      jsonString,
      onValuesChange,
    ],
  )
}
