import React, { FC, useState } from "react"

import { Divider, Form } from "antd"
import TextArea from "antd/lib/input/TextArea"

import { FormElement } from "components"
import { ContestServerSideConfiguration } from "domains/managingJson/store/types"

export const ContestBackEndStuff: FC<{
  data?: ContestServerSideConfiguration;
}> = ({ data }) => {
  const [shareMessageLength, setShareMessageLength] = useState(data?.contestTwitterShareMessage?.length || 0)

  const shareMessageChangeHandler = e => setShareMessageLength(e.target.value.length)

  return (
    <div>
      <Divider>Server-side configuration </Divider>
      <div>
        <Form.Item
          label={`Twitter share message. Length: ${shareMessageLength}`}
          name="contestTwitterShareMessage"
          initialValue={data?.contestTwitterShareMessage}
          rules={[
            {
              required: true,
              message: "required",
            },
            {
              max: 250,
              message: "Twitter API has limitation for tweets, keep less then 250 symbols",
            },
          ]}
        >
          <TextArea onChange={shareMessageChangeHandler} style={{ height: "100px" }} />
        </Form.Item>

        <FormElement
          type="inputNumber"
          label="Leaderboard reward count"
          name="contestRewardCount"
          initialValue={data?.contestRewardCount}
          required
          props={{ min: 1 }}
        />
      </div>
    </div>
  )
}
