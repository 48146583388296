import { PlusCircleOutlined } from "@ant-design/icons"
import { FormInstance } from "antd"
import { ContestConfigOnboardingScreenData } from "domains/managingJson/store/types"
import { FC } from "react"

interface props {
  onboardingSteps: ContestConfigOnboardingScreenData[]
  setOnboardingSteps: React.Dispatch<React.SetStateAction<ContestConfigOnboardingScreenData[]>>
  form: FormInstance<any>
}

export const OnboardingAdd: FC<props> = ({ onboardingSteps, setOnboardingSteps }) => {


  return (
    <div style={{ width: "30%", marginRight: "16px" }}>
      <div
        style={{
          padding: "16px 16px 0px",
          border: "1px solid #F0F0F0",
          height: "444px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setOnboardingSteps([...onboardingSteps, { title: "", text: "", image: "" }])
        }}
      >
        <PlusCircleOutlined style={{ fontSize: "48px", color: "#1890FF" }} />
      </div>
    </div>
  )
}
