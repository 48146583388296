import { Modal } from "antd"

export const showInfoConfirm = (onCancel?: () => void, onOk?: () => void, title?: string) => {
  Modal.confirm({
    title,
    okText: "Save",
    okType: "danger",
    cancelText: "Close",
    maskClosable: true,
    onOk() {
      if (onOk) {
        return onOk()
      }
    },
    onCancel() {
      if (onCancel) {
        return onCancel()
      }
    },
  })
}
