import { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { TablePaginationConfig } from "antd/lib/table/interface"
import { Button, Modal, Table } from "antd"
import { observer } from "mobx-react"

import { AddKeyComponent } from "./add-key-form"
import { AddKeysComponent } from "./add-keys.form"
import { UpdateDeleteKey } from "./update-key"

import { GiftCard } from "stores/primitives/gift-card"

export const KeysList: FC<{ itemTypeId: string }> = observer(({ itemTypeId }) => {
  const { dataStore: { giftCardsStore } } = useStore()

  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      giftCardsStore.pagination.page = page
    },
    total: giftCardsStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }

  useEffect(() => {
    giftCardsStore.getData()
  }, [giftCardsStore, itemTypeId])

  const columns: Record<string, any>[] = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    { title: "Updated at", dataIndex: "updatedAt", key: "updatedAt" },
    {
      title: "Login",
      dataIndex: "login",
      key: "login",
      render: (el: string) => el || "Not taken",
    },
    {
      title: "Name",
      dataIndex: ["itemType", "name"],
      key: "name",
    },
    {
      title: "Price",
      dataIndex: ["itemType", "price"],
      key: "price",
    },
    {
      title: "Key",
      dataIndex: "giftCode",
      key: "giftCoder",
    },
  ]

  const [visibleSingleKeyModal, setSingleKeyModalVisibility] = useState<boolean>(false)
  const [visibleMultipleKeyModal, setMultipleKeyModalVisibility] = useState<boolean>(false)
  const [visibleUpdateDeleteKeyModal, setUpdateDeleteKeyModalVisibility] = useState<boolean>(false)

  return (
    <>
      <Button onClick={() => setSingleKeyModalVisibility(true)}>Add new single Key</Button>
      <Button onClick={() => setMultipleKeyModalVisibility(true)}>Add new multiple Keys</Button>
      <Table
        dataSource={giftCardsStore.giftCards}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={giftCardsStore.isLoading}
        onRow={(record: GiftCard) => ({
          onClick: () => {
            giftCardsStore.selectedElement = record
            setUpdateDeleteKeyModalVisibility(true)
          },
        })}
      />
      <Modal
        visible={visibleSingleKeyModal}
        title="Add New Key"
        footer={null}
        onCancel={() => setSingleKeyModalVisibility(false)}
      >
        <AddKeyComponent />
      </Modal>
      <Modal
        visible={visibleMultipleKeyModal}
        title="Add New Keys"
        footer={null}
        onCancel={() => setMultipleKeyModalVisibility(false)}
      >
        <AddKeysComponent onClose={() => setMultipleKeyModalVisibility(false)} />
      </Modal>
      {visibleUpdateDeleteKeyModal && (
        <UpdateDeleteKey
          visible={visibleUpdateDeleteKeyModal}
          onClose={() => setUpdateDeleteKeyModalVisibility(false)}
        />
      )}
    </>
  )
})
