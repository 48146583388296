import React, { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Button, Form, Input, InputNumber, Select, Switch, Checkbox, Radio } from "antd"
import { observer } from "mobx-react"

import { ItemProvider, ItemToUpdate, ItemProviderResponse } from "domains/marketplace/store/types"

import { ItemInfo } from "../Item"

import Styles from "./styles.module.css"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { SubmitCancelButtons, UploadComponent } from "components"

const { Option } = Select

export const MarketplaceEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      marketplaceStore: {
        selectedElement: {
          name,
          price,
          premiumPrice,
          active,
          howToRedeem,
          description,
          device,
          img,
          tierId,
          giftCards,
          providerItem,
          outOfStockItems,
          type: marketType,
          geoGroup,
          itemsCategory,
          regionId,
          isSensitiveGeo,
          giftoinCampaignId,
        },
      },
      marketplaceStore,
      marketplaceRegionsStore: { regions },
      itemProviderStore: {
        itemProviders,
        isEmptyError,
        setEmptyError,
        setVisibleError,
        isVisibleError,
        setIsEmptyImage,
        isEmptyImage,
      },
      itemProviderStore,
      marketplaceCategoriesStore: { categories },
      tierStore: { tiers },
    },
  } = useStore()
  const [selectedItemProviderId, setSelectedItemProviderId] = useState<string>(providerItem?.itemProviderId || "")
  const [productId, setProductId] = useState<string>(providerItem?.itemId)
  const [itemType, setItemType] = useState(marketType || "REGULAR")

  const [image, setImage] = useState(img)
  const [canBeOutOfStock, setCanBeOutOfStock] = useState<boolean>(!!outOfStockItems || false)
  const [maxPrice, setmaxPrice] = useState(providerItem?.price || 0)
  const [devices, setDevices] = useState(device || [])
  const [isEmptydevices, setisEmptyDevices] = useState<boolean>(false)
  const [isGeoSensitive, setisGeoSensitive] = useState(isSensitiveGeo)

  useEffect(() => {
    device && device.length === 3 && setDevices(device.concat("all"))
  }, [device])

  const onFinishHandler = async(event: ItemToUpdate) => {
    const validateProvider = () => {
      const { providerItemId, ...payload } = event
      if (!providerItemId) return payload
      if (providerItemId === "") return payload

      return event
    }
    const e = validateProvider()
    e.img = image || e.img
    e.price = e.price && e.price / 100
    e.premiumPrice = e.premiumPrice && e.premiumPrice / 100
    e.isOutOfStock = canBeOutOfStock
    e.itemId = productId
    e.isSensitiveGeo = isGeoSensitive
    e.active = e.active === undefined ? true : e.active
    e.device = devices.filter(i => i !== "all")

    // if (!e.expire) e.expire = undefined;
    if (!e.tierId) e.tierId = undefined

    if (!e.img) {
      setIsEmptyImage()

      return
    }
    if (devices.length === 0) {
      setisEmptyDevices(true)

      return
    }
    const providerId = selectedItemProviderId
    const providerName = itemProviders.find(x => x.id === providerId)
    const name = (providerName as ItemProvider)?.name
    if (selectedItemProviderId === "") {
      delete e.itemProviderId
    }
    if (itemProviderStore.itemInfo?.item.price && name === "gcow") {
      e.maxPrice = itemProviderStore.itemInfo?.item.price
    }
    e.isPremium = itemType === "PREMIUM"
    if (name === "kinguin") {
      e.maxPrice = maxPrice
      await handleGetItemInfo()
      const { status, item } = itemProviderStore?.itemInfo as ItemProviderResponse
      const { error } = status
      if (error) return
      const { price } = item
      const isWarninPrice = price > (e.maxPrice as number)
      if (isWarninPrice) {
        setVisibleError(true)

        return
      }
    }
    marketplaceStore.funcAfterSuccess = async() => {
      onClose()
      await marketplaceStore.getData()
    }

    editType === "edit" ? await marketplaceStore.update(e) : marketplaceStore.create(e)
  }

  const handleGetItemInfo = async() => {
    const providerId = selectedItemProviderId
    const providerName = itemProviders.find(x => {
      return x.id === providerId
    })
    const name = (providerName as ItemProvider)?.name
    if (!productId) setEmptyError()
    else await itemProviderStore.getItemInfo(name, productId)
  }

  const setPlatform = (e: CheckboxChangeEvent) => {
    setisEmptyDevices(false)
    if (e.target.value === "all") {
      if (e.target.checked) {
        setDevices(["all", "android", "ios", "pc"])
      } else {
        setDevices([])
      }
    } else {
      if (e.target.checked) {
        let newDev = devices.concat(e.target.value)
        if (newDev.length === 3) {
          newDev = newDev.concat("all")
        }
        setDevices(newDev)
      } else {
        const newDev = devices.filter(item => item !== "all" && item !== e.target.value)
        setDevices(newDev)
      }
    }
  }

  return (
    <Form onFinish={onFinishHandler}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input item name!",
          },
        ]}
        style={{ width: "100%" }}
        className={Styles.flex}
      >
        <Input style={{ width: "100%" }} maxLength={90} />
      </Form.Item>
      <Form.Item
        label="Platform"
        className={Styles.platforms}
        initialValue={devices}
        valuePropName="checked"
        required
      >
        <Checkbox value="all" onChange={setPlatform} checked={devices.includes("all")}>
          All
        </Checkbox>
        <Checkbox onChange={setPlatform} value="pc" checked={devices.includes("pc")}>
          Desktop
        </Checkbox>
        <Checkbox onChange={setPlatform} value="android" checked={devices.includes("android")}>
          Android
        </Checkbox>
        <Checkbox onChange={setPlatform} value="ios" checked={devices.includes("ios")}>
          IOS
        </Checkbox>
      </Form.Item>
      {isEmptydevices && (
        <div
          style={{
            color: "red",
            position: "absolute",
            transform: "translate(0px, -15px)",
          }}
        >
          Choose min one platform
        </div>
      )}
      <Form.Item
        label="Item type"
        name="type"
        rules={[
          {
            required: true,
            message: "Select item type",
          },
        ]}
        className={Styles.platforms}
        initialValue={itemType}
      >
        <Radio.Group onChange={e => setItemType(e.target.value)} value={marketType}>
          <Radio value="REGULAR" defaultChecked>
            Regular
          </Radio>
          <Radio value="PREMIUM">Premium Only</Radio>
          <Radio value="BUFF_PAY">BuffPay Only</Radio>
          <Radio value="BUFF_PLAY">BuffPlay Only</Radio>
        </Radio.Group>
      </Form.Item>
      <div className={Styles.grid}>
        {(itemType !== "PREMIUM") && (
          <Form.Item
            rules={[
              {
                required: true,
                type: "number",
                min: 1,
                message: "Input price value",
              },
            ]}
            label="Price"
            name="price"
            className={Styles.subgrid}
            initialValue={(price || 0) * 100}
          >
            <InputNumber min={1} max={100000} style={{ width: "100%" }} />
          </Form.Item>
        )}
        <Form.Item
          label="Premium Price"
          name="premiumPrice"
          initialValue={(premiumPrice || 0) * 100}
          rules={[
            {
              required: true,
              type: "number",
              min: 1,
              message: "Input premium price value",
            },
          ]}
        >
          <InputNumber min={1} max={100000} style={{ width: "100%" }} />
        </Form.Item>
      </div>

      <Form.Item
        label="Category"
        name="categoryId"
        initialValue={
          editType === "edit" ? categories.find(el => el.id === itemsCategory?.id)?.id : ""
        }
        rules={[
          {
            required: true,
            message: "Select category type",
          },
        ]}
        className={Styles.flex}
      >
        <Select placeholder="Choose a category" style={{ width: "100%" }}>
          {categories.map(category => {
            return (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Tier limitations"
        name="tierId"
        className={Styles.flex}
        style={{ width: "100%" }}
        initialValue={
          editType === "edit"
            ? tiers.find(el => el.id === tierId)?.id
            : tiers.find(el => el.name.toLocaleLowerCase() === "beginner")?.id
        }
        rules={[
          {
            required: true,
            message: "Select tier",
          },
        ]}
      >
        <Select placeholder="Please select" style={{ width: "100%" }}>
          {tiers.map(tier => {
            return (
              <Option key={tier.id} value={tier.id}>
                {tier.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Geo Limitations"
        name="geoGroup"
        initialValue={geoGroup}
        className={Styles.flex}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Region"
        name="regionId"
        className={Styles.flex}
        style={{ width: "100%" }}
        initialValue={regionId}
        rules={[
          {
            required: true,
            message: "Select region",
          },
        ]}
      >
        <Select placeholder="Please select" style={{ width: "100%" }}>
          {regions?.map(region => {
            return (
              <Option key={region.id} value={region.id}>
                {region.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Sensitive by GEO"
        style={{ marginBottom: "14px" }}
        initialValue={isGeoSensitive}
      >
        <Switch checked={isGeoSensitive} onChange={() => setisGeoSensitive(!isGeoSensitive)} />
      </Form.Item>
      <Form.Item
        label="Giftoin Campaign ID"
        name="giftoinCampaignId"
        initialValue={giftoinCampaignId}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        initialValue={description}
        className={Styles.flexTextarea}
        rules={[
          {
            required: true,
            message: "Input description!",
          },
        ]}
      >
        <Input.TextArea
          className={Styles.textarea}
          showCount
          autoSize={{ minRows: 2 }}
          maxLength={250}
        />
      </Form.Item>

      <Form.Item
        label="How to redeem"
        name="howToRedeem"
        initialValue={howToRedeem}
        className={Styles.flexTextarea}
      >
        <Input.TextArea
          className={Styles.textarea}
          autoSize={{ minRows: 2 }}
          showCount
          maxLength={500}
        />
      </Form.Item>
      <div className={Styles.flex1}>
        <Form.Item
          label="Out of stock option:"
          name="isOutOfStock"
          valuePropName="checked"
          style={{ marginBottom: "14px" }}
          initialValue={canBeOutOfStock}
        >
          <Switch
            onChange={() => {
              setCanBeOutOfStock(!canBeOutOfStock)
            }}
          />
        </Form.Item>
      </div>

      {canBeOutOfStock && (
        <>
          <Form.Item
            label="Number of daily restocks"
            name="numberOfDailyRestocks"
            initialValue={outOfStockItems?.numberOfDailyRestocks || 1}
          >
            <Select style={{ width: "228px" }}>
              <Option value={1} key={0}>
                1
              </Option>
              <Option value={2} key={1}>
                2
              </Option>
              <Option value={3} key={2}>
                3
              </Option>
              <Option value={4} key={3}>
                4
              </Option>
              <Option value={6} key={4}>
                6
              </Option>
              <Option value={8} key={5}>
                8
              </Option>
              <Option value={12} key={6}>
                12
              </Option>
              <Option value={24} key={7}>
                24
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Number items to deactivate"
            name="numberOfItemsToDeactivate"
            initialValue={outOfStockItems?.numberOfItemsToDeactivate || 1}
          >
            <InputNumber style={{ width: "228px" }} />
          </Form.Item>
        </>
      )}

      <Form.Item
        label="Item provider:"
        name="itemProviderId"
        className={Styles.flex}
        initialValue={String(selectedItemProviderId) || ""}
      >
        <Select
          placeholder="Please select"
          onChange={data => {
            setSelectedItemProviderId(String(data))
          }}
        >
          <Option key={0} value="">
            Internal Item
          </Option>
          {itemProviders.map(itemProvider => {
            return (
              <Option key={itemProvider.id} value={String(itemProvider.id)}>
                {itemProvider.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      {selectedItemProviderId && (
        <>
          <Form.Item
            label="Provider product ID"
            name="itemId"
            initialValue={providerItem?.itemId}
            rules={[
              {
                required: true,
                message: "Please input provider product ID",
              },
            ]}
          >
            <Input
              onChange={e => setProductId(e.target.value)}
              style={{ borderColor: isEmptyError ? "red" : "#d9d9d9" }}
              value={productId}
            />
          </Form.Item>
          <Form.Item label="Dedicated items" name="providerItemAmount" initialValue={giftCards}>
            <InputNumber />
          </Form.Item>
          {itemProviders.find(i => i.id === selectedItemProviderId)?.name === "kinguin" && (
            <Form.Item
              label="Kinguin max price we are ready to pay (In EURO)"
              name="maxPrice"
              initialValue={maxPrice}
            >
              <InputNumber
                style={{ width: "100%", borderColor: isVisibleError ? "red" : "#d9d9d9" }}
                value={maxPrice}
                onChange={(e: any) => setmaxPrice(e)}
              />
              {isVisibleError && (
                <div
                  style={{ color: "red" }}
                >{`Minimal price for item is ${itemProviderStore.itemInfo?.item.price}`}
                </div>
              )}
            </Form.Item>
          )}
          {itemProviders.find(i => i.id === selectedItemProviderId)?.name === "wegift" && (
            <Form.Item
              label="Wegift item value"
              name="maxPrice"
              initialValue={providerItem?.price}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          )}

          {itemProviders.find(i => i.id === selectedItemProviderId)?.name === "gcow"
              && itemProviderStore.itemInfo?.item?.type
              && itemProviderStore.itemInfo?.item?.type !== "fixed" && (
            <Form.Item
              label="GCOW item value"
              name="maxPrice"
              initialValue={providerItem?.price}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          )}
          {itemProviders.find(i => i.id === selectedItemProviderId)?.name === "kinguin" && editType === "create" && (
            <div style={{ fontSize: "12px", color: "red" }}>
              API will find item in kinguin API and will set price automatically*
            </div>)}
          {itemProviders.find(i => i.id === selectedItemProviderId)?.name === "wegift" && (
            <div style={{ fontSize: "12px", color: "red" }}>
              Wegift item info shows max price from API*
            </div>
          )}
          {itemProviders.find(i => i.id === selectedItemProviderId)?.name === "gcow" && (
            <div style={{ fontSize: "12px", color: "red" }}>
              GCOW item provider have unique id for each item*
            </div>
          )}

          <Button
            style={{ backgroundColor: "green", color: "white", borderColor: "white" }}
            onClick={handleGetItemInfo}
          >
            Find item in provider
          </Button>
          <ItemInfo type="find" />
        </>
      )}

      <div className={Styles.flex}>
        {isEmptyImage && (
          <div
            style={{
              color: "red",
              position: "absolute",
              transform: "translate(0px, -15px)",
            }}
          >
            Image is required
          </div>
        )}
        <UploadComponent
          image={image}
          setImage={setImage}
          uploadStyle={{ className: "avatar-uploader", listType: "picture" }}
          placeholder="Upload item image"
        />
      </div>
      <Form.Item initialValue={active} label="Is Active" name="active" valuePropName="checked">
        <Switch defaultChecked={true} />
      </Form.Item>
      <SubmitCancelButtons onClose={onClose} isLoading={marketplaceStore.isLoading} />
    </Form>
  )
})
