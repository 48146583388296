import { apiCall } from "api-calls/api-call"

export const sendEmailNotification = async(
  templateId: string,
  tagId: string,
  senderName: string,
  senderEmail: string,
  file: any,
) => {
  const formData = new FormData()
  formData.append("file", file)

  return apiCall({
    method: "POST",
    path: `email/send-notifications?senderName=${senderName}&senderEmail=${senderEmail}&templateId=${templateId}&notificationTag=${tagId}`,
    formData,
  })
}
