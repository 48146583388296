import { apiCall } from "api-calls/api-call"
import { GetItemsFilter, ItemToUpdate, MarketplaceRegion } from "domains/marketplace/store/types"

export const getMarketplaceRegion = async(
  input: GetItemsFilter,
  page: number,
  limit: number,
): Promise<{ total: number; data: MarketplaceRegion[] }> => {
  const query: { page: number; limit: number } & GetItemsFilter = {
    page,
    limit,
    ...input,
  }

  return apiCall({
    method: "GET",
    path: "marketplace-regions",
    query,
  })
}

export const createMarketplaceRegion = async(input: ItemToUpdate): Promise<void> => {
  return apiCall({
    method: "POST",
    path: "marketplace-regions",
    body: input,
  })
}

export const updateMarketplaceRegion = async(id: string, input: ItemToUpdate): Promise<void> => {
  return apiCall({
    method: "PUT",
    path: `marketplace-regions/${id}`,
    body: input,
  })
}
export const deleteMarketplaceRegion = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `marketplace-regions/${id}`,
  })
}

