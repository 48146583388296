import React, { FC, useState } from "react"
import { observer } from "mobx-react"
import { Button, Form, Input, Modal } from "antd"
import { useStore } from "stores/utils/use-store"
import { SubmitCancelButtons } from "components"

type AvailableActions = "update" | "delete";

export const UpdateDeleteKey: FC<{
  visible: boolean;
  onClose: () => void;
}> = observer(({ visible, onClose }) => {
  const {
    dataStore: {
      giftCardsStore: { selectedElement },
      giftCardsStore,
    },
  } = useStore()
  const onFinishHandler = async(el: { giftCode: string }) => {
    giftCardsStore.funcAfterSuccess = async() => {
      await giftCardsStore.getData()
      onClose()
      selectAction(null)
    }
    await giftCardsStore.update(el)
  }

  const [selectedAction, selectAction] = useState<AvailableActions | null>()
  const closeModal = () => {
    selectAction(null)
    onClose()
  }

  return (
    <Modal visible={visible} title="Modify Key" footer={null} onCancel={closeModal}>
      {!selectedAction ? (
        <>
          <Button onClick={() => selectAction("update")}>Update</Button>
          <Button onClick={() => selectAction("delete")} disabled={!!selectedElement.accountId}>
            Delete
          </Button>
        </>
      ) : (
        <div />
      )}
      {selectedAction === "update" && (
        <Form onFinish={onFinishHandler}>
          <Form.Item
            label="New Gift Code"
            name="giftCode"
            rules={[
              {
                required: true,
                message: "Please input New Gift Code!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <SubmitCancelButtons onClose={onClose} isLoading={giftCardsStore.isLoading} />
        </Form>
      )}
      <Modal
        title="Delete Key?"
        visible={selectedAction === "delete"}
        onOk={async() => {
          giftCardsStore.funcAfterSuccess = async() => {
            await giftCardsStore.getData()
            onClose()
            selectAction(null)
          }
          await giftCardsStore.delete()
        }}
        onCancel={() => {
          selectAction(null)
        }}
      >
        Are you sure you want delete this key?
      </Modal>
    </Modal>
  )
})
