import { Form, Select, Tag, Tooltip } from "antd"
import { useForm } from "antd/lib/form/Form"
import { NamePath } from "antd/lib/form/interface"
import { FC } from "react"
import { COUNTRY_CODES } from "utils/countryCodes"

interface GeoPickerElementProps {
    label?: string;
    name: NamePath;
    initialValue?: string[];
    style?: React.CSSProperties;
    disabled?: boolean;
    onChange?: (value: string[]) => void;

}

const GeoPickerElement: FC<GeoPickerElementProps> = ({ label, name, initialValue, style, disabled, onChange }) => {
  const [form] = useForm()

  return (
    <Form.Item label={label} name={name} initialValue={initialValue} style={{ width: "100%" }}>
      <Select
        mode="tags"
        disabled={disabled}
        allowClear
        style={{ width: "100%", ...style }}
        onChange={(value: string[]) => {
          form.setFieldValue(name, value)
          onChange?.(value)
        }}
        placeholder="No countries selected"
        options={COUNTRY_CODES.map(({ code, name }) => ({ label: `${name} (${code})`, value: code }))}
        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        tagRender={({ closable, value, label, onClose }) => {
          const handleMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault()
            event.stopPropagation()
          }

          return (
            <Tooltip title={label}>
              <Tag
                onMouseDown={handleMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3, cursor: "default" }}
              >
                {value}
              </Tag>
            </Tooltip>
          )
        }}
      />
    </Form.Item>
  )
}

export default GeoPickerElement
