import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  InsertImage,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor"
import { DatePicker, Form, FormInstance, Select, Switch } from "antd"
import { FormElement } from "components"
import GeoPickerElement from "components/geoPicker"
import { ContestConfig } from "domains/managingJson/store/types"
import { NotificationsPerGame } from "domains/specialEvents/pages/main"
import moment from "moment"
import { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"

type contestType = "CHALLENGE_BASED" | "SCORE_BASED" | undefined

export const MainInfoTab: FC<{
  form: FormInstance<any>,
  setLeaderboardWithCountry: (a: boolean) => void
}> = ({
  form,
  setLeaderboardWithCountry,
}) => {

  const contestTypes: contestType[] = ["CHALLENGE_BASED", "SCORE_BASED"]

  const {
    dataStore: {
      managingJsonStore: { selectedElement },
      managingJsonStore,
    },
  } = useStore()
  const data = selectedElement.data as ContestConfig
  const name = selectedElement.name

  const { dataStore: { filesStore } } = useStore()

  const [selectedContestType, setSelectedContestType] = useState(managingJsonStore.selectedElement.data["contestType"])

  const updateContestType = (value: number) => {
    managingJsonStore.selectedElement.data["contestType"] = contestTypes[value]
    setSelectedContestType(contestTypes[value])
  }

  const handleCountryChange = (selectedCountryCodes: string[]) => {
    const selectedCountryCodesWithGlobal = ["global", ...selectedCountryCodes]
    const notificationsPerGame: NotificationsPerGame[] = form.getFieldValue(["notificationsPerGame"]) || []
    const updatedNotificationsPerGame = notificationsPerGame.map(notificationPerGame => {
      const updatedNotifications = notificationPerGame.notifications?.map(notification => {
        const updatedImagesByGeo = selectedCountryCodesWithGlobal.map(countryCode => {
          // eslint-disable-next-line max-len
          const existingImageByGeo = notification.sourceImagesByGeo?.find(imageByGeo => imageByGeo.country === countryCode)

          return existingImageByGeo || { country: countryCode, imageSources: [] }
        })

        return { ...notification, sourceImagesByGeo: updatedImagesByGeo }
      })

      return { ...notificationPerGame, notifications: updatedNotifications }
    })

    form.setFieldsValue({ notificationsPerGame: updatedNotificationsPerGame })
  }


  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
      <div style={{ background: "white", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: 500, marginBottom: "16px" }}>Main Info</div>
        <div style={{ display: "flex", background: "white" }}>
          <div style={{ width: "30%", marginRight: "16px" }}>
              Name*
            <FormElement type="readOnly" label="" name="name" initialValue={name} required />
          </div>
          <div style={{ width: "30%", marginRight: "16px" }}>
              Pretty Name
            <FormElement type="input" label="" name="prettyName" initialValue={data.prettyName} />
          </div>
          <div style={{ width: "30%" }}>
              Type of Contest
            <div>
              <Select value={selectedContestType} onChange={e => updateContestType(e as any)} style={{ width: "100%" }} disabled>
                {Object.entries(contestTypes)
                  .map(el => <Select.Option key={el[0]} value={el[0]}>{el[1]}</Select.Option>)}
              </Select>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <GeoPickerElement
            onChange={handleCountryChange}
            label="Whitelisted Countries"
            name="countries"
            initialValue={data["countries"]}
          />
          {selectedContestType === "CHALLENGE_BASED" && (
            <>
              <div style={{ width: "30%", marginRight: "16px" }}>
                Start Date
                <Form.Item
                  label=""
                  name="contestStartDate"
                  style={{ width: "100%" }}
                  initialValue={moment(data.startDate)}
                >
                  <DatePicker
                    format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
                    value={moment(data.startDate)}
                    onChange={e => {
                      if (e) {
                        data.startDate = e.toDate()
                      }
                    }}
                    showTime
                    placeholder="Select Start Date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <div style={{ width: "30%", marginRight: "16px" }}>
                End Date
                <Form.Item
                  label=""
                  name="contestEndDate"
                  style={{ width: "100%" }}
                  initialValue={moment(data.endDate)}
                >
                  <DatePicker
                    format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
                    value={moment(data.endDate)}
                    onChange={e => {
                      if (e) {
                        data.endDate = e.toDate()
                      }
                    }}
                    showTime
                    placeholder="Select End Date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", marginBottom: 24 }}>
        <div style={{ background: "white", padding: "24px 16px", width: "49%", marginRight: "auto" }}>
          <div style={{ fontWeight: 500, marginBottom: "16px" }}>Links</div>
          <div style={{ display: "flex", background: "white" }}>
            <div style={{ width: "49%", marginRight: "16px" }}>
              Terms and Conditions Link
              <FormElement type="input" label="" name="termsAndCondition" initialValue={data.termsAndConditionsLink} />
            </div>
            <div style={{ width: "49%" }}>
              Support Link
              <FormElement type="input" label="" name="support" initialValue={data.supportLink} />
            </div>
          </div>
          <div style={{ width: "100%" }}>
              FAQ Link
            <FormElement type="input" label="" name="faq" initialValue={data.faqLink} />
          </div>
        </div>
        {selectedContestType === "SCORE_BASED" && (
          <div style={{ background: "white", padding: "24px 16px", width: "49%" }}>
            <div style={{ fontWeight: 500, marginBottom: "16px" }}>Additional</div>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <div style={{ marginRight: "auto" }}>Group Leaderboard by Country</div>
              <Switch
                style={{ marginRight: "20%" }}
                defaultChecked={data.leaderboardWithCountry}
                onChange={e => {
                  data.leaderboardWithCountry = e
                  setLeaderboardWithCountry(e)
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <div style={{ marginRight: "auto" }}>Show all time leaderboard</div>
              <Switch
                style={{ marginRight: "20%" }}
                defaultChecked={data.showAllTimeLeaderboard}
                onChange={e => {
                  data.showAllTimeLeaderboard = e
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <div style={{ marginRight: "auto" }}>In-app ADS Block </div>
              <Switch
                style={{ marginRight: "20%" }}
                defaultChecked={data.ignoreAdsHiding}
                onChange={e => {
                  data.ignoreAdsHiding = e
                }}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <div style={{ marginRight: "auto" }}>Leaderboard fullheight sidebar </div>
              <Switch
                style={{ marginRight: "20%" }}
                defaultChecked={data.leaderboardFullheightSidebar}
                onChange={e => {
                  data.leaderboardFullheightSidebar = e
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ background: "white", padding: "24px 16px", width: "100%", marginRight: "auto" }}>
          <div style={{ fontWeight: 500, marginBottom: "16px", display: "flex", gap: 8 }}>
            How it works (tab)
            <Switch
              style={{ marginRight: "20%" }}
              defaultChecked={data.howItWorksTabEnabled}
              onChange={e => {
                data.howItWorksTabEnabled = e
              }}
            />
          </div>
          <MDXEditor
            markdown={data.howItWorksContent ?? ""}
            onChange={text => {
              data.howItWorksContent = text
            }}
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              linkDialogPlugin(),
              linkPlugin(),
              imagePlugin({
                imageUploadHandler: async file => {
                  const formData = new FormData()
                  formData.append("file", file)
                  const url = await filesStore.uploadImageAndGetUrl(formData)

                  return url
                },
              }),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <BlockTypeSelect />
                    <BoldItalicUnderlineToggles />
                    <CreateLink />
                    <ListsToggle />
                    <InsertImage />
                    <UndoRedo />
                  </>
                ),
              }),
            ]}
          />
        </div>
      </div>
    </div>
  )

}
