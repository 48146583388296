import { EntityToUpdate } from "abstract/entity-to-update"

export type ParentCodeType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  parentCode: string;
  codeSize: number;
  childPromoCodesLimit: number;
  startDate: Date;
  endDate: Date;
  countries: string[];
  promoCodeType: "PUBLIC" | "PERSONALIZED";
  promoPrizeTypeId: string;
  successMessage: { header: string; body: string };
  promoGroupId: string;
  numberOfCodes?: number;
  numberOfRedeems?: number;
  prizePayload: any;
};

export type ParentToUpdate = EntityToUpdate<
  Omit<ParentCodeType, ""> & {
    date: Date[];
    code?: string;
    prefix?: string;
    // successMessageHeader: string,
    // successMessageBody: string,
  }
>;

export type ChildCodeType = {
  id: string;
  code: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
};

export type ChildToUpdate = EntityToUpdate<
  ChildCodeType & {
    status?: string;
    parentPromoCodeId: string;
  }
>;

export type GetItemsFilter = {
  name?: string;
  parentPromoCodeId?: string;
  promoGroupId?: string;
  parentPromoCodeGroupId?: string;
  timeStatus?: "ACTIVE" | "EXPIRED";
};

export type PromocodeGroup = {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  groupPayload: {
    availableDaysAfterRegistration: number;
    maxNumberOfRedeems: number;
  };
  groupRuleId: string;
};

export type GroupToUpdate = EntityToUpdate<
  Omit<PromocodeGroup, "groupPayload"> & {
    name: string;
    availableDaysAfterRegistration?: number;
    maxNumberOfRedeems?: number;
    groupRuleId?: string;
  }
>;

export interface PromocodeResponse {
  success: boolean;
  message: string;
  statusCode: number;
  item: ParentCodeType;
}
export interface GroupResponse {
  success: boolean;
  message: string;
  statusCode: number;
  item: PromocodeGroup;
}

export type PrizeType = "COINS" | "PREMIUM" | "RAFFLES";

export type ParentPrize = {
  id: string;
  type: PrizeType;
};

export type GroupRule = {
  id: string;
  groupRuleType: string;
};

export const buffTransactionTypeArr = ["AVATAR_VIDEO_REWARD", "ADS_REWARD"] as const
export const typeRewardArr = [
  "BUFFS",
  "BOOSTER",
  "PREMIUM",
  "PREMIUM_PLUS",
  "PREMIUM_ELITE",
  "MARKETPLACE_DISCOUNT",
  "RAFFLE_TICKET",
  "CODE_STORAGE",
] as const

export type RewardType = {
  id: string;
  type: typeof typeRewardArr[number];
  transactionName: string;
  eventTitle: string;

  buffTransactionType: typeof buffTransactionTypeArr[number];

  countOfPrizes: number;
  confirm: boolean;

  rewardCondition: {
    duration?: number;
    periodType: "HOURS" | "DAYS";
    percentage?: number;

    id?: string;
    rewardTypeId?: string;
    createdAt?: string;
    updatedAt?: string;
  };
};

export type RewardToUpdate = EntityToUpdate<Omit<RewardType, "groupPayload">>;

export type RewardPrize = {
  id: string;
  name: string;
};

export interface RewardResponse {
  success: boolean;
  message: string;
  statusCode: number;
  item: RewardType;
}

export type RewardBuffType = {
  id: string;
  type: string;
  name: string;
};
