import { useCallback, useEffect, useMemo, useState } from "react"
import { listExternalOfferProviders } from "../api"
import { IExternalOfferProvider } from "../api/types"

const useExternalProviders = () => {
  const [unsortedProviders, setProviders] = useState<IExternalOfferProvider[]>()

  useEffect(() => {
    listExternalOfferProviders().then(setProviders)
  }, [])

  const updateProvider = useCallback((provider: Partial<IExternalOfferProvider>) => {
    setProviders(prev => prev?.map(p => p.id === provider.id ? { ...p, ...provider } : p))
  }, [])

  const providers = useMemo(() => {
    if (!unsortedProviders) return unsortedProviders
    const copy = [...unsortedProviders]
    // sort by priority
    copy.sort((a, b) => b.priority - a.priority)

    return copy
  }, [unsortedProviders])

  return {
    providers,
    updateProvider,
  }
}

export default useExternalProviders
