import React, { Fragment, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Button, Checkbox, Form, Input, Select } from "antd"
import { observer } from "mobx-react"

import { notifications } from "notifications"
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { InputColor, UploadComponent, DefaultLinksRouting } from "components"
import { DynamicUIType } from "domains/managingJson/store/types"
import { RulesInviteFriends } from "./RulesInviteFriends"

const { Option } = Select

export const DesktopDynamicUI = observer(() => {
  const { dataStore: { managingJsonStore, marketplaceRegionsStore } } = useStore()
  const [prevData, setPrevData] = useState<DynamicUIType>()

  const [form] = Form.useForm<DynamicUIType>()

  // TODO (PeterS): There should not be a state for each single variable -
  // all form state should be handled inside `form` instance, not in separate states

  const [dynamicUI, setdynamicUI] = useState(false)
  // topbar
  const [topbarSettingsBool, setTopbarSettingsBool] = useState(false)
  const [topbarSettingsImage, setTopbarSettingsImage] = useState("")

  // topbar
  const [buffboxImages, setBuffboxImages] = useState(false)
  const [buffboxImagesLinks, setBuffboxImagesLinks] = useState({ openedBox: "", closedBox: "" })
  const [buffboxBackdropImage, setBuffboxBackdropImage] = useState("")
  const [buffboxBackgroundGlowingBlobs, setBuffboxBackgroundGlowingBlobs] = useState("")

  // topbar colors
  const [topbarSettingsColors, setTopbarSettingsColors] = useState(false)
  const [topbarSettingsMainColor, setTopbarSettingsMainColor] = useState("")
  const [topbarSettingsSecondColor, setTopbarSettingsSecondColor] = useState("")

  // lounge varous task banner
  const [loungeVariousTask, setloungeVariousTask] = useState(false)
  const [loungeVariousTaskImage, setloungeVariousTaskImage] = useState("")

  // avatar mask
  const [avatarMasks, setAvatarMasks] = useState(false)
  const [avatarMasksImages, setAvatarMasksImages] = useState([""])

  // cursor updates
  const [cursor, setCursor] = useState(false)
  const [cursorImage, setCursorImage] = useState("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cursorImagePointer, setCursorImagePointer] = useState("")

  // market mask
  const [marketMask, setMarketMask] = useState(false)
  const [marketMaskImage, setMarketMaskImage] = useState("")

  // market geo value
  const [marketGeoValue, setMarketGeoValue] = useState(false)
  const [marketGeoValueImage, setMarketGeoValueImage] = useState("")
  const [marketGeoValueString, setMarketGeoValueString] = useState("")

  // background image
  const [background, setBackground] = useState(false)
  const [backgroundImage, setBackgroundImage] = useState("")

  // background image popups
  const [backgroundPopups, setBackgroundPopups] = useState(false)
  const [backgroundImagePopups, setBackgroundImagePopups] = useState("")

  // challenge banner
  const [challengeBanner, setchallengeBanner] = useState(false)
  const [challengeBannerValueImage, setchallengeBannerValueImage] = useState("")
  const [challengeBannerCTA, setchallengeBannerCTA] = useState("")
  // buffpay for israel
  const [buffPayILState, setbuffPayILState] = useState(false)

  // Buff Pay Israel Section Banner
  const [buffPayIL, setbuffPayIL] = useState(false)
  const [buffPayILValueImage, setbuffPayILValueImage] = useState("")
  const [buffPayILCTA, setbuffPayILCTA] = useState("")

  // snow animation
  const [fallAnimationState, setfallAnimationState] = useState(false)
  const [fallAnimationImage, setfallAnimationImage] = useState("")
  const [fallAnimationImageHeight, setfallAnimationImageHeight] = useState("")
  const [fallAnimationImageCount, setfallAnimationImageCount] = useState("")

  // invite Friends Texts popup
  const [inviteFriendsTextsState, setinviteFriendsTextsState] = useState(false)
  const [inviteFriendsRulesState, setinviteFriendsRulesState] = useState<string | undefined>("")
  const [inviteFriendsTooltipDataState, setinviteFriendsTooltipDataState] = useState<string[]>([
    "",
    "",
    "",
  ])
  const [paypalOnButton, setpaypalOnButton] = useState(false)

  const [ingameScreenAdType, setIngameScreenAdType] = useState<DynamicUIType["ingameScreenAdType"]>("tall")

  useEffect(() => {
    managingJsonStore.getData()
    marketplaceRegionsStore.getData()
  }, [managingJsonStore, marketplaceRegionsStore])

  useEffect(() => {
    const dynamicUIData = managingJsonStore?.itemTypes?.filter(item => item.type === "dynamicUI")
    if (dynamicUIData && dynamicUIData.length > 0) {
      const data = dynamicUIData[0].data as DynamicUIType
      setPrevData(data)
      setdynamicUI(true)
      if (data.topbarSettingsImage) {
        setTopbarSettingsBool(true)
        setTopbarSettingsImage(data.topbarSettingsImage)
      }
      if (data.topbarSettingsMainColor || data.topbarSettingsSecondColor) {
        setTopbarSettingsColors(true)
        setTopbarSettingsMainColor(data.topbarSettingsMainColor || "")
        setTopbarSettingsSecondColor(data.topbarSettingsSecondColor || "")
      }

      if (data.loungeVariousTaskImage) {
        setloungeVariousTask(true)
        setloungeVariousTaskImage(data.loungeVariousTaskImage)
      }

      if (data.avatarMasksImages) {
        setAvatarMasks(true)
        setAvatarMasksImages(data.avatarMasksImages)
      }
      if (data.cursorImage || data.cursorImagePointer) {
        setCursor(true)
        setCursorImage(data.cursorImage || "")
        setCursorImagePointer(data.cursorImagePointer || "")
      }

      if (data.marketMaskImage) {
        setMarketMask(true)
        setMarketMaskImage(data.marketMaskImage)
      }

      if (data.marketGeoValueImage || data.marketGeoValueString) {
        setMarketGeoValue(true)
        setMarketGeoValueImage(data.marketGeoValueImage || "")
        setMarketGeoValueString(data.marketGeoValueString || "")
      }
      if (data.backgroundImage) {
        setBackground(true)
        setBackgroundImage(data.backgroundImage)
      }
      if (data.backgroundImagePopups) {
        setBackgroundPopups(true)
        setBackgroundImagePopups(data.backgroundImagePopups)
      }

      if (data.challengeBannerCTA || data.challengeBannerValueImage) {
        setchallengeBanner(true)
        setchallengeBannerCTA(data.challengeBannerCTA || "")
        setchallengeBannerValueImage(data.challengeBannerValueImage || "")
      }

      if (data.buffPayILCTA || data.buffPayILValueImage) {
        setbuffPayIL(true)
        setbuffPayILCTA(data.buffPayILCTA || "")
        setbuffPayILValueImage(data.buffPayILValueImage || "")
      }
      if (data.buffPayILState) {
        setbuffPayILState(data.buffPayILState)
      }

      if (data.inviteFriendsRules || data.inviteFriendsTooltipData) {
        setinviteFriendsRulesState(data.inviteFriendsRules || "")
        setinviteFriendsTextsState(true)
        setinviteFriendsTooltipDataState(data.inviteFriendsTooltipData || ["", "", ""])
      }
      if (data.paypalOnButtonState) {
        setpaypalOnButton(true)
      }

      if (
        data.fallAnimation
        || data.fallAnimationImage
        || data.fallAnimationImageHeight
        || data.fallAnimationImageCount
      ) {
        setfallAnimationState(data.fallAnimation || false)
        setfallAnimationImage(data.fallAnimationImage || "")
        setfallAnimationImageHeight(data.fallAnimationImageHeight || "")
        setfallAnimationImageCount(data.fallAnimationImageCount || "")
      }
      if (data.ingameScreenAdType) {
        setIngameScreenAdType(data.ingameScreenAdType)

      }

      if (data.buffboxImagesLinks) {
        setBuffboxImagesLinks(data.buffboxImagesLinks)
        setBuffboxImages(true)
      }

      if (data.buffboxBackdropImage) {
        setBuffboxBackdropImage(data.buffboxBackdropImage)
        setBuffboxImages(true)
      }

      if (data.buffboxBackgroundGlowingBlobs) {
        setBuffboxBackgroundGlowingBlobs(data.buffboxBackgroundGlowingBlobs)
        setBuffboxImages(true)
      }
      form.setFieldValue("startgameScreenType", data?.startgameScreenType ?? "window")
    }
  }, [managingJsonStore.itemTypes])

  const onFinishHandler = async(event: DynamicUIType) => {
    const data: DynamicUIType = { ...prevData, ...event }
    data.topbarSettingsImage = (topbarSettingsBool && topbarSettingsImage) || undefined
    data.topbarSettingsMainColor = (topbarSettingsColors && topbarSettingsMainColor) || undefined
    data.topbarSettingsSecondColor
      = (topbarSettingsColors && topbarSettingsSecondColor) || undefined
    data.loungeVariousTaskImage = (loungeVariousTask && loungeVariousTaskImage) || undefined
    data.avatarMasksImages = (avatarMasks && avatarMasksImages) || undefined
    data.cursorImage = (cursor && cursorImage) || undefined

    data.marketMaskImage = (marketMask && marketMaskImage) || undefined
    data.marketGeoValueImage = (marketGeoValue && marketGeoValueImage) || undefined
    data.marketGeoValueString = (marketGeoValue && marketGeoValueString) || undefined
    data.challengeBannerCTA = (challengeBanner && event.challengeBannerCTA) || undefined
    data.challengeBannerValueImage = (challengeBanner && challengeBannerValueImage) || undefined
    data.buffPayILState = buffPayILState
    data.buffPayILCTA = (buffPayIL && event.buffPayILCTA) || undefined
    data.buffPayILValueImage = (buffPayIL && buffPayILValueImage) || undefined
    data.backgroundImage = (background && backgroundImage) || undefined
    data.backgroundImagePopups = (backgroundPopups && backgroundImagePopups) || undefined
    data.fallAnimation = fallAnimationState
    data.fallAnimationImage = (fallAnimationState && fallAnimationImage) || undefined
    data.fallAnimationImageHeight = (fallAnimationState && fallAnimationImageHeight) || undefined
    data.fallAnimationImageCount = (fallAnimationState && fallAnimationImageCount) || undefined
    data.inviteFriendsRules = (inviteFriendsTextsState && inviteFriendsRulesState) || undefined
    data.paypalOnButtonState = paypalOnButton
    data.ingameScreenAdType = ingameScreenAdType
    data.inviteFriendsTooltipData
      = (inviteFriendsTextsState && inviteFriendsTooltipDataState) || undefined
    data.buffboxImagesLinks = ((buffboxImagesLinks.closedBox === "" && buffboxImagesLinks.openedBox === "")
    || !buffboxImages) ? undefined : buffboxImagesLinks
    data.buffboxBackdropImage = (buffboxImages && buffboxBackdropImage) || undefined
    data.buffboxBackgroundGlowingBlobs = (buffboxImages && buffboxBackgroundGlowingBlobs) || undefined
    data.underMaintenance = event.underMaintenance || {}
    managingJsonStore.funcAfterSuccess = async() => {
      notifications.success("Saved successfully")
      await managingJsonStore.getData()
    }

    dynamicUI
      ? await managingJsonStore.update({
        name: "dynamicUI",
        type: "dynamicUI",
        data,
      })
      : await managingJsonStore.create({
        name: "dynamicUI",
        type: "dynamicUI",
        data,
      })
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (topbarSettingsBool) {
            setTopbarSettingsImage("")
          }
          setTopbarSettingsBool(!topbarSettingsBool)
        }}
        value={topbarSettingsBool}
        checked={topbarSettingsBool}
      >
        Topbar updates
      </Checkbox>
      {topbarSettingsBool && (
        <div style={{ display: "flex", gap: "10px" }}>
          <UploadComponent
            image={topbarSettingsImage}
            setImage={setTopbarSettingsImage}
            placeholder="Topbar Image"
            title="Topbar Image"
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          setBuffboxImages(!buffboxImages)
        }}
        value={buffboxImages}
        checked={buffboxImages}
      >
        Buffbox
      </Checkbox>
      {buffboxImages && (
        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <UploadComponent
              image={buffboxImagesLinks.openedBox}
              setImage={img => setBuffboxImagesLinks(prev => ({ ...prev, openedBox: img }))}
              placeholder="opened box"
              title="opened box"
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <UploadComponent
              image={buffboxImagesLinks.closedBox}
              setImage={img => setBuffboxImagesLinks(prev => ({ ...prev, closedBox: img }))}
              placeholder="closed box"
              title="closed box"
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <UploadComponent
              image={buffboxBackdropImage}
              setImage={setBuffboxBackdropImage}
              placeholder="Backdrop"
              title="Backdrop"
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <UploadComponent
              image={buffboxBackgroundGlowingBlobs}
              setImage={setBuffboxBackgroundGlowingBlobs}
              placeholder="Background with glowing blobs"
              title="Background with glowing blobs"
            />
          </div>
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (topbarSettingsColors) {
            setTopbarSettingsMainColor("")
            setTopbarSettingsSecondColor("")
          }
          setTopbarSettingsColors(!topbarSettingsColors)
        }}
        value={topbarSettingsColors}
        checked={topbarSettingsColors}
      >
        Topbar colors
      </Checkbox>
      {topbarSettingsColors && (
        <div style={{ display: "flex", gap: "10px" }}>
          <InputColor
            title="TopBar main-color-background"
            color={topbarSettingsMainColor}
            onChange={setTopbarSettingsMainColor}
          />
          <InputColor
            title="TopBar second-color-icons"
            color={topbarSettingsSecondColor}
            onChange={setTopbarSettingsSecondColor}
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (loungeVariousTask) {
            setloungeVariousTaskImage("")
          }
          setloungeVariousTask(!loungeVariousTask)
        }}
        value={loungeVariousTask}
        checked={loungeVariousTask}
      >
        Lounge varios task
      </Checkbox>
      {loungeVariousTask && (
        <UploadComponent
          image={loungeVariousTaskImage}
          setImage={setloungeVariousTaskImage}
          placeholder="Lounge varius task banner Image"
          title="Lounge varius task banner Image"
        />
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (avatarMasks) {
            setAvatarMasksImages([""])
          }
          setAvatarMasks(!avatarMasks)
        }}
        value={avatarMasks}
        checked={avatarMasks}
      >
        Avatar masks
      </Checkbox>
      {avatarMasks && (
        <div style={{ display: "flex" }}>
          {avatarMasksImages.map((item, indx) => (
            <Fragment key={indx}>
              <UploadComponent
                image={avatarMasksImages[indx]}
                setImage={(e: any) => {
                  const masks = [...avatarMasksImages]
                  masks[indx] = e
                  setAvatarMasksImages(masks)
                }}
                placeholder={`Avatar image ${indx + 1}`}
              />
              <CloseCircleOutlined
                onClick={() => {
                  const masks = [...avatarMasksImages]
                  masks.splice(indx, 1)
                  setAvatarMasksImages(masks)
                }}
              />
            </Fragment>
          ))}
          <div
            onClick={() => setAvatarMasksImages([...avatarMasksImages, ""])}
            style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}
          >
            <div>Add avatar</div>
            <PlusCircleOutlined />
          </div>
        </div>
      )}
      <br />
      {/* <Checkbox
          style={{ margin: '15px 0' }}
          onChange={() => {
            if (cursor) {
              setCursorImage('');
              setCursorImagePointer('');
            }
            setCursor(!cursor);
          }}
          value={cursor}
          checked={cursor}
        >
          Cursor images
        </Checkbox>
        {cursor && (
          <div style={{ display: 'flex' }}>
            <UploadComponent
              image={cursorImage}
              setImage={setCursorImage}
              placeholder={`Cursor image`}
            />
            <UploadComponent
              image={cursorImagePointer}
              setImage={setCursorImagePointer}
              placeholder={`Cursor image pointer`}
            />
          </div>
        )}
        <br /> */}
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (marketMask) {
            setMarketMaskImage("")
          }
          setMarketMask(!marketMask)
        }}
        value={marketMask}
        checked={marketMask}
      >
        Market mask
      </Checkbox>
      {marketMask && (
        <div style={{ display: "flex" }}>
          <UploadComponent
            image={marketMaskImage}
            setImage={setMarketMaskImage}
            placeholder="Market mask image"
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (marketGeoValue) {
            setMarketGeoValueImage("")
            setMarketGeoValueString("")
          }
          setMarketGeoValue(!marketGeoValue)
        }}
        value={marketGeoValue}
        checked={marketGeoValue}
      >
        Market geo value
      </Checkbox>
      {marketGeoValue && (
        <div style={{ display: "grid", gap: "10px" }}>
          <Select
            placeholder="Please select"
            style={{ width: "100%" }}
            onChange={setMarketGeoValueString}
            value={marketGeoValueString}
          >
            {marketplaceRegionsStore
              && marketplaceRegionsStore.regions
              && marketplaceRegionsStore.regions.map(region => {
                return (
                  <Option key={region.name} value={region.name}>
                    {region.name}
                  </Option>
                )
              })}
          </Select>
          <UploadComponent
            image={marketGeoValueImage}
            setImage={setMarketGeoValueImage}
            placeholder="Market geo image"
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (background) {
            setBackgroundImage("")
          }
          setBackground(!background)
        }}
        value={background}
        checked={background}
      >
        Background app
      </Checkbox>
      {background && (
        <div style={{ display: "flex" }}>
          <UploadComponent
            image={backgroundImage}
            setImage={setBackgroundImage}
            placeholder="Background image"
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (backgroundPopups) {
            setBackgroundImagePopups("")
          }
          setBackgroundPopups(!backgroundPopups)
        }}
        value={backgroundPopups}
        checked={backgroundPopups}
      >
        Background at popups
      </Checkbox>
      {backgroundPopups && (
        <div style={{ display: "flex" }}>
          <UploadComponent
            image={backgroundImagePopups}
            setImage={setBackgroundImagePopups}
            placeholder="Background at popups image"
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (challengeBanner) {
            setchallengeBannerValueImage("")
            setchallengeBannerCTA("")
            form.setFieldsValue({ challengeBannerCTA: "" })
          }
          setchallengeBanner(!challengeBanner)
        }}
        value={challengeBanner}
        checked={challengeBanner}
      >
        Challenge banner
      </Checkbox>
      {challengeBanner && (
        <div style={{ display: "grid", gap: "10px", width: "50%" }}>
          <DefaultLinksRouting
            link={challengeBannerCTA || ""}
            formItemName="challengeBannerCTA"
            notImportant
          />

          <UploadComponent
            image={challengeBannerValueImage}
            setImage={setchallengeBannerValueImage}
            placeholder="Challenge banner image"
          />
        </div>
      )}{" "}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          setbuffPayILState(!buffPayILState)
        }}
        value={buffPayILState}
        checked={buffPayILState}
      >
        Buff Pay Israel Section
      </Checkbox>
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (buffPayIL) {
            setbuffPayILValueImage("")
            setbuffPayILCTA("")
            form.setFieldsValue({ buffPayILCTA: "" })
          }
          setbuffPayIL(!buffPayIL)
        }}
        value={buffPayIL}
        checked={buffPayIL}
      >
        Buff Pay Israel Section Banner
      </Checkbox>
      {buffPayIL && (
        <div style={{ display: "grid", gap: "10px", width: "50%" }}>
          <DefaultLinksRouting
            link={buffPayILCTA || ""}
            formItemName="buffPayILCTA"
            notImportant
          />

          <UploadComponent
            image={buffPayILValueImage}
            setImage={setbuffPayILValueImage}
            placeholder="Buff Pay Israel Section Banner image"
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (fallAnimationState) {
            setfallAnimationImage("")
            setfallAnimationState(false)
            setfallAnimationImageHeight("")
            setfallAnimationImageCount("")
          }
          setfallAnimationState(!fallAnimationState)
        }}
        value={fallAnimationState}
        checked={fallAnimationState}
      >
        Fall animation
      </Checkbox>
      {fallAnimationState && (
        <div style={{ display: "flex", gap: "10px", width: "50%" }}>
          <UploadComponent
            image={fallAnimationImage}
            setImage={setfallAnimationImage}
            placeholder="Fall image"
          />
          <Input
            value={fallAnimationImageHeight}
            type="number"
            min={1}
            onChange={e => setfallAnimationImageHeight(e.target.value)}
            style={{ height: "32px", alignSelf: "self-end" }}
            placeholder="Max height of fall image"
          />
          <Input
            value={fallAnimationImageCount}
            type="number"
            min={1}
            onChange={e => setfallAnimationImageCount(e.target.value)}
            style={{ height: "32px", alignSelf: "self-end" }}
            placeholder="Max count of fall elements"
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (inviteFriendsTextsState) {
            setinviteFriendsRulesState("")
            setinviteFriendsTextsState(false)
            setinviteFriendsTooltipDataState(["", "", ""])
          }
          setinviteFriendsTextsState(!inviteFriendsTextsState)
        }}
        value={inviteFriendsTextsState}
        checked={inviteFriendsTextsState}
      >
        Invite friends dynamic texts
      </Checkbox>
      {inviteFriendsTextsState && (
        <div style={{ width: 800 }}>
          <RulesInviteFriends
            setparagraps={setinviteFriendsRulesState}
            data={inviteFriendsRulesState}
          />
          <Input
            value={inviteFriendsTooltipDataState[0]}
            placeholder="Tooltip 1"
            onChange={e => {
              const texts = [...inviteFriendsTooltipDataState]
              texts[0] = e.target.value
              setinviteFriendsTooltipDataState(texts)
            }}
          />
          <Input
            value={inviteFriendsTooltipDataState[1]}
            placeholder="Tooltip 2"
            onChange={e => {
              const texts = [...inviteFriendsTooltipDataState]
              texts[1] = e.target.value
              setinviteFriendsTooltipDataState(texts)
            }}
          />
          <Input
            placeholder="Tooltip 3"
            value={inviteFriendsTooltipDataState[2]}
            onChange={e => {
              const texts = [...inviteFriendsTooltipDataState]
              texts[2] = e.target.value
              setinviteFriendsTooltipDataState(texts)
            }}
          />
        </div>
      )}
      <br />
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          setpaypalOnButton(!paypalOnButton)
        }}
        value={paypalOnButton}
        checked={paypalOnButton}
      >
        Paypal on button
      </Checkbox>
      <br />
      <Form.Item label="Ingame Screen Ad type">
        <Select value={ingameScreenAdType} onChange={setIngameScreenAdType}>
          <Option value="short">Short (400*300)</Option>
          <Option value="tall">Tall (400*600)</Option>
        </Select>
      </Form.Item>
      <br />
      <Form.Item name="startgameScreenType" label="Startgame Screen Type">
        <Select>
          <Option value="window">Window (Old & Default)</Option>
          <Option value="notification">Notification (New)</Option>
        </Select>
      </Form.Item>
      <Button
        type="primary"
        onClick={() => {
          setTopbarSettingsBool(false)
          setTopbarSettingsColors(false)
          setloungeVariousTask(false)
          setAvatarMasks(false)
          setCursor(false)
          setMarketMask(false)
          setBackground(false)
          setBackgroundPopups(false)
          setchallengeBanner(false)
          setbuffPayIL(false)
          setbuffPayILState(false)
          setTopbarSettingsImage("")
          setTopbarSettingsMainColor("")
          setTopbarSettingsSecondColor("")
          setloungeVariousTaskImage("")
          setAvatarMasksImages([""])
          setCursorImage("")
          setCursorImagePointer("")
          setMarketMaskImage("")
          setBackgroundImage("")
          setBackgroundImagePopups("")
          setchallengeBannerCTA("")
          setchallengeBannerValueImage("")
          setbuffPayILCTA("")
          setbuffPayILValueImage("")
          setfallAnimationImage("")
          setfallAnimationState(false)
          setfallAnimationImageHeight("")
          setfallAnimationImageCount("")
          setMarketGeoValue(false)
          setMarketGeoValueImage("")
          setMarketGeoValueString("")
          setinviteFriendsTextsState(false)
          setinviteFriendsRulesState("")
          setinviteFriendsTooltipDataState(["", "", ""])
          setIngameScreenAdType("tall")
          setBuffboxImages(false)
          setBuffboxImagesLinks({ openedBox: "", closedBox: "" })

          form.setFieldsValue({ challengeBannerCTA: "", buffPayILCTA: "" })
        }}
      >
        Reset all
      </Button>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              cursor: managingJsonStore.isLoading ? "not-allowed" : "auto",
              backgroundColor: managingJsonStore.isLoading ? "#d9d9d9" : "#1690ff",
              borderColor: managingJsonStore.isLoading ? "#d9d9d9" : "#1690ff",
            }}
            disabled={managingJsonStore.isLoading}
          >
            Save
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
})
