import { Column } from "abstract/types/column.type"

type ColumnWithSorter = Column & { sorter?: boolean };

export const promocodesGroupsColumns: ColumnWithSorter[] = [
  {
    title: "Event title",
    key: "eventTitle",
    dataIndex: "eventTitle",
    fixed: true,
    sorter: true,
    width: "20%",
  },
  {
    title: "Transaction name",
    dataIndex: "transactionName",
    sorter: true,
    key: "transactionName",
    width: "20%",
  },
  {
    title: "Type",
    key: "type",
    dataIndex: "type",
    sorter: true,
    width: "15%",
  },
  {
    title: "Buff Transaction Type Name",
    dataIndex: "buffTransactionType",
    sorter: true,
    key: "buffTransactionType",
    width: "20%",
  },
  {
    title: "Reward prize",
    dataIndex: "countOfPrizes",
    sorter: true,
    width: "15%",
    key: "countOfPrizes",
  },
]
