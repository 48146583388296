import { FC, useState } from "react"
import { Button, Checkbox, Divider, Form, Input, Select, Typography } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { NotificationTypeCreation } from "../store/types"
import {
  UploadComponent,
  MobileView,
  DefaultLinksRouting,
  SubmitCancelButtons,
} from "components"
import Styles from "./styles.module.css"
import { notifications } from "notifications"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

export const NotificationCreate: FC<{ onClose: () => void }> = observer(({ onClose }) => {
  const { dataStore: { notificationsStore } } = useStore()
  const [form] = Form.useForm()
  const [isFull, setIsFull] = useState<boolean | undefined>()
  const [isSystem, setIsSystem] = useState<boolean | undefined>()
  const [withCTA, setwithCTA] = useState<boolean | undefined>()
  const [buttonIsRedirect, setbuttonIsRedirect] = useState<boolean | undefined>()

  const [type, setType] = useState("Notification")
  const [image, setImage] = useState<string>("")
  const [platforms, setplatforms] = useState<CheckboxValueType[]>([])
  const [icon, setIcon] = useState<string>("")
  const [uploadText, setUploadText] = useState("")
  const [description, setDescription] = useState("")
  const [listDescriptions, setListDescription] = useState<string[]>([])

  const [isShowDevice, setIsShowDevice] = useState(false)
  const [infoVisibleDevice, setInfoVisibleDevice] = useState({
    title: "",
    image: "",
    buttonName: "",
  })

  const onFinishHandler = async(e: NotificationTypeCreation) => {
    if (e.inAppNotification?.platforms === undefined) {
      return notifications.error("Choose mimimum 1 platform")
    }
    if (notificationsStore.notifications.find(item => item.title === e.title)) {
      return notifications.error("System title should be unique")
    }
    const data = {
      ...e,
      needToSend: true, // !!e.needToSend,
      inAppNotification: {
        ...e.inAppNotification,
        iconUrl: icon.length === 0 ? null : icon,
        imageUrl: image.length === 0 ? null : image,
        body: uploadText,
      },
    }
    notificationsStore.funcAfterSuccess = async() => {
      notifications.success("Saved success")
      onClose()
      form.resetFields()
      await notificationsStore.getData()
    }
    await notificationsStore.createNotification(data as NotificationTypeCreation)
  }
  const addParagraphOrItemList = (symbol: string) => {
    const concat = symbol.concat(description)
    listDescriptions.push(concat)

    setUploadText(listDescriptions.join(""))
    setDescription("")
  }

  return (
    <Form onFinish={onFinishHandler} form={form} className={Styles.form}>
      <Form.Item
        label="System title"
        name="title"
        rules={[
          {
            required: true,
            message: "System title is required",
          },
        ]}
      >
        <Input
          onInput={(e: any) => (e.target.value = e.target.value.replace(" ", "").toUpperCase())}
        />
      </Form.Item>

      <Form.Item
        label="System Type"
        name="type"
        rules={[
          {
            required: true,
            message: "System Type is required",
          },
        ]}
      >
        <Select onChange={e => setIsSystem(e === "SYSTEM")}>
          {/* 'SEGMENTED' ,  'SYSTEM'*/}
          {["ALL_USERS"].map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Type of Notification" initialValue={type}>
        <Select value={type} onChange={setType}>
          {["Notification"].map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* {!isSystem && (
          <Form.Item required label="Send right now" name="needToSend">
            <Checkbox onChange={(e) => form.setFieldsValue({ needToSend: e.target.checked })} />
          </Form.Item>
        )} */}
      <Divider>Notification Data</Divider>
      <Form.Item
        label="Title"
        name={["inAppNotification", "title"]}
        rules={[
          {
            required: true,
            message: "Title is required",
          },
          {
            type: "string",
            max: 255,
            message: "Max length is 255",
          },
        ]}
      >
        <Input maxLength={50} />
      </Form.Item>
      <Form.Item
        label="Subtitle"
        name={["inAppNotification", "subtitle"]}
        rules={[
          {
            required: true,
            message: "Subtitle is required",
          },
          {
            type: "string",
            max: 255,
            message: "Max length is 255",
          },
        ]}
      >
        <Input maxLength={200} />
      </Form.Item>
      <Form.Item
        label="Type of notification"
        name={["inAppNotification", "type"]}
        rules={[
          {
            required: true,
            message: "Type of notification is required",
          },
        ]}
      >
        <Select
          onChange={e => {
            let isFullT = false
            let withCTAT = false

            if (e.includes("FULL")) {
              isFullT = true
            }
            if (e.includes("CTA")) {
              withCTAT = true
            }
            setIsFull(isFullT)
            setwithCTA(withCTAT)
          }}
        >
          {["FULL_READONLY", "SHORT_READONLY", "FULL_CTA", "SHORT_CTA"].map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item required label="Platforms" name={["inAppNotification", "platforms"]}>
        <Checkbox.Group
          value={platforms}
          onChange={e => setplatforms(e)}
          options={["DESKTOP", "ANDROID", "IOS"]}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: "1 1 0",
            maxWidth: "70%",
          }}
        />
      </Form.Item>

      <div style={{ display: "flex" }}>
        {isFull && (
          <div style={{ display: "flex", width: "50%" }}>
            <div style={{ paddingRight: "15px" }}>{"Image: "}</div>
            <UploadComponent
              image={image}
              setImage={e => {
                setImage(e)
                form.setFieldsValue({ "inAppNotification.imageUrl": e })
              }}
              maxSize={0.5}
            />
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ paddingRight: "15px" }}>{"Icon: "}</div>
          <UploadComponent
            image={icon}
            setImage={e => {
              setIcon(e)
              form.setFieldsValue({ "inAppNotification.iconUrl": e })
            }}
            maxSize={0.1}
          />
        </div>
      </div>

      {isFull && (
        <>
          <Form.Item
            name={["inAppNotification", "body"]}
            style={{
              lineHeight: "0px",
              minHeight: "0px",
              marginBottom: "0px",
              height: "0px",
            }}
          />
          <>
            <Typography.Paragraph>Body:</Typography.Paragraph>
            <Input.TextArea
              value={description}
              autoSize={{ minRows: 4 }}
              onChange={e => setDescription(e.target.value)}
              maxLength={10000}
            />
            <div className={Styles.descriptionButtons}>
              <Button onClick={() => addParagraphOrItemList("&~~")}>Add paragraph</Button>
              <Button onClick={() => addParagraphOrItemList("*--")}>Add Item List</Button>
              <Button
                onClick={() => {
                  setListDescription([])
                  setUploadText("")
                }}
              >
                Clear Description
              </Button>
            </div>
            <div>
              Body`s result<b>(if you want to see result click Device Visible)</b>:
            </div>
            <Typography.Paragraph>{uploadText}</Typography.Paragraph>

            <Button
              onClick={() => {
                setIsShowDevice(true)

                setInfoVisibleDevice({
                  title: form.getFieldValue(["inAppNotification", "title"]),
                  image,
                  buttonName: form.getFieldValue([
                    "inAppNotification",
                    "inAppNotificationButton",
                    "displayLabel",
                  ]),
                })
              }}
            >
              Device Visible / Device Update
            </Button>
            {isShowDevice && (
              <MobileView infoVisibleDevice={infoVisibleDevice} uploadText={uploadText} />
            )}
          </>
        </>
      )}

      {withCTA && (
        <>
          <Form.Item
            style={{ marginTop: "10px" }}
            label="Button display label"
            name={["inAppNotification", "inAppNotificationButton", "displayLabel"]}
            rules={[
              {
                required: true,
                message: "Display label is required",
              },
              {
                type: "string",
                max: 255,
                message: "Max length is 255",
              },
            ]}
          >
            <Input maxLength={25} />
          </Form.Item>
          <div>
            <Form.Item
              label="Action Button Type"
              name={["inAppNotification", "inAppNotificationButton", "actionType"]}
              rules={[
                {
                  required: true,
                  message: "Type is required",
                },
              ]}
              style={{ flex: 1 }}
            >
              <Select onChange={e => setbuttonIsRedirect(e === "REDIRECT")}>
                <Select.Option value="REDIRECT">REDIRECT</Select.Option>
                {isSystem && <Select.Option value="CLAIM">CLAIM</Select.Option>}
              </Select>
            </Form.Item>
            {buttonIsRedirect && (
              <>
                <DefaultLinksRouting
                  link=""
                  formItemName={[
                    "inAppNotification",
                    "inAppNotificationButton",
                    "notificationRedirectType",
                    "redirectTo",
                  ]}
                  mobile={!platforms.includes("DESKTOP") && platforms.length !== 0}
                />

                <Form.Item
                  label="Button display label"
                  name={[
                    "inAppNotification",
                    "inAppNotificationButton",
                    "notificationRedirectType",
                    "name",
                  ]}
                  style={{ display: "none" }}
                  initialValue=""
                >
                  <Input maxLength={25} value="" />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Design Button Type"
              name={["inAppNotification", "inAppNotificationButton", "designType"]}
              rules={[
                {
                  required: true,
                  message: "Design Button Type is required",
                },
              ]}
              style={{ flex: 1 }}
            >
              <Select>
                {[
                  "PRIMARY_TEXT",
                  "PRIMARY_TEXT_BUFFPOINT",
                  "SECONDARY_TEXT",
                  "SECONDARY_TEXT_BUFFPOINT",
                  "PREMIUM_TEXT",
                  "PREMIUM_TEXT_BUFFPOINT",
                ].map(item => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </>
      )}
      <SubmitCancelButtons onClose={onClose} isLoading={notificationsStore.isLoading} />
    </Form>
  )
})
