import React, { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Form, Input, Select, Switch } from "antd"
import { observer } from "mobx-react"

import { ActionType, IFrameButtonsConfig } from "domains/managingJson/store/types"
import { ApprovedButtonNames, ApprovedIframeNames } from "domains/managingJson/constants"
import { SubmitCancelButtons, DefaultLinksRouting } from "components"

export const EditIFrameButtons: FC<{
  editType: "create" | "edit";
  onClose: () => void;
  configType: "buttons";
}> = observer(({ editType, configType, onClose }) => {
  const {
    dataStore: {
      managingJsonStore,
      managingJsonStore: { selectedElement: { name, data } },
    },
  } = useStore()
  const [actionType, setActionType] = useState<ActionType | null>("internal-routing")
  const [iframeSwitch, setiframeSwitch] = useState<boolean>(false)
  const [nameSwitch, setnameSwitch] = useState(false)

  const { text, action, href, to, iframeName, queries } = (data || {}) as IFrameButtonsConfig


  const onFinishHandler = async(event: IFrameButtonsConfig & { name: string }) => {
    const queriesParams = new URLSearchParams(event.queries)
    const queriesEntered = Object.fromEntries(queriesParams)
    const dataToBack = {
      name: event.name,
      type: configType,
      data: {
        text: event.text,
        icon: event.icon,
        action: event.action,
        href: event.href,
        to: event.to,
        iframeName: event.iframeName,
        queries: queriesEntered,
      },
    }

    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      await managingJsonStore.getData()
    }
    editType === "edit"
      ? await managingJsonStore.update(dataToBack)
      : await managingJsonStore.create(dataToBack)
  }

  const handleSelectType = (e: ActionType) => {
    setActionType(e)
  }

  return (
    <Form onFinish={onFinishHandler}>
      <div style={{ display: "flex", gap: "10px" }}>
        <div>
          Select/Input
          <Switch checked={nameSwitch} onChange={setnameSwitch} />
        </div>
        <Form.Item
          label="Name"
          name="name"
          initialValue={name}
          required
          style={{ width: "100%", marginBottom: "20px" }}
        >
          {!nameSwitch ? (
            <Select disabled={editType === "edit"}>
              {ApprovedButtonNames.map(action => (
                <Select.Option key={action} value={action}>
                  {action}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input style={{ width: "100%" }} disabled={editType === "edit"} />
          )}
        </Form.Item>
      </div>
      <Form.Item
        label="Text"
        name="text"
        style={{ width: "100%" }}
        rules={[
          {
            required: true,
            message: "Please input text!",
          },
        ]}
        initialValue={text}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item label="Icon" name="icon" style={{ width: '100%' }} initialValue={icon}>
          <Input />
        </Form.Item> */}
      <Form.Item
        label="Action"
        name="action"
        style={{ width: "100%" }}
        initialValue={action}
        required
      >
        <Select onChange={handleSelectType}>
          {["iframe", "external-link", "internal-routing"].map(el => (
            <Select.Option value={el} key={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {actionType === "iframe" && (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              Switch/Input
              <Switch defaultChecked={iframeSwitch} onChange={setiframeSwitch} />
            </div>
            <Form.Item
              label="IFrame name"
              name="iframeName"
              style={{ width: "100%" }}
              initialValue={iframeName}
            >
              {!iframeSwitch ? (
                <Select>
                  {ApprovedIframeNames.map(action => (
                    <Select.Option key={action} value={action}>
                      {action}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input />
              )}
            </Form.Item>
          </div>
          <Form.Item
            label="Queries"
            name="queries"
            style={{ width: "100%" }}
            initialValue={new URLSearchParams(queries).toString()}
          >
            <Input />
          </Form.Item>
        </>
      )}
      {actionType === "iframe" ? (
        managingJsonStore.itemTypes
          .filter(item => item.type === "iframes")
          .map(el => (
            <Select.Option value={el} key={el.name}>
              {el.name}
            </Select.Option>
          ))
      ) : (
        <DefaultLinksRouting
          formItemName={actionType === "external-link" ? "href" : "to"}
          link={actionType === "external-link" ? href || "" : to || ""}
        />
      )}

      <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
    </Form>
  )
})
