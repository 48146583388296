import React, { FC, useEffect } from "react"

import moment from "moment"
import { observer } from "mobx-react"
import { DatePicker, Form, Input, InputNumber, Radio, Select, Switch } from "antd"

import { useStore } from "stores/utils/use-store"
import { BillingPeriod } from "domains/accounts/store/types"
import { UtmBonus } from "domains/utm/store"
import { SubmitCancelButtons } from "components"
import { COUNTRY_CODES } from "utils/countryCodes"

interface FormValues extends Omit<UtmBonus, "expiresAt"> {
  pointsEnabled: boolean;
  premiumEnabled: boolean;
  expiresAt: moment.Moment;
}

export const UtmBonusEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      utmBonusesStore: {
        selectedElement: {
          name,
          code,
          amount,
          subscriptionId,
          billingPeriod,
          expiresAt,
          utmDataField,
          geoRegion,
        },
      },
      utmBonusesStore,
      subscriptionStore,
    },
  } = useStore()

  useEffect(() => {
    subscriptionStore.getData()
  }, [subscriptionStore])

  const [form] = Form.useForm()
  const pointsEnabled = Form.useWatch("pointsEnabled", form)
  const premiumEnabled = Form.useWatch("premiumEnabled", form)

  const onFinishHandler = async(e: FormValues) => {
    if (!e.pointsEnabled && !e.premiumEnabled) {
      form.setFields([
        {
          errors: ["At least one of Premium or Points must be set"],
          name: "premiumEnabled",
        },
      ])

      return
    }

    const {
      name,
      amount,
      expiresAt,
      billingPeriod,
      subscriptionId,
      code,
      id,
      createdAt,
      updatedAt,
      utmDataField,
      geoRegion: geoRegionArray,
    } = e

    const geoRegion = geoRegionArray[0]

    const expiresAtTransformed = expiresAt ? expiresAt.toDate() : null

    const sendingData: UtmBonus = {
      id,
      name,
      billingPeriod,
      subscriptionId,
      code,
      createdAt,
      updatedAt,
      utmDataField,
      expiresAt: expiresAtTransformed,
      amount: +amount,
      geoRegion,
    }

    utmBonusesStore.funcAfterSuccess = async() => {
      await utmBonusesStore.getData()
      onClose()
    }
    editType === "edit"
      ? await utmBonusesStore.update(sendingData)
      : await utmBonusesStore.create(sendingData)
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (
    <Form form={form} onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
      >
        <Input maxLength={25} />
      </Form.Item>
      <Form.Item
        label="GEO region"
        name="geoRegion"
        initialValue={[geoRegion]}
        rules={[
          {
            validator: (_, value) => {
              if (value && value.length === 0) {
                return Promise.reject(new Error("Please select one geo region!"))
              }
              if (value && value.length > 1) {
                return Promise.reject(new Error("Please select only one geo region!"))
              }
              if (value && value.length === 1) {
                return Promise.resolve()
              }
            },
          },
        ]}
      >
        <Select
          allowClear
          mode="tags"
          options={COUNTRY_CODES.map(({ code, name }) => ({ label: `${name} (${code})`, value: code }))}
        />
      </Form.Item>
      <Form.Item
        label="Parameter"
        name="utmDataField"
        initialValue={utmDataField}
        rules={[
          {
            required: true,
            message: "Please select parameter!",
          },
        ]}
      >
        <Select value={utmDataField}>
          {[
            "source",
            "medium",
            "campaign",
            "extra",
            "content",
            "term",
          ].map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="UTM Sign"
        name="code"
        initialValue={code}
        rules={[
          {
            required: true,
            message: "Please input Code!",
          },
        ]}
      >
        <Input maxLength={25} />
      </Form.Item>

      <Form.Item
        label="Active Until"
        name="expiresAt"
        initialValue={expiresAt ? moment(expiresAt) : ""}
        style={{ width: "100%" }}
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" placeholder="Select Time" />
      </Form.Item>

      <Form.Item
        label="Add Buffs"
        name="pointsEnabled"
        valuePropName="checked"
        initialValue={!!amount}
      >
        <Switch />
      </Form.Item>

      {pointsEnabled && (
        <Form.Item
          label="Points Amount"
          name="amount"
          initialValue={amount}
          rules={[
            {
              required: true,
              message: "Please input Bonus Amount!",
            },
          ]}
        >
          <InputNumber max={10000} />
        </Form.Item>
      )}

      <Form.Item
        label="Add BUFF Premium"
        name="premiumEnabled"
        valuePropName="checked"
        initialValue={!!subscriptionId}
        dependencies={["pointsEnabled"]}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && !getFieldValue("pointsEnabled")) {
                return Promise.reject(new Error("Please select at least one of Points and Premium!"))
              }

              return Promise.resolve()
            },
          }),
        ]}
      >
        <Switch />
      </Form.Item>

      {premiumEnabled && (
        <>
          <Form.Item
            name="subscriptionId"
            label="Premium Type"
            style={{ width: "100%" }}
            initialValue={subscriptionId}
          >
            <Select>
              {subscriptionStore.subscriptions.map(subscription => (
                <Select.Option key={subscription.name} value={subscription.id}>
                  {subscription.name.toUpperCase()}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Billing Period"
            name="billingPeriod"
            initialValue={billingPeriod || BillingPeriod.MONTH}
          >
            <Radio.Group>
              <Radio.Button value={BillingPeriod.MONTH}>Month</Radio.Button>
              <Radio.Button value={BillingPeriod.YEAR}>Year</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </>
      )}

      <SubmitCancelButtons onClose={onClose} isLoading={utmBonusesStore.isLoading} />
    </Form>
  )
})
