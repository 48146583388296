export const NAVIGATION_DEFAULT = [
  "lounge",
  "newsfeed",
  "discover",
  "gamers",
  "highlights",
  "library",

  "marketplace",
  "inventory",

  "challenges",
  "leaders",
  "settings",
  "profile",

  "offerwall",
  "reward-track-fullview",

  "raffles",
  "BuffPay",

  "bestPlays",

  "premium",
  "notifications-list",
  "rate",
  "invites",
  "tiktok",
]

export const NAVIGATION_MOBILE = [
  "adjoe",
  "supply",
  "minigames",
  "spin&collect",
  "rewardtrack",
  "premium",
  "raffles",
]
