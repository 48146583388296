export const notificationsColumns = [
  { title: "System title", dataIndex: "title", key: "title" },
  { title: "System type", dataIndex: "type", key: "type" },
  { title: "SendStatus", dataIndex: "sendStatus", key: "sendStatus" },
  {
    title: "Title",
    key: "title",
    dataIndex: ["inAppNotification", "title"],
  },
  {
    title: "Type",
    key: "type",
    dataIndex: ["inAppNotification", "type"],
  },
  {
    title: "Platforms",
    key: "platforms",
    dataIndex: ["inAppNotification", "platforms"],
    render: (value: string[]) => {
      return value?.join(", ")
    },
  },
]
