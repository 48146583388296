import { FC, useCallback, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { observer } from "mobx-react"
import { Button, Modal, Table } from "antd"
import { TablePaginationConfig } from "antd/lib/table/interface"

import { Column } from "abstract/types/column.type"
import { showDeleteConfirm, DeleteIconButton, EditIconButton } from "components"

import { ChildCodeType } from "domains/promocodes/store/types"
import { ChildEdit } from "domains/promocodes/components/ItemTypes"

import { childPromocodeColumns } from "./columns"
import Styles from "./styles.module.css"

export const ChildList: FC<{ itemTypeId: string; isPrivate?: boolean }> = observer(({ isPrivate, itemTypeId }) => {
  const { dataStore: { childPromocodesStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)
  const [isCsvExporting, setIsCsvExporting] = useState(false)

  const csvExport = useCallback(async() => {
    if (isCsvExporting) return
    setIsCsvExporting(true)
    try {
      const csvUrl = await childPromocodesStore.getCsv(itemTypeId)
      window.open(csvUrl)
    } catch (error) {
      console.log(error)
    }
    setIsCsvExporting(false)
  }, [childPromocodesStore.selectedElement.id, itemTypeId, isCsvExporting, setIsCsvExporting])

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      childPromocodesStore.pagination = {
        page,
        limit: pageSize || childPromocodesStore.pagination.limit,
      }
    },
    total: childPromocodesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: childPromocodesStore.pagination.page,
  }

  const onClose = () => {
    childPromocodesStore.clearSelectedElement()
    setEditCreateVisibility(null)
    childPromocodesStore.isLoading = false
  }

  const columns: Column[] = [
    ...childPromocodeColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: ChildCodeType) => (
        <div className={Styles.actions}>
          <EditIconButton
            onClick={() => {
              childPromocodesStore.selectedElement = row
              setEditCreateVisibility("edit")
            }}
          />
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(`promo code: ${row.code}`, undefined, async() => {
                childPromocodesStore.funcAfterSuccess = async() => await childPromocodesStore.getData()
                childPromocodesStore.deleteById(row.id)
              })
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      {isPrivate && (
        <Button onClick={() => setEditCreateVisibility("create")}>Add a Child Code</Button>
      )}
      <Table
        dataSource={[...childPromocodesStore.itemTypes]}
        columns={columns as any}
        rowKey="id"
        loading={childPromocodesStore.isLoading}
        pagination={paginationOptions}
        scroll={{ x: true, y: "70vh" }}
        onRow={(record: ChildCodeType) => ({
          onClick: () => {
            childPromocodesStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />
      <button onClick={csvExport}>CSV export</button>
      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit Promo Code" : "Create Promo Code"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <ChildEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
