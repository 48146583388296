/* eslint-disable max-len */
import { Form } from "antd"
import { UploadComponent } from "components"
import Styles from "./styles.module.css"

type Props = {
  countryIndex: number;
  onChange: (v: string) => void;
  value?: string;
};

export const SourceImagesEditor = ({ countryIndex, onChange, value }: Props) => {

  return (
    <Form name={countryIndex + ""}>
      <div style={{ marginBottom: 24 }}>
        <div className={Styles.imageUploadWrapper}>
          <Form.Item name="src">
            <UploadComponent image={value ?? ""} setImage={onChange} />
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}
