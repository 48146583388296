import { Collapse, Tag } from "antd"

import { defaultFormat } from "utils/date"
import { notifications } from "notifications"
import { CopyOutlined } from "@ant-design/icons"
import { gameNames } from "abstract/constants/game-ids"
import { TransactionTypes } from "../store/types"

const { Panel } = Collapse

export const transactionColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id: string) => {
      return (
        <div
          onClick={() => {
            navigator.clipboard.writeText(id)
            notifications.success("Copied id:" + id)
          }}
          style={{
            background: "#A3A3A3",
            width: 24,
            height: 24,
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <CopyOutlined
            style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }}
          />
        </div>
      )
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type: number) => {
      return (
        type && (
          <div>
            {TransactionTypes[type]}({type})
          </div>
        )
      )
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Data",
    dataIndex: "data",
    key: "data",
    width: "350px",
    render: (data: any) => {
      return (
        <Collapse>
          <Panel key="1" header="Show content" style={{ width: "350px" }}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Panel>
        </Collapse>
      )
    },
  },
  {
    title: "GameId",
    dataIndex: "gameId",
    key: "gameId",
    filterSearch: true,
    render: (gameId: number) => {
      return (
        gameId && (
          <div>
            {gameId}({gameNames[gameId]})
          </div>
        )
      )
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (createdAt: any) => {
      return (
        <div style={{ display: "grid", width: "max-content" }}>
          <Tag color="green">{defaultFormat(createdAt)}</Tag>
        </div>
      )
    },
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (updatedAt: any) => {
      return (
        <div style={{ display: "grid", width: "max-content" }}>
          <Tag color="blue">{defaultFormat(updatedAt)}</Tag>
        </div>
      )
    },
  },
]
