/* eslint-disable max-len */
import { QuestionCircleOutlined } from "@ant-design/icons"
import { Form, Space, Tag, Tooltip, Typography } from "antd"
import { Notification } from "domains/specialEvents/pages/main"
import Styles from "./styles.module.css"

const { Text } = Typography

type Props = {
  // value gets injected by antd form
  value?: string;
  index: number;
  parentIndex: number;
};

export const NotificationNameDisplay = ({ value, index, parentIndex }: Props) => {
  const form = Form.useFormInstance()
  const isDuplicateOccurence = () => {
    const notifications: Notification[] = form.getFieldValue([
      "notificationsPerGame",
      parentIndex,
      "notifications",
    ])

    return notifications.some((notification, notificationIndex) => {
      return (
        notificationIndex !== index
        && notification?.triggerEventName === value
        && notification?.triggerEventName !== undefined
      )
    })
  }

  return (
    <Text style={{ fontSize: 18 }}>
      <Space size={2}>
        <span>Event:</span>
        {value ? (
          <Text style={{ fontSize: 24 }} code>
            {value}
          </Text>
        ) : (
          <Tooltip title="It's okay to leave it unset - it just means it won't be triggered">
            <Tag
              color="error"
              className={Styles.tag}
              style={{ marginLeft: 8, display: "flex", gap: 6, alignItems: "center" }}
            >
              Not set
              <QuestionCircleOutlined />
            </Tag>
          </Tooltip>
        )}
        {isDuplicateOccurence() && (
          <Tooltip title="It's okay to have duplicate events, but all of them will get triggered. Possible use case is to play with different delays to make them appear in a sequence.">
            <Tag color="warning" className={Styles.tag}>Duplicate event<QuestionCircleOutlined /></Tag>
          </Tooltip>
        )}
      </Space>
    </Text>
  )
}
