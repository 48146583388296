import { FC } from "react"
import { Button, Input, Typography } from "antd"
import { observer } from "mobx-react"

export const BodyContentComponent: FC<{
  description: string;
  setDescription: (str: string) => void;
  listDescriptions: string[];
  setListDescription: (str: string[]) => void;
  setUploadText: (str: string) => void;
  uploadText: string;
  s: any;
}> = observer(({
  description,
  setDescription,
  listDescriptions,
  setListDescription,
  uploadText,
  setUploadText,
  s,
}) => {
  const addParagraphOrItemList = (symbol: string) => {
    const concat = symbol.concat(description)
    listDescriptions.push(concat)

    setUploadText(listDescriptions.join(""))
    setDescription("")
  }

  return (
    <>
      <Typography.Paragraph>Body:</Typography.Paragraph>
      <Input.TextArea
        value={description}
        autoSize={{ minRows: 4 }}
        onChange={e => setDescription(e.target.value)}
        maxLength={10000}
      />
      <div className={s.descriptionButtons}>
        <Button onClick={() => addParagraphOrItemList("&~~")}>Add paragraph</Button>
        <Button onClick={() => addParagraphOrItemList("*--")}>Add Item List</Button>
        <Button
          onClick={() => {
            setListDescription([])
            setUploadText("")
            setDescription("")
          }}
        >
          Clear Description
        </Button>
      </div>
      <div>Body`s result:</div>
      <Typography.Paragraph>{uploadText}</Typography.Paragraph>
    </>
  )
})
