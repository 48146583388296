import { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { Button, Modal, Select, Table, TablePaginationConfig } from "antd"
import { emailsColumns } from "./columns"
import { observer } from "mobx-react"
import { EmailCtaEdit } from "./email-cta-edit"
import { Column } from "abstract/types/column.type"
import { CreateNewElement } from "components"
import { SendOutlined } from "@ant-design/icons"
import Search from "antd/es/input/Search"

export const EmailCtaList: FC = observer(() => {
  const { dataStore: { emailsCtaStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | null>(null)
  const [isVisibleCsv, setVisibilityCsv] = useState<boolean>(false)
  const [typeSearch, settypeSearch] = useState<
    "accountId" | "email" | "templateId" | "category" | "type" | null
  >(null)

  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      emailsCtaStore.pagination.page = page
    },
    total: emailsCtaStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }
  const searchHandler = async(value: string) => {
    if (value.length === 0) {
      emailsCtaStore.setFilter({} as any)
    } else {
      emailsCtaStore.setFilter({ [typeSearch as string]: value } as any)
    }
  }
  const onClose = () => {
    setEditCreateVisibility(null)
  }
  const onCloseSsv = () => {
    setVisibilityCsv(false)
    emailsCtaStore.dataUploadType = null
  }

  useEffect(() => {
    emailsCtaStore.getData()
  }, [emailsCtaStore])

  const columns: Column[] = [
    ...emailsColumns,
    {
      title: "Send",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (id: string) => (
        <Button
          type="primary"
          onClick={e => {
            e.stopPropagation()
            emailsCtaStore.sendEmailCTA(id)
          }}
          icon={<SendOutlined />}
        />
      ),
    },
  ]
  console.log(`Search by ${typeSearch}`, typeSearch, "`Search by ${typeSearch}`")

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="email cta" />
        <CreateNewElement onClick={() => setVisibilityCsv(true)} valueText="email cta via csv" />
      </div>
      <div>
        <Select
          onChange={e => settypeSearch(e as any)}
          value={typeSearch}
          style={{ width: "200px", marginBottom: "10px" }}
          placeholder="Filter:"
          allowClear
        >
          {["accountId", "email", "templateId", "category", "type"].map(item => {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            )
          })}
        </Select>
        {typeSearch === "type" ? (
          <Select
            onChange={e => searchHandler(e as any)}
            value={emailsCtaStore.filter}
            style={{ width: "200px", marginBottom: "10px" }}
            placeholder="Filter by type:"
            allowClear
          >
            {[
              "PREMIUM",
              "PREMIUM_PLUS",
              "PREMIUM_ELITE",
              "MARKETPLACE_DISCOUNT",
              "MARKETPLACE",
              "BUFFS",
              "RAFFLE_TICKETS",
              "BOOST_TIMED",
              "BOOST_SEASONAL",
              "SPIN_COLLECT_GOLDEN_TICKET",
            ].map(item => {
              return (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              )
            })}
          </Select>
        ) : (
          typeSearch && (
            <Search
              placeholder={`Search by ${typeSearch}`}
              allowClear
              enterButton
              onSearch={searchHandler}
              style={{ width: "500px" }}
            />
          )
        )}
      </div>
      <Table
        pagination={paginationOptions}
        dataSource={emailsCtaStore.emailCTAs}
        columns={columns as any}
        rowKey="id"
        scroll={{ x: true }}
        loading={emailsCtaStore.isLoading}
        onChange={(p, c, sorter: any) => {
          emailsCtaStore.getData({
            [sorter?.columnKey]:
              sorter.order === "ascend" ? "ASC" : sorter.order === "descend" ? "DESC" : null,
          })
        }}
      />
      {isVisibleEditCreate && (
        <Modal
          visible
          title="Create email cta"
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <EmailCtaEdit onClose={onClose} isCsv={false} />
        </Modal>
      )}
      {isVisibleCsv && (
        <Modal
          visible
          title="Create email cta via CSV"
          width="700px"
          footer={null}
          onCancel={onCloseSsv}
          style={{ top: "10px" }}
        >
          <EmailCtaEdit onClose={onCloseSsv} isCsv />
        </Modal>
      )}
    </>
  )
})
