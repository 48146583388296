import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import { Table, Button, Input, Checkbox, Select } from "antd"
import { useStore } from "stores/utils/use-store"
import { useNavigate, useParams } from "react-router-dom"

import { DeleteIconButton, EditIconButton, showDeleteConfirm, CreateNewElement } from "components"
import { GameMode, GameType } from "domains/games/components"
import { typesColumns, modesColumns } from "domains/games/components/GameDetails/columns"
import {
  GameMode as GameModeType,
  GameType as GameTypeType,
  GameStatus,
} from "domains/games/store/types"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { GameStatusModal } from "../GameStatus"

export const GameDetails: FC = observer(() => {
  const [isEditGameModeModal, setEditGameModeModal] = useState<"create" | "edit" | null>(null)
  const [isEditGameStatusModal, setEditGameStatusModal] = useState<"create" | "edit" | null>(null)
  const [isEditGameTypeModal, setEditGameTypeModal] = useState<"create" | "edit" | null>(null)

  const { id: gameId } = useParams<{ id: string }>()
  const {
    dataStore: {
      gamesStore,
      gamesStore: { games },
    },
  } = useStore()

  useEffect(() => {
    gamesStore.getData()
  }, [gamesStore])

  const { gameModes, pretyName: pretyName, name, gamePlatform, pictureUrl, gameTypes, isAutoUpdatable }
    = toJS(games.find(item => item.id === gameId)) || {}

  const onClose = () => {
    gamesStore.clearSelectedElement()
    setEditGameModeModal(null)
    setEditGameTypeModal(null)
    setEditGameStatusModal(null)
  }

  const handleChangeAutoupdate = async({ target: { checked } }) => {
    if (!gameId) {
      return
    }

    const dataToBack = {
      gameId,
      status: checked,
    }

    await gamesStore.autoUpdate(dataToBack)
    onClose()
    await gamesStore.getData()
  }

  const handleChangeGameStatus = async(status: GameStatus) => {
    if (!gameId) {
      return
    }

    const dataToBack = {
      gameId,
      status,
    }

    await gamesStore.updateGameStatus(dataToBack)
    onClose()
    await gamesStore.getData()
  }

  const gameModesColumns = [
    ...modesColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: GameModeType) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(`game mode: ${row.name}`, undefined, async() => {
              await gamesStore.deleteGameMode(row.id)
              await gamesStore.getData()
            })
          }}
        />
      ),
    },
  ]

  const gameTypesColumns = [
    ...typesColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: GameTypeType) => (
        <div>
          <EditIconButton
            onClick={e => {
              e.stopPropagation()
              setEditGameTypeModal("edit")
              gamesStore.selectedGameType = row
            }}
          /><DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(`game status: ${row.name}`, undefined, async() => {
                await gamesStore.deleteGameStatus(row.id)
                await gamesStore.getData()
              })
            }}
          />
        </div>
      ),
    },
  ]

  const gameStatus = gameTypes?.find(item => item.isActive)?.status
  const navigate = useNavigate()

  return (
    <div>
      <Button onClick={() => navigate("/games/games-list")} style={{ marginBottom: "15px" }}>
        <ArrowLeftOutlined /> Back to List
      </Button>
      <br />
      <img src={pictureUrl} alt="" style={{ backgroundColor: "green", margin: "1rem 0" }} />
      <Input addonBefore="Name:" value={name} style={{ marginBottom: "1rem" }} />
      <Input addonBefore="Pretty name:" value={pretyName} style={{ marginBottom: "1rem" }} />
      <Input
        addonBefore="Game platform:"
        value={gamePlatform?.name}
        style={{ marginBottom: "2rem" }}
      />
      <Checkbox
        checked={isAutoUpdatable}
        onChange={handleChangeAutoupdate}
        style={{ marginBottom: "2rem" }}
      >
        Autoupdate
      </Checkbox>
      <h1>Active status</h1>
      <Select
        placeholder="Game status"
        onChange={handleChangeGameStatus}
        style={{ width: "100%", marginBottom: "2rem" }}
        value={gameStatus}
        disabled={isAutoUpdatable}
      >
        {["ONLINE", "UNSTABLE", "OFFLINE"].map(el => (
          <Select.Option value={el} key={el}>
            {el}
          </Select.Option>
        ))}
      </Select>
      <h1>Game modes</h1>

      <CreateNewElement onClick={() => setEditGameModeModal("create")} valueText="game mode" />

      <Table
        dataSource={gameModes}
        columns={gameModesColumns}
        rowKey="id"
        onRow={record => ({
          onClick: () => {
            setEditGameModeModal("edit")
            gamesStore.selectedGameMode = record
          },
        })}
      />
      <h1 style={{ marginTop: "2rem" }}>Game statuses</h1>
      <CreateNewElement onClick={() => setEditGameStatusModal("create")} valueText="game status" />
      <Table
        dataSource={gameTypes}
        columns={gameTypesColumns}
        rowKey="id"
        onRow={record => ({
          onClick: () => {
            setEditGameStatusModal("edit")
            gamesStore.selectedGameType = record
          },
        })}
      />
      {isEditGameModeModal && (
        <GameMode onClose={onClose} editType={isEditGameModeModal} gameId={gameId || ""} />
      )}
      {isEditGameStatusModal && (
        <GameStatusModal onClose={onClose} editType={isEditGameStatusModal} gameId={gameId || ""} />
      )}
      {isEditGameTypeModal && <GameType onClose={onClose} />}
    </div>
  )
})
