/* eslint-disable max-len */
import React, { FC, useEffect, useState } from "react"

import { Form } from "antd"
import { InputColor } from "components"
import { ContestConfig } from "domains/managingJson/store/types"

export const Colors: FC<{ data: ContestConfig }> = ({ data }) => {
  const [neutralBlack, setneutralBlack] = useState((data && data.colors && data.colors?.neutral && data.colors?.neutral.black) || "#fff")
  const [neutralGray, setneutralGray] = useState((data && data.colors && data.colors?.neutral && data.colors?.neutral.gray) || "#fff")
  const [neutralInactive, setneutralInactive] = useState((data && data.colors && data.colors?.neutral && data.colors?.neutral.inactive) || "#fff")
  const [neutralRed, setneutralRed] = useState((data && data.colors && data.colors?.neutral && data.colors?.neutral.red) || "#fff")

  const [primaryDefault, setprimaryDefault] = useState((data && data.colors && data.colors?.primary && data.colors?.primary.default) || "#fff")
  const [primaryLight, setprimaryLight] = useState((data && data.colors && data.colors?.primary && data.colors?.primary.light) || "#fff")
  const [primaryDark, setprimaryDark] = useState((data && data.colors && data.colors?.primary && data.colors?.primary.dark) || "#fff")

  const [secondaryClick, setsecondaryClick] = useState((data && data.colors && data.colors?.secondary && data.colors?.secondary.click) || "#fff")

  const [secondaryDefault, setsecondaryDefault] = useState((data && data.colors && data.colors?.secondary && data.colors?.secondary.default) || "#fff")
  const [secondaryHover, setsecondaryHover] = useState((data && data.colors && data.colors?.secondary && data.colors?.secondary.hover) || "#fff")

  const [backgroundMain, setbackgroundMain] = useState((data && data.colors && data.colors?.background && data.colors?.background.main) || "")

  const [backgroundSecondary, setbackgroundSecondary] = useState((data && data.colors && data.colors?.background && data.colors?.background.secondary) || "")

  const [textInactive, settextInactive] = useState((data && data.colors && data.colors?.text && data.colors?.text.inactive) || "#fff")
  const [textWhite, settextWhite] = useState((data && data.colors && data.colors?.text && data.colors?.text.white) || "")
  const [textGray, settextGray] = useState((data && data.colors && data.colors?.text && data.colors?.text.gray) || "")

  useEffect(() => {
    if (data) {
      setprimaryDefault(data.colors?.primary?.default || "#fff")
      setprimaryLight(data.colors?.primary?.light || "#fff")
      setprimaryDark(data.colors?.primary?.dark || "#fff")

      setsecondaryHover(data.colors?.secondary?.hover || "#fff")
      setsecondaryDefault(data.colors?.secondary?.default || "#fff")
      setsecondaryClick(data.colors?.secondary?.click || "#fff")

      setneutralBlack(data.colors?.neutral?.black || "#fff")
      setneutralGray(data.colors?.neutral?.gray || "#fff")
      setneutralInactive(data.colors?.neutral?.inactive || "#fff")
      setneutralRed(data.colors?.neutral?.red || "#fff")

      setbackgroundMain(data.colors?.background?.main || "#fff")
      setbackgroundSecondary(data.colors?.background?.secondary || "#fff")

      settextInactive(data.colors?.text?.inactive || "#fff")
      settextWhite(data.colors?.text?.white || "#fff")
      settextGray(data.colors?.text?.gray || "#fff")
    }
  }, [data])

  return (
    <div>
      {/* Primary colors */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
          name="primaryDefault"
          initialValue={primaryDefault}
          style={{ marginBottom: "0" }}
        >
          <InputColor
            title="Primary Default color"
            color={primaryDefault}
            onChange={setprimaryDefault}
          />
        </Form.Item>
        <Form.Item name="primaryLight" initialValue={primaryLight} style={{ marginBottom: "0" }}>
          <InputColor
            title="Primary Light color"
            color={primaryLight}
            onChange={setprimaryLight}
          />
        </Form.Item>
        <Form.Item name="primaryDark" initialValue={primaryDark} style={{ marginBottom: "0" }}>
          <InputColor title="Primary Dark color" color={primaryDark} onChange={setprimaryDark} />
        </Form.Item>
      </div>
      {/* Secondary colors */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
          name="secondaryDefault"
          initialValue={secondaryDefault}
          style={{ marginBottom: "0" }}
        >
          <InputColor
            title="Secondary Default color"
            color={secondaryDefault}
            onChange={setsecondaryDefault}
          />
        </Form.Item>
        <Form.Item
          name="secondaryHover"
          initialValue={secondaryHover}
          style={{ marginBottom: "0" }}
        >
          <InputColor
            title="Secondary Hover color"
            color={secondaryHover}
            onChange={setsecondaryHover}
          />
        </Form.Item>
        <Form.Item
          name="secondaryClick"
          initialValue={secondaryClick}
          style={{ marginBottom: "0" }}
        >
          <InputColor
            title="Secondary Click color"
            color={secondaryClick}
            onChange={setsecondaryClick}
          />
        </Form.Item>
      </div>
      {/*  Text colors */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item name="textInactive" initialValue={textInactive} style={{ marginBottom: "0" }}>
          <InputColor
            title="Text inactive color"
            color={textInactive}
            onChange={settextInactive}
          />
        </Form.Item>
        <Form.Item name="textWhite" initialValue={textWhite} style={{ marginBottom: "0" }}>
          <InputColor title="Text white color" color={textWhite} onChange={settextWhite} />
        </Form.Item>
        <Form.Item name="textGray" initialValue={textGray} style={{ marginBottom: "0" }}>
          <InputColor title="Text gray color" color={textGray} onChange={settextGray} />
        </Form.Item>
      </div>
      {/* Neutral colors */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item
          name="neutralInactive"
          initialValue={neutralInactive}
          style={{ marginBottom: "0" }}
        >
          <InputColor
            title="Neutral Inactive color"
            color={neutralInactive}
            onChange={setneutralInactive}
          />
        </Form.Item>
        <Form.Item name="neutralBlack" initialValue={neutralBlack} style={{ marginBottom: "0" }}>
          <InputColor
            title="Neutral Black color"
            color={neutralBlack}
            onChange={setneutralBlack}
          />
        </Form.Item>
        <Form.Item name="neutralGray" initialValue={neutralGray} style={{ marginBottom: "0" }}>
          <InputColor title="Neutral gray color" color={neutralGray} onChange={setneutralGray} />
        </Form.Item>
      </div>

      {/* Background colors */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Item name="neutralRed" initialValue={neutralRed} style={{ marginBottom: "0" }}>
          <InputColor title="Neutral red color" color={neutralRed} onChange={setneutralRed} />
        </Form.Item>
        <Form.Item
          name="backgroundMain"
          initialValue={backgroundMain}
          style={{ marginBottom: "0" }}
        >
          <InputColor
            title="Background Main color"
            color={backgroundMain}
            onChange={setbackgroundMain}
          />
        </Form.Item>
        <Form.Item
          name="backgroundSecondary"
          initialValue={backgroundSecondary}
          style={{ marginBottom: "0" }}
        >
          <InputColor
            title="Background Secondary color"
            color={backgroundSecondary}
            onChange={setbackgroundSecondary}
          />
        </Form.Item>
      </div>
    </div>
  )
}
