import { CopyOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import { notifications } from "notifications"
import { defaultFormat } from "utils/date"

export const codesUploadColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id: string) => {
      return (
        <div
          onClick={() => {
            navigator.clipboard.writeText(id)
            notifications.success("Copied id:" + id)
          }}
          style={{
            background: "#A3A3A3",
            width: 24,
            height: 24,
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <CopyOutlined
            style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }}
          />
        </div>
      )
    },
  },
  { title: "Code", dataIndex: "code", key: "code" },
  {
    title: "Reservation",
    dataIndex: "reservationName",
    key: "reservationName",
  },
  {
    title: "Assignee",
    dataIndex: "accountId",
    key: "accountId",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    return: (status: string) => {
      if (status === "Unclaimed") {
        return (
          <ul className="anotherStatus">
            <li>Unclaimed</li>
          </ul>
        )
      } else if (status === "Claimed") {
        return (
          <ul className="activeStatus">
            <li>Claimed</li>
          </ul>
        )
      }
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: createdAt => {
      return <Tag color="green">{defaultFormat(createdAt)}</Tag>
    },
  },
]
