/* eslint-disable max-len */
import { gameNamesDesktop } from "abstract/constants/game-ids"
import { FormInstance, Select, Space } from "antd"
import { FormElement } from "components"
import { FC } from "react"
import { GamesEditor } from "domains/specialEvents/pages/edit/components/gamesEditor"
import Styles from "./styles.module.css"

const { Option } = Select

export const GamesSetupTab: FC<{
  form: FormInstance<any>
  selectedGames: string[] | undefined
  setSelectedGames: React.Dispatch<React.SetStateAction<string[] | undefined>>
}> = ({ selectedGames, setSelectedGames, form }) => {

  const selectedGamesOnchange = e => {
    setSelectedGames(e)
  }

  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px", display: "flex", flexDirection: "column", gap: 45 }}>
      <div style={{ background: "white", padding: "24px 16px" }}>
        <div style={{ fontWeight: 500, marginBottom: "16px" }}>Setup</div>
        <div style={{ display: "flex", background: "white" }}>
          <div style={{ width: "50%" }}>
              Games
            <FormElement type="select" label="" name="selectedGames">
              <Select
                mode="multiple"
                placeholder="Please select games"
                defaultValue={selectedGames}
                onChange={e => selectedGamesOnchange(e)}
              >
                {Object.entries(gameNamesDesktop).map(item => {
                  return (
                    <Option value={item[0]} key={item[0]}>
                      {item[1]}
                    </Option>
                  )
                })}
              </Select>
            </FormElement>
          </div>
        </div>
      </div>
      <Space direction="vertical" className={Styles.cardContainer} size="large">
        <GamesEditor contestMode />
      </Space>
    </div>
  )

}
