import React, { FC, useState } from "react"
import { Button, Form, Input, Divider, Modal, Select } from "antd"
import { UploadFileComponent, SubmitCancelButtons } from "components"
import s from "./styles.module.css"
import { useStore } from "stores/utils/use-store"

export const EmailNotificationsList: FC = () => {
  const [form] = Form.useForm()
  const [isVisibleAdd, setIsVisibleAdd] = useState<boolean>(false)
  const [file, setfile] = useState<File>()
  const [fileErr, setfileErr] = useState(false)

  const toggleModal = () => {
    setIsVisibleAdd(prevState => {
      return !prevState
    })
  }

  const { dataStore: { emailNotificationsStore } } = useStore()

  const onFinishHandler = async(data: any) => {
    if (file && !file?.size) {
      setfileErr(true)
    } else {
      await emailNotificationsStore.sendEmail(
        data.templateId.replace(" ", ""),
        data.notificationTag.replace(" ", ""),
        data.senderName,
        data.senderEmail,
        file,
      )
      setIsVisibleAdd(false)
      form.resetFields()
      setfile(undefined)
    }
  }

  return (
    <>
      <Modal
        visible={isVisibleAdd}
        onCancel={() => setIsVisibleAdd(false)}
        width={700}
        footer={null}
      >
        <div>
          Please input template id and notification tag without whitespace. Whitespaces will be
          deleted during sending data
        </div>
        <Form onFinish={onFinishHandler} style={{ marginTop: 16 }} form={form}>
          <Form.Item
            label="Template id"
            name="templateId"
            rules={[
              {
                required: true,
                message: "Please input template id without whitespaces!",
              },
            ]}
          >
            <Input className={s.defaultBorder} />
          </Form.Item>

          <Form.Item
            label="Notification Tag"
            name="notificationTag"
            rules={[
              {
                required: true,
                message: "Please input Notification tag without whitespaces!",
              },
            ]}
          >
            <Input className={s.defaultBorder} maxLength={50} />
          </Form.Item>
          <Form.Item
            label="Sender Name"
            name="senderName"
            // initialValue={isEdit ? selectedElement?.platform : ''}
            rules={[
              {
                required: true,
                message: "Please select sender name!",
              },
            ]}
          >
            <Select>
              <Select.Option value="Buff Game">Buff Game</Select.Option>
              <Select.Option value="BuffPay">BuffPay</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Sender Email"
            name="senderEmail"
            // initialValue={isEdit ? selectedElement?.platform : ''}
            rules={[
              {
                required: true,
                message: "Please select sender email!",
              },
            ]}
          >
            <Select>
              <Select.Option value="noreply@mybuffpay.com">noreply@mybuffpay.com</Select.Option>
              <Select.Option value="noreply@buff.game">noreply@buff.game</Select.Option>
            </Select>
          </Form.Item>
          {fileErr && <div style={{ color: "red" }}>Please select file</div>}
          <Divider className={s.title} orientation="left">
            Csv file
          </Divider>

          <div className={s.containerUploadImages}>
            <UploadFileComponent file={file} setfile={setfile} fileType=".csv" />
          </div>
          <SubmitCancelButtons
            onClose={toggleModal}
            isLoading={emailNotificationsStore.isLoading}
          />
        </Form>
      </Modal>
      <Button onClick={toggleModal}>Make Notification</Button>
    </>
  )
}
