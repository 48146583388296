import { DeleteOutlined } from "@ant-design/icons"
import { DatePicker, Form, FormInstance } from "antd"
import { FormElement, UploadComponent } from "components"
import { ContestConfig, ContestConfigLiveData, ContestConfigSchedulePageData } from "domains/managingJson/store/types"
import moment from "moment"
import { FC, SetStateAction, useReducer } from "react"
import { useStore } from "stores/utils/use-store"

interface props {
  step: (ContestConfigSchedulePageData & {
    CTAtext: string;
})
  index?: number
  form: FormInstance<any>
  deleteFunc?: () => void
  name: string
  finish?: boolean
  weeks?: ContestConfigLiveData[]
  setContestLive?: React.Dispatch<SetStateAction<ContestConfigLiveData[]>>
  setter?: React.Dispatch<SetStateAction<ContestConfigSchedulePageData>>
}

export const SchedulingImage: FC<props> = ({ step, index = -1, form, deleteFunc, name, finish, weeks, setContestLive, setter }) => {

  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  return (
    <div style={{ width: "30%", marginRight: "16px", marginBottom: "16px" }}>
      <div style={{ padding: "16px 16px 16px", border: "1px solid #F0F0F0" }}>
        <div style={{ fontWeight: "500" }}>{name}</div>
        <UploadComponent
          image={step.image}
          setImage={e => {
            if (weeks) {
              forceUpdate()
              weeks[index].image = e
            } else {
              setter ? setter({ ...step, image: e }) : ""
            }
          }}
          placeholder=""
          title=""
        />
        <div style={{ background: "white", marginTop: "32px" }}>
          <div style={{ width: "100%", marginRight: "16px" }}>
              Title
            <FormElement
              type="input"
              label=""
              name={`weekTitle${index !== -1 ? index+1 : !finish ? "Start" : "Finish"}`}
              initialValue={step.title}
              props={{
                onChange: e => {
                  if (index !== -1 && weeks) {
                    weeks[index].title = e.target.value
                    setContestLive ? setContestLive(weeks) : ""
                  }
                },
              }}
            />
          </div>
          <div style={{ width: "100%", marginRight: "16px" }}>
              Text
            <FormElement
              type="text"
              label=""
              name={`weekText${index !== -1 ? index+1 : !finish ? "Start" : "Finish"}`}
              props={{
                onChange: e => {
                  if (index !== -1 && weeks) {
                    weeks[index].text = e.target.value
                    setContestLive ? setContestLive(weeks) : ""
                  }
                },
              }}
              initialValue={step.text}
            />
          </div>
          {finish && (
            <div style={{ width: "100%", marginRight: "16px" }}>
            CTA text
              <FormElement
                type="input"
                label=""
                name="CTAtext"
                initialValue={step.CTAtext}
              />
            </div>
          )}
          {weeks && (
            <>
              <div>
                {index === 0 ? "Contest Start" : `Week ${index + 1} Start`}
                <Form.Item
                  label=""
                  name={`contestLiveStartDate${index+1}`}
                  style={{ width: "100%" }}
                  initialValue={moment(weeks[index].startDate)}
                >
                  <DatePicker
                    format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
                    value={moment(weeks[index].startDate)}
                    disabled={index !== 0}
                    onChange={e => {
                      if (e) {
                        weeks[index].startDate = e.toDate()
                        data.startDate = e.toDate()
                      }
                      setContestLive ? setContestLive(weeks) : ""
                    }}
                    showTime
                    placeholder="Select Start Date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <div>
                {index+1 === weeks.length ? "Contest End" : `Week ${index + 1} End`}
                <Form.Item
                  label=""
                  name={`contestLiveEndDate${index + 1}`}
                  style={{ width: "100%" }}
                  initialValue={moment(weeks[index].endDate)}
                >
                  <DatePicker
                    format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
                    value={moment(weeks[index].endDate)}
                    onChange={e => {
                      if (e) {
                        weeks[index].endDate = e.toDate()
                        if (weeks.length - 1 > index) {
                          e.add(1, "s")
                          weeks[index + 1].startDate = e.toDate()
                          form.setFieldValue(`contestLiveStartDate${index + 2}`, e)
                        } else {
                          data.endDate = e.toDate()
                        }
                      }
                      setContestLive ? setContestLive(weeks) : ""
                    }}
                    showTime
                    placeholder="Select End Date"
                    style={{ width: "100%" }}
                    disabledDate={e => {
                      return e < moment(weeks[index].startDate)
                    }}
                  />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </div>
      {deleteFunc && (
        <div
          style={{
            margin: "",
            color: "#FF0000",
            border: "1px dotted  #D9D9D9",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={deleteFunc}
        ><DeleteOutlined style={{}} />Delete
        </div>
      )}
    </div>
  )
}
