import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"

import { TablePaginationConfig } from "antd/lib/table/interface"
import { Modal, Table, Input } from "antd"

import { Column } from "abstract/types/column.type"
import { marketplaceColumns } from "./columns"

import { InternalItemTypeGet } from "domains/marketplace/store/types"
import { MarketplaceEdit } from "../Edit"
import { DeleteIconButton, showDeleteConfirm, UploadComponent, CreateNewElement } from "components"
import Styles from "./styles.module.css"

export const InternalMarketplaceList: FC = observer(() => {
  const { dataStore: { internalMarketplaceStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  const [isVisibleImage, setIsVisibleImage] = useState<boolean>(false)
  const [image, setImage] = useState("")

  useEffect(() => {
    internalMarketplaceStore.getData()
    internalMarketplaceStore.getCategories()
  }, [internalMarketplaceStore])

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      internalMarketplaceStore.pagination = {
        page,
        limit: pageSize || internalMarketplaceStore.pagination.limit,
      }
    },
    total: internalMarketplaceStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: internalMarketplaceStore.pagination.page,
  }

  const onClose = () => {
    internalMarketplaceStore.clearSelectedElement()
    setEditCreateVisibility(null)

    internalMarketplaceStore.isLoading = false
  }

  const columns: Column[] = [
    ...marketplaceColumns,

    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (_: any, row: InternalItemTypeGet) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(`market item: ${row.title}`, undefined, async() => {
              internalMarketplaceStore.funcAfterSuccess = async() => await internalMarketplaceStore.getData()
              internalMarketplaceStore.delete(row.id)
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <div className={Styles.header}>
        <CreateNewElement
          onClick={() => setEditCreateVisibility("create")}
          valueText="internal market item"
        />
      </div>

      <Table
        dataSource={internalMarketplaceStore.itemTypes}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={internalMarketplaceStore.isLoading}
        scroll={{ x: true, y: "70vh" }}
        onRow={(record: InternalItemTypeGet) => ({
          onClick: () => {
            internalMarketplaceStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={
            isVisibleEditCreate === "edit"
              ? "Edit internal market item"
              : "Create internal market item"
          }
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <MarketplaceEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}

      <Modal
        visible={isVisibleImage}
        footer={null}
        onCancel={() => {
          setIsVisibleImage(false)
          setImage("")
        }}
      >
        <UploadComponent image={image} setImage={setImage} placeholder=" " />
        <Input addonBefore="URL:" value={image} style={{ marginTop: "1rem" }} />
      </Modal>
    </>
  )
})
