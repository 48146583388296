import { gameNames } from "abstract/constants/game-ids"
import { FormInstance, Tabs } from "antd"
import { FC } from "react"
import { useStore } from "stores/utils/use-store"
import { MicroPrizesList } from "./MicroPrizesList"

interface props {
  form: FormInstance<any>
}

export const MicroPrizes: FC<props> = ({ form }) => {

  const {
    dataStore: {
      managingJsonStore: { selectedElement: { name, data } },
      managingJsonStore,
    },
  } = useStore()


  return (
    <div style={{ background: "white", padding: "24px 16px 0px", width: "49%", marginRight: "auto" }}>
      <div style={{ fontWeight: 500, marginBottom: "16px" }}>Micro Prize (weekly)</div>

      {/* <Tabs>
        {data?.loungePageData?.games?.map((gameId, index) => (
          <Tabs.TabPane tab={gameNames[gameId]} key={index+1}> */}
      <MicroPrizesList gameId="" form={form} />
      {/* </Tabs.TabPane>
        ))}
      </Tabs> */}
    </div>
  )
}
