import { gameNames } from "abstract/constants/game-ids"
import { FormInstance, Radio, Tabs } from "antd"
import { Dispatch, FC, SetStateAction } from "react"
import { useStore } from "stores/utils/use-store"
import { GrandPrizesList } from "./GrandPrizesList"
import { MicroPrizes } from "./MicroPrizes"
import { ContestConfig, GrandPrizeRewards } from "domains/managingJson/store/types"


export const PrizesTab: FC<{
  form: FormInstance<any>
  distTypeGrand: "EVERY_WEEK" | "FINAL_WEEK_ONLY" | "ALL-TIME_LEADERBOARD"
  setDistTypeGrand: Dispatch<SetStateAction<"EVERY_WEEK" | "FINAL_WEEK_ONLY" | "ALL-TIME_LEADERBOARD">>
  grandPrizes: GrandPrizeRewards
  setGrandPrizes: Dispatch<SetStateAction<GrandPrizeRewards>>
}> = ({ form, distTypeGrand, setDistTypeGrand, grandPrizes, setGrandPrizes }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const distOnChange = e => {
    setDistTypeGrand(e.target.value)
    data.distributionType = e.target.value
  }

  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ background: "white", padding: "24px 16px 0px", width: "49%", marginRight: "auto" }}>
          <div style={{ fontWeight: 500, marginBottom: "16px" }}>Grand Prize</div>
          <div>Distribution type</div>
          <div style={{ display: "flex" }}>
            <Radio.Group onChange={distOnChange} value={distTypeGrand}>
              <Radio value="EVERY_WEEK">Every week</Radio>
              <Radio value="FINAL_WEEK_ONLY">Final week only</Radio>
              <Radio value="ALL-TIME_LEADERBOARD">All-time leaderboard</Radio>
            </Radio.Group>
          </div>
          <Tabs>
            {data?.loungePageData?.games?.map((gameId, index) => (
              <Tabs.TabPane tab={gameNames[gameId]} key={index+1}>
                <GrandPrizesList gameId={gameId} form={form} grandPrizes={grandPrizes} setGrandPrizes={setGrandPrizes} />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
        <MicroPrizes form={form} />
      </div>
    </div>
  )

}
