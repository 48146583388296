import React, { FC, useState } from "react"
import { observer } from "mobx-react"
import { Table, Modal, Tabs } from "antd"

import { useStore } from "stores/utils/use-store"

import { WheelValuesItem } from "domains/managingSpinCollect/components/WheelValuesItem"
import { wheelValuesColumns } from "domains/managingSpinCollect/components/WheelValuesList/columns"

import { DeleteIconButton, showDeleteConfirm, CreateNewElement } from "components"

import { Column } from "abstract/types/column.type"
import { ConfigItemAtList } from "domains/managingSpinCollect/store/types"

export const WheelValuesList: FC = observer(() => {
  const { TabPane } = Tabs
  const {
    dataStore: {
      managingSpinCollectStore,
      managingSpinCollectStore: { freeValues, paidValues },
    },
  } = useStore()
  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  const onClose = () => {
    managingSpinCollectStore.clearSelectedWheelValue()
    setEditCreateVisibility(null)
  }

  const columnsCurrent: Column[] = [
    ...wheelValuesColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: ConfigItemAtList) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm("wheel value", undefined, async() => {
              managingSpinCollectStore.funcAfterSuccess = async() => {
                await managingSpinCollectStore.getConfiguration()
                await managingSpinCollectStore.getFreeValues()
                await managingSpinCollectStore.getPaidValues()
              }

              await managingSpinCollectStore.deleteWheelValue(row.id)
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="wheel value" />
      <Tabs type="card">
        <TabPane tab="Free" key="1">
          <Table
            dataSource={freeValues}
            columns={columnsCurrent as any}
            rowKey="id"
            onRow={record => ({
              onClick: () => {
                setEditCreateVisibility("edit")
                managingSpinCollectStore.selectedElement = record
              },
            })}
          />
        </TabPane>
        <TabPane tab="Paid" key="2">
          <Table
            dataSource={paidValues}
            columns={columnsCurrent as any}
            rowKey="id"
            onRow={record => ({
              onClick: () => {
                setEditCreateVisibility("edit")
                managingSpinCollectStore.selectedElement = record
              },
            })}
          />
        </TabPane>
      </Tabs>
      {isVisibleEditCreate && (
        <Modal
          visible
          title={
            isVisibleEditCreate === "edit" ? "Edit wheel value list" : "Create wheel value list"
          }
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <WheelValuesItem editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
