import React, { FC } from "react"
import { Form, InputNumber } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import s from "./styles.module.css"
import { SubmitCancelButtons } from "components"

export const ChallengeEdit: FC<{ onClose: () => void }> = observer(({ onClose }) => {
  const {
    dataStore: {
      challengesStore: { selectedElement },
      challengesStore,
    },
  } = useStore()

  const onFinishHandler = async(data: any) => {
    challengesStore.funcAfterSuccess = () => challengesStore.getData()
    for await (const item of selectedElement.levels) {
      if (+item.level === 1) {
        if (data.goal1 !== item.goalValue || data.reward1 !== item.reward) {
          await challengesStore.update({
            levelId: item.id,
            reward: +data.reward1,
            goalValue: +data.goal1,
          })
        }
      }

      if (+item.level === 2) {
        if (data.goal2 !== item.goalValue || data.reward2 !== item.reward) {
          await challengesStore.update({
            levelId: item.id,
            reward: +data.reward2,
            goalValue: +data.goal2,
          })
        }
      }

      if (+item.level === 3) {
        if (data.goal3 !== item.goalValue || data.reward3 !== item.reward) {
          await challengesStore.update({
            levelId: item.id,
            reward: +data.reward3,
            goalValue: +data.goal3,
          })
        }
      }
    }
    onClose()
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (
    <Form
      onFinish={onFinishHandler}
      onFinishFailed={onFinishFailedHandler}
      style={{ margin: 16, display: "grid", justifyContent: "center" }}
    >
      <div style={{ paddingLeft: "1.5rem", paddingBottom: "1rem" }}>
        <span style={{ fontWeight: "bold" }}>Title: </span>
        {selectedElement.title}
      </div>
      <div className={s.table}>
        <div>Level</div>
        <div>Goal</div>
        <div>Reward</div>
      </div>

      {selectedElement.levels.map(item => {
        return (
          <div className={s.table} key={item.id}>
            <div>{item.level}</div>
            <Form.Item
              name={`goal${item.level}`}
              rules={[
                {
                  required: true,
                  message: "Please input goal!",
                },
              ]}
              initialValue={item.goalValue}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name={`reward${item.level}`}
              rules={[
                {
                  required: true,
                  message: "Please input Reward!",
                },
              ]}
              initialValue={item.reward}
            >
              <InputNumber min={1} />
            </Form.Item>
          </div>
        )
      })}
      <SubmitCancelButtons onClose={onClose} isLoading={challengesStore.isLoading} />
    </Form>
  )
})
