import { Button, Space } from "antd"
import { Pixel } from "domains/managingJson/store/types"
import { FC } from "react"
import { PixelEditRow } from "./PixelEditRow"

type Props = {
  pixels: Pixel[];
  onChange: (pixels: Pixel[]) => void;
};

export const PixelsEditorV2: FC<Props> = ({ onChange, pixels }) => {

  const onChangePixel = (index: number, callback: (pixel: Pixel) => Pixel) => {
    const newPixels = [...pixels]
    newPixels[index] = callback(newPixels[index])
    onChange(newPixels)
  }

  const addNewPixel = () => {
    onChange([...pixels, { src: "" }])
  }

  const deletePixel = (index: number) => {
    const newPixels = [...pixels]
    newPixels.splice(index, 1)
    onChange(newPixels)
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {pixels.map((pixel, index) => (
        <PixelEditRow key={index} pixel={pixel} index={index} deletePixel={deletePixel} onChange={onChangePixel} />
      ))}

      <Button type="primary" onClick={addNewPixel}>
        Add new Pixel
      </Button>
    </Space>
  )
}
