import { computed, makeObservable, observable, transaction } from "mobx"
import { PaginationStore } from "stores/data/pagination.store"
import { RootStore } from "stores/root.store"
import { createCode, deleteCode, getCodes, updateCode } from "../api"
import { CodesInventoryType } from "./types"

export class CodesStore extends PaginationStore<CodesInventoryType, any> {
  @observable
  private _codesInventory: CodesInventoryType[]

  @observable
  private _selectedElement: CodesInventoryType

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<CodesInventoryType[]> {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getCodes)

    transaction(() => {
      this.codesInventory = res.data
      this.total = res.total
    })

    return res.data
  }

  async createCodes(body: CodesInventoryType) {
    await this.defaultFunction(createCode, body)
  }

  async updateCodes(id: string, body: CodesInventoryType) {
    await this.defaultFunction(updateCode, id, body)
  }

  async deleteCodes(id: string) {
    await this.defaultFunction(deleteCode, id)
  }

  @computed
  get codesInventory(): CodesInventoryType[] {
    return this._codesInventory
  }

  set codesInventory(codesInventory: CodesInventoryType[]) {
    this._codesInventory = codesInventory
  }

  @computed
  get selectedElement(): CodesInventoryType {
    return this._selectedElement
  }

  set selectedElement(value: CodesInventoryType) {
    this._selectedElement = value
  }

  clearSelectedElement() {
    this._selectedElement = {}
  }
}
