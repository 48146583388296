import { EntityToUpdate } from "abstract/entity-to-update"
import { apiCall } from "api-calls/api-call"
import { PromoPopUp } from "domains/promo-pop-ups/store/types"

export const getPromoPopups = async() => {
  return apiCall({
    method: "GET",
    path: "promotion-popup/all",
  })
}

export const createPromoPopup = async(values: EntityToUpdate<PromoPopUp>) => {
  return apiCall({
    method: "POST",
    path: "promotion-popup/create",
    body: values,
  })
}

export const updatePromoPopup = async(id: string, values: EntityToUpdate<PromoPopUp>) => {
  return apiCall({
    method: "PUT",
    path: `promotion-popup/${id}`,
    body: values,
  })
}

export const deletePromoPopups = async(id: string) => {
  return apiCall({
    method: "DELETE",
    path: `promotion-popup/${id}`,
  })
}
