import { EntityToUpdate } from "abstract/entity-to-update"
import { apiCall } from "api-calls/api-call"
import { NotificationsResponse, NotificationTypeCreation } from "../store/types"

export const getNotifications = async(
  filter: string,
  page: number,
  limit: number,
): Promise<{
  data: NotificationsResponse[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string } = {
    page,
    limit,
  }

  if (filter) query.filter = filter

  return apiCall({
    method: "GET",
    path: "notifications",
    query: query,
  })
}

export const createNotification = async(data: EntityToUpdate<NotificationTypeCreation>): Promise<{
  data: NotificationsResponse;
}> => {
  return apiCall({
    method: "POST",
    path: "notifications",
    body: data,
  })
}

export const deleteNotification = async(id: string): Promise<{
  data: NotificationsResponse;
}> => {
  return apiCall({
    method: "DELETE",
    path: `notifications/${id}`,
  })
}

export const getNotification = async(id: string): Promise<{
  data: NotificationsResponse;
}> => {
  return apiCall({
    method: "GET",
    path: `notifications/${id}`,
  })
}

export const sendNotification = async(id: string): Promise<{
  data: NotificationsResponse;
}> => {
  return apiCall({
    method: "POST",
    path: `notifications/${id}/send`,
  })
}
