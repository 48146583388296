import { Tabs } from "antd"
import * as React from "react"
import { PromocodesList } from "../ItemTypes"
import { GroupsList } from "../Groups"

export const PromocodesSettings: React.FunctionComponent = () => {
  return (
    <Tabs>
      <Tabs.TabPane tab="Promo Code" key="1">
        <PromocodesList />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Promo Code Groups" key="2">
        <GroupsList />
      </Tabs.TabPane>
    </Tabs>
  )
}
