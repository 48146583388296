import React, { FC } from "react"
import { DatePicker, Form, Input, InputNumber, Select } from "antd"
import moment from "moment"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { Booster } from "domains/boosters/store/boosters.store"
import { gameNames } from "abstract/constants/game-ids"
import { SubmitCancelButtons } from "components"

export const BoosterEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const { Option } = Select
  const [form] = Form.useForm()

  const {
    dataStore: {
      boostersStore: { selectedElement: { name, booster, gameIds, endDate, startDate } },
      boostersStore,
    },
  } = useStore()

  const onFinishHandler = async(e: Booster) => {
    const gameIdsUpd = e.gameIds.map((item: any) => {
      if (isNaN(+item)) {
        const game = Object.entries(gameNames).find(el => el[1] === item)
        if (game) {
          return +game[0]
        }
      }

      return +item
    })
    const data = {
      name: e.name,
      booster: e.booster,
      startDate: e.date[0],
      endDate: e.date[1],
      gameIds: gameIdsUpd,
    }

    boostersStore.funcAfterSuccess = async() => {
      onClose()
      form.resetFields()
      await boostersStore.getData()
    }

    editType === "edit" ? await boostersStore.update(data) : await boostersStore.create(data)
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (
    <Form form={form} onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
      >
        <Input maxLength={25} />
      </Form.Item>

      <Form.Item
        label="Booster in %"
        name="booster"
        initialValue={booster || 20}
        rules={[
          {
            required: true,
            message: "Please input Booster!",
          },
        ]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item
        label="Select Game Ids"
        name="gameIds"
        initialValue={gameIds?.map((el: number) => gameNames[el])}
      >
        <Select mode="multiple">
          {Object.entries(gameNames).map(el => (
            <Option key={el[0]} value={el[0]}>{el[1]}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Starts at / Ends at"
        name="date"
        initialValue={
          startDate && endDate ? [moment(startDate as Date), moment(endDate as Date)] : ""
        }
        rules={[
          {
            required: true,
            message: "Please Select Date!",
          },
        ]}
      >
        <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
      </Form.Item>

      <SubmitCancelButtons onClose={onClose} isLoading={boostersStore.isLoading} />
    </Form>
  )
})
