import { Column } from "abstract/types/column.type"
import { Tag } from "antd"
import { defaultFormat } from "utils/date"

export const promocodesGroupsColumns: Column[] = [
  { title: "Name", key: "name", dataIndex: "name", fixed: true },
  {
    title: "Time limitations",
    key: "timeLimitations",
    dataIndex: ["groupPayload", "availableDaysAfterRegistration"],
  },
  {
    title: "Limit redeems",
    key: "limitRedeems",
    dataIndex: ["groupPayload", "maxNumberOfRedeems"],
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => <Tag color="green">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (el: Date) => <Tag color="blue">{defaultFormat(el)}</Tag>,
  },
]
