import {
  Breadcrumb,
  Button,
  Space,
  Typography,
} from "antd"
import Styles from "./styles.module.css"


const { Title } = Typography

type Props = {
  name: string,
  onSave?: () => void,
  onCancel?: () => void,
  onDelete?: () => void,
}

export const SpecialEventEditHeader = ({ name, onSave, onCancel, onDelete }: Props) => {
  return (
    <div className={Styles.header}>
      <Space direction="vertical">
        <Breadcrumb>
          <Breadcrumb.Item href="/special-events/">Special Events</Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
        <Title level={3}>{name}</Title>
      </Space>
      <Space size="middle">
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        {onDelete && <Button danger onClick={onDelete}>Delete</Button>}
        {onSave && <Button type="primary" onClick={onSave}>Save changes</Button>}
      </Space>
    </div>
  )
}
