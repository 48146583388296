export const purchasedGiftCardsColumns = [
  { title: "Item Id", dataIndex: "itemId", key: "itemId" },
  {
    title: "Item Name",
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: "Item Price",
    dataIndex: "itemPrice",
    key: "itemPrice",
  },
  {
    title: "Login",
    dataIndex: "login",
    key: "login",
  },
  {
    title: "Key",
    dataIndex: "giftCode",
    key: "giftCode",
  },
  {
    title: "Purchased At",
    dataIndex: "purchasedAt",
    key: "purchasedAt",
  },
]
