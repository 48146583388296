import {
  Challenge,
  CreateChallengeDTO,
  StatusEnum,
  TemplateChallenge,
  UpdatedChallenge,
} from "domains/challenges/store/types"
import { apiCall } from "api-calls/api-call"

export const getChallenges = async(
  gameId: string,
  page: number,
  limit: number,
): Promise<{
  data: { payload: { goals: Challenge[]; total: number } };
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string; gameId?: string } = {
    page,
    limit,
  }

  if (gameId) query.gameId = gameId

  return apiCall({
    method: "GET",
    path: "challenges/goal/get-goals-with-levels-by-search",
    query,
  })
}

export const getActiveChallenges = async() => {
  return apiCall({
    method: "GET",
    path: "challenges/active-challenges",
  })
}

export const updateActiveChallenges = async(activeGoalId: string, data: { newGoalId: string }) => {
  console.log()

  return apiCall({
    method: "PUT",
    path: `challenges/active-challenges/goal/${activeGoalId}/replace`,
    body: data,
  })
}

export const createChallenge = async(data: CreateChallengeDTO): Promise<void> => {
  return apiCall({
    method: "POST",
    path: "challenges/goal/create",
    body: data,
  })
}

export const updateChallenge = async(data: UpdatedChallenge): Promise<void> => {
  return apiCall({
    method: "PATCH",
    path: "challenges/goal/update-goal-level-data",
    body: data,
  })
}

export const updateGoalTemplate = async(
  goalId: string,
  data: TemplateChallenge,
): Promise<void> => {
  return apiCall({
    method: "PUT",
    path: `challenges/goal/${goalId}/update`,
    body: data,
  })
}

export const updateStatusChallenge = (goalId: string, status: StatusEnum) => {
  const query: { status: string } = { status }

  return apiCall({
    method: "PUT",
    path: `challenges/goal/${goalId}/status-update`,
    query,
  })
}
