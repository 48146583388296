import React, { FC, useState, useEffect } from "react"
import { useStore } from "stores/utils/use-store"

import { Form, Input, InputNumber, Select, Switch, DatePicker } from "antd"
import { observer } from "mobx-react"

import { ParentToUpdate, PrizeType } from "domains/promocodes/store/types"

import Styles from "./styles.module.css"
import moment from "moment"
import { FullRaffle } from "domains/raffles/store/types"
import { SubmitCancelButtons } from "components"
import GeoPickerElement from "components/geoPicker"

const { Option } = Select
const { RangePicker } = DatePicker

export const PromocodeEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      parentPromocodesStore: {
        selectedElement: {
          name,
          startDate,
          endDate,
          promoCodeType: storePromoCodeType,
          countries,
          promoGroupId,
          parentCode,
          childPromoCodesLimit,
          codeSize,
          promoPrizeTypeId,
          prizePayload,
          successMessage,
        },
        parentPrizes,
      },
      parentPromocodesStore,
      promocodesGroupsStore,
      subscriptionStore,
      subscriptionStore: { subscriptions },
    },
  } = useStore()

  const [form] = Form.useForm()

  const [promoCodeType, setPromoCodeType] = useState<"PUBLIC" | "PERSONALIZED" | "">(storePromoCodeType || "")
  const [prizeType, setPrizeType] = useState<PrizeType | "">("")

  const [isGeo, setGeo] = useState<boolean>(countries?.length > 0)
  const [selectedElement] = useState<FullRaffle | null>(null)

  useEffect(() => {
    promocodesGroupsStore.getData()
    parentPromocodesStore.getParentPrizes()
    subscriptionStore.getData()
  }, [promocodesGroupsStore, parentPromocodesStore, subscriptionStore])

  useEffect(() => {
    const prize = parentPrizes.find(item => item.id === promoPrizeTypeId)
    if (prize) {
      setPrizeType(prize.type)
    }
  }, [promoPrizeTypeId, parentPrizes])

  const onFinishHandler = async(event: ParentToUpdate & {
        countries: string;
        successMessageHeader: string;
        successMessageBody: string;
        amount?: number;
        numberOfTickets?: number;
        ticketId?: string;
        subscriptionId?: string;
        billingPeriod?: "YEAR" | "MONTH";
      }) => {
    const dataToBack: ParentToUpdate = {
      name: event.name,
      promoGroupId: event.promoGroupId,
      startDate: event.date && event.date[0],
      endDate: event.date && event.date[1],
      prizePayload: {},
      promoPrizeTypeId: event.promoPrizeTypeId,
      successMessage: {
        header: event.successMessageHeader,
        body: event.successMessageBody,
      },
    }
    if (isGeo) {
      dataToBack.countries = event.countries
    }
    if (promoCodeType === "PUBLIC") {
      dataToBack.promoCodeType = promoCodeType
      dataToBack.parentCode = event.code
      dataToBack.childPromoCodesLimit = event.childPromoCodesLimit
    }
    if (promoCodeType === "PERSONALIZED") {
      dataToBack.promoCodeType = promoCodeType
      dataToBack.parentCode = event.prefix
      dataToBack.codeSize = event.codeSize
      dataToBack.numberOfCodes = event.numberOfCodes
      dataToBack.childPromoCodesLimit = event.numberOfCodes
    }
    if (prizeType === "COINS") {
      dataToBack.prizePayload = { amount: event.amount }
    }
    if (prizeType === "RAFFLES") {
      dataToBack.prizePayload = {
        ticketId: event.ticketId,
        numberOfTickets: event.numberOfTickets,
      }
    }
    if (prizeType === "PREMIUM") {
      dataToBack.prizePayload = {
        subscriptionId: event.subscriptionId,
        billingPeriod: event.billingPeriod,
      }
    }
    parentPromocodesStore.funcAfterSuccess = async() => {
      onClose()
      form.resetFields()
      await parentPromocodesStore.getData()
    }
    editType === "edit"
      ? await parentPromocodesStore.update(dataToBack)
      : await parentPromocodesStore.create(dataToBack)
  }

  return (
    <Form onFinish={onFinishHandler} labelCol={{ span: 6 }} labelAlign="left" form={form}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input item name!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Group"
        name="promoGroupId"
        rules={[
          {
            required: true,
            message: "Select category type",
          },
        ]}
        initialValue={promoGroupId}
      >
        <Select placeholder="Choose a group" style={{ width: "100%" }}>
          {promocodesGroupsStore.groups.map(item => {
            return (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Date"
        name="date"
        initialValue={
          startDate && endDate ? [moment(startDate as Date), moment(endDate as Date)] : ""
        }
        rules={[
          {
            required: true,
            message: "Select date",
          },
        ]}
      >
        <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label="GEO:"
        name="isGeo"
        valuePropName="checked"
        style={{ marginBottom: "14px" }}
        initialValue={isGeo}
      >
        <Switch
          onChange={() => {
            setGeo(!isGeo)
          }}
        />
      </Form.Item>
      {isGeo && (
        <GeoPickerElement label="Choose GEOs" name="countries" initialValue={countries} />
      )}

      <Form.Item
        label="Type"
        name="promoCodeType"
        initialValue={storePromoCodeType}
        rules={[
          {
            required: true,
            message: "Select promo code type",
          },
        ]}
      >
        <Select
          placeholder="Choose type"
          style={{ width: "100%" }}
          onChange={value => setPromoCodeType(value)}
          disabled={editType === "edit"}
        >
          {[
            { name: "Public code", id: "PUBLIC" },
            { name: "Persinalized codes", id: "PERSONALIZED" },
          ].map(item => {
            return (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      {promoCodeType === "PUBLIC" && (
        <>
          <Form.Item label="Code" name="code" initialValue={parentCode}>
            <Input disabled={editType === "edit"} />
          </Form.Item>
          <Form.Item
            label="Number of codes to generate"
            name="childPromoCodesLimit"
            initialValue={childPromoCodesLimit}
          >
            <InputNumber style={{ width: "100%" }} disabled={editType === "edit"} />
          </Form.Item>
        </>
      )}
      {promoCodeType === "PERSONALIZED" && (
        <>
          <Form.Item label="Prefix " name="prefix" initialValue={parentCode}>
            <Input disabled={editType === "edit"} />
          </Form.Item>
          <Form.Item
            label="Number of symbols after prefix"
            name="codeSize"
            initialValue={codeSize}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={6}
              max={12}
              disabled={editType === "edit"}
            />
          </Form.Item>
          <Form.Item
            label="Number of codes to generate"
            name="numberOfCodes"
            initialValue={childPromoCodesLimit}
          >
            <InputNumber style={{ width: "100%" }} min={1} disabled={editType === "edit"} />
          </Form.Item>
        </>
      )}

      <Form.Item
        label="Prize type"
        name="promoPrizeTypeId"
        rules={[
          {
            required: true,
            message: "Select prize type",
          },
        ]}
        initialValue={promoPrizeTypeId}
      >
        <Select
          placeholder="Choose prize type"
          style={{ width: "100%" }}
          onChange={value => {
            const prize = parentPrizes?.find(item => item.id === value)
            if (prize) {
              setPrizeType(prize.type)
            }
          }}
          disabled={editType === "edit"}
        >
          {parentPrizes.map(item => {
            return (
              <Option key={item.id} value={item.id}>
                {item.type}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      {prizeType === "COINS" && (
        <Form.Item label="Buffs amount" name="amount" initialValue={prizePayload?.amount}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      )}
      {prizeType === "RAFFLES" && (
        <>
          {/* <Form.Item
              label="Raffle to enter"
              name="raffleId"
              initialValue={prizePayload?.raffleId}
            >
              <Select
                placeholder="Choose prize type"
                style={{ width: '100%' }}
                onChange={(value) => {
                  const raffle = validRaffles.find((el) => el.id === value);

                  if (raffle) {
                    setSelectedRaffle(raffle);
                  }
                }}
              >
                {validRaffles?.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
          {selectedElement && (
            <div />
          )}
        </>
      )}
      {prizeType === "PREMIUM" && (
        <>
          <Form.Item
            label="Premium Type"
            name="subscriptionId"
            initialValue={prizePayload?.subscriptionId}
          >
            <Select placeholder="Choose premium type" style={{ width: "100%" }}>
              {subscriptions?.map(item => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Duration"
            name="billingPeriod"
            initialValue={prizePayload?.billingPeriod}
          >
            <Select placeholder="Choose premium type" style={{ width: "100%" }}>
              {["YEAR", "MONTH", "DAY"].map(item => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </>
      )}

      <p>Success Message:</p>
      <div className={Styles.grid}>
        <Form.Item
          label="Header"
          name="successMessageHeader"
          rules={[
            {
              required: true,
              message: "Please input header!",
            },
          ]}
          initialValue={successMessage?.header}
        >
          <Input.TextArea
            className={Styles.textarea}
            autoSize={{ minRows: 2 }}
            showCount
            maxLength={200}
          />
        </Form.Item>
        <Form.Item label="Body" name="successMessageBody" initialValue={successMessage?.body}>
          <Input.TextArea autoSize={{ minRows: 2 }} showCount maxLength={200} />
        </Form.Item>
      </div>
      <SubmitCancelButtons onClose={onClose} isLoading={parentPromocodesStore.isLoading} />
    </Form>
  )
})
