import React, { useEffect, useState } from "react"
import { Card, Row, Col, Form, InputNumber, Divider, Typography, Select } from "antd"
import Styles from "./styles.module.css"
import { gameNames } from "abstract/constants/game-ids"
import { NotificationEditor } from "../notificationEditor"

const { Title } = Typography

type OffsetUnit = "%" | "px"

type SelectStackingWrapperTopOffsetUnitProps = {
  value: OffsetUnit
  onChange: (value: OffsetUnit) => void
}

const SelectStackingWrapperTopOffsetUnit = ({ value, onChange }: SelectStackingWrapperTopOffsetUnitProps) => (
  <Select value={value} onChange={onChange} className="select-after">
    <Select.Option value="%">%</Select.Option>
    <Select.Option value="px">px</Select.Option>
  </Select>
)

type GameCardProps = {
  index: number,
  contestMode?: boolean
}

export const GameCard = ({ index, contestMode }: GameCardProps) => {
  const form = Form.useFormInstance()
  const [offsetUnit, setOffsetUnit] = useState<OffsetUnit>(
    form.getFieldValue(["notificationsPerGame", index, "stackingWrapperTopOffsetUnit"]) || "%",
  )

  useEffect(() => {
    const prevOffsetValue = form.getFieldValue(["notificationsPerGame", index, "stackingWrapperTopOffset"])
    let newOffsetValue = prevOffsetValue
    if (offsetUnit === "%" && prevOffsetValue > 100) {
      newOffsetValue = 0
    }
    form.setFieldValue(["notificationsPerGame", index, "stackingWrapperTopOffset"], newOffsetValue)
    form.setFieldValue(["notificationsPerGame", index, "stackingWrapperTopOffsetUnit"], offsetUnit)
  }, [offsetUnit])

  return (
    <Card bodyStyle={{ paddingTop: 10 }} >
      <div style={{ marginBottom: 24 }} >
        <Row >
          <Col span={20} className={Styles.titleCol}>
            <Title
              className={Styles.title}
              level={2}
            >{gameNames[form.getFieldValue(["notificationsPerGame", index, "gameId"])]} Notifications
            </Title>
            <Divider style={{ flexGrow: 1, minWidth: "auto" }} />

          </Col>
          <Col span={4}>
            {!contestMode && (
              <Form.Item
                name={[index, "notificationsSpawnCap"]}
                label="Notification Spawn Cap (0 = unlimited)"
                tooltip="Caps the number of notifications that can be triggered in a single gaming session."
                style={{ marginBottom: 30 }}
              >
                <InputNumber min={0} step={1} style={{ width: "100%" }} />
              </Form.Item>)}
          </Col>
        </Row>
        <Row>
          <Form.Item
            name={[index, "stackingWrapperTopOffset"]}
            label="Stacking Wrapper Top Offset"
            tooltip="The distance from the top of the screen to the top of the wrapper for notifications with type 'stacking'"
          >
            <InputNumber
              max={offsetUnit === "%" ? 100 : undefined}
              min={0}
              addonAfter={
                <SelectStackingWrapperTopOffsetUnit
                  value={offsetUnit}
                  onChange={value => setOffsetUnit(value)}
                />
              }
            />
          </Form.Item>
        </Row>

        <NotificationEditor parentIndex={index} contestMode={contestMode} />
      </div>
    </Card>
  )
}
