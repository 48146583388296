import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Form, Button, Checkbox, InputNumber, Input } from "antd"

import { useStore } from "stores/utils/use-store"
import { Configuration } from "domains/managingSpinCollect/store/types"
import { DynamicUIType } from "domains/managingJson/store/types"
import { notifications } from "notifications"
import { UploadComponent } from "components"

export const ConfigurationPage: FC = observer(() => {
  const {
    dataStore: {
      managingJsonStore,
      managingSpinCollectStore,
      managingSpinCollectStore: { configuration: { status, freeSpinInterval } },
    },
  } = useStore()
  const [isActive, setActive] = useState<boolean>(false)
  const [prevData, setPrevData] = useState<DynamicUIType>()
  const [dynamicUI, setdynamicUI] = useState(false)

  const [form] = Form.useForm()
  // wheel spin backgrounds
  const [wheelSpin, setWheelSpin] = useState(false)
  const [wheelSpinImage, setWheelSpinImage] = useState("")
  const [wheelSpinImagePaid, setWheelSpinImagePaid] = useState("")

  // wheel spin guide text
  const [wheelSpinText, setWheelSpinText] = useState(false)
  const [wheelSpinGuideTextFree, setWheelSpinGuideTextFree] = useState("")
  const [wheelSpinGuideTextPaid, setWheelSpinGuideTextPaid] = useState("")
  useEffect(() => {
    if (managingJsonStore?.itemTypes === undefined || managingJsonStore?.itemTypes?.length === 0) managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const dynamicUIData = managingJsonStore?.itemTypes?.filter(item => item.type === "dynamicUI")
    if (dynamicUIData && dynamicUIData.length > 0) {
      const data = dynamicUIData[0].data as DynamicUIType
      setdynamicUI(true)
      setPrevData(data)
      if (data.wheelSpinImage || data.wheelSpinImagePaid) {
        setWheelSpin(true)
        setWheelSpinImage(data.wheelSpinImage || "")
        setWheelSpinImagePaid(data.wheelSpinImagePaid || "")
      }
      if (data.wheelSpinGuideTextFree || data.wheelSpinGuideTextPaid) {
        setWheelSpinText(true)
        setWheelSpinGuideTextFree(data.wheelSpinGuideTextFree || "")
        setWheelSpinGuideTextPaid(data.wheelSpinGuideTextPaid || "")
      }
    }
  }, [managingJsonStore.itemTypes])

  useEffect(() => {
    setActive(status === "ACTIVE")
  }, [status])

  const onFinishHandler = async(event: Configuration) => {
    await managingSpinCollectStore.updateConfiguration({
      status: isActive ? "ACTIVE" : "INACTIVE",
      freeSpinInterval: event.freeSpinInterval,
    })
    // await managingSpinCollectStore.getConfiguration();

    const data: DynamicUIType = { ...prevData }
    data.wheelSpinImage = (wheelSpin && wheelSpinImage) || undefined
    data.wheelSpinImagePaid = (wheelSpin && wheelSpinImagePaid) || undefined
    data.wheelSpinGuideTextFree = (wheelSpinText && wheelSpinGuideTextFree) || undefined
    data.wheelSpinGuideTextPaid = (wheelSpinText && wheelSpinGuideTextPaid) || undefined

    managingJsonStore.funcAfterSuccess = async() => {
      notifications.success("Saved successfully")
      await managingJsonStore.getData()
    }

    dynamicUI
      ? await managingJsonStore.update({
        name: "dynamicUI",
        type: "dynamicUI",
        data,
      })
      : await managingJsonStore.create({
        name: "dynamicUI",
        type: "dynamicUI",
        data,
      })
  }

  const handleChangeActivity = () => {
    setActive(prevState => !prevState)
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <Checkbox checked={isActive} onChange={handleChangeActivity}>
        Active
      </Checkbox>
      <Form.Item
        label="Free spin interval"
        name="freeSpinInterval"
        initialValue={freeSpinInterval}
        rules={[
          {
            required: true,
            message: "Input interval!",
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (wheelSpin) {
            setWheelSpinImage("")
            setWheelSpinImagePaid("")
          }
          setWheelSpin(!wheelSpin)
        }}
        value={wheelSpin}
        checked={wheelSpin}
      >
        Wheel spin images
      </Checkbox>
      {wheelSpin && (
        <div style={{ display: "flex", gap: "10px" }}>
          <UploadComponent
            image={wheelSpinImage}
            setImage={setWheelSpinImage}
            placeholder="Wheel Spin image"
          />
          <UploadComponent
            image={wheelSpinImagePaid}
            setImage={setWheelSpinImagePaid}
            placeholder="Wheel Spin image paid"
          />
        </div>
      )}
      <br />

      <Checkbox
        style={{ margin: "15px 0" }}
        onChange={() => {
          if (wheelSpinText) {
            setWheelSpinGuideTextFree("")
            setWheelSpinGuideTextPaid("")
          }
          setWheelSpinText(!wheelSpinText)
        }}
        value={wheelSpinText}
        checked={wheelSpinText}
      >
        Wheel spin guide texts
      </Checkbox>
      {wheelSpinText && (
        <div style={{ display: "flex", gap: "10px" }}>
          <Input
            value={wheelSpinGuideTextFree}
            onChange={e => setWheelSpinGuideTextFree(e.target.value)}
            placeholder="Wheel Spin guide text free, write points by ;; (two ;)"
          />
          <Input
            value={wheelSpinGuideTextPaid}
            onChange={e => setWheelSpinGuideTextPaid(e.target.value)}
            placeholder="Wheel Spin guide text paid, write points by ;; (two ;)"
          />
        </div>
      )}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100px" }}
        >
          Confirm
        </Button>
      </Form.Item>
    </Form>
  )
})
