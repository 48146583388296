import { FC, useMemo, useState } from "react"
import useAdvertisers from "../hooks/useAdvertisers"
import Title from "antd/lib/typography/Title"
import { Button, Modal, Space, Switch, Table } from "antd"
import { IAdvertiser } from "../api/types"
import { CreateAdvertiser } from "./createAdvertiser"
import { useNavigate } from "react-router-dom"
import { updateAdvertiser } from "../api"
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import { priorityUtils } from "../utils/priority"

const InternalOWAdvertisersPage: FC = () => {
  const { advertisers, updateAdvertiser: updateAdvertiserEntry } = useAdvertisers()
  const [showCreateAdvertiser, setShowCreateAdvertiser] = useState(false)
  const navigate = useNavigate()
  const [patching, setPatching] = useState(false)

  const onPatch = (advertiser: IAdvertiser) => {
    if (patching) return
    setPatching(true)
    updateAdvertiser(advertiser).then(updated => {
      updateAdvertiserEntry(updated)
    }).finally(() => {
      setPatching(false)
    })
  }

  const {
    isTopPriority,
    isLowestPriority,
    switchPriority,
  } = useMemo(() => priorityUtils(advertisers || [], updateAdvertiser, updateAdvertiserEntry), [advertisers, updateAdvertiserEntry])

  const onSwitchPriority = (advertiser: IAdvertiser, up: boolean) => {
    if (patching) return
    setPatching(true)
    switchPriority(advertiser, up).finally(() => setPatching(false))
  }

  const onClose = () => setShowCreateAdvertiser(false)

  const renderTrimmedDescription = (description: string) => {
    if (!description) return ""
    const willTrim = description.length > 100

    return willTrim ? description.substring(0, 100).trim() + "..." : description
  }

  return (
    <div>
      <Modal
        open={showCreateAdvertiser}
        title="Create an Advertiser"
        width="700px"
        footer={null}
        onCancel={onClose}
        style={{ top: "10px" }}
      >
        <CreateAdvertiser onClose={onClose} />
      </Modal>
      <Space
        direction="horizontal"
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: 12,
        }}
      >
        <Title level={2}>All Advertisers</Title>
        <Button
          type="primary"
          onClick={() => {
            setShowCreateAdvertiser(true)
          }}
        >
          Add an Advertiser
        </Button>
      </Space>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Table<IAdvertiser>
          dataSource={advertisers}
          pagination={false}
          rowKey={record => record.id}
          scroll={{ x: true }}
          columns={[
            {
              key: "imageUrl",
              title: "Image",
              dataIndex: "imageUrl",
              render: url => <img src={url} alt="" style={{ width: 48, height: 48 }} />,
            },
            { key: "name", title: "Advertiser Name", dataIndex: "name" },
            {
              key: "description",
              title: "Description",
              dataIndex: "description",
              render: renderTrimmedDescription,
            },
            {
              key: "regions",
              title: "Regions",
              dataIndex: "geoRegions",
              render: regions => regions.join(", "),
            },
            {
              key: "forMobile",
              title: "For Mobile",
              dataIndex: "forMobile",
              render: forMobile => (forMobile ? "Yes" : "No"),
            },
            {
              key: "active",
              title: "Active",
              dataIndex: "active",
              render: (active, advertiser) => (
                <div onClick={e => e.stopPropagation()}>
                  <Switch
                    onClick={() => onPatch({ ...advertiser, active: !advertiser.active })}
                    disabled={patching}
                    checked={active}
                  />
                </div>
              ),
            },
            {
              key: "priority",
              title: "Priority",
              dataIndex: "priority",
              render: (priority, advertiser) => (
                <div
                  onClick={e => e.stopPropagation()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 12,
                    pointerEvents: patching ? "none" : "auto",
                  }}
                >
                  <Button
                    disabled={isTopPriority(advertiser)}
                    onClick={
                      () => onSwitchPriority(advertiser, true)
                    }
                  >
                    <ArrowUpOutlined />
                  </Button>
                  <Button
                    disabled={isLowestPriority(advertiser)}
                    onClick={
                      () => onSwitchPriority(advertiser, false)
                    }
                  >
                    <ArrowDownOutlined />
                  </Button>
                </div>
              ),
            },
          ]}
          onRow={record => ({
            style: { cursor: "pointer" },
            onClick: () => {
              navigate(`/internal-offerwall/advertisers/${record.id}`)
            },
          })}
          loading={!advertisers}
        />
      </Space>
    </div>
  )
}

export default InternalOWAdvertisersPage
