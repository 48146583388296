import { gameNamesDesktop } from "abstract/constants/game-ids"
import { Form, Tabs } from "antd"
import Title from "antd/lib/typography/Title"
import { FC, useEffect, useState } from "react"
import { PrizesEditor } from "./components/PrizesEditor"
import { GrandPrizeRewards } from "domains/managingJson/store/types"
import { toJS } from "mobx"

type Props = {
  games: string[];
  data: GrandPrizeRewards;
};

export const RewardsConfigurationTab: FC<Props> = ({ games, data }) => {
  const [prizes, setPrizes] = useState<GrandPrizeRewards>(toJS(data))
  const form = Form.useFormInstance()

  useEffect(() => {
    form.setFieldValue("grandPrizeRewards", prizes)
  }, [prizes])

  const tabs = games?.map(gameIdString => {
    const gameId = Number(gameIdString)
    const gameName = gameNamesDesktop[gameId]
    const prizesForGame = prizes?.[gameId] || []

    return {
      label: gameName,
      key: gameIdString,
      children: <PrizesEditor prizes={prizesForGame} gameIdString={gameIdString} onChange={setPrizes} />,
    }
  })

  return (
    <div>
      <Title>Grand Prizes</Title>
      <Tabs items={tabs} />
    </div>
  )
}
