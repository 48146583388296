import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { Modal, Table } from "antd"
import { ContestEdit } from "./contest.edit"
import { CreateNewElement, DeleteIconButton, EditIconButton, showDeleteConfirm } from "components"
import { ConfigItemAtList, ConfigItem } from "domains/managingJson/store/types"
import { Column } from "abstract/types/column.type"
import { ContestNameEdit } from "./contest.nameEdit"
import "./contest.css"
import { ContestListEdit } from "./contest-list.edit"

export const ContestsList: FC = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    managingJsonStore.itemTypes = managingJsonStore?.itemTypesFromBackend?.filter(item => item.type === "contests")
  }, [managingJsonStore.itemTypesFromBackend])

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)
  const [isVisibleNameEdit, setIsVisibleNameEdit] = useState<boolean>(false)

  const onClose = () => {
    managingJsonStore.clearSelectedElement()
    setEditCreateVisibility(null)
    setIsVisibleNameEdit(false)
  }

  function formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = String(date.getSeconds()).padStart(2, "0")

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  const sortByDate = (a: any, b: any): number => {
    if (a === undefined || b === undefined) return 0
    if (a < b) {
      return 1
    }
    if (a > b) {
      return -1
    }

    return 0
  }

  const sortByString = (a: any, b: any): number => {
    if (a === undefined || b === undefined) return 0
    if (a < b) {
      return 1
    }
    if (a > b) {
      return -1
    }

    return 0
  }

  type ColumnUpd = Column & { sorter?: (a: any, b: any) => number };
  const columnsCurrent: ColumnUpd[] = [
    {
      sorter(a, b) {
        return sortByString(a["name"], b["name"])
      },
      title: "Contest Name",
      key: "name",
      dataIndex: "name",
      render: (value: string) => (
        <div
          // onClick={() => setIsVisibleNameEdit(true)}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
          className="name"
        >
          {value}
        </div>
      ),
    },
    {
      sorter(a, b) {
        return sortByString(a["data"]["contestType"], b["data"]["contestType"])
      },
      title: "Type",
      key: "data",
      dataIndex: "data",
      render: (el: ConfigItem<"contests">) => {
        return (
          <div>
            {el["contestType"]}
          </div>)
      },
    },
    {
      title: "Status",
      key: "data",
      dataIndex: "data",
      render: (el: ConfigItem<"contests">) => {
        if (el["startDate"] === undefined || el["endDate"] === undefined) {
          return (
            <div>
              <div color="red">Not set</div>
            </div>)
        }

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {new Date(el["startDate"]) < new Date() ? (
              new Date(el["endDate"]) < new Date() ? (
                <>
                  <div style={{ width: "6px", height: "6px", backgroundColor: "red", borderRadius: "999px", marginRight: "5px" }} />
                  <div>Finished</div>
                </>
              ) : (
                <>
                  <div style={{ width: "6px", height: "6px", backgroundColor: "green", borderRadius: "999px", marginRight: "5px" }} />
                  <div>Online</div>
                </>
              )
            ) : (
              <>
                <div style={{ width: "6px", height: "6px", backgroundColor: "blue", borderRadius: "999px", marginRight: "5px" }} />
                <div>Upcoming</div>
              </>
            )}
          </div>)
      },
    },
    {
      sorter: (a, b) => sortByDate(a["data"]["endDate"], b["data"]["endDate"]),
      title: "Start date",
      key: "data",
      dataIndex: "data",
      render: (el: ConfigItem<"contests">) => {
        return (
          <div>{el["startDate"] ? (
            formatDate(new Date(el["startDate"])))
            : (
              ""
            )}
          </div>)
      },
    },
    {
      sorter: (a, b) => sortByDate(a["data"]["endDate"], b["data"]["endDate"]),
      title: "End date",
      key: "data",
      dataIndex: "data",
      render: (el: ConfigItem<"contests">) => {
        return (
          <div>{el["endDate"] ? (
            formatDate(new Date(el["endDate"])))
            : (
              ""
            )}
          </div>)
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: ConfigItemAtList) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <EditIconButton
            onClick={e => {
              e.stopPropagation()
              managingJsonStore.selectedElement = row
            }}
          />
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(`contest: ${row.name}`, undefined, async() => {
                managingJsonStore.funcAfterSuccess = async() => await managingJsonStore.getData()

                managingJsonStore.deleteByName(row.name, row.type)
              })
            }}
          />
        </div>
      ),
    },
  ]

  if (managingJsonStore.selectedElement["name"] && !(isVisibleEditCreate === "edit" || isVisibleEditCreate === "create")) {
    return (
      <ContestListEdit />
    )
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <h1 style={{ marginRight: "auto", fontSize: "20px" }}>Contests</h1>
        <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="contest" />
      </div>

      <div style={{ background: "#F0F2F5", width: "calc(100% + 32px)", marginLeft: "-16px", height: "100%", padding: "24px" }}>
        <Table
          dataSource={managingJsonStore.itemTypes}
          columns={columnsCurrent as any}
          rowKey={(record: ConfigItemAtList) => record.name}
          onRow={(record: ConfigItemAtList) => ({
            onClick: () => {
              managingJsonStore.selectedElement = record
              setEditCreateVisibility("edit")
            },
          })}
          loading={managingJsonStore.isLoading}
        />

        {isVisibleEditCreate && (
          <Modal
            visible
            title={isVisibleEditCreate === "edit" ? "Edit contest" : "Create contest"}
            width="700px"
            footer={null}
            onCancel={onClose}
            style={{ top: "10px" }}
          >
            <ContestEdit editType={isVisibleEditCreate} onClose={onClose} />
          </Modal>
        )}
        {isVisibleNameEdit && (
          <Modal
            visible
            title="Edit contest name"
            width="700px"
            footer={null}
            onCancel={onClose}
            style={{ top: "10px" }}
          >
            <ContestNameEdit onClose={onClose} />
          </Modal>
        )}
      </div>
    </>
  )
})
