import React, { FC } from "react"
import { Form, Input } from "antd"

import { RegionToUpdate } from "domains/marketplace/store/types"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { SubmitCancelButtons } from "components"

export const CategoryEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      marketplaceCategoriesStore: { selectedElement: { name } },
      marketplaceCategoriesStore,
    },
  } = useStore()

  const onFinishHandler = async(event: RegionToUpdate) => {
    marketplaceCategoriesStore.funcAfterSuccess = async() => {
      onClose()
      await marketplaceCategoriesStore.getData()
    }
    editType === "edit"
      ? await marketplaceCategoriesStore.update(event)
      : await marketplaceCategoriesStore.create(event)
  }

  return (
    <Form onFinish={onFinishHandler}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input region name!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input style={{ width: "100%" }} maxLength={20} />
      </Form.Item>
      <SubmitCancelButtons onClose={onClose} isLoading={marketplaceCategoriesStore.isLoading} />
    </Form>
  )
})
