import { apiCall } from "api-calls/api-call"
import { Game, GameResponse, GameMode, GameStatus, GameType } from "domains/games/store/types"

export const getGames = async(): Promise<Game[]> => {
  return apiCall({
    method: "GET",
    path: "game/all",
  })
}

export const getGameTypes = async(): Promise<any> => {
  return apiCall({
    method: "GET",
    path: "game/game-types",
  })
}

export const createGameMode = async(input: GameMode): Promise<GameResponse> => {
  return apiCall({
    method: "POST",
    path: "game/mode/create",
    body: input,
  })
}

export const updateGameMode = async(input: GameMode & { modeId: string }): Promise<GameResponse> => {
  return apiCall({
    method: "PATCH",
    path: "game/mode/update",
    body: input,
  })
}

export const deleteGameMode = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `game/mode/${id}/remove`,
  })
}


export const deleteGame = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `game/${id}`,
  })
}

export const autoUpdate = async(input: {
  gameId: string;
  status: boolean;
}): Promise<GameResponse> => {
  return apiCall({
    method: "PATCH",
    path: "game/autoupdate",
    body: input,
  })
}

export const createGameStatus = async(input: GameType): Promise<GameResponse> => {
  return apiCall({
    method: "POST",
    path: "game/type",
    body: input,
  })
}

export const deleteGameStatus = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `game/type/${id}/remove`,
  })
}

export const updateGameStatus = async(input: {
  gameId: string;
  status: GameStatus;
}): Promise<GameResponse> => {
  return apiCall({
    method: "PATCH",
    path: "game/type/status",
    body: input,
  })
}
export const updateGameType = async(input: {
  gameTypeId: string;
  pretyName: string;
  description: string;
}): Promise<GameResponse> => {
  return apiCall({
    method: "PATCH",
    path: "game/type/update",
    body: input,
  })
}

export const updateGamePriority = async(input: {
  gameId: string;
  priority: number | null;
}): Promise<void> => {
  return apiCall({
    method: "PATCH",
    path: `game/${input.gameId}/priority`,
    body: input,
  })
}

export const createGame = async(input: {
    gameIdentifier: string;
    priority: number | null;
    gamePlatform: boolean | any;
    gamePlatformId: string;
    pretyName: string;
    name: string;
    updateUrl: string;
    updatedAt: string;
    createdAt: string;
    pictureUrl: string;
    isAutoUpdatable: boolean;

  }): Promise<void> => {

  //gamePlatform isMobile
  if (input.gamePlatform) {
    input.gamePlatform
    = {
        createdAt: "2022-04-28T13:42:26.314Z",
        id: "272b5d88-08a1-40d5-8615-e85756275642",
        name: "Mobile",
        updatedAt: "2022-04-28T13:42:26.314Z",
      }
    input.gamePlatformId = "272b5d88-08a1-40d5-8615-e85756275642"
  } else {
    input.gamePlatform
    = {
        id: "a2b5d120-2f04-4c88-a232-eb8d437934ea",
        name: "Overwolf",
        createdAt: "2022-04-28T13:42:25.340Z",
        updatedAt: "2022-04-28T13:42:25.340Z",
      }
    input.gamePlatformId = "a2b5d120-2f04-4c88-a232-eb8d437934ea"
  }

  input.updateUrl = `https://game-events-status.overwolf.com/${input.gameIdentifier}_prod.json`
  input.updatedAt = Date.now().toString()
  input.createdAt = Date.now().toString()
  if (input.pictureUrl === "") {
    input.pictureUrl = "https://s3.us-east-2.amazonaws.com/buff.images/game-icons/splitgate.svg"
  }

  return apiCall({
    method: "POST",
    path: "game",
    body: input,
  })
}
