import React, { FC } from "react"
import { Button } from "antd"
import { EditOutlined } from "@ant-design/icons"

interface EditIconButtonProps {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const EditIconButton: FC<EditIconButtonProps> = ({ disabled, onClick }) => {
  return <Button disabled={disabled} type="primary" onClick={onClick} icon={<EditOutlined />} />
}
