import React, { FC } from "react"
import { Button } from "antd"
import { UserOutlined } from "@ant-design/icons"

interface UserIconButtonProps {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  title?: string;
}

export const UserIconButton: FC<UserIconButtonProps> = ({ disabled, onClick, title }) => {
  return (
    <Button
      disabled={disabled}
      type="primary"
      style={{ background: "#FFD176", borderColor: "#FFD176" }}
      onClick={onClick}
      icon={<UserOutlined />}
      title={title}
    />
  )
}
