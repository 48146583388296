import React, { FC, useEffect, useState } from "react"

import { Button, Form, DatePicker } from "antd"
import { UploadComponent, FormElement } from "components"
import { ContestConfigLiveData } from "domains/managingJson/store/types"

import moment from "moment"

export const ContestLive: FC<{
  data?: ContestConfigLiveData;
  form: any;
  id: number;
  deleteFunc: () => void;
}> = ({ id, data, form, deleteFunc }) => {
  const [image, setImage] = useState(data?.image || "")
  const [startDate, setstartDate] = useState(data && data.startDate)
  const [endDate, setendDate] = useState(data && data.endDate)

  useEffect(() => {
    if (data) {
      setImage(data.image)
      setstartDate(data.startDate)
      setendDate(data.endDate)
    }
  }, [data])

  useEffect(() => {
    const arr = {}
    arr[`contestLiveImage${id}`] = image
    form.setFieldsValue({ ...arr })
  }, [image, form, id])

  return (
    <div>
      <FormElement
        type="input"
        label={`Contest Live title ${id}`}
        name={`contestLiveTitle${id}`}
        initialValue={data?.title}
        required
      />
      <FormElement
        type="text"
        label={`Contest Live text ${id}`}
        name={`contestLiveText${id}`}
        initialValue={data?.text}
        required
      />
      <div style={{ display: "flex", gap: "20px" }}>
        <Form.Item
          label="Start Date (UTC)"
          name={`contestLiveStartDate${id}`}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Select start date",
            },
          ]}
        >
          <DatePicker
            value={moment(startDate)}
            onChange={e => setstartDate(e?.toDate())}
            showTime
            format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
            placeholder="Select Start Date"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="End Date (UTC)"
          name={`contestLiveEndDate${id}`}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Select end date",
            },
          ]}
        >
          <DatePicker
            showTime
            format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
            placeholder="Select End Date"
            style={{ width: "100%" }}
            value={moment(endDate)}
            onChange={e => setendDate(e?.toDate())}
            disabledDate={e => {
              return e < moment(startDate)
            }}
          />
        </Form.Item>
      </div>
      <Form.Item name={`contestLiveImage${id}`} initialValue={image}>
        <UploadComponent
          image={image}
          setImage={setImage}
          placeholder={`Contest Live Image ${id}`}
          title={`Contest Live Image ${id}`}
          required
        />
      </Form.Item>
      <Button
        style={{ display: "flex", margin: "auto", marginBottom: "14px" }}
        onClick={deleteFunc}
      >
        Delete ContestLive {id}
      </Button>
    </div>
  )
}
