import React, { FC, useEffect } from "react"
import { useStore } from "stores/utils/use-store"
import { Form, Input, InputNumber, Select, DatePicker } from "antd"
import { observer } from "mobx-react"
import { GiftoinCampaignToUpdate } from "domains/giftoin/types"
import { SubmitCancelButtons } from "components"
import { Option } from "antd/lib/mentions"
import moment from "moment"
const { RangePicker } = DatePicker
import { COUNTRY_CODES } from "utils/countryCodes"

export const GitftoinEditModal: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const { dataStore: { giftoinStore } } = useStore()
  const { name, periodStart, periodFinish, event, externalCampaignId, probability, premiumRestriction, additionalData, geoRestriction }
   = giftoinStore.selectedElement
  const [form] = Form.useForm()

  useEffect(() => {
    giftoinStore.getData()
  }, [giftoinStore])

  const onFinishHandler = async(event: GiftoinCampaignToUpdate & {
        countries: string;
        successMessageHeader: string;
        successMessageBody: string;
        amount?: number;
        numberOfTickets?: number;
        ticketId?: string;
        subscriptionId?: string;
        billingPeriod?: "YEAR" | "MONTH";
      }) => {
    const dataToBack: GiftoinCampaignToUpdate = {
      name: event.name,
      externalCampaignId: event.externalCampaignId,
      probability: Number(event.probability),
      event: event.event,
      periodStart: event.date && event.date[0],
      periodFinish: event.date && event.date[1],
      // premiumRestriction: event.premiumRestriction && event.premiumRestriction,
      // geoRestriction: event.geoRestriction && event.geoRestriction,
      // additionalData: event.additionalData && JSON.parse(event.additionalData),
    }

    giftoinStore.funcAfterSuccess = async() => {
      onClose()
      form.resetFields()
      await giftoinStore.getData()
    }
    editType === "edit"
      ? await giftoinStore.update(dataToBack)
      : await giftoinStore.create(dataToBack)
  }

  return (
    <Form onFinish={onFinishHandler} labelCol={{ span: 6 }} labelAlign="left" form={form}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input giftoin campaign name!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label="external campaign id"
        name="externalCampaignId"
        initialValue={externalCampaignId}
        rules={[
          {
            required: true,
            message: "Please input external campaign id!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="event" label="Event" initialValue={event}>
        <Select placeholder="Select an event">
          <Option value="ENDGAME">ENDGAME</Option>
          {/* <Option value="REGISTRATION">REGISTRATION</Option> */}
          {/* <Option value="WEEKLY_CHALLENGE">WEEKLY_CHALLENGE</Option> */}
        </Select>
      </Form.Item>

      <Form.Item
        label="probability %"
        name="probability"
        initialValue={probability}
        rules={[
          {
            required: true,
            message: "Please input probability!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <InputNumber style={{ width: "100%" }} max={100} min={0} placeholder="0-100%" />
      </Form.Item>

      <Form.Item
        label="Date"
        name="date"
        initialValue={
          periodStart && periodFinish ? [moment(periodStart as Date), moment(periodFinish as Date)] : ""
        }
        rules={[
          {
            required: true,
            message: "Select date",
          },
        ]}
      >
        <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: "100%" }} />
      </Form.Item>

      {/* <Form.Item name="premiumRestriction" label="premium restriction" initialValue={premiumRestriction}>
        <Select mode="multiple" placeholder="Select an premium restriction">
          <Option value="PREMIUM">PREMIUM</Option>
          <Option value="PREMIUM_PLUS">PREMIUM_PLUS</Option>
          <Option value="PREMIUM_ELITE">PREMIUM_ELITE</Option>
        </Select>
      </Form.Item> */}

      {/* <Form.Item name="geoRestriction" label="geo restriction" initialValue={geoRestriction}>
        <Select
          mode="multiple"
          placeholder="Select an geo restriction"
          options={COUNTRY_CODES.map(({ code, name }) => ({ label: `${name} (${code})`, value: code }))}
        />
      </Form.Item> */}

      {/* <Form.Item
        label="Additional Data"
        name="additionalData"
        initialValue={JSON.stringify(additionalData)}
        rules={[
          { message: "Please input a JSON object" },
          {
            validator: (_, value) => {
              try {
                JSON.parse(value)

                return Promise.resolve()
              } catch (error) {
                return Promise.reject(new Error("Invalid JSON object"))
              }
            },
          },
        ]}
      >
        <Input.TextArea placeholder="Enter a JSON object" />
      </Form.Item> */}

      <SubmitCancelButtons onClose={onClose} isLoading={giftoinStore.isLoading} />
    </Form>
  )
})
