import React, { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { Modal, Table } from "antd"
import { bannersColumns } from "./columns"
import { observer } from "mobx-react"
import { BannerEdit } from "./banner-edit"
import { Banner, BannerPlatform } from "../store/banners.store"
import { DeleteIconButton, CreateNewElement, showDeleteConfirm } from "components"
import { Column } from "abstract/types/column.type"

export const BannersList: FC<{
  platform: BannerPlatform;
}> = observer(({ platform }) => {
  const { dataStore: { bannersStore } } = useStore()
  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"edit" | "create" | null>(null)

  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      bannersStore.pagination.page = page
    },
    total: bannersStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }

  useEffect(() => {
    bannersStore.setFilter({ platform })
    bannersStore.getData()
  }, [bannersStore, platform])

  const onClose = () => {
    setEditCreateVisibility(null)
    bannersStore.selectedElement = {} as Banner
  }

  const columns: Column[] = [
    ...bannersColumns,
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: Banner) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(row.name, undefined, async() => {
              bannersStore.funcAfterSuccess = async() => await bannersStore.getData()
              await bannersStore.delete(row.id)
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <CreateNewElement
        onClick={() => {
          bannersStore.selectedElement = {} as Banner
          setEditCreateVisibility("create")
        }}
        valueText="banner"
      />
      <Table
        dataSource={bannersStore.banners}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={bannersStore.isLoading}
        onRow={(record: Banner) => ({
          onClick: () => {
            bannersStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit banner" : "Create banner"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <BannerEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
