import React, { FC, useEffect } from "react"
import { Form, Modal, Select } from "antd"
import { useStore } from "stores/utils/use-store"
import { SubmitCancelButtons, FormElement } from "components"

export const AddBonusComponent: FC<{
  visible: boolean;
  onClose: () => void;
}> = ({ visible, onClose }) => {
  const { dataStore: { accountDetailsStore, accountTransactionsStore } } = useStore()

  useEffect(() => {
    if (!accountTransactionsStore.availableTypes) {
      accountTransactionsStore.getTransactionTypes()
    }
  }, [])

  const onFinishHandler = async(el: { name: string; amount: number, type: number }) => {
    accountDetailsStore.funcAfterSuccess = async() => {
      onClose()
      await accountDetailsStore.getAccountDetails(accountDetailsStore.id)
    }

    await accountDetailsStore.addBonus(el)
  }

  return (
    <Modal visible={visible} title="Create a Transaction" footer={null} onCancel={() => onClose()}>
      <a
        style={{ marginBottom: "15px", display: "inline-block" }}
        href="https://fulcrumrocks.atlassian.net/wiki/spaces/BUFF/pages/649101317/Transaction+Types"
      >
        Documentation link
      </a>
      <Form onFinish={onFinishHandler}>
        {accountTransactionsStore.availableTypes && (
          <Form.Item
            label="Transaction type"
            name="type"
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Select>
              {Object.entries(accountTransactionsStore.availableTypes).map((item, id) => (
                <Select.Option value={item[0]} key={id}>
                  {item[1]} - {item[0]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <FormElement type="input" label="Transaction Name" name="name" required />
        <FormElement
          type="inputNumber"
          label="Transaction Amount"
          name="amount"
          required
          props={{ style: { width: "100%" } }}
        />
        <SubmitCancelButtons onClose={onClose} isLoading={accountDetailsStore.isLoading} />
      </Form>
    </Modal>
  )
}
