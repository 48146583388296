export class Config {
  public static baseUrl: string
  public static version: string

  public static init(configData: { baseUrl: string; version: string }): void {
    Config.baseUrl = process.env.REACT_APP_BASE_URL || configData.baseUrl
    Config.version = process.env.REACT_APP_VERSION || configData.version

    console.log("Current config:", Config)
  }
}
