import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { FormInstance, Input } from "antd"
import { GrandPrizeReward, GrandPrizeRewards } from "domains/managingJson/store/types"
import { FC, useMemo } from "react"

interface props {
  gameId: string
  form: FormInstance<any>
  grandPrizes: GrandPrizeRewards
  setGrandPrizes: React.Dispatch<React.SetStateAction<GrandPrizeRewards>>
}

const getTooltipTexts = (grandPrizes: GrandPrizeRewards, gameId: string): GrandPrizeReward[] => {
  return grandPrizes ? grandPrizes[gameId] ?? [] : []
}

export const GrandPrizesList: FC<props> = ({ gameId, grandPrizes, setGrandPrizes }) => {
  const tooltipTexts = useMemo(() => getTooltipTexts(grandPrizes, gameId), [grandPrizes, gameId])

  const changeTooltipText = (e, index) => {
    setGrandPrizes(old => ({
      ...old,
      [gameId]: getTooltipTexts(old, gameId).map((tooltip, i) => {
        if (i === index) {
          return { tooltipText: e.target.value }
        }

        return tooltip
      }),
    }))

    /*if (!data.grandPrizeRewards
      || !data.grandPrizeRewards[gameId]) {
      data.grandPrizeRewards = {
        ...data.grandPrizeRewards,
        [gameId]: tooltipTexts,
      }
    }*/
  }

  const deleteTooltipText = (index: number) => {
    setGrandPrizes(old => ({
      ...old,
      [gameId]: getTooltipTexts(old, gameId).filter((_, i) => i !== index),
    }))
  }

  const addTooltipText = () => {
    setGrandPrizes(old => ({
      ...old,
      [gameId]: [...getTooltipTexts(old, gameId), { tooltipText: "" }],
    }))
  }

  console.log("tooltipTexts", tooltipTexts)

  if (!tooltipTexts || tooltipTexts.length === 0) {
    return (
      <div
        onClick={addTooltipText}
        style={{
          margin: "0px -16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "calc(100% + 32px)",
          height: "50px",
          border: "1px dashed #D9D9D9",
          cursor: "pointer",
        }}
      >
        <PlusOutlined style={{ margin: "0px 8px" }} />
        Add row
      </div>
    )
  }

  return (
    <div>
      <div style={{ display: "flex", margin: "0px -16px" }}>
        <div style={{ padding: "8px 16px 8px 32px", backgroundColor: "#FAFAFA", fontWeight: 500 }}>Place</div>
        <div
          style={{
            fontWeight: 500,
            padding: "8px 16px",
            flexGrow: 1,
            backgroundColor: "#FAFAFA",
            borderLeft: "1px solid #00000008",
            borderRight: "1px solid #00000008",
          }}
        >
          Prize Tooltip Text
        </div>
        <div style={{ padding: "8px 32px 8px 16px", backgroundColor: "#FAFAFA", fontWeight: 500 }} >Action</div>
      </div>
      <div>
        {tooltipTexts.map((reward, index) => (
          <div key={index} style={{ display: "flex", margin: "0px -16px" }}>
            <div style={{ fontWeight: 500, width: "81px", padding: "8px 16px 8px 32px" }}>{index + 1}</div>
            <div
              style={{
                padding: "8px 16px",
                flexGrow: 1,
              }}
            >
              <Input
                type="input"
                defaultValue={reward.tooltipText}
                onChange={e => {
                  changeTooltipText(e, index)
                }}
              />
            </div>
            <div style={{ padding: "8px 32px 8px 16px" }}>
              <DeleteOutlined
                style={{ color: "#FF0000", fontSize: "20px", cursor: "pointer" }}
                onClick={() => {
                  deleteTooltipText(index)
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div
        onClick={addTooltipText}
        style={{
          margin: "0px -16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "calc(100% + 32px)",
          height: "50px",
          border: "1px dashed #D9D9D9",
          cursor: "pointer",
        }}
      >
        <PlusOutlined style={{ margin: "0px 8px" }} />
        Add row
      </div>
    </div>
  )
}
