import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { Button, DatePicker, Select, Table } from "antd"
import { transactionColumns } from "./transaction-columns"
import moment from "moment"
import s from "./styles.module.css"

type TypeTags = { title: string; values: number[] };

const transactionsTags: TypeTags[] = [
  { title: "Games", values: [2, 4] },
  { title: "Bonuses", values: [5, 141] },
  { title: "Mini Games", values: [162] },
  { title: "Purchases", values: [3] },
  { title: "Raffles", values: [18, 165] },
  { title: "Monthly Premium Bonuses", values: [13] },
  { title: "BuffPay", values: [100, 101, 102, 103] },
  { title: "Boosters", values: [15, 90, 98] },
  { title: "Spin&Collect", values: [110, 111] },
  { title: "ADS Rewards", values: [9, 120, 121, 122, 160, 161] },
  { title: "Buffs Packs", values: [133, 134, 135] },
  { title: "Reward Track", values: [163, 164] },
]

export const AccountTransactions: FC = observer<FC>(() => {
  const { id } = useParams<{ id: string }>()

  const { dataStore: { accountTransactionsStore } } = useStore()
  const [typeFilter, setTypeFilter] = useState<number[]>([])
  const [gameFilter, setgameFilter] = useState<number[]>([])

  const [selectedTags, setselectedTags] = useState<TypeTags[]>([])

  const [startDate, setstartDate] = useState<Date | undefined>(undefined)
  const [endDate, setendDate] = useState<Date | undefined>(undefined)
  const [showAllFilters, setshowAllFilters] = useState(false)

  useEffect(() => {
    setselectedTags(selectedTags.filter(item => item.values.every(v => {
      return typeFilter.includes(v)
    })))
  }, [typeFilter])

  useEffect(() => {
    accountTransactionsStore.setFilter({
      ...accountTransactionsStore.filter,
      type: typeFilter,
      gameId: gameFilter,
      startDate: startDate ? startDate.toString() : undefined,
      endDate: endDate ? endDate.toString() : undefined,
      lastDate: undefined,
    })

    accountTransactionsStore.loadedPages = 1
  }, [typeFilter, gameFilter, startDate, endDate])

  useEffect(() => {
    if (!id) {
      return
    }

    accountTransactionsStore.getAccountTransactions(id)
    if (!accountTransactionsStore.availableGames) {
      accountTransactionsStore.getGameTypes()
    }
    if (!accountTransactionsStore.availableTypes) {
      accountTransactionsStore.getTransactionTypes()
    }
  }, [accountTransactionsStore, id])

  const selectTag = item => {
    let newTags: any = []
    const newArr: number[] = []
    if (selectedTags.includes(item)) {
      newTags = selectedTags.filter(v => {
        return v !== item
      })
      setselectedTags(newTags)

      newTags.map(item => {
        return newArr.push(...item.values)
      })
      setTypeFilter(typeFilter.filter(item => newArr.includes(item)))
    } else {
      newTags = [...selectedTags, item]
      setselectedTags(newTags)
      newTags.map(item => {
        return newArr.push(...item.values)
      })
      setTypeFilter(newArr)
    }
  }

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {transactionsTags.map(item => {
          return (
            <div
              key={item.title}
              className={[s.tag, selectedTags.includes(item) && s.selected]
                .filter(Boolean)
                .join(" ")}
              onClick={() => selectTag(item)}
            >
              {item.title}
            </div>
          )
        })}
      </div>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <div style={{ display: "grid", gap: "10px" }}>
          <Button onClick={() => setshowAllFilters(!showAllFilters)}>
            {showAllFilters ? "Hide all filters" : "Show all filters"}
          </Button>
          <Button
            onClick={() => {
              setTypeFilter([])
              setgameFilter([])
              setselectedTags([])
              setstartDate(undefined)
              setendDate(undefined)
              setshowAllFilters(false)
            }}
            type="text"
          >
            Clear all filters
          </Button>
        </div>
        Filters:{" "}
        {accountTransactionsStore.availableTypes && (
          <Select
            onChange={setTypeFilter}
            value={typeFilter}
            style={{ marginBottom: "10px", minWidth: "350px" }}
            mode="multiple"
            placeholder="Filter by type"
            allowClear
          >
            {Object.entries(accountTransactionsStore.availableTypes).map((item, id) => item?.length > 1 && (
              <Select.Option value={item[1]} key={id}>
                {item[0]}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>

      {showAllFilters && (
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          {accountTransactionsStore.availableGames && (
            <Select
              onChange={setgameFilter}
              value={gameFilter}
              style={{ width: "350px", marginBottom: "10px" }}
              placeholder="Filter by Game:"
              allowClear
              mode="multiple"
            >
              {Object.entries(accountTransactionsStore.availableGames).map(item => {
                return (
                  item?.length > 1 && (
                    <Select.Option key={item[1]} value={item[1]}>
                      {item[0]}
                    </Select.Option>
                  )
                )
              })}
            </Select>
          )}
          <DatePicker
            showTime
            format="YYYY-MM-DD"
            placeholder="Start date"
            style={{ width: "350px" }}
            name="startDate"
            value={startDate ? moment(startDate) : undefined}
            onChange={e => setstartDate(e?.toDate())}
          />
          <DatePicker
            showTime
            format="YYYY-MM-DD"
            placeholder="End date"
            style={{ width: "350px" }}
            name="endDate"
            value={endDate ? moment(endDate) : undefined}
            onChange={e => setendDate(e?.toDate())}
          />
        </div>
      )}
      <Table
        dataSource={accountTransactionsStore.accountTransactions}
        columns={transactionColumns}
        rowKey="id"
        pagination={false}
        loading={accountTransactionsStore.isLoading}
        onChange={(p, c, sorter: any) => {
          accountTransactionsStore.setFilter({
            ...accountTransactionsStore.filter,
            createdAtOrder: sorter.order === "ascend" ? "ASC" : "DESC",
            lastDate: undefined,
          })
          accountTransactionsStore.loadedPages = 1
        }}
      />
      <div>
        {accountTransactionsStore.accountTransactions?.length} /{" "}
        {accountTransactionsStore.pagination.limit * accountTransactionsStore.loadedPages}{" "}
        <Button
          disabled={
            accountTransactionsStore.accountTransactions?.length
              < accountTransactionsStore.pagination.limit * accountTransactionsStore.loadedPages
            || accountTransactionsStore.isEmptyArr
          }
          onClick={() => {
            accountTransactionsStore.loadedPages = accountTransactionsStore.loadedPages + 1
            accountTransactionsStore.setFilter({
              ...accountTransactionsStore.filter,
              lastDate: accountTransactionsStore.lastDate,
            })
          }}
        >
          Load more
        </Button>
      </div>
    </>
  )
})
