import React, { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Form, Input, Checkbox, InputNumber, Switch, Select } from "antd"
import { observer } from "mobx-react"

import { IFrameSettings } from "domains/managingJson/store/types"
import { FormElement, SubmitCancelButtons } from "components"
import { ApprovedIframeNames } from "domains/managingJson/constants"

const DesktopDimesions = { width: 1255, height: 740 }

export const EditIFrames: FC<{
  editType: "create" | "edit";
  onClose: () => void;
  configType: "iframes";
}> = observer(({ editType, configType, onClose }) => {
  const {
    dataStore: {
      managingJsonStore,
      managingJsonStore: { selectedElement },
    },
  } = useStore()
  const data = selectedElement.data as IFrameSettings
  const name = selectedElement.name

  const [nameSwitch, setnameSwitch] = useState(false)

  const [tokenRequired, settokenRequired] = useState(false)
  const [withBg, setwithBg] = useState(false)
  const [withAd, setwithAd] = useState(false)
  const [withScrollbar, setwithScrollbar] = useState(false)
  const [outsideClickHandler, setoutsideClickHandler] = useState(false)

  useEffect(() => {
    settokenRequired(!!data?.tokenRequired)
    setwithBg(!!data?.withBg)
    setwithAd(!!data?.withAd)
    setwithScrollbar(!!data?.withScrollbar)
    setoutsideClickHandler(!!data?.outsideClickHandler)
  }, [data])

  const onFinishHandler = async(event: any) => {
    const data = {
      tokenRequired,
      withBg,
      withAd,
      withScrollbar,
      outsideClickHandler,
      title: event.title,
      securityGroup: event.securityGroup,
      url: event.url,
      services: typeof event.services === "string" ? event.services.split(",") : event.services,
      dimensions: {
        width: event.width,
        height: event.height,
      },
      position: {
        left: event.left,
        right: event.right,
        top: event.top,
        bottom: event.bottom,
      },
    }
    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      await managingJsonStore.getData()
    }
    editType === "edit"
      ? await managingJsonStore.update({
        name: event.name,
        type: configType,
        data,
      })
      : await managingJsonStore.create({
        name: event.name,
        type: configType,
        data,
      })
  }

  return (
    <Form onFinish={onFinishHandler}>
      <div style={{ display: "flex", gap: "10px" }}>
        <div>
          Select/Input
          <Switch checked={nameSwitch} onChange={setnameSwitch} />
        </div>
        <Form.Item
          label="Name"
          name="name"
          initialValue={name}
          required
          style={{ width: "100%", marginBottom: "20px" }}
        >
          {!nameSwitch ? (
            <Select disabled={editType === "edit"}>
              {ApprovedIframeNames.map(action => (
                <Select.Option key={action} value={action}>
                  {action}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input style={{ width: "100%" }} disabled={editType === "edit"} />
          )}
        </Form.Item>
      </div>

      <FormElement
        label="Title"
        name="title"
        type="input"
        required
        style={{ width: "100%" }}
        initialValue={data?.title}
        props={{ style: { width: "100%" } }}
      />
      <FormElement
        label="URL"
        name="url"
        type="input"
        rules={[
          {
            required: true,
            message: "Please input url!",
          },
          {
            type: "url",
            message: "This field must be a valid url.",
          },
        ]}
        style={{ width: "100%" }}
        initialValue={data?.url}
        props={{ style: { width: "100%" } }}
      />
      <FormElement
        label="Security group"
        name="securityGroup"
        type="input"
        required
        style={{ width: "100%" }}
        initialValue={data?.securityGroup}
        props={{ style: { width: "100%" } }}
      />

      <div style={{ display: "flex" }}>
        <Checkbox onChange={() => settokenRequired(!tokenRequired)} checked={tokenRequired}>
          Token is Required
        </Checkbox>
        <Checkbox
          onChange={() => setoutsideClickHandler(!outsideClickHandler)}
          checked={outsideClickHandler}
        >
          Outside Click Handler
        </Checkbox>
        <Checkbox onChange={() => setwithScrollbar(!withScrollbar)} checked={withScrollbar}>
          With scrollbar
        </Checkbox>
      </div>
      <div style={{ display: "flex" }}>
        <Checkbox onChange={() => setwithBg(!withBg)} checked={withBg}>
          With background
        </Checkbox>
        <Checkbox onChange={() => setwithAd(!withAd)} checked={withAd}>
          With Ad
        </Checkbox>
      </div>

      <div style={{ fontWeight: "bold", padding: "10px 0" }}>Fill services devide by comma &#39;,&#39;</div>
      <Form.Item
        label="Services"
        name="services"
        style={{ width: "100%" }}
        initialValue={data?.services}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <div style={{ fontWeight: "bold", padding: "10px 0" }}>Dimensions</div>
      <div style={{ display: "grid", gridAutoFlow: "column", gap: "10px" }}>
        <Form.Item
          label="Width"
          name="width"
          rules={[
            {
              required: true,
              message: "Please input width!",
            },
          ]}
          style={{ width: "100%" }}
          initialValue={data?.dimensions?.width}
        >
          <InputNumber style={{ width: "100%" }} max={DesktopDimesions.width} min={0} />
        </Form.Item>
        <Form.Item
          label="Height"
          name="height"
          rules={[
            {
              required: true,
              message: "Please input height!",
            },
          ]}
          style={{ width: "100%" }}
          initialValue={data?.dimensions?.height}
        >
          <InputNumber style={{ width: "100%" }} max={DesktopDimesions.height} min={0} />
        </Form.Item>
      </div>
      <div style={{ fontWeight: "bold", padding: "10px 0" }}>Position</div>

      <div style={{ display: "grid", gridAutoFlow: "column", gap: "10px" }}>
        <Form.Item
          label="Left"
          name="left"
          style={{ width: "100%" }}
          initialValue={data?.position?.left}
        >
          <InputNumber min={0} max={DesktopDimesions.width} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Right"
          name="right"
          style={{ width: "100%" }}
          initialValue={data?.position?.right}
        >
          <InputNumber min={0} max={DesktopDimesions.width} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Top"
          name="top"
          style={{ width: "100%" }}
          initialValue={data?.position?.top}
        >
          <InputNumber min={0} max={DesktopDimesions.height} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Bottom"
          name="bottom"
          style={{ width: "100%" }}
          initialValue={data?.position?.bottom}
        >
          <InputNumber min={0} max={DesktopDimesions.height} style={{ width: "100%" }} />
        </Form.Item>
      </div>
      <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
    </Form>
  )
})
