import {
  ItemType,
  GetItemsFilter,
  ItemToUpdate,
  ItemProviderResponse,
  InternalCategory,
} from "domains/marketplace/store/types"
import { apiCall } from "api-calls/api-call"

export const getItems = async(
  page: number,
  limit: number,
): Promise<{ total: number; data: ItemType[] }> => {
  const query: { page: number; limit: number } & GetItemsFilter = {
    page,
    limit,
  }

  return apiCall({
    method: "GET",
    path: "internal-marketplace/items",
    query,
  })
}

export const createItem = async(input: ItemToUpdate): Promise<void> => {
  return apiCall({
    method: "POST",
    path: "internal-marketplace/items",
    body: input,
  })
}

export const updateItem = async(id: string, input: ItemToUpdate): Promise<void> => {
  return apiCall({
    method: "PUT",
    path: `internal-marketplace/items/${id}`,
    body: input,
  })
}
export const deleteItem = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `internal-marketplace/items/${id}`,
  })
}

export const getItemInfo = async(itemId: string): Promise<ItemProviderResponse> => {
  return apiCall({
    method: "GET",
    path: `internal-marketplace/items/${itemId}`,
  })
}

export const getItemFilters = async(): Promise<InternalCategory> => {
  return apiCall({
    method: "GET",
    path: "internal-marketplace/filters",
  })
}

