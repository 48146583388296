import React, { FC } from "react"
import { useStore } from "stores/utils/use-store"

import { Form, Input, Switch } from "antd"
import { observer } from "mobx-react"

import { AvatarConfig, ConfigItemType } from "domains/managingJson/store/types"
import { SubmitCancelButtons } from "components"

export const EditAvatar: FC<{
  editType: "create" | "edit";
  onClose: () => void;
  configType: ConfigItemType;
}> = observer(({ editType, configType, onClose }) => {
  const {
    dataStore: {
      managingJsonStore,
      managingJsonStore: { selectedElement: { name, data } },
    },
  } = useStore()
  const { clipRecording } = (data || {}) as AvatarConfig

  const onFinishHandler = async(event: AvatarConfig & { name: string }) => {
    const dataToBack = {
      name: event.name,
      type: configType,
      data: { clipRecording: event.clipRecording },
    }

    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      await managingJsonStore.getData()
    }
    editType === "edit"
      ? await managingJsonStore.update(dataToBack)
      : await managingJsonStore.create(dataToBack)
  }

  return (
    <Form onFinish={onFinishHandler}>
      <Form.Item
        label="Name"
        name="name"
        style={{ width: "100%" }}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
        initialValue={name}
      >
        <Input disabled={editType === "edit"} />
      </Form.Item>
      <Form.Item
        label="Clip recording"
        name="clipRecording"
        style={{ width: "100%" }}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
        valuePropName="checked"
        initialValue={!!clipRecording}
      >
        <Switch defaultChecked={!!clipRecording} />
      </Form.Item>
      <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
    </Form>
  )
})
