
type UnknownWithPriorityAndId = {priority: number, id: string}

export const priorityUtils = <T extends UnknownWithPriorityAndId = UnknownWithPriorityAndId>(
  data: T[],
  update: (entry: T) => Promise<T>,
  updateEntry: (entry: T) => void,
) => {
  const isTopPriority = (point: T) => {
    return data?.at(0)?.id === point.id
  }

  const isLowestPriority = (point: T) => {
    return data?.at(-1)?.id === point.id
  }

  const switchPriority = async(point: T, up: boolean) => {
    if (!data) return
    const currentIndex = data.findIndex(a => a.id === point.id)
    if (currentIndex < 0) return
    if (up) {
      if (currentIndex === 0) return
      const oneEntryUp =data[currentIndex - 1]
      if (oneEntryUp.priority === point.priority) {
        // move all up from oneEntryUp up
        const moveTwo = currentIndex > 1 && data[currentIndex - 2].priority === point.priority
        const movedUp = data.slice(0, currentIndex - 1).map(a => ({ ...a, priority: a.priority + (moveTwo ? 2 : 1) }))
        const currentMovedUp = { ...point, priority: point.priority + 1 }

        return Promise.all([
          ...movedUp.map(update),
          currentMovedUp,
        ]).then(updated => {
          updated.forEach(updateEntry)
        })
      } else {
        const priorityCurrent = point.priority
        const oneEntryUpPriority = oneEntryUp.priority

        const updatedCurrent = { ...point, priority: oneEntryUpPriority }
        const updatedOneUp = { ...oneEntryUp, priority: priorityCurrent }

        return Promise.all([
          update(updatedCurrent),
          update(updatedOneUp),
        ]).then(updated => {
          updated.forEach(updateEntry)
        })
      }
    } else {
      if (currentIndex >= data.length -1) return
      const oneEntryDown = data[currentIndex + 1]
      if (oneEntryDown.priority === point.priority) {
        // make space for two
        const moveTwo = currentIndex < data.length - 2 && data[currentIndex + 2].priority === point.priority
        // move all up from oneEntryDown down
        const movedDown = data.slice(currentIndex + 2).map(a => ({ ...a, priority: a.priority - (moveTwo ? 2 : 1) }))
        const currentMovedDown = { ...point, priority: point.priority - 1 }

        return Promise.all([
          ...movedDown.map(update),
          currentMovedDown,
        ]).then(updated => {
          updated.forEach(updateEntry)
        })
      } else {
        const priorityCurrent = point.priority
        const oneEntryDownPriority = oneEntryDown.priority

        const updatedCurrent = { ...point, priority: oneEntryDownPriority }
        const updatedOneDown = { ...oneEntryDown, priority: priorityCurrent }

        return Promise.all([
          update(updatedCurrent),
          update(updatedOneDown),
        ]).then(updated => {
          updated.forEach(updateEntry)
        })
      }
    }
  }

  return {
    isTopPriority,
    isLowestPriority,
    switchPriority,
  }
}
