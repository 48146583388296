import { useCallback, useEffect, useMemo, useState } from "react"
import { IAdvertiser } from "../api/types"
import { listAdvertisers } from "../api"

const useAdvertisers = () => {
  const [unsortedAdvertisers, setAdvertisers] = useState<IAdvertiser[]>()

  useEffect(() => {
    listAdvertisers().then(setAdvertisers)
  }, [])

  const updateAdvertiser = useCallback((advertiser: Partial<IAdvertiser>) => {
    setAdvertisers(prev => prev?.map(p => p.id === advertiser.id ? { ...p, ...advertiser } : p))
  }, [])

  const addAdvertiser = useCallback((advertiser: IAdvertiser) => {
    setAdvertisers(prev => prev ? [...prev, advertiser] : [advertiser])
  }, [])

  const advertisers = useMemo(() => {
    if (!unsortedAdvertisers) return unsortedAdvertisers
    const copy = [...unsortedAdvertisers]
    // sort by createdAt
    copy.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    // sort by priority
    copy.sort((a, b) => b.priority - a.priority)

    return copy
  }, [unsortedAdvertisers])

  return {
    advertisers,
    updateAdvertiser,
    addAdvertiser,
  }
}

export default useAdvertisers
