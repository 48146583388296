import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { Button, Input, Select } from "antd"
import { observer } from "mobx-react"
import Title from "antd/lib/typography/Title"
import { UploadOutlined } from "@ant-design/icons"
import { CodesInventoryType } from "domains/codesInventory/store/types"
import { notifications } from "notifications"
import { bulkRewardsApi } from "../api/api"

interface BuffReward {
  type: "buffs";
  name?: string;
  transactionType?: string;
  amount?: number;
}

interface GiftCardReward {
  type: "gift-cards";
  name?: string;
  code?: string;
}

export const BulkRewards: FC = observer<FC>(() => {
  const { dataStore: { codesStore, accountTransactionsStore } } = useStore()
  const [file, setFile] = useState<File | null>(null)
  const [userCount, setUserCount] = useState<number>()
  const [type, _setType] = useState<"buffs" | "gift-cards">()
  const [data, setData] = useState<GiftCardReward | BuffReward>()
  const [availableCodes, setAvailableCodes] = useState<CodesInventoryType[]>()
  const [status, setStatus] = useState<"uploading" | "processing">()
  const submitting = !!status


  const setType: Dispatch<SetStateAction<string | undefined>> = t => {
    _setType(old => {
      let newType
      if (t === undefined) {
        setData(undefined)

        return undefined
      }
      if (typeof t === "string") newType = t
      else newType = t(old)
      if (old === t) return old
      if (newType === "gift-cards") {
        setData({
          type: "gift-cards",
          name: undefined,
          code: undefined,
        })
      } else {
        setData({
          type: "buffs",
          name: undefined,
          transactionType: undefined,
          amount: undefined,
        })
      }

      return newType
    })
  }

  useEffect(() => {
    if (!file) {
      setUserCount(undefined)
      setType(undefined)
      setAvailableCodes(undefined)

      return
    }
    const reader = new FileReader()
    reader.onload = () => {
      const text = reader.result
      const lines = text?.toString().split("\n").filter(l => !!l.trim())
      setUserCount(lines?.length)
    }
    reader.readAsText(file)
  }, [file])

  useEffect(() => {
    if (type !== "gift-cards") return setAvailableCodes(undefined)
    codesStore.getData().then(codes => {
      setAvailableCodes(codes)
    })
  }, [type])

  const selectFile = async() => {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".csv"
    input.onchange = e => {
      const target = e.target as HTMLInputElement
      const file: File = (target.files as FileList)[0]
      if (!file) return
      setFile(file)
    }
    input.click()
  }

  const handleSubmit = () => {
    console.log(data)
    if (status) return
    if (!file) return notifications.error("Please upload a file")
    if (!type) return notifications.error("Please choose a reward type")
    if (!data) return notifications.error("Please fill in reward details")
    if (!data.name) return notifications.error("Please fill in reward name")
    if (data.type === "gift-cards" && !data.code) return notifications.error("Please choose a code")
    if (data.type === "buffs" && !data.transactionType) return notifications.error("Please fill in transaction type")
    if (data.type === "buffs" && !data.amount) return notifications.error("Please fill in buffs amount")
    setStatus("uploading")
    bulkRewardsApi.getUploadUrl().then(async({ fileData, putUrl }) => (
      bulkRewardsApi.uploadCSV(file, putUrl).then(async() => {
        setStatus("processing")

        if (data.type === "gift-cards") {
          return bulkRewardsApi.sendBulkRewards({
            file: fileData,
            codeInfoId: data.code as string,
          }).then(({ published, failed }) => {
            setStatus(undefined)
            const failures = failed.map(f => f.accountId)
            if (failures.length) {
              notifications.info(`
              Failed to send rewards to ${failures.join(", ")}, successful: ${published}, file with failed accounts will be downloaded
              `)
              const failedFile = new File([failures.join("\n")], file.name.replace(".csv", "") + "_failed.csv", { type: "text/csv" })
              const url = window.URL.createObjectURL(failedFile)
              const link = document.createElement("a")
              link.href = url
              link.setAttribute("download", failedFile.name)
              document.body.appendChild(link)
              link.click()
              link.remove()
            } else notifications.success(`Rewards sent successfully to ${published} users`)
          }).catch(() => {
            notifications.error("Failed to send rewards")
          })
        } else if (data.type === "buffs") {
          return bulkRewardsApi.sendBulkRewardBuffs({
            file: fileData,
            transactionAmount: data.amount as number,
            transactionType: data.transactionType?.toString() as string,
            transactionName: data.name as string,
          }).then(({ published, failed }) => {
            setStatus(undefined)
            const failures = failed.map(f => f.accountId)
            if (failures.length) {
              notifications.info(`
              Failed to send rewards to ${failures.join(", ")}, successful: ${published}, file with failed accounts will be downloaded
              `)
              const failedFile = new File([failures.join("\n")], file.name.replace(".csv", "") + "_failed.csv", { type: "text/csv" })
              const url = window.URL.createObjectURL(failedFile)
              const link = document.createElement("a")
              link.href = url
              link.setAttribute("download", failedFile.name)
              document.body.appendChild(link)
              link.click()
              link.remove()
            } else notifications.success(`Rewards sent successfully to ${published} users`)
          }).catch(() => {
            notifications.error("Failed to send rewards")
          })
        }
      }).catch(e => {
        setStatus(undefined)
        notifications.error("Failed to upload file")
      })
    )).catch(e => {
      setStatus(undefined)
      notifications.error("Failed to get upload url")
    }).finally(() => {
      setStatus(undefined)
    })
  }

  useEffect(() => {
    if (!accountTransactionsStore.availableTypes) {
      accountTransactionsStore.getTransactionTypes()
    }
  }, [])

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 16,
          backgroundColor: "white",
          paddingBlock: 16,
        }}
      >
        <Title level={2} style={{ margin: 0 }}>
          Create Bulk Rewards
        </Title>
        <Title level={4} style={{ margin: 0, flexGrow: 1 }}>
          Upload a spreadsheet with account IDs
        </Title>
        <Button type="primary" onClick={selectFile}>
          <UploadOutlined />
          Upload CSV
        </Button>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingBottom: 12,
          marginTop: 16,
          backgroundColor: "white",
          boxShadow: "0px 0px 400px lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "1px solid lightgray",
            flex: 1,
            flexGrow: 1,
            padding: 12,
          }}
        >
          <Title level={4} style={{ margin: 0 }}>
            Main info
          </Title>
          <div>
            <Button
              type="default"
              onClick={() => setFile(null)}
              style={{ marginRight: 12 }}
              disabled={!file || submitting}
            >
              Cancel
            </Button>
            <Button type="primary" disabled={!file || submitting} onClick={handleSubmit} loading={submitting}>
              Send Rewards
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: 16,
            width: "100%",
            justifyContent: "space-between",
            flex: 1,
            flexGrow: 1,
            paddingInline: 12,
          }}
        >
          <div>
            <h4 style={{ margin: 0, paddingBottom: 12 }}>File Name</h4>
            <Input
              value={file?.name || "Not uploaded"}
              style={{ cursor: "pointer" }}
              readOnly
              onClick={selectFile}
            />
          </div>
          <div>
            <h4 style={{ margin: 0, paddingBottom: 12 }}>User Count</h4>
            <Input
              value={userCount || "---"}
              readOnly
              bordered={false}
              style={{ border: "1px solid lightgray" }}
            />
          </div>
          <div>
            <h4 style={{ margin: 0, paddingBottom: 12, width: "100%" }}>Type of Reward</h4>
            {!file ? (
              <Input
                value="---"
                readOnly
                bordered={false}
                style={{ border: "1px solid lightgray", width: "100%" }}
              />
            ) : (
              <Select
                placeholder="Choose Reward Type"
                value={type || null}
                style={{ width: "100%" }}
                onChange={e => setType(e)}
              >
                <Select.Option value="buffs" style={{ width: "100%" }}>
                Buffs
                </Select.Option>
                <Select.Option value="gift-cards" style={{ width: "100%" }}>
                Gift Cards
                </Select.Option>
              </Select>
            )}
          </div>
        </div>
        {!!file && !!type && !!data && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: 16,
              width: "100%",
              justifyContent: "space-between",
              flex: 1,
              flexGrow: 1,
              paddingInline: 12,
            }}
          >
            <div>
              <h4 style={{ margin: 0, paddingBottom: 12, width: "100%" }}>Reward Name</h4>
              <Input
                value={data.name}
                onChange={e => setData(
                  old => old && {
                    ...old,
                    name: e.target.value,
                  },
                )}
              />
            </div>
            {data.type === "buffs" ? (
              <>
                <div>
                  <h4 style={{ margin: 0, paddingBottom: 12, width: "100%" }}>Transaction Type</h4>
                  <Select
                    style={{ width: "100%" }}
                    value={data.transactionType}
                    onChange={e => setData(old => {
                      if (!old) return undefined

                      return {
                        ...(old as BuffReward),
                        transactionType: e,
                      }
                    })}
                  >
                    {Object.entries(accountTransactionsStore.availableTypes).map((item, id) => (
                      <Select.Option value={item[0]} key={id}>
                        {item[1]} - {item[0]}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <h4 style={{ margin: 0, paddingBottom: 12, width: "100%" }}>Buffs Amount</h4>
                  <Input
                    value={data.amount}
                    onChange={e => setData(old => {
                      if (!old) return undefined
                      let value: number | undefined = Number(e.target.value)
                      if (isNaN(value)) value = undefined

                      return {
                        ...(old as BuffReward),
                        amount: value,
                      }
                    })}
                  />
                </div>
              </>
            ) : (
              <div>
                <h4 style={{ margin: 0, paddingBottom: 12, width: "100%" }}>Code Storage</h4>
                <Select
                  loading={!availableCodes}
                  placeholder="Choose Code From Storage"
                  value={data.code || null}
                  style={{ width: "100%" }}
                  onChange={e => setData(old => {
                    if (!old) return undefined

                    return {
                      ...(old as GiftCardReward),
                      code: e,
                    }
                  })}
                  disabled={!availableCodes}
                  notFoundContent={availableCodes ? "No codes available" : "Loading..."}
                  optionLabelProp="label"
                >
                  {!!availableCodes?.length && (
                    <Select.Option
                      value=""
                      style={{ backgroundColor: "#EAEAEA", paddingBlock: 12, fontWeight: "bold" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyItems: "space-between",
                        }}
                      >
                        <span style={{ flexGrow: 1 }}>Name</span>
                        <span style={{ width: 80 }}>Free Codes</span>
                      </div>
                    </Select.Option>
                  )}
                  {availableCodes?.map(code => {
                    const disabled = (code.numberOfFreeCodes || 0) < (userCount || 0)

                    return (
                      <Select.Option key={code.id} value={code.id} label={code.name} disabled={disabled}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyItems: "space-between",
                            fontWeight: !disabled ? "bold" : undefined,
                          }}
                        >
                          <span style={{ flexGrow: 1 }}>{code.name}</span>
                          <span style={{ width: 80 }}>{code.numberOfFreeCodes}</span>
                        </div>
                      </Select.Option>
                    )
                  })}
                </Select>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
})
