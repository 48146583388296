import React, { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { Modal, Table } from "antd"
import { bannersColumns } from "./columns"
import { observer } from "mobx-react"
import { BannerEdit } from "./ads-banners-edit"
import { DeleteIconButton, showDeleteConfirm, CreateNewElement } from "components"
import { Column } from "abstract/types/column.type"
import { ConfigItemAtList, InGameBannerType } from "domains/managingJson/store/types"

export const AdsBannersList: FC = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()
  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)
  const [banners, setbanners] = useState<any[]>()
  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const adsBanners = managingJsonStore?.itemTypes?.filter(item => item.type === "adsBanners")
    setbanners(adsBanners)
  }, [managingJsonStore.itemTypes])

  const onClose = () => {
    setEditCreateVisibility(null)
    managingJsonStore.clearSelectedElement()
  }

  const columns: Column[] = [
    ...bannersColumns,
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: InGameBannerType) => (
        <DeleteIconButton
          onClick={e => {
            if (row?.name) {
              e.stopPropagation()
              showDeleteConfirm(row?.name, undefined, async() => {
                managingJsonStore.funcAfterSuccess = async() => await managingJsonStore.getData()

                await managingJsonStore.deleteByName(row?.name || "", "adsBanners")
              })
            }
          }}
        />
      ),
    },
  ]

  return (
    <>
      <CreateNewElement
        onClick={() => {
          managingJsonStore.clearSelectedElement()
          setEditCreateVisibility("create")
        }}
        valueText="ads banner"
      />

      <Table
        dataSource={banners}
        columns={columns as any}
        rowKey="id"
        onRow={(record: ConfigItemAtList) => ({
          onClick: () => {
            managingJsonStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit ads banner" : "Create ads banner"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <BannerEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
