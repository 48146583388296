import React, { useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Button, Divider, Select, Tag } from "antd"
import { observer } from "mobx-react"

import { gameNames } from "abstract/constants/game-ids"
import { CloseOutlined, PlusOutlined, SaveFilled } from "@ant-design/icons"
import { DeleteIconButton } from "components"
import Styles from "./styles.module.css"
import { toJS } from "mobx"
import { produce } from "immer"
import { notifications } from "notifications"
import type { CustomTagProps } from "rc-select/lib/BaseSelect"
import { TestingArea } from "./TestingArea"
import { ConfigItemAtList } from "domains/managingJson/store/types"

type BlockedGameMode = {
  gameId: string;
  exactMatches: string[];
  partialMatches: string[];
};

export const EditBlockedGameModesV2 = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()
  const [testString, setTestString] = useState<string>("")
  const [dataFromServer, setDataFromServer] = useState<BlockedGameMode[] | undefined>(undefined)
  const [gamesWithBlockedModes, setGamesWithBlockedModes] = useState<BlockedGameMode[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    const fetch = async() => {
      setIsLoading(true)
      await managingJsonStore.getData()
      setIsLoading(false)
    }
    fetch()
  }, [managingJsonStore])

  useEffect(() => {
    const blockedGameModes = managingJsonStore?.itemTypes?.find(
      item => item.type === "blockedGameModesV2",
    )
    const JSblockedGameModes = toJS(blockedGameModes) as ConfigItemAtList<"blockedGameModesV2">
    if (blockedGameModes && JSblockedGameModes?.data?.blockedGameModes) {
      setGamesWithBlockedModes(JSblockedGameModes.data.blockedGameModes)
      setDataFromServer(JSblockedGameModes.data.blockedGameModes)
    }
  }, [managingJsonStore.itemTypes])

  const save = async() => {
    setIsSaving(true)

    if (gamesWithBlockedModes.length > 0) {
      const emptyGameId = gamesWithBlockedModes.find(game => game.gameId.length === 0)
      if (emptyGameId) {
        notifications.error("Please select a game for all entries before saving")
        setIsSaving(false)

        return
      }
    }

    try {
      dataFromServer
        ? await managingJsonStore.update({
          name: "blockedGameModesV2",
          type: "blockedGameModesV2",
          data: { blockedGameModes: gamesWithBlockedModes },
        })
        : await managingJsonStore.create({
          name: "blockedGameModesV2",
          type: "blockedGameModesV2",
          data: { blockedGameModes: gamesWithBlockedModes },
        })
      notifications.success("Successfully saved")
    } catch (error) {
      notifications.error("Something went wrong")
    }
    setIsSaving(false)
  }

  const addNewGame = () => {
    setGamesWithBlockedModes(
      produce(gamesWithBlockedModes, draft => {
        if (draft) {
          draft.push({ gameId: "", exactMatches: [], partialMatches: [] })
        }
      }),
    )
  }

  const deleteGame = (gameId: string) => {
    setGamesWithBlockedModes(
      produce(gamesWithBlockedModes, draft => {
        if (draft) {
          const index = draft.findIndex(el => el.gameId === gameId)
          if (index !== -1) {
            draft.splice(index, 1)
          }
        }
      }),
    )
  }

  const updateGameExactMatches = (gameId: string, value: string[]) => {
    setGamesWithBlockedModes(
      produce(gamesWithBlockedModes, draft => {
        if (draft) {
          const game = draft.find(el => el.gameId === gameId)
          if (game) {
            game.exactMatches = value
          }
        }
      }),
    )
  }

  const updateGamePartialMatches = (gameId: string, value: string[]) => {
    setGamesWithBlockedModes(
      produce(gamesWithBlockedModes, draft => {
        if (draft) {
          const game = draft.find(el => el.gameId === gameId)
          if (game) {
            game.partialMatches = value
          }
        }
      }),
    )
  }

  const setGameIdForIndex = (index: number, gameId: string) => {
    setGamesWithBlockedModes(prev => produce(prev, draft => {
      if (draft) {
        const game = draft[index]
        if (game) {
          game.gameId = gameId
        }
      }
    }),
    )
  }

  const tagRender = (props: CustomTagProps, matchType: "exact" | "partial") => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }

    const getIsTriggered = () => {
      if (matchType === "exact") {
        return (
          testString.length > 0
          && value.length > 0
          && value.toLowerCase() === testString.toLowerCase()
        )
      }
      if (matchType === "partial") {
        return (
          testString.length > 0
          && value.length > 0
          && testString.toLowerCase().includes(value.toLowerCase())
        )
      }
    }

    return (
      <Tag
        color={getIsTriggered() ? "error" : "default"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        closeIcon={<CloseOutlined style={getIsTriggered() ? { color: "rgb(255, 77, 79)" } : {}} />}
      >
        {label}
      </Tag>
    )
  }

  return (
    <div className={Styles.wrapper}>
      <h1 className={Styles.title}>Blocked Game Modes V2</h1>
      <TestingArea
        value={testString}
        onChange={
          (value: string) => {
            setTestString(value)
          }
        }
      />
      <Divider />

      {isLoading && <div>Loading...</div>}
      {!isLoading && gamesWithBlockedModes.map(({ gameId, exactMatches, partialMatches }, index) => {
        // select options exclude already selected games, but include the current selected game
        const selectOptions = Object.entries(gameNames)
          .filter(
            ([key]) => !gamesWithBlockedModes.find(blockedMode => blockedMode.gameId === key)
              || key === gameId,
          )
          .map(([key, value]) => ({ label: value, value: key }))

        return (
          <div className={Styles.column} key={gameId}>
            <Select
              className={Styles.select}
              value={gameId ?? undefined}
              onChange={e => {
                setGameIdForIndex(index, e)
              }}
              options={selectOptions}
            />
            <label>
              Exact matches
              <Select
                mode="tags"
                placeholder="Add by pressing enter"
                tagRender={props => tagRender(props, "exact")}
                disabled={gameId.length === 0}
                onChange={(e: string[]) => {
                  updateGameExactMatches(gameId, e)
                }}
                value={exactMatches}
              />
            </label>

            <label>
              Partial matches
              <Select
                mode="tags"
                placeholder="Add by pressing enter"
                tagRender={props => tagRender(props, "partial")}
                disabled={gameId.length === 0}
                onChange={(e: string[]) => {
                  updateGamePartialMatches(gameId, e)
                }}
                value={partialMatches}
              />
            </label>

            <DeleteIconButton onClick={() => deleteGame(gameId)} />
          </div>
        )
      })}
      <div>
        <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addNewGame} />
      </div>
      <div style={{ marginTop: 20 }}>
        <Button
          style={{ margin: "20 0" }}
          type="primary"
          htmlType="submit"
          onClick={save}
          disabled={isSaving}
          icon={<SaveFilled />}
        >
          Save
        </Button>
      </div>
    </div>
  )
})
