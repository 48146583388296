import { apiCall } from "api-calls/api-call"
import {
  GroupToUpdate,
  PromocodeGroup,
  GroupResponse,
  GroupRule,
} from "domains/promocodes/store/types"

export const getPromocodeGroups = async(
  page: number,
  limit: number,
): Promise<{ total: number; data: PromocodeGroup[] }> => {
  const query: { page: number; limit: number } = {
    page,
    limit,
  }

  return apiCall({
    method: "GET",
    path: "promocodes/promo-groups",
    query,
  })
}

export const createPromocodeGroup = async(input: GroupToUpdate): Promise<GroupResponse> => {
  return apiCall({
    method: "POST",
    path: "promocodes/promo-groups",
    body: input,
  })
}

export const updatePromocodeGroup = async(
  id: string,
  input: GroupToUpdate,
): Promise<GroupResponse> => {
  return apiCall({
    method: "PUT",
    path: `promocodes/promo-groups/${id}`,
    body: input,
  })
}
export const deletePromocodeGroup = async(id: string): Promise<GroupResponse> => {
  return apiCall({
    method: "DELETE",
    path: `promocodes/promo-groups/${id}`,
  })
}

export const getGroupRules = async(): Promise<{ total: number; data: GroupRule[] }> => {
  return apiCall({
    method: "GET",
    path: "promocodes/promo-group-rules?page=1&limit=10",
  })
}
