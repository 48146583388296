import React, { FC } from "react"

import { Divider } from "antd"
import { FormElement } from "components"

export const ErrorStateGlobal: FC<{ data?: any }> = ({ data }) => {
  return (
    <>
      <Divider>Error State Global data </Divider>
      <div>
        <FormElement
          type="input"
          label="title"
          name={["errorStateGlobal", "title"]}
          initialValue={data?.title}
        />
        <FormElement
          type="input"
          label="subtitle"
          name={["errorStateGlobal", "subtitle"]}
          initialValue={data?.subtitle}
        />
        <FormElement
          type="input"
          label="CTAtext"
          name={["errorStateGlobal", "CTAtext"]}
          initialValue={data?.CTAtext}
        />
      </div>
    </>
  )
}
