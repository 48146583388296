import React, { FC } from "react"

import { Divider } from "antd"
import { FormElement } from "components"

export const Leaderboard: FC<{ data?: any }> = ({ data }) => {
  return (
    <>
      <Divider>Leaderboard data</Divider>
      <div>
        <h4>Head table sell titles</h4>
        <FormElement
          type="input"
          label="Rank"
          name={["leaderboard", "head", "rank"]}
          initialValue={data?.head?.rank}
        />
        <FormElement
          type="input"
          label="Nickname"
          name={["leaderboard", "head", "nickname"]}
          initialValue={data?.head?.nickname}
        />
        <FormElement
          type="input"
          label="Reward"
          name={["leaderboard", "head", "reward"]}
          initialValue={data?.head?.reward}
        />
        <FormElement
          type="input"
          label="Points"
          name={["leaderboard", "head", "points"]}
          initialValue={data?.head?.points}
        />
        <FormElement
          type="input"
          label="Points Tooltip"
          name={["leaderboard", "head", "pointsTooltip"]}
          initialValue={data?.head?.pointsTooltip}
        />
        <FormElement
          type="input"
          label="Live"
          name={["leaderboard", "head", "live"]}
          initialValue={data?.head?.live}
        />
        <FormElement
          type="input"
          label="Week"
          name={["leaderboard", "head", "week"]}
          initialValue={data?.head?.week}
        />
        <FormElement
          type="input"
          label="Completed"
          name={["leaderboard", "head", "completed"]}
          initialValue={data?.head?.completed}
        />
        <FormElement
          type="input"
          label="All Time Best"
          name={["leaderboard", "head", "allTimeBest"]}
          initialValue={data?.head?.allTimeBest}
        />
        <FormElement
          type="input"
          label="Upcoming"
          name={["leaderboard", "head", "upcoming"]}
          initialValue={data?.head?.upcoming}
        />
        <h4>Empty Page</h4>
        <FormElement
          type="input"
          label="title"
          name={["leaderboard", "emptyPage", "title"]}
          initialValue={data?.emptyPage?.title}
        />
        <FormElement
          type="input"
          label="subtitle"
          name={["leaderboard", "emptyPage", "subtitle"]}
          initialValue={data?.emptyPage?.subtitle}
        />
      </div>
    </>
  )
}
