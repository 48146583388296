import {
  MobileConfigAnalytics,
  MobileConfigHeaderButton,
  MobileConfigTab,
  MobileConfigVariant,
} from "domains/managingJson/store/types"
import { Button, Space, Tooltip, Typography } from "antd"
import { HeaderButtonEditor } from "./HeaderButtonEditor"
import { AnalyticsEditor } from "./AnalyticsEditor"
import { useCallback, useEffect, useState } from "react"
import { TabEditor } from "./TabEditor"
import { v4 as uuidv4 } from "uuid"
const { Title, Text } = Typography

type Props = {
  index: number;
  deleteVariant: () => void;
  duplicateVariant: () => void;
  setVariantData: (
    callback: (prev: MobileConfigVariant) => MobileConfigVariant,
    index: number,
  ) => void;
  variantData: MobileConfigVariant;
  setIsVariantValid: (isValid: boolean, index: number) => void;
};

export const VariantEditor = ({
  index,
  deleteVariant,
  duplicateVariant,
  setVariantData,
  variantData,
  setIsVariantValid,
}: Props) => {
  const [tabErrors, setTabErrors] = useState<boolean[]>([])
  const [headerButtonErrors, setHeaderButtonErrors] = useState<boolean[]>([])
  const [analyticsError, setAnalyticsError] = useState(false)

  const isValid
    = !tabErrors.includes(true) && !headerButtonErrors.includes(true) && !analyticsError

  useEffect(() => {
    setIsVariantValid(isValid, index)
  }, [index, isValid, setIsVariantValid])

  const updateTabData = useCallback(
    (callback: (prev: MobileConfigTab) => MobileConfigTab, tabIndex: number) => {
      setVariantData(prev => {
        const prevTabData = prev.tabs[tabIndex]
        const newTabData = callback(prevTabData)
        const newTabs = [...prev.tabs]
        newTabs[tabIndex] = newTabData

        return { ...prev, tabs: newTabs }
      }, index)
    },
    [index, setVariantData],
  )

  const updateHeaderButtonData = useCallback(
    (
      callback: (prev: MobileConfigHeaderButton) => MobileConfigHeaderButton,
      headerButtonIndex: number,
    ) => {
      setVariantData(prev => {
        if (!prev.headerButtons) return prev
        const prevHeaderButtonData = prev.headerButtons[headerButtonIndex]
        const newHeaderButtonData = callback(prevHeaderButtonData)
        const newHeaderButtons = [...prev.headerButtons]
        newHeaderButtons[headerButtonIndex] = newHeaderButtonData

        return { ...prev, headerButtons: newHeaderButtons }
      }, index)
    },
    [index, setVariantData],
  )

  const updateAnalyticsData = (data: MobileConfigAnalytics | undefined) => {
    setVariantData(prev => {
      if (!data) {
        const { ...withoutAnalytics } = prev

        return withoutAnalytics
      }

      return { ...prev, analytics: data }
    }, index)
  }

  const createNewTab = () => {
    setVariantData(
      prev => ({
        ...prev,
        tabs: [
          ...prev.tabs,
          { icon: "", name: "New tab", src: "", iconProps: undefined, uid: uuidv4() },
        ],
      }),
      index,
    )
  }

  const createNewHeaderButton = () => {
    const newHeaderButton = { uid: uuidv4(), icon: "", options: undefined, src: "" }
    setVariantData(
      prev => ({
        ...prev,
        headerButtons: [...(prev.headerButtons ?? []), newHeaderButton],
      }),
      index,
    )
  }

  const deleteTab = useCallback(
    (tabIndex: number) => {
      setVariantData(
        prev => ({
          ...prev,
          tabs: [...prev.tabs.filter((_, index) => index !== tabIndex)],
        }),
        index,
      )
    },
    [index, setVariantData],
  )

  const deleteHeaderButton = useCallback(
    (tabIndex: number) => {
      setVariantData(prev => {
        const newHeaderButtons = prev.headerButtons!.filter((_, index) => index !== tabIndex)
        if (newHeaderButtons.length === 0) {
          const { ...withoutHeaderButtons } = prev

          return withoutHeaderButtons
        }

        return {
          ...prev,
          headerButtons: newHeaderButtons.length > 0 ? newHeaderButtons : undefined,
        }
      }, index)
    },
    [index, setVariantData],
  )

  const duplicateTab = useCallback(
    (tabIndex: number) => {
      setVariantData(prev => {
        const toBeDuplicated = prev.tabs[tabIndex]
        const duplicated: MobileConfigTab = {
          ...toBeDuplicated,
          name: `${toBeDuplicated.name} DUPLICATED`,
          uid: uuidv4(),
        }
        const tabs = [...prev.tabs]
        tabs.splice(tabIndex + 1, 0, duplicated)

        return {
          ...prev,
          tabs,
        }
      }, index)
    },
    [index, setVariantData],
  )

  const duplicateHeaderButton = useCallback(
    (tabIndex: number) => {
      setVariantData(prev => {
        const toBeDuplicated = prev?.headerButtons?.[tabIndex]
        if (!toBeDuplicated) return prev
        const duplicated: MobileConfigHeaderButton = {
          ...toBeDuplicated,
          uid: uuidv4(),
        }
        const headerButtons = [...(prev.headerButtons ?? [])]
        headerButtons.splice(tabIndex + 1, 0, duplicated)

        return {
          ...prev,
          headerButtons,
        }
      }, index)
    },
    [index, setVariantData],
  )

  const updateTabErrors = useCallback((err: boolean, index: number) => {
    setTabErrors(prev => {
      const newErrors = [...prev]
      newErrors[index] = err

      return newErrors
    })
  }, [])

  const updateHeaderErrors = useCallback((err: boolean, index: number) => {
    setHeaderButtonErrors(prev => {
      const newErrors = [...prev]
      newErrors[index] = err

      return newErrors
    })
  }, [])

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex", paddingBottom: 20 }}>
      <div>
        <Title level={3}>
          Tabs{tabErrors.some(err => err) && <Text type="danger"> (!)</Text>}
        </Title>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          {variantData.tabs.map((tab, index) => (
            <TabEditor
              index={index}
              initialTab={tab}
              key={tab.uid}
              setError={updateTabErrors}
              error={tabErrors[index]}
              updateTab={updateTabData}
              deleteTab={deleteTab}
              duplicateTab={duplicateTab}
            />
          ))}
          <Button type="primary" onClick={createNewTab}>
            Add new tab
          </Button>
        </Space>
      </div>

      <div>
        <Title level={3}>Header Buttons</Title>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          {variantData?.headerButtons
            && variantData.headerButtons.map((headerButton, index) => (
              <HeaderButtonEditor
                headerButton={headerButton}
                key={headerButton.uid}
                index={index}
                setError={updateHeaderErrors}
                error={headerButtonErrors[index]}
                updateHeaderButton={updateHeaderButtonData}
                deleteHeaderButton={deleteHeaderButton}
                duplicateHeaderButton={duplicateHeaderButton}
              />
            ))}
          <Button type="primary" onClick={createNewHeaderButton}>
            Add new header button
          </Button>
        </Space>
      </div>

      <div>
        <Title level={3}>Analytics{analyticsError && <Text type="danger"> (!)</Text>}</Title>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <AnalyticsEditor
            initialAnalytics={variantData.analytics}
            setError={setAnalyticsError}
            updateAnalytics={updateAnalyticsData}
          />
        </Space>
      </div>

      <Space size="middle">
        <Tooltip title="REMINDER: After deleting, you still have to save your changes for them to take place">
          <Button danger onClick={deleteVariant}>
            Delete variant {index}
          </Button>
        </Tooltip>
        <Button onClick={duplicateVariant}>Duplicate variant {index}</Button>
      </Space>
    </Space>
  )
}
