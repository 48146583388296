import { apiCall } from "api-calls/api-call"
import { Subscription, SubscriptionResponse } from "domains/subscription/store/types"

export const getSubscriptions = async(): Promise<Subscription[]> => {
  return apiCall({
    method: "GET",
    path: "subscriptions",
  })
}

export const updateSubscription = async(
  id: string,
  input: Subscription,
): Promise<SubscriptionResponse> => {
  return apiCall({
    method: "PUT",
    path: `subscriptions/${id}`,
    body: input,
  })
}
