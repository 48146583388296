import { computed, observable, transaction, makeObservable } from "mobx"

import { RootStore } from "stores/root.store"
import { ConfigItemAtList, ConfigItem, NameItem, TypeItem } from "./types"
import { BaseStore } from "stores/base.store"
import {
  createItem,
  deleteItem,
  getItems,
  updateItem,
  updateItemName,
  updateItemType,
} from "../api"

export class ManagingJsonStore extends BaseStore {
  @observable
  private _itemTypes: ConfigItemAtList[]
  @observable
  private _itemTypesFromBackend: ConfigItemAtList[]

  @observable
  private _selectedElement: ConfigItemAtList = {} as ConfigItemAtList

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<ConfigItemAtList[]> {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getItems)
    if (res) {
      transaction(() => {
        this._itemTypes = res
        this._itemTypesFromBackend = res
      })
    }

    return res
  }

  async update<K extends ConfigItem = ConfigItem>(data: K) {
    await this.defaultFunction(updateItem, data)
  }

  async updateName(data: NameItem) {
    await this.defaultFunction(updateItemName, data)
  }

  async updateType(data: TypeItem) {
    await this.defaultFunction(updateItemType, data)
  }

  async create(data: ConfigItem) {
    await this.defaultFunction(createItem, data)
  }

  async deleteByName(name: string, type: string) {
    await this.defaultFunction(deleteItem, name, type)
  }

  @computed
  get itemTypes(): ConfigItemAtList[] {
    return this._itemTypes
  }
  set itemTypes(items: ConfigItemAtList[]) {
    this._itemTypes = items
  }

  @computed
  get itemTypesFromBackend(): ConfigItemAtList[] {
    return this._itemTypesFromBackend
  }

  @computed
  get selectedElement(): ConfigItemAtList {
    return this._selectedElement
  }

  set selectedElement(value: ConfigItemAtList) {
    this._selectedElement = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as ConfigItemAtList
  }
}
