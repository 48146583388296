import {
  GetItemsFilter,
  PromocodeResponse,
  ChildCodeType,
  ChildToUpdate,
} from "domains/promocodes/store/types"
import { apiCall } from "api-calls/api-call"

export const getChildCodes = async(
  input: GetItemsFilter,
  page: number,
  limit: number,
): Promise<{ total: number; data: ChildCodeType[] }> => {
  const query: { page: number; limit: number } & { filter: GetItemsFilter } = {
    page,
    limit,
    filter: { ...input },
  }

  return apiCall({
    method: "GET",
    path: "promocodes/child-codes",
    query,
  })
}

export const createItem = async(input: ChildToUpdate): Promise<PromocodeResponse> => {
  return apiCall({
    method: "POST",
    path: "promocodes/child-codes",
    body: input,
  })
}

export const updateItem = async(id: string, input: ChildToUpdate): Promise<PromocodeResponse> => {
  return apiCall({
    method: "PUT",
    path: `promocodes/child-codes/${id}`,
    body: input,
  })
}
export const deleteItem = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `promocodes/child-codes/${id}`,
  })
}

export const getCsv = async(id: string): Promise<{url: string}> => {
  return apiCall({
    method: "GET",
    path: `promocodes/child-codes/csv?parentPromoCodeId=${id}`,
  })
}
