import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { Button, Modal, Table, Tabs } from "antd"
import { rafflesColumns } from "./all-columns"
import { DeleteIconButton, showDeleteConfirm, EditIconButton, TrophyIconButton } from "components"
import Search from "antd/es/input/Search"
import { useDebouncedCallback } from "use-debounce"
import { PrizeCreation } from "../PrizeCreation"
import { FullRaffle } from "../../store/types"
import { RaffleEdit } from "../RaffleCreate"

export const RafflesList: FC = observer(() => {
  const { dataStore: { rafflesStore } } = useStore()

  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [valSearch, setValSearch] = useState<string>("")

  const [isCreateEditRaffle, setIsCreateEditRaffle] = useState<"create" | "edit" | null>(null)
  const [isPrizeSetup, setPrizeSetup] = useState<boolean>(false)

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      rafflesStore.pagination = {
        page,
        limit: pageSize || rafflesStore.pagination.limit,
      }
    },
    total: rafflesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }

  const debounce = useDebouncedCallback(async(val: string) => {
    rafflesStore.setFilter({ ...rafflesStore.filter, title: val.trim() })
    setLoadingSearch(false)
  }, 2000)

  useEffect(() => {
    rafflesStore.getData()
  }, [rafflesStore])

  const columns = columnsAdd => [
    ...columnsAdd,
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, row: any) => (
        <div style={{ display: "flex", gap: 10 }}>
          {row.lifeCycleStatus === "PROCESSING" && (
            <Button type="primary" onClick={() => rafflesStore.submitProcessing(row?.id || "")}>
              Submit
            </Button>
          )}
          <TrophyIconButton
            onClick={e => {
              e.stopPropagation()
              setPrizeSetup(true)
              rafflesStore.selectedElement = row
            }}
          />

          <EditIconButton
            onClick={e => {
              e.stopPropagation()
              setIsCreateEditRaffle("edit")
              rafflesStore.selectedElement = row
            }}
          />
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(row.title, undefined, async() => {
                await rafflesStore.deleteRaffle(row?.id || "")
                await rafflesStore.getData()
              })
            }}
          />
        </div>
      ),
    },
  ]

  const rafflesReturn = () => (
    <>
      <div style={{ display: "flex", gap: 10 }}>
        <Button
          type="primary"
          onClick={() => setIsCreateEditRaffle("create")}
          style={{ marginBottom: "1rem" }}
        >
          Create a new raffle
        </Button>
        <Search
          placeholder="Search by name"
          allowClear
          loading={loadingSearch}
          value={valSearch}
          onChange={e => {
            const val = e.target.value
            setValSearch(val)
            setLoadingSearch(true)
            debounce(val)
          }}
          enterButton={<Button type="primary">Search</Button>}
          onSearch={(value: string) => {
            rafflesStore.setFilter({ ...rafflesStore.filter, title: value.trim() })
          }}
        />
      </div>
      <Table
        dataSource={rafflesStore.raffles}
        columns={columns(rafflesColumns)}
        rowKey="id"
        pagination={paginationOptions}
        loading={rafflesStore.isLoading}
        id="raffleTable"
      />
    </>
  )

  return (
    <div id="rafflesPage">
      <Tabs
        onChange={(e: string) => {
          switch (e) {
          case "1":
            rafflesStore.setFilter({})
            break
          case "2":
            rafflesStore.setFilter({ handleType: "CUSTOM" })
            break
          case "3":
            rafflesStore.setFilter({ handleType: "AUTOMATED" })
            break
          }
        }}
      >
        <Tabs.TabPane tab="All Raffles" key="1">
          {rafflesReturn()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Custom" key="2">
          {rafflesReturn()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Automated" key="3">
          {rafflesReturn()}
        </Tabs.TabPane>
      </Tabs>

      {isCreateEditRaffle && (
        <Modal
          visible
          onCancel={() => {
            rafflesStore.selectedElement = {} as FullRaffle
            setIsCreateEditRaffle(null)
          }}
          width={510}
          style={{ top: 50 }}
          bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
          footer={null}
          title={isCreateEditRaffle === "create" ? "Create Raffle" : "Edit Raffle"}
        >
          <RaffleEdit
            editType={isCreateEditRaffle}
            closeModal={() => {
              rafflesStore.selectedElement = {} as FullRaffle
              setIsCreateEditRaffle(null)
            }}
          />
        </Modal>
      )}

      {isPrizeSetup && (
        <PrizeCreation
          closeModal={() => setPrizeSetup(false)}
          editType={isCreateEditRaffle ? "create" : "edit"}
        />
      )}
    </div>
  )
})
