import React, { FC } from "react"

import { SplashScreen } from "../SplashScreen"
import { OnboardingCTA } from "../OnboardingCTA"
import { ErrorStateGlobal } from "../ErrorStateGlobal"
import { Schedule } from "../Schedule"
import { Leaderboard } from "../Leaderboard"
import { Rewards } from "../Rewards"
import { RulesSection } from "../RulesSection"

export const TranslationsTab: FC<{
  data: any;
  setparagraps: any;
  addRules: any;
  setaddRules: any;
  type: "SCORE_BASED" | "CHALLENGE_BASED" | undefined;
}> = ({ data, setparagraps, addRules, setaddRules, type }) => {
  const isScoreBased = type === "SCORE_BASED"

  return (
    <div>
      {isScoreBased && (
        <>
          <SplashScreen data={data?.splashScreen} />
          <OnboardingCTA data={data?.onboardingCTA} />
          <ErrorStateGlobal data={data?.errorStateGlobal} />
          <Schedule data={data?.schedule} />
          <Leaderboard data={data?.leaderboard} />
        </>
      )}
      <Rewards data={data} />
      {isScoreBased && (
        <RulesSection
          data={data?.rulesSection}
          setparagraps={setparagraps}
          addRules={addRules}
          setaddRules={setaddRules}
        />
      )}
    </div>
  )
}
