import { apiCall } from "api-calls/api-call"
import { IAdvertiser, IExternalOfferProvider, IExternalOfferProviderInput, ITask, ITaskReward } from "./types"

export const listAdvertisers = async(): Promise<IAdvertiser[]> => {
  return apiCall({
    method: "GET",
    path: "offerwall/advertisers",
  })
}

export const getAdvertiser = async(id: string): Promise<IAdvertiser> => {
  return apiCall({
    method: "GET",
    path: `offerwall/advertisers/${id}`,
  })
}

export const createAdvertiser = async(input: Omit<IAdvertiser, "id">): Promise<IAdvertiser> => {
  return apiCall({
    method: "POST",
    path: "offerwall/advertisers",
    body: input,
  })
}

export const updateAdvertiser = async(input: IAdvertiser): Promise<IAdvertiser> => {
  return apiCall({
    method: "PATCH",
    path: `offerwall/advertisers/${input.id}`,
    body: input,
  })
}

export const deleteAdvertiser = async(id: string): Promise<IAdvertiser> => {
  return apiCall({
    method: "DELETE",
    path: `offerwall/advertisers/${id}`,
  })
}

export const createTask = async(advertiser: string, input: ITask): Promise<ITask> => {
  return apiCall({
    method: "POST",
    path: "offerwall/tasks",
    body: {
      advertiserId: advertiser,
      ...input,
    },
  })
}

export const updateTask = async(advertiser: string, input: ITask): Promise<ITask> => {
  return apiCall({
    method: "PATCH",
    path: `offerwall/tasks/${input.id}`,
    body: {
      advertiserId: advertiser,
      ...input,
    },
  })
}

export const deleteTask = async(id: string): Promise<ITask> => {
  return apiCall({
    method: "DELETE",
    path: `offerwall/tasks/${id}`,
  })
}

export const createTaskReward = async(taskId: string, reward: ITaskReward) => {
  return apiCall({
    method: "POST",
    path: "offerwall/rewards",
    body: {
      taskId,
      ...reward,
    },
  })
}

export const updateTaskReward = async(reward: ITaskReward) => {
  return apiCall({
    method: "PATCH",
    path: `offerwall/rewards/${reward.id}`,
    body: reward,
  })
}

export const deleteTaskReward = async(id: string) => {
  return apiCall({
    method: "DELETE",
    path: `offerwall/rewards/${id}`,
  })
}

export const listExternalOfferProviders = async(): Promise<IExternalOfferProvider[]> => {
  return apiCall({
    method: "GET",
    path: "offerwall/external/providers",
  })
}

export const updateExternalOfferProvider = async(provider: IExternalOfferProvider): Promise<IExternalOfferProvider> => {
  const { type, name, priority, active, geoRegions, params } = provider
  const input: IExternalOfferProviderInput = { type, name, priority, active, geoRegions, params }

  return apiCall({
    method: "PATCH",
    path: `offerwall/external/providers/${provider.id}`,
    body: input,
  })
}
