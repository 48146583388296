import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, transaction, makeObservable } from "mobx"
import { EntityToUpdate } from "abstract/entity-to-update"
import { RootStore } from "stores/root.store"
import {
  deleteNotification,
  createNotification,
  getNotification,
  sendNotification,
  getNotifications,
} from "../api"
import { NotificationTypeCreation, NotificationsResponse } from "./types"

export class NotificationsStore extends PaginationStore<NotificationsResponse, string> {
  @observable
  private _notifications: NotificationsResponse[]
  @observable
  private _selectedElement: NotificationsResponse = {} as NotificationsResponse

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<NotificationsResponse[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getNotifications, this.filter, page, limit)

    transaction(() => {
      this._notifications = res.data
      this.total = res.total
    })

    return res.data
  }

  async createNotification(data: EntityToUpdate<NotificationTypeCreation>) {
    await this.defaultFunction(createNotification, data)
  }

  async deleteNotificationById(id: string) {
    await this.defaultFunction(deleteNotification, id)
  }

  async getNotificationById(id: string) {
    await this.defaultFunction(getNotification, id)
  }

  async sendNotificationById(id: string) {
    await this.defaultFunction(sendNotification, id)
  }

  @computed
  get notifications(): NotificationsResponse[] {
    return this._notifications
  }

  @computed
  get selectedElement(): NotificationsResponse {
    return this._selectedElement
  }

  set selectedElement(value: NotificationsResponse) {
    this._selectedElement = value
  }

  clearSelectedElement() {
    this._selectedElement = {} as NotificationsResponse
  }
}
