import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Modal, Table } from "antd"

import { useStore } from "stores/utils/use-store"
import { RewardType } from "domains/promocodes/store/types"
import { RewardEdit } from "domains/promocodes/components/Rewards"
import { EditIconButton, CreateNewElement } from "components"
import { promocodesGroupsColumns } from "./columns"
import { TablePaginationConfig } from "antd/lib/table/interface"

import Styles from "./styles.module.css"

export const RewardsList: FC = observer(() => {
  const { dataStore: { promocodesRewardsStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  useEffect(() => {
    promocodesRewardsStore.getData()
  }, [promocodesRewardsStore])

  const onClose = () => {
    promocodesRewardsStore.clearSelectedElement()
    setEditCreateVisibility(null)
  }

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      promocodesRewardsStore.pagination = {
        page,
        limit: pageSize || promocodesRewardsStore.pagination.limit,
      }
    },
    total: promocodesRewardsStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: promocodesRewardsStore.pagination.page,
  }

  const columns = [
    ...promocodesGroupsColumns,
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: RewardType) => (
        <div className={Styles.actions}>
          <EditIconButton
            onClick={e => {
              e.stopPropagation()
              setEditCreateVisibility("edit")
              promocodesRewardsStore.selectedElement = row
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="reward" />
      <Table
        dataSource={promocodesRewardsStore?.rewards ? [...promocodesRewardsStore.rewards] : []}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        onChange={(p, c, sorter: any) => {
          if (sorter?.columnKey) {
            promocodesRewardsStore.getData({
              [sorter?.columnKey]:
                sorter.order === "ascend" ? "ASC" : sorter.order === "descend" ? "DESC" : null,
            })
          }
        }}
        onRow={(record: RewardType) => ({
          onClick: () => {
            promocodesRewardsStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit reward" : "Create reward"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <RewardEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
