import { Tabs } from "antd"
import * as React from "react"
import { BannersList } from "./banners-list"

export const BannersSettings: React.FunctionComponent = () => {
  return (
    <Tabs destroyInactiveTabPane>
      <Tabs.TabPane tab="Desktop" key="1">
        <BannersList platform="pc" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Mobile" key="2">
        <BannersList platform="mobile" />
      </Tabs.TabPane>
    </Tabs>
  )
}
