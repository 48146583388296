import { Modal, Table } from "antd"

import { gamesColumns } from "domains/games/components/GamesList/columns"

import { Game } from "domains/games/store/types"
import { observer } from "mobx-react"
import React, { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useStore } from "stores/utils/use-store"
import { Column } from "abstract/types/column.type"
import { EditIconButton } from "components/buttons/edit-icon-button"
import { GamePriorityEdit } from "../GamePriorityEdit"
import { CreateNewElement, DeleteIconButton } from "components"
import { GameDelete } from "../GameDelete"
import { GameCreation } from "../GameCreation"

export const GamesList: FC = observer(() => {
  const navigate = useNavigate()
  const {
    dataStore: {
      gamesStore,
      gamesStore: { games },
    },
  } = useStore()

  const [isEditPriorityModalOpen, setIsEditPriorityModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState<boolean>(false)

  useEffect(() => {
    gamesStore.getData()
  }, [gamesStore])

  const handleRowClick = (value: Game): any => {
    const { id } = value
    gamesStore.setGame(value)
    navigate(`/games/${id}`)
  }

  const onClose = () => {
    setIsEditPriorityModalOpen(false)
    setDeleteModalOpen(false)
    setIsCreatingModalOpen(false)
  }

  const columns: Column[] = [
    ...gamesColumns,
    {
      title: "Edit priority",
      dataIndex: "priority",
      key: "priority_edit",
      render: (_: any, row: Game) => (
        <EditIconButton
          onClick={e => {
            e.stopPropagation()
            gamesStore.setGame(row)
            setIsEditPriorityModalOpen(true)
          }}
        />
      ),
    },
    {
      title: "Delete game",
      dataIndex: "priority",
      key: "priority_edit",
      render: (_: any, row: Game) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            gamesStore.setGame(row)
            setDeleteModalOpen(true)
          }}
        />
      ),
    },
  ]

  return (
    <>
      <CreateNewElement onClick={() => setIsCreatingModalOpen(true)} valueText="Add game" />
      <Table
        dataSource={games}
        columns={columns as any}
        rowKey="id"
        onRow={record => ({
          onClick: () => {
            handleRowClick(record)
          },
        })}
      />
      {isCreatingModalOpen && (
        <Modal
          visible
          title="Create game"
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <GameCreation onClose={onClose} />
        </Modal>
      )}
      {isEditPriorityModalOpen && (
        <Modal
          visible
          title="Edit priority"
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <GamePriorityEdit onClose={onClose} />
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal
          visible
          title="Delete game"
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <GameDelete onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
