import React, { FC, useState } from "react"
import { DatePicker, Form, Input, Radio, Select, Switch } from "antd"
import { DefaultLinksRouting, PixelsEditorV2, UploadComponent } from "components"

import { observer } from "mobx-react"
import moment from "moment"
import { useStore } from "stores/utils/use-store"
import { notifications } from "notifications"
import { gameNames } from "abstract/constants/game-ids"
import {
  InGameBannerType,
  NameItem,
  Pixel,
  userTypes,
  windowTypesType,
} from "domains/managingJson/store/types"
import { SubmitCancelButtons } from "components"
import GeoPickerElement from "components/geoPicker"

export const BannerEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      managingJsonStore,
      managingJsonStore: { selectedElement },
    },
  } = useStore()

  const data = selectedElement.data as InGameBannerType
  const name = selectedElement.name

  const [image, setImage] = useState(editType === "edit" ? data?.image || "" : "")
  const [link] = useState(editType === "edit" ? data?.link || undefined : undefined)
  const [userType, setuserType] = useState(editType === "edit" ? data?.userType || "ALL" : "ALL")
  const [windows, setwindows] = useState(editType === "edit" ? data?.windows || [] : [])
  const [trackingPixels, setTrackingPixels] = useState<Pixel[] | undefined>(data?.pixels)

  const onFinishHandler = async(e: InGameBannerType) => {
    if (image?.length === 0) {
      return notifications.error("Please select banner image")
    }

    const data = {
      ...e,
      image,
      windows: windows?.length > 0 ? windows : undefined,
      userType,
      countries:
          e?.countries && e?.countries?.length > 0 ? e?.countries : undefined,
      gameIds: e?.gameIds && e?.gameIds?.length > 0 ? String(e?.gameIds).split(",") : undefined,
      startDate: e?.startDate ? e?.startDate : undefined,
      endDate: e?.endDate ? e?.endDate : undefined,
      pixels: trackingPixels ?? [],
    }

    if (e.name !== name && editType === "edit") {
      const dataToBack: NameItem = {
        oldName: name || "",
        newName: e.name,
        type: "inGameBanners",
      }
      await managingJsonStore.updateName(dataToBack)
    }

    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      await managingJsonStore.getData()
    }
    editType === "edit"
      ? await managingJsonStore.update({
        name: e.name || "",
        type: "inGameBanners",
        data,
      })
      : await managingJsonStore.create({
        name: e.name || "",
        type: "inGameBanners",
        data,
      })
  }
  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  const changeSwitcherWindow = (windowName: windowTypesType) => {
    if (windows?.includes(windowName)) {
      setwindows(windows.filter(item => item !== windowName))
    } else {
      const newWindows = windows && [...windows]
      newWindows?.push(windowName)
      setwindows(newWindows)
    }
  }

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} id="createBanner">
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input />
      </Form.Item>
      <GeoPickerElement label="Countries" name="countries" initialValue={data?.countries || []} />
      <Form.Item label="Game Ids" name="gameIds" initialValue={data?.gameIds || undefined}>
        <Select mode="multiple">
          {Object.entries(gameNames).map(el => (
            <Select.Option key={el[0]} value={el[0]}>{el[1]}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        required
        label="User Type"
        name="userType"
        initialValue={data?.userType || "ALL"}
      >
        <Radio.Group
          value={userType}
          defaultValue={undefined}
          onChange={e => {
            setuserType(e.target.value)
          }}
        >
          {userTypes.map(item => (
            <Radio key={item} value={item}>{item}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <DefaultLinksRouting link={link || ""} formItemName="link" notImportant />

      <Form.Item
        label="Startgame"
        style={{ marginBottom: "14px" }}
        initialValue={windows?.includes("START_GAME")}
      >
        <Switch
          checked={windows?.includes("START_GAME")}
          onChange={() => changeSwitcherWindow("START_GAME")}
        />
      </Form.Item>
      <Form.Item
        label="Endgame"
        style={{ marginBottom: "14px" }}
        initialValue={windows?.includes("END_GAME")}
      >
        <Switch
          checked={windows?.includes("END_GAME")}
          onChange={() => changeSwitcherWindow("END_GAME")}
        />
      </Form.Item>

      <Form.Item
        label="Launch game"
        style={{ marginBottom: "14px" }}
        initialValue={windows?.includes("LAUNCH_GAME")}
      >
        <Switch
          checked={windows?.includes("LAUNCH_GAME")}
          onChange={() => changeSwitcherWindow("LAUNCH_GAME")}
        />
      </Form.Item>
      <Form.Item
        label="Game summary"
        style={{ marginBottom: "14px" }}
        initialValue={windows?.includes("GAME_SUMMURY")}
      >
        <Switch
          checked={windows?.includes("GAME_SUMMURY")}
          onChange={() => changeSwitcherWindow("GAME_SUMMURY")}
        />
      </Form.Item>

      <Form.Item
        label="Second monitor"
        style={{ marginBottom: "14px" }}
        initialValue={windows?.includes("SECOND_MONITOR")}
      >
        <Switch
          checked={windows?.includes("SECOND_MONITOR")}
          onChange={() => changeSwitcherWindow("SECOND_MONITOR")}
        />
      </Form.Item>
      <div style={{ display: "flex", gap: "20px" }}>
        <Form.Item
          label="Start Date"
          name="startDate"
          initialValue={data?.startDate ? moment(data?.startDate as Date) : ""}
          style={{ width: "100%" }}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="Select Time"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="End Date"
          name="endDate"
          initialValue={data?.endDate ? moment(data?.endDate as Date) : ""}
          style={{ width: "100%" }}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="Select Time"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div>
      <UploadComponent
        image={image}
        setImage={setImage}
        uploadStyle={{ className: "avatar-uploader", listType: "picture" }}
        imgStyle={{ width: "100%", textAlign: "center" }}
        placeholder="Upload item image"
        placeHolderStyle={{ display: "flex", flexDirection: "row" }}
      />
      <h2 style={{ marginTop: "16px" }}>Tracking pixels</h2>
      <PixelsEditorV2
        pixels={trackingPixels ?? []}
        onChange={values => setTrackingPixels(values)}
      />
      <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
    </Form>
  )
})
