import React, { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Form, Input, InputNumber, Select, Switch, Checkbox, Radio } from "antd"
import { observer } from "mobx-react"

import { Category } from "domains/marketplace/store/types"

import Styles from "./styles.module.css"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { SubmitCancelButtons, UploadComponent } from "components"
import { gameNames } from "abstract/constants/game-ids"
import { PersonalBoosterType } from "domains/emailsCTA/store/types"
import { notifications } from "notifications"

const { Option } = Select

export const MarketplaceEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      internalMarketplaceStore: {
        selectedElement: {
          price,
          howToRedeem,
          description,
          device,
          type,
          region,
          title,
          imageUrl,
          premiumPrice,
          category,
          product,
          active,
        },
      },
      internalMarketplaceStore,
    },
  } = useStore()

  const [image, setImage] = useState(imageUrl)
  const [url, seturl] = useState((product?.data as any)?.url || "")
  const [devices, setDevices] = useState<any[]>(device || [])
  const [isEmptydevices, setisEmptyDevices] = useState<boolean>(false)
  const [itemType, setItemType] = useState<"REGULAR" | "PREMIUM" | "BUFF_PAY">(type)
  const [itemCategory, setitemCategory] = useState<Category>(category?.code)

  useEffect(() => {
    device && device.length === 3 && setDevices((device as any).concat("all"))
  }, [device])

  const onFinishHandler = async(event: any) => {
    const e: any = {} as any

    e.imageUrl = image || event.imageUrl
    e.price = event.price ? event.price : 0
    e.premiumPrice = event.premiumPrice && event.premiumPrice
    e.description = event.description
    e.title = event.title
    e.type = itemType

    e.howToRedeem = event.howToRedeem
    e.region = event.region
    e.category = event.category
    e.device = devices.filter(i => i !== "all")
    e.productPayload = {}
    if (itemCategory === "PROFILE_IMAGE" || itemCategory === "BACKGROUND") {
      if (url && url.length !== 0) {
        e.productPayload.url = url
      } else {
        notifications.error("Please select image")
      }
    } else if (itemCategory === "BOOSTER") {
      e.productPayload.percentage = +event.percentage
      e.productPayload.duration = +event.duration
      e.productPayload.period = event.period
      e.productPayload.gameIds = event.gameIds.map(item => +item)
      e.productPayload.personalBoosterType = event.personalBoosterType
    } else if (itemCategory === "PREMIUM") {
      e.productPayload.premiumType = event.premiumType
      e.productPayload.period = event.period
      e.productPayload.duration = +event.duration
    } else if (itemCategory === "GOLDEN_SPIN") {
      e.productPayload.count = +event.count
    }
    if (!e.imageUrl) {
      notifications.error("Please select image")

      return
    }
    if (devices.length === 0) {
      setisEmptyDevices(true)

      return
    }
    if (editType === "edit") {
      e.active = event.active
    }
    internalMarketplaceStore.funcAfterSuccess = async() => {
      onClose()
      await internalMarketplaceStore.getData()
    }

    editType === "edit"
      ? await internalMarketplaceStore.update(e)
      : internalMarketplaceStore.create(e)
  }

  const setPlatform = (e: CheckboxChangeEvent) => {
    setisEmptyDevices(false)
    if (e.target.value === "all") {
      if (e.target.checked) {
        setDevices(["all", "DESKTOP", "ANDROID", "IOS"])
      } else {
        setDevices([])
      }
    } else {
      if (e.target.checked) {
        let newDev = devices.concat(e.target.value)
        if (newDev.length === 3) {
          newDev = newDev.concat("all")
        }
        setDevices(newDev)
      } else {
        const newDev = devices.filter(item => item !== "all" && item !== e.target.value)
        setDevices(newDev)
      }
    }
  }

  return (
    <Form onFinish={onFinishHandler}>
      <Form.Item
        label="Title"
        name="title"
        initialValue={title}
        rules={[
          {
            required: true,
            message: "Please input item title!",
          },
        ]}
        style={{ width: "100%" }}
        className={Styles.flex}
      >
        <Input style={{ width: "100%" }} maxLength={90} />
      </Form.Item>
      <Form.Item
        label="Platform"
        className={Styles.platforms}
        initialValue={devices}
        valuePropName="checked"
        required
      >
        <Checkbox value="all" onChange={setPlatform} checked={devices.includes("all")}>
          All
        </Checkbox>
        <Checkbox onChange={setPlatform} value="DESKTOP" checked={devices.includes("DESKTOP")}>
          Desktop
        </Checkbox>
        <Checkbox onChange={setPlatform} value="ANDROID" checked={devices.includes("ANDROID")}>
          Android
        </Checkbox>
        <Checkbox onChange={setPlatform} value="IOS" checked={devices.includes("IOS")}>
          IOS
        </Checkbox>
      </Form.Item>
      {isEmptydevices && (
        <div
          style={{
            color: "red",
            position: "absolute",
            transform: "translate(0px, -15px)",
          }}
        >
          Choose min one platform
        </div>
      )}
      <Form.Item
        label="Item type"
        name="type"
        rules={[
          {
            required: true,
            message: "Select item type",
          },
        ]}
        className={Styles.platforms}
        initialValue={itemType}
      >
        <Radio.Group
          onChange={e => {
            setItemType(e.target.value)
          }}
          value={type}
        >
          <Radio value="REGULAR" defaultChecked>
            Regular
          </Radio>
          <Radio value="PREMIUM">Premium Only</Radio>
          <Radio value="BUFF_PAY">BuffPay Only</Radio>
        </Radio.Group>
      </Form.Item>
      <div className={Styles.grid}>
        {itemType !== "PREMIUM" && (
          <Form.Item
            rules={[
              {
                required: true,
                type: "number",
                min: 1,
                message: "Input price value",
              },
            ]}
            label="Price"
            name="price"
            className={Styles.subgrid}
            initialValue={price || 0}
          >
            <InputNumber min={1} max={100000} style={{ width: "100%" }} />
          </Form.Item>
        )}
        {itemType !== "BUFF_PAY" && (
          <Form.Item
            label="Premium Price"
            name="premiumPrice"
            initialValue={premiumPrice || 0}
            rules={[
              {
                required: true,
                type: "number",
                min: 1,
                message: "Input price value",
              },
            ]}
          >
            <InputNumber min={1} max={100000} style={{ width: "100%" }} />
          </Form.Item>
        )}
      </div>

      <Form.Item
        label="Category"
        name="category"
        initialValue={editType === "edit" ? category?.code : ""}
        rules={[
          {
            required: true,
            message: "Select category type",
          },
        ]}
        className={Styles.flex}
      >
        <Select
          disabled={editType === "edit"}
          placeholder="Choose a category"
          style={{ width: "100%" }}
          onChange={e => {
            if (editType === "create") {
              setitemCategory(e)
            }
          }}
          value={itemCategory}
        >
          {internalMarketplaceStore?.internalCategories?.categories?.map(el => {
            return (
              <Option key={el} value={el}>
                {el}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      {itemCategory === "BOOSTER" ? (
        <div>
          <Form.Item
            label="Percentage"
            name="percentage"
            rules={[
              {
                required: true,
                message: "Please input percentage!",
              },
            ]}
            initialValue={(product?.data as any)?.percentage}
          >
            <Input type="number" min={1} />
          </Form.Item>

          <Form.Item
            label="Period"
            name="period"
            rules={[
              {
                required: true,
                message: "Please input period!",
              },
            ]}
            initialValue={(product?.data as any)?.period}
          >
            <Select>
              {["HOURS", "DAYS"]?.map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Duration"
            name="duration"
            initialValue={(product?.data as any)?.duration}
            rules={[
              {
                required: true,
                message: "Please input duration!",
              },
            ]}
          >
            <Input type="number" min={1} max={750} />
          </Form.Item>
          <Form.Item
            label="Select Game Ids"
            name="gameIds"
            initialValue={(product?.data as any)?.gameIds?.map(item => String(item))}
            rules={[
              {
                required: true,
                message: "Please select games!",
              },
            ]}
          >
            <Select mode="multiple">
              {Object.entries(gameNames).map(el => (
                <Select.Option key={el[0]} value={el[0]}>{el[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Personal Booster Type"
            name="personalBoosterType"
            initialValue={(product?.data as any)?.personalBoosterType}
            rules={[
              {
                required: true,
                message: "Please input Personal Booster Type!",
              },
            ]}
          >
            <Select>
              {Object.entries(PersonalBoosterType).map(el => (
                <Select.Option key={el[0]} value={el[0]}>{el[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ) : itemCategory === "PROFILE_IMAGE" || itemCategory === "BACKGROUND" ? (
        <UploadComponent
          image={url}
          setImage={seturl}
          uploadStyle={{ className: "avatar-uploader", listType: "picture" }}
          placeholder="Upload item image"
        />
      ) : itemCategory === "PREMIUM" ? (
        <div>
          <Form.Item
            label="Premium Type"
            name="premiumType"
            initialValue={(product?.data as any)?.premiumType}
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Select>
              {["PREMIUM", "PREMIUM_PLUS", "PREMIUM_ELITE"]?.map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Duration"
            name="duration"
            initialValue={(product?.data as any)?.duration}
            rules={[
              {
                required: true,
                message: "Please input duration!",
              },
            ]}
          >
            <Input type="number" min={1} max={750} />
          </Form.Item>
          <Form.Item
            label="Period"
            name="period"
            initialValue={(product?.data as any)?.period}
            rules={[
              {
                required: true,
                message: "Please select period!",
              },
            ]}
          >
            <Select>
              {["HOURS", "DAYS"]?.map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ) : (
        itemCategory === "GOLDEN_SPIN" && (
          <Form.Item
            label="Count"
            name="count"
            initialValue={(product?.data as any)?.count}
            rules={[
              {
                required: true,
                message: "Please input count!",
              },
            ]}
          >
            <Input type="number" min={1} />
          </Form.Item>
        )
      )}
      <Form.Item
        label="Region"
        name="region"
        className={Styles.flex}
        style={{ width: "100%" }}
        initialValue={region?.tag}
        rules={[
          {
            required: true,
            message: "Select region",
          },
        ]}
      >
        <Select placeholder="Please select" style={{ width: "100%" }}>
          {internalMarketplaceStore?.internalCategories?.regions?.map(region => {
            return (
              <Option key={region} value={region}>
                {region}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        initialValue={description}
        className={Styles.flexTextarea}
        rules={[
          {
            required: true,
            message: "Input description!",
          },
        ]}
      >
        <Input.TextArea
          className={Styles.textarea}
          showCount
          autoSize={{ minRows: 2 }}
          maxLength={250}
        />
      </Form.Item>

      <Form.Item
        label="How to redeem"
        name="howToRedeem"
        initialValue={howToRedeem}
        rules={[
          {
            required: true,
            message: "Input how To Redeem!",
          },
        ]}
        className={Styles.flexTextarea}
      >
        <Input.TextArea
          className={Styles.textarea}
          autoSize={{ minRows: 2 }}
          showCount
          maxLength={500}
        />
      </Form.Item>

      <div className={Styles.flex}>
        <UploadComponent
          image={image}
          setImage={setImage}
          uploadStyle={{ className: "avatar-uploader", listType: "picture" }}
          placeholder="Upload item image"
        />
      </div>
      {editType === "edit" && (
        <Form.Item initialValue={active} label="Is Active" name="active" valuePropName="checked">
          <Switch defaultChecked={true} />
        </Form.Item>
      )}
      <SubmitCancelButtons onClose={onClose} isLoading={internalMarketplaceStore.isLoading} />
    </Form>
  )
})
