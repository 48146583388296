import {
  action,
  computed,
  observable,
  reaction,
  toJS,
  transaction,
  makeObservable,
  IReactionDisposer,
} from "mobx"

import { getAllGameAccounts } from "domains/game-accounts/api/game-accounts.call"
import { BaseStore } from "stores/base.store"
import { RootStore } from "stores/root.store"

export type GameAccDataType = {
  nickname: string;
  email: string;
  balance: number;
  isClosed: boolean;
  isFrozen: boolean;
};
export class GameAccount {
  constructor(
    public id: string,
    public inGameId: string,
    public buffAccountId: string,
    public gameId: string,
    public account: GameAccDataType,
  ) {}
}

export class GameAccountStore extends BaseStore {
  @observable
  public isLoading: boolean = false

  @observable
  public pagination: { page: number; limit: number } = {
      page: 1,
      limit: 10,
    }

  @observable
  public filter: string = ""
  @observable
  public total: number = 0

  @observable
  private _accounts: GameAccount[] = []

  private readonly _activateReaction = (): IReactionDisposer => reaction(
    () => {
      return JSON.stringify(toJS(this.pagination)) + this.filter
    },
    () => {
      this.getAllGameAccounts()
    },
  )

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)

    this._activateReaction()
  }

  public async getAllGameAccounts(): Promise<GameAccount[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getAllGameAccounts, this.filter, page, limit)

    transaction(() => {
      this._accounts = res.gamingAccounts
      this.total = res.count
    })

    return res.gamingAccounts
  }

  @action
  nextPage() {
    this.pagination.page += 1
  }

  @action
    setFilter = (filter: string) => {
      this.filter = filter
      this.pagination.page = 1
    }

  @computed
  get accounts(): GameAccount[] {
    return this._accounts
  }
}
