import { computed, observable, transaction, makeObservable } from "mobx"
import { EntityToUpdate } from "abstract/entity-to-update"
import { createUtmTier, deleteUtmTier, getUtmTiers, updateUtmTier } from "../api"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"

export class UtmTier {
  constructor(
    public id: string,
    public name: string,
    public countries: string[] | null,
    public payoutAmount: number,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}

export class UtmTiersStore extends PaginationStore<UtmTier> {
  @observable
  private _utmTiers: UtmTier[]
  @observable
  private _selectedElement: UtmTier = {} as UtmTier

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<UtmTier[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getUtmTiers, this.filter, page, limit)
    transaction(() => {
      this._utmTiers = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: EntityToUpdate<UtmTier>) {
    await this.defaultFunction(updateUtmTier, this._selectedElement.id, data)
  }

  async create(data: EntityToUpdate<UtmTier>) {
    await this.defaultFunction(createUtmTier, data)
  }

  async delete(id: string) {
    await this.defaultFunction(deleteUtmTier, id)
  }

  @computed
  get utmTiers(): UtmTier[] {
    return this._utmTiers
  }

  @computed
  get selectedElement(): UtmTier {
    return this._selectedElement
  }

  set selectedElement(value: UtmTier) {
    this._selectedElement = value
  }
  clearSelectedElement() {
    this._selectedElement = {} as UtmTier
  }
}
