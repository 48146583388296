import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor"
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Switch,
  Typography,
} from "antd"
import { DefaultLinksRouting, UploadComponent } from "components"
import {
  StyledTemplate,
  StyledTemplateInterface,
} from "domains/popup-templates/store/StyledTemplate.store"
import { usePopupTemplatesContext } from "domains/popup-templates/store/context"
import { runInAction } from "mobx"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"
import "@mdxeditor/editor/style.css"
import { ClaimableRewardPicker } from "domains/popup-templates/components/ClaimableRewardPicker"
import { PopupReward } from "domains/popup-templates/store/rewards.types"
import {
  ButtonAction,
  ButtonActionTypes,
  buttonPrimaryActions,
  buttonSecondaryActions,
  getButtonActionTypeLabel,
} from "domains/popup-templates/store/buttonAction.types"
import { PixelsEditorV2 } from "components/PixelsEditorV2"
import { Pixel } from "domains/managingJson/store/types"
import { ConsentRow, UserConsentEditor } from "components/userConsentEditor"
import { useStore } from "stores/utils/use-store"
import { usePopupActivation } from "domains/popup-templates/hooks/usePopupActivation"

const { Text } = Typography

const AutoCloseWarning = () => (
  <Text type="warning">If secondary action is routing - popup will autoclose</Text>
)

type Props = {
  onClose: () => void;
  editedTemplate?: StyledTemplate;
};

type FormStateType = {
  name: string;
  id: number;
  fullWidthImage: boolean;
  richText: string;
  width: number;
  heading: string;
  withCloseButton: boolean;
  autoShowCloseButton: number;
  withDivider: boolean;
  renderButtonsInsideImage: boolean;
  headerInsideImage: boolean;
  reward: PopupReward;

  primaryButtonLabel?: string;
  primaryButtonPrimaryAction?: ButtonActionTypes;
  primaryButtonPrimaryRoutingLink?: string;
  primaryButtonprimaryContestName?: string;
  primaryButtonSecondaryRoutingLink?: string;
  primaryButtonSecondaryAction?: ButtonActionTypes;

  secondaryButtonLabel?: string;
  secondaryButtonPrimaryAction?: ButtonActionTypes;
  secondaryButtonPrimaryRoutingLink?: string;
  secondaryButtonSecondaryRoutingLink?: string;
  secondaryButtonSecondaryAction?: ButtonActionTypes;

  enabled: boolean;
};

export const StyledPopupEditModal = observer(({ editedTemplate, onClose }: Props) => {
  const { dataStore: { managingJsonStore } } = useStore()
  const { createTemplate } = usePopupTemplatesContext()
  const [form] = Form.useForm<FormStateType>()
  const [image, setImage] = useState<string>("")
  const [imageError, setImageError] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState(false)
  const { isActivated, changeActivationStatus } = usePopupActivation(editedTemplate?.enabled)

  const [primaryBtnPrimaryAction, setPrimaryBtnPrimaryAction] = useState<
    ButtonActionTypes | undefined
  >(editedTemplate?.primaryButton?.primaryAction.type)

  const [primaryBtnSecondaryAction, setPrimaryBtnSecondaryAction] = useState<
    ButtonActionTypes | undefined
  >(editedTemplate?.primaryButton?.secondaryAction?.type)

  const [secondaryBtnPrimaryAction, setSecondaryBtnPrimaryAction] = useState<
    ButtonActionTypes | undefined
  >(editedTemplate?.secondaryButton?.primaryAction.type)

  const [secondaryBtnSecondaryAction, setSecondaryBtnSecondaryAction] = useState<
    ButtonActionTypes | undefined
  >(editedTemplate?.secondaryButton?.secondaryAction?.type)

  const [trackingPixels, setTrackingPixels] = useState<Pixel[] | undefined>(
    editedTemplate?.trackingPixels,
  )
  const [consentRows, setConsentRows] = useState<ConsentRow[] | undefined>(
    editedTemplate?.consentRows,
  )

  useEffect(() => {
    const getData = async() => {
      if (!managingJsonStore?.itemTypes || managingJsonStore?.itemTypes?.length === 0) {
        await managingJsonStore.getData()
      }
    }
    getData()
  }, [managingJsonStore?.itemTypes])

  useEffect(() => {
    if (image) setImageError(false)
  }, [image])

  useEffect(() => {
    if (!editedTemplate) return
    form.setFieldsValue({
      name: editedTemplate.name,
      enabled: editedTemplate.enabled,
      id: editedTemplate.id,
      fullWidthImage: editedTemplate.fullWidthImage,
      richText: editedTemplate.richText,
      width: editedTemplate.width,
      heading: editedTemplate.heading,
      withCloseButton: editedTemplate.withCloseButton,
      autoShowCloseButton: editedTemplate.autoShowCloseButton,
      withDivider: editedTemplate.withDivider,
      renderButtonsInsideImage: editedTemplate.renderButtonsInsideImage,
      headerInsideImage: editedTemplate.headerInsideImage,
      reward: {
        rewardType: editedTemplate.reward?.rewardType,
        amount: editedTemplate.reward?.amount,
        duration: editedTemplate.reward?.duration,
        period: editedTemplate.reward?.period,
        premiumType: editedTemplate.reward?.premiumType,
        count: editedTemplate.reward?.count,
        boosterType: editedTemplate.reward?.boosterType,
        percentage: editedTemplate.reward?.percentage,
      } as PopupReward,
      primaryButtonLabel: editedTemplate.primaryButton?.text,
      primaryButtonPrimaryAction: editedTemplate.primaryButton?.primaryAction.type,
      primaryButtonPrimaryRoutingLink: editedTemplate.primaryButton?.primaryAction.routingLink,
      primaryButtonprimaryContestName: editedTemplate.primaryButton?.primaryAction.contestName,
      primaryButtonSecondaryAction: editedTemplate.primaryButton?.secondaryAction?.type,
      primaryButtonSecondaryRoutingLink: editedTemplate.primaryButton?.secondaryAction?.routingLink,
      secondaryButtonLabel: editedTemplate.secondaryButton?.text,
      secondaryButtonPrimaryAction: editedTemplate.secondaryButton?.primaryAction.type,
      secondaryButtonPrimaryRoutingLink: editedTemplate.secondaryButton?.primaryAction.routingLink,
      secondaryButtonSecondaryAction: editedTemplate.secondaryButton?.secondaryAction?.type,
      secondaryButtonSecondaryRoutingLink:
        editedTemplate.secondaryButton?.secondaryAction?.routingLink,
    })
    setImage(editedTemplate.img)
  }, [form, editedTemplate])

  const onFinishHandler = async() => {
    if (!image) return setImageError(true)
    setIsSaving(true)

    const getPrimaryButtonPrimaryAction = () => {
      const primaryButtonPrimaryAction: ButtonAction = {
        type: data.primaryButtonPrimaryAction,
        routingLink: data.primaryButtonPrimaryRoutingLink,
        contestName: data.primaryButtonprimaryContestName,
      } as ButtonAction

      return primaryButtonPrimaryAction
    }

    const getPrimaryButtonSecondaryAction = () => {
      const primaryButtonSecondaryAction: ButtonAction = {
        type: data.primaryButtonSecondaryAction,
        routingLink: data.primaryButtonSecondaryRoutingLink,
      } as ButtonAction

      return primaryButtonSecondaryAction
    }

    const getSecondaryButtonPrimaryAction = () => {
      const secondaryButtonPrimaryAction: ButtonAction = {
        type: data.secondaryButtonPrimaryAction,
        routingLink: data.secondaryButtonPrimaryRoutingLink,
      } as ButtonAction

      return secondaryButtonPrimaryAction
    }

    const getSecondaryButtonSecondaryAction = () => {
      const secondaryButtonSecondaryAction: ButtonAction = {
        type: data.secondaryButtonSecondaryAction,
        routingLink: data.secondaryButtonSecondaryRoutingLink,
      } as ButtonAction

      return secondaryButtonSecondaryAction
    }

    const data = {
      ...form.getFieldsValue(),
      img: image,
    }

    const template: StyledTemplateInterface = {
      fullWidthImage: data.fullWidthImage,
      heading: data.heading,
      id: data.id,
      img: data.img,
      link: "",
      name: data.name,
      enabled: isActivated,
      popupVersion: "styled",
      primaryButton: data.primaryButtonLabel
        ? {
          text: data.primaryButtonLabel,
          primaryAction: getPrimaryButtonPrimaryAction(),
          secondaryAction: getPrimaryButtonSecondaryAction(),
        }
        : undefined,
      secondaryButton: data.secondaryButtonLabel
        ? {
          text: data.secondaryButtonLabel,
          primaryAction: getSecondaryButtonPrimaryAction(),
          secondaryAction: getSecondaryButtonSecondaryAction(),
        }
        : undefined,
      richText: data.richText,
      reward: data.reward?.rewardType ? data.reward : undefined,
      width: data.width,
      withCloseButton: data.withCloseButton,
      autoShowCloseButton: data.autoShowCloseButton,
      withDivider: data.withDivider,
      renderButtonsInsideImage: data.renderButtonsInsideImage,
      headerInsideImage: data.headerInsideImage,
      trackingPixels: trackingPixels,
      consentRows: consentRows,
    }

    if (editedTemplate) {
      runInAction(() => {
        editedTemplate.img = data.img
        editedTemplate.name = data.name
        editedTemplate.fullWidthImage = data.fullWidthImage
        editedTemplate.richText = data.richText
        editedTemplate.width = data.width
        editedTemplate.heading = data.heading
        editedTemplate.withCloseButton = data.withCloseButton
        editedTemplate.autoShowCloseButton = data.autoShowCloseButton
        editedTemplate.withDivider = data.withDivider
        editedTemplate.renderButtonsInsideImage = data.renderButtonsInsideImage
        editedTemplate.headerInsideImage = data.headerInsideImage
        editedTemplate.reward = data.reward?.rewardType ? data.reward : undefined;
        (editedTemplate.primaryButton = data.primaryButtonLabel
          ? {
            text: data.primaryButtonLabel,
            primaryAction: getPrimaryButtonPrimaryAction(),
            secondaryAction: getPrimaryButtonSecondaryAction(),
          }
          : undefined),
        (editedTemplate.secondaryButton = data.secondaryButtonLabel
          ? {
            text: data.secondaryButtonLabel,
            primaryAction: getSecondaryButtonPrimaryAction(),
            secondaryAction: getSecondaryButtonSecondaryAction(),
          }
          : undefined)
        editedTemplate.trackingPixels = trackingPixels
        editedTemplate.consentRows = consentRows
        editedTemplate.enabled = isActivated
      })
      await editedTemplate.save()
      onClose()

      return
    }
    await createTemplate(template)
    setIsSaving(false)
    onClose()
  }

  const onPrimaryButtonPrimaryActionChange = (value: string) => {
    setPrimaryBtnPrimaryAction(value as ButtonActionTypes | undefined)
  }

  const onPrimaryButtonSecondaryActionChange = (value: string) => {
    setPrimaryBtnSecondaryAction(value as ButtonActionTypes | undefined)
  }

  const onSecondaryButtonPrimaryActionChange = (value: string) => {
    setSecondaryBtnPrimaryAction(value as ButtonActionTypes | undefined)
  }

  const onSecondaryButtonSecondaryActionChange = (value: string) => {
    setSecondaryBtnSecondaryAction(value as ButtonActionTypes | undefined)
  }

  const contests = managingJsonStore.itemTypes?.filter(el => el.type === "contests")

  const onEnabledChange = async(value: boolean) => {
    changeActivationStatus(value, editedTemplate?.id)
  }

  return (
    <Modal
      open
      title={editedTemplate ? "Edit template" : "Create template"}
      width="800px"
      footer={null}
      onCancel={onClose}
    >
      <Form onFinish={onFinishHandler} form={form}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter popup name",
            },
          ]}
        >
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item label="Enabled" name="_enabled">
          <Switch onChange={onEnabledChange} checked={isActivated} disabled={!editedTemplate} />
          {!editedTemplate && (
            <Text style={{ marginLeft: "1rem" }} type="danger">
              New templates are enabled by default - you can disable it after initial saving
            </Text>
          )}
        </Form.Item>
        <Form.Item label="Heading" name="heading" initialValue={editedTemplate?.heading ?? ""}>
          <Input maxLength={120} />
        </Form.Item>
        <div style={{ marginBottom: 20 }}>
          <UploadComponent
            title="Banner"
            image={image}
            setImage={setImage}
            maxSize={1}
            required
            requiredError={imageError}
          />
        </div>
        <Form.Item label="Popup width (pixels)" name="width" initialValue={editedTemplate?.width}>
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="With close button"
          name="withCloseButton"
          valuePropName="checked"
          initialValue={editedTemplate?.withCloseButton ?? false}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="Automatically show close button after this time (seconds)"
          name="autoShowCloseButton"
          initialValue={editedTemplate?.autoShowCloseButton}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Full Width Image"
          name="fullWidthImage"
          valuePropName="checked"
          initialValue={editedTemplate?.fullWidthImage ?? false}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="Description"
          name="richText"
          initialValue={editedTemplate?.richText ?? ""}
          valuePropName="markdown"
        >
          <MDXEditor
            markdown=""
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              linkDialogPlugin(),
              linkPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <BlockTypeSelect />
                    <BoldItalicUnderlineToggles />
                    <CreateLink />
                    <ListsToggle />
                    <UndoRedo />

                  </>
                ),
              }),
            ]}
          />
        </Form.Item>
        <Form.Item
          label="With divider"
          name="withDivider"
          valuePropName="checked"
          initialValue={editedTemplate?.withDivider ?? false}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="Render header (title & close button) inside the image instead of above it"
          name="headerInsideImage"
          valuePropName="checked"
          initialValue={editedTemplate?.headerInsideImage ?? false}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="Render buttons inside the image instead of below it"
          name="renderButtonsInsideImage"
          valuePropName="checked"
          initialValue={editedTemplate?.renderButtonsInsideImage ?? false}
        >
          <Checkbox />
        </Form.Item>

        <Divider />

        <h2>Primary Button</h2>
        <div>
          <Form.Item
            name="primaryButtonLabel"
            label="Text"
            initialValue={editedTemplate?.primaryButton?.text}
            rules={[
              {
                required: primaryBtnPrimaryAction !== undefined,
                message: "Please enter button text",
              },
            ]}
          >
            <Input placeholder="Claim prize" />
          </Form.Item>

          {/* PRIMARY BUTTON PRIMARY ACTION */}
          <Form.Item name="primaryButtonPrimaryAction" label="Primary Action">
            <Select placeholder="Action" onChange={onPrimaryButtonPrimaryActionChange}>
              <Select.Option value={[]}>None</Select.Option>
              {buttonPrimaryActions.map(action => (
                <Select.Option key={action} value={action}>
                  {getButtonActionTypeLabel(action)}
                </Select.Option>
              ))}
              {buttonSecondaryActions.map(action => (
                <Select.Option key={action} value={action}>
                  {getButtonActionTypeLabel(action)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div style={{ marginLeft: 100 }}>
            {primaryBtnPrimaryAction === ButtonActionTypes.CLAIM_REWARD && (
              <Form.Item name="reward">
                <ClaimableRewardPicker />
              </Form.Item>
            )}

            {primaryBtnPrimaryAction === ButtonActionTypes.ROUTING && (
              <DefaultLinksRouting
                link={editedTemplate?.primaryButton?.primaryAction?.routingLink || ""}
                formItemName="primaryButtonPrimaryRoutingLink"
              />
            )}

            {
              primaryBtnPrimaryAction === ButtonActionTypes.ONBOARD_TO_CONTEST && (
                <Form.Item name="primaryButtonprimaryContestName">
                  <Select>
                    {contests?.map(item => (
                      <Select.Option
                        key={item.name}
                        value={item.name}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }
          </div>

          {/* PRIMARY BUTTON SECONDARY ACTION */}
          {primaryBtnPrimaryAction && (
            <>
              <Form.Item name="primaryButtonSecondaryAction" label="Secondary Action">
                <Select placeholder="Action" onChange={onPrimaryButtonSecondaryActionChange}>
                  <Select.Option value={[]}>None</Select.Option>
                  {buttonSecondaryActions.map(action => (
                    <Select.Option key={action} value={action}>
                      {getButtonActionTypeLabel(action)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div style={{ marginLeft: 100 }}>
                {primaryBtnSecondaryAction === ButtonActionTypes.ROUTING && (
                  <>
                    <DefaultLinksRouting
                      link={editedTemplate?.primaryButton?.secondaryAction?.routingLink || ""}
                      formItemName="primaryButtonSecondaryRoutingLink"
                    />
                    <AutoCloseWarning />
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <h2>Secondary Button</h2>
        <div>
          <Form.Item
            name="secondaryButtonLabel"
            label="Text"
            required={secondaryBtnPrimaryAction !== undefined}
            rules={[
              {
                required: secondaryBtnPrimaryAction !== undefined,
                message: "Please enter button text",
              },
            ]}
          >
            <Input placeholder="Claim prize" />
          </Form.Item>

          {/* SECONDARY BUTTON PRIMARY ACTION */}
          <Form.Item name="secondaryButtonPrimaryAction" label="Action">
            <Select placeholder="Action" onChange={onSecondaryButtonPrimaryActionChange}>
              <Select.Option value={[]}>None</Select.Option>
              {buttonSecondaryActions.map(action => (
                <Select.Option key={action} value={action}>
                  {getButtonActionTypeLabel(action)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {secondaryBtnPrimaryAction === ButtonActionTypes.ROUTING && (
            <div style={{ marginLeft: 100 }}>
              <DefaultLinksRouting
                link={editedTemplate?.secondaryButton?.primaryAction?.routingLink || ""}
                formItemName="secondaryButtonPrimaryRoutingLink"
              />
            </div>
          )}
        </div>

        {secondaryBtnPrimaryAction && (
          <>
            <Form.Item name="secondaryButtonSecondaryAction" label="Secondary Action">
              <Select placeholder="Action" onChange={onSecondaryButtonSecondaryActionChange}>
                <Select.Option value={[]}>None</Select.Option>
                {buttonSecondaryActions.map(action => (
                  <Select.Option key={action} value={action}>
                    {getButtonActionTypeLabel(action)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div style={{ marginLeft: 100 }}>
              {secondaryBtnSecondaryAction === ButtonActionTypes.ROUTING && (
                <>
                  <DefaultLinksRouting
                    link={editedTemplate?.secondaryButton?.secondaryAction?.routingLink || ""}
                    formItemName="secondaryButtonSecondaryRoutingLink"
                  />
                  <AutoCloseWarning />
                </>
              )}
            </div>
          </>
        )}

        <Divider />

        <h2>Tracking pixels</h2>
        <PixelsEditorV2
          pixels={trackingPixels ?? []}
          onChange={values => setTrackingPixels(values)}
        />

        <Divider />

        <h2>User consent</h2>
        <UserConsentEditor
          rows={consentRows ?? []}
          onChange={value => setConsentRows(value)}
          withCustomId
        />

        <Divider />

        <Space style={{ marginTop: "1rem" }}>
          <Button onClick={onClose} disabled={isSaving}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isSaving}>
            Save
          </Button>
        </Space>
      </Form>
    </Modal>
  )
})
