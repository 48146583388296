import { Button, Form, Switch } from "antd"
import { useForm } from "antd/lib/form/Form"
import Title from "antd/lib/typography/Title"
import { CommonDynamicUI } from "domains/managingJson/store/types"
import { notifications } from "notifications"
import { FC, useEffect, useMemo } from "react"
import { useStore } from "stores/utils/use-store"

const CommonDynamicUITab: FC = () => {
  const { dataStore: { managingJsonStore } } = useStore()
  const [form] = useForm()

  const initial = useMemo(() => {
    const dynamicUIData = managingJsonStore?.itemTypes?.filter(item => item.type === "dynamicUI")
    if (dynamicUIData && dynamicUIData.length > 0) {
      const data = dynamicUIData[0].data

      return data
    }

    return undefined
  }, [managingJsonStore])

  useEffect(() => {
    form.resetFields()
  }, [initial])

  const onFinishHandler = async(formData: CommonDynamicUI) => {
    const data = { ...initial, ...formData }
    managingJsonStore.funcAfterSuccess = async() => {
      notifications.success("Saved successfully")
      await managingJsonStore.getData()
    }

    initial
      ? await managingJsonStore.update({
        name: "dynamicUI",
        type: "dynamicUI",
        data,
      })
      : await managingJsonStore.create({
        name: "dynamicUI",
        type: "dynamicUI",
        data,
      })

  }

  return (
    <Form<CommonDynamicUI> onFinish={onFinishHandler} initialValues={initial} form={form} disabled={!initial}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderBottom: "1px solid #d9d9d9",
        }}
      >
        <Title level={4}>Under Maintenance</Title>
        <Form.Item name={["underMaintenance", "BUFF_DESKTOP"]} label="Buff Desktop" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name={["underMaintenance", "BUFF_MOBILE"]} label="Buff Mobile" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name={["underMaintenance", "BUFF_PLAY"]} label="Buff Play" valuePropName="checked">
          <Switch />
        </Form.Item>
      </div>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 16 }}>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              cursor: managingJsonStore.isLoading ? "not-allowed" : "auto",
              backgroundColor: managingJsonStore.isLoading ? "#d9d9d9" : "#1690ff",
              borderColor: managingJsonStore.isLoading ? "#d9d9d9" : "#1690ff",
            }}
            disabled={managingJsonStore.isLoading}
          >
            Save
          </Button>
        </Form.Item>
      </div>

    </Form>
  )
}

export default CommonDynamicUITab
