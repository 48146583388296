import React from "react"

import { Form, Input } from "antd"
import { observer } from "mobx-react"
import { FC } from "react"
import { useStore } from "stores/utils/use-store"
import { Game } from "../../store/types"
import { SubmitCancelButtons } from "components"

export const GamePriorityEdit: FC<{ onClose: () => void }> = observer(({ onClose }) => {
  const {
    dataStore: {
      gamesStore,
      gamesStore: { game: { id, priority } },
    },
  } = useStore()

  const onFinishHandler = async(e: Game) => {
    const priority = e.priority ? +e.priority : null

    await gamesStore.updateGamePriority({ gameId: id, priority })
    onClose()
    await gamesStore.getData()
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler}>
      <Form.Item label="Priority" name="priority" initialValue={priority} style={{ width: "100%" }}>
        <Input />
      </Form.Item>

      <SubmitCancelButtons onClose={onClose} isLoading={gamesStore.isLoading} />
    </Form>
  )
})
