import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  toJS,
  transaction,
} from "mobx"

import { getAccounts } from "domains/accounts/api/accounts.call"
import { BaseStore } from "stores/base.store"
import { RootStore } from "stores/root.store"

export class Account {
  constructor(
    public id: string,
    public nickname: string,
    public login: string,
    public email: string,
    public balance: number,
    public isConfirmedEmail: boolean,
    public isClosed: boolean,
    public isFrozen: boolean,
  ) {}
}

export class AccountStore extends BaseStore {
  @observable
  public isLoading: boolean = false

  @observable
  public pagination: { page: number; limit: number } = {
      page: 1,
      limit: 10,
    }

  @observable
  public filter: string = ""
  @observable
  public total: number = 0

  @observable
  private _accounts: Account[] = []

  private readonly _activateReaction = (): IReactionDisposer => reaction(
    () => {
      return JSON.stringify(toJS(this.pagination)) + this.filter
    },
    () => {
      this.getAccounts()
    },
  )

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)

    this._activateReaction()
  }

  public async getAccounts(): Promise<Account[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getAccounts, this.filter, page, limit)
    transaction(() => {
      this._accounts = res.data
      this.total = res.total
    })

    return res.data
  }

  @action
  nextPage() {
    this.pagination.page += 1
  }

  @action
    setFilter = (filter: string) => {
      this.filter = filter
      this.pagination.page = 1
    }

  @computed
  get accounts(): Account[] {
    return this._accounts
  }
}
