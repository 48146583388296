import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { EntityToUpdate } from "abstract/entity-to-update"
import { createBooster, deleteBooster, getBoosters, updateBooster } from "../api/api"

export class Booster {
  constructor(
    public id: string,
    public startDate: Date,
    public endDate: Date,
    public booster: number,
    public gameIds: number[],
    public createdAt: Date,
    public updatedAt: Date,
    public name: string,
    public date: [Date, Date],
  ) {}
}

export class BoostersStore extends PaginationStore<Booster, string> {
  @observable
  private _boosters: Booster[]
  @observable
  private _selectedElement: Booster = {} as Booster

  @computed
  get boosters(): Booster[] {
    return this._boosters
  }

  @computed
  get selectedElement(): Booster {
    return this._selectedElement
  }

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<Booster[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getBoosters, this.filter, page, limit)
    transaction(() => {
      this._boosters = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: EntityToUpdate<Booster>) {
    await this.defaultFunction(updateBooster, this._selectedElement.id, data)
  }

  async create(data: EntityToUpdate<Booster>) {
    await this.defaultFunction(createBooster, data)
  }

  async delete(id: string) {
    await this.defaultFunction(deleteBooster, id)
  }

  set selectedElement(value: Booster) {
    this._selectedElement = value
  }
  clearElement() {
    this._selectedElement = {} as Booster
  }
}
