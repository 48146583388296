import { apiCall } from "./api-call"

export const uploadImage = async(data: FormData): Promise<{
  url: string;
}> => {
  return apiCall({
    method: "POST",
    path: "files/img",
    formData: data,
  })
}
