import React, { FC } from "react"
import { Descriptions } from "antd"
import { observer } from "mobx-react"
import { NotificationsResponse } from "../store/types"

export const NotificationData: FC<{ data: NotificationsResponse }> = observer(({ data }) => {
  return (
    <Descriptions title="Notification Info" column={1}>
      <Descriptions.Item label="System title">{data.title}</Descriptions.Item>
      <Descriptions.Item label="System type">{data.type}</Descriptions.Item>
      <Descriptions.Item label="NeedToSend">{!!data.needToSend}</Descriptions.Item>
      <Descriptions.Item label="SendStatus">{data.sendStatus}</Descriptions.Item>
      {data.inAppNotification
        && Object.entries(data.inAppNotification)?.map(item => {
          if (item[0] === "iconUrl" || item[0] === "imageUrl") {
            return (
              <Descriptions.Item style={{ textTransform: "capitalize" }} label={String(item[0])} key={item[0]}>
                <img style={{ width: "50px", height: "50px" }} alt="" src={String(item[1])} />;
              </Descriptions.Item>
            )
          }
          if (item[0] === "inAppNotificationButton") {
            return (
              item[1] && (
                <>
                  <div style={{ fontWeight: "bold" }}> InAppNotificationButton</div>
                  {Object.entries(item[1]).map(item1 => {
                    if (item1[0] === "notificationRedirectType") {
                      return (
                        <>
                          <div style={{ fontWeight: "bold" }}> NotificationRedirectType</div>

                          {item1[1]
                            && Object.entries(item1[1]).map(item2 => {
                              return (
                                <Descriptions.Item
                                  style={{ textTransform: "capitalize" }}
                                  label={item2[0]}
                                  key={item2[0]}
                                >
                                  {String(item2[1])}
                                </Descriptions.Item>
                              )
                            })}
                        </>
                      )
                    }

                    return (
                      <Descriptions.Item style={{ textTransform: "capitalize" }} label={item1[0]} key={item1[0]}>
                        {String(item1[1])}
                      </Descriptions.Item>
                    )
                  })}
                </>
              )
            )
          }

          return (
            <Descriptions.Item style={{ textTransform: "capitalize" }} label={item[0]} key={item[0]}>
              {String(item[1])}
            </Descriptions.Item>
          )
        })}
    </Descriptions>
  )
})
