import { apiCall } from "api-calls/api-call"
import { ItemToUpdate, MarketplaceCategory } from "domains/marketplace/store/types"

export const getMarketplaceCategories = async(): Promise<MarketplaceCategory[]> => {
  return apiCall({
    method: "GET",
    path: "marketplace-categories",
  })
}

export const createMarketplaceCategory = async(input: ItemToUpdate): Promise<void> => {
  return apiCall({
    method: "POST",
    path: "marketplace-categories",
    body: input,
  })
}

export const updateMarketplaceCategory = async(id: string, input: ItemToUpdate): Promise<void> => {
  return apiCall({
    method: "PUT",
    path: `marketplace-categories/${id}`,
    body: input,
  })
}
export const deleteMarketplaceCategory = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `marketplace-categories/${id}`,
  })
}
