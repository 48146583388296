import React, { Dispatch, FC, SetStateAction, useState } from "react"

import { Button, Divider, Input, Typography } from "antd"
import { RulesSectionType } from "domains/managingJson/store/types"
import s from "../styles.module.css"
import { UploadComponent, MobileView, FormElement } from "components"

export const RulesSection: FC<{
  data?: RulesSectionType;
  setparagraps: Dispatch<SetStateAction<string | undefined>>;
  addRules: boolean;
  setaddRules: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ data, setparagraps, addRules, setaddRules }) => {
  const [description, setDescription] = useState("")
  const [listDescriptions, setListDescription] = useState<string[]>(data?.paragraps ? [data?.paragraps] : [])
  const [uploadText, setUploadText] = useState(data?.paragraps || "")
  const [isShowDevice, setIsShowDevice] = useState(false)
  const [infoVisibleDevice] = useState({
    title: "",
    image: "",
    buttonName: "",
  })

  const addParagraphOrItemList = (symbol: string) => {
    const concat = symbol.concat(description)
    listDescriptions.push(concat)

    setUploadText(listDescriptions.join(""))
    setparagraps(listDescriptions.join(""))
    setDescription("")
  }

  const addParagrapImageList = (symbol: string) => {
    const concat = symbol.concat(description)
    listDescriptions.push(concat)

    setUploadText(listDescriptions.join(""))
    setparagraps(listDescriptions.join(""))
    setDescription("")
  }

  return (
    <>
      <Divider>Rules section</Divider>

      <Button
        onClick={() => setaddRules(prevState => !prevState)}
        style={{ marginBottom: "10px" }}
      >
        Rules {addRules ? "Hide" : "Activate"}
      </Button>
      {addRules && (
        <div>
          <FormElement
            type="input"
            name={["rulesSection", "header"]}
            label="Header"
            initialValue={data?.header}
            required
          />
          <FormElement
            type="input"
            name={["rulesSection", "buttonText"]}
            label="Tooltip Text"
            initialValue={data?.buttonText}
            required
          />
          <Typography.Paragraph>Body:</Typography.Paragraph>
          <Input.TextArea
            value={description}
            autoSize={{ minRows: 4 }}
            onChange={e => setDescription(e.target.value)}
          />
          <div className={s.descriptionButtons}>
            <Button onClick={() => addParagraphOrItemList("&##")}>Add header paragraph</Button>
            <Button onClick={() => addParagraphOrItemList("&~~")}>Add paragraph</Button>
            <Button onClick={() => addParagraphOrItemList("*--")}>Add Item List</Button>
            <Button onClick={() => addParagraphOrItemList("&@@")}>Add image by string</Button>
          </div>
          <UploadComponent
            image=""
            setImage={e => addParagrapImageList(`&@@${e}`)}
            placeholder="Upload image"
          />
          <Button
            onClick={() => {
              setListDescription([])
              setUploadText("")
            }}
          >
            Clear Description
          </Button>
          <div style={{ margin: "10px 0" }}>
            Body`s result <b>(if you want to see result click Device button)</b>:
          </div>
          <Typography.Paragraph>{uploadText}</Typography.Paragraph>

          <Button
            onClick={() => {
              setIsShowDevice(prevState => !prevState)
            }}
          >
            Device {isShowDevice ? "Hide" : "Display"}
          </Button>
          {isShowDevice && (
            <MobileView infoVisibleDevice={infoVisibleDevice} uploadText={uploadText} />
          )}
          <div style={{ marginBottom: "20px" }} />
        </div>
      )}
    </>
  )
}
