import { Form, FormInstance, InputNumber, Select, Switch } from "antd"
import { FormElement, UploadComponent } from "components"
import { ContestConfig } from "domains/managingJson/store/types"
import { FC, useEffect, useReducer } from "react"
import { useStore } from "stores/utils/use-store"

export const HighlightSharingTab: FC<{form: FormInstance<any>}> = ({ form }) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  useEffect(() => {
    if (data.freePoints === undefined || data.freePoints.length === 0) {
      data.freePoints = [{
        type: "share",
        title: "",
        rewardText: "",
        points: 0,
        text: "",
        image: "",
        CTA: {
          activeText: "",
          inactiveText: "",
        },
        watermark: "",
        watermarkWidth: 0,
        watermarkPosition: "topLeft",
      }]
    }
  }, [data])

  const watermarkPositions = ["topLeft", "center", "topRight", "bottomLeft", "bottomRight"]

  const [, forceUpdate] = useReducer(x => x + 1, 0)

  return (
    <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px", display: "flex" }}>
            Enable Highlights Sharing
          <Switch
            style={{ marginLeft: "16px" }}
            defaultChecked={data.highlightSharing}
            onChange={() => data.highlightSharing = !data.highlightSharing}
          />
        </div>
        <div style={{ marginBottom: "24px", display: "flex" }}>
            Enable highlight recording automatically upon onboarding
          <Switch
            style={{ marginLeft: "16px" }}
            defaultChecked={data.automaticHighlightRecordingEnabling}
            onChange={() => data.automaticHighlightRecordingEnabling = !data.automaticHighlightRecordingEnabling}
          />
        </div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ width: "49%", marginRight: "8px" }}>
            Sharing Interval (in hours)
            <FormElement
              type="input"
              label=""
              name="contestSharingInterval"
              initialValue={data.contestServerSideConfiguration?.contestSharingInterval}
            />
          </div>
          <div style={{ width: "49%", marginRight: "8px" }}>
            Cotest Points for successful Share
            <FormElement
              type="input"
              label=""
              name="contestPoints"
              initialValue={data.freePoints?.[0]?.points}
            />
          </div>
        </div>
        <div style={{ width: "49%", marginRight: "8px" }}>
            Sharing Message Text for Twitter
          <FormElement
            type="text"
            label=""
            name="sharingMessage"
            initialValue={data.contestServerSideConfiguration?.contestTwitterShareMessage}
          />
        </div>
      </div>
      <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
        <div style={{ fontWeight: "500", marginBottom: "24px", display: "flex" }}>
            Watermark
          <Switch
            style={{ marginLeft: "16px" }}
            defaultChecked={data.freePoints && data.freePoints[0] ? !!data.freePoints[0].watermark : false}
            onChange={() => {
              data.highlightSharing = !data.highlightSharing
            }}
          />
        </div>
        <div style={{ display: "flex", marginBottom: "24px" }}>
          <div style={{ width: "49%", marginRight: "8px" }}>
            Watermark Position
            <Select
              value={data.freePoints ? data.freePoints[0] ? data.freePoints[0].watermarkPosition : "" : ""}
              onChange={e => {
                data.freePoints ? data.freePoints[0].watermarkPosition = e as any : ""
                forceUpdate()
              }}
              style={{ width: "100%" }}
            >
              {watermarkPositions.map(position => <Select.Option key={position} value={position}>{position}</Select.Option>)}
            </Select>
          </div>
          <div style={{ width: "49%", marginRight: "8px" }}>
            Watermark Width (watermark width / screen width ratio)
            <FormElement
              props={{ step: 0.01, min: 0, max: 1 }}
              type="inputNumber"
              label=""
              name="watermarkWidth"
              initialValue={data.freePoints ? data.freePoints[0] ? data.freePoints[0].watermarkWidth : 0 : 0}
            />
          </div>
        </div>
        <div style={{ width: "49%", marginRight: "8px" }}>
            Sharing Message Text for Twitter
          <UploadComponent
            image={data.freePoints ? data.freePoints[0] ? data.freePoints[0].watermark ?? "" : "" : ""}
            setImage={e => {
              if (data.freePoints) {
                forceUpdate()
                data.freePoints[0].watermark = e
              }
            }}
            placeholder=""
            title=""
          />
        </div>
      </div>
    </div>
  )

}
