import { FormInstance } from "antd"
import { Dispatch, FC, SetStateAction } from "react"
import { OnboardingStep } from "./OnboardingStep"
import { OnboardingAdd } from "./OnboardingAdd"
import { ContestConfigOnboardingScreenData } from "domains/managingJson/store/types"

export const OnboadringTab: FC<{
  form: FormInstance<any>
  onboardingSteps: ContestConfigOnboardingScreenData[]
  setOnboardingSteps: Dispatch<SetStateAction<ContestConfigOnboardingScreenData[]>>
  }> = ({ form, onboardingSteps, setOnboardingSteps }) => {

    return (
      <div style={{ background: "#F0F2F5", margin: "-16px", padding: "24px 48px" }}>
        <div style={{ background: "#FFFFFF", padding: "24px 16px", marginBottom: "24px" }}>
          <div style={{ fontWeight: "500", marginBottom: "16px" }}>Onboarding</div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {onboardingSteps.map((step, index) => (
              <OnboardingStep
                key={index}
                step={step}
                index={index}
                onboardingSteps={onboardingSteps}
                setOnboardingSteps={setOnboardingSteps}
                form={form}
              />
            ))}
            <OnboardingAdd
              key="add"
              onboardingSteps={onboardingSteps}
              setOnboardingSteps={setOnboardingSteps}
              form={form}
            />
          </div>
        </div>
      </div>
    )

  }
