import { makeAutoObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { ResponsiveTemplate, isResponsiveTemplate } from "./ResponsiveTemplate.store"
import { PutBody, TemplateGetDTO, popupAPI } from "../api/popupApi"
import { notification } from "antd"
import { StyledTemplate, isStyledTemplate } from "./StyledTemplate.store"
import { GenericTemplateInterface } from "./types"

const spawnErrorNotification = (message: string) => notification.error({
  message,
  placement: "bottomRight",
})


const prepareDataWithPayload = (data: GenericTemplateInterface & {id?: number}) => {
  const { name, img, link, popupVersion, id, ...rest } = data
  const mainData = {
    name,
    img,
    link,
    popupVersion,
    ...(id && { id }),
  }
  const payloadData = rest
  const finalData = {
    ...mainData,
    ...(Object.keys(payloadData).length !== 0 && { payload: JSON.stringify(payloadData) }),
  }

  return finalData
}

type TemplateType = ResponsiveTemplate | StyledTemplate


export class PopupTemplatesStore {
  rootStore: RootStore
  templates: TemplateType[] = []
  isLoading = true

  constructor() {
    makeAutoObservable(this)
    this.loadTemplates()
  }

  loadTemplates = async() => {
    this.isLoading = true
    try {
      const fetchedTemplates: TemplateGetDTO[] = await popupAPI.getAll()
      fetchedTemplates.forEach(template => {
        const { payload, ...rest } = template
        const flattenedTemplate = {
          ...rest,
          ...payload,
        }
        if (isStyledTemplate(flattenedTemplate)) {
          this.templates.push(new StyledTemplate(this, flattenedTemplate))
        } else if (isResponsiveTemplate(flattenedTemplate)) {
          this.templates.push(new ResponsiveTemplate(this, flattenedTemplate))
        }
      })
      this.isLoading = false
    } catch (error) {
      console.error(error)
      spawnErrorNotification("Error loading templates")
    }
  }

  createTemplate = async(data: GenericTemplateInterface) => {
    const preparedData = prepareDataWithPayload(data)

    try {
      const id = await popupAPI.post(preparedData)
      const getTemplate = () => {
        if (isStyledTemplate(data)) return new StyledTemplate(this, { ...data, id })
        if (isResponsiveTemplate(data)) return new ResponsiveTemplate(this, { ...data, id })
      }
      const template = getTemplate()
      if (!template) throw new Error("Error creating template")
      this.templates.push(template)
    } catch (error) {
      console.error(error)
      spawnErrorNotification("Error creating template")
    }
  }

  updateTemplate = async(data: TemplateType) => {
    const preparedData = prepareDataWithPayload(data.asJson)
    if (preparedData?.id === undefined) throw new Error("Missing id in prepared data")

    try {
      await popupAPI.put(preparedData as PutBody)
    } catch (error) {
      spawnErrorNotification("Error updating template")
    }
  }

  removeTemplate = async(template: TemplateType) => {
    try {
      await popupAPI.delete(template.id)
      this.templates = this.templates.filter(t => t.id !== template.id)
    } catch (error) {
      console.error(error)
      spawnErrorNotification("Error removing template")
    }
  }

  get responsiveTemplates() {
    return this.templates.filter(template => template.popupVersion === "responsive")
  }

  get styledTemplates() {
    return this.templates.filter(template => template.popupVersion === "styled")
  }

  get styledTemplatesJS() {
    return this.styledTemplates.map(template => template.asJson)
  }

  get responsiveTemplatesJS() {
    return this.responsiveTemplates.map(template => template.asJson)
  }

  findTemplate = (id: number) => this.templates.find(template => template.id === id)
}
