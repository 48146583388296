import React, { FC, useState } from "react"
import { DatePicker, Form, Input, Radio, Select } from "antd"
import moment from "moment"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { UploadComponent, DefaultLinksRouting, SubmitCancelButtons } from "components"
import { PopUp } from "stores/primitives/pop-up"
import { notifications } from "notifications"
import GeoPickerElement from "components/geoPicker"

export const PopUpEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      popUpsStore: {
        selectedPopUP: {
          name,
          link,
          activeUntil,
          startDate,
          img,
          countries,
          daysSinceRegistration,
          userTypes,
          excludeCountries,
        },
      },
      popUpsStore,
    },
  } = useStore()

  const [image, setImage] = useState(img)
  const [geoType, setgeoType] = useState(countries && countries.length > 0
    ? "WHITE"
    : excludeCountries && excludeCountries.length > 0
      ? "BLACK"
      : "ALL")

  const onFinishHandler = async(e: PopUp) => {
    e.img = e.img || image
    e.excludeCountries
        = geoType === "BLACK" && e.countries && String(e.countries)?.length > 0
        ? e.countries
        : null
    e.countries
        = geoType === "WHITE" && e.countries && String(e.countries)?.length > 0
        ? e.countries
        : null

    e.daysSinceRegistration
        = String(e.daysSinceRegistration).length === 0 || Number(e.daysSinceRegistration) === 0
        ? null
        : Number(e.daysSinceRegistration)

    if (!image) {
      return notifications.error("Please select image")
    }
    popUpsStore.funcAfterSuccess = async() => {
      onClose()
      await popUpsStore.getData()
    }
    const countries = e.countries?.toString()

    const array = countries === "" ? undefined : countries?.split(",")
    e.countries = array || []
    if (e.userTypes && e.userTypes.length < 1) e.userTypes = null

    if (e.countries.length < 1) e.countries = null
    else e.countries = e.countries
      .map(item => item.toUpperCase())
      .filter((value, index, self) => self.indexOf(value) === index)
    if (!e.activeUntil) e.activeUntil = null
    if (!e.startDate) e.startDate = null
    if (editType === "edit") {
      await popUpsStore.updatePopUp(e)
    } else {
      await popUpsStore.createPopUp(e)
    }
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input PopUp name!",
          },
        ]}
      >
        <Input maxLength={30} />
      </Form.Item>

      <Form.Item
        label="Days Since Registration"
        name="daysSinceRegistration"
        initialValue={daysSinceRegistration}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item label="User Types" name="userTypes" initialValue={userTypes || undefined}>
        <Select value={link} mode="multiple">
          {["REGULAR", "PREMIUM", "PREMIUM PLUS", "PREMIUM ELITE"]?.map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="GEO limits" initialValue={geoType}>
        <Radio.Group onChange={e => setgeoType(e.target.value)} value={geoType}>
          <Radio value="ALL" defaultChecked>
            All
          </Radio>
          <Radio value="WHITE">Whitelist GEOs</Radio>
          <Radio value="BLACK">Blacklist GEOs</Radio>
        </Radio.Group>
      </Form.Item>
      {geoType !== "ALL" && (
        <GeoPickerElement label="Choose GEOs" name="countries" initialValue={(geoType === "BLACK" ? excludeCountries : countries) || []} />
      )}
      <DefaultLinksRouting link={link || ""} formItemName="link" notImportant />
      <Form.Item
        label="Start Date"
        name="startDate"
        initialValue={startDate ? moment(startDate as Date) : ""}
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" placeholder="Select Time" />
      </Form.Item>
      <Form.Item
        label="Expire"
        name="activeUntil"
        initialValue={activeUntil ? moment(activeUntil as Date) : ""}
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" placeholder="Select Time" />
      </Form.Item>
      <UploadComponent image={image} setImage={setImage} maxSize={1} />
      <SubmitCancelButtons onClose={onClose} isLoading={popUpsStore.isLoading} />
    </Form>
  )
})
