import { FC, useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { Modal, Table, TablePaginationConfig } from "antd"
import { popupsColumns } from "./columns"
import { observer } from "mobx-react"
import { PopUp } from "stores/primitives/pop-up"
import { PopUpEdit } from "./pop-up-edit"
import { Column } from "abstract/types/column.type"
import { DeleteIconButton, showDeleteConfirm, CreateNewElement } from "components"

export const PopUpsList: FC = observer(() => {
  const { dataStore: { popUpsStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"edit" | "create" | null>(null)
  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      popUpsStore.pagination.page = page
    },
    total: popUpsStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }
  const onClose = () => {
    setEditCreateVisibility(null)
    popUpsStore.clearSelectedElement()
  }

  useEffect(() => {
    popUpsStore.getData()
  }, [popUpsStore])

  const columns: Column[] = [
    ...popupsColumns,
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: any) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(row.name, undefined, async() => {
              popUpsStore.funcAfterSuccess = async() => await popUpsStore.getData()

              await popUpsStore.deletePopUp(row.id)
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="popup" />
      <Table
        pagination={paginationOptions}
        dataSource={popUpsStore.popUps}
        columns={columns as any}
        rowKey="id"
        scroll={{ x: true }}
        loading={popUpsStore.isLoading}
        onRow={(record: PopUp) => ({
          onClick: () => {
            popUpsStore.setSelectedPopUp = record
            setEditCreateVisibility("edit")
          },
        })}
      />
      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit popup" : "Create popup"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <PopUpEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
