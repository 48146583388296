import { FullRaffle, UpdateRaffle } from "../store/types"
import { apiCall } from "api-calls/api-call"
import { GetItemsFilter } from "../store"

// RAFFLE`S API
export const getRaffles = async(
  page: number,
  limit: number,
  filter?: GetItemsFilter,
): Promise<{
  data: FullRaffle[];
  total: number;
  filter?: GetItemsFilter;
}> => {
  const query: { page: number; limit: number; filter?: GetItemsFilter } = {
    page,
    limit,
  }

  if (filter) {
    query.filter = {}
  }

  if (query.filter && filter?.title) query.filter["title"] = filter.title

  if (query.filter && filter?.lifeCycleStatus) query.filter["lifeCycleStatus"] = filter.lifeCycleStatus

  if (query.filter && filter?.distributionType) query.filter["distributionType"] = filter.distributionType

  if (query.filter && filter?.handleType) query.filter["handleType"] = filter.handleType

  return apiCall({
    method: "GET",
    path: "raffle/core",
    query,
  })
}
export const createRaffle = async(body: FullRaffle) => {
  return apiCall({
    method: "POST",
    path: "raffle/core",
    body,
  })
}
export const updateRaffle = async(raffleId: string, body: UpdateRaffle) => {
  return apiCall({
    method: "PATCH",
    path: `raffle/core/${raffleId}`,
    body,
  })
}
export const deleteRaffle = async(raffleId: string) => {
  return apiCall({
    method: "DELETE",
    path: `raffle/core/${raffleId}`,
  })
}

export const rafflePrizeSubmit = async(raffleId: string) => {
  return apiCall({
    method: "POST",
    path: `raffle/core/${raffleId}/submit`,
  })
}
// PRIZE`S API
export const getPrizes = async(id: string) => {
  const query: { page: number; limit: number; filter?: string } = {
    page: 1,
    limit: 100,
  }

  return apiCall({
    method: "GET",
    path: `raffle/${id}/places`,
    query,
  })
}
export const createPrize = async(body: any) => {
  return apiCall({
    method: "POST",
    path: "raffle/places",
    body,
  })
}
export const updatePrize = async(id: string, body: any) => {
  return apiCall({
    method: "PUT",
    path: `raffle/places/prize/${id}`,
    body,
  })
}
export const deletePrize = async(id: string) => {
  return apiCall({
    method: "DELETE",
    path: `raffle/places/${id}`,
  })
}

// WINNER`S API
export const createWinner = async(body: any) => {
  return apiCall({
    method: "POST",
    path: "raffle/winner",
    body,
  })
}
export const updateWinner = async(winnerId: string, body: any) => {
  return apiCall({
    method: "PUT",
    path: `raffle/winner/${winnerId}`,
    body,
  })
}
export const deleteWinner = async(winnerId: string) => {
  return apiCall({
    method: "DELETE",
    path: `raffle/winner/${winnerId}`,
  })
}
