import { FC, useMemo } from "react"
import { Descriptions } from "antd"
import { useStore } from "stores/utils/use-store"

export const MarketplaceRegionInfo: FC = () => {
  const {
    dataStore: {
      marketplaceRegionsStore: {
        selectedElement: {
          name,
          tag,
          priority,
          parent,
        },
      },
      marketplaceRegionsStore,
    },
  } = useStore()

  const numberByPriority = useMemo(() => {
    return marketplaceRegionsStore.regions.reduce((acc, curr) => {
      if (priority <= curr.priority) {
        return acc + 1
      }

      return acc
    }, 0)
  }, [priority, marketplaceRegionsStore.regions])

  return (
    <Descriptions column={1}>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Name">
        {name}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Tag">
        {tag}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Priority">
        {`${priority} (~${numberByPriority} of ${marketplaceRegionsStore.regions.length})`}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Parent">
        {parent?.name}
      </Descriptions.Item>
    </Descriptions>
  )
}
