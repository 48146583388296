import { MobileConfigTab } from "domains/managingJson/store/types"
import { Button, Collapse, Form, Typography } from "antd"
import { Input } from "antd"
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react"
import { UploadComponent } from "components"
import Styles from "../styles.module.css"

const { Panel } = Collapse
const { TextArea } = Input
const { Text } = Typography

type Props = {
  index: number;
  initialTab: MobileConfigTab;
  setError: (err: boolean, index: number) => void;
  error: boolean;
  updateTab: (callback: (data: MobileConfigTab) => MobileConfigTab, index: number) => void;
  deleteTab: (index: number) => void;
  duplicateTab: (index: number) => void;
};

export const TabEditor = ({
  initialTab,
  setError,
  error,
  updateTab,
  index,
  deleteTab,
  duplicateTab,
}: Props) => {
  const [form] = Form.useForm()
  const [imageUrl, setImageUrl] = useState<string>(initialTab.icon ?? "")

  const jsonString = initialTab.iconProps
    ? JSON.stringify(initialTab.iconProps, null, 4)
    : undefined

  const validate = useCallback(async() => {
    try {
      await form.validateFields()
      setError(false, index)
    } catch (errors: any) {
      if (errors?.errorFields?.length > 0) setError(true, index)
    }
  }, [form, index, setError])

  const onValuesChange = useCallback(async() => {
    const formData = form.getFieldsValue()
    let parsedIconProps = undefined
    try {
      parsedIconProps = formData.iconProps ? JSON.parse(formData.iconProps) : undefined
    } catch (error) {
      console.log(error)
    }

    updateTab(
      prev => ({
        ...prev,
        icon: formData.icon,
        name: formData.name,
        src: formData.src,
        iconProps: parsedIconProps,
      }),
      index,
    )

    validate()
  }, [form, index, updateTab, validate])

  useEffect(() => {
    validate()
  }, [validate])

  const handleDelete = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setError(false, index)
      deleteTab(index)
    },
    [deleteTab, index, setError],
  )

  const handleDuplicate = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      duplicateTab(index)
    },
    [duplicateTab, index],
  )

  return useMemo(
    () => (
      <Collapse>
        <Panel
          forceRender
          header={
            <div>
              {initialTab.name}
              {error && <Text type="danger"> (!)</Text>}
            </div>
          }
          key={initialTab.uid}
        >
          <Form
            form={form}
            name="tabForm"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{
              name: initialTab.name,
              src: initialTab.src,
              iconProps: jsonString,
              icon: initialTab.icon,
            }}
            autoComplete="off"
            onChange={onValuesChange}
          >
            <Form.Item
              label="name"
              name="name"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="src"
              name="src"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="icon"
              name="icon"
              valuePropName="fileList"
              rules={[{ required: true, message: "Required field" }]}
            >
              <UploadComponent
                image={imageUrl}
                setImage={src => {
                  setImageUrl(src)
                  form.setFieldsValue({ icon: src })
                }}
                uploadStyle={{ listType: "picture-card" }}
                placeholder="Upload icon"
              />
            </Form.Item>
            <Form.Item
              label="iconProps"
              name="iconProps"
              rules={[
                {
                  message: "Unparsable JSON syntax",
                  validator: (_, value) => {
                    try {
                      if (!value) return Promise.resolve()
                      JSON.parse(value)

                      return Promise.resolve()
                    } catch (error) {
                      return Promise.reject(new Error("Unparsable JSON syntax"))
                    }
                  },
                },
              ]}
            >
              <TextArea autoSize={{ minRows: 3 }} />
            </Form.Item>
          </Form>
          <div className={Styles.collapseButtonWrapper}>
            <Button onClick={handleDuplicate}>Duplicate</Button>
            <Button danger onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </Panel>
      </Collapse>
    ),
    [
      error,
      form,
      handleDelete,
      handleDuplicate,
      imageUrl,
      initialTab.icon,
      initialTab.name,
      initialTab.src,
      initialTab.uid,
      jsonString,
      onValuesChange,
    ],
  )
}
