import { gameNames } from "abstract/constants/game-ids"
import { Tag } from "antd"
import { InGameBannerType } from "domains/managingJson/store/types"
import { defaultFormat } from "utils/date"

export const bannersColumns = [
  { title: "Name", dataIndex: "name", key: "name" },
  {
    title: "Countries",
    dataIndex: "countries",
    key: "countries",
    render: (_, row: { data: InGameBannerType }) => (
      <Tag color="red">{row?.data?.countries?.join(",")}</Tag>
    ),
  },
  {
    title: "Games",
    dataIndex: "gameIds",
    key: "gameIds",
    render: (_, row: { data: InGameBannerType }) => (
      <Tag color="green">{row?.data?.gameIds?.map(item => gameNames[item]).join(",")}</Tag>
    ),
  },
  {
    title: "Windows",
    dataIndex: "windows",
    key: "windows",
    render: (_, row: { data: InGameBannerType }) => (
      <Tag color="blue">{row?.data?.windows?.join(",")}</Tag>
    ),
  },
  {
    title: "Start/End Dates",
    dataIndex: "startDate",
    key: "startEndDates",
    render: (_, row: { data: InGameBannerType }) => {
      return (
        <div style={{ display: "grid", gap: "6px", width: "max-content" }}>
          {row?.data?.startDate ? (
            <Tag color="green">{defaultFormat(row?.data?.startDate)}</Tag>
          ) : (
            <Tag>No time</Tag>
          )}
          {row?.data?.endDate ? (
            <Tag color="red">{defaultFormat(row?.data?.endDate)}</Tag>
          ) : (
            <Tag>No time</Tag>
          )}
        </div>
      )
    },
  },
]
