import { MobileConfigAnalytics } from "domains/managingJson/store/types"
import { Form } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useCallback } from "react"

type Props = {
  initialAnalytics: MobileConfigAnalytics | undefined;
  setError: (isError: boolean) => void;
  updateAnalytics: (data: MobileConfigAnalytics | undefined) => void;
};

export const AnalyticsEditor = ({ initialAnalytics, setError, updateAnalytics }: Props) => {
  const [form] = Form.useForm()

  const jsonString = initialAnalytics ? JSON.stringify(initialAnalytics, null, 4) : ""

  const onValuesChange = useCallback(async() => {
    const formData = form.getFieldsValue()
    let data = undefined
    try {
      data = formData.analytics ? JSON.parse(formData.analytics) : undefined
    } catch (error) {
      console.log(error)
    }
    updateAnalytics(data)

    try {
      await form.validateFields()
      setError(false)
    } catch (errors: any) {
      if (errors?.errorFields?.length > 0) setError(true)
    }
  }, [form, setError, updateAnalytics])

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ analytics: jsonString }}
      autoComplete="off"
      onChange={onValuesChange}
    >
      <Form.Item
        label="analytics"
        name="analytics"
        rules={[
          {
            message: "Unparsable JSON syntax",
            validator: (_, value) => {
              try {
                if (!value) return Promise.resolve()
                JSON.parse(value)
                setError(false)

                return Promise.resolve()
              } catch (error) {
                setError(true)

                return Promise.reject(new Error("Unparsable JSON syntax"))
              }
            },
          },
        ]}
      >
        <TextArea autoSize={{ minRows: 3 }} />
      </Form.Item>
    </Form>
  )
}
