import { PopupTemplatesStore } from "./popupTemplates.store"
import { makeAutoObservable } from "mobx"
import { GenericTemplateInterface, GenericTemplateWithIdInterface } from "./types"
import { PopupReward } from "./rewards.types"

import { ButtonAction } from "./buttonAction.types"
import { Pixel } from "domains/managingJson/store/types"
import { ConsentRow } from "components/userConsentEditor"

export const isStyledTemplate = (
  template: GenericTemplateInterface,
): template is StyledTemplate => {
  return template.popupVersion === "styled"
}

type ButtonData = {
  text: string;
  primaryAction: ButtonAction;
  secondaryAction?: ButtonAction;
}

export interface StyledTemplatePayload {
  enabled?: boolean;
  popupVersion: "styled";
  fullWidthImage: boolean;
  heading?: string;
  width?: number;
  richText?: string;
  withCloseButton?: boolean;
  autoShowCloseButton?: number;
  withDivider?: boolean;
  renderButtonsInsideImage?: boolean;
  headerInsideImage?: boolean;
  primaryButton?: ButtonData;
  secondaryButton?: ButtonData;
  reward?: PopupReward;
  consentRows?: ConsentRow[];
}

export interface StyledTemplateInterface extends Omit<GenericTemplateWithIdInterface, "popupVersion">, StyledTemplatePayload {}

export class StyledTemplate implements StyledTemplateInterface {
  popupVersion = "styled" as const
  name: string
  enabled: boolean = true
  id: number
  img: string
  link: string = ""
  store: PopupTemplatesStore
  fullWidthImage: boolean = false
  heading?: string
  width?: number
  richText?: string
  withCloseButton?: boolean
  autoShowCloseButton?: number
  withDivider?: boolean
  renderButtonsInsideImage?: boolean
  headerInsideImage?: boolean
  primaryButton?: ButtonData
  secondaryButton?: ButtonData
  reward?: PopupReward | undefined
  trackingPixels?: Pixel[]
  consentRows?: ConsentRow[]

  constructor(store: PopupTemplatesStore, data: Omit<StyledTemplateInterface, "link">) {
    makeAutoObservable(this, {
      id: false,
      store: false,
    })
    this.store = store
    this.id = data.id
    this.img = data.img
    this.link = ""
    this.name = data.name
    this.enabled = data.enabled ?? true
    this.fullWidthImage = data.fullWidthImage
    this.heading = data.heading
    this.width = data.width
    this.richText = data.richText
    this.withCloseButton = data.withCloseButton
    this.autoShowCloseButton = data.autoShowCloseButton
    this.withDivider = data.withDivider
    this.renderButtonsInsideImage = data.renderButtonsInsideImage
    this.headerInsideImage = data.headerInsideImage
    this.primaryButton = data.primaryButton
    this.secondaryButton = data.secondaryButton
    this.reward = data.reward
    this.trackingPixels = data.trackingPixels
    this.consentRows = data.consentRows
  }

  public delete() {
    this.store.removeTemplate(this)
  }

  public save() {
    if (this.id) return this.store.updateTemplate(this)
    this.store.createTemplate(this)
  }

  get asJson() {
    return {
      popupVersion: this.popupVersion,
      name: this.name,
      enabled: this.enabled,
      id: this.id,
      img: this.img,
      link: this.link,
      fullWidthImage: this.fullWidthImage,
      heading: this.heading,
      width: this.width,
      richText: this.richText,
      withCloseButton: this.withCloseButton,
      autoShowCloseButton: this.autoShowCloseButton,
      withDivider: this.withDivider,
      renderButtonsInsideImage: this.renderButtonsInsideImage,
      headerInsideImage: this.headerInsideImage,
      primaryButton: this.primaryButton,
      secondaryButton: this.secondaryButton,
      reward: this.reward,
      trackingPixels: this.trackingPixels,
      consentRows: this.consentRows,
    }
  }
}
