import React, { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useStore } from "stores/utils/use-store"
import { Spin, Col, Tabs, Button, Modal, Input } from "antd"
import { observer } from "mobx-react"
import { ArrowLeftOutlined } from "@ant-design/icons"


export const GameAccountDetails: FC = observer<FC>(() => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const { dataStore: { gameAccountDetailsStore } } = useStore()

  useEffect(() => {
    if (!id) {
      return
    }

    gameAccountDetailsStore.getAccountDetails(id).then()
  }, [gameAccountDetailsStore, id])

  const { list } = gameAccountDetailsStore.gameAccounts
  const dataAcc = gameAccountDetailsStore.dataAcc
  const [visibleCloseAccountModal, setCloseAccountModalVisibility] = useState<boolean>(false)
  const [deleteConfimation, setDeleteConfirmation] = useState("")
  const [isValidInput, setValidInput] = useState(false)

  useEffect(() => {
    if (list && dataAcc.id == null) {
      navigate("/game-accounts")
    }
  }, [dataAcc.id, list, navigate])

  return (
    <Spin spinning={gameAccountDetailsStore.isLoading} size="large">
      <Button onClick={() => navigate("/games/game-accounts")} style={{ marginBottom: "15px" }}>
        <ArrowLeftOutlined /> Back to List
      </Button>
      <br />
      {!gameAccountDetailsStore.isLoading && (
        <Col span={18}>
          <Tabs>
            {/* <TabPane tab="Account Info" key={-1}>
              <GameAccountInfo accounts={list} account={dataAcc.account} />
            </TabPane> */}
          </Tabs>
        </Col>
      )}

      {/* {!gameAccountDetailsStore.account.isClosed && ( */}
      <Button onClick={() => setCloseAccountModalVisibility(true)}>Delete Account</Button>
      {/* )} */}

      <Modal
        visible={visibleCloseAccountModal}
        onOk={async() => {
          const isValid = deleteConfimation === "DELETE"
          if (!isValid) {
            console.log("just temp")
          } else {
            await gameAccountDetailsStore.deleteAccount(dataAcc.id)
            setCloseAccountModalVisibility(false)
            setValidInput(false)
            setDeleteConfirmation("")
            navigate("/game-accounts")
          }
        }}
        onCancel={() => setCloseAccountModalVisibility(false)}
        okType={isValidInput ? "primary" : "danger"}
      >
        <p> Account abfuscation: Are you sure?</p>
        <p>
          This action will abfuscate sensitive information for this user. It will be impossible to
          return
        </p>
        <p>
          Write in <span style={{ color: "red" }}>DELETE</span> into input and click{" "}
          <span style={{ color: "blue" }}>OK</span>
        </p>
        <Input
          key="input"
          value={deleteConfimation}
          onChange={e => {
            const value = e.target.value
            if (value === "DELETE") setValidInput(true)
            if (value !== "DELETE") setValidInput(false)
            setDeleteConfirmation(value)
          }}
        />
      </Modal>
    </Spin>
  )
})
