/* eslint-disable max-len */
import {
  Card,
  Form,
  FormInstance,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd"
import { getCountryNameById } from "utils/countryCodes"
import Styles from "./styles.module.css"
import { useStore } from "stores/utils/use-store"
import { SourceImagesEditor } from "./sourceImagesEditor"
import { useReducer } from "react"
import { ContestConfig } from "domains/managingJson/store/types"

type Props = {
  parentIndex: number;
  form: FormInstance<any>;
  title: string;
  objectKey: string,
};

export const GeoImagesEditor = ({ parentIndex, form, title, objectKey }: Props) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()

  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const data = selectedElement.data as ContestConfig

  const countries = data.countries || []
  const countriesWithGlobal = ["global", ...countries]

  const items: TabsProps["items"] = countriesWithGlobal.map(countryName => {
    const country = countryName

    const getTooltipTitle = () => {
      if (country === "global") {
        return "Global images are used when no specific country images are available."
      }

      return getCountryNameById(country)
    }

    return {
      label: <Tooltip title={getTooltipTitle()}>{country}</Tooltip>,
      key: country,
      children: <SourceImagesEditor
        key={countryName}
        countryIndex={countriesWithGlobal.indexOf(countryName)}
        onChange={url => {
          if (!data[objectKey]) {
            data[objectKey] = {}
          }
          data[objectKey][countryName] = url
          forceUpdate()

        }}
        value={data[objectKey]?.[countryName]}
      />,
    }
  })

  return (
    <Space direction="vertical" size="large" className={Styles.mainSpace}>
      <div key={5} className={Styles.wrapper}>
        <Card
          size="small"
          className={Styles.fullHeightCard}
          title={
            <div className={Styles.cardHeadingWithTooltip}>
              {title}
            </div>
          }
        >
          <div>
            <Tabs items={items} />
          </div>
        </Card>
      </div>
    </Space>
  )
}
