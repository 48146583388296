import React, { FC, useEffect, useState } from "react"
import { Checkbox, Form, Input, Select } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { SubmitCancelButtons, UploadFileComponent } from "components"
import { gameNames } from "abstract/constants/game-ids"
import { PersonalBoosterType } from "../store/types"
import { notifications } from "notifications"

export const EmailCtaEdit: FC<{ onClose: () => void; isCsv: boolean }> = observer(({ onClose, isCsv }) => {
  const { dataStore: { emailsCtaStore } } = useStore()
  const [type, setType] = useState("")
  const [needToSend, setneedToSend] = useState(false)
  const [file, setfile] = useState<File>()

  useEffect(() => {
    if (file && emailsCtaStore.dataUploadType === null) {
      emailsCtaStore.uploadCsv()
    } else if (emailsCtaStore.dataUploadType?.putUrl && file) {
      const reader = new FileReader()
      reader.onload = function(e) {
        if (e?.target?.result) {
          const blob = new Blob([new Uint8Array(e.target.result as ArrayBuffer)], { type: file.type })
          if (blob) {
            const req = new XMLHttpRequest()

            req.open("PUT", emailsCtaStore.dataUploadType?.putUrl || "", true)

            req.onload = event => {
              console.log(event, "event")
            }
            req.send(blob)
          }
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }, [file, emailsCtaStore.dataUploadType])

  const onFinishHandler = async(e: any) => {
    if (isCsv && !file) {
      return notifications.error("Select file")
    }
    emailsCtaStore.funcAfterSuccess = async() => {
      onClose()
      setfile(undefined)
      await emailsCtaStore.getData()
    }
    const data: any = {}

    data.templateId = e.templateId
    data.category = e.category
    data.type = type
    data.needToSend = needToSend
    data.data = {}

    if (type === "BUFFS") {
      data.data.amount = +e.amount
    } else if (type === "BOOST_TIMED") {
      data.data.percentage = +e.percentage
      data.data.duration = +e.duration
      data.data.period = e.period
      data.data.gameIds = e.gameIds.map(item => +item)
      data.data.personalBoosterType = e.personalBoosterType
    } else if (type === "PREMIUM") {
      data.data.premiumType = e.premiumType
      data.data.period = e.period
      data.data.duration = +e.duration
    } else if (type === "MARKETPLACE_DISCOUNT") {
      data.data.percentage = +e.percentage
    } else if (type === "SPIN_COLLECT_GOLDEN_TICKET") {
      data.data.count = +e.count
    }
    if (isCsv) {
      data.fileData = emailsCtaStore.dataUploadType?.fileData
      await emailsCtaStore.sendEmailCsv(data)
    } else {
      data.accountId = e.accountId
      data.email = e.email
      await emailsCtaStore.createEmailCTA(data)
    }
  }

  return (
    <Form onFinish={onFinishHandler}>
      {isCsv ? (
        <div style={{ width: "85px" }}>
          <UploadFileComponent file={file} setfile={setfile} fileType=".csv" />
        </div>
      ) : (
        <>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input Email name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Account Id"
            name="accountId"
            rules={[
              {
                required: true,
                message: "Please input accountId!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Template Id"
        name="templateId"
        rules={[
          {
            required: true,
            message: "Please input templateId!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Category"
        name="category"
        rules={[
          {
            required: true,
            message: "Please input category!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Types"
        name="type"
        rules={[
          {
            required: true,
            message: "Please select type!",
          },
        ]}
      >
        <Select onChange={setType} value={type}>
          {[
            "PREMIUM",
            "MARKETPLACE_DISCOUNT",
            "BUFFS",
            "BOOST_TIMED",
            "SPIN_COLLECT_GOLDEN_TICKET",
          ]?.map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {type.includes("BOOST") ? (
        <div>
          <Form.Item
            label="Percentage"
            name="percentage"
            rules={[
              {
                required: true,
                message: "Please input percentage!",
              },
            ]}
          >
            <Input type="number" min={1} />
          </Form.Item>

          <Form.Item
            label="Period"
            name="period"
            rules={[
              {
                required: true,
                message: "Please input period!",
              },
            ]}
          >
            <Select>
              {["HOURS", "DAYS"]?.map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Duration"
            name="duration"
            rules={[
              {
                required: true,
                message: "Please input duration!",
              },
            ]}
          >
            <Input type="number" min={1} max={750} />
          </Form.Item>
          <Form.Item
            label="Select Game Ids"
            name="gameIds"
            rules={[
              {
                required: true,
                message: "Please select games!",
              },
            ]}
          >
            <Select mode="multiple">
              {Object.entries(gameNames).map(el => (
                <Select.Option key={el[0]} value={el[0]}>{el[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Personal Booster Type"
            name="personalBoosterType"
            rules={[
              {
                required: true,
                message: "Please input Personal Booster Type!",
              },
            ]}
          >
            <Select>
              {Object.entries(PersonalBoosterType).map(el => (
                <Select.Option key={el[0]} value={el[0]}>{el[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ) : type === "BUFFS" ? (
        <Form.Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: true,
              message: "Please input amount!",
            },
          ]}
        >
          <Input type="number" min={1} />
        </Form.Item>
      ) : type.includes("MARKETPLACE") ? (
        <Form.Item
          label="Percentage"
          name="percentage"
          rules={[
            {
              required: true,
              message: "Please input percentage!",
            },
          ]}
        >
          <Input type="number" min={1} max={100} />
        </Form.Item>
      ) : type.includes("PREMIUM") ? (
        <div>
          <Form.Item
            label="Premium Type"
            name="premiumType"
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Select>
              {["PREMIUM", "PREMIUM_PLUS", "PREMIUM_ELITE"]?.map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Duration"
            name="duration"
            rules={[
              {
                required: true,
                message: "Please input duration!",
              },
            ]}
          >
            <Input type="number" min={1} max={750} />
          </Form.Item>
          <Form.Item
            label="Period"
            name="period"
            rules={[
              {
                required: true,
                message: "Please select period!",
              },
            ]}
          >
            <Select>
              {["HOURS", "DAYS"]?.map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ) : (
        type === "SPIN_COLLECT_GOLDEN_TICKET" && (
          <Form.Item
            label="Count"
            name="count"
            rules={[
              {
                required: true,
                message: "Please input count!",
              },
            ]}
          >
            <Input type="number" min={1} />
          </Form.Item>
        )
      )}
      <Checkbox checked={needToSend} onChange={() => setneedToSend(!needToSend)}>
        Need To Send
      </Checkbox>
      <SubmitCancelButtons onClose={onClose} isLoading={emailsCtaStore.isLoading} />
    </Form>
  )
})
