import { ItemType, GetItemsFilter, ItemToUpdate } from "domains/marketplace/store/types"
import { computed, observable, transaction, makeObservable } from "mobx"
import { createItem, deleteItem, getItems, updateItem } from "domains/marketplace/api"

import { PaginationStore } from "stores/data/pagination.store"
import { RootStore } from "stores/root.store"

export class MarketplaceStore extends PaginationStore<ItemType, GetItemsFilter> {
  @observable
  private _itemTypes: ItemType[]

  @observable
  private _selectedElement: ItemType = {} as ItemType

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<ItemType[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getItems, this.filter, page, limit)
    transaction(() => {
      this._itemTypes = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: ItemToUpdate) {
    await this.defaultFunction(updateItem, this._selectedElement.id, data)
  }

  async create(data: ItemToUpdate) {
    await this.defaultFunction(createItem, data)
  }

  async delete(id: string) {
    await this.defaultFunction(deleteItem, id)
  }

  @computed
  get itemTypes(): ItemType[] {
    return this._itemTypes
  }

  @computed
  get selectedElement(): ItemType {
    return this._selectedElement
  }

  set selectedElement(value: ItemType) {
    this._selectedElement = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as ItemType
  }
}
