import React, { FC } from "react"
import { useStore } from "stores/utils/use-store"

import { Form, Input, Modal } from "antd"
import { observer } from "mobx-react"

import { SubmitCancelButtons } from "components"
import { NameItem } from "domains/managingJson/store/types"

export const EditName: FC<{
  onClose: () => void;
}> = observer(({ onClose }) => {
  const {
    dataStore: {
      managingJsonStore,
      managingJsonStore: { selectedElement: { name, type } },
    },
  } = useStore()

  const onFinishHandler = async(event: NameItem & { name: string }) => {
    const dataToBack = {
      oldName: name,
      newName: event.newName,
      type,
    }

    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      await managingJsonStore.getData()
    }
    await managingJsonStore.updateName(dataToBack)
  }

  return (
    <Modal style={{ top: "10px" }} visible title="Edit name" footer={null} onCancel={onClose}>
      <Form onFinish={onFinishHandler}>
        <Form.Item label="Old name" name="oldName" style={{ width: "100%" }} initialValue={name}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="New name" name="newName" style={{ width: "100%" }}>
          <Input />
        </Form.Item>
        <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
      </Form>
    </Modal>
  )
})
