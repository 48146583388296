import { PopupTemplatesStore } from "./popupTemplates.store"
import { makeAutoObservable } from "mobx"
import { GenericTemplateInterface, GenericTemplateWithIdInterface } from "./types"
import { Pixel } from "domains/managingJson/store/types"


export const isResponsiveTemplate = (template: GenericTemplateInterface): template is ResponsiveTemplate => {
  return template.popupVersion === "responsive"
}

export interface ResponsiveTemplatePayload {
  enabled?: boolean;
  popupVersion: "responsive";
}

// interface ResponsiveTemplateInterface extends GenericTemplateWithIdInterface {}
export interface ResponsiveTemplateInterface extends Omit<GenericTemplateWithIdInterface, "popupVersion">, ResponsiveTemplatePayload {}


export class ResponsiveTemplate implements ResponsiveTemplateInterface {
  popupVersion = "responsive" as const
  name: string
  enabled: boolean = true
  id: number
  img: string
  link: string
  store: PopupTemplatesStore
  trackingPixels?: Pixel[] | undefined

  constructor(store: PopupTemplatesStore, data: ResponsiveTemplateInterface) {
    makeAutoObservable(this, {
      id: false,
      store: false,
    })
    this.store = store
    this.id = data.id
    this.img = data.img
    this.link = data.link
    this.name = data.name
    this.enabled = data.enabled ?? true
    this.trackingPixels = data.trackingPixels
  }

  public delete() {
    this.store.removeTemplate(this)
  }

  public save() {
    if (this.id) return this.store.updateTemplate(this)
    this.store.createTemplate(this)
  }

  get asJson() {
    return {
      popupVersion: this.popupVersion,
      name: this.name,
      enabled: this.enabled,
      id: this.id,
      img: this.img,
      link: this.link,
      trackingPixels: this.trackingPixels,
    }
  }
}
