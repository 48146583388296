/* eslint-disable max-len */
import {
  Card,
  FormInstance,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd"
import { getCountryNameById } from "utils/countryCodes"
import Styles from "./styles.module.css"
import { useStore } from "stores/utils/use-store"
import { useReducer } from "react"
import { TranslationInput } from "./TranslationInput"
import { ContestConfig } from "domains/managingJson/store/types"

type Props = {
  form: FormInstance<any>;
  title: string;
};

export const GeoTranslations = ({ form, title }: Props) => {

  const { dataStore: { managingJsonStore: { selectedElement } } } = useStore()
  const data = selectedElement.data as ContestConfig

  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const countries = data.countries || []
  const countriesWithGlobal = ["global", ...countries]

  const items: TabsProps["items"] = countriesWithGlobal.map(countryName => {
    const country = countryName

    const getTooltipTitle = () => {
      if (country === "global") {
        return "Global is used when no specific country is available."
      }

      return getCountryNameById(country)
    }

    return {
      label: <Tooltip title={getTooltipTitle()}>{country}</Tooltip>,
      key: country,
      children: <TranslationInput title={country+" leaderboard title"} value={data.countryTranslations ? data.countryTranslations[country][title] ?? "" : ""} width={100} />,
    }
  })

  return (
    <Space direction="vertical" size="large" className={Styles.mainSpace}>
      <div key={5} className={Styles.wrapper}>
        <Card
          size="small"
          className={Styles.fullHeightCard}
          title={
            <div className={Styles.cardHeadingWithTooltip}>
              {title}
            </div>
          }
        >
          <div>
            <Tabs items={items} />
          </div>
        </Card>
      </div>
    </Space>
  )
}
