import { EntityToUpdate } from "abstract/entity-to-update"
import { Contest } from "../store/contests.store"
import { apiCall } from "api-calls/api-call"

export const getContests = async(
  filter: string,
  page: number,
  limit: number,
): Promise<{
  data: Contest[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string } = {
    page,
    limit,
  }

  if (filter) query.filter = filter

  return apiCall({
    method: "GET",
    path: "contests",
    query: query,
  })
}

export const updateContest = async(
  id: string,
  data: EntityToUpdate<Contest>,
): Promise<{
  data: Contest;
}> => {
  return apiCall({
    method: "PUT",
    path: `contests/${id}`,
    body: data,
  })
}

export const createContest = async(data: EntityToUpdate<Contest>): Promise<{
  data: Contest;
}> => {
  return apiCall({
    method: "POST",
    path: "contests",
    body: data,
  })
}

export const deleteContest = async(id: string): Promise<{
  data: Contest;
}> => {
  return apiCall({
    method: "DELETE",
    path: `contests/${id}`,
  })
}
