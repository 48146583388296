import React, { FC, useEffect } from "react"
import { observer } from "mobx-react"
import { Tabs } from "antd"

import { useStore } from "stores/utils/use-store"

import { ConfigurationPage } from "domains/managingSpinCollect/components/ConfigurationPage"
import { WheelValuesList } from "domains/managingSpinCollect/components/WheelValuesList"

export const ManagingSpinCollectList: FC = observer(() => {
  const { dataStore: { managingSpinCollectStore } } = useStore()

  useEffect(() => {
    managingSpinCollectStore.getConfiguration()
    managingSpinCollectStore.getFreeValues()
    managingSpinCollectStore.getPaidValues()
  }, [])

  return (
    <Tabs>
      <Tabs.TabPane tab="Wheel values" key="1">
        <WheelValuesList />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Configuration" key="2">
        <ConfigurationPage />
      </Tabs.TabPane>
    </Tabs>
  )
})
