import React, { FC, useState } from "react"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import TextArea from "antd/lib/input/TextArea"

import s from "./styles.module.css"
import { Button, Input } from "antd"
import { notifications } from "notifications"

export const ChallengeProcessorInfo: FC<{ onClose: () => void }> = observer(() => {
  const {
    dataStore: {
      challengesStore,
      challengesStore: { selectedElement },
    },
  } = useStore()
  const [pattern, setPattern] = useState(selectedElement.template)
  const [errorTransition, seterrorTransition] = useState("")
  const [transitionFunctions, setTransiotions] = useState(JSON.stringify(selectedElement.transitionFunctions, null, 2))

  return (
    <div className={s.prettyJson}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>Pattern:</div>
        <Input onChange={e => setPattern(e.target.value)} value={pattern} />
      </div>
      <div>Transition function:</div>
      <TextArea
        onChange={e => {
          seterrorTransition("")
          try {
            console.log(JSON.parse(e.target.value), "!")
          } catch (objError: any) {
            seterrorTransition(`${String(objError?.name)}:${String(objError?.message)} `)
          }
          setTransiotions(e.target.value)
        }}
        value={transitionFunctions}
        style={{ height: "200px" }}
      />
      <div style={{ color: "red" }}>{errorTransition}</div>
      <Button
        style={{ display: "flex", margin: "auto", marginTop: "15px" }}
        type="primary"
        onClick={() => {
          if (pattern.length > 0 && errorTransition.length === 0) {
            challengesStore.funcAfterSuccess = () => {
              notifications.success("Saved!")
            }
            challengesStore.updateGoalTemplate(selectedElement.id, {
              template: pattern,
              transitionFunctions: JSON.parse(transitionFunctions),
            })
          } else if (pattern.length === 0) {
            notifications.error("Please check pattern")
          } else {
            notifications.error("Please check template")
          }
        }}
      >
        Update pattern and transaction
      </Button>
    </div>
  )
})
