import { CopyOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import { notifications } from "notifications"
import { defaultFormat } from "utils/date"

export const codesColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id: string) => {
      return (
        <div
          onClick={() => {
            navigator.clipboard.writeText(id)
            notifications.success("Copied id:" + id)
          }}
          style={{
            background: "#A3A3A3",
            width: 24,
            height: 24,
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <CopyOutlined
            style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }}
          />
        </div>
      )
    },
  },
  {
    title: "Image",
    dataIndex: "imageUrl",
    key: "imageUrl",
    render: (imageUrl: string) => {
      return <img alt="" src={imageUrl} style={{ width: "50px", height: "50px" }} />
    },
  },
  { title: "Name", dataIndex: "name", key: "name" },

  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: createdAt => {
      return <Tag color="green">{defaultFormat(createdAt)}</Tag>
    },
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: updatedAt => {
      return <Tag color="red">{defaultFormat(updatedAt)}</Tag>
    },
  },
  {
    title: "Codes total",
    dataIndex: "numberOfCodes",
    key: "numberOfCodes",
  },
  {
    title: "Free codes",
    dataIndex: "numberOfFreeCodes",
    key: "numberOfFreeCodes",
  },
]
