import { action, observable, reaction, toJS, makeObservable, IReactionDisposer } from "mobx"
import { BaseStore } from "../base.store"
import { RootStore } from "../root.store"

export abstract class PaginationStore<T, T2 = string> extends BaseStore {
  @observable
  public isLoading: boolean = false
  @observable
  public pagination: { page: number; limit: number } = {
      page: 1,
      limit: 10,
    }
  @observable
  public filter: T2
  @observable
  public total: number = 0

  private readonly _activateReaction = (): IReactionDisposer => reaction(
    () => {
      return (
        JSON.stringify(toJS(this.pagination))
            + (typeof this.filter === "object" ? JSON.stringify(this.filter) : this.filter) || ""
      )
    },
    () => {
      this.getData()
    },
  )

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)

    this._activateReaction()
  }

  @action
  nextPage() {
    this.pagination.page += 1
  }

  public abstract getData(): Promise<T[]>;

  @action
    setFilter = (filter: T2) => {
      this.filter = filter
      this.pagination.page = 1
    }
}
