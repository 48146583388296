import { computed, observable, makeObservable } from "mobx"
import { BaseStore } from "stores/base.store"
import {
  getConfiguration,
  updateConfiguration,
  getWheelValues,
  getFreeValues,
  getPaidValues,
  createWheelValue,
  updateWheelValue,
  deleteWheelValue,
} from "domains/managingSpinCollect/api"
import { Configuration, WheelValue } from "domains/managingSpinCollect/store/types"
import { RootStore } from "stores/root.store"

export class ManagingSpinCollectStore extends BaseStore {
  @observable
  private _configuration: Configuration = {} as Configuration

  @observable
  private _selectedElement: WheelValue = {} as WheelValue

  @observable
  private _wheelValues: WheelValue[] = []

  @observable
  private _freeValues: WheelValue[] = []

  @observable
  private _paidValues: WheelValue[] = []

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getConfiguration() {
    this.resetFuncAfterSuccess()
    this._configuration = await this.defaultFunction(getConfiguration)
  }

  public async getWheelValues() {
    this.resetFuncAfterSuccess()
    this._wheelValues = await this.defaultFunction(getWheelValues)
  }

  public async getFreeValues() {
    this.resetFuncAfterSuccess()
    this._freeValues = await this.defaultFunction(getFreeValues)
  }

  public async getPaidValues() {
    this.resetFuncAfterSuccess()
    this._paidValues = await this.defaultFunction(getPaidValues)
  }

  async updateConfiguration(data: Configuration) {
    await this.defaultFunction(updateConfiguration, data)
  }

  async createWheelValue(data: WheelValue) {
    await this.defaultFunction(createWheelValue, data)
  }

  async updateWheelValue(data: WheelValue) {
    await this.defaultFunction(updateWheelValue, data)
  }

  async deleteWheelValue(id: string) {
    await this.defaultFunction(deleteWheelValue, id)
  }

  @computed
  get configuration(): Configuration {
    return this._configuration
  }

  @computed
  get wheelValues(): WheelValue[] {
    return this._wheelValues
  }

  @computed
  get freeValues(): WheelValue[] {
    return this._freeValues
  }

  @computed
  get paidValues(): WheelValue[] {
    return this._paidValues
  }

  @computed
  get selectedElement(): WheelValue {
    return this._selectedElement
  }

  set selectedElement(value: WheelValue) {
    this._selectedElement = value
  }

  public clearSelectedWheelValue() {
    this._selectedElement = {} as WheelValue
  }
}
