import React, { useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Button, Form, Input, Select } from "antd"
import { observer } from "mobx-react"

import { gameNames, gameNamesDesktop } from "abstract/constants/game-ids"
import { PlusCircleOutlined } from "@ant-design/icons"
import { DeleteIconButton } from "components"
import Styles from "./styles.module.css"
import { notifications } from "notifications"

export const EditBlockedGameModes = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()

  const [form] = Form.useForm()
  const [blockedGamesModesObject, setblockedGamesModesObject] = useState<Record<string, string>[]>([
    { "": "" },
  ])
  const [loading, setloading] = useState<boolean>(false)
  const [blockedGamesModesObjectExist, setblockedGamesModesObjectExist] = useState(false)

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const blockedGameModes = managingJsonStore?.itemTypes?.filter(item => item.type === "blockedGameModes")
    if (blockedGameModes && blockedGameModes.length === 1 && blockedGameModes[0]?.data) {
      const obj = Object.entries(blockedGameModes[0]?.data).map(key => {
        const obj = {}
        obj[key[0]] = Array(key[1]).join(",")

        return obj
      })
      console.log(obj)

      setblockedGamesModesObject(obj as any)
      setblockedGamesModesObjectExist(true)
    }
  }, [managingJsonStore.itemTypes, form])

  const updateData = (id: number, game: keyof typeof gameNamesDesktop, value: string) => {
    const newPostionObject = [...blockedGamesModesObject]

    newPostionObject[String(id)] = { [game]: value }

    setblockedGamesModesObject(newPostionObject)
  }

  const onFinishHandler = async() => {
    setloading(true)
    if (blockedGamesModesObject.find(el => Object.values(el)[0].length === 0)) {
      setloading(false)

      return notifications.error("Fill all fields or remove unnecessary")
    }
    const updated = blockedGamesModesObject.map(item => {
      const v = Object.entries(item)[0]
      const obj: any = {}
      console.log(v[1], "v[1]")
      obj[v[0] as any] = v[1].split(",").filter(el => el.length > 0)

      return obj
    })

    const arrForUpdate = Object.assign({}, ...updated)

    blockedGamesModesObjectExist
      ? await managingJsonStore.update({
        name: "blockedGameModes",
        type: "blockedGameModes",
        data: arrForUpdate,
      })
      : await managingJsonStore.create({
        name: "blockedGameModes",
        type: "blockedGameModes",
        data: arrForUpdate,
      })

    setloading(false)
  }

  const deleteValue = (id: number) => {
    const newblockedGamesModesObject = [...blockedGamesModesObject]
    newblockedGamesModesObject.splice(id, 1)
    setblockedGamesModesObject(newblockedGamesModesObject)
  }

  return (
    <div style={{ marginTop: 50 }}>
      {blockedGamesModesObject.map((val, id) => {
        const item = Object.entries(val)[0]

        return (
          <div className={Styles.column} key={item[0]}>
            <Select value={item[0]} onChange={e => updateData(id, Number(e), item[1])}>
              {item[0].length != 0 && (
                <Select.Option value={item[0]}>{gameNames[Number(item[0])]}</Select.Option>
              )}
              {item[0].length === 0
                ? Object.entries(gameNames)
                  .filter(subitem => !blockedGamesModesObject.find(el => Object.keys(el)[0] === subitem[0]))
                  .map(el => <Select.Option key={el[0]} value={el[0]}>{el[1]}</Select.Option>)
                : Object.entries(gameNames)
                  .filter(subitem => !blockedGamesModesObject.find(el => Object.keys(el)[0] === subitem[0]))
                  .map(el => <Select.Option key={el[0]} value={el[0]}>{el[1]}</Select.Option>)}
            </Select>
            <Input
              disabled={item[0].length === 0}
              placeholder="Input modes devide by comma"
              value={item[1]}
              onChange={e => updateData(id, Number(item[0]), e.target.value)}
            />
            <DeleteIconButton onClick={() => deleteValue(id)} />
          </div>
        )
      })}
      <PlusCircleOutlined
        style={{
          cursor: "pointer",
          paddingLeft: "10px",
          paddingTop: "10px",
          transform: "scale(1.5)",
        }}
        onClick={() => {
          setblockedGamesModesObject([...blockedGamesModesObject, { "": "" }])
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", margin: "20px", width: 800 }}>
        <Button type="primary" htmlType="submit" onClick={onFinishHandler} disabled={loading}>
          Save
        </Button>
      </div>
    </div>
  )
})
