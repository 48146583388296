import {
  GetItemsFilter,
  PromocodeResponse,
  ParentCodeType,
  ParentToUpdate,
  ParentPrize,
} from "domains/promocodes/store/types"
import { apiCall } from "api-calls/api-call"

export const getParentCodes = async(
  input: GetItemsFilter,
  page: number,
  limit: number,
): Promise<{ total: number; data: ParentCodeType[] }> => {
  const query: { page: number; limit: number } & { filter: GetItemsFilter } = {
    page,
    limit,
    filter: { ...input },
  }

  return apiCall({
    method: "GET",
    path: "promocodes/parent-codes",
    query,
  })
}

export const createItem = async(input: ParentToUpdate): Promise<PromocodeResponse> => {
  return apiCall({
    method: "POST",
    path: "promocodes/parent-codes",
    body: input,
  })
}

export const updateItem = async(id: string, input: ParentToUpdate): Promise<PromocodeResponse> => {
  return apiCall({
    method: "PUT",
    path: `promocodes/parent-codes/${id}`,
    body: input,
  })
}

export const deleteItem = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `promocodes/parent-codes/${id}`,
  })
}

export const getParentPrizes = async(): Promise<{ total: number; data: ParentPrize[] }> => {
  return apiCall({
    method: "GET",
    path: "promocodes/parent-prizes?page=1&limit=10",
  })
}
