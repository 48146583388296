import { Button, Tag } from "antd"
import { gameNames } from "abstract/constants/game-ids"
import { defaultFormat } from "utils/date"
import { Level } from "domains/challenges/store/types"

const returnGoals = (lvl: any, setupdateGoal) => {
  return lvl[0].goals.map((goal: Level) => {
    return (
      <>
        <div>
          <div>
            <span style={{ fontWeight: "bold" }}>Title:</span> {goal.title}
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>Type:</span> {goal.type}
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>Goal / Reward:</span> {goal.goalValue} /{" "}
            {goal.reward}
          </div>
        </div>
        <Button
          style={{ marginBottom: "10px", marginTop: "10px" }}
          onClick={() => setupdateGoal(goal)}
        >
          Update goal
        </Button>
      </>
    )
  })
}
export const activeChallengesColumns = setupdateGoal => [
  {
    title: "Game id",
    dataIndex: "gameId",
    key: "gameId",
  },
  {
    title: "Game",
    dataIndex: "gameId",
    key: "gameId",
    render: (gameId: number) => {
      return <div>{gameNames[+gameId]}</div>
    },
  },
  {
    title: "Start/end of week",
    dataIndex: "week",
    key: "week",
    render: (week: any) => {
      if (week) {
        return (
          <div style={{ display: "grid", width: "max-content" }}>
            <Tag color="blue">{defaultFormat(week?.startDate)}</Tag>
            <Tag color="red">{defaultFormat(week?.endDate)}</Tag>
          </div>
        )
      }
    },
  },
  {
    title: "Challenges Lvl 1",
    dataIndex: "challenges",
    key: "challenges",
    render: (challenges: { level: number; goals: Level[] }[]) => {
      const lvl = challenges.filter(item => +item.level === 1)

      return returnGoals(lvl, setupdateGoal)
    },
  },
  {
    title: "Challenges Lvl 2",
    dataIndex: "challenges",
    key: "challenges",
    render: (challenges: { level: number; goals: Level[] }[]) => {
      const lvl = challenges.filter(item => +item.level === 2)

      return returnGoals(lvl, setupdateGoal)
    },
  },
  {
    title: "Challenges Lvl 3",
    dataIndex: "challenges",
    key: "challenges",
    render: (challenges: { level: number; goals: Level[] }[]) => {
      const lvl = challenges.filter(item => +item.level === 3)

      return returnGoals(lvl, setupdateGoal)
    },
  },
]
