import {
  ConfigItemAtList,
  ConfigItem,
  ConfigItemResponse,
  NameItem,
  TypeItem,
} from "domains/managingJson/store/types"
import { apiCall } from "api-calls/api-call"

export const getItems = async(): Promise<ConfigItemAtList[]> => {
  return apiCall({
    method: "GET",
    path: "app-config/all",
  })
}

export const createItem = async<K extends ConfigItem = ConfigItem>(input: K): Promise<ConfigItemResponse<K>> => {
  return apiCall({
    method: "POST",
    path: "app-config/create",
    body: input,
  })
}

export const updateItem = async<K extends ConfigItem = ConfigItem>(input: K): Promise<ConfigItemResponse<K>> => {
  return apiCall({
    method: "PUT",
    path: "app-config/update",
    body: input,
  })
}

export const updateItemName = async(input: NameItem): Promise<ConfigItemResponse> => {
  return apiCall({
    method: "PUT",
    path: "app-config/update-name",
    body: input,
  })
}

export const updateItemType = async(input: TypeItem): Promise<ConfigItemResponse> => {
  return apiCall({
    method: "PUT",
    path: "app-config/update-type",
    body: input,
  })
}

export const deleteItem = async(name: string, type: string): Promise<void> => {
  const query: { name: string; type: string } = {
    name,
    type,
  }

  return apiCall({
    method: "DELETE",
    path: "app-config/remove",
    query,
  })
}
