import { computed, observable, transaction, makeObservable } from "mobx"
import { PaginationStore } from "stores/data/pagination.store"
import { RootStore } from "stores/root.store"
import {
  createMarketplaceRegion,
  deleteMarketplaceRegion,
  getMarketplaceRegion,
  updateMarketplaceRegion,
} from "../api"
import { GetItemsFilter, MarketplaceRegion, RegionToUpdate } from "./types"

export class MarketplaceRegionsStore extends PaginationStore<MarketplaceRegion, GetItemsFilter> {
  @observable
  private _regions: MarketplaceRegion[]

  @observable
  private _selectedElement: MarketplaceRegion = {} as MarketplaceRegion

  public updatedLimit: number = 100

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<MarketplaceRegion[]> {
    this.resetFuncAfterSuccess()
    const { page } = this.pagination

    const res = await this.defaultFunction(
      getMarketplaceRegion,
      this.filter,
      page,
      this.updatedLimit,
    )
    transaction(() => {
      this._regions = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: RegionToUpdate) {
    await this.defaultFunction(updateMarketplaceRegion, this._selectedElement.id, data)
  }

  async create(data: RegionToUpdate) {
    await this.defaultFunction(createMarketplaceRegion, data)
  }

  async deleteById(id: string) {
    await this.defaultFunction(deleteMarketplaceRegion, id)
  }

  @computed
  get regions(): MarketplaceRegion[] {
    return this._regions
  }

  @computed
  get selectedElement(): MarketplaceRegion {
    return this._selectedElement
  }

  set selectedElement(value: MarketplaceRegion) {
    this._selectedElement = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as MarketplaceRegion
  }
}
