import { Descriptions } from "antd"
import { FC } from "react"
import { useStore } from "stores/utils/use-store"

export const MarketplaceInfo: FC = () => {
  const {
    dataStore: {
      tierStore,
      marketplaceCategoriesStore,
      itemProviderStore,
      marketplaceStore: {
        selectedElement: {
          name,
          price,
          premiumPrice,
          active,
          howToRedeem,
          description,
          // expire,
          device,
          giftCards,
          tierId,
          isPremium,
          categoryId,
          itemId,
          providerItemId,
          providerItem,
          outOfStockItems,
          type: marketType,
          geoGroup,
          region,
          giftoinCampaignId,
        },
      },
    },
  } = useStore()

  return (
    <Descriptions column={1}>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Name">
        {name}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Device">
        {device || "all devices"}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Is Active?">
        {active ? "Yes" : "No"}
      </Descriptions.Item>

      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Price In Buff Coins">
        {price * 100}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Premium Price In Buff Coins">
        {premiumPrice * 100}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Is Premium Only?">
        {isPremium ? "Yes" : "No"}
      </Descriptions.Item>

      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Type">
        {marketType}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Geo group">
        {geoGroup}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Region">
        {region?.name}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Giftoin Campaign Id">
        {giftoinCampaignId || "Not Specified"}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Description">
        {description}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="How to redeem">
        {howToRedeem}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Category Name">
        {marketplaceCategoriesStore.categories.filter(el => el.id === categoryId)[0]?.name
          || "Not Specified"}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Tier Name">
        {tierStore.tiers.filter(el => el.id === tierId)[0]?.name || "Not Specified"}
      </Descriptions.Item>
      {providerItemId && (
        <>
          <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Item Provider Name">
            {itemProviderStore.itemProviders.filter(el => el.id === providerItemId)[0]?.name
              || "Not Specified"}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ fontWeight: "bold" }}
            label="Item Id in Providers System"
          >
            {itemId}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ fontWeight: "bold" }}
            label="Item Max Price in Providers System"
          >
            {price}
          </Descriptions.Item>
        </>
      )}
      <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Can Be Out Of Stock?">
        {outOfStockItems ? "Yes" : "No"}
      </Descriptions.Item>
      {outOfStockItems && (
        <>
          <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Number Of Daily Restocks">
            {outOfStockItems?.numberOfDailyRestocks}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ fontWeight: "bold" }}
            label="Number Of Items To Purchase To Deactivate"
          >
            {outOfStockItems?.numberOfItemsToDeactivate}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ fontWeight: "bold" }}
            label="Is Out Of Stock Right Now?"
          >
            {outOfStockItems?.isOutOfStock ? "Yes" : "No"}
          </Descriptions.Item>
        </>
      )}
      {providerItem && (
        <>
          <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Item provider">
            {providerItem?.itemProvider.name}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Max price provider">
            {providerItem?.price}
          </Descriptions.Item>

          <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Dedicated items">
            {giftCards}
          </Descriptions.Item>
        </>
      )}
    </Descriptions>
  )
}
