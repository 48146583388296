import { Button, Divider, Form, Input, Modal, Space, Switch, Typography } from "antd"
import { DefaultLinksRouting, UploadComponent } from "components"
import { PixelsEditorV2 } from "components/PixelsEditorV2"
import { Pixel } from "domains/managingJson/store/types"
import { usePopupActivation } from "domains/popup-templates/hooks/usePopupActivation"
import { ResponsiveTemplate } from "domains/popup-templates/store/ResponsiveTemplate.store"
import { usePopupTemplatesContext } from "domains/popup-templates/store/context"
import { runInAction } from "mobx"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"

const { Text, Link } = Typography

type Props = {
  onClose: () => void;
  editedTemplate?: ResponsiveTemplate;
};

type FormStateType = {
  name: string;
  link: string;
  id: number;
  enabled: boolean;
};

export const ResponsivePopupEditModal = observer(({ editedTemplate, onClose }: Props) => {
  const { createTemplate } = usePopupTemplatesContext()
  const [form] = Form.useForm<FormStateType>()
  const [image, setImage] = useState<string>("")
  const [isSaving, setIsSaving] = useState(false)
  const [trackingPixels, setTrackingPixels] = useState<Pixel[] | undefined>(editedTemplate?.trackingPixels)
  const { isActivated, changeActivationStatus, isLoading } = usePopupActivation(editedTemplate?.enabled)


  useEffect(() => {
    if (!editedTemplate) return
    form.setFieldsValue({
      link: editedTemplate.link,
      name: editedTemplate.name,
      id: editedTemplate.id,
      enabled: editedTemplate.enabled,
    })
    setImage(editedTemplate.img)
  }, [form, editedTemplate, isActivated])

  const onFinishHandler = async() => {
    setIsSaving(true)
    if (!image) return
    const data = {
      ...form.getFieldsValue(),
      trackingPixels,
      enabled: isActivated,
      img: image,
    }
    if (editedTemplate) {
      runInAction(() => {
        editedTemplate.img = data.img
        editedTemplate.link = data.link
        editedTemplate.name = data.name
        editedTemplate.trackingPixels = trackingPixels
        editedTemplate.enabled = isActivated
      })
      await editedTemplate.save()
      onClose()

      return
    }
    await createTemplate({ ...data, popupVersion: "responsive" })
    setIsSaving(false)
    onClose()
  }

  const onEnabledChange = async(value: boolean) => {
    changeActivationStatus(value, editedTemplate?.id)
  }

  return (
    <Modal
      open
      title={editedTemplate ? "Edit template" : "Create template"}
      width="700px"
      footer={null}
      onCancel={onClose}
    >
      <Form onFinish={onFinishHandler} form={form}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter popup name",
            },
          ]}
        >
          <Input maxLength={100} />
        </Form.Item>

        <Form.Item label="Enabled" name="_enabled">
          <Switch onChange={onEnabledChange} checked={isActivated} disabled={!editedTemplate || isLoading} />
          {isLoading && <span style={{ marginLeft: "10px" }}>loading...</span>}
          {!editedTemplate && (
            <Text style={{ marginLeft: "1rem" }} type="danger">
              New templates are enabled by default - you can disable it after initial saving
            </Text>
          )}
        </Form.Item>
        <DefaultLinksRouting link={editedTemplate?.link || ""} formItemName="link" />
        <UploadComponent title="Banner" image={image} setImage={setImage} maxSize={1} required />

        <Divider />

        <h2>Tracking pixels</h2>
        <PixelsEditorV2
          pixels={trackingPixels ?? []}
          onChange={values => setTrackingPixels(values)}
        />

        <Divider />

        <Space style={{ marginTop: "1rem" }}>
          <Button onClick={onClose} disabled={isSaving}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isSaving}>
            Save
          </Button>
        </Space>
      </Form>
    </Modal>
  )
})
