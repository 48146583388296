import React, { FC, useEffect, useRef, useState } from "react"
import { DatePicker, Form, Modal, Select } from "antd"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"
import { BillingPeriod, PaymentProviderTypeEnum } from "domains/accounts/store/types"
import moment from "moment"
import { SubmitCancelButtons, FormElement } from "components"

export const GivePremium: FC<{
  visible: boolean;
  onClose: () => void;
}> = observer(({ visible, onClose }) => {
  const formRef: any = useRef()
  const { Option } = Select

  const [selectedProvider, setselectedProvider] = useState<PaymentProviderTypeEnum>()
  const {
    dataStore: {
      accountDetailsStore,
      subscriptionStore: { subscriptions },
      subscriptionStore,
    },
  } = useStore()

  const startsId: Record<PaymentProviderTypeEnum, string> = {
    STRIPE: "cus_",
    PAYPAL: "I-",
    ADMIN: "Admin",
  }

  useEffect(() => {
    subscriptionStore.getData()
  }, [subscriptionStore])

  const onFinishHandler = async(el: {
    subscriptionId: string;
    billingPeriod: BillingPeriod;
    startDate: string;
    paymentProviderType: PaymentProviderTypeEnum;
    billingPlanId: string;
  }) => {
    accountDetailsStore.funcAfterSuccess = async() => {
      onClose()
      await accountDetailsStore.getAccountDetails(accountDetailsStore.id)
    }
    await accountDetailsStore.givePremium(el)
  }

  return (
    <Modal visible={visible} title="Give Premium" footer={null} onCancel={() => onClose()}>
      <Form onFinish={onFinishHandler} ref={formRef}>
        <Form.Item
          label="Subscription"
          name="subscriptionId"
          rules={[
            {
              required: true,
              message: "Please input Key!",
            },
          ]}
        >
          <Select>
            {subscriptions?.map(el => (
              <Option value={el.id} key={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Billing Period"
          name="billingPeriod"
          rules={[
            {
              required: true,
              message: "Please input Amount!",
            },
          ]}
        >
          <Select>
            <Option value={BillingPeriod.DAY} key={0}>
              Day
            </Option>
            <Option value={BillingPeriod.MONTH} key={1}>
              Month
            </Option>
            <Option value={BillingPeriod.YEAR} key={2}>
              Year
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Starts At"
          name="startDate"
          rules={[
            {
              required: true,
              message: "Please Select Start Date!",
            },
          ]}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD"
            placeholder="Select Date"
            disabledDate={current => {
              return moment() < current
            }}
          />
        </Form.Item>
        <Form.Item
          label="Payment Provider Type"
          name="paymentProviderType"
          rules={[
            {
              required: true,
              message: "Please select Provider!",
            },
          ]}
        >
          <Select
            onChange={(item: PaymentProviderTypeEnum) => {
              setselectedProvider(item)
              formRef.current.setFieldsValue({ billingPlanId: startsId[item] })
              if (item === PaymentProviderTypeEnum.ADMIN) {
                formRef.current.setFieldsValue({ billingPlanId: `Admin-${moment.now()}` })
              }
            }}
          >
            <Option value={PaymentProviderTypeEnum.STRIPE} key={0}>
              STRIPE
            </Option>
            <Option value={PaymentProviderTypeEnum.PAYPAL} key={1}>
              PAYPAL
            </Option>
            <Option value={PaymentProviderTypeEnum.ADMIN} key={2}>
              ADMIN
            </Option>
          </Select>
        </Form.Item>

        <FormElement
          type="input"
          label="Blling Plan Id"
          name="billingPlanId"
          required
          props={{ disabled: selectedProvider === PaymentProviderTypeEnum.ADMIN }}
        />

        <SubmitCancelButtons onClose={onClose} isLoading={accountDetailsStore.isLoading} />
      </Form>
    </Modal>
  )
})
