import React, { FC } from "react"

import { observer } from "mobx-react"
import { FormElement } from "components"
import { PlusCircleOutlined } from "@ant-design/icons"
import { Button } from "antd"

export const LaunchGameOnlineMode: FC<{
  form: any;
  texts: { title: string; subtitle: string }[];
  settexts: any;
}> = observer(({ form, texts, settexts }) => {
  const deleteFunc = (id: number, dependingData: any) => {
    const arr = [...dependingData].filter((_, idx) => idx !== id)

    const onb = {}

    let idx = 0
    let idx2 = 0
    dependingData.forEach(() => {
      if (idx2 === id) {
        onb[`title${idx2}`] = ""
        onb[`subtitle${idx2}`] = ""
        idx2++

        return
      }

      onb[`title${idx}`] = form.getFieldValue(`title${idx2}`)
      onb[`subtitle${idx}`] = form.getFieldValue(`subtitle${idx2}`)

      if (idx2 === dependingData.length - 1) {
        onb[`title${idx2}`] = ""
        onb[`subtitle${idx2}`] = ""
      }
      idx++
      idx2++
    })
    settexts(arr)

    form.setFieldsValue({ ...onb })
  }

  return (
    <>
      {texts?.map((item, id) => (
        <>
          <FormElement
            label={`title ${id + 1}`}
            name={`title${id}`}
            type="input"
            required
            style={{ width: "100%" }}
            initialValue={item?.title}
            props={{ style: { width: "100%" } }}
          />
          <FormElement
            label={`subtitle ${id + 1}`}
            name={`subtitle${id}`}
            type="input"
            required
            style={{ width: "100%" }}
            initialValue={item?.subtitle}
            props={{ style: { width: "100%" } }}
          />
          <Button
            style={{ display: "flex", margin: "auto", marginBottom: "14px" }}
            onClick={() => deleteFunc(id, texts)}
          >
            Delete texts {id + 1}
          </Button>
        </>
      ))}
      <div
        onClick={() => settexts([...texts, { title: "", subtitle: "" }])}
        style={{
          display: "flex",
          gap: "10px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
      >
        <div>Add texts</div>
        <PlusCircleOutlined style={{ transform: "scale(1.5)" }} />
      </div>
    </>
  )
})
