import React, { FC } from "react"

import { Divider } from "antd"
import { FormElement } from "components"

export const Rewards: FC<{ data?: any }> = ({ data }) => {
  return (
    <>
      <Divider>Rewards data </Divider>
      <div>
        <FormElement
          type="input"
          label="claimButton"
          name={["rewards", "claimButton"]}
          initialValue={data?.rewards?.claimButton}
        />
        <FormElement
          type="input"
          label="claimedButton"
          name={["rewards", "claimedButton"]}
          initialValue={data?.rewards?.claimedButton}
        />
        <FormElement
          type="input"
          label="supportButton"
          name={["rewards", "supportButton"]}
          initialValue={data?.rewards?.supportButton}
        />
        <FormElement
          type="input"
          label="faqButton"
          name={["rewards", "faqButton"]}
          initialValue={data?.rewards?.faqButton}
        />
        <FormElement
          type="input"
          label="footerText"
          name={["rewards", "footerText"]}
          initialValue={data?.rewards?.footerText}
        />
        <FormElement
          type="input"
          label="Footer asterix note"
          name={["rewards", "footerAsterixNote"]}
          initialValue={data?.rewards?.footerAsterixNote}
        />
        <h4>Head table sell titles</h4>
        <FormElement
          type="input"
          label="Date"
          name={["rewards", "head", "date"]}
          initialValue={data?.rewards?.head?.date}
        />
        <FormElement
          type="input"
          label="Event"
          name={["rewards", "head", "event"]}
          initialValue={data?.rewards?.head?.event}
        />
        <FormElement
          type="input"
          label="Reward"
          name={["rewards", "head", "reward"]}
          initialValue={data?.rewards?.head?.reward}
        />
        <h4>Empty Page</h4>
        <FormElement
          type="input"
          label="title"
          name={["rewards", "emptyPage", "title"]}
          initialValue={data?.rewards?.emptyPage?.title}
        />
        <FormElement
          type="input"
          label="subtitle"
          name={["rewards", "emptyPage", "subtitle"]}
          initialValue={data?.rewards?.emptyPage?.subtitle}
        />
        <Divider>Invites popup </Divider>
        <FormElement
          type="input"
          label="header"
          name={["invitePopup", "header"]}
          initialValue={data?.invitePopup?.header}
        />
        <FormElement
          type="input"
          label="body"
          name={["invitePopup", "body"]}
          initialValue={data?.invitePopup?.body}
        />
        <FormElement
          type="input"
          label="disclaimer"
          name={["invitePopup", "disclaimer"]}
          initialValue={data?.invitePopup?.disclaimer}
        />
        <FormElement
          type="input"
          label="inviteesLeft"
          name={["invitePopup", "inviteesLeft"]}
          initialValue={data?.invitePopup?.inviteesLeft}
        />
        <FormElement
          type="input"
          label="completedInvitees"
          name={["invitePopup", "completedInvitees"]}
          initialValue={data?.invitePopup?.completedInvitees}
        />
        <Divider>Tooltips</Divider>
        <FormElement
          type="input"
          label="tooltip twitter"
          name={["tooltipData", "twitterSharingText"]}
          initialValue={data?.tooltipData?.twitterSharingText}
        />
        <FormElement
          type="input"
          label="tooltip reward"
          name={["tooltipData", "reward"]}
          initialValue={data?.tooltipData?.reward}
        />
      </div>
    </>
  )
}
