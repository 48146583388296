import { Input } from "antd"
import { ChangeEvent, FC } from "react"

type EditableCellProps = {
  index: number;
  text: string;
  editText: (index: number, text: string) => void;
};

export const EditableCell: FC<EditableCellProps> = ({ index, text, editText }) => {
  const edit = (e: ChangeEvent<HTMLInputElement>) => editText(index, e.target.value)

  return (
    <Input type="text" autoFocus placeholder="Add tooltip text" value={text} onChange={edit} />
  )
}
