import React, { useEffect, useState } from "react"
import { useStore } from "stores/utils/use-store"

import { Button, Form } from "antd"
import { observer } from "mobx-react"

import { notifications } from "notifications"
import { UploadComponent } from "components"
import { DynamicUIType } from "domains/managingJson/store/types"
import Checkbox from "antd/lib/checkbox/Checkbox"

export const MobileDynamicUI = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()
  const [form] = Form.useForm()
  const [prevData, setPrevData] = useState<DynamicUIType>()
  const [dynamicUI, setdynamicUI] = useState(false)

  const [mobileTopbarMaskBool, setMobileTopbarMaskBool] = useState(false)
  const [mobileTopbarMask, setMobileTopbarMask] = useState("")

  const [mobileMarketplaceItemMaskBool, setMobileMarketplaceItemMaskBool] = useState(false)
  const [mobileMarketplaceItemMask, setMobileMarketplaceItemMask] = useState("")

  const [mobileSidebarMaskBool, setMobileSidebarMaskBool] = useState(false)
  const [mobileSidebarMask, setMobileSidebarMask] = useState("")

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  useEffect(() => {
    const dynamicUIData = managingJsonStore?.itemTypes?.filter(item => item.type === "dynamicUI")
    if (dynamicUIData && dynamicUIData.length > 0) {
      const data = dynamicUIData[0].data as DynamicUIType
      setdynamicUI(true)
      setPrevData(data)

      if (data.mobileTopbarMask) {
        setMobileTopbarMaskBool(true)
        setMobileTopbarMask(data.mobileTopbarMask)
      }
      if (data.mobileMarketplaceItemMask) {
        setMobileMarketplaceItemMaskBool(true)
        setMobileMarketplaceItemMask(data.mobileMarketplaceItemMask)
      }
      if (data.mobileSidebarMask) {
        setMobileSidebarMaskBool(true)
        setMobileSidebarMask(data.mobileSidebarMask)
      }
    }
  }, [managingJsonStore.itemTypes])

  const onFinishHandler = async() => {
    const data: DynamicUIType = { ...prevData }
    data.mobileTopbarMask = (mobileTopbarMaskBool && mobileTopbarMask) || undefined
    data.mobileMarketplaceItemMask
      = (mobileMarketplaceItemMaskBool && mobileMarketplaceItemMask) || undefined
    data.mobileSidebarMask = (mobileSidebarMaskBool && mobileSidebarMask) || undefined

    managingJsonStore.funcAfterSuccess = async() => {
      notifications.success("Saved successfully")
      await managingJsonStore.getData()
    }

    dynamicUI
      ? await managingJsonStore.update({
        name: "dynamicUI",
        type: "dynamicUI",
        data,
      })
      : await managingJsonStore.create({
        name: "dynamicUI",
        type: "dynamicUI",
        data,
      })
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <div style={{ display: "flex", flexDirection: "column", gap: 8, marginBottom: 32 }}>
        <div>
          <Checkbox
            style={{ userSelect: "none", marginBottom: 8 }}
            onChange={() => setMobileTopbarMaskBool(prev => !prev)}
            checked={mobileTopbarMaskBool}
          >
            Mobile Topbar Mask
          </Checkbox>
          {mobileTopbarMaskBool && (
            <div style={{ display: "flex", gap: "10px", marginBottom: 16, marginLeft: 24 }}>
              <UploadComponent
                image={mobileTopbarMask || ""}
                setImage={setMobileTopbarMask}
                placeholder="Click to upload"
                title="Mobile Topbar Mask Image"
              />
            </div>
          )}
        </div>

        <div>
          <Checkbox
            style={{ userSelect: "none", marginBottom: 8 }}
            onChange={() => setMobileMarketplaceItemMaskBool(prev => !prev)}
            checked={mobileMarketplaceItemMaskBool}
          >
            Mobile Marketplace Item Mask
          </Checkbox>
          {mobileMarketplaceItemMaskBool && (
            <div style={{ display: "flex", gap: "10px", marginBottom: 16, marginLeft: 24 }}>
              <UploadComponent
                image={mobileMarketplaceItemMask || ""}
                setImage={setMobileMarketplaceItemMask}
                placeholder="Click to upload"
                title="Mobile Marketplace Item Mask Image"
              />
            </div>
          )}
        </div>

        <div>
          <Checkbox
            style={{ userSelect: "none", marginBottom: 8 }}
            onChange={() => setMobileSidebarMaskBool(prev => !prev)}
            checked={mobileSidebarMaskBool}
          >
            Mobile Sidebar Item Mask
          </Checkbox>
          {mobileSidebarMaskBool && (
            <div style={{ display: "flex", gap: "10px", marginBottom: 16, marginLeft: 24 }}>
              <UploadComponent
                image={mobileSidebarMask || ""}
                setImage={setMobileSidebarMask}
                placeholder="Click to upload"
                title="Mobile Sidebar Item Mask Image"
              />
            </div>
          )}
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 480 }}>
        <Button
          type="primary"
          onClick={() => {
            setMobileTopbarMask("")
            setMobileTopbarMaskBool(false)
            setMobileMarketplaceItemMask("")
            setMobileMarketplaceItemMaskBool(false)
            setMobileSidebarMask("")
            setMobileSidebarMaskBool(false)
          }}
        >
          Reset all
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            cursor: managingJsonStore.isLoading ? "not-allowed" : "auto",
            backgroundColor: managingJsonStore.isLoading ? "#d9d9d9" : "#1690ff",
            borderColor: managingJsonStore.isLoading ? "#d9d9d9" : "#1690ff",
          }}
          disabled={managingJsonStore.isLoading}
        >
          Save
        </Button>
      </div>
    </Form>
  )
})
