import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"

import { Modal, Switch, Checkbox, Divider, Radio, Button } from "antd"
import { MarketplaceCategory, ItemProvider } from "domains/marketplace/store/types"
import { Tier } from "stores/primitives/tier"

const CheckboxGroup = Checkbox.Group

interface IMarketplaceFilter {
  setIsVisibleFilter: (arg: boolean) => void;
  isVisibleFilter: boolean;
}

export const MarketplaceFilter: FC<IMarketplaceFilter> = observer(({ setIsVisibleFilter, isVisibleFilter }) => {
  const { dataStore: { marketplaceStore, itemProviderStore, marketplaceCategoriesStore, tierStore } } = useStore()

  const [category, setCategory] = useState<Array<string>>([])
  const [device, setDevice] = useState<Array<string>>([])
  const [tiers, setTiers] = useState<Array<string>>([])
  const [itemProvider, setItemProvider] = useState<Array<string>>([])

  const [isActive, setIsActive] = useState<"ALL" | "DISABLED_ONLY" | "ACTIVE_ONLY" | undefined>(undefined)
  const [isAlphabet, setIsAlphabet] = useState<boolean>(false)

  const [categoryOptions, setCategoryOptions] = useState<MarketplaceCategory[]>([])
  const [type, settype] = useState<("REGULAR" | "BUFF_PAY" | "PREMIUM")[]>([])
  const [itemProviderOptions, setitemProviderOptions] = useState<ItemProvider[]>([])
  const [tiersOptions, settiersOptions] = useState<Tier[]>([])

  const deviceOptions = ["Android", "IOS", "PC"]

  useEffect(() => {
    async function callAwait() {
      await marketplaceCategoriesStore.getData()
      setCategoryOptions(marketplaceCategoriesStore.categories)

      const tierData: Tier[] = await tierStore.getData()
      settiersOptions(tierData)

      const itemProviderData: ItemProvider[] = await itemProviderStore.getData()
      setitemProviderOptions(itemProviderData)
    }
    callAwait()

    return () => {
      marketplaceStore.setFilter({})
    }
  }, [])
  const onChangeCategory = (checkedValues: any) => {
    setCategory(checkedValues)
  }

  const onChangeDevice = (checkedValues: any) => {
    setDevice(checkedValues)
  }

  const onChangeItemProvider = (checkedValues: any) => {
    setItemProvider(checkedValues)
  }

  const onChangeTiers = (checkedValues: any) => {
    setTiers(checkedValues)
  }

  const editValues = (arr: string[] | undefined) => {
    if (arr !== undefined) {
      const acc: string[] = []
      arr.forEach(item => {
        acc.push(item.toLowerCase())
      })

      return acc
    } else {
      return arr
    }
  }

  const sendIds = (arr: string[] | undefined, fromStoreObj: any) => {
    if (arr !== undefined) {
      const acc: string[] = []
      arr.forEach(item => {
        const findByName = fromStoreObj.find((i: any) => i.name === item)
        acc.push(findByName.id)
      })

      return acc
    } else {
      return arr
    }
  }

  const onClick = async() => {
    const sortOrder = isAlphabet ? "ASC" : "DESC"

    const filters: any = {}

    if (isActive !== undefined) {
      filters.active = isActive
    }
    if (type !== undefined) {
      filters.type = type
    }
    const cat = sendIds(category, categoryOptions)
    if (cat !== undefined && cat.length > 0) {
      filters.categories = cat
    }
    const dev = editValues(device)
    if (dev !== undefined && dev.length > 0) {
      filters.device = dev
    }
    const providers = sendIds(itemProvider, itemProviderOptions)
    if (providers !== undefined && providers.length > 0) {
      filters.itemProviders = providers
    }
    const tie = sendIds(tiers, tiersOptions)

    if (tie !== undefined && tie.length > 0) {
      filters.tiers = tie
    }
    filters.sortOrder = sortOrder
    await marketplaceStore.setFilter(filters)
    setIsVisibleFilter(false)
  }
  const reset = () => {
    setCategory([])
    setDevice([])
    setTiers([])
    setItemProvider([])
    settype([])
    setIsActive(undefined)
    setIsAlphabet(false)
  }
  console.log(category, categoryOptions, "categoryOptions")

  return (
    <Modal
      width="700px"
      bodyStyle={{
        width: 800,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      visible={isVisibleFilter}
      onOk={onClick}
      onCancel={() => setIsVisibleFilter(false)}
    >
      <Divider orientation="left">Category</Divider>
      <CheckboxGroup
        value={category}
        options={categoryOptions?.map(item => item.name)}
        onChange={onChangeCategory}
        style={{ width: "70%", textAlign: "center" }}
      />
      <Divider orientation="left">Devices</Divider>
      <CheckboxGroup value={device} options={deviceOptions} onChange={onChangeDevice} />
      <Divider orientation="left">Item Provider</Divider>
      <CheckboxGroup
        value={itemProvider}
        options={itemProviderOptions.map(item => item.name)}
        onChange={onChangeItemProvider}
      />
      <Divider orientation="left">Tiers</Divider>
      <CheckboxGroup
        value={tiers}
        options={tiersOptions.map(item => item.name)}
        onChange={onChangeTiers}
      />
      <Divider orientation="left">Premium</Divider>
      <CheckboxGroup
        value={type}
        options={["REGULAR", "BUFF_PAY", "PREMIUM"]?.map(item => item)}
        onChange={(e: any) => settype(e)}
        style={{ width: "70%", textAlign: "center" }}
      />

      <Divider orientation="left">Status</Divider>
      <Radio.Group
        value={isActive}
        defaultValue={undefined}
        onChange={e => {
          setIsActive(e.target.value)
        }}
      >
        <Radio.Button value="ALL">All</Radio.Button>
        <Radio.Button value="DISABLED_ONLY">Disabled</Radio.Button>
        <Radio.Button value="ACTIVE_ONLY">Active</Radio.Button>
      </Radio.Group>
      <Divider orientation="left" />
      <Switch
        checkedChildren="Alphabet: ASC"
        unCheckedChildren="Alphabet: DESC"
        style={{ marginRight: 8, marginLeft: 8 }}
        defaultChecked={isAlphabet}
        onChange={setIsAlphabet}
        checked={isAlphabet}
      />
      <Divider orientation="left" />

      <Button onClick={reset}>Reset</Button>
    </Modal>
  )
})
