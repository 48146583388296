import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"
import { Button, Modal, Select, Table, Form, Tag } from "antd"
import { DownloadOutlined } from "@ant-design/icons"

import { Column } from "abstract/types/column.type"

import { DeleteIconButton, EditIconButton, showDeleteConfirm, CreateNewElement } from "components"

import {
  ConfigItemAtList,
  ConfigItemType,
  configItemTypes,
} from "domains/managingJson/store/types"
import {
  EditIFrames,
  EditIFrameButtons,
  EditName,
  EditType,
  EditAvatar,
  EditTexts,
} from "domains/managingJson/components"
import { EditContest } from "../EditContest"
import Search from "antd/es/input/Search"
import { useDebouncedCallback } from "use-debounce"
import { defaultFormat } from "utils/date"

const filteredTypesFromFullList = [
  "navbarAvatarTabs",
  "dynamicUI",
  "inGameBanners",
  "adsBanners",
  "positionsIngameScreens",
]

export const ManagingJsonList: FC = observer(() => {
  const { dataStore: { managingJsonStore } } = useStore()

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)
  const [configType, setConfigType] = useState<ConfigItemType | null>(null)
  const [isEditNameTypeModal, setEditNameTypeModal] = useState<"name" | "type" | null>(null)
  const [valSearch, setValSearch] = useState<string>("")
  const [valFilter, setValFilter] = useState<string>("")

  useEffect(() => {
    managingJsonStore.getData()
  }, [managingJsonStore])

  const onClose = () => {
    managingJsonStore.clearSelectedElement()
    setEditCreateVisibility(null)
    setConfigType(null)
    setEditNameTypeModal(null)
  }

  const debounce = useDebouncedCallback(async(val: string) => {
    setValSearch(val)
  }, 2000)

  useEffect(() => {
    if (!valSearch || valSearch.length === 0) {
      managingJsonStore.itemTypes = managingJsonStore?.itemTypesFromBackend
    } else {
      managingJsonStore.itemTypes = managingJsonStore?.itemTypesFromBackend?.filter(item => item.name.includes(valSearch))
    }
  }, [valSearch, managingJsonStore.itemTypesFromBackend])

  useEffect(() => {
    if (!valFilter || valFilter.length === 0) {
      managingJsonStore.itemTypes = managingJsonStore?.itemTypesFromBackend
    } else {
      managingJsonStore.itemTypes = managingJsonStore?.itemTypesFromBackend?.filter(item => item.type === valFilter)
    }
  }, [valFilter, managingJsonStore.itemTypesFromBackend])

  type ColumnUpd = Column & { sorter?: boolean };
  const columnsCurrent: ColumnUpd[] = [
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (value: string, row: ConfigItemAtList) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <EditIconButton
            onClick={e => {
              e.stopPropagation()
              setEditNameTypeModal("name")
              managingJsonStore.selectedElement = row
            }}
          />
          {value}
        </div>
      ),
    },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (el: Date) => <Tag color="green">{defaultFormat(el)}</Tag>,
    },
    {
      title: "Updated At",
      key: "updatedAt",
      dataIndex: "updatedAt",
      render: (el: Date) => <Tag color="blue">{defaultFormat(el)}</Tag>,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (_: any, row: ConfigItemAtList) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()
              showDeleteConfirm(`market item: ${row.name}`, undefined, async() => {
                managingJsonStore.funcAfterSuccess = async() => await managingJsonStore.getData()

                managingJsonStore.deleteByName(row.name, row.type)
              })
            }}
          />
          <Button
            type="primary"
            onClick={e => {
              e.stopPropagation()
              const url = window.URL.createObjectURL(new Blob([JSON.stringify(row.data)]))
              const link = document.createElement("a")
              link.href = url
              link.setAttribute("download", "data.json")
              document.body.appendChild(link)
              link.click()
              link.parentNode?.removeChild(link)
            }}
            icon={<DownloadOutlined />}
          />
        </div>
      ),
    },
  ]

  const handleSelectType = (e: ConfigItemType) => {
    setConfigType(e)
  }

  return (
    <>
      <div style={{ display: "flex", gap: 20 }}>
        <CreateNewElement
          onClick={() => {
            setEditCreateVisibility("create")
          }}
          valueText="config"
        />
        <Button
          href="https://fulcrumrocks.atlassian.net/wiki/spaces/BUFF/pages/1021018118/JSON+manager+at+Admin+Panel"
          target="_blank"
          rel="noopener noreferrer"
        >
          Documentation
        </Button>

        <Search
          placeholder="Write name for search ..."
          allowClear
          enterButton
          style={{ width: "80%" }}
          value={valSearch}
          onChange={e => {
            const val = e.target.value
            setValSearch(val)
            debounce(val)
          }}
          onSearch={(value: string) => {
            setValSearch(value)
          }}
        />
      </div>
      Filter by type: {"   "}
      <Select
        onChange={setValFilter}
        value={valFilter}
        style={{ width: "200px", marginBottom: "10px" }}
      >
        {Object.keys(configItemTypes).map(item => {
          return (
            <Select.Option key={item} value={item} disabled={filteredTypesFromFullList.includes(item)} >
              {item}
            </Select.Option>
          )
        })}
      </Select>
      <Table
        dataSource={managingJsonStore?.itemTypes?.filter(item => !filteredTypesFromFullList.includes(item.type))}
        columns={columnsCurrent as any}
        rowKey="id"
        onRow={(record: ConfigItemAtList) => ({
          onClick: () => {
            managingJsonStore.selectedElement = record
            setEditCreateVisibility("edit")
            setConfigType(record.type)
          },
        })}
        loading={managingJsonStore.isLoading}
      />
      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit item" : "Create item"}
          footer={null}
          onCancel={onClose}
          width={configType === "contests" ? "800px" : "700px"}
          style={{ top: "10px" }}
        >
          <Form>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please input name!",
                },
              ]}
              style={{ width: "100%" }}
              initialValue={configType}
            >
              <Select onChange={handleSelectType} disabled={isVisibleEditCreate === "edit"}>
                {Object.keys(configItemTypes).map(el => {
                  return (
                    <Select.Option key={el} value={el} disabled={filteredTypesFromFullList.includes(el)} >
                      {el}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Form>
          {configType === "iframes" && (
            <EditIFrames editType={isVisibleEditCreate} onClose={onClose} configType={configType} />
          )}
          {configType === "buttons" && (
            <EditIFrameButtons
              editType={isVisibleEditCreate}
              onClose={onClose}
              configType={configType}
            />
          )}
          {configType === "contests" && (
            <EditContest editType={isVisibleEditCreate} onClose={onClose} configType={configType} />
          )}
          {configType === "avatar" && (
            <EditAvatar editType={isVisibleEditCreate} onClose={onClose} configType={configType} />
          )}

          {configType === "texts" && (
            <EditTexts editType={isVisibleEditCreate} onClose={onClose} configType={configType} />
          )}
        </Modal>
      )}
      {isEditNameTypeModal === "name" && <EditName onClose={onClose} />}
      {isEditNameTypeModal === "type" && <EditType onClose={onClose} />}
    </>
  )
})
