import React, { FC, useState } from "react"
import { observer } from "mobx-react"
import { Modal, Form, Input, Tag } from "antd"
import { useStore } from "stores/utils/use-store"

import { Subscription } from "domains/subscription/store/types"

import Styles from "./styles.module.css"
import { SubmitCancelButtons } from "components"

const DESCRIPTION_CHAR_LIMIT = 300

type SubscriptionItemProps = {
  isVisibleInfo: boolean;
  setInfoVisibility: (arg: boolean) => void;
};

export const SubscriptionItem: FC<SubscriptionItemProps> = observer(({ isVisibleInfo, setInfoVisibility }) => {
  const {
    dataStore: {
      subscriptionStore,
      subscriptionStore: {
        selectedElement,
        selectedElement: { description, tags },
      },
    },
  } = useStore()
  const [form] = Form.useForm()
  const [inputVisible, setInputVisible] = useState<boolean>(false)
  const [newTag, setNewTag] = useState<string>("")

  const onClose = () => {
    setInfoVisibility(false)
    subscriptionStore.clearSelectedElement()
    form.resetFields()
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const hideInput = () => {
    setInputVisible(false)
    setNewTag("")
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setNewTag(value)
  }

  const handleInputConfirm = () => {
    if (newTag) {
      selectedElement.tags = [...selectedElement.tags, newTag]
    }
    hideInput()
  }

  const handleRemoveTag = (tag: string) => {
    selectedElement.tags = selectedElement.tags?.filter(item => item !== tag)
  }

  const onFinishHandler = async(event: Subscription) => {
    subscriptionStore.funcAfterSuccess = async() => {
      onClose()
      await subscriptionStore.getData()
    }
    await subscriptionStore.update({
      ...selectedElement,
      description: event.description,
    })
  }

  return (
    <Modal
      visible={isVisibleInfo}
      onCancel={onClose}
      width={600}
      bodyStyle={{ paddingTop: 60 }}
      footer={null}
    >
      <Form onFinish={onFinishHandler} form={form}>
        <Form.Item label="Tags" name="tags">
          {tags?.map((item, index) => (
            <Tag key={`${item}${index}`} closable onClose={() => handleRemoveTag(item)}>
              {item}
            </Tag>
          ))}
          {inputVisible && (
            <Input
              type="text"
              size="small"
              value={newTag}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              style={{ width: "150px" }}
              maxLength={20}
            />
          )}
          {!inputVisible && tags.length === 0 && (
            <Tag onClick={showInput} style={{ cursor: "pointer" }}>
              + New Tag
            </Tag>
          )}
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          initialValue={description}
          className={Styles.flexTextarea}
          rules={[
            {
              required: true,
              message: "Input description!",
            },
          ]}
        >
          <Input.TextArea
            className={Styles.textarea}
            showCount
            autoSize={{ minRows: 6 }}
            maxLength={DESCRIPTION_CHAR_LIMIT}
          />
        </Form.Item>
        <div style={{ fontSize: 13 }}>
          Please make sure that the text/lines will fit in the buff app (no guarantee based on
          char count)
        </div>
        <SubmitCancelButtons onClose={onClose} isLoading={subscriptionStore.isLoading} />
      </Form>
    </Modal>
  )
})
