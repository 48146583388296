import React, { FC } from "react"
import { Button, Form, Input } from "antd"

import { getJwtToken } from "api-calls/auth.calls"
import { useStore } from "stores/utils/use-store"

export const LoginForm: FC = () => {
  const { dataStore: { authStore } } = useStore()

  const onFinishHandler = async(data: { username: string; password: string }) => {
    const { username, password } = data

    authStore.token = await getJwtToken(username, password)
  }

  const onFinishFailedHandler = (value: any) => {
    console.log(value)
  }

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinishHandler}
      onFinishFailed={onFinishFailedHandler}
      style={{
        transform: "translate(50%, 100%)",
        position: "absolute",
        width: "50%",
      }}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
        ]}
        style={{ justifyContent: "center" }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        style={{ justifyContent: "center" }}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item style={{ display: "grid", placeContent: "center" }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
