import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, makeObservable, transaction } from "mobx"
import { RootStore } from "stores/root.store"
import { SubCodesInventoryType } from "./types"
import { createSubCodes, getSubCode, deleteSubCodes, createSubCodesCsv } from "../api"

export class SubCodesStore extends PaginationStore<SubCodesInventoryType, any> {
  @observable
  private _subCodesInventory: SubCodesInventoryType[]

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<SubCodesInventoryType[]> {
    return null as any
  }
  async getSubCodes(codeId: string): Promise<SubCodesInventoryType[]> {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getSubCode, this.pagination.page, codeId)
    if (res) {
      transaction(() => {
        this.subCodesInventory = res?.data
        this.total = res?.total
      })
    }

    return res
  }

  async createSubCodes(body: { codeInfoId: string; codes: string[] }) {
    await this.defaultFunction(createSubCodes, body)
  }

  async createSubCodesCsv(codeInfoId: string, body: FormData) {
    await this.defaultFunction(createSubCodesCsv, codeInfoId, body)
  }

  async deleteCode(id: string) {
    await this.defaultFunction(deleteSubCodes, id)
  }

  @computed
  get subCodesInventory(): SubCodesInventoryType[] {
    return this._subCodesInventory
  }

  set subCodesInventory(value: SubCodesInventoryType[]) {
    this._subCodesInventory = value
  }
}
