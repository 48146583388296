import { FC, useState, useEffect } from "react"
import { observer } from "mobx-react"
import { Table } from "antd"

import { useStore } from "stores/utils/use-store"

import { SubscriptionItem } from "domains/subscription/components/SubscriptionItem"
import { subscriptionColumns } from "domains/subscription/components/SubscriptionList/columns"

export const SubscriptionList: FC = observer(() => {
  const {
    dataStore: {
      subscriptionStore,
      subscriptionStore: { subscriptions },
    },
  } = useStore()
  const [isVisibleInfo, setInfoVisibility] = useState<boolean>(false)

  useEffect(() => {
    subscriptionStore.getData()
  }, [subscriptionStore])

  return (
    <>
      <Table
        dataSource={subscriptions}
        columns={subscriptionColumns}
        rowKey="id"
        onRow={record => ({
          onClick: () => {
            setInfoVisibility(true)
            subscriptionStore.selectedElement = record
          },
        })}
      />
      {isVisibleInfo && (
        <SubscriptionItem setInfoVisibility={setInfoVisibility} isVisibleInfo={isVisibleInfo} />
      )}
    </>
  )
})
