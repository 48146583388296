import React, { FC, useState } from "react"
import { Form } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { FormElement, SubmitCancelButtons } from "components"
import { ContestConfig } from "domains/managingJson/store/types"

export const ContestEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      managingJsonStore: { selectedElement: { name, data } },
      managingJsonStore,
    },
  } = useStore()
  const [form] = Form.useForm()
  const [check, setCheck] = useState<"SCORE_BASED" | "CHALLENGE_BASED" | undefined>(editType === "edit"
    ? data["contestType"] : "SCORE_BASED")

  const onFinishHandler = async(e: {name: string, "pretty name": string}) => {
    managingJsonStore.funcAfterSuccess = async() => {
      onClose()
      form.resetFields()
      await managingJsonStore.getData()
    }
    const contest = { type: "contests" as any, name: e.name, data: (editType === "edit" ? data : new Object()) as ContestConfig }
    contest["data"]["prettyName"] = e["pretty name"]
    contest["data"]["contestType"] = check
    if (editType === "edit") {
      await managingJsonStore.update(contest)
    } else {
      contest.data.countries = []
      contest.data.loungePageData = { games: [] }
      contest.data.freePoints = []
      contest.data.contestLiveSchedule = []
      contest.data.topBarLogo = ""
      contest.data.mainLogo = ""
      contest.data.navigationLogo = ""
      contest.data.settingsLogo = ""
      contest.data.advImage = ""
      contest.data.startDate = new Date()
      contest.data.endDate = new Date()
      contest.data.onboarding = [{ title: "", text: "", image: "" }]
      contest.data.contestLiveSchedule = [{ title: "", text: "", startDate: new Date(), endDate: new Date(), image: "" }]
      contest.data.contestNotStartedSchedule = { title: "", text: "", image: "" }
      contest.data.contestFinishedSchedule = { title: "", text: "", image: "", CTAtext: "" }
      contest.data.contestServerSideConfiguration = {
        contestTwitterShareMessage: "",
        contestRewardCount: 0,
        contestSharingInterval: 0,
      }
      contest.data.leaderboardBanner = ""
      contest.data.leaderboardTopBanner = ""
      contest.data.leaderboardAdReplacementBanner = ""
      contest.data.topLadderRewardStrategy = {
        type: "BUFF_POINTS",
        rewardText: "",
        buffPointsAmount: 0,
        name: "",
        transactionName: "",
      }
      contest.data.scheduleSidebarBannerImage = ""

      await managingJsonStore.create(contest)
    }
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  return (

    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} form={form}>
      <FormElement type={editType == "edit" ? "readOnly" : "input"} label="Name" name="name" initialValue={name} required />
      <FormElement
        type="input"
        label="Pretty name"
        name="pretty name"
        initialValue={editType == "edit" ? data["prettyName"] : ""}
        required
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ marginRight: "auto" }}>
          <span style={{ marginRight: "5px", color: "red" }}>*</span>Contest Type:
        </label>
        <input
          style={{ marginRight: "5px" }}
          type="checkbox"
          id="leaderboard"
          name="leaderboard"
          checked={check == "SCORE_BASED"}
          onChange={() => setCheck("SCORE_BASED")}
        />
        <label style={{ marginRight: "auto" }} htmlFor="leaderboard" >Leaderboard</label>
        <input
          style={{ marginRight: "5px" }}
          type="checkbox"
          id="challenge"
          name="challenge"
          checked={check == "CHALLENGE_BASED"}
          onChange={() => setCheck("CHALLENGE_BASED")}
        />
        <label style={{ marginRight: "auto" }} htmlFor="challenge" >Challenge</label>
      </div>

      <SubmitCancelButtons onClose={onClose} isLoading={managingJsonStore.isLoading} />
    </Form>
  )
})
