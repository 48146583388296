import React, { FC } from "react"
import { observer } from "mobx-react"
import { Modal } from "antd"
import { ProductProcessorInfo } from "../product-processor.info"
import { ProductProcessorCreate } from "../product-processor.create"

interface IProductDetails {
  isVisibleInfo: boolean;
  setInfoVisibility: (arg: boolean) => void;
  newProduct?: boolean;
}

export const ProductDetails: FC<IProductDetails> = observer(({ isVisibleInfo, setInfoVisibility, newProduct = false }) => {

  return (
    <Modal
      visible={isVisibleInfo}
      onCancel={() => setInfoVisibility(false)}
      width={400}
      bodyStyle={{ paddingBottom: 0 }}
      footer={null}
    >
      {newProduct ? (
        <ProductProcessorCreate onClose={() => setInfoVisibility(false)} />
      ) : (
        <ProductProcessorInfo onClose={() => setInfoVisibility(false)} />
      )}

    </Modal>
  )
})
