import * as agent from "superagent"
import { Config } from "../config/config"

export const getJwtToken = async(login: string, password: string): Promise<string> => {
  try {
    console.log(Config.baseUrl)

    return (await agent.post(`${Config.baseUrl}/auth/login`).send({ login, password })).body.token
  } catch (error) {
    console.log(error)

    return ""
  }
}
