import React, { FC, useEffect, useRef, useState } from "react"
import { Form, InputNumber, Input, Select, FormInstance } from "antd"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import s from "./styles.module.css"
import { SubmitCancelButtons } from "components"
import { CreateChallengeDTO } from "../store/types"
import Title from "antd/lib/typography/Title"
import { gameNames } from "abstract/constants/game-ids"
import { notifications } from "notifications"

export const ChallengeCreate: FC<{ onClose: () => void, open: boolean }> = observer(({ onClose, open }) => {
  const form = useRef<FormInstance<CreateChallengeDTO>>(null)
  const [transitionError, setTransitionError] = useState("")
  const { dataStore: { challengesStore } } = useStore()

  const onFinishHandler = async(data: CreateChallengeDTO) => {
    if (transitionError) return notifications.error(transitionError)
    challengesStore.funcAfterSuccess = () => {
      challengesStore.getData()
      onClose()
    }
    await challengesStore.create({
      ...data,
      gameId: Number(data.gameId),
      transitionFunctions: JSON.parse(data.transitionFunctions as unknown as string),
    })
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  useEffect(() => {
    if (!open) {
      form.current?.resetFields()
    }
  }, [])

  return (
    <Form<CreateChallengeDTO>
      ref={form}
      onFinish={onFinishHandler}
      onFinishFailed={onFinishFailedHandler}
      style={{ margin: 12, display: "grid", justifyContent: "center" }}
    >
      <Title level={4}>Create Challenge</Title>
      <div style={{ paddingLeft: "1.5rem", paddingBottom: "1rem" }}>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input title!",
            },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: "Please input type!",
            },
          ]}
          initialValue="ACCUMULATIVE"
        >
          <Select>
            <Select.Option value="ACCUMULATIVE">Accumulative</Select.Option>
            <Select.Option value="NON_ACCUMULATIVE">Non-Accumulative</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="gameId"
          label="Game"
          rules={[
            {
              required: true,
              message: "Please input game!",
            },
          ]}
        >
          <Select>
            {Object.entries(gameNames).map(item => {
              return (
                <Select.Option key={item[0]} value={item[0]}>
                  {item[1]}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </div>
      <Title level={5}>Levels</Title>
      <div className={s.table}>
        <div>Level</div>
        <div>Goal</div>
        <div>Reward</div>
      </div>

      {Array(3).fill(null).map((_, index) => {
        const level = index + 1

        return (
          <div className={s.table} key={level}>
            <span>{level}</span>
            <Form.Item name={["levels", index, "level"]} style={{ display: "none" }} initialValue={level} />
            <Form.Item
              name={["levels", index, "goalValue"]}
              rules={[
                {
                  required: true,
                  message: "Please input goal!",
                },
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name={["levels", index, "reward"]}
              rules={[
                {
                  required: true,
                  message: "Please input Reward!",
                },
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>
          </div>
        )
      })}
      <div className={s.prettyJson}>
        <Form.Item
          name="template"
          label="Pattern"
          rules={[
            {
              required: true,
              message: "Please input pattern!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="transitionFunctions"
          label="Transition function"
          labelAlign="left"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please input transition function!",
            },
          ]}
        >
          <Input.TextArea
            style={{ height: "200px" }}
            onChange={e => {
              setTransitionError("")
              try {
                console.log(JSON.parse(e.target.value), "!")
              } catch (objError: any) {
                setTransitionError(`${String(objError?.name)}:${String(objError?.message)} `)
              }
            }}
          />
        </Form.Item>
      </div>
      <SubmitCancelButtons onClose={onClose} isLoading={challengesStore.isLoading} />
    </Form>
  )
})
