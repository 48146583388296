import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { EntityToUpdate } from "abstract/entity-to-update"
import { createUtmBonus, deleteUtmBonus, getUtmBonuses, updateUtmBonus } from "../api"

export class UtmBonus {
  constructor(
    public id: string,
    public name: string,
    public code: string,
    public geoRegion: string,
    public amount: number,
    public subscriptionId: string,
    public billingPeriod: "MONTH" | "YEAR",
    public expiresAt: Date | null,
    public createdAt: Date,
    public updatedAt: Date,
    public utmDataField:
      | "source"
      | "medium"
      | "campaign"
      | "extra"
      | "content"
      | "term",
  ) {}
}

export class UtmBonusesStore extends PaginationStore<UtmBonus> {
  @observable
  private _utmBonuses: UtmBonus[]
  @observable
  private _selectedElement: UtmBonus = {} as UtmBonus

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<UtmBonus[]> {
    const { page, limit } = this.pagination

    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getUtmBonuses, this.filter, page, limit)

    transaction(() => {
      this._utmBonuses = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: EntityToUpdate<UtmBonus>) {
    await this.defaultFunction(updateUtmBonus, this._selectedElement.id, data)
  }

  async create(data: EntityToUpdate<UtmBonus>) {
    await this.defaultFunction(createUtmBonus, data)
  }

  async delete(id: string) {
    await this.defaultFunction(deleteUtmBonus, id)
  }

  @computed
  get utmBonuses(): UtmBonus[] {
    return this._utmBonuses
  }

  @computed
  get selectedElement(): UtmBonus {
    return this._selectedElement
  }

  set selectedElement(value: UtmBonus) {
    this._selectedElement = value
  }
  clearSelectedElement() {
    this._selectedElement = {} as UtmBonus
  }
}
