import { apiCall } from "api-calls/api-call"
import { RewardToUpdate, RewardType, RewardResponse } from "domains/promocodes/store/types"

export const getRewardsTypes = async(
  page: number,
  limit: number,
  order: any,
): Promise<{ total: number; data: RewardType[]; order: any }> => {
  const query: { page: number; limit: number } = {
    page,
    limit,
  }
  if (order) {
    query["order"] = order
  }

  return apiCall({
    method: "GET",
    path: "rewards/types",
    query,
  })
}

export const createRewardsType = async(input: RewardToUpdate): Promise<RewardResponse> => {
  return apiCall({
    method: "POST",
    path: "rewards/types",
    body: input,
  })
}

export const updateRewardsType = async(
  id: string,
  input: RewardToUpdate,
): Promise<RewardResponse> => {
  return apiCall({
    method: "PUT",
    path: `rewards/types/${id}`,
    body: input,
  })
}
