import { createContext, useContext } from "react"
import { PopupTemplatesStore } from "./popupTemplates.store"

export const PopupTemplatesContext = createContext<PopupTemplatesStore | null>(null)

export const usePopupTemplatesContext = () => {
  const context = useContext(PopupTemplatesContext)
  if (context === null) {
    throw new Error("You have forgotten to wrap your root component with RootStoreProvider")
  }

  return context
}
