import React, { FC } from "react"

import { Divider } from "antd"
import { FormElement } from "components"

export const SplashScreen: FC<{ data?: any }> = ({ data }) => {
  return (
    <>
      <Divider>Splash screen data </Divider>
      <div>
        <FormElement
          type="input"
          label="title"
          name={["splashScreen", "title"]}
          initialValue={data?.title}
        />
        <FormElement
          type="input"
          label="subtitle"
          name={["splashScreen", "subtitle"]}
          initialValue={data?.subtitle}
        />
        <FormElement
          type="input"
          label="CTAtext"
          name={["splashScreen", "CTAtext"]}
          initialValue={data?.CTAtext}
        />
        <FormElement
          type="input"
          label="TOCtext"
          name={["splashScreen", "TOCtext"]}
          initialValue={data?.TOCtext}
        />
      </div>
    </>
  )
}
