import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, transaction, makeObservable } from "mobx"
import { getLastPurchasedGiftCards } from "domains/purchased-gift-cards/api/gift-cards.call"

export class PurchasedGiftCard {
  itemId: string
  itemName: string
  itemPrice: number
  login: string
  giftCode: string
  purchasedAt: Date
}

export class PurchasedGiftCardsStore extends PaginationStore<PurchasedGiftCard> {
  @observable
  private _giftCards: PurchasedGiftCard[]

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<PurchasedGiftCard[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getLastPurchasedGiftCards, page, limit)

    transaction(() => {
      this._giftCards = res.data
      this.total = res.total
    })

    return res.data
  }

  @computed
  get giftCards(): PurchasedGiftCard[] {
    return this._giftCards
  }
}
