import { Banner, BannerFilters } from "../store/banners.store"
import { EntityToUpdate } from "abstract/entity-to-update"
import { apiCall } from "api-calls/api-call"
import { Response } from "domains/managingJson/store/types"

export const getBanners = async(
  filter: BannerFilters,
  page: number,
  limit: number,
): Promise<{
  data: Banner[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: BannerFilters } = {
    page,
    limit,
  }
  if (filter) query.filter = filter

  return apiCall({
    method: "GET",
    path: "banners",
    query: query,
  })
}

export const updateBanner = async(
  id: string,
  data: EntityToUpdate<Banner>,
): Promise<Response & Banner> => {
  return apiCall({
    method: "PUT",
    path: `banners/${id}`,
    body: data,
  })
}

export const createBanner = async(data: EntityToUpdate<Banner>): Promise<Response & Banner> => {
  return apiCall({
    method: "POST",
    path: "banners",
    body: data,
  })
}

export const deleteBanner = async(id: string): Promise<{
  data: Banner[];
  total: number;
}> => {
  return apiCall({
    method: "DELETE",
    path: `banners/${id}`,
  })
}
