import { FC, useEffect, useState } from "react"
import { Button, Form, Input, Modal, Select } from "antd"

import { observer } from "mobx-react"
import { notifications } from "notifications"
import { DeleteIconButton, SubmitCancelButtons } from "components"
import { ITask, ITaskReward } from "../api/types"
import { createTask, createTaskReward, deleteTaskReward, updateTask, updateTaskReward } from "../api"
import { COUNTRY_CODES } from "utils/countryCodes"

export const CreateTask: FC<{
  onClose: (task?: ITask) => void,
  initial?: ITask, advertiser: string,
  advertiserRegions: string[],
}> = observer(({ onClose, initial: _initial, advertiser, advertiserRegions }) => {
  const [initial, setInitial] = useState<ITask | undefined>(_initial)
  const [rewards, setRewards] = useState<ITask["rewards"]>(initial?.rewards || [])
  const [submitting, setSubmitting] = useState(false)
  const [addRegion, setAddRegion] = useState(false)

  useEffect(() => {
    const missingRegions = advertiserRegions.filter(r => !_initial?.rewards.find(reward => reward.geoRegion === r))
    setRewards([...(_initial?.rewards || []), ...missingRegions.map(r => ({ geoRegion: r, amount: 0, revenue: 0 }))])
    setInitial(_initial)
  }, [_initial])

  const onFinishHandler = async(e: ITask & {rewards: Record<string, ITaskReward>}) => {
    const isEdit = !!initial?.id
    if (submitting) return
    if (!e.name) {
      return notifications.error("Please enter the task name")
    }
    if (!e.description) {
      return notifications.error("Please enter the task description")
    }
    if (Object.values(e.rewards).some(r => !r.amount)) {
      return notifications.error("Please enter the task reward")
    }
    if (Object.values(e.rewards).some(r => !r.revenue)) {
      return notifications.error("Please enter the task revenue")
    }
    setSubmitting(true)

    const rewardsByGeoRegion = e.rewards

    const data = {
      ...initial,
      ...e,
    } as ITask

    data.rewards = Object.keys(rewardsByGeoRegion).map(r => ({
      ...rewardsByGeoRegion[r],
      amount: Number(rewardsByGeoRegion[r].amount),
      revenue: Number(rewardsByGeoRegion[r].revenue),
    }))

    if (isEdit) {
      e.id = initial?.id

      return updateTask(advertiser, data).then(async updated => {
        const updatedRewards = await Promise.all(data.rewards.map(r => {
          if (r.id) {
            return updateTaskReward(r)
          } else {
            return createTaskReward(data.id as string, r)
          }
        }))
        updated.rewards = updatedRewards
        notifications.success("Task updated")

        setSubmitting(false)
        close(updated)
      }).catch(e => {
        notifications.error(e.cause.message)
        setSubmitting(false)
      })
    }

    createTask(advertiser, { ...data, active: false }).then(async created => {
      notifications.success("Task created")
      setSubmitting(false)
      close(created)
    }).catch(e => {
      notifications.error(e.cause.message)
      setSubmitting(false)
    })
  }
  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  const close = (task?: ITask) => {
    (document.getElementById("createTask") as HTMLFormElement).reset()
    onClose(task)
  }

  const onCreateReward = (geoRegion: string) => {
    setRewards([...rewards, { amount: 0, geoRegion, revenue: 0 }])
  }

  const onDeleteReward = (id: string) => {
    if (submitting) return
    setSubmitting(true)
    deleteTaskReward(id).then(() => {
      notifications.success("Reward deleted")
      setSubmitting(false)
      setRewards(rewards.filter(r => r.id !== id))
    }).catch(e => {
      notifications.error(e.cause.message)
      setSubmitting(false)
    })
  }

  useEffect(() => {
    (document.getElementById("createTask") as HTMLFormElement).reset()
  }, [initial])

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} id="createTask">
      <Modal
        open={addRegion}
        title="Select a Region"
        width="256px"
        footer={null}
        onCancel={() => setAddRegion(false)}
        style={{ top: "50%", transform: "translate(0, -50%)" }}
      >
        <Select
          allowClear
          mode="tags"
          value={[]}
          style={{ width: "100%" }}
          options={COUNTRY_CODES.map(({ code, name }) => ({ label: `${name} (${code})`, value: code }))}
          onSelect={r => {
            if (rewards.find(reward => reward.geoRegion === r)) return notifications.error("Region already present")
            r && onCreateReward(r)
            setAddRegion(false)
          }}
        />
      </Modal>
      <Form.Item
        label="Name"
        name="name"
        initialValue={initial?.name || ""}
        style={{ width: "100%" }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        initialValue={initial?.description || ""}
        style={{ width: "100%" }}
      >
        <Input.TextArea />
      </Form.Item>
      <div
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", marginBottom: 8 }}>
          <h4>Rewards</h4>
          <Button
            type="primary"
            onClick={() => {
              setAddRegion(true)
            }}
          >
            Add Region
          </Button>
        </div>
        {rewards.map(r => (
          <div
            key={r.geoRegion}
            style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}
          >
            <Form.Item
              name={["rewards", r.geoRegion, "id"]}
              initialValue={r?.id || ""}
              style={{ display: "none" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={["rewards", r.geoRegion, "geoRegion"]}
              initialValue={r?.geoRegion || ""}
              style={{ display: "none" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={`Reward for ${r.geoRegion}`}
              name={["rewards", r.geoRegion, "amount"]}
              initialValue={r?.amount || ""}
              style={{ width: "100%" }}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label={`Revenue for ${r.geoRegion}`}
              name={["rewards", r.geoRegion, "revenue"]}
              initialValue={r?.revenue || ""}
              style={{ width: "100%", paddingLeft: 16 }}
            >
              <Input type="number" />
            </Form.Item>
            <div style={{ position: "relative", width: 48, marginLeft: 4, top: -12 }}>
              {
                !advertiserRegions.includes(r.geoRegion)
                  ? (
                    <DeleteIconButton
                      onClick={() => {
                        if (r?.id) {
                          onDeleteReward(r.id as string)
                        } else {
                          setRewards(rewards.filter(reward => reward.geoRegion !== r.geoRegion))
                        }
                      }}
                    />
                  ) : null
              }
            </div>
          </div>
        ))}
      </div>
      <SubmitCancelButtons onClose={() => close()} isLoading={submitting} />
    </Form>
  )
})
