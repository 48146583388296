import { EntityToUpdate } from "abstract/entity-to-update"
import { GiftCard } from "stores/primitives/gift-card"
import { PurchasedGiftCard } from "domains/purchased-gift-cards/store/purchased-gift-cards.store"
import { apiCall } from "api-calls/api-call"

export const getLastPurchasedGiftCards = async(
  page: number,
  limit: number,
): Promise<{
  data: PurchasedGiftCard[];
  total: number;
}> => {
  const query: { page: number; limit: number } = {
    page,
    limit,
  }

  return apiCall({
    method: "GET",
    path: "gift-cards/last-purchased",
    query,
  })
}

export const getGiftCards = async(
  itemTypeId: string,
  page: number,
  limit: number,
): Promise<{
  data: GiftCard[];
  total: number;
}> => {
  const query: { page: number; limit: number; itemTypeId: string } = {
    page,
    limit,
    itemTypeId,
  }

  return apiCall({
    method: "GET",
    path: "gift-cards",
    query,
  })
}

export const createGiftCard = async(data: EntityToUpdate<GiftCard>): Promise<void> => {
  return apiCall({
    method: "POST",
    path: "gift-cards",
    body: data,
  })
}

export const deleteGiftCard = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `gift-cards/${id}`,
  })
}
export const updateGiftCard = async(id: string, data: { giftCode: string }): Promise<void> => {
  return apiCall({
    method: "PUT",
    path: `gift-cards/${id}`,
    body: data,
  })
}

export const uploadGiftCardsList = async(id: string, data: FormData): Promise<void> => {
  return apiCall({
    method: "POST",
    path: `gift-cards/list/${id}`,
    formData: data,
  })
}
