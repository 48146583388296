import { RootStore } from "stores/root.store"
import { PromocodeGroup, GroupToUpdate, GroupRule } from "./types"
import { computed, observable, makeObservable, transaction } from "mobx"
import {
  getPromocodeGroups,
  updatePromocodeGroup,
  createPromocodeGroup,
  deletePromocodeGroup,
  getGroupRules,
} from "../api/groups.api"
import { PaginationStore } from "stores/data/pagination.store"

export class PromocodesGroupsStore extends PaginationStore<PromocodeGroup> {
  @observable
  private _groups: PromocodeGroup[] = [] as PromocodeGroup[]

  @observable
  private _selectedElement: PromocodeGroup = {} as PromocodeGroup

  @observable
  private _groupRules: GroupRule[] = [] as GroupRule[]

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getData() {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getPromocodeGroups, page, limit)

    transaction(() => {
      this._groups = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: GroupToUpdate) {
    await this.defaultFunction(updatePromocodeGroup, this._selectedElement.id, data)
  }

  async create(data: GroupToUpdate) {
    await this.defaultFunction(createPromocodeGroup, data)
  }

  async delete(id: string) {
    await this.defaultFunction(deletePromocodeGroup, id)
  }

  async getGroupRules(): Promise<GroupRule[]> {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getGroupRules)

    transaction(() => {
      this._groupRules = res.data
    })

    return res.data
  }
  @computed
  get groups(): PromocodeGroup[] {
    return this._groups
  }
  @computed
  get selectedElement(): PromocodeGroup {
    return this._selectedElement
  }

  set selectedElement(value: PromocodeGroup) {
    this._selectedElement = value
  }

  @computed
  get groupRules(): GroupRule[] {
    return this._groupRules
  }

  public clearSelectedElement() {
    this._selectedElement = {} as PromocodeGroup
  }
}
