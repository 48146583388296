import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { EntityToUpdate } from "abstract/entity-to-update"
import {
  getEmailCTAs,
  createEmailCTA,
  sendEmailCTA,
  sendCsvEmailCTA,
  uploadUrl,
  sendEmailCsv,
} from "domains/emailsCTA/api/email-cta.call"
import { DataUploadType, EmailCTA } from "./types"

export class EmailCTAsStore extends PaginationStore<EmailCTA, string> {
  @observable
  private _emailCTAs: EmailCTA[]
  public dataUploadType: DataUploadType | null = null
  private _order: any = { createdAt: "DESC" }

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(order?: any): Promise<EmailCTA[]> {
    const { page, limit } = this.pagination

    this.resetFuncAfterSuccess()
    if (order) {
      this._order = order
    }

    const res = await this.defaultFunction(getEmailCTAs, this.filter, page, limit, this._order)

    transaction(() => {
      this._emailCTAs = res.data
      this.total = res.total
    })

    return res.data
  }
  async sendEmailCTA(id: string) {
    await this.defaultFunction(sendEmailCTA, id)
  }
  async createEmailCTA(email: EntityToUpdate<EmailCTA>) {
    await this.defaultFunction(createEmailCTA, email)
  }

  async sendCsvEmailCTA(id: string) {
    await this.defaultFunction(sendCsvEmailCTA, id)
  }

  async uploadCsv() {
    const res = await this.defaultFunction(uploadUrl)
    this.dataUploadType = res
  }
  async sendEmailCsv(email: EntityToUpdate<EmailCTA>) {
    await this.defaultFunction(sendEmailCsv, email)
  }

  @computed
  get emailCTAs(): EmailCTA[] {
    return this._emailCTAs
  }
}
