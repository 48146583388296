
export const wheelValuesColumns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Coins Amount",
    dataIndex: "coinsAmount",
    key: "coinsAmount",
  },
  {
    title: "Weight",
    dataIndex: "weight",
    key: "weight",
  },
]
