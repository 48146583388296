import React, { FC } from "react"

import { Divider } from "antd"
import { FormElement } from "components"

export const Schedule: FC<{ data?: any }> = ({ data }) => {
  return (
    <>
      <Divider>Schedule data </Divider>
      <div>
        <FormElement
          type="input"
          label="bigTimerTitle"
          name={["schedule", "bigTimerTitle"]}
          initialValue={data?.bigTimerTitle}
        />
        <FormElement
          type="input"
          label="smallTimerTitle"
          name={["schedule", "smallTimerTitle"]}
          initialValue={data?.smallTimerTitle}
        />
        <FormElement
          type="input"
          label="getStartedButton"
          name={["schedule", "getStartedButton"]}
          initialValue={data?.getStartedButton}
        />

        <h4>Tabs</h4>
        <FormElement
          type="input"
          label="Schedule"
          name={["schedule", "tabs", "schedule"]}
          initialValue={data?.tabs?.schedule}
        />
        <FormElement
          type="input"
          label="Leaderboard"
          name={["schedule", "tabs", "leaderboard"]}
          initialValue={data?.tabs?.leaderboard}
        />
        <FormElement
          type="input"
          label="FreePoints"
          name={["schedule", "tabs", "freePoints"]}
          initialValue={data?.tabs?.freePoints}
        />
        <FormElement
          type="input"
          label="MyRewards"
          name={["schedule", "tabs", "myRewards"]}
          initialValue={data?.tabs?.myRewards}
        />
      </div>
    </>
  )
}
