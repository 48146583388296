import React, { FC, useState } from "react"
import { Checkbox, Form, Input, Select } from "antd"

import {
  RewardToUpdate,
  buffTransactionTypeArr,
  typeRewardArr,
} from "domains/promocodes/store/types"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { FormElement, SubmitCancelButtons } from "components"

export const RewardEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      promocodesRewardsStore,
      promocodesRewardsStore: { selectedElement },
    },
  } = useStore()

  const [confirmed, setConfirmed] = useState(selectedElement.confirm || false)
  const [typeRew, setTypeRew] = useState(selectedElement.type || null)
  const onFinishHandler = async(event: RewardToUpdate) => {
    const reward = {}

    if (event.rewardCondition) {
      if (event?.rewardCondition?.duration) {
        reward["duration"] = +event?.rewardCondition?.duration
      }
      if (event?.rewardCondition?.percentage) {
        reward["percentage"] = +event?.rewardCondition?.percentage
      }
      if (event?.rewardCondition?.periodType) {
        reward["periodType"] = event?.rewardCondition?.periodType
      }
    }

    const dataToBack: RewardToUpdate = {
      transactionName: event.transactionName,
      buffTransactionType: event.buffTransactionType,
      eventTitle: event.eventTitle,
      countOfPrizes: Number(event.countOfPrizes),
      type: event.type,
      confirm: confirmed,
      rewardCondition: event.type !== "BUFFS" ? (reward as any) : null,
    }

    promocodesRewardsStore.funcAfterSuccess = async() => {
      onClose()
      await promocodesRewardsStore.getData()
    }
    editType === "edit"
      ? await promocodesRewardsStore.update(dataToBack)
      : await promocodesRewardsStore.create(dataToBack)
  }

  return (
    <Form onFinish={onFinishHandler} labelCol={{ span: 9 }} labelAlign="left">
      <Form.Item
        label="Event title"
        name="eventTitle"
        initialValue={selectedElement.eventTitle}
        rules={[
          {
            required: true,
            message: "Please input event title!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input
          style={{ width: "100%" }}
          placeholder="Input Event title"
          disabled={editType === "edit"}
        />
      </Form.Item>

      <Form.Item
        label="Transaction name"
        name="transactionName"
        initialValue={selectedElement.transactionName}
        rules={[
          {
            required: true,
            message: "Please input transaction name!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input style={{ width: "100%" }} placeholder="Input Transaction name" />
      </Form.Item>

      <Form.Item
        label="Buff Transaction Type"
        name="buffTransactionType"
        initialValue={selectedElement.buffTransactionType}
        rules={[
          {
            required: true,
            message: "Please select Buff Transaction Type!",
          },
        ]}
      >
        <Select placeholder="Chose Buff Transaction Type">
          {buffTransactionTypeArr.map(item => (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Type"
        name="type"
        rules={[
          {
            required: true,
            message: "Please select type!",
          },
        ]}
        initialValue={selectedElement.type}
      >
        <Select
          placeholder="Choose type"
          style={{ width: "100%" }}
          onChange={e => setTypeRew(e)}
        >
          {typeRewardArr?.map(item => {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Count Of Prizes"
        name="countOfPrizes"
        initialValue={selectedElement.countOfPrizes || 1}
        rules={[
          {
            required: true,
            message: "Please input Count Of Prizes!",
          },
        ]}
      >
        <Input type="number" min={1} max={typeRew !== "BUFFS" ? 1 : undefined} />
      </Form.Item>

      <Form.Item label="Is confirmed?" name="confirm" initialValue={selectedElement.confirm}>
        <Checkbox checked={confirmed} onChange={e => setConfirmed(e.target.checked)} />
      </Form.Item>

      {typeRew !== "BUFFS" && (
        <>
          <b>Reward Condition</b>
          {typeRew !== "MARKETPLACE_DISCOUNT" && (
            <>
              <FormElement
                label="Duration"
                name={["rewardCondition", "duration"]}
                initialValue={selectedElement?.rewardCondition?.duration}
                props={{ min: 1 }}
                type="inputNumber"
              />

              <Form.Item
                label="Period Type"
                name={["rewardCondition", "periodType"]}
                initialValue={selectedElement?.rewardCondition?.periodType}
              >
                <Select>
                  {["HOURS", "DAYS"].map(item => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          {(typeRew === "BOOSTER" || typeRew === "MARKETPLACE_DISCOUNT") && (
            <Form.Item
              label="Percentage"
              name={["rewardCondition", "percentage"]}
              initialValue={selectedElement?.rewardCondition?.percentage}
            >
              <Input
                type="number"
                min={1}
                max={typeRew === "MARKETPLACE_DISCOUNT" ? 100 : undefined}
              />
            </Form.Item>
          )}
        </>
      )}
      <SubmitCancelButtons onClose={onClose} isLoading={promocodesRewardsStore.isLoading} />
    </Form>
  )
})
