import React, { FC, useEffect } from "react"
import { EntityToUpdate } from "abstract/entity-to-update"
import { Checkbox, Form } from "antd"
import { observer } from "mobx-react"

import { useStore } from "stores/utils/use-store"
import { UtmTier } from "domains/utm/store"
import { FormElement, SubmitCancelButtons } from "components"
import GeoPickerElement from "components/geoPicker"

interface UtmTierEditFields {
  name: string;
  countries: string[];
  payoutAmount: number;
  anyCountry: boolean;
}

export const UtmTierEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      utmTiersStore: { selectedElement: { name, countries, payoutAmount } },
      utmTiersStore,
    },
  } = useStore()

  const [form] = Form.useForm()
  const isAnyCountry = Form.useWatch("anyCountry", form)

  const onFinishHandler = async(e: UtmTierEditFields) => {
    const sendingData: EntityToUpdate<UtmTier> = {
      name: e.name,
      payoutAmount: +e.payoutAmount,
    }

    if (e.anyCountry) {
      sendingData.countries = null
    } else if (e.countries) {
      sendingData.countries = e.countries
    }
    utmTiersStore.funcAfterSuccess = async() => {
      await utmTiersStore.getData()
      onClose()
    }
    editType === "edit"
      ? await utmTiersStore.update(sendingData)
      : await utmTiersStore.create(sendingData)
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  useEffect(() => {
    return () => {
      utmTiersStore.selectedElement = {} as UtmTier
    }
  }, [utmTiersStore])

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} form={form}>
      <FormElement
        label="Name"
        name="name"
        initialValue={name}
        required
        props={{ maxLength: 25 }}
        type="input"
      />

      <GeoPickerElement label="Countries" name="countries" initialValue={countries || undefined} />
      <Form.Item
        label="Any country"
        name="anyCountry"
        initialValue={!countries || countries.length === 0}
        valuePropName="checked"
        status="error"
      >
        <Checkbox />
      </Form.Item>
      <FormElement
        label="Payout Amount"
        name="payoutAmount"
        initialValue={payoutAmount}
        required
        props={{ min: 1 }}
        type="inputNumber"
      />
      <SubmitCancelButtons onClose={onClose} isLoading={utmTiersStore.isLoading} />
    </Form>
  )
})
