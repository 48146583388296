import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { Button, Input, Modal, Select, Table, TablePaginationConfig, Tabs } from "antd"
import { challengesColumns } from "./columns"
import { ChallengeDetails } from "./challenges"
import { Challenge, Level, StatusEnum } from "domains/challenges/store/types"
import Search from "antd/es/input/Search"
import { activeChallengesColumns } from "./activeColumns"
import { notifications } from "notifications"
import { gameNames } from "abstract/constants/game-ids"
import { ChallengeCreate } from "../challenge.create"

const { TabPane } = Tabs

export const ChallengesList: FC = observer(() => {
  const { dataStore: { challengesStore } } = useStore()

  const [activeTab, setActiveTab] = useState("0")
  const [filt, setfilt] = useState("")
  const [isVisibleInfo, setInfoVisibility] = useState<boolean>(false)
  const [isCreateVisible, setCreateVisibility] = useState<boolean>(false)
  const [updateGoal, setupdateGoal] = useState<Level | null>(null)
  const [inputedId, setinputedId] = useState<string>("")

  const searchHandler = async(value: string) => {
    challengesStore.setFilter(value)
  }

  useEffect(() => {
    if (activeTab === "0") {
      challengesStore.getData()
    } else {
      challengesStore.getActiveChallenges()
    }
  }, [challengesStore, activeTab])

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, limit) => {
      challengesStore.pagination.page = page
      if (limit) {
        challengesStore.pagination.limit = limit
      }
    },
    total: challengesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }
  const updatedColumns = [
    ...challengesColumns,
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, row: Challenge) => {
        const notDeleted = row.deletedAt === null

        return (
          <Button
            type={notDeleted ? "primary" : "default"}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              challengesStore.funcAfterSuccess = () => {
                challengesStore.getData()
              }
              challengesStore.updateStatusChallenge(
                row.id,
                notDeleted ? StatusEnum.DEACTIVATE : StatusEnum.ACTIVATE,
              )
            }}
          >
            {notDeleted ? "DEACTIVATE" : "ACTIVATE"}
          </Button>
        )
      },
    },
  ]

  return (
    <Tabs style={{ marginBottom: "20px" }} activeKey={activeTab} onChange={setActiveTab}>
      <TabPane tab="Available Goals" key="0">
        <div style={{ display: "flex", gap: "10px" }}>
          <Select
            onChange={searchHandler}
            value={challengesStore.filter}
            style={{ width: "200px", marginBottom: "10px" }}
            placeholder="Filter by Game:"
            allowClear
          >
            {Object.entries(gameNames).map(item => {
              return (
                <Select.Option key={item[0]} value={item[0]}>
                  {item[1]}
                </Select.Option>
              )
            })}
          </Select>
          <Search
            placeholder="Search by gameId(input full code)"
            allowClear
            enterButton
            onSearch={searchHandler}
          />
          <Button onClick={() => setCreateVisibility(true)} type="primary" style={{ justifySelf: "end" }}>
            Create a Challenge
          </Button>
        </div>
        <Modal
          open={isCreateVisible}
          onCancel={() => setCreateVisibility(false)}
          width={400}
          bodyStyle={{ paddingBottom: 0 }}
          footer={null}
        >
          <ChallengeCreate onClose={() => setCreateVisibility(false)} open={isCreateVisible} />
        </Modal>

        <Table
          dataSource={challengesStore.challenges}
          columns={updatedColumns}
          rowKey="id"
          pagination={paginationOptions}
          loading={challengesStore.isLoading}
          onRow={(record: Challenge) => ({
            onClick: () => {
              challengesStore.selectedElement = {
                ...record,
                levels: record.levels.sort((a, b) => +a.level - +b.level),
              }
              setInfoVisibility(true)
            },
          })}
        />
        {isVisibleInfo ? (
          <ChallengeDetails setInfoVisibility={setInfoVisibility} isVisibleInfo={isVisibleInfo} />
        ) : null}
      </TabPane>
      <TabPane tab="Active Challenges" key="1">
        <div style={{ display: "flex", gap: "10px" }}>
          <Select
            onChange={e => {
              console.log(e, "!!!")
              setfilt(e)
            }}
            value={filt}
            style={{ width: "200px", marginBottom: "10px" }}
            placeholder="Filter by Game:"
            allowClear
          >
            {Object.entries(gameNames).map(item => {
              return (
                <Select.Option key={item[0]} value={item[0]}>
                  {item[1]}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        <Table
          dataSource={
            filt && filt.length > 0
              ? challengesStore?.activeChallenges?.filter(item => item.gameId === Number(filt))
              : challengesStore.activeChallenges
          }
          columns={activeChallengesColumns(setupdateGoal)}
          rowKey="id"
          loading={challengesStore.isLoading}
        />
        {!!updateGoal?.id && (
          <Modal
            open={!!updateGoal?.id}
            onOk={() => {
              if (inputedId && inputedId?.length > 0) {
                challengesStore.funcAfterSuccess = () => {
                  setinputedId("")
                  challengesStore.getActiveChallenges()
                  setupdateGoal(null)
                }
                challengesStore.updateActiveChallengeGoal(updateGoal.id, inputedId)
              } else {
                notifications.error("Input goal id")
              }
            }}
            onCancel={() => setupdateGoal(null)}
          >
            <div>
              You want to change {updateGoal.id}
              <br /> with title <span style={{ fontWeight: "bold" }}>{updateGoal.title}</span>
            </div>
            <div style={{ marginBottom: "10px" }}>Input new goal id</div>
            <Input onChange={e => setinputedId(e.target.value)} value={inputedId} />
          </Modal>
        )}
      </TabPane>
    </Tabs>
  )
})
