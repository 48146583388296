import {
  Configuration,
  ConfigurationResponse,
  WheelValue,
  WheelValueResponse,
} from "domains/managingSpinCollect/store/types"
import { apiCall } from "api-calls/api-call"

export const getConfiguration = async(): Promise<Configuration> => {
  return apiCall({
    method: "GET",
    path: "spin-collect/configuration",
  })
}

export const updateConfiguration = async(input: Configuration): Promise<ConfigurationResponse> => {
  return apiCall({
    method: "PUT",
    path: "spin-collect/configuration",
    body: input,
  })
}

export const getWheelValues = async(): Promise<WheelValue[]> => {
  return apiCall({
    method: "GET",
    path: "spin-collect/wheel-values",
  })
}

export const getFreeValues = async(): Promise<WheelValue[]> => {
  return apiCall({
    method: "GET",
    path: "spin-collect/free-values",
  })
}

export const getPaidValues = async(): Promise<WheelValue[]> => {
  return apiCall({
    method: "GET",
    path: "spin-collect/paid-values",
  })
}

export const createWheelValue = async(input: WheelValue): Promise<WheelValueResponse> => {
  return apiCall({
    method: "POST",
    path: "spin-collect/wheel-value",
    body: input,
  })
}

export const updateWheelValue = async(input: WheelValue): Promise<WheelValueResponse> => {
  return apiCall({
    method: "PUT",
    path: "spin-collect/wheel-value",
    body: input,
  })
}

export const deleteWheelValue = async(id: string): Promise<void> => {
  return apiCall({
    method: "DELETE",
    path: `spin-collect/wheel-value/${id}`,
  })
}
