import { observable, makeObservable } from "mobx"

import { BaseStore } from "../base.store"
import { uploadImage } from "api-calls/files.calls"
import { RootStore } from "../root.store"

export class FilesStore extends BaseStore {
  @observable
  public isLoading: boolean = false

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async uploadImageAndGetUrl(file: FormData): Promise<string> {
    const data = await this.defaultFunction(uploadImage, file)

    return data.url
  }
}
