import React, { FC, useEffect, useState } from "react"

import { Divider, Form } from "antd"
import { UploadComponent, FormElement } from "components"
import { ContestConfigSchedulePageData } from "domains/managingJson/store/types"

export const ContestFinishedSchedule: FC<{
  data?: ContestConfigSchedulePageData & { CTAtext: string };
  form: any;
}> = ({ data, form }) => {
  const [image, setImage] = useState(data?.image || "")

  useEffect(() => {
    if (data) {
      setImage(data?.image)
    }
  }, [data])

  useEffect(() => {
    const arr = {}
    arr["contestFinishedSchedule"] = { image }
    form.setFieldsValue({ ...arr })
  }, [image])

  return (
    <>
      <Divider>Finished schedule </Divider>
      <div>
        <FormElement
          type="input"
          label="ContestFinishedSchedule title "
          name={["contestFinishedSchedule", "title"]}
          initialValue={data?.title}
          required
        />
        <FormElement
          type="text"
          label="ContestFinishedSchedule text "
          name={["contestFinishedSchedule", "text"]}
          initialValue={data?.text}
          required
        />
        <FormElement
          type="input"
          label="ContestFinishedSchedule CTA text "
          name={["contestFinishedSchedule", "CTAtext"]}
          initialValue={data?.CTAtext}
          required
        />

        <Form.Item name={["contestFinishedSchedule", "image"]} initialValue={image}>
          <UploadComponent
            image={image}
            setImage={setImage}
            placeholder="Contest Finished Schedule Image "
            title="Contest Finished Schedule Image "
            required
          />
        </Form.Item>
      </div>
    </>
  )
}
