import React, { FC } from "react"
import { Button } from "antd"
import { TrophyOutlined } from "@ant-design/icons"

interface TrophyIconButtonProps {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const TrophyIconButton: FC<TrophyIconButtonProps> = ({ disabled, onClick }) => {
  return (
    <Button
      disabled={disabled}
      type="primary"
      style={{ background: "#FFD176", borderColor: "#FFD176" }}
      onClick={onClick}
      icon={<TrophyOutlined />}
    />
  )
}
