import React, { FC } from "react"
import { Button } from "antd"

interface BookIconButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  valueText: string;
}

export const CreateNewElement: FC<BookIconButtonProps> = ({ valueText, onClick }) => {
  return (
    <Button type="primary" onClick={onClick} style={{ marginBottom: 10 }}>
      Create new {valueText}
    </Button>
  )
}
