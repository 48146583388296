import { GameAccount } from "domains/game-accounts/store/game-account.store"
import { apiCall } from "api-calls/api-call"

export const getAllGameAccounts = async(
  search: string,
  page: number,
  limit: number,
): Promise<{
  gamingAccounts: GameAccount[];
  count: number;
}> => {
  const query: { page: number; limit: number; search?: string } = {
    page,
    limit,
  }

  if (search) query.search = search

  return apiCall({
    method: "GET",
    path: "accounts/get-all-gaming-accounts",
    query,
  })
}

export const getGameAccountDetailsById = async(id: string) => {
  return apiCall({
    method: "GET",
    path: `accounts/get-gaming-accounts?accountId=${id}`,
  })
}

export const deleteGameAccount = async(gamingAccountId: string): Promise<void> => {
  const query: { gamingAccountId: string } = { gamingAccountId }

  return apiCall({
    method: "DELETE",
    path: "accounts/remove-gaming-account",
    query,
  })
}
