import { computed, observable, transaction, makeObservable } from "mobx"
import {
  createItem,
  deleteItem,
  getGiftoin,
  updateItem,
} from "domains/giftoin/api/giftoin.api"

import { GiftoinCampaign } from "../types"
import { PaginationStore } from "stores/data/pagination.store"
import { RootStore } from "stores/root.store"

export class GiftoinStore extends PaginationStore<GiftoinCampaign, any> {
  @observable
  private _itemTypes: GiftoinCampaign[] = [] as GiftoinCampaign[]

  @observable
  private _selectedElement: GiftoinCampaign = {} as GiftoinCampaign

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<any[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getGiftoin, this.filter, page, limit)

    transaction(() => {
      this._itemTypes = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: any) {
    await this.defaultFunction(updateItem, this._selectedElement.id, data)
  }

  async create(data: any) {
    await this.defaultFunction(createItem, data)
  }

  async deleteById(id: string) {
    await this.defaultFunction(deleteItem, id)
  }

  @computed
  get itemTypes(): any[] {
    return this._itemTypes
  }

  @computed
  get selectedElement(): any {
    return this._selectedElement
  }

  set selectedElement(value: any) {
    this._selectedElement = value
  }

  public clearSelectedElement() {
    this._selectedElement = {} as any
  }
}
