import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { Button, Modal, Table } from "antd"

import { DeleteIconButton, showDeleteConfirm, EditIconButton, BookIconButton } from "components"
import { codesColumns } from "./CodeCreation/codes-columns"
import { CodeCreation } from "./CodeCreation"
import { CodeUpload } from "./CodeUpload"

export const CodesList: FC = observer(() => {
  const { dataStore: { codesStore } } = useStore()

  const [isCreateEditCodes, setIsCreateEditCodes] = useState<"edit" | "create" | null>(null)
  const [uploadCodes, setuploadCodes] = useState<boolean>(false)

  const paginationOptionsCodes: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      codesStore.pagination = {
        page,
        limit: pageSize || codesStore.pagination.limit,
      }
    },
    total: codesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 100,
  }

  useEffect(() => {
    codesStore.getData()
  }, [codesStore])

  const columns = columnsAdd => [
    ...columnsAdd,
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, row: any) => (
        <div style={{ display: "flex", gap: 10 }}>
          <BookIconButton
            onClick={e => {
              e.stopPropagation()
              setuploadCodes(true)
              codesStore.selectedElement = row
            }}
          />

          <EditIconButton
            onClick={e => {
              e.stopPropagation()

              setIsCreateEditCodes("edit")
              codesStore.selectedElement = row
            }}
          />
          <DeleteIconButton
            onClick={e => {
              e.stopPropagation()

              showDeleteConfirm(row.name, undefined, async() => {
                await codesStore.deleteCodes(row?.id || "")
                await codesStore.getData()
              })
            }}
          />
        </div>
      ),
    },
  ]

  const codesInventory = () => (
    <>
      <div style={{ display: "flex", gap: 10 }}>
        <Button
          type="primary"
          onClick={() => setIsCreateEditCodes("create")}
          style={{ marginBottom: "1rem" }}
        >
          Create a New Inventory
        </Button>
      </div>
      <Table
        dataSource={codesStore.codesInventory}
        columns={columns(codesColumns)}
        rowKey="id"
        pagination={paginationOptionsCodes}
        loading={codesStore.isLoading}
        id="codesInventory"
      />
    </>
  )

  return (
    <div id="rafflesPage">
      {codesInventory()}

      {isCreateEditCodes && (
        <Modal
          visible={!!isCreateEditCodes}
          onCancel={() => {
            setIsCreateEditCodes(null)
            codesStore.clearSelectedElement()
          }}
          width={510}
          style={{ top: 50 }}
          bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
          footer={null}
          title={isCreateEditCodes ? "Create code" : "Edit code"}
        >
          <CodeCreation
            closeModal={() => {
              setIsCreateEditCodes(null)
              codesStore.clearSelectedElement()
            }}
            editType={isCreateEditCodes}
          />
        </Modal>
      )}
      {uploadCodes && (
        <Modal
          visible={!!uploadCodes}
          onCancel={() => setuploadCodes(false)}
          width={960}
          style={{ top: 50 }}
          bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
          footer={null}
        >
          <CodeUpload closeModal={() => setuploadCodes(false)} />
        </Modal>
      )}
    </div>
  )
})
