export enum ButtonActionTypes {
  ROUTING = "routing",
  CLAIM_REWARD = "claimReward",
  CLOSE = "close",
  ONBOARD_TO_CONTEST = "onboardToContest",
}

const ButtonActionTypesLabels = {
  [ButtonActionTypes.ROUTING]: "Routing",
  [ButtonActionTypes.CLAIM_REWARD]: "Claim Reward",
  [ButtonActionTypes.CLOSE]: "Close Popup",
  [ButtonActionTypes.ONBOARD_TO_CONTEST]: "Onboard to Contest",
}

export const buttonPrimaryActions = [
  ButtonActionTypes.CLAIM_REWARD,
  ButtonActionTypes.ONBOARD_TO_CONTEST,
]

export const buttonSecondaryActions = [
  ButtonActionTypes.ROUTING,
  ButtonActionTypes.CLOSE,
]

export const getButtonActionTypeLabel = (type: ButtonActionTypes) => {
  return ButtonActionTypesLabels[type]
}

type RoutingAction = {
  type: ButtonActionTypes.ROUTING;
  routingLink: string;
  contestName?: never;
}

type RewardAction = {
  type: ButtonActionTypes.CLAIM_REWARD;
  routingLink?: never;
  contestName?: never;
}

type CloseAction = {
  type: ButtonActionTypes.CLOSE;
  routingLink?: never;
  contestName?: never;
}

type OnboardToContestAction = {
  type: ButtonActionTypes.ONBOARD_TO_CONTEST;
  routingLink?: never;
  contestName: string;
}

export type ButtonAction = RoutingAction | RewardAction | CloseAction | OnboardToContestAction
