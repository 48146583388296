import { CopyOutlined } from "@ant-design/icons"
import { notifications } from "notifications"

const copyId = (goalId: string) => {
  return (
    <div
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        navigator.clipboard.writeText(goalId)
        notifications.success("Copied goalId:" + goalId)
      }}
      style={{
        background: "#A3A3A3",
        width: 24,
        height: 24,
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      <CopyOutlined style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }} />
    </div>
  )
}

export default copyId
