import { Upload } from "antd"
import React, { FC, useState } from "react"

export const UploadFileComponent: FC<{
  file: any;
  fileType: string;
  setfile: React.Dispatch<React.SetStateAction<any>>;
}> = ({ file, setfile, fileType }) => {
  const [filename, setfilename] = useState("")

  const handleUpload = (data: any) => {
    setfilename(data.file.name)
    setfile(data.file)
  }

  return (
    <div>
      <Upload
        accept={fileType}
        name="avatar"
        showUploadList={false}
        className={!file ? "avatar-uploader-file" : ""}
        onChange={e => {
          handleUpload(e)
        }}
        beforeUpload={() => {
          return false
        }}
      >
        {file ? (
          <div
            className="ant-upload-text"
            style={{ cursor: "pointer" }}
          >{`Uploaded file: ${filename}`}
          </div>
        ) : (
          <div className="ant-upload-text">Upload</div>
        )}
      </Upload>
    </div>
  )
}
