import React, { FC } from "react"

import { Button } from "antd"
import s from "./styles.module.css"

export const SubmitCancelButtons: FC<{
  formId?: string;
  onClose: () => void;
  isLoading: boolean;
  onClick?: () => void;
}> = ({ onClose, isLoading, onClick, formId }) => {
  return (
    <div className={s.buttonSubmit}>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        form={formId ? formId : undefined}
        type="primary"
        htmlType="submit"
        style={{
          cursor: isLoading ? "not-allowed" : "auto",
          backgroundColor: isLoading ? "#d9d9d9" : "#1690ff",
          borderColor: isLoading ? "#d9d9d9" : "#1690ff",
        }}
        disabled={isLoading}
        onClick={onClick}
      >
        Submit
      </Button>
    </div>
  )
}
