import { Tabs } from "antd"
import * as React from "react"
import { DesktopDynamicUI } from "./desktopDynamicUI"
import { MobileDynamicUI } from "./mobileDynamicUI"
import CommonDynamicUITab from "./commonDynamicUI"

export const EditDynamicUI: React.FunctionComponent = () => {
  return (
    <Tabs destroyInactiveTabPane>
      <Tabs.TabPane tab="Desktop" key="1">
        <DesktopDynamicUI />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Mobile" key="2">
        <MobileDynamicUI />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Common" key="3">
        <CommonDynamicUITab />
      </Tabs.TabPane>
    </Tabs>
  )
}
