import { apiCall } from "api-calls/api-call"
import { BlacklistType } from "../store/types"
import { Blacklist } from "../store/blacklist.store"

export const getBlacklist = async(type?: BlacklistType) => {
  return apiCall({
    method: "GET",
    path: "blacklist",
    query: type && { type },
  })
}

export const postBlacklist = async(data: Blacklist) => {
  return apiCall({
    method: "POST",
    path: "blacklist",
    body: data,
  })
}

export const deleteBlacklist = async(type: BlacklistType, value: string) => {
  const query: { type: BlacklistType; value: string } = {
    type,
    value,
  }

  return apiCall({
    method: "DELETE",
    path: "blacklist",
    query,
  })
}
