import { Button, Form, Input } from "antd"
import React, { FC } from "react"
import { useStore } from "stores/utils/use-store"

export const AddKeyComponent: FC = () => {
  const { dataStore: { giftCardsStore } } = useStore()
  const [form] = Form.useForm()

  const onFinishHandler = async(el: { key: string }) => {
    giftCardsStore.funcAfterSuccess = async() => await giftCardsStore.getData()
    await giftCardsStore.create({ giftCode: el.key })
    form.resetFields()
  }

  return (
    <Form form={form} onFinish={onFinishHandler}>
      <Form.Item
        label="Key"
        name="key"
        initialValue=""
        rules={[
          {
            required: true,
            message: "Please input Key!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
