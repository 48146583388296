import React, { FC } from "react"
import { Form, Modal } from "antd"
import { useStore } from "stores/utils/use-store"
import { SubmitCancelButtons, FormElement } from "components"

export const AccountFrozen: FC<{
  visible: boolean;
  onClose: () => void;
  title: string;
  isFrozen: boolean;
}> = ({ visible, onClose, title, isFrozen }) => {
  const [form] = Form.useForm()

  const { dataStore: { accountDetailsStore } } = useStore()

  const onFinishHandler = async(el: { reasonOfBan: string }) => {
    accountDetailsStore.funcAfterSuccess = async() => {
      onClose()
      form.resetFields()
      await accountDetailsStore.getAccountDetails(accountDetailsStore.id)
    }
    isFrozen
      ? await accountDetailsStore.unfreezeAccount()
      : await accountDetailsStore.freezeAccount({ reasonOfBan: el.reasonOfBan })
  }

  return (
    <Modal
      visible={visible}
      title={title}
      footer={null}
      onCancel={() => {
        onClose()
        form.resetFields()
      }}
    >
      <Form form={form} onFinish={onFinishHandler}>
        {isFrozen === false && (
          <>
            <FormElement type="input" label="Reason of ban" name="reasonOfBan" required />
            <div style={{ marginBottom: "10px" }}>
              Example: Your account is frozen because you may have violated our terms of service.
            </div>
          </>
        )}
        <SubmitCancelButtons onClose={onClose} isLoading={accountDetailsStore.isLoading} />
      </Form>
    </Modal>
  )
}
