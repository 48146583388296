import { computed, observable, transaction, makeObservable } from "mobx"

import { BaseStore } from "stores/base.store"
import {
  deleteGameAccount,
  getGameAccountDetailsById,
} from "domains/game-accounts/api/game-accounts.call"
import { RootStore } from "stores/root.store"
import { GameAccDataType } from "./game-account.store"

export type GameAccountType = {
  id: string;
  inGameId: string;
  lastPlayedAt: string;
  gameId: string;
};
export class GameAccountDetails {
  constructor(public list: GameAccountType[]) {}
}

export class DataAcc {
  constructor(
    public account: GameAccDataType,
    public id: string,
    public buffAccountId: string,
    public inGameId: string,
  ) {}
}

export class GameAccountDetailsStore extends BaseStore {
  @observable
  private _gameAccounts: GameAccountDetails = {} as GameAccountDetails

  @observable
  private _gameDataAccount: DataAcc = {} as DataAcc

  @observable
  public isLoading: boolean = false

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  public async getAccountDetails(id: string) {
    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getGameAccountDetailsById, id)

    transaction(() => {
      this._gameAccounts = { list: res.data }
    })

    return res.data
  }

  public setDataAcc(data: any) {
    transaction(() => {
      this._gameDataAccount = data
    })
  }
  public async deleteAccount(someId: string) {
    await this.defaultFunction(deleteGameAccount, someId)
  }

  @computed
  get gameAccounts(): GameAccountDetails {
    return this._gameAccounts
  }

  @computed
  get dataAcc(): DataAcc {
    return this._gameDataAccount
  }
}
