import copyId from "./copyId"

export const productsColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id: string) => {
      return <div>{copyId(id)}</div>
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "price",
    dataIndex: "price",
    key: "price",
    render: (price: number) => {
      return (
        <div>
          {(price/100).toLocaleString(undefined, { minimumFractionDigits: 0 })} $
        </div>
      )
    },
  },
  {
    title: "stripe id",
    dataIndex: "stripeId",
    key: "stripeId",
  },
  {
    title: "apple id",
    dataIndex: "appStoreId",
    key: "appStoreId",
  },
  {
    title: "google id",
    dataIndex: "googlePlayId",
    key: "googlePlayId",
  },
  {
    title: "isActive",
    dataIndex: "isActive",
    key: "isActive",
    render: (isActive: boolean) => {
      return <div>{isActive ? "true" : "false"}</div>
    },
  },
  {
    title: "consumable",
    dataIndex: "consumable",
    key: "consumable",
    render: (consumable: boolean) => {
      return <div>{consumable ? "true" : "false"}</div>
    },
  },
]
