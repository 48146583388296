import React, { Dispatch, FC, SetStateAction, useState } from "react"

import { Button, Form, Input, Typography } from "antd"
import MobileView from "./mobile-view"
import s from "./styles.module.css"

export const RulesInviteFriends: FC<{
  data?: string;

  setparagraps: Dispatch<SetStateAction<string | undefined>>;
}> = ({ data, setparagraps }) => {
  const [description, setDescription] = useState("")
  const [listDescriptions, setListDescription] = useState<string[]>(data ? [data] : [])
  const [uploadText, setUploadText] = useState(data || "")
  const [isShowDevice, setIsShowDevice] = useState(false)
  const [edit, setedit] = useState(false)

  const addParagraphOrItemList = (symbol: string, withoutText?: boolean) => {
    const concat = withoutText ? symbol : symbol.concat(description)
    listDescriptions.push(concat)
    setUploadText(listDescriptions.join(""))
    setparagraps(listDescriptions.join(""))
    if (!withoutText) {
      setDescription("")
    }
  }

  return (
    <div>
      <Form.Item label="Rules invite friends" required>
        <Input.TextArea
          value={description}
          autoSize={{ minRows: 4 }}
          onChange={e => setDescription(e.target.value)}
        />
      </Form.Item>
      {edit ? (
        <Button
          style={{ marginBottom: "15px", marginRight: "10px" }}
          className={s.button}
          onClick={() => {
            setedit(false)
            setUploadText(description)
            setparagraps(description)
            setDescription("")
          }}
        >
          Save Description
        </Button>
      ) : (
        <Button
          style={{ marginBottom: "15px", marginRight: "10px" }}
          className={s.button}
          onClick={() => {
            setedit(true)
            setDescription(uploadText)
          }}
        >
          Edit Description
        </Button>
      )}
      <Button
        className={s.button}
        onClick={() => {
          setListDescription([])
          setUploadText("")
        }}
      >
        Clear Description
      </Button>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "grid" }}>
          <Button
            disabled={edit}
            className={s.button}
            onClick={() => addParagraphOrItemList("<li>", true)}
          >
            Start bullet
          </Button>
          {"<li>"}
        </div>

        <div style={{ display: "grid" }}>
          <Button
            disabled={edit}
            className={s.button}
            onClick={() => addParagraphOrItemList("~~wrap-new-line!", true)}
          >
            Wrap to new line
          </Button>
          ~~wrap-new-line!
        </div>
        <div style={{ display: "grid" }}>
          <Button
            disabled={edit}
            className={s.button}
            onClick={() => addParagraphOrItemList("~~green-text!")}
          >
            Green text
          </Button>
          ~~green-text!
        </div>
        <div style={{ display: "grid" }}>
          <Button
            disabled={edit}
            className={s.button}
            onClick={() => addParagraphOrItemList("~~gray-text!")}
          >
            Gray italic text
          </Button>
          ~~gray-text!
        </div>
        <div style={{ display: "grid" }}>
          <Button
            disabled={edit}
            className={s.button}
            onClick={() => addParagraphOrItemList("~~white-text!")}
          >
            White text
          </Button>
          ~~white-text!
        </div>
        <div style={{ display: "grid" }}>
          <Button
            disabled={edit}
            className={s.button}
            onClick={() => addParagraphOrItemList("~~pink-text!")}
          >
            Pink text
          </Button>
          ~~pink-text!
        </div>
      </div>

      <Typography.Paragraph>{uploadText}</Typography.Paragraph>

      <Button
        onClick={() => {
          setIsShowDevice(prevState => !prevState)
        }}
        style={{ marginLeft: "auto", display: "grid" }}
        type="primary"
      >
        {isShowDevice ? "Hide" : "Show"} how it looks
      </Button>
      {isShowDevice && <MobileView uploadText={uploadText} />}
      <div style={{ marginBottom: "20px" }} />
    </div>
  )
}
