import { Column } from "abstract/types/column.type"

import { Tag } from "antd"

import Styles from "./styles.module.css"

export const marketplaceColumns: Column[] = [
  { title: "Title", key: "title", dataIndex: "title", fixed: true },

  {
    title: "Category",
    key: "items_category",
    dataIndex: ["category", "code"],
  },

  {
    title: "Type",
    key: "type",
    dataIndex: "type",
  },

  {
    title: "Price",
    key: "price",
    align: "center",
    dataIndex: "price",
    render: (price: number) => <Tag color="blue">{Number(price).toFixed(2)}</Tag>,
  },
  {
    title: "Premium price",
    key: "premiumPrice",
    align: "center",
    dataIndex: "premiumPrice",
    render: (price: number) => <Tag color="blue">{Number(price).toFixed(2)}</Tag>,
  },

  {
    title: "Status",
    key: "active",
    align: "center",
    dataIndex: "active",
    render: (active: boolean) => (
      <Tag color={active ? "blue" : "red"}>{active ? "Active" : "Disabled"}</Tag>
    ),
  },

  {
    title: "Icon",
    key: "imageUrl",
    dataIndex: "imageUrl",
    render: (img: string) => img ? <img src={img} alt="ico" className={Styles.icon} /> : <Tag>No Image</Tag>,
  },
]
