import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useStore } from "stores/utils/use-store"
import { Spin, Tabs, Table, Button, Modal, Input } from "antd"
import { observer } from "mobx-react"

import {
  itemsColumns,
  raffleTicketColumns,
  sessionColumns,
  accountColumns,
  transactionColumns,
} from "./columns"
import { AccountSubscription } from "domains/accounts/store/types"
import { RemovePremium } from "domains/accounts/components/remove-premium"
import { AddBonusComponent } from "domains/accounts/components/add-bonus"
import { GivePremium } from "domains/accounts/components/give-premium"
import { AccountFrozen } from "domains/accounts/components/account-frozen"
import { AccountInfo } from "domains/accounts/components/account-info"
import { UtmData } from "domains/accounts/components/utm-data"
import { AccountTransactions } from "./account-transactions"
import { ChangeCountry } from "./change-country"
import { AccountInventory } from "./account-inventory"

const { TabPane } = Tabs

export const AccountDetails: FC = observer<FC>(() => {
  const { id } = useParams<{ id: string }>()

  const { dataStore: { accountDetailsStore } } = useStore()

  useEffect(() => {
    if (!id) {
      return
    }

    accountDetailsStore.getAccountDetails(id).then()
  }, [accountDetailsStore, id])
  const [visibleClearBalanceModal, setClearBalanceVisibility] = useState<boolean>(false)
  const [selectedAccountSubscriptionId, setSelectedAccountSubscriptionId] = useState<string>()
  const [visibleAddBonusModal, setAddBonusModalVisibility] = useState<boolean>(false)
  const [visibleGivePremiumModal, setGivePremiumModalVisibility] = useState<boolean>(false)
  const [visibleCloseAccountModal, setCloseAccountModalVisibility] = useState<boolean>(false)
  const [visibleFreezeAccountModal, setFreezeAccountModalVisibility] = useState<boolean>(false)
  const [visibleVerifyEmailModal, setVerifyEmailModalVisibility] = useState<boolean>(false)
  const [visibleChangeCountryModal, setVisibleChangeCountryModal] = useState<boolean>(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState("")
  const [isValidInput, setValidInput] = useState(false)

  if (!accountDetailsStore.account) return null
  const {
    currentTier,
    items,
    bonuses,
    subscriptions,
    coinsPurchases,
    raffleTickets,
    premiumBonuses,
    sessions,
    email,
    login,
    nickname,
    createdAt,
    updatedAt,
    balance,
    earned,
    spent,
    earnedByGames,
    referrals,
    utmData,
    isFrozen,
    reasonOfBan,
    accountFreeze,
  } = accountDetailsStore.account

  return (
    <Spin spinning={accountDetailsStore.isLoading} size="large">
      {!accountDetailsStore.isLoading ? (
        <Tabs style={{ marginBottom: "20px" }}>
          <TabPane tab="General Info" key={-2}>
            <div
              style={{
                display: "grid",
                gridAutoFlow: "column",
                gridTemplateColumns: "max-content",
              }}
            >
              <AccountInfo
                id={id}
                earned={earned}
                createdAt={createdAt}
                earnedByGames={earnedByGames}
                email={email}
                login={login}
                nickname={nickname}
                updatedAt={updatedAt}
                balance={balance}
                spent={spent}
                tier={currentTier}
                reasonOfBan={reasonOfBan}
                isFrozen={isFrozen}
                accountFreeze={accountFreeze}
              />
              <UtmData data={utmData} />
            </div>
          </TabPane>
          <TabPane tab='Transactions' key={-1}>
            <AccountTransactions />
          </TabPane>
          <TabPane tab="Premium Subscriptions" key={0}>
            <Table
              dataSource={subscriptions}
              columns={accountColumns}
              rowKey="id"
              onRow={(record: AccountSubscription) => {
                return {
                  onDoubleClick: () => {
                    if (record.isActive) setSelectedAccountSubscriptionId(record.id)
                  },
                }
              }}
            />
          </TabPane>
          <TabPane tab="Marketplace Redeems" key={1}>
            <Table dataSource={items} columns={itemsColumns as any} rowKey="id" />
          </TabPane>
          <TabPane tab="Bonuses" key={2}>
            <Table dataSource={bonuses} columns={transactionColumns as any} rowKey="id" />
          </TabPane>

          <TabPane tab="Premium Bonuses" key={3}>
            <Table dataSource={premiumBonuses} columns={transactionColumns as any} rowKey="id" />
          </TabPane>

          <TabPane tab="Point Packs" key={4}>
            <Table dataSource={coinsPurchases} columns={transactionColumns as any} rowKey="id" />
          </TabPane>
          <TabPane tab="Raffle Tickets" key={5}>
            <Table dataSource={raffleTickets} columns={raffleTicketColumns as any} rowKey="id" />
          </TabPane>

          <TabPane tab="Sessions" key={6}>
            <Table dataSource={sessions} columns={sessionColumns as any} rowKey="id" />
          </TabPane>
          <TabPane tab="Referrals" key={7}>
            <Table
              dataSource={referrals}
              columns={[
                {
                  title: "Referral login",
                  dataIndex: "login",
                  key: "login",
                },
                {
                  title: "Referral Balance",
                  dataIndex: "balance",
                  key: "balance",
                },
              ]}
              rowKey="id"
            />
          </TabPane>
          <TabPane tab="Inventory" key={8}>
            <AccountInventory />
          </TabPane>
        </Tabs>
      ) : (
        <div />
      )}
      <RemovePremium
        visible={!!selectedAccountSubscriptionId}
        onClose={() => setSelectedAccountSubscriptionId("")}
        accountSubscriptionId={selectedAccountSubscriptionId as string}
      />
      <AddBonusComponent
        visible={visibleAddBonusModal}
        onClose={() => setAddBonusModalVisibility(false)}
      />
      <GivePremium
        visible={visibleGivePremiumModal}
        onClose={() => setGivePremiumModalVisibility(false)}
      />
      <ChangeCountry
        visible={visibleChangeCountryModal}
        onClose={() => setVisibleChangeCountryModal(false)}
      />
      <AccountFrozen
        isFrozen={isFrozen}
        title={(isFrozen ? "Unfreeze " : "Freeze ") + "Account?"}
        visible={visibleFreezeAccountModal}
        onClose={() => setFreezeAccountModalVisibility(false)}
      />
      <Button onClick={() => setAddBonusModalVisibility(true)}>Create a Transaction</Button>
      <Button onClick={() => setGivePremiumModalVisibility(true)}>Give Premium</Button>
      {(window.location.href.includes("admin.core-tst.app.buff.game")
        || window.location.href.includes("http://localhost:300")) && (
        <Button onClick={() => setClearBalanceVisibility(true)}>Clear Balance</Button>
      )}
      <Button onClick={() => setVerifyEmailModalVisibility(true)}>Verify Email</Button>
      <Button onClick={() => setFreezeAccountModalVisibility(true)}>
        {isFrozen ? "Unfreeze" : "Freeze"} Account
      </Button>
      {!accountDetailsStore.account.isClosed && (
        <Button onClick={() => setCloseAccountModalVisibility(true)}>Delete Account</Button>
      )}
      <Button onClick={() => setVisibleChangeCountryModal(true)}>Change Country</Button>
      <Modal
        visible={visibleClearBalanceModal}
        onOk={async() => {
          accountDetailsStore.funcAfterSuccess = async() => {
            setClearBalanceVisibility(false)
            await accountDetailsStore.getAccountDetails(accountDetailsStore.id)
          }

          await accountDetailsStore.clearBalance()
        }}
        onCancel={() => setClearBalanceVisibility(false)}
      >
        Clear Balance?
      </Modal>
      <Modal
        visible={visibleVerifyEmailModal}
        onOk={async() => {
          accountDetailsStore.funcAfterSuccess = async() => {
            setVerifyEmailModalVisibility(false)
            await accountDetailsStore.getAccountDetails(accountDetailsStore.id)
          }
          await accountDetailsStore.verifyEmail()
        }}
        onCancel={() => setVerifyEmailModalVisibility(false)}
      >
        Verify Email?
      </Modal>
      <Modal
        visible={visibleCloseAccountModal}
        onOk={async() => {
          const isValid = deleteConfirmation === "DELETE"
          if (!isValid) {
            console.log("just temp")
          } else {
            accountDetailsStore.funcAfterSuccess = async() => {
              setCloseAccountModalVisibility(false)
              setValidInput(false)
              setDeleteConfirmation("")
              await accountDetailsStore.getAccountDetails(accountDetailsStore.id)
            }
            await accountDetailsStore.closeAccount()
          }
        }}
        onCancel={() => setCloseAccountModalVisibility(false)}
        okType={isValidInput ? "primary" : "danger"}
      >
        <p> Account abfuscation: Are you sure?</p>
        <p>
          This action will abfuscate sensitive information for this user. It will be impossible to
          return
        </p>
        <p>
          Write in <span style={{ color: "red" }}>DELETE</span> into input and click{" "}
          <span style={{ color: "blue" }}>OK</span>
        </p>
        <Input
          key="input"
          value={deleteConfirmation}
          onChange={e => {
            const value = e.target.value
            if (value === "DELETE") setValidInput(true)
            if (value !== "DELETE") setValidInput(false)
            setDeleteConfirmation(value)
          }}
        />
      </Modal>
    </Spin>
  )
})
