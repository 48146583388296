import React, { useState } from "react"

import { Form, Input, Switch } from "antd"
import { observer } from "mobx-react"
import { FC } from "react"
import { useStore } from "stores/utils/use-store"
import { Game } from "../../store/types"
import { SubmitCancelButtons, UploadComponent } from "components"

export const GameCreation: FC<{ onClose: () => void }> = observer(({ onClose }) => {
  const { dataStore: { gamesStore } } = useStore()

  const onFinishHandler = async(e: Game) => {

    await gamesStore.createGame({
      ...e,
      gamePlatformId: "",
      updateUrl: "",
      updatedAt: "",
      createdAt: "",
      isAutoUpdatable: true,
      pictureUrl: gameLogo,
    })
    onClose()
    await gamesStore.getData()
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  const [isMobile, setIsMobile] = useState(false)
  const [gameLogo, setGameLogo] = useState("")

  return (
    <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler}>
      <UploadComponent
        image={gameLogo}
        setImage={setGameLogo}
        placeholder="Game Logo"
        title="Game Logo"
        required
      />
      <Form.Item label="Game Id" name="gameIdentifier" initialValue="" style={{ width: "100%" }}>
        <Input />
      </Form.Item>
      <Form.Item label="Name" name="name" initialValue="" style={{ width: "100%" }}>
        <Input />
      </Form.Item>
      <Form.Item label="Pretty Name" name="pretyName" initialValue="" style={{ width: "100%" }}>
        <Input />
      </Form.Item>
      <Switch
        defaultChecked={isMobile}
        onChange={setIsMobile}
        style={{
          position: "absolute",
          left: "33%",
          zIndex: 1,
        }}
      />
      <Form.Item
        label="is Mobile game"
        name="gamePlatform"
        className="withSwitchers"
        initialValue={false}
      />
      <Form.Item label="Priority" name="priority" initialValue="" style={{ width: "100%" }}>
        <Input />
      </Form.Item>

      <SubmitCancelButtons onClose={onClose} isLoading={gamesStore.isLoading} />
    </Form>
  )
})
