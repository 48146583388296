import React, { FC, useEffect } from "react"
import { Form, Input, InputNumber, Select } from "antd"

import { GroupToUpdate } from "domains/promocodes/store/types"
import { observer } from "mobx-react"
import { useStore } from "stores/utils/use-store"
import { SubmitCancelButtons } from "components"

export const GroupEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      promocodesGroupsStore: {
        selectedElement: { name, groupPayload, groupRuleId },
        groupRules,
      },
      promocodesGroupsStore,
    },
  } = useStore()

  useEffect(() => {
    promocodesGroupsStore.getGroupRules()
  }, [promocodesGroupsStore])

  const onFinishHandler = async(event: GroupToUpdate) => {
    const dataToBack = {
      name: event.name,
      groupPayload: {
        availableDaysAfterRegistration: event.availableDaysAfterRegistration,
        maxNumberOfRedeems: event.maxNumberOfRedeems,
      },
      groupRuleId: event.groupRuleId,
    }

    promocodesGroupsStore.funcAfterSuccess = async() => {
      onClose()
      await promocodesGroupsStore.getData()
    }
    editType === "edit"
      ? await promocodesGroupsStore.update(dataToBack)
      : await promocodesGroupsStore.create(dataToBack)
  }

  return (
    <Form onFinish={onFinishHandler}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: "Please input region name!",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label="Time limitations from registration date"
        name="availableDaysAfterRegistration"
        initialValue={groupPayload?.availableDaysAfterRegistration}
        style={{ textAlign: "end" }}
      >
        <InputNumber style={{ width: "200px" }} min={1} />
      </Form.Item>

      <Form.Item
        label="Limit redeems"
        name="maxNumberOfRedeems"
        style={{ textAlign: "end" }}
        initialValue={groupPayload?.maxNumberOfRedeems}
      >
        <InputNumber style={{ width: "200px" }} min={1} />
      </Form.Item>

      <Form.Item
        label="Group rule"
        name="groupRuleId"
        rules={[
          {
            required: true,
            message: "Select group rule",
          },
        ]}
        initialValue={groupRuleId}
      >
        <Select placeholder="Choose group rule" style={{ width: "100%" }}>
          {groupRules?.map(item => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.groupRuleType}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      <SubmitCancelButtons onClose={onClose} isLoading={promocodesGroupsStore.isLoading} />
    </Form>
  )
})
