import { BaseStore } from "stores/base.store"
import { sendEmailNotification } from "domains/email-notifications/api/email-notifications.calls"

export class EmailNotificationsStore extends BaseStore {
  async sendEmail(
    templateId: string,
    tagId: string,
    senderName: string,
    senderEmail: string,
    file: any,
  ) {
    await this.defaultFunction(
      sendEmailNotification,
      templateId,
      tagId,
      senderName,
      senderEmail,
      file,
    )
  }
}
