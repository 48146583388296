import { FormElement } from "components"
import { FC } from "react"

interface props {
  title: string
  value: string
  width: number
}

export const TranslationInput: FC<props> = ({ title, value, width }) => {

  return (
    <div style={{ width: `${width}%`, marginRight: "16px" }}>
      {title}
      <FormElement type="input" label="" name={`trans${title}`} initialValue={value} />
    </div>
  )
}
