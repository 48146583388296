import React, { useEffect, useState } from "react"
import { Form, Input, Checkbox, Switch } from "antd"
import { observer } from "mobx-react"

import s from "./styles.module.css"
import { useStore } from "stores/utils/use-store"
import { UploadComponent, SubmitCancelButtons } from "components"
import { BodyContentComponent } from "domains/promo-pop-ups/components/body-content"

import { ButtonCTA } from "./ButtonCTA"
import { Btn } from "../store/types"

export interface CreatePromoPopupsForm {
  editType: "create" | "edit";
  onClose: () => void;
}
export enum CTATypes {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  Premium = "PREMIUM",
}

const CreatePromoPopupRaw = React.forwardRef<any, CreatePromoPopupsForm>(({ onClose, editType }) => {
  const {
    dataStore: {
      promoPopupsStore,
      promoPopupsStore: { selectedPopUp: { title, imageUrl, text, buttons, platforms } },
    },
  } = useStore()

  const [form] = Form.useForm()
  const [devices, setDevices] = useState(platforms)

  const [description, setDescription] = useState("")
  const [listDescriptions, setListDescription] = useState<string[]>([text])
  const [uploadText, setUploadText] = useState(text || "")

  const [CTAButtons, setCTAButtons] = useState(buttons && buttons.length > 0)
  const [twoCTAButtonBool, settwoCTAButtonsBool] = useState(buttons && buttons.length === 2)
  const [twoCTAButton, settwoCTAButtons] = useState<any>(buttons && buttons.length > 0 ? buttons : [{}])

  const [image, setImage] = useState(imageUrl)

  useEffect(() => {
    form.setFieldsValue({ platforms: devices })
  }, [form, devices])

  useEffect(() => {
    form.setFieldsValue({ imageUrl: imageUrl })
  }, [form, imageUrl])

  const onFinishHandler = async(event: any) => {
    let btns: Btn[] = []

    if (CTAButtons) {
      if (twoCTAButtonBool) {
        btns = [
          {
            text: event.titleCTAButton1,
            type: event.typeCTAButton1,
            link: event.link1,
            linkType: "INTERNAL",
          },
          {
            text: event.titleCTAButton2,
            type: event.typeCTAButton2,
            link: event.link2,
            linkType: "INTERNAL",
          },
        ]
      } else {
        btns = [
          {
            text: event.titleCTAButton1,
            type: event.typeCTAButton1,
            link: event.link1,
            linkType: "INTERNAL",
          },
        ]
      }
    }
    const popup = {
      title: event.title,
      imageUrl: event.imageUrl,
      text: uploadText,
      buttons: btns,
      platforms: event.platforms,
    }

    promoPopupsStore.funcAfterSuccess = async() => {
      onClose()
      await promoPopupsStore.getData()
    }

    editType === "edit"
      ? await promoPopupsStore.updatePopUp(popup)
      : await promoPopupsStore.createPopUp(popup)
  }

  return (
    <Form onFinish={onFinishHandler} form={form}>
      <Form.Item
        label="Title"
        name="title"
        style={{ width: "100%" }}
        initialValue={title}
        rules={[
          {
            required: true,
            message: "Please input PopUp title!",
          },
        ]}
      >
        <Input maxLength={100} />
      </Form.Item>
      <div style={{ display: "flex", flexDirection: "row", padding: "20px 0px " }}>
        <Form.Item
          name="platforms"
          style={{
            position: "absolute",
            width: "100%",
            transform: "translate(0px, -50px)",
          }}
          rules={[{ required: true, message: "Please select device" }]}
        />
        <label style={{ width: "20%" }}>Devices: </label>
        <Checkbox.Group
          options={["DESKTOP", "IOS", "ANDROID"]}
          value={devices}
          defaultValue={devices}
          onChange={e => {
            const mapped = e.map(x => `${x}`)
            setDevices(mapped)
            form.setFieldsValue({ platforms: mapped })
          }}
          style={{
            display: "flex",
            flex: "1 1 0",
            maxWidth: "70%",
          }}
        />
      </div>

      <div style={{ position: "relative" }}>
        <Form.Item
          name="imageUrl"
          style={{ position: "absolute", width: "100%", top: "-55px" }}
          rules={[{ required: true, message: "Please select image" }]}
        />
        <UploadComponent
          image={image}
          setImage={e => {
            setImage(e)
            form.setFieldsValue({ imageUrl: e })
          }}
          maxSize={1}
        />
      </div>

      <BodyContentComponent
        description={description}
        setDescription={setDescription}
        uploadText={uploadText}
        setUploadText={setUploadText}
        s={s}
        listDescriptions={listDescriptions}
        setListDescription={setListDescription}
      />

      <Checkbox onChange={() => setCTAButtons(!CTAButtons)} checked={CTAButtons}>
        CTA Buttons on
      </Checkbox>
      {CTAButtons && (
        <>
          <div
            style={{ padding: "10px 0" }}
          >
            <Form.Item style={{ lineHeight: "0px", height: "0px" }}>
              <label style={{ padding: "0px 10px" }}>1 CTA button</label>
              <Switch
                defaultChecked={twoCTAButtonBool}
                onChange={() => {
                  settwoCTAButtons(!twoCTAButtonBool ? [{}, {}] : [{}])

                  settwoCTAButtonsBool(!twoCTAButtonBool)
                }}
              />
              <label style={{ padding: "0px 10px" }}>2 CTA button</label>
            </Form.Item>
          </div>
          {twoCTAButton.map((e: any, id: number) => (
            <ButtonCTA id={id} button={e} key={id} />
          ))}
        </>
      )}
      <SubmitCancelButtons onClose={onClose} isLoading={promoPopupsStore.isLoading} />
    </Form>
  )
})

export const CreatePromoPopupForm = observer(CreatePromoPopupRaw)
