export const ApprovedIframeNames = [
  "spin-n-collect",
  "avatar-settings-profile",
  "avatar-profile-other",
  "avatar-friend-list",
  "avatar-settings-profile-image",
  "avatar-settings-favorite-games",
  "avatar-settings-cover-image",
  "avatar-profile-me",
  "offerwall",
]

export const ApprovedButtonNames = [
  "premiumBlock",
  "rafflesButton",
  "referralsButton",
  "leaderboardButton",
  "accountPremiumButton",
  "becomePremiumButton",
  "notifications",
  "information",
  "avatarSearch",
  "gameModes",
  "leftBottomLoungeButton",
]

export const ApprovedTextsNames = ["launchGameOnlineMode"]
