import React, { FC } from "react"
import { Modal } from "antd"
import { useStore } from "stores/utils/use-store"

export const RemovePremium: FC<{
  visible: boolean;
  onClose: () => void;
  accountSubscriptionId: string;
}> = ({ visible, onClose, accountSubscriptionId }) => {
  const { dataStore: { accountDetailsStore } } = useStore()

  return (
    <Modal
      visible={visible}
      title="Delete Subscription?"
      onCancel={() => onClose()}
      onOk={async() => {
        accountDetailsStore.funcAfterSuccess = async() => {
          onClose()
          await accountDetailsStore.getAccountDetails(accountDetailsStore.id)
        }
        await accountDetailsStore.removePremium({ accountSubscriptionId })
      }}
    >
      Do you really want to deactivate subscription with id
      {accountSubscriptionId}
    </Modal>
  )
}
