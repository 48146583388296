import { BillingPeriod, IAccountInventoryPage, PaymentProviderTypeEnum } from "domains/accounts/store/types"
import { Account } from "domains/accounts/store/account.store"
import { apiCall } from "api-calls/api-call"

export const getAccounts = async(
  filter: string,
  page: number,
  limit: number,
): Promise<{
  data: Account[];
  total: number;
}> => {
  const query: { page: number; limit: number; filter?: string } = {
    page,
    limit,
  }

  if (filter) query.filter = filter

  return apiCall({
    method: "GET",
    path: "accounts",
    query,
  })
}

export const getAccountDetailsById = async(id: string) => {
  return apiCall({
    method: "GET",
    path: `accounts/${id}`,
  })
}

export const getGameAccountDetailsById = async(id: string) => {
  return apiCall({
    method: "GET",
    path: `accounts/${id}`,
  })
}

export const givePremiumFunc = async(
  data: {
    subscriptionId: string;
    billingPeriod: BillingPeriod;

    startDate: string;
    paymentProviderType: PaymentProviderTypeEnum;
    billingPlanId: string;
  },
  id: string,
) => {
  return apiCall({
    method: "POST",
    path: `accounts/give-premium/${id}`,
    body: data,
  })
}

export const removePremiumFunc = async(data: { accountSubscriptionId: string }) => {
  return apiCall({
    method: "POST",
    path: "accounts/remove-premium",
    body: data,
  })
}

export const freezeAccountFunc = async(id: string, data: { reasonOfBan: string }) => {
  return apiCall({
    method: "POST",
    path: `accounts/freeze-account/${id}`,
    body: data,
  })
}

export const unfreezeAccountFunc = async(id: string) => {
  return apiCall({
    method: "POST",
    path: `accounts/unfreeze-account/${id}`,
  })
}

export const verifyEmailFunc = async(id: string) => {
  return apiCall({
    method: "POST",
    path: `accounts/verify-email/${id}`,
  })
}

export const clearBalanceFunc = async(id: string) => {
  return apiCall({
    method: "POST",
    path: `accounts/clear-balance/${id}`,
  })
}

export const obfuscateAccount = async(id: string) => {
  return apiCall({
    method: "POST",
    path: `accounts/obfuscate/${id}`,
  })
}

export const addBonusFunc = async(
  data: { name: string; amount: number; type: number },
  id: string,
) => {
  return apiCall({
    method: "POST",
    path: "transactions",
    body: { ...data, accountId: id },
  })
}

export const changeCountryFunc = async(id: string, country: string) => {
  return apiCall({
    method: "POST",
    path: `accounts/change-country/${id}`,
    body: { country },
  })
}

export const getInventory = async(
  id: string,
  page: number,
  limit: number,
): Promise<IAccountInventoryPage> => {
  const query: { page: number; limit: number; } = {
    page,
    limit,
  }

  return apiCall({
    method: "GET",
    path: `accounts/${id}/inventory`,
    query,
  })
}
