import { Tag } from "antd"
import { gameNames } from "abstract/constants/game-ids"
import { defaultFormat } from "utils/date"

export const boosterColumns = [
  { title: "Name", dataIndex: "name", key: "name" },
  {
    title: "Game Ids",
    dataIndex: "gameIds",
    key: "gameIds",
    render: (el: number[]) => el.map(el => gameNames[el]).join(", "),
  },
  { title: "Booster in %", dataIndex: "booster", key: "booster" },
  {
    title: "Start/End Dates",
    dataIndex: "startDate",
    key: "startEndDates",
    render: (_, row) => {
      return (
        <div style={{ display: "grid", gap: "6px", width: "max-content" }}>
          {row?.startDate ? (
            <Tag color="green">{defaultFormat(row?.startDate)}</Tag>
          ) : (
            <Tag>No time</Tag>
          )}
          {row?.endDate ? (
            <Tag color="red">{defaultFormat(row?.endDate)}</Tag>
          ) : (
            <Tag>No time</Tag>
          )}
        </div>
      )
    },
  },
]
