import { Tabs } from "antd"
import { JSONEditor } from "./JSONEditor"
import { UIEditor } from "./UIEditor"

export const EditMobileConfig = () => {
  return (
    <Tabs destroyInactiveTabPane>
      <Tabs.TabPane tab="Visual Editor" key="1">
        <UIEditor />
      </Tabs.TabPane>
      <Tabs.TabPane tab="JSON Editor" key="2">
        <JSONEditor />
      </Tabs.TabPane>
    </Tabs>
  )
}
